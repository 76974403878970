import { SummaryApi } from 'api/SummaryApi';
import moment from 'moment';
import { ProjectSummary } from 'model/ProjectSummary';

export class ProjectSummaryMapper {
  deserializeCombine(entry: SummaryApi.ProjectSummary): ProjectSummary {
    return {
      id: Number(entry.id),
      score: entry.score,
      date: moment(entry.date),
      benchmark: entry.benchmark,
      status: entry.type,
    };
  }
}
