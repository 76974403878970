import React from 'react';

import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import { EngagementDriverCompare } from 'view/EngagementDriversView/EngagementDriverCompare';

export namespace OverallComparePage {
  export type Props = {};
}

export const OverallComparePage: React.FC<OverallComparePage.Props> = () => (
  <>
    <SummaryCompareWidgets />

    <EngagementDriverCompare />
  </>
);
