import { TypographyVariantsOptions } from '@mui/material';
import { pxToRem } from 'app/theme/utils/pxToRem';
import { breakpoints } from 'app/theme/breakpoints';
import { HTML_FONT_SIZE } from 'app/theme/constants';
import { TEXT_COLOR } from 'app/theme/colors';

const fontWeight: TypographyVariantsOptions = {
  fontWeightRegular: 400,
  fontWeightBold: 800,
};

export const fontSize = {
  xsToMd: {
    h1: pxToRem(28.83),
    h2: pxToRem(25.63),
    h3: pxToRem(22.78),
    h4: pxToRem(20.25),
    h5: pxToRem(18),
    h6: pxToRem(16),
    subtitle1: pxToRem(16),
    subtitle2: pxToRem(12.6),
    body1: pxToRem(16),
    body2: pxToRem(12.64),
  },
  mdPlus: {
    h1: pxToRem(39.81),
    h2: pxToRem(33.18),
    h3: pxToRem(27.65),
    h4: pxToRem(23.04),
    h5: pxToRem(19.2),
    h6: pxToRem(16),
    subtitle1: pxToRem(16),
    subtitle2: pxToRem(13.3),
    body1: pxToRem(16),
    body2: pxToRem(13.33),
  },
};

export const fontFamily = ['Montserrat', 'sans-serif'].join(',');

export const typography: TypographyVariantsOptions = {
  fontFamily,
  htmlFontSize: HTML_FONT_SIZE,
  h1: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: fontSize.xsToMd.h1,
    lineHeight: pxToRem(40.3),
    color: TEXT_COLOR,
    [breakpoints.up('md')]: {
      fontSize: fontSize.mdPlus.h1,
      lineHeight: pxToRem(55.7),
    },
  },
  h2: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: fontSize.xsToMd.h2,
    lineHeight: pxToRem(35),
    color: TEXT_COLOR,
    [breakpoints.up('md')]: {
      fontSize: fontSize.mdPlus.h2,
      lineHeight: pxToRem(46.4),
    },
  },
  h3: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: fontSize.xsToMd.h3,
    lineHeight: pxToRem(31.8),
    color: TEXT_COLOR,
    [breakpoints.up('md')]: {
      fontSize: fontSize.mdPlus.h3,
      lineHeight: pxToRem(38.7),
    },
  },
  h4: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: fontSize.xsToMd.h4,
    lineHeight: pxToRem(28.3),
    color: TEXT_COLOR,
    [breakpoints.up('md')]: {
      fontSize: fontSize.mdPlus.h4,
      lineHeight: pxToRem(32),
    },
  },
  h5: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: fontSize.xsToMd.h5,
    lineHeight: pxToRem(25.2),
    color: TEXT_COLOR,
    [breakpoints.up('md')]: {
      fontSize: fontSize.mdPlus.h5,
      lineHeight: pxToRem(26.8),
    },
  },
  h6: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: fontSize.xsToMd.h6,
    lineHeight: pxToRem(22.4),
    color: TEXT_COLOR,
  },
  subtitle1: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: fontSize.xsToMd.subtitle1,
    lineHeight: pxToRem(22),
    color: TEXT_COLOR,
  },
  subtitle2: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: fontSize.xsToMd.subtitle2,
    lineHeight: pxToRem(17.6),
    color: TEXT_COLOR,
    [breakpoints.up('md')]: {
      fontSize: fontSize.mdPlus.subtitle2,
      lineHeight: pxToRem(18.6),
    },
  },
  body1: {
    fontWeight: fontWeight.fontWeightRegular,
    fontSize: fontSize.xsToMd.body1,
    lineHeight: pxToRem(24),
    color: TEXT_COLOR,
  },
  body2: {
    fontWeight: fontWeight.fontWeightRegular,
    fontSize: fontSize.xsToMd.body2,
    lineHeight: pxToRem(18.96),
    color: TEXT_COLOR,
    [breakpoints.up('md')]: {
      fontSize: fontSize.mdPlus.body2,
      lineHeight: pxToRem(19.9),
    },
  },
};
