import { styled, Typography } from '@mui/material';
import { WHITE, TEXT_COLOR, IRON, BOULDER } from 'app/theme/colors';
import { typography } from 'app/theme/typography';
import { Button } from 'component/NavButton/NavButton.s';

export const Menu = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
});

export const MenuTitle = styled(Typography)({
  color: TEXT_COLOR,
  borderTop: `1px solid ${IRON}`,
  paddingTop: 24,
  marginTop: 16,
  marginBottom: 16,
});

export const SwitchModeWrapper = styled('div')({});

export const ClearAllFiltersButton = styled(Button)({
  ...typography.subtitle2,
  whiteSpace: 'nowrap',
  backgroundColor: WHITE,
  color: BOULDER,
});
