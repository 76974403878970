import React from 'react';
import { useSelector } from 'react-redux';

import { selectSummaryCategoryViewMode } from 'util/selector/querySelector';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { EngagementDriversCombine } from 'view/EngagementDriversView/EngagementDriversCombine';
import { EngagementDriverByCategoryCombine } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/EngagementDriverByCategoryCombine';
import {
  selectEngagementDriversCombineList,
  selectTrendLabel,
} from 'reduxStore/engagementDrivers/selectors';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { EngagementDriver, ReportEngagementDriver } from 'model/ReportEngagamentDriver';
import {
  selectCurrentProjectIsHighPulseFrequency,
  selectProjectClosedAt,
  selectProjectKind,
} from 'reduxStore/project/selectors';
import { ProjectKind } from 'model/ProjectKind';
import { SummaryReportStrategy } from 'view/ReportView/SummaryWidgetReport/SummaryReportStrategy';
import { Project } from 'model/Project';
import { FlagNew } from 'component/FlagNew/FlagNew';
import {
  NewExplanationText,
  NewExplanationWrapper,
} from 'view/SelectProjectPage/SelectProjectPage.s';
import { Moment } from 'moment';
import { SummaryCategoryViewMode } from 'register/ModeType';

export type EngagementScoreReportCombinePureProps = {
  engagementDrivers: ReportEngagementDriver[];
  isAdmin: boolean;
  trendLabel: string;
  projectKind: ProjectKind;
  isPulseHighFrequency: boolean;
  summaryCategoryViewMode: SummaryCategoryViewMode;
  closedAt?: Moment;
};

export const EngagementScoreReportCombinePure: React.FC<EngagementScoreReportCombinePureProps> = ({
  closedAt,
  engagementDrivers,
  isAdmin,
  isPulseHighFrequency,
  projectKind,
  trendLabel,
  summaryCategoryViewMode,
}) => (
  <div>
    <SummaryReportStrategy kind={projectKind} />
    <ReportContent
      title={Project.getEngagementDriversTitle({
        kind: projectKind,
        closedAt: closedAt,
      })}
      component={<EngagementDriversCombine isReportView />}
    />
    {summaryCategoryViewMode === SummaryCategoryViewMode.Category &&
      engagementDrivers.length &&
      !isPulseHighFrequency && (
        <>
          <div>
            {engagementDrivers.map((engagementDriver, index) => (
              <ReportContent
                key={index}
                title={`${engagementDriver.category!.name} : ${engagementDriver.score}`}
                component={
                  <EngagementDriverByCategoryCombine
                    key={index}
                    categoryTitle={engagementDriver.category!.name}
                    categoryId={engagementDriver.category!.id}
                    score={engagementDriver.score}
                    categoryData={engagementDriver.details}
                    trends={engagementDriver.trends}
                    benchmark={engagementDriver.benchmark}
                    isAdmin={isAdmin}
                    trendLabel={trendLabel}
                    isReportView
                  />
                }
              />
            ))}
          </div>
          {EngagementDriver.needLegend(engagementDrivers) && (
            <ReportContent
              title="Legend"
              component={
                <NewExplanationWrapper>
                  <FlagNew isLatest />
                  <NewExplanationText>
                    This question was asked in your most recent survey. It may be the first time
                    you've asked this question, or a question you've asked before with new feedback
                    showing for it.
                  </NewExplanationText>
                </NewExplanationWrapper>
              }
            />
          )}
        </>
      )}
  </div>
);

export const EngagementScoreReportCombine: React.FC = () => {
  const closedAt = useSelector(selectProjectClosedAt);
  const engagementDrivers = useSelector(selectEngagementDriversCombineList);
  const isAdmin = useSelector(selectIsAdmin);
  const isPulseHighFrequency = useSelector(selectCurrentProjectIsHighPulseFrequency);
  const projectKind = useSelector(selectProjectKind);
  const trendLabel = useSelector(selectTrendLabel);
  const summaryCategoryViewMode = useSelector(selectSummaryCategoryViewMode);

  return (
    <EngagementScoreReportCombinePure
      closedAt={closedAt}
      engagementDrivers={engagementDrivers}
      isAdmin={isAdmin}
      isPulseHighFrequency={isPulseHighFrequency}
      projectKind={projectKind}
      trendLabel={trendLabel}
      summaryCategoryViewMode={summaryCategoryViewMode}
    />
  );
};
