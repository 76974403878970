import { formatDateToShortDateString } from 'util/formatDate';

import moment, { Moment } from 'moment';
import { monthNames } from 'register/MonthNames';
import { ProjectKind, PULSE_TEMPLATE_CHILD_PROJECT_KIND_LIST } from 'model/ProjectKind';
import { Demography } from 'model/Demography';
import { Department } from 'model/Department';
import { Question, QuestionsStats } from 'model/Question';
import { Lifecycle } from 'model/Lifecycle';
import {
  ProjectModel,
  PulseTemplateChildProjectModel,
  PulseTemplateProjectModel,
} from 'model/Project.model';

export namespace Project {
  export const getMonthName = (month: number) => monthNames[month];

  export const getReportTitle = (project: ProjectModel): string => {
    switch (project.kind) {
      case ProjectKind.PULSE_TEMPLATE:
        return 'WorkBuzz Pulse';
      case ProjectKind.PULSE_LIFECYCLE_PROJECT:
        return `${Lifecycle.LIFECYCLE_LABEL[project.lifecycleType]}`;
      default:
        return project.name;
    }
  };

  type GetEngagementTitle = (projectDetails: { kind: ProjectKind; closedAt?: Moment }) => string;
  const EMPLOYEE_TITLE = 'Your Employee Experience:';
  const GENERIC_TITLE = 'Engagement Drivers';

  export const getEngagementDriversTitle: GetEngagementTitle = (projectDetails) => {
    switch (projectDetails.kind) {
      case ProjectKind.PULSE_PROJECT:
        return `Feedback for ${formatDateToShortDateString(moment(projectDetails.closedAt))}`;
      case ProjectKind.PULSE_TEMPLATE:
      case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
        return EMPLOYEE_TITLE;
      default:
        return GENERIC_TITLE;
    }
  };

  type ShouldHideSidebar = (
    isReportsTab: boolean,
    demography: Demography[],
    departments: Department[]
  ) => boolean;

  export const shouldHideSidebar: ShouldHideSidebar = (isReportsTab, demography, departments) => {
    if (isReportsTab) {
      return true;
    }

    return demography.length === 0 && !Department.showFilters(departments);
  };

  export const isPulseTemplateChild = (
    project: ProjectModel
  ): project is PulseTemplateChildProjectModel => {
    return PULSE_TEMPLATE_CHILD_PROJECT_KIND_LIST.some(
      (expectedProjectKind) => expectedProjectKind === project.kind
    );
  };

  export const isPulseTemplateOrOneOfChild = (
    project: ProjectModel
  ): project is PulseTemplateChildProjectModel | PulseTemplateProjectModel => {
    return Project.isPulseTemplateChild(project) || project.kind === ProjectKind.PULSE_TEMPLATE;
  };

  export const getAvailableProjectOptions = (
    project: PulseTemplateChildProjectModel | PulseTemplateProjectModel
  ) => {
    let latestPulseId: number | undefined;
    let latestOnDemandId: number | undefined;

    if (project.pulseProjects.length > 0) {
      latestPulseId = project.pulseProjects[project.pulseProjects.length - 1].id;
    }
    if (project.pulseOnDemandProjects.length > 0) {
      latestOnDemandId = project.pulseOnDemandProjects[project.pulseOnDemandProjects.length - 1].id;
    }

    return { latestPulseId, latestOnDemandId };
  };

  type ShowTab = (questionsStats: QuestionsStats | null, checkAnswers: boolean) => boolean;
  export const showCommentsTab: ShowTab = (questionsStats, checkAnswers) => {
    if (!questionsStats || !Question.hasCommentsQuestion(questionsStats)) {
      return false;
    }

    // Additional condition if project is not pulse or template it should contain at least 1 answer
    if (checkAnswers && !Question.hasCommentsAnswers(questionsStats)) {
      return false;
    }

    return true;
  };

  export const showInsightsTab: ShowTab = (questionsStats, checkAnswers) => {
    if (!questionsStats || !Question.hasInsightsQuestion(questionsStats)) {
      return false;
    }

    // Additional condition if project is not pulse or template it should contain at least 1 answer
    if (checkAnswers && !Question.hasInsightsAnswers(questionsStats)) {
      return false;
    }

    return true;
  };
}
