import React, { MouseEventHandler } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Menu } from '@mui/icons-material';
import { MobileMenuButtonWrapper } from 'component/MobileMenuButton/MobileMenuButton.s';

type MobileMenuButtonProps = { onClick: MouseEventHandler; isMenuOpen: boolean };

export const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ onClick, isMenuOpen }) => {
  return (
    <MobileMenuButtonWrapper data-testid="mobileMenuButton" onClick={onClick}>
      {isMenuOpen ? <CloseIcon /> : <Menu />}
    </MobileMenuButtonWrapper>
  );
};
