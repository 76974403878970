import { useLocation } from 'react-router';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectIsReportView } from 'util/selector/reportSelector';

import posthog from 'posthog-js';
import { getAppConfig } from 'app/getConfig';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { selectCurrentCompanyName } from 'reduxStore/project/selectors';

import { useIdentifyPostHog } from './useIdentifyPostHog';

export const useInitializePostHog = () => {
  const { REACT_APP_PUBLIC_POSTHOG_KEY, REACT_APP_PUBLIC_POSTHOG_HOST } = getAppConfig();
  const location = useLocation();
  const isPosthogEnabled = useFeatureIsOn('posthog-core');
  const companyName = useSelector(selectCurrentCompanyName);
  const posthogInitedRef = useRef(false);
  const isReportView = useSelector(selectIsReportView);

  if (
    !posthogInitedRef.current &&
    !isReportView &&
    REACT_APP_PUBLIC_POSTHOG_KEY &&
    REACT_APP_PUBLIC_POSTHOG_HOST &&
    isPosthogEnabled
  ) {
    posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY, {
      api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      },
    });

    posthogInitedRef.current = true;
  }

  useIdentifyPostHog();

  useEffect(() => {
    if (isPosthogEnabled && !isReportView) {
      posthog.capture('$pageview');
    }
  }, [location.pathname, isPosthogEnabled, isReportView]);

  useEffect(() => {
    if (isPosthogEnabled && companyName && !isReportView) {
      posthog.capture('company_name_changed', { companyName });
    }
  }, [companyName, isPosthogEnabled, isReportView]);
};
