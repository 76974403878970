import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';

import { ResponseRateState, STORE_NAME } from './initialState';

export const selectResponseRateDomain = (state: AppState): ResponseRateState.Domain =>
  state[STORE_NAME];

export const selectResponseRateDomainData = createSelector(
  selectResponseRateDomain,
  (domain) => domain.data
);

export const selectResponseRateByProjectId = (projectId: number) =>
  createSelector(selectResponseRateDomainData, (domain) => domain[projectId]);

export const selectIsLoadingResponseRate = (projectId: number) =>
  createSelector(
    selectResponseRateDomainData,
    (domain) => !!(domain[projectId] as ResponseRateState.LoadingState | undefined)?.isLoading
  );
