import { QuestionDataType } from './QuestionTable';

export const hasAnyTrendPlotData = (questionList: QuestionDataType): boolean => {
  if (!(questionList.length > 0)) {
    return false;
  }

  return questionList.some((questionItem) => {
    return questionItem.question.trendPlotData?.some((trendItem) => trendItem !== null);
  });
};
