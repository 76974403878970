import React from 'react';
import { useSelector } from 'react-redux';

import { formatDateToFullDateString } from 'util/formatDate';

import { ReportType } from 'model/ReportType';
import WorkbuzzLogo from 'app/asset/workbuzz_logo.svg';
import { selectIsLifecycleProjectKind, selectProjectClosedAt } from 'reduxStore/project/selectors';
import moment from 'moment';

import {
  BackgroundWrapper,
  CommentsMessage,
  DepartmentTitle,
  LogosRow,
  LogoWrapper,
  MainContent,
  ReportImage,
  OrangeTitle,
  TitleContainer,
  TitlePageWrapper,
} from './TitlePage.s';
import { useGetTitlePageLogos } from './useGetTitlePageLogos';

export type TitlePageProps = {
  title: string;
  reportType: string;
  departmentName?: string;
};

export const TitlePage: React.VFC<TitlePageProps> = ({ reportType, departmentName, title }) => {
  const { circleLogoUrl, companyLogoUrl } = useGetTitlePageLogos();
  const closedAt = useSelector(selectProjectClosedAt);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);

  const formattedClosedDate = formatDateToFullDateString(moment(closedAt));

  return (
    <TitlePageWrapper data-testid="titlePageWrapper" reportType={reportType}>
      <LogosRow>
        {circleLogoUrl ? (
          <ReportImage data-testid="logoCircleReportImage" src={circleLogoUrl} />
        ) : (
          <LogoWrapper>
            <WorkbuzzLogo />
          </LogoWrapper>
        )}
        {companyLogoUrl && <ReportImage data-testid="logoReportImage" src={companyLogoUrl} />}
      </LogosRow>

      <MainContent>
        <TitleContainer>
          <OrangeTitle>{title}</OrangeTitle>
          {!isLifecycleProjectKind && (
            <OrangeTitle data-testid="closedAtDate">{formattedClosedDate}</OrangeTitle>
          )}
          {departmentName && (
            <DepartmentTitle data-testid="departmentName">{departmentName}</DepartmentTitle>
          )}
          <DepartmentTitle data-testid="reportType">{reportType}</DepartmentTitle>
          {reportType === ReportType.Comments && (
            <CommentsMessage data-testid="commentsMessage">
              PLEASE NOTE that comments are provided verbatim from the survey, and so appear exactly
              as submitted, including any spelling errors, grammatical errors, and blank entries.
              Due to variations in operating systems and browsers, unusual characters may also
              occasionally appear.
            </CommentsMessage>
          )}
        </TitleContainer>

        <BackgroundWrapper />
      </MainContent>
    </TitlePageWrapper>
  );
};
