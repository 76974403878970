import { styled } from '@mui/material';
import { ReportType } from 'model/ReportType';

type ReportWrapperProps = {
  reportType?: string;
};

export const ReportWrapper = styled('table', {
  shouldForwardProp: (prop) => prop !== 'reportType',
})(
  {
    height: 'auto',
    marginTop: 0,
    '@media print': {
      pageBreakAfter: 'always',
    },
    '&:last-child': {
      pageBreakAfter: 'auto',
    },
  },
  (props: ReportWrapperProps) => ({
    width: props.reportType === ReportType.Comments ? 'auto' : '100%',
  })
);
