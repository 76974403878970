import React from 'react';
import { useSelector } from 'react-redux';

import { CasReportProps } from 'view/ReportView/FeedbackReport/Cas/CasReport';
import { LifecycleReportCombine } from 'view/ReportView/FeedbackReport/Lifecycle/LifecycleReportCombine';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import {
  selectSimpleProjectCombineResource,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';

import { LifecycleReportCompare } from './LifecycleReportCompare';

export const LifecycleReport: React.FC<CasReportProps> = ({ isCompareMode }) => {
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);

  const simpleProjectResource = isCompareMode
    ? simpleProjectCompareResource
    : simpleProjectCombineResource;

  return (
    <ResourceLoader resource={simpleProjectResource}>
      {isCompareMode ? <LifecycleReportCompare /> : <LifecycleReportCombine />}
    </ResourceLoader>
  );
};
