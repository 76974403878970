export type Feedback = Readonly<{
  label: string;
  value: number;
  options?: [string] | [string, string];
}>;

export namespace Feedback {
  export const getTooltip = ({ options, value }: Feedback) => {
    if (!options) {
      return null;
    }

    const [firstOption, secondOption] = options;

    const optionalSecond = secondOption ? ` or ${secondOption}` : '';
    return `${value}% of respondents selected ${firstOption}${optionalSecond}`;
  };
}
