import React from 'react';

import { SummaryInfoWrapper } from 'view/SummaryPage/SummaryInfo/SummaryInfo.s';

export namespace ResponseRateInfo {
  export type Props = {
    projectLabel: string;
  };
}

export const ResponseRateInfo: React.FC<ResponseRateInfo.Props> = ({ projectLabel }) => {
  return <SummaryInfoWrapper>This shows the response rate for {projectLabel}</SummaryInfoWrapper>;
};
