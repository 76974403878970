import { createSlice, Draft } from '@reduxjs/toolkit';
import { Demography } from 'model/Demography';
import { Resource } from 'model/Resource';

import { DemographyState, STORE_NAME } from './initialState';
import { fetchDemography } from './asyncActions';

export const demographySlice = createSlice({
  name: STORE_NAME,
  initialState: DemographyState.INITIAL_DOMAIN,
  reducers: {
    clearDemography: (state) => {
      state.list = DemographyState.INITIAL_DOMAIN.list as Draft<Resource<Demography[]>>;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDemography.pending, (state) => {
      state.list = Resource.createPendingResource(state.list.data);
    });
    builder.addCase(fetchDemography.fulfilled, (state, action) => {
      state.list = Resource.createResolvedResource({ data: action.payload as Draft<Demography[]> });
    });
    builder.addCase(fetchDemography.rejected, (state, action) => {
      state.list = Resource.createRejectedResource({
        data: [],
        error: action.payload ?? action.error,
      }) as Draft<Resource<Demography[]>>;
    });
  },
});

export const { clearDemography } = demographySlice.actions;
