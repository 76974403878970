import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isArrayEmpty } from 'util/isEmpty';

import {
  ArrowIconWrapper,
  Item,
  ItemTitle,
  ItemTitleWrapper,
  ItemWrapper,
  StyledCheckbox,
} from 'component/DepartmentList/DepartmentList.s';
import { Department } from 'model/Department';
import { ArrowIconDown, ArrowIconRight } from 'component/DemographyFilter/DemographyFilter.s';
import { selectIsDepartmentSelected } from 'reduxStore/departments/selectors';
import { BOULDER, LIGHTER_GREY } from 'app/theme/colors';
import { changeFilter } from 'reduxStore/filtering/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { toggleDepartmentCollapsedState } from 'reduxStore/departments/slice';
import { OverflowTooltip } from 'component/OverflowTooltip/OverflowTooltip';
import { useMediaQuery, useTheme } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';

export type DepartmentListItemPureProps = {
  checked: boolean;
  disabled: boolean;
  item: Department;
  onToggleDepartmentCollapsedState: (id: number) => void;
  onSelectDepartment: (id: number, collapsed: boolean) => void;
};

export const DepartmentListItemPure: React.FC<DepartmentListItemPureProps> = ({
  checked,
  disabled,
  item,
  onToggleDepartmentCollapsedState,
  onSelectDepartment,
}) => {
  const arrowColor = item.blocked ? LIGHTER_GREY : BOULDER;
  const [collapsed, setCollapsed] = useState(item.collapsed || item.blocked);
  const departmentNameNode = <>{item.name}</>;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const desktopTooltipProps: {
    placement: TooltipProps['placement'];
    slotProps: TooltipProps['slotProps'];
  } = {
    placement: 'right',
    slotProps: {
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
        ],
      },
    },
  };

  const mobileTooltipProps: { placement: TooltipProps['placement'] } = {
    placement: 'top',
  };

  // TODO: check if we need below block, it's left here to repeat the before refactoring logic
  useEffect(() => {
    setCollapsed(item.collapsed || item.blocked);
  }, [item]);

  return (
    <ItemWrapper>
      <Item level={item.level!} data-testid={`department[${item.id}]`}>
        <button
          style={{ all: 'unset' }}
          onClick={() => {
            setCollapsed(!collapsed);
            onToggleDepartmentCollapsedState(item.id);
          }}
        >
          {!isArrayEmpty(item.children) &&
            (collapsed ? (
              <ArrowIconWrapper level={item.level}>
                <ArrowIconRight color={arrowColor} />
              </ArrowIconWrapper>
            ) : (
              <ArrowIconWrapper level={item.level}>
                <ArrowIconDown color={arrowColor} />
              </ArrowIconWrapper>
            ))}
        </button>
        <ItemTitleWrapper
          onClick={() => !disabled && !item.blocked && onSelectDepartment(item.id, item.collapsed)}
        >
          <ItemTitle
            as="span"
            data-cy={`isChecked[${checked}]`}
            hasChildren={!isArrayEmpty(item.children)}
            checked={checked}
            blocked={item.blocked}
            level={item.level}
            translate="no"
          >
            <OverflowTooltip
              id={`department${item.id}`}
              title={
                <span translate="no" style={{ color: 'inherit' }}>
                  {departmentNameNode}
                </span>
              }
              arrow
              {...(isMobile ? mobileTooltipProps : desktopTooltipProps)}
              enterDelay={0}
              leaveDelay={0}
            >
              {departmentNameNode}
            </OverflowTooltip>
          </ItemTitle>
        </ItemTitleWrapper>
        {!item.blocked && (
          <StyledCheckbox
            disabled={checked ? false : disabled}
            checked={checked}
            variant="LIGHT"
            onToggle={() => {
              onSelectDepartment(item.id, item.collapsed);
            }}
          />
        )}
      </Item>
    </ItemWrapper>
  );
};

type DepartmentListItemProps = {
  item: Department;
  disabled: boolean;
};

export const DepartmentListItem: React.FC<DepartmentListItemProps> = ({ item, disabled }) => {
  const dispatch = useAppDispatch();
  const checked = useSelector(selectIsDepartmentSelected(item.id));

  const onSelectDepartment = (id: number) => {
    dispatch(
      changeFilter({
        type: 'DEPARTMENT',
        departmentId: id,
      })
    );
  };

  const onToggleDepartmentCollapsedState = (id: number) => {
    dispatch(toggleDepartmentCollapsedState(id));
  };

  return (
    <DepartmentListItemPure
      checked={checked}
      item={item}
      onToggleDepartmentCollapsedState={onToggleDepartmentCollapsedState}
      onSelectDepartment={onSelectDepartment}
      disabled={disabled}
    />
  );
};
