import {
  selectFiltersQuery,
  selectIsCompareMode,
  selectLifecycleFiltersQuery,
} from 'util/selector/querySelector';
import { selectReportOrCurrentProjectId } from 'util/selector/reportSelector';

import { AppThunk } from 'reduxStore/appStore';
import debounce from 'lodash/debounce';
import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { simpleProjectApi } from 'container/simpleProjectApi';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'shared/utils/redux';
import { SimpleProject } from 'model/SimpleProject';

import { STORE_NAME } from './initialState';

export const fetchSimpleProject = createAsyncThunk<void>(
  `${STORE_NAME}/fetchSimpleProject`,
  async (_, { getState, dispatch }) => {
    const isCompareMode = selectIsCompareMode(getState());

    if (isCompareMode) {
      await dispatch(fetchSimpleProjectCompare());
    } else {
      await dispatch(fetchSimpleProjectCombine());
    }
  },
  { serializeError }
);

// we have to debounce ThunkAction (result of fetchEngagementScore()))
// debouncing ThunkAction creator like fetchEngagementScore will not work
const fetchSimpleProjectDebouncedThunk = debounce(
  fetchSimpleProject(),
  FILTERS_CHANGED_DEBOUNCE_TIMEOUT
);

export const fetchSimpleProjectDebounced = (): AppThunk<unknown> => (dispatch) => {
  dispatch(fetchSimpleProjectDebouncedThunk);
};

export const fetchSimpleProjectCombine = createAsyncThunk<SimpleProject.Combine>(
  `${STORE_NAME}/fetchSimpleProjectCombine`,
  (_, { getState }) => {
    const projectId = selectReportOrCurrentProjectId(getState());
    const isLifecycleKind = selectIsLifecycleProjectKind(getState());
    const query = isLifecycleKind
      ? selectLifecycleFiltersQuery(getState())
      : selectFiltersQuery(getState());

    return simpleProjectApi.combine(projectId, query);
  },
  { serializeError }
);

export const fetchSimpleProjectCompare = createAsyncThunk<SimpleProject.Compare>(
  `${STORE_NAME}/fetchSimpleProjectCompare`,
  (_, { getState }) => {
    const projectId = selectReportOrCurrentProjectId(getState());
    const isLifecycleKind = selectIsLifecycleProjectKind(getState());
    const query = isLifecycleKind
      ? selectLifecycleFiltersQuery(getState())
      : selectFiltersQuery(getState());

    return simpleProjectApi.compare(projectId, query);
  },
  { serializeError }
);
