import React from 'react';

import { TooltipProps as ChartTooltipProps } from 'recharts';
import { ChartTooltipWrapper } from 'component/LifecycleCharts/LifecycleCharts.s';
import { ResponseCountModel } from 'api/dto/ResponseCount.dto';

export const ResponsesChartTooltip: React.FC<ChartTooltipProps<number, string>> = ({
  active,
  payload,
}) => {
  if (active && payload?.length) {
    const { value: responsesNumber, date }: ResponseCountModel = payload[0].payload;

    return (
      <ChartTooltipWrapper date-testid="responsesChartTooltip">
        <span>{`${date}, ${responsesNumber} Response${responsesNumber === 1 ? '' : 's'}`}</span>
      </ChartTooltipWrapper>
    );
  }

  return null;
};
