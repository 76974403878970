import { Box, styled } from '@mui/material';

export const PageContainer = styled(Box)({
  padding: '30px 40px',
  height: 'calc(100vh - 60px)',
});

export const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 2,
  position: 'relative',
});

export const Heading = styled('h2')({
  fontWeight: 300,
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '40px',
  marginTop: '40px',
  textAlign: 'center',
});

export const Description = styled('p')({
  fontWeight: 300,
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  marginTop: '20px',
});

export const SlipUp = styled('img')({
  maxWidth: '650px',
  width: '90%',
  height: 'auto',
  position: 'absolute',
  bottom: '50px',
  left: 0,
  right: 0,
  margin: '0 auto',
});
