export enum PermissionType {
  MatrixAccess = 'feature-matrix-access',
  ImpactOnEngagement = 'feature-impact-on-engagement',
  PercentEmployeeWhoAre = 'feature-percent-employees-who-are',
  ResponseRate = 'feature-response-rate-tab',
  Report = 'feature-reports-tab',
  // This is backwards, it is restriction, not permission. Should be changed in BackEnd and FrontEnd
  RestrictedCrossFiltering = 'feature-restrict-cross-filtering',
  SecureConversation = 'feature-enable-secure-conversations',
  Comment = 'feature-show-comments',
  SentimentAnalysisComments = 'feature-sentiment-analysis-comments',
  SentimentAnalysisTopics = 'feature-sentiment-analysis-topics',
  ShowVsExternalBenchmark = 'feature-show-vs-external-benchmark',
}
