import { isArrayEmpty } from 'util/isEmpty';
import {
  selectCommentsQuery,
  selectFiltersQuery,
  selectLifecycleFiltersQuery,
} from 'util/selector/querySelector';
import {
  selectIsReportView,
  selectReportOrCurrentProjectId,
  selectReportProjectId,
  selectReportQuestionId,
} from 'util/selector/reportSelector';
import { selectQueryString } from 'util/selector/routerSelector';

import {
  selectAdvocacyFilter,
  selectSearchedText,
  selectSelectedOptionId,
  selectSelectedQuestionId,
} from 'reduxStore/comments/selectors';
import { Pagination } from 'model/Pagination';
import { selectCurrentProjectId, selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { fetchWordCloud } from 'reduxStore/wordCloud/asyncActions';
import { queryDto } from 'api/dto/Query.dto';
import { Answer } from 'model/Answer';
import { SentimentStats } from 'model/Sentiment';
import { Question } from 'model/Question';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { commentApi } from 'container/commentApi';
import { serializeError } from 'shared/utils/redux';
import { QuestionAndAnswers } from 'model/QuestionAndAnswers';
import { PaginatedResponse } from 'model/PaginatedResponse';
import { TopicsApi } from 'api/TopicsApi';
import { topicsApi } from 'container/topicsApi';

import {
  setEmptyResolvedCommentsAnswers,
  resetCommentsQuestions,
  resetSelectedQuestion,
  setPagination,
  setSelectedQuestion,
} from './slice';
import { STORE_NAME } from './initialState';

export const fetchCommentsQuestions = createAsyncThunk<Question[]>(
  `${STORE_NAME}/fetchCommentsQuestions`,
  (_, { getState, dispatch }) => {
    const isReport = selectIsReportView(getState());
    const projectId = selectReportOrCurrentProjectId(getState());
    const isLifecycleKind = selectIsLifecycleProjectKind(getState());
    const query = isLifecycleKind
      ? selectLifecycleFiltersQuery(getState())
      : selectFiltersQuery(getState());
    const selectedQuestionId = selectSelectedQuestionId(getState());

    return commentApi.fetchQuestions(projectId, query).then((result) => {
      if (!isArrayEmpty(result)) {
        const containsSelectedQuestion = result.find((q) => q.id === selectedQuestionId);
        if (!containsSelectedQuestion) {
          dispatch(setSelectedQuestion({ id: result[0].id, optionId: result[0].option }));
        }
        isReport ? dispatch(fetchReportAnswers()) : dispatch(fetchCommentAnswers());
      } else {
        dispatch(resetSelectedQuestion());
        dispatch(resetCommentsQuestions());
        dispatch(setEmptyResolvedCommentsAnswers());
      }

      return result;
    });
  },
  { serializeError }
);

export const fetchAllSentimentTopics = createAsyncThunk<TopicsApi.AllTopicsEntry>(
  `${STORE_NAME}/fetchAllSentimentTopics`,
  () => topicsApi.getAllTopics(),
  { serializeError }
);

export const fetchCommentAnswers = createAsyncThunk<
  PaginatedResponse<Answer[]> & { sentimentStats?: SentimentStats | null },
  { page?: number; refetchWordCloud?: boolean } | undefined
>(
  `${STORE_NAME}/fetchCommentAnswers`,
  (
    { page = 1, refetchWordCloud = true } = { page: 1, refetchWordCloud: true },
    { getState, dispatch }
  ) => {
    const projectId = selectCurrentProjectId(getState());
    const questionId = selectSelectedQuestionId(getState());
    const optionId = selectSelectedOptionId(getState());
    const advocacyIds = selectAdvocacyFilter(getState());
    const answerLike = selectSearchedText(getState());

    const query = selectCommentsQuery({ optionId, advocacyIds, page, answerLike })(getState());

    if (!questionId) {
      return Promise.reject(new Error('No question selected to get its answers'));
    }
    return commentApi.fetchAnswers(projectId, questionId, query).then((result) => {
      dispatch(setPagination(result.pagination as Pagination));
      refetchWordCloud && dispatch(fetchWordCloud({ projectId, questionId, query }));

      return result;
    });
  },
  { serializeError }
);

export const fetchReportAnswers = createAsyncThunk<Answer[]>(
  `${STORE_NAME}/fetchReportAnswers`,
  (_, { getState }) => {
    const mockedLengthQuery = 'p[length]=0';

    const queryFromRouter = selectQueryString(getState());
    const filtersQueryString = decodeURI(queryFromRouter).replace(
      /projectId=\d+&|&format=pdf/g,
      ''
    );

    const projectId = selectReportProjectId(getState());
    const questionId = selectReportQuestionId(getState());
    const query = queryDto.build(filtersQueryString, mockedLengthQuery);
    return commentApi.fetchAnswers(projectId, questionId, query).then((result) => result.data);
  },
  { serializeError }
);

export const fetchAllComments = createAsyncThunk<
  QuestionAndAnswers[],
  { projectId: number; query: string }
>(
  `${STORE_NAME}/fetchAllComments`,
  async ({ projectId, query }) => {
    return commentApi.fetchAllComments(projectId, query);
  },
  { serializeError }
);
