/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { getSelectedLanguage } from 'shared/hooks/useLocalize';

import { AISummaryVideo, AISummaryVideoContainer } from './AISummaryVideoWidget.s';

type AISummaryVideoWidget = {
  projectId: number;
};

export const AISummaryVideoWidget: React.FC<AISummaryVideoWidget> = ({ projectId }) => {
  const path = `https://workbuzz-ai-summaries.s3.eu-west-1.amazonaws.com/${projectId}`;
  const captionLanguages = ['en', 'es'];

  return (
    <AISummaryVideoContainer>
      <AISummaryVideo crossOrigin="anonymous" controls controlsList="nofullscreen nodownload">
        <source src={`${path}/video.mp4`} type="video/mp4" />
        {captionLanguages.map((language) => (
          <track
            key={language}
            default={language === getSelectedLanguage()}
            kind="captions"
            srcLang={language}
            src={`${path}/captions.${language}.vtt`}
          />
        ))}
      </AISummaryVideo>
    </AISummaryVideoContainer>
  );
};
