import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Cell, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis, LabelList } from 'recharts';
import { Topic, TopicKeys } from 'model/Topics';
import {
  SentimentChartWrapper,
  SentimentGraphVerticalLegend,
  SentimentGraphWrapper,
  Tooltip as WBTooltip,
} from 'view/TopicsPage/Sentiment/Sentiment.s';
import { selectTopics } from 'reduxStore/topics/selectors';
import { SentimentLabel } from 'view/TopicsPage/Sentiment/SentimentLabel';
import { ChartData, topicsToChartData } from 'view/TopicsPage/Sentiment/sentiment.utils';
import { SentimentLegend } from 'view/TopicsPage/Sentiment/SentimentLegend';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { Typography } from '@mui/material';
import { BOULDER, IRON } from 'app/theme/colors';

export type SentimentGraphPureProps = {
  topics: Topic[];
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active: boolean;
  payload: { name: string; value: string }[];
}) => {
  const format = (name: string, value: string | number) => {
    switch (name) {
      case TopicKeys.score:
        return `Sentiment score: ${value}`;
      case TopicKeys.commentCount:
        return `Comments: ${value}`;
      default:
        console.warn('Unsupported value in formatter');
        return `${name}: ${value}`;
    }
  };

  if (active && payload?.length) {
    return (
      <WBTooltip>
        {payload.map((e, i) => {
          if (e.name === TopicKeys.name) {
            return (
              <Typography mb={1} variant="h6" key={i}>
                {e.value}
              </Typography>
            );
          }
          return (
            <Typography mb={i + 1 !== payload.length ? 1 : 0} variant="body1" key={i}>
              {format(e.name, e.value)}
            </Typography>
          );
        })}
      </WBTooltip>
    );
  }

  return null;
};

export const SentimentGraphPure: React.FC<SentimentGraphPureProps> = ({ topics }) => {
  const { domain, list, range, scoreDomain } = useMemo<ChartData>(() => topicsToChartData(topics), [
    topics,
  ]);

  return (
    <ScrollBar maxWidth={1000} overflow="horizontal" float="none" light>
      <SentimentGraphWrapper data-testid="sentimentGraph">
        <SentimentGraphVerticalLegend>
          <SentimentLegend />
        </SentimentGraphVerticalLegend>
        <SentimentChartWrapper>
          <ScatterChart
            width={900}
            height={500}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 0,
            }}
          >
            <XAxis
              axisLine={{ stroke: IRON }}
              stroke={BOULDER}
              strokeWidth={3}
              type="category"
              dataKey={TopicKeys.name}
              label={{
                value: 'Topics (A-Z)',
                fill: BOULDER,
              }}
              tick={false}
              padding={{ left: 25 }}
            />
            <YAxis
              axisLine={{ stroke: IRON }}
              tickLine={{ stroke: IRON }}
              stroke={BOULDER}
              strokeWidth={3}
              type="number"
              dataKey={TopicKeys.score}
              domain={scoreDomain}
              scale="linear"
              padding={{ bottom: 60 }}
            />
            <ZAxis type="number" dataKey={TopicKeys.commentCount} domain={domain} range={range} />
            <Tooltip
              cursor={{ strokeDasharray: '6 6', stroke: IRON, strokeWidth: 3 }}
              content={<CustomTooltip active={false} payload={[]} />}
            />
            <Scatter name="Topics Sentiment" data={list}>
              {list.map((item, index) => (
                <Cell key={`cell-${index}`} fill={item.color.toHexString()} />
              ))}
              <LabelList dataKey="label" position="bottom" content={<SentimentLabel />} />
            </Scatter>
          </ScatterChart>
        </SentimentChartWrapper>
      </SentimentGraphWrapper>
    </ScrollBar>
  );
};

export const SentimentGraph: React.FC = () => {
  const score = useSelector(selectTopics);

  return <SentimentGraphPure topics={score} />;
};
