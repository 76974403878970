import React from 'react';
import { useSelector } from 'react-redux';

import { selectQueryString } from 'util/selector/routerSelector';

import { PageWrapperHeader } from 'component/PageHeader/PageHeader.s';
import { AppRoute } from 'app/route/app';
import {
  Divider,
  NavLink,
  navLinkContainerSx,
} from 'view/EngagementDriverByCategoryPage/CategoryHeader/CategoryHeader.s';
import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { downloadCategoryReport } from 'reduxStore/report/asyncActions';
import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { routeWithQuery } from 'reduxStore/router/asyncActions';
import { PageTitle } from 'component/PageHeaderTitle/PageTitle';
import { DocumentType } from 'model/DocumentType';
import { DocumentIcon } from 'component/DocumentIcon/DocumentIcon';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import { getReportActionNameByReportFormat } from 'reduxStore/report/getReportActionName';
import { Typography } from '@mui/material';
import { selectShouldShowDownloadButton } from 'reduxStore/filtering/selectors';

export type CategoryHeaderProps = {
  categoryName?: string;
  header?: string | null;
  isDownloadDisabled?: boolean;
  style?: any;
};

export type CategoryHeaderPureProps = {
  currentProjectId: string;
  query: any;
  isExcelDownloadPending: boolean;
  isPdfDownloadPending: boolean;
  showPDF: boolean;
  onReportDownload: (type: ReportFormatType) => void;
  onLinkClick: (id: string) => void;
} & CategoryHeaderProps;

export const CategoryHeaderPure: React.FC<CategoryHeaderPureProps> = (props) => {
  return (
    <PageWrapperHeader data-testid="categoryHeader" style={{ ...props.style }}>
      <div data-testid="categoryHeaderNavigation">
        {props.categoryName ? (
          <Typography sx={navLinkContainerSx} variant="body1">
            <NavLink
              data-testid="summaryLink"
              onClick={() => props.onLinkClick(props.currentProjectId)}
            >
              Summary
            </NavLink>
            <Divider> / </Divider>
            {props.categoryName}
          </Typography>
        ) : props.header ? (
          <PageTitle title={props.header} />
        ) : (
          ''
        )}
      </div>
      {!props.isDownloadDisabled && (
        <Dropdown label="Download Report" data-testid="downloadReportDropdown">
          <DropdownItem
            data-testid="downloadReportExcel"
            onClick={() => props.onReportDownload(ReportFormat.Excel)}
          >
            <DocumentIcon
              type={DocumentType.Excel}
              isDownloadPending={props.isExcelDownloadPending}
            />
            Excel
          </DropdownItem>
          {props.showPDF && (
            <DropdownItem
              data-testid="downloadReportPDF"
              onClick={() => props.onReportDownload(ReportFormat.PDF)}
            >
              <DocumentIcon
                type={DocumentType.PDF}
                isDownloadPending={props.isPdfDownloadPending}
              />
              PDF
            </DropdownItem>
          )}
        </Dropdown>
      )}
    </PageWrapperHeader>
  );
};

export const CategoryHeader: React.FC<CategoryHeaderProps> = (props) => {
  const dispatch = useAppDispatch();

  const currentProjectId = String(useSelector(selectCurrentProjectId));
  const query = useSelector(selectQueryString);
  const isExcelDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadCategoryReport, { type: ReportFormat.Excel })
    )
  );
  const isPdfDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadCategoryReport, { type: ReportFormat.PDF })
    )
  );

  const onReportDownload = (type: ReportFormatType) => dispatch(downloadCategoryReport({ type }));
  const onLinkClick = (id: string) =>
    dispatch(routeWithQuery({ pattern: AppRoute.Home.SUMMARY, params: { id: id } }));

  const shouldShowPDFandPPT = useSelector(selectShouldShowDownloadButton);

  return (
    <CategoryHeaderPure
      {...props}
      currentProjectId={currentProjectId}
      query={query}
      isExcelDownloadPending={isExcelDownloadPending}
      isPdfDownloadPending={isPdfDownloadPending}
      showPDF={shouldShowPDFandPPT}
      onReportDownload={onReportDownload}
      onLinkClick={onLinkClick}
    />
  );
};
