import { styled } from '@mui/material';
import { ORANGE_TEXT } from 'app/theme/colors';

export const ImpactOnEngagementWrapper = styled('div')({
  textAlign: 'left',
});

export const ImpactOnEngagementLink = styled('span')({
  textDecoration: 'underline',
  color: ORANGE_TEXT,
  '&:hover': {
    cursor: 'pointer',
  },
});
