import { styled } from '@mui/material';
import { WHITE } from 'app/theme/colors';

export const TopNavBarWrapper = styled('header')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    height: 'auto',
    position: 'relative',
  },
  height: '96px',
  backgroundColor: WHITE,
}));

export const Menu = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 25px',
  height: '96px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'auto',
    marginTop: '20px',
  },
}));

export const NavigationMenuItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  flex: '0 0 auto',
  width: '200px',
  marginTop: '5px',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '16px',
  },
}));

export const Logo = styled('img')({
  width: 200,
  marginTop: 5,
});

export const TopDropdownWrapper = styled('div')({
  flex: '0 0 auto',
});
