import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ModeType } from 'register/ModeType';
import { fetchSimpleProjectCompare } from 'reduxStore/simpleProject/asyncActions';
import {
  fetchEngagementScoreCombine,
  fetchEngagementScoreCompare,
} from 'reduxStore/engagementDrivers/asyncActions';
import {
  fetchInsightsCompared,
  fetchInsightsLifecycleCompared,
} from 'reduxStore/insights/asyncActions';
import { fetchOverallCombine, fetchOverallCompare } from 'reduxStore/overallSurvey/asyncActions';

import { VisualSettingsState, STORE_NAME } from './initialState';

export const visualSettingsSlice = createSlice({
  name: STORE_NAME,
  initialState: VisualSettingsState.INITIAL_DOMAIN,
  reducers: {
    clearVisualSettings(state) {
      return { ...VisualSettingsState.INITIAL_DOMAIN, preferredMode: state.preferredMode };
    },
    setIsClearingAllFilters(state, action: PayloadAction<Draft<boolean>>) {
      state.isClearingAllFilters = action.payload;
    },
    setPreferredMode(state, action: PayloadAction<Draft<ModeType | null>>) {
      state.preferredMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // setBenchmark
      .addCase(fetchEngagementScoreCombine.fulfilled, (state, action) => {
        state.benchmark = action.payload.summary?.benchmark || state.benchmark;
      })
      // setCombineModeLock
      .addMatcher(
        (action) => {
          return [
            fetchSimpleProjectCompare.fulfilled.type,
            fetchEngagementScoreCompare.fulfilled.type,
            fetchInsightsCompared.fulfilled.type,
            fetchInsightsLifecycleCompared.fulfilled.type,
            fetchOverallCompare.fulfilled.type,
          ].includes(action.type);
        },
        (state, action) => {
          state.isCombineModeLock = action.payload.isCombineModeLock ?? false;
        }
      )
      // setShowImpactOnEngagement
      .addMatcher(
        (action) => {
          return [
            fetchEngagementScoreCombine.fulfilled.type,
            fetchEngagementScoreCompare.fulfilled.type,
            fetchOverallCombine.fulfilled.type,
            fetchOverallCompare.fulfilled.type,
          ].includes(action.type);
        },
        (state, action) => {
          state.showImpactOnEngagement =
            action.payload.showImpactOnEngagement || state.showImpactOnEngagement;
        }
      )
      // setChartLabel
      .addMatcher(
        (action) => {
          return [
            fetchEngagementScoreCombine.fulfilled.type,
            fetchEngagementScoreCompare.fulfilled.type,
          ].includes(action.type);
        },
        (state, action) => {
          state.chartLabel = action.payload.chartLabel || VisualSettingsState.INITIAL_CHART_LABEL;
        }
      );
  },
});

export const {
  clearVisualSettings,
  setIsClearingAllFilters,
  setPreferredMode,
} = visualSettingsSlice.actions;
