import { InsightsOptionDto, InsightsOptionModel } from 'api/dto/new/InsightsOption.dto';
import {
  InsightsByGroupDto,
  InsightsByGroupModel,
  mapInsightsByGroupDtoToModel,
} from 'api/dto/new/InsightsCompared/InsightsByGroup.dto';
import { RespondentProtectionEnumNullable } from 'model/RespondentProtection';

export type InsightsAnswerDto = Readonly<{
  option: InsightsOptionDto;
  byGroup: Readonly<Record<string, InsightsByGroupDto>>;
}>;

export type InsightsAnswerModel = Readonly<{
  option: InsightsOptionModel;
  byGroup: Readonly<Record<string, InsightsByGroupModel>>;
}>;

export const mapInsightsAnswerDtoToModel = (
  dto: InsightsAnswerDto,
  protections: Record<string | number, RespondentProtectionEnumNullable>
): InsightsAnswerModel => ({
  option: dto.option,
  byGroup: Object.fromEntries(
    Object.entries(dto.byGroup).map(([key, byGroupDto]) => [
      key,
      mapInsightsByGroupDtoToModel(byGroupDto, protections[key]),
    ])
  ),
});
