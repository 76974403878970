import React from 'react';
import { useSelector } from 'react-redux';

import { ReportType } from 'model/ReportType';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import {
  selectInsightsCombined,
  selectInsightsCombinedResource,
} from 'reduxStore/insights/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { fetchInsightsCombined } from 'reduxStore/insights/asyncActions';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';

export const InsightsCombinedReportView: React.FC = () => {
  const insightsCombineResource = useSelector(selectInsightsCombinedResource);
  const insightsCombine = useSelector(selectInsightsCombined);

  useFetchInsights(fetchInsightsCombined);

  return (
    <ResourceLoader resource={insightsCombineResource}>
      <ReportContent
        title="Insights"
        reportType={ReportType.Insights}
        component={
          <>
            {insightsCombine?.data?.map((item, index) => (
              <InsightsCombinedQuestion isReportView key={index} item={item} />
            ))}
          </>
        }
      />
    </ResourceLoader>
  );
};
