import { styled } from '@mui/material';
import { SONIC_SILVER, WORKBUZZ_BLUE, WORKBUZZ_ORANGE } from 'app/theme/colors';
import reportImage from 'app/asset/reportImage.png';
import { ReportType } from 'model/ReportType';

type TitlePageWrapperProps = {
  reportType?: string;
};

export const TitlePageWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'reportType',
})(
  {
    '@media print': {
      pageBreakAfter: 'always',
    },
  },
  (props: TitlePageWrapperProps) => ({
    width: props.reportType === ReportType.Comments ? '19cm' : '1024px',
    height: props.reportType === ReportType.Comments ? '25cm' : '16cm',
  })
);

export const LogoWrapper = styled('div')({
  flex: '40% 0 1',
});

export const LogosRow = styled('h2')({
  marginTop: '60px',
  marginBottom: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const MainContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const TitleContainer = styled('div')({
  flex: '40% 0 0',

  '@media print and (orientation: portrait)': {
    flex: '100% 1 1',
  },
});

export const OrangeTitle = styled('h2')({
  fontSize: '34px',
  fontWeight: 800,
  color: WORKBUZZ_ORANGE,
  marginBottom: '24px',
});

export const DepartmentTitle = styled('h2')({
  fontSize: '34px',
  fontWeight: 800,
  color: WORKBUZZ_BLUE,
  marginBottom: '24px',
});

export const BackgroundWrapper = styled('div')({
  position: 'absolute',
  bottom: 0,
  right: 0,
  height: '500px',
  width: '60%',
  backgroundImage: `url(${reportImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center bottom',
  backgroundSize: 'contain',

  '@media print and (orientation: portrait)': {
    margin: 'auto',
    width: '70%',
    height: '500px',
    left: 0,
  },
});

export const CommentsMessage = styled('div')({
  color: SONIC_SILVER,
  fontSize: '14px',
  lineHeight: '20px',
});

export const ReportImage = styled('img')({
  maxWidth: 200,
  maxHeight: 200,
});
