import { useSelector } from 'react-redux';

import { selectHasMatch } from 'util/selector/routerSelector';
import { selectIsTopicsAnalysisEnabled } from 'util/selector/sentimentSelector';

import { compilePath } from 'router/compilePath';
import { AppRoute } from 'app/route/app';
import {
  selectCurrentProjectId,
  selectIsLifecycleProjectKind,
  selectSentimentIsLoaded,
} from 'reduxStore/project/selectors';
import {
  selectHasReportPagePermission,
  selectHasSummaryPermission,
  selectIsCircleAdmin,
} from 'reduxStore/user/selectors';

export type RibbonNavBarItem = {
  visible: boolean;
  label: string;
  url: string;
};

export const useGetVisibleRibbonNavBarItems = (): RibbonNavBarItem[] => {
  const isHomePath = useSelector(selectHasMatch(AppRoute.HOME_PATTERN));
  const isAnalysisPath = useSelector(selectHasMatch(AppRoute.COMMENTS, AppRoute.TOPICS));
  const isSentimentLoaded = useSelector(selectSentimentIsLoaded);
  const showComments = useSelector(selectIsTopicsAnalysisEnabled);
  const showTopics = useSelector(selectIsTopicsAnalysisEnabled);
  const hasSummaryPermission = useSelector(selectHasSummaryPermission);
  const hasReportsPagePermission = useSelector(selectHasReportPagePermission);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);
  const isCircleAdmin = useSelector(selectIsCircleAdmin);

  return [
    {
      label: 'Summary',
      url: compilePath(AppRoute.Home.SUMMARY, { id: currentProjectId }),
      visible: isHomePath && hasSummaryPermission && !isLifecycleProjectKind && !isCircleAdmin,
    },
    {
      label: 'Reports',
      url: compilePath(AppRoute.Home.REPORTS, { id: currentProjectId }),
      visible: isHomePath && hasReportsPagePermission,
    },
    {
      label: 'Comments',
      url: compilePath(AppRoute.COMMENTS, { id: currentProjectId }),
      visible: isAnalysisPath && showComments && isSentimentLoaded,
    },
    {
      label: 'Topics',
      url: compilePath(AppRoute.TOPICS, { id: currentProjectId }),
      visible: isAnalysisPath && showTopics && isSentimentLoaded,
    },
  ].filter((item) => item.visible);
};
