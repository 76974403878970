import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { scrollTop } from 'util/scrollTop';
import { InfoIcon } from 'util/withTooltip';

import {
  ComponentLabel,
  ComponentWrapper,
  SummaryWidgetsContent,
  SummaryWidgetsWrapper,
} from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { selectProjectLabelByProjectId, selectPulsesList } from 'reduxStore/summary/selectors';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { SummaryInfo } from 'view/SummaryPage/SummaryInfo/SummaryInfo';
import { AppRoute } from 'app/route/app';
import { routeWithQuery } from 'reduxStore/router/asyncActions';
import { ResponseRateWidget } from 'view/ReportView/SummaryWidgetReport/ResponseRateWidget';
import { setEngagementDriverByCategory } from 'reduxStore/engagementDrivers/asyncActions';
import {
  selectEngagementDriverProjectScore,
  selectShouldShowEngagementScore,
} from 'reduxStore/engagementDrivers/selectors';
import { ProjectKindLabel } from 'component/ProjectKindLabel/ProjectKindLabel';
import { PulseSurveyBarChart } from 'component/PulseSurveyBarChart/PulseSurveyBarChart';
import { ProjectSummary, ProjectSummarySelected } from 'model/ProjectSummary';
import { ResponseRateInfo } from 'view/ReportView/SummaryWidgetReport/ResponseRateInfo';
import { PercentageBarSize } from 'component/PercentageBar/PercentageBarSize';
import { useAppDispatch } from 'reduxStore/appStore';
import { fetchPulsesSummary } from 'reduxStore/summary/asyncActions';
import Tooltip from '@mui/material/Tooltip';
import { useFeatureValue } from '@growthbook/growthbook-react';

import { AISummaryVideoWidget } from '../AISummaryVideoWidget/AISummaryVideoWidget';
import { AISummaryVideoWidgetWrapper } from '../AISummaryVideoWidget/AISummaryVideoWidget.s';

export type PulseSummaryCombineWidgetsProps = {
  currentProjectId: number;
  showEngagementScore: boolean;
  pulseSummary: ProjectSummarySelected[];
  projectLabel: string;
  currentProjectEngagementScore: number | undefined;
  onProjectLabelClick: (projectId: number, categoryId: number) => void;
};

export const PulseSummaryCombineWidgetsPure: React.FC<PulseSummaryCombineWidgetsProps> = ({
  currentProjectId,
  showEngagementScore,
  pulseSummary,
  projectLabel,
  currentProjectEngagementScore,
  onProjectLabelClick,
}) => {
  const selectedPulseTemplate = pulseSummary.find(({ selected }) => !!selected);
  const maxScore = (selectedPulseTemplate || {}).score || currentProjectEngagementScore;
  const aiSummaries = useFeatureValue('ai-summaries', '').split(',');

  return (
    <SummaryWidgetsWrapper data-testid="pulseSummaryCombineWidgets">
      <ScrollBar overflow="horizontal" light>
        <SummaryWidgetsContent>
          {
            <>
              {aiSummaries.includes(`${currentProjectId}`) ? (
                <AISummaryVideoWidgetWrapper>
                  <AISummaryVideoWidget projectId={currentProjectId} />
                </AISummaryVideoWidgetWrapper>
              ) : (
                <ResponseRateWidget
                  projectId={currentProjectId}
                  percentageBarSize={PercentageBarSize.BIG}
                  icon={
                    <Tooltip
                      id="ResponseRateTooltip"
                      arrow
                      title={<ResponseRateInfo projectLabel={projectLabel} />}
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                    >
                      <InfoIcon />
                    </Tooltip>
                  }
                />
              )}
              {ProjectSummary.showPulseSummaryGraph(pulseSummary) && (
                <ComponentWrapper sx={{ width: '100%' }}>
                  {maxScore && (
                    <ComponentLabel adjustToLeft marginBottom={20}>
                      <ProjectKindLabel
                        name={`Engagement Score: ${maxScore}`}
                        onClick={() => onProjectLabelClick(currentProjectId, 10)}
                        isClickable
                      />
                      <Tooltip
                        id="EngagementTooltip"
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        title={
                          <SummaryInfo
                            overrideProjectKind={ProjectSummary.getOverrideProjectKind(
                              showEngagementScore
                            )}
                          />
                        }
                      >
                        <InfoIcon />
                      </Tooltip>
                    </ComponentLabel>
                  )}
                  <PulseSurveyBarChart data={pulseSummary} />
                </ComponentWrapper>
              )}
            </>
          }
        </SummaryWidgetsContent>
      </ScrollBar>
    </SummaryWidgetsWrapper>
  );
};

export const PulseSummaryCombineWidgets: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentPulseOrTemplateId = useSelector(selectCurrentProjectId);
  const pulses = useSelector(selectPulsesList);
  const pulseSummarySelected = ProjectSummary.markSelected(currentPulseOrTemplateId, pulses || []);
  const showEngagementScore = useSelector(selectShouldShowEngagementScore);
  const projectLabel = useSelector(selectProjectLabelByProjectId(currentPulseOrTemplateId));
  const currentProjectEngagementScore = useSelector(selectEngagementDriverProjectScore);
  const onProjectLabelClick = (projectId: number, categoryId: number) => {
    dispatch(
      routeWithQuery({
        pattern: AppRoute.Home.Summary.REPORT,
        params: { id: projectId, categoryId: categoryId },
      })
    );
    dispatch(setEngagementDriverByCategory());
    scrollTop();
  };

  useEffect(() => {
    if (currentPulseOrTemplateId) {
      dispatch(fetchPulsesSummary());
    }
  }, [dispatch, currentPulseOrTemplateId]);

  return (
    <PulseSummaryCombineWidgetsPure
      currentProjectId={currentPulseOrTemplateId}
      showEngagementScore={showEngagementScore}
      pulseSummary={pulseSummarySelected}
      projectLabel={projectLabel}
      currentProjectEngagementScore={currentProjectEngagementScore}
      onProjectLabelClick={onProjectLabelClick}
    />
  );
};
