import React from 'react';
import { useSelector } from 'react-redux';

import { selectSummaryCategoryViewMode } from 'util/selector/querySelector';

import { ReportEngagementDriver } from 'model/ReportEngagamentDriver';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { ReportSummaryWidgets } from 'view/ReportView/SummaryWidgetReport/ReportSummaryWidgets';
import { EngagementDriversCombine } from 'view/EngagementDriversView/EngagementDriversCombine';
import { EngagementDriverByCategoryCombine } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/EngagementDriverByCategoryCombine';
import {
  selectEngagementDriversCombineList,
  selectTrendLabel,
} from 'reduxStore/engagementDrivers/selectors';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import { selectProjectClosedAt, selectProjectKind } from 'reduxStore/project/selectors';
import { Moment } from 'moment';
import { SummaryCategoryViewMode } from 'register/ModeType';

export type OverallSurveyReportCombinePureProps = {
  engagementDrivers: ReportEngagementDriver[];
  isAdmin: boolean;
  trendLabel: string;
  projectKind: ProjectKind;
  closedAt?: Moment;
  categoryMode: SummaryCategoryViewMode;
};

export const OverallSurveyReportCombinePure: React.FC<OverallSurveyReportCombinePureProps> = (
  props
) => {
  return (
    <div>
      <ReportContent title="Summary" component={<ReportSummaryWidgets isReportView />} />
      <ReportContent
        title={Project.getEngagementDriversTitle({
          kind: props.projectKind,
          closedAt: props.closedAt,
        })}
        component={<EngagementDriversCombine isReportView />}
      />
      {props.categoryMode === SummaryCategoryViewMode.Category && props.engagementDrivers ? (
        <div>
          {props.engagementDrivers.map((engagementDriver, index) => (
            <ReportContent
              key={index}
              title={engagementDriver.category!.name + ' : ' + engagementDriver.score}
              component={
                <EngagementDriverByCategoryCombine
                  key={index}
                  categoryTitle={engagementDriver.category!.name}
                  categoryId={engagementDriver.category!.id}
                  score={engagementDriver.score}
                  categoryData={engagementDriver.details}
                  trends={engagementDriver.trends}
                  benchmark={engagementDriver.benchmark}
                  isAdmin={props.isAdmin}
                  trendLabel={props.trendLabel}
                  isReportView
                />
              }
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export const OverallSurveyReportCombine: React.FC = () => {
  const engagementDrivers = useSelector(selectEngagementDriversCombineList);
  const isAdmin = useSelector(selectIsAdmin);
  const trendLabel = useSelector(selectTrendLabel);
  const projectKind = useSelector(selectProjectKind);
  const closedAt = useSelector(selectProjectClosedAt);
  const categoryMode = useSelector(selectSummaryCategoryViewMode);

  return (
    <OverallSurveyReportCombinePure
      engagementDrivers={engagementDrivers}
      isAdmin={isAdmin}
      trendLabel={trendLabel}
      projectKind={projectKind}
      closedAt={closedAt}
      categoryMode={categoryMode}
    />
  );
};
