import { convertObjectToArray } from 'util/convertObjectToArray';

import { SummaryApi } from 'api/SummaryApi';
import { Summary } from 'model/Summary';
import { BenchmarkApi } from 'api/BenchmarkApi';

import { TrendMapper } from './TrendMapper';
import { ResponseRateMapper } from './ResponseRateMapper';
import { CompareGroupMapper } from './CompareGroupMapper';
import { ProjectSummaryMapper } from './ProjectSummaryMapper';
import { BenchmarkMapper } from './BenchmarkMapper';
import { ScoreMapper } from './ScoreMapper';

export class SummaryMapper {
  constructor(
    private trendMapper: TrendMapper,
    private responseRateMapper: ResponseRateMapper,
    private groupMapper: CompareGroupMapper,
    private projectSummaryMapper: ProjectSummaryMapper
  ) {}

  deserializeCombine(entry: SummaryApi.EntryCombine): Summary.Combine {
    return {
      score: ScoreMapper.deserialize(entry.score),
      benchmark: BenchmarkMapper.deserialize(entry.benchmark) || null,
      trends: entry.trends
        ? entry.trends.map((trend) => this.trendMapper.deserialize(trend))
        : undefined,
      responseRate: this.responseRateMapper.deserialize(entry.responseRate),
    };
  }

  deserializeCompare(
    entry: SummaryApi.EntryCompare,
    groups: { [key: number]: string },
    protections: { [key: number]: boolean }
  ): Summary.Compare {
    return {
      name: entry.label,
      groups: convertObjectToArray(entry.byGroup).map((group) =>
        this.groupMapper.deserialize(group, groups, protections)
      ),
    };
  }

  deserializePulseSummary(
    aggregatedBenchmark: BenchmarkApi.Entry,
    projectSummary: SummaryApi.ProjectSummary[]
  ): Summary.Pulses {
    return {
      list: convertObjectToArray(projectSummary).map(this.projectSummaryMapper.deserializeCombine),
      aggregatedBenchmark: BenchmarkMapper.deserialize(aggregatedBenchmark),
    };
  }
}
