import React from 'react';

import { Tag, TagVariantEnum } from 'component/Tags/Tag';
import { AdvocacyModel, AdvocacyModelEnum } from 'api/dto/Advocacy.dto';

export type AdvocacyTagProps = {
  advocacy: AdvocacyModel;
  companyName: string;
};

const mapAdvocacyPropsToLabel = ({ advocacy, companyName }: AdvocacyTagProps): string => {
  switch (advocacy) {
    case AdvocacyModelEnum.WouldRecommend:
      return `Would recommend ${companyName}`;
    case AdvocacyModelEnum.Unsure:
      return 'Unsure';
    case AdvocacyModelEnum.WouldNotRecommend:
      return `Would not recommend ${companyName}`;
    default:
      throw new Error('No Advocacy tag matched');
  }
};

const mapAdvocacyPropsToTagVariant = ({ advocacy }: AdvocacyTagProps): TagVariantEnum => {
  switch (advocacy) {
    case AdvocacyModelEnum.WouldRecommend:
      return TagVariantEnum.Positive;
    case AdvocacyModelEnum.Unsure:
      return TagVariantEnum.Neutral;
    case AdvocacyModelEnum.WouldNotRecommend:
      return TagVariantEnum.Negative;
    default:
      throw new Error('No Advocacy tag matched');
  }
};

export const AdvocacyTag: React.FC<AdvocacyTagProps> = (props) =>
  props.advocacy ? (
    <Tag variant={mapAdvocacyPropsToTagVariant(props)}>
      <span data-testid="advocacyTagSpan">{mapAdvocacyPropsToLabel(props)}</span>
    </Tag>
  ) : null;
