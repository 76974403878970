import React from 'react';
import { useSelector } from 'react-redux';

import {
  AnswerItem,
  AnswerItemWrapper,
  Block,
  ContentWrapper,
} from 'view/CommentsPage/CommentsPage.s';
import { Answer, isLifecycleAnswer } from 'model/Answer';
import { SentimentLabels } from 'view/CommentsPage/SentimentLabels';
import { AdvocacyTag } from 'component/Tags/AdvocacyTag';
import { EmployeeInfo } from 'component/EmployeeInfo/EmployeeInfo';
import {
  selectCurrentCompanyName,
  selectIsLifecycleProjectKind,
} from 'reduxStore/project/selectors';
import { Typography } from '@mui/material';

export namespace CommentAnswerItem {
  export type OwnProps = {
    item: Answer;
    isSentimentLoaded?: boolean;
    isReportView?: boolean;
  };
  export type Props = OwnProps;
}

export const CommentAnswerItem: React.FC<CommentAnswerItem.Props> = ({
  item,
  isReportView,
  isSentimentLoaded,
}) => {
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);
  const companyName = useSelector(selectCurrentCompanyName);

  if (isLifecycleProjectKind && isLifecycleAnswer(item)) {
    return (
      <>
        <AnswerItemWrapper
          sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
          data-testid="employeeAnswer"
        >
          <EmployeeInfo respondent={item.respondent} />
          <AnswerItem data-testid={`commentAnswerItem[${item.id}]`}>
            {!isReportView && <Block />}
            <ContentWrapper isReportView={isReportView}>
              <Typography variant="body1" data-cy="comment">
                {item.answer}
              </Typography>
              <AdvocacyTag advocacy={item.respondent.advocacy} companyName={companyName} />
            </ContentWrapper>
          </AnswerItem>
        </AnswerItemWrapper>
      </>
    );
  }

  return (
    <AnswerItemWrapper>
      <AnswerItem data-testid={`commentAnswerItem[${item.id}]`}>
        {!isReportView && <Block />}
        <ContentWrapper isReportView={isReportView}>
          <Typography variant="body1">{item.answer}</Typography>
          {isSentimentLoaded && item.sentiment && <SentimentLabels data={item.sentiment} />}
        </ContentWrapper>
      </AnswerItem>
    </AnswerItemWrapper>
  );
};
