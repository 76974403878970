import React, { CSSProperties } from 'react';

import { Button } from 'component/NavButton/NavButton.s';

export type NavButtonProps = {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: CSSProperties;
  color?: string;
  backgroundColor?: string;
  fontWeight?: any;
  'data-testid'?: string;
};

export const NavButton: React.FC<NavButtonProps> = (props) => (
  <Button
    onClick={props.onClick}
    style={props.style}
    color={props.color}
    backgroundColor={props.backgroundColor}
    fontWeight={props.fontWeight}
    data-testid={props['data-testid']}
  >
    {props.label}
  </Button>
);
