import { flatten } from 'util/flatten';

import { Department } from 'model/Department';

export type FlatDepartments = Department & { level: number };

const handleItem = (
  item: Department,
  handleChildren = false,
  result: any[] = [],
  level: number = 0
): FlatDepartments[] => {
  const flattenItem = {
    ...item,
    level,
  };
  result.push(flattenItem);

  if (!item.collapsed || handleChildren) {
    item.children.forEach((child) => {
      handleItem(child, handleChildren, result, level + 1);
    });
  }

  return result;
};

export const treeToFlatList = (tree: Department[]) => flatten(tree.map((item) => handleItem(item)));
export const treeToFlatListForSelector = (tree: Department[]) =>
  flatten(tree.map((item) => handleItem(item, true)));
