import React from 'react';
import { useSelector } from 'react-redux';

import { ReportType } from 'model/ReportType';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import {
  selectInsightsLifecycleCombined,
  selectInsightsLifecycleCombinedNumberOfRespondents,
  selectInsightsLifecycleCombinedResource,
} from 'reduxStore/insights/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { fetchInsightsLifecycleCombined } from 'reduxStore/insights/asyncActions';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';
import { ReportParamsSelectionMessage } from 'view/ReportView/components/ReportParamsSelectionMessage';

export const InsightsLifecycleCombinedReportView: React.FC = () => {
  const insightsLifecycleCombineResource = useSelector(selectInsightsLifecycleCombinedResource);
  const insightsLifecycleCombine = useSelector(selectInsightsLifecycleCombined);
  const insightsLifecycleCombineEmployeesNumber = useSelector(
    selectInsightsLifecycleCombinedNumberOfRespondents
  );

  useFetchInsights(fetchInsightsLifecycleCombined);

  return (
    <ResourceLoader resource={insightsLifecycleCombineResource}>
      <ReportContent
        title="Insights"
        reportType={ReportType.Insights}
        component={
          <>
            <ReportParamsSelectionMessage
              responsesNumber={insightsLifecycleCombineEmployeesNumber}
            />
            {insightsLifecycleCombine?.data.map((item, index) => (
              <InsightsCombinedQuestion
                isReportView
                isLifecycleProjectKind
                key={index}
                item={item}
              />
            ))}
          </>
        }
      />
    </ResourceLoader>
  );
};
