import React, { CSSProperties } from 'react';

import { DocumentType } from 'model/DocumentType';
import ExcelIcon from 'react-icons/lib/fa/file-excel-o';
import PdfIcon from 'react-icons/lib/fa/file-pdf-o';
import PowerPointIcon from 'react-icons/lib/fa/file-powerpoint-o';
import { DARK_ORANGE } from 'app/theme/colors';
import { LoadingIcon } from 'component/PageHeader/PageHeader.s';

export type DocumentIconProps = {
  type: number;
  isDownloadPending: boolean;
};

export const DocumentIcon: React.FC<DocumentIconProps> = ({ type, isDownloadPending }) => {
  if (isDownloadPending) {
    return <LoadingIcon type="spin" color={DARK_ORANGE} height={25} width={25} />;
  }

  const iconStyle: CSSProperties = { fontSize: '18px', marginRight: '5px', minWidth: '18px' };
  return (
    <>
      {type === DocumentType.Excel && <ExcelIcon style={iconStyle} />}
      {type === DocumentType.PDF && <PdfIcon style={iconStyle} />}
      {type === DocumentType.PowerPoint && <PowerPointIcon style={iconStyle} />}
    </>
  );
};
