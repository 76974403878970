import React from 'react';
import { useSelector } from 'react-redux';

import { isArrayEmpty } from 'util/isEmpty';

import { CasContentWrapper } from 'view/CasPage/CasPage.s';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { DemandSummaryCombineWidgets } from 'view/SummaryPage/SummaryWidgets/DemandSummaryCombineWidgets';
import {
  selectSimpleProjectCombineQuestions,
  selectSimpleProjectCombineResource,
} from 'reduxStore/simpleProject/selectors';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';
import { FeedbackWrapper, TableWrapper } from 'view/LifecyclePage/LifecyclePage.s';
import { Typography } from '@mui/material';

export type OnDemandCombinePagePureProps = {
  questions: OpinionQuestionDetails.Combine[];
  isAdmin: boolean;
  simpleProjectCombineResource: Resource<any>;
};

export const OnDemandCombinePagePure: React.FC<OnDemandCombinePagePureProps> = (props) => (
  <ResourceLoader resource={props.simpleProjectCombineResource}>
    <PulseFilter />
    <DemandSummaryCombineWidgets />
    <CasContentWrapper>
      {!isArrayEmpty(props.questions) && (
        <FeedbackWrapper>
          <Typography variant="h5">Survey Questions</Typography>
          <TableWrapper>
            <ScrollBar overflow="horizontal" float="none" light>
              <SortableQuestionTable
                data={props.questions}
                width={'100%'}
                shouldRenderCategory={false}
              />
            </ScrollBar>
          </TableWrapper>
        </FeedbackWrapper>
      )}
    </CasContentWrapper>
  </ResourceLoader>
);

export const OnDemandCombinePage: React.FC = () => {
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const isAdmin = useSelector(selectIsAdmin);
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);

  return (
    <OnDemandCombinePagePure
      questions={questions}
      isAdmin={isAdmin}
      simpleProjectCombineResource={simpleProjectCombineResource}
    />
  );
};
