import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectSummaryCompare,
  selectSummaryCompareGroups,
  selectSummaryCompareGroupsLength,
} from 'reduxStore/summary/selectors';
import { CompareMatrix } from 'component/CompareMatrix/CompareMatrix';
import {
  selectIsLifecycleProjectKind,
  selectMinResponsesCloseQuestion,
} from 'reduxStore/project/selectors';
import { SummaryHeatmapLegend } from 'component/HeatmapLegend/HeatMapLegend';
import { PeriodSelectionSliderWrapper } from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { CompareGroup } from 'model/CompareGroup';
import { Summary } from 'model/Summary';
import { useAppDispatch } from 'reduxStore/appStore';
import { PeriodSelectionSlider } from 'component/PeriodSelectionSlider/PeriodSelectionSlider';
import { fetchSimpleProject } from 'reduxStore/simpleProject/asyncActions';
import { Box } from '@mui/material';
import { mapSummaryCompareWidgetsData } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/mapSummaryCompareWidgetsData';
import { ReportParamsSelectionMessage } from 'view/ReportView/components/ReportParamsSelectionMessage';
import { selectSimpleProjectCompareEmployeesNumber } from 'reduxStore/simpleProject/selectors';

export type SummaryCompareWidgetsProps = {
  isReportView?: boolean;
};

export type SummaryCompareWidgetsPureProps = {
  title?: string;
  list: Summary.Compare[];
  groups: ReadonlyArray<CompareGroup>;
  minResponses: number;
  groupsLength: number;
  isLifecycleProjectKind: boolean;
  responsesNumber: number;
  isReportView?: boolean;
  handleChangeCommitted: () => void;
};

export const SummaryCompareWidgetsPure: React.FC<SummaryCompareWidgetsPureProps> = ({
  title,
  list,
  groups,
  groupsLength,
  isLifecycleProjectKind,
  handleChangeCommitted,
  minResponses,
  isReportView,
  responsesNumber,
}) => {
  const { groups: departments, labels, values } = mapSummaryCompareWidgetsData({
    groups,
    list,
    groupsLength,
    isLifecycleProjectKind,
  });

  return (
    <Box data-testid="summaryCompareWidgets">
      {isReportView && <ReportParamsSelectionMessage responsesNumber={responsesNumber} />}
      <SummaryHeatmapLegend sx={{ mb: 3 }} />
      <CompareMatrix
        title={title}
        sx={{ mb: 3 }}
        departments={departments}
        labels={labels}
        values={values}
        colorType="mixed"
        minResponses={minResponses}
        isReportView={isReportView}
      />
      {isLifecycleProjectKind && !isReportView && (
        <PeriodSelectionSliderWrapper>
          <PeriodSelectionSlider onChangeCommitted={handleChangeCommitted} />
        </PeriodSelectionSliderWrapper>
      )}
    </Box>
  );
};

export const SummaryCompareWidgets: React.FC<SummaryCompareWidgetsProps> = (props) => {
  const dispatch = useAppDispatch();
  const list = useSelector(selectSummaryCompare);
  const groupsLength = useSelector(selectSummaryCompareGroupsLength)!;
  const groups = useSelector(selectSummaryCompareGroups);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);
  const responsesNumber = useSelector(selectSimpleProjectCompareEmployeesNumber);

  const handleChangeCommitted = () => dispatch(fetchSimpleProject());

  return (
    <SummaryCompareWidgetsPure
      list={list}
      groupsLength={groupsLength}
      groups={groups}
      minResponses={minResponses}
      isLifecycleProjectKind={isLifecycleProjectKind}
      responsesNumber={responsesNumber}
      handleChangeCommitted={handleChangeCommitted}
      isReportView={props.isReportView}
    />
  );
};
