import { DepartmentApi } from 'api/DepartmentApi';
import { Department } from 'model/Department';

export class DepartmentMapper {
  deserialize(entry: DepartmentApi.Entry, parentId?: number | undefined): Department {
    return {
      id: entry.id,
      name: entry.name,
      parentId: parentId,
      children: (entry.children || [])
        .filter((child) => !!child)
        .map((child) => this.deserialize(child, entry.id)),
      blocked: entry.blocked,
      checked: false,
      collapsed: true,
      matched: false,
    };
  }
}
