import { Pagination } from 'model/Pagination';
import { PaginationDto } from 'api/dto/Pagination.dto';

export namespace PaginationMapper {
  export const deserialize = (paginationDto: PaginationDto): Pagination => {
    return (
      paginationDto && {
        index: paginationDto.index,
        pageCount: paginationDto.pageCount,
        itemCount: paginationDto.itemCount,
        pageLength: paginationDto.pageLength,
      }
    );
  };
}
