import { mapDataToCompareMatrix } from 'util/mapDataToCompareMatrix';
import { isArrayEmpty } from 'util/isEmpty';
import { chunk } from 'util/chunkArray';

import { CompareGroup } from 'model/CompareGroup';
import { Summary } from 'model/Summary';
import { QuestionWithGroup } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCompare/EngagementDriverByCategoryCompare';

type ListType = Summary.Compare[] | QuestionWithGroup[];

export const mapGroups = (groups?: ReadonlyArray<CompareGroup>) => {
  return groups !== undefined ? mapDataToCompareMatrix<string>(groups, ['group'], 'group') : [];
};

export const mapScores = (list: ListType, groupsLength: number) => {
  return list && !isArrayEmpty(list)
    ? chunk<number>(mapDataToCompareMatrix<number>(list, ['groups', 'score'], 'data'), groupsLength)
    : [];
};

export const mapProtectedScores = (list: ListType, groupsLength: number) => {
  return list && !isArrayEmpty(list)
    ? chunk<number>(
        mapDataToCompareMatrix<number>(list, ['groups', 'respondentProtectionType'], 'data'),
        groupsLength
      )
    : [];
};

export const mapConfidentialityProtection = (list: ListType, groupsLength: number) => {
  return list && !isArrayEmpty(list)
    ? chunk<number>(
        mapDataToCompareMatrix<number>(list, ['groups', 'confidentialityProtection'], 'data'),
        groupsLength
      )
    : [];
};

export const mapValues = (
  scores: number[][],
  protectedScores: number[][],
  confidentialityProtection: number[][]
) => {
  return scores.map((item, index) => ({
    value: item,
    respondentProtectionTypeArray: protectedScores[index],
    isConfidentialityProtection: confidentialityProtection[index],
  })) as any[];
};
