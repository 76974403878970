import React from 'react';
import { useSelector } from 'react-redux';

import { selectTitlePageOnly } from 'util/selector/querySelector';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { TitlePage } from 'view/ReportView/ReportTemplate/TitlePage/TitlePage';
import { selectQuestionsAndAnswersResource } from 'reduxStore/comments/selectors';
import { CommentAnswerItem } from 'view/CommentsPage/CommentAnswerItem';
import { ReportType } from 'model/ReportType';
import { selectReportTitle } from 'reduxStore/project/selectors';
import { Department } from 'model/Department';
import { selectReportDepartment } from 'reduxStore/departments/selectors';
import { QuestionAndAnswers } from 'model/QuestionAndAnswers';
import { useFetchAllComments } from 'view/ReportView/AllCommentsReport/hooks/useFetchAllComments';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { Resource } from 'model/Resource';

export type AllCommentsReportViewPure = {
  allQuestionsAndAnswersResource: Resource<QuestionAndAnswers[] | null>;
  reportTitle: string;
  selectedDepartment: Department | undefined;
};

export const AllCommentsReportViewPure: React.FC<AllCommentsReportViewPure> = ({
  allQuestionsAndAnswersResource,
}) => (
  <div>
    <ResourceLoader resource={allQuestionsAndAnswersResource}>
      <>
        {allQuestionsAndAnswersResource.data?.map((question) => {
          const newFlag = question.question.isLatest ? '(New)' : '';

          return (
            <ReportContent
              key={question.question.id}
              title={question.question.content + ' ' + newFlag}
              reportType={ReportType.Comments}
              component={
                <>
                  {question.answers.map((item, index) => (
                    <CommentAnswerItem key={index} item={item} isReportView />
                  ))}
                </>
              }
            />
          );
        })}
      </>
    </ResourceLoader>
  </div>
);

export const AllCommentsReportView: React.FC = () => {
  const allQuestionsAndAnswersResource = useSelector(selectQuestionsAndAnswersResource);
  const reportTitle = useSelector(selectReportTitle);
  const selectedDepartment = useSelector(selectReportDepartment);
  const isTitlePageOnly = useSelector(selectTitlePageOnly);

  useFetchAllComments();

  if (isTitlePageOnly) {
    return (
      <TitlePage
        title={reportTitle}
        reportType={ReportType.Comments}
        departmentName={selectedDepartment && selectedDepartment.name}
      />
    );
  }

  return (
    <AllCommentsReportViewPure
      allQuestionsAndAnswersResource={allQuestionsAndAnswersResource}
      reportTitle={reportTitle}
      selectedDepartment={selectedDepartment}
    />
  );
};
