import { Box, styled } from '@mui/material';
import { DARK_ORANGE, WHITE } from 'app/theme/colors';

export const SubTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1em',
  lineHeight: '1.4',
});

export type RowsWrapperProps = {
  mobileDirection?: string;
};

export const RowsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobileDirection',
})<RowsWrapperProps>(({ theme, mobileDirection }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '30px 0',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    flexDirection: mobileDirection ?? 'row',
    gap: mobileDirection ? '16px' : 'initial',
  },
}));

export const TooltipLink = styled('a')({
  textDecoration: 'none',
  color: DARK_ORANGE,
  ':hover': {
    color: DARK_ORANGE,
  },
});

export const PaginationWrapper = styled('div')({
  marginTop: '20px',
});

export const IndividualFeedbackPageCard = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: WHITE,
  borderRadius: '10px',
  padding: 24,
});
