import { Insight } from 'model/Insight';
import { Resource } from 'model/Resource';
import { InsightsComparedModel } from 'api/dto/new/InsightsCompared.dto';
import { InsightsLifecycleComparedModel } from 'api/dto/new/InsightsLifecycleCompared.dto';

export const STORE_NAME = 'insights';

export namespace InsightsState {
  export type Domain = {
    combined: Resource<Insight.Combine>;
    lifecycleCombined: Resource<Insight.Combine>;
    compared: Resource<InsightsComparedModel | null>;
    lifecycleCompared: Resource<InsightsLifecycleComparedModel | null>;
  };

  export const INITIAL_DOMAIN_COMBINE = Resource.createPendingResource<Insight.Combine>({
    data: [],
  });

  export const INITIAL_DOMAIN_COMPARED = Resource.createPendingResource<InsightsComparedModel | null>(
    null
  );

  export const INITIAL_DOMAIN_LIFECYCLE_COMPARED = Resource.createPendingResource<InsightsLifecycleComparedModel | null>(
    null
  );

  export const INITIAL_DOMAIN = {
    combined: INITIAL_DOMAIN_COMBINE,
    lifecycleCombined: INITIAL_DOMAIN_COMBINE,
    compared: INITIAL_DOMAIN_COMPARED,
    lifecycleCompared: INITIAL_DOMAIN_LIFECYCLE_COMPARED,
  };
}
