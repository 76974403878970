import styled from '@emotion/styled';

export const NotFoundPageContentWrapper = styled.div({
  flexGrow: 1,
  maxWidth: '500px',
  padding: '0 1rem',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
