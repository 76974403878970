import React from 'react';

import { Menu } from 'component/RibbonNavBar/RibbonNavBar.s';
import { WBNavItem } from 'component/NavItem/WBNavItem';
import { RibbonNavBarItem } from 'component/RibbonNavBar/hooks/useGetVisibleRibbonNavBarItems';

export type RibbonNavBarProps = {
  visibleRibbonNavBarItems: RibbonNavBarItem[];
};

export const RibbonNavBar: React.FC<RibbonNavBarProps> = ({ visibleRibbonNavBarItems }) => {
  return (
    <Menu data-testid="ribbonNavBarWrapper">
      {visibleRibbonNavBarItems.map((item, index) => (
        <WBNavItem
          isSubNav
          key={index}
          label={item.label}
          href={item.url}
          hasVisibleRibbonNavBarItems={visibleRibbonNavBarItems.length > 0}
        />
      ))}
    </Menu>
  );
};
