import { createSlice, Draft } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import {
  fetchInsightsCombined,
  fetchInsightsCompared,
  fetchInsightsLifecycleCombined,
  fetchInsightsLifecycleCompared,
} from 'reduxStore/insights/asyncActions';
import { Resource } from 'model/Resource';
import { Insight } from 'model/Insight';
import { InsightsComparedModel } from 'api/dto/new/InsightsCompared.dto';
import { InsightsLifecycleComparedModel } from 'api/dto/new/InsightsLifecycleCompared.dto';

import { InsightsState, STORE_NAME } from './initialState';

export const insightsSlice = createSlice({
  name: STORE_NAME,
  initialState: InsightsState.INITIAL_DOMAIN,
  reducers: {
    clearInsights: (state) => {
      state.combined = InsightsState.INITIAL_DOMAIN_COMBINE as Draft<Resource<Insight.Combine>>;
      state.lifecycleCombined = InsightsState.INITIAL_DOMAIN_COMBINE as Draft<
        Resource<Insight.Combine>
      >;
      state.compared = InsightsState.INITIAL_DOMAIN_COMPARED as Draft<
        Resource<InsightsComparedModel>
      >;
      state.lifecycleCompared = InsightsState.INITIAL_DOMAIN_LIFECYCLE_COMPARED as Draft<
        Resource<InsightsLifecycleComparedModel>
      >;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchInsightsCombined, (state, action) => {
      state.combined = action.payload as Draft<Resource<Insight.Combine>>;
    });
    addAsyncCases(builder, fetchInsightsLifecycleCombined, (state, action) => {
      state.lifecycleCombined = action.payload as Draft<Resource<Insight.Combine>>;
    });
    addAsyncCases(builder, fetchInsightsCompared, (state, action) => {
      state.compared = action.payload as Draft<Resource<InsightsComparedModel>>;
    });
    addAsyncCases(builder, fetchInsightsLifecycleCompared, (state, action) => {
      state.lifecycleCompared = action.payload as Draft<Resource<InsightsLifecycleComparedModel>>;
    });
  },
});

export const { clearInsights } = insightsSlice.actions;
