import React, { ReactElement } from 'react';

import { ProjectKind } from 'model/ProjectKind';
import { ReportSummaryWidgets } from 'view/ReportView/SummaryWidgetReport/ReportSummaryWidgets';
import { ReportPulseSummaryCombineWidgets } from 'view/ReportView/SummaryWidgetReport/ReportPulseSummaryCombineWidget';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';

export namespace SummaryReportStrategy {
  export type Props = {
    kind: ProjectKind;
  };
}

export const SummaryReportStrategy: React.FC<SummaryReportStrategy.Props> = (props) => {
  const wrapComponent = (component: ReactElement<any>): ReactElement<any> => (
    <ReportContent title="Summary" component={component} />
  );

  switch (props.kind) {
    case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
    case ProjectKind.PROJECT_NET_PROMOTER_SCORE:
    case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
    case ProjectKind.PROJECT_OVERALL_SCORE:
    case ProjectKind.PULSE_ON_DEMAND_PROJECT:
      return wrapComponent(<ReportSummaryWidgets isReportView />);
    case ProjectKind.PULSE_PROJECT:
    case ProjectKind.PULSE_TEMPLATE:
      return <ReportPulseSummaryCombineWidgets />;
    default:
      return null;
  }
};
