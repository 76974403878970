import { styled } from '@mui/material';

export const LoaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  padding: 15,
  boxSizing: 'border-box',
});
