import React from 'react';
import { useSelector } from 'react-redux';

import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { CasPieChartCompare } from 'view/CasPage/Compare/CasPieChartCompare';
import { CasBarChartCompare } from 'view/CasPage/Compare/CasBarChartCompare';
import { CasQuestionTableCompare } from 'view/CasPage/Compare/CasQuestionTableCompare';
import {
  selectSimpleProjectCompareGroups,
  selectSimpleProjectCompareQuestionContent,
} from 'reduxStore/simpleProject/selectors';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import { CasQuestionCompare } from 'view/CasPage/Compare/CasQuestionCompare';

export type CasReportComparePureProps = {
  question: string | undefined;
  groups: string[];
  minResponses: number;
};

export const CasReportComparePure: React.FC<CasReportComparePureProps> = (props) => {
  return (
    <div>
      <ReportContent title="Summary" component={<SummaryCompareWidgets isReportView />} />
      <ReportContent
        title="Client Advocacy Score"
        component={
          <CasQuestionCompare
            groups={props.groups}
            minResponses={props.minResponses}
            isReportView
          />
        }
      />
      <ReportContent
        title="Percentage of:"
        component={
          <CasPieChartCompare
            groups={props.groups}
            minResponses={props.minResponses}
            isReportView
          />
        }
      />
      <ReportContent
        title={props.question}
        component={
          <CasBarChartCompare
            groups={props.groups}
            minResponses={props.minResponses}
            isReportView
          />
        }
      />
      <ReportContent
        title="Survey Questions"
        component={
          <CasQuestionTableCompare
            groups={props.groups}
            minResponses={props.minResponses}
            isReportView
          />
        }
      />
    </div>
  );
};

export const CasReportCompare: React.FC = () => {
  const question = useSelector(selectSimpleProjectCompareQuestionContent);
  const groups = useSelector(selectSimpleProjectCompareGroups);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);

  return <CasReportComparePure question={question} groups={groups} minResponses={minResponses} />;
};
