import { styled } from '@mui/material';
import { BACKGROUND_COMMENT, WHITE } from 'app/theme/colors';

export const CasContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '30px 0',
});

export const CasChartsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  background: WHITE,
  borderRadius: '10px',
  marginTop: '24px',
});

export const CasQuestion = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  float: 'left',
  height: '40px',
  backgroundColor: BACKGROUND_COMMENT,
  paddingLeft: 10,
  marginBottom: 40,
});
