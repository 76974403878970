import React from 'react';
import { useSelector } from 'react-redux';

import {
  CellText,
  CellTextWrapper,
  HeaderCell,
  HeaderRow,
} from 'component/CompareMatrix/Header/Header.s';
import { selectTableBreadth } from 'reduxStore/departments/selectors';
import { ComparisionMatrix } from 'model/ComparisionMatrix';

export type HeaderProps = {
  names: string[];
};

export type HeaderPureProps = {
  breadth: ComparisionMatrix.Breadth;
} & HeaderProps;

export const HeaderPure: React.FC<HeaderPureProps> = (props) => (
  <thead>
    <HeaderRow>
      <th />
      {props.names.map((name, index) => (
        <HeaderCell data-testid="compareMatrixHeaderCell" scope="col" key={index}>
          <CellTextWrapper>
            <CellText variant="subtitle2" title={name}>
              {name}
            </CellText>
          </CellTextWrapper>
        </HeaderCell>
      ))}
    </HeaderRow>
  </thead>
);

export const Header: React.FC<HeaderProps> = (props) => {
  const breadth = useSelector(selectTableBreadth);

  return <HeaderPure breadth={breadth} names={props.names} />;
};
