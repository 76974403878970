import React from 'react';

import { MARINER } from 'app/theme/colors';

export namespace PercentageArc {
  export type Props = {
    width: number;
    radius: number;
    strokeWidth: number;
    dashArray: number;
    dashOffset: number;
  };
}

export const PercentageArc: React.FC<PercentageArc.Props> = (props) => (
  <circle
    cx={props.width / 2}
    cy={props.width / 2}
    r={props.radius}
    strokeWidth={`${props.strokeWidth}px`}
    transform={`rotate(-90 ${props.width / 2} ${props.width / 2})`}
    strokeDasharray={props.dashArray}
    strokeDashoffset={props.dashOffset}
    strokeLinecap="round"
    fill="transparent"
    stroke={MARINER}
  />
);
