import { Benchmark } from 'model/Benchmark';
import { ModeType } from 'register/ModeType';

export const STORE_NAME = 'visualSettings';

export namespace VisualSettingsState {
  export type Domain = {
    isCombineModeLock: boolean;
    showImpactOnEngagement: boolean;
    chartLabel: string | null;
    benchmark: Benchmark;
    isClearingAllFilters: boolean;
    preferredMode: ModeType | null;
  };

  export const INITIAL_IS_COMBINE_MODE_LOCK = false;
  export const INITIAL_SHOW_IMPACT_ON_ENGAGEMENT = false;
  export const INITIAL_CHART_LABEL = null;
  export const INITIAL_BENCHMARK = null;
  export const INITIAL_IS_CLEARING_ALL_FILTERS = false;
  export const INITIAL_PREFERRED_MODE = null;

  export const INITIAL_DOMAIN: VisualSettingsState.Domain = {
    isCombineModeLock: INITIAL_IS_COMBINE_MODE_LOCK,
    showImpactOnEngagement: INITIAL_SHOW_IMPACT_ON_ENGAGEMENT,
    chartLabel: INITIAL_CHART_LABEL,
    benchmark: INITIAL_BENCHMARK,
    isClearingAllFilters: INITIAL_IS_CLEARING_ALL_FILTERS,
    preferredMode: INITIAL_PREFERRED_MODE,
  };
}

export type VisualSettings = {
  visualSettings: VisualSettingsState.Domain;
};
