import { css } from 'glamor';
import { LINE } from 'app/theme/colors';

export const colorLine = css({
  '& path': {
    stroke: 'none',
    fill: 'none',
  },
  '& g': {
    '& line': {
      stroke: LINE,
    },
  },
});

export const textStyle = css({
  '& g': {
    '& text': {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '14px',
      fontWeight: 300,
    },
  },
});
