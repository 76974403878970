import { BLACK, IRON, SONIC_SILVER } from 'app/theme/colors';
import Icon from 'react-icons/lib/md/search';
import { styled } from '@mui/material';
import { typography } from 'app/theme/typography';

export const SearchInputWrapper = styled('div')({
  width: 300,
  height: 45,
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  position: 'relative',
  margin: '30px 0',
});

export const SearchInputContent = styled('input')({
  ...typography.body1,
  width: '300px',
  height: '45px',
  float: 'left',
  color: SONIC_SILVER,
  paddingLeft: '20px',
  border: `2px solid ${IRON}`,
  borderRadius: '6px',
  '&:focus': {
    outline: 'none',
  },
});

export const SearchIcon = styled(Icon)({
  position: 'absolute',
  top: 15,
  right: 0,
  margin: '0 15px',
  zIndex: 1,
  fontSize: 18,
  color: BLACK,
});
