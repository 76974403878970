import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectIsSentimentLoadPending } from 'util/selector/sentimentSelector';

import { fetchCommentAnswers } from 'reduxStore/comments/asyncActions';
import { TextFlagNew } from 'component/FlagNew/TextFlagNew';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import { RespondentProtectionLabel } from 'component/RespondentProtectionLabel/RespondentProtectionLabel';
import { QuestionSelectBox, QuestionSelectBoxProps } from 'component/SelectBox/QuestionSelectBox';
import { OptionItem } from 'component/SelectBox/SelectBox.s';
import { SentimentBar } from 'component/SentimentBar/SentimentBar';
import { SentimentBarPlaceholder } from 'component/SentimentBar/SentimentBarPlaceholder';
import { SentimentFilter, SentimentFilterValue } from 'component/SentimentFilter/SentimentFilter';
import { TopicFilter, TopicFilterValue } from 'component/SentimentFilter/TopicFilter';
import { CommentWordCloud } from 'component/CommentWordCloud/CommentWordCloud';
import { Answer } from 'model/Answer';
import { Pagination } from 'model/Pagination';
import { Question } from 'model/Question';
import {
  selectAdvocacyFilter,
  selectAnswersResource,
  selectHasAnyQuestions,
  selectPagination,
  selectQuestionsResource,
  selectSearchedText,
  selectSelectedQuestion,
  selectSentimentStats,
} from 'reduxStore/comments/selectors';
import {
  selectCurrentProjectResource,
  selectIsPulseLifecycleProjectKind,
  selectIsPulseProjectKind,
  selectSentimentIsLoaded,
} from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { CommentsState } from 'reduxStore/comments/initialState';
import { CommentAnswers } from 'view/CommentsPage/CommentAnswers';
import { CommentsHeader } from 'view/CommentsPage/CommentsHeader/CommentsHeader';
import { CommentsPageCard, RowCellWrapper, RowsWrapper } from 'view/CommentsPage/CommentsPage.s';
import { SentimentEnum, SentimentStats } from 'model/Sentiment';
import { LifecycleCommentsPage } from 'view/CommentsPage/LifecycleCommentsPage';
import { AdvocacyDtoEnum } from 'api/dto/Advocacy.dto';
import { SearchInput } from 'component/SearchInput/SearchInput';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import {
  resetPaginationPage,
  setAdvocacyFilter,
  setSearchText,
  setSelectedQuestion,
  setSentimentFilter,
  setTopicFilter,
} from 'reduxStore/comments/slice';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { useCommentsPageEffects } from 'view/CommentsPage/hooks/useCommentsPageEffects';
import { PageContentWrapper } from 'shared/styles/layout';
import { Typography } from '@mui/material';
import { NoResultsMessage } from 'component/NoResultsMessage/NoResultsMessage';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import debounce from 'lodash/debounce';
import { ProjectModel } from 'model/Project.model';

export type CommentsPagePureProps = {
  questionsResource: Resource<Question[]>;
  answersResource: Resource<Answer[]>;
  pagination: Pagination | null;
  hasQuestions: boolean;
  searchedText: string;
  isPulseProjectKind: boolean;
  isPulseLifecycleProjectKind: boolean;
  selectedQuestion: Question;
  isSentimentLoaded: boolean;
  isSentimentLoadPending: boolean;
  sentimentStats: SentimentStats | null;
  selectedAdvocacyFilterValues: AdvocacyDtoEnum[];
  currentProjectResource: Resource<ProjectModel>;
  setSelectedQuestion: (id: number, optionId?: number) => void;
  fetchAnswers: (page?: number) => void;
  setSentimentFilter: (values: SentimentEnum[]) => void;
  setTopicFilter: (values: number[]) => void;
  setSearchText: (text: string) => void;
  setAdvocacyFilter: (values: AdvocacyDtoEnum[]) => void;
  resetPaginationPage: () => void;
  onUnmount: () => void;
};

const BAR_WIDTH = 400;
const BAR_HEIGHT = 25;

export const NoCommentsMessage = () => (
  <NoResultsMessage
    title="For this pulse survey, the group you are viewing feedback for received no comments."
    hideIcon
  />
);

const NoComments = ({ isPulse }: { isPulse: boolean }) => (
  <>
    <PulseFilter />
    {isPulse ? <NoCommentsMessage /> : <RespondentProtectionLabel />}
  </>
);

export const CommentsPagePure: React.FC<CommentsPagePureProps> = (props) => {
  const {
    hasQuestions,
    questionsResource,
    searchedText,
    pagination,
    answersResource,
    selectedQuestion,
    isSentimentLoaded,
    isSentimentLoadPending,
    sentimentStats,
    selectedAdvocacyFilterValues,
    currentProjectResource,
  } = props;

  const sentimentPendingIntervalRef = useRef<number>();
  const isSentimentLoadPendingRef = useRef<boolean>(props.isSentimentLoadPending);

  useEffect(() => {
    const reFetchAnswers = () => {
      props.fetchAnswers(props.pagination?.index);
    };

    if (!sentimentPendingIntervalRef.current && isSentimentLoadPending) {
      sentimentPendingIntervalRef.current = window.setInterval(() => reFetchAnswers(), 5000);
    }

    return () => {
      props.onUnmount();

      clearInterval(sentimentPendingIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Did update
  useEffect(() => {
    if (
      !!sentimentPendingIntervalRef.current &&
      isSentimentLoadPendingRef.current &&
      !isSentimentLoadPending
    ) {
      clearInterval(sentimentPendingIntervalRef.current);

      isSentimentLoadPendingRef.current = isSentimentLoadPending;
    }
  }, [isSentimentLoadPending]);

  const handleSelectQuestion: QuestionSelectBoxProps['onChange'] = (question, optionId?) => {
    if (question) props.setSelectedQuestion(question.id, optionId);
    props.fetchAnswers();
  };

  const handleSentimentFilterChange = (values: SentimentFilterValue[]) => {
    props.setSentimentFilter(values);
    resetPaginationPage();
    props.fetchAnswers();
  };

  const handleTopicFilterChange = (values: TopicFilterValue[]) => {
    props.setTopicFilter(values);
    resetPaginationPage();
    props.fetchAnswers();
  };

  const handleSearch = (value: string) => {
    props.setSearchText(value);
    handleSearchDebounced();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchDebounced = useCallback(
    debounce(() => {
      resetPaginationPage();
      props.fetchAnswers();
    }, FILTERS_CHANGED_DEBOUNCE_TIMEOUT),
    []
  );

  const handleChangeAdvocacy = (values: AdvocacyDtoEnum[]) => {
    props.setAdvocacyFilter(values);
    resetPaginationPage();
    props.fetchAnswers();
  };

  const isSentimentActive = selectedQuestion && Question.hasSentiment(selectedQuestion);
  const answers = answersResource.data;
  const questions = questionsResource.data;

  const renderResolvedContent = () => {
    if (props.isPulseLifecycleProjectKind) {
      return (
        <LifecycleCommentsPage
          answers={answers}
          initiallySelectedQuestion={selectedQuestion || questions?.[0]}
          onChangeAdvocacy={handleChangeAdvocacy}
          onQuestionSelect={handleSelectQuestion}
          onSearch={handleSearch}
          pagination={pagination}
          questions={questions}
          searchedText={searchedText}
          selectedAdvocacyFilterValues={selectedAdvocacyFilterValues}
        />
      );
    }

    // TODO Create separated component for non-lifecycle comments page
    return (
      <>
        {hasQuestions ? (
          <>
            <PulseFilter />

            <QuestionSelectBox
              onChange={handleSelectQuestion}
              initialSelected={selectedQuestion || questions[0]}
            >
              {questions.map((question) => (
                <OptionItem
                  data-testid={`question[${question.id}]`}
                  key={question.id}
                  data-option={question.option}
                  value={question}
                >
                  {question.content}
                  <TextFlagNew isLatest={question.isLatest} />
                </OptionItem>
              ))}
            </QuestionSelectBox>

            {answers.length > 0 && (
              <RowsWrapper sx={{ gap: { md: '24px' }, margin: 0 }}>
                {isSentimentActive && (
                  <CommentsPageCard mt={3.75}>
                    <Typography variant="h5">Sentiment</Typography>
                    {isSentimentLoaded && !!sentimentStats ? (
                      <SentimentBar height={BAR_HEIGHT} data={sentimentStats} />
                    ) : (
                      <SentimentBarPlaceholder width={BAR_WIDTH} height={BAR_HEIGHT} />
                    )}
                  </CommentsPageCard>
                )}
                <CommentsPageCard mt={3.75}>
                  <CommentWordCloud />
                </CommentsPageCard>
              </RowsWrapper>
            )}

            <CommentsPageCard mt={3.75}>
              <RowsWrapper>
                {isSentimentActive && (answers.length === 0 || isSentimentLoaded) && (
                  <>
                    <RowCellWrapper noMargins>
                      <SentimentFilter onChange={handleSentimentFilterChange} />
                    </RowCellWrapper>
                    <RowCellWrapper noMargins>
                      <TopicFilter onChange={handleTopicFilterChange} />
                    </RowCellWrapper>
                  </>
                )}
                <RowCellWrapper noMargins>
                  <SearchInput
                    onChange={handleSearch}
                    value={searchedText}
                    data-testid="commentsSearchInput"
                  />
                </RowCellWrapper>
              </RowsWrapper>

              <CommentAnswers answers={answers} pagination={pagination} />
            </CommentsPageCard>
          </>
        ) : (
          <NoComments isPulse={props.isPulseProjectKind} />
        )}
      </>
    );
  };

  return (
    <div>
      <CommentsHeader title="Comments" isDownloadDisabled={answersResource.data.length < 1} />
      <ResourceLoader
        resource={[currentProjectResource, questionsResource, answersResource]}
        rejected={(error) => (
          <PageContentWrapper data-testid="commentsPageWrapper">
            <ErrorHandler error={error} />
          </PageContentWrapper>
        )}
      >
        <PageContentWrapper data-testid="commentsPageWrapper">
          {renderResolvedContent()}
        </PageContentWrapper>
      </ResourceLoader>
    </div>
  );
};

export const CommentsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const isPulseProjectKind = useSelector(selectIsPulseProjectKind);
  const isPulseLifecycleProjectKind = useSelector(selectIsPulseLifecycleProjectKind);
  const questionsResource = useSelector(selectQuestionsResource);
  const selectedQuestion = useSelector(selectSelectedQuestion)!;
  const hasQuestions = useSelector(selectHasAnyQuestions);
  const answersResource = useSelector(selectAnswersResource);
  const pagination = useSelector(selectPagination);
  const searchedText = useSelector(selectSearchedText);
  const isSentimentLoaded = useSelector(selectSentimentIsLoaded);
  const isSentimentLoadPending = useSelector(selectIsSentimentLoadPending);
  const sentimentStats = useSelector(selectSentimentStats);
  const selectedAdvocacyFilterValues = useSelector(selectAdvocacyFilter);
  const currentProjectResource = useSelector(selectCurrentProjectResource);

  useCommentsPageEffects();

  return (
    <CommentsPagePure
      questionsResource={questionsResource}
      answersResource={answersResource}
      pagination={pagination}
      hasQuestions={hasQuestions}
      searchedText={searchedText}
      isPulseProjectKind={isPulseProjectKind}
      isPulseLifecycleProjectKind={isPulseLifecycleProjectKind}
      selectedQuestion={selectedQuestion}
      isSentimentLoaded={isSentimentLoaded}
      isSentimentLoadPending={isSentimentLoadPending}
      sentimentStats={sentimentStats}
      selectedAdvocacyFilterValues={selectedAdvocacyFilterValues}
      currentProjectResource={currentProjectResource}
      setSelectedQuestion={(id, optionId) => dispatch(setSelectedQuestion({ id, optionId }))}
      fetchAnswers={(page) => dispatch(fetchCommentAnswers({ page }))}
      setSentimentFilter={(values) => dispatch(setSentimentFilter(values))}
      setTopicFilter={(values) => dispatch(setTopicFilter(values))}
      setSearchText={(text) => dispatch(setSearchText(text))}
      setAdvocacyFilter={(values) => dispatch(setAdvocacyFilter(values))}
      resetPaginationPage={() => dispatch(resetPaginationPage())}
      onUnmount={() => {
        dispatch(setSentimentFilter(CommentsState.INITIAL_DOMAIN.sentimentFilter));
        dispatch(setTopicFilter(CommentsState.INITIAL_DOMAIN.topicFilter));
        dispatch(setAdvocacyFilter(CommentsState.INITIAL_DOMAIN.advocacyFilter));
        dispatch(setSearchText(''));
      }}
    />
  );
};
