import React, {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  DisplayOption,
  OptionList,
  OptionListItem,
  SelectBoxWrapper,
} from 'component/SelectBox/SelectBox.s';
import AngleDown from 'react-icons/lib/fa/angle-down';
import { Typography } from '@mui/material';

export type SelectBoxBaseProps<T> = {
  onChange: (value: T, optionId?: number) => any;
  initialSelected: T;
  renderSelected: (selectedId: T) => React.JSX.Element;
  wrapper?: ComponentType<PropsWithChildren<{ visible: boolean; selected: T }>>;
  isBold?: boolean;
  children?: ReactNode;
  'data-testid'?: string;
};

const DefaultWrapper: React.FC<PropsWithChildren<{ visible: boolean; selected: any }>> = ({
  children,
}) => <>{children}</>;

export const SelectBoxBase = <T,>({
  onChange,
  initialSelected,
  renderSelected,
  wrapper: Wrapper = DefaultWrapper,
  isBold = false,
  children,
  'data-testid': dataTestId,
}: SelectBoxBaseProps<T>) => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(initialSelected);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  const toggleSelectBoxVisible = () => {
    setVisible(!visible);
  };

  const handleOptionClick = (value: T, optionId?: number) => {
    onChange(value, optionId);
    setSelected(value);
    setVisible(false);
  };

  return (
    <SelectBoxWrapper ref={wrapperRef}>
      <DisplayOption onClick={toggleSelectBoxVisible} data-testid={dataTestId}>
        <Wrapper visible={visible} selected={selected}>
          <Typography variant={isBold ? 'h5' : 'body1'}>{renderSelected(selected)}</Typography>
          <AngleDown style={{ fontSize: 25, marginLeft: 10 }} />
        </Wrapper>
      </DisplayOption>
      <OptionList visible={visible}>
        {visible && (
          <OptionListItem>
            {React.Children.map(children, (child: ReactElement | any) =>
              React.cloneElement(child, {
                onClick: () => {
                  if (!child.props.disabled) {
                    handleOptionClick(child.props.value, child.props['data-option']);
                  }
                },
              })
            )}
          </OptionListItem>
        )}
      </OptionList>
    </SelectBoxWrapper>
  );
};
