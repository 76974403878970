import { selectReportProjectId } from 'util/selector/reportSelector';
import { selectHasMatch } from 'util/selector/routerSelector';
import { selectFiltersQuery } from 'util/selector/querySelector';

import { AsyncThunk, AsyncThunkConfig, createAsyncThunk } from '@reduxjs/toolkit';
import { ReportRoute } from 'app/route/report';
import { projectScoreApi } from 'container/projectScoreApi';
import { Summary } from 'model/Summary';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { STORE_NAME } from 'reduxStore/summary/initialState';
import { serializeError } from 'shared/utils/redux';

export const fetchPulsesSummary: AsyncThunk<
  Summary.Pulses,
  void,
  AsyncThunkConfig
> = createAsyncThunk(
  `${STORE_NAME}/fetchPulsesSummary`,
  (_, { getState }) => {
    const isReport = selectHasMatch(ReportRoute.REPORT_PATTERN)(getState());
    const projectId = isReport
      ? selectReportProjectId(getState())
      : selectCurrentProjectId(getState());

    const query = selectFiltersQuery(getState());

    return projectScoreApi.pulsesSummary(projectId, query);
  },
  { serializeError }
);
