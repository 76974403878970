import { css } from 'glamor';
import { DARK_ORANGE, GREY_ICON, GREY_TEXT, ORANGE_TEXT } from 'app/theme/colors';

export const container = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  listStyle: 'none',
  margin: '0',
});

export const paginateItem = css({
  margin: '0 0.5em',

  color: GREY_TEXT,
  '&:hover': {
    color: ORANGE_TEXT,
  },
  '&:focus': {
    outline: 'none',
  },
  '@media(max-width: 900px)': {
    margin: 0,
  },
});

export const paginateLinkItem = css({
  color: GREY_TEXT,
  padding: '5px 10px',
  cursor: 'pointer',
  transition: 'background-color .1s ease-in',
  '&:hover': {
    color: ORANGE_TEXT,
  },
  '&:hover, &:focus': {
    outline: 'none',
    textDecoration: 'none',
  },
  '@media(max-width: 900px)': {
    fontSize: '1.125rem',
  },
});

export const arrowLinkItem = css({
  color: GREY_TEXT,
  '&:hover': {
    cursor: 'pointer',
    color: GREY_TEXT,
  },
  '&:focus': {
    outline: 'none',
    textDecoration: 'none',
  },
  '.disabled &': {
    cursor: 'default',
  },
  '.disabled & span': {
    color: GREY_ICON,
  },
  '@media(max-width: 900px)': {
    fontSize: '2rem',
    lineHeight: '1rem',
  },
});

export const active = css({
  '& a, & a:hover': {
    color: DARK_ORANGE,
    borderRadius: '2px',
    fontWeight: 600,
  },
});
