import {
  BasicDemographyClassification,
  DemographyClassification,
} from 'model/DemographyClassification';
import { Department } from 'model/Department';

export namespace Filtering {
  type ToggleDemography = {
    type: 'DEMOGRAPHY';
    classification: DemographyClassification;
  };

  type ToggleDepartment = {
    type: 'DEPARTMENT';
    departmentId: number;
  };

  export type ChangeFilteringCommand = ToggleDemography | ToggleDepartment;

  type FilterCommandContext = {
    command: ChangeFilteringCommand;
    selectedDemographics: BasicDemographyClassification[];
    selectedDepartments: Department['id'][];
  };

  export const canApplyFilter = (filterContext: FilterCommandContext): boolean => {
    return Filtering.noFiltersUsed(filterContext) || Filtering.isInTheSameScope(filterContext);
  };

  /** User can filter any number of elements as long as they are in same scope,
   *  all departments are single scope
   *  one demography is single scope
   * @param command
   * @param selectedDemographics
   * @param selectedDepartments
   */
  export const isInTheSameScope = ({
    command,
    selectedDemographics,
    selectedDepartments,
  }: FilterCommandContext): boolean => {
    switch (command.type) {
      case 'DEPARTMENT':
        // If user want to filter by department, there cannot be any demography filters
        return selectedDemographics.length === 0;
      case 'DEMOGRAPHY':
        // If user want to filter by demography no department filter must be selected
        // and it has to be the same demography as currently used in filters
        return (
          selectedDepartments.length === 0 &&
          selectedDemographics.every(
            (demographic) => demographic.demographyId === command.classification.demographyId
          )
        );
    }
  };

  type NoFiltersUsed = {
    selectedDemographics: BasicDemographyClassification[];
    selectedDepartments: Department['id'][];
  };

  export const noFiltersUsed = ({
    selectedDemographics,
    selectedDepartments,
  }: NoFiltersUsed): boolean => {
    return selectedDemographics.length === 0 && selectedDepartments.length === 0;
  };
}
