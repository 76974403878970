import { AnswerSentiment } from 'model/Sentiment';
import { RespondentModel } from 'api/dto/Respondent.dto';

export type Answer = Readonly<{
  id: number;
  answer: string;
  sentiment: AnswerSentiment | null;
  respondent: RespondentModel | null;
}>;

export type LifecycleAnswer = Omit<Answer, 'respondent'> & {
  respondent: RespondentModel;
};

export const isLifecycleAnswer = (answer: Answer): answer is LifecycleAnswer => {
  return !!answer.respondent;
};
