import React from 'react';

import { LogoWrapper } from 'component/TopNavBar/TopNavBar.s';
import WorkbuzzLogo from 'app/asset/workbuzz_logo.svg';
import slipUp from 'app/asset/slipUp.png';
import { BUTTON_ORANGE, WHITE } from 'app/theme/colors';
import { NavButton } from 'component/NavButton/NavButton';

import { ContentContainer, Description, Heading, PageContainer, SlipUp } from './ErrorPage.s';

export const ErrorPage: React.FC = () => {
  return (
    <PageContainer>
      <LogoWrapper>
        <a href="/">
          <WorkbuzzLogo />
        </a>
      </LogoWrapper>

      <ContentContainer>
        <Heading data-testid="error_page_heading">Oops! Sorry about the slip up.</Heading>
        <Description data-testid="error_page_description">
          We apologize, but it seems that something has gone awry. Our team of experts has been
          notified of the issue, and we're working diligently to resolve it as soon as possible.
        </Description>

        <NavButton
          data-testid="error_page_refresh_button"
          label="Refresh Page"
          style={{
            color: WHITE,
            backgroundColor: BUTTON_ORANGE,
            borderRadius: 4,
            marginTop: '30px',
          }}
          onClick={() => {
            window.location.reload();
          }}
        />
      </ContentContainer>

      <SlipUp src={slipUp} />
    </PageContainer>
  );
};
