import { isArrayEmpty } from 'util/isEmpty';
import { selectReportQuestionId } from 'util/selector/reportSelector';

import { AppState } from 'reduxStore/appStore';
import { CommentsState } from 'reduxStore/comments/initialState';
import { Question } from 'model/Question';
import { createSelector } from '@reduxjs/toolkit';

export const selectDomain = (state: AppState): CommentsState.Domain => {
  return state.comments;
};

export const selectQuestionsResource = createSelector(selectDomain, (domain) => domain.questions);

export const selectQuestions = createSelector(
  selectQuestionsResource,
  (questionsResource) => questionsResource.data
);

export const selectHasAnyQuestions = createSelector(selectQuestions, (questions) => {
  return Array.isArray(questions) ? !isArrayEmpty(questions) : false;
});

export const selectAnswersResource = createSelector(selectDomain, (domain) => domain.answers);

export const selectAnswers = createSelector(
  selectAnswersResource,
  (answersResource) => answersResource.data
);

export const selectSentimentStats = createSelector(selectDomain, (domain) => domain.sentimentStats);

export const selectAllAnswersResource = createSelector(selectDomain, (domain) => domain.allAnswers);

export const selectQuestionsAndAnswersResource = createSelector(
  selectDomain,
  (domain) => domain.all
);

export const selectPagination = createSelector(selectDomain, (domain) => domain.pagination);

export const selectPaginationPage = createSelector(
  selectPagination,
  (pagination) => pagination?.index ?? 0
);

export const selectPaginationItemCount = createSelector(
  selectPagination,
  (pagination) => (pagination && pagination?.itemCount) ?? 0
);

export const selectAllSentimentTopics = createSelector(
  selectDomain,
  (domain) => domain.allSentimentTopics
);

export const selectSentimentFilter = createSelector(
  selectDomain,
  (domain) => domain.sentimentFilter
);

export const selectAdvocacyFilter = createSelector(selectDomain, (domain) => domain.advocacyFilter);

export const selectTopicFilter = createSelector(selectDomain, (domain) => domain.topicFilter);

export const selectSearchedText = createSelector(selectDomain, (domain) => domain.searchedText);

export const selectSelectedQuestionId = createSelector(
  selectDomain,
  (domain) => domain.selected?.id
);

export const selectSelectedOptionId = createSelector(
  selectDomain,
  (domain) => domain.selected?.optionId
);

export const selectHasAnswers = createSelector(selectAnswers, (answers) => !isArrayEmpty(answers));

export const selectReportSelectedQuestion = createSelector(
  selectQuestions,
  selectReportQuestionId,
  (questions, questionId) => questions?.find((question) => question.id === questionId) as Question
);

export const selectSelectedQuestion = createSelector(
  selectQuestions,
  selectSelectedQuestionId,
  selectSelectedOptionId,
  (questions, questionId, optionId) =>
    questions?.find((question) => question.id === questionId && question.option === optionId)
);
