import { styled } from '@mui/material';

export const MobileMenuButtonWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
  },
}));
