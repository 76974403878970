import React from 'react';

import { Row } from 'component/CompareMatrix/Row/Row';
import { ComparisionMatrix } from 'model/ComparisionMatrix';

export type BodyProps = {
  values: ComparisionMatrix.Value[];
  labels: ComparisionMatrix.Label[];
  columnNumber: number;
  colorType: ComparisionMatrix.ColorType;
  minResponses: number;
  categoriesId?: (number | null)[];
  onCategoryClick?: (id: number) => void;
  isReportView?: boolean;
  respondentProtectionMessage: string;
};

export const Body: React.FC<BodyProps> = (props) => (
  <tbody>
    {props.labels.map((label, index) => (
      <Row
        key={index}
        label={label.content}
        categoryId={props.categoriesId !== undefined ? props.categoriesId[index] : undefined}
        values={props.values[index]}
        formatType={label.contentType}
        isLatest={label.isLatest}
        colorType={props.colorType}
        labelIndex={index}
        labelsNumber={props.labels.length}
        columnNumber={props.columnNumber}
        minResponses={props.minResponses}
        isReportView={props.isReportView}
        onCategoryClick={props.onCategoryClick}
        submitType={label.submitType}
        respondentProtectionMessage={props.respondentProtectionMessage}
      />
    ))}
  </tbody>
);
