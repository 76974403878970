import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { store } from 'reduxStore/appStore';
import { theme } from 'app/theme/theme';
import { AppRoutes } from 'router/AppRoutes';
import { useInitializeGTM } from 'shared/hooks/useInitializeGTM';
import history from 'app/history';
import { WBGrowthBookProvider } from 'component/WBGrowthBookProvider/WBGrowthBookProvider';
import { ErrorBoundary } from 'component/ErrorBoundary/ErrorBoundary';
import { CssBaseline } from '@mui/material';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useRedirectToLogin } from 'shared/hooks/useRedirectToLogin';

export const App = () => {
  useInitializeGTM();
  useRedirectToLogin();

  return (
    <ErrorBoundary>
      <WBGrowthBookProvider>
        <PostHogProvider client={posthog}>
          <StoreProvider store={store}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <ConnectedRouter history={history}>
                  <AppRoutes />
                </ConnectedRouter>
              </ThemeProvider>
            </StyledEngineProvider>
          </StoreProvider>
        </PostHogProvider>
      </WBGrowthBookProvider>
    </ErrorBoundary>
  );
};
