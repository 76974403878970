import { categoryMapper } from 'container/categoryMapper';
import { questionMapper } from 'container/questionMapper';
import { opinionQuestionDetailsMapper } from 'container/opinionQuestionDetailsMapper';
import { compareGroupMapper } from 'container/compareGroupMapper';
import { trendMapper } from 'container/trendMapper';

import { OpinionQuestionMapper } from '../mapper/OpinionQuestionMapper';

export const opinionQuestionMapper = new OpinionQuestionMapper(
  categoryMapper,
  questionMapper,
  opinionQuestionDetailsMapper,
  compareGroupMapper,
  trendMapper
);
