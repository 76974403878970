import { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCompanyLogoUrl } from 'reduxStore/project/selectors';

export const useGetTitlePageLogos = () => {
  const [circleLogoUrl] = useState();
  const companyLogoUrl = useSelector(selectCompanyLogoUrl);

  // TODO Fetch CircleLogo

  return {
    circleLogoUrl: circleLogoUrl,
    companyLogoUrl: companyLogoUrl,
  };
};
