import React from 'react';
import { useSelector } from 'react-redux';

import { isArrayEmpty } from 'util/isEmpty';

import { CasContentWrapper } from 'view/CasPage/CasPage.s';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import {
  selectSimpleProjectCombineEmployeesNumber,
  selectSimpleProjectCombineQuestions,
  selectSimpleProjectCombineResource,
} from 'reduxStore/simpleProject/selectors';
import { fetchSimpleProject } from 'reduxStore/simpleProject/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { PeriodSelectionSlider } from 'component/PeriodSelectionSlider/PeriodSelectionSlider';
import { MonthlyBreakdownReport } from 'model/MonthlyBreakdownReport';
import { DownloadMonthlyBreakdownButton } from 'component/DownloadMonthlyBreakdownButton/DownloadMonthlyBreakdownButton';
import { LifecycleCharts } from 'component/LifecycleCharts/LifecycleCharts';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';
import { Typography } from '@mui/material';

import { FeedbackWrapper, TableWrapper } from './LifecyclePage.s';

export type LifecycleCombinePagePureProps = {
  questions: OpinionQuestionDetails.Combine[];
  responsesNumber: number;
  simpleProjectCombineResource: Resource<any>;
  handleChangeCommitted: () => void;
};

export const LifecycleCombinePagePure: React.FC<LifecycleCombinePagePureProps> = ({
  questions,
  responsesNumber,
  handleChangeCommitted,
  simpleProjectCombineResource,
}) => (
  <>
    <ResourceLoader resource={simpleProjectCombineResource}>
      <LifecycleCharts />
      <PeriodSelectionSlider onChangeCommitted={handleChangeCommitted} />
      <CasContentWrapper data-testid="resourceLoader">
        {!isArrayEmpty(questions) && (
          <>
            <FeedbackWrapper>
              <Typography variant="h5" data-testid="questionContentLabel">
                Based on feedback from {responsesNumber} employees
              </Typography>

              <TableWrapper>
                <ScrollBar
                  maxWidth={'unset'}
                  minWidth={500}
                  maxHeight={'max-content'}
                  overflow={'horizontal'}
                  light
                  float={'none'}
                >
                  <SortableQuestionTable
                    data={questions}
                    width="100%"
                    shouldRenderCategory={false}
                    isReportView={false}
                  />
                </ScrollBar>
              </TableWrapper>
            </FeedbackWrapper>
            <DownloadMonthlyBreakdownButton
              monthlyBreakdownReport={MonthlyBreakdownReport.Summary}
            />
          </>
        )}
      </CasContentWrapper>
    </ResourceLoader>
  </>
);

export const LifecycleCombinePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const responsesNumber = useSelector(selectSimpleProjectCombineEmployeesNumber);
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);

  const handleChangeCommitted = () => dispatch(fetchSimpleProject());

  return (
    <LifecycleCombinePagePure
      questions={questions}
      responsesNumber={responsesNumber}
      simpleProjectCombineResource={simpleProjectCombineResource}
      handleChangeCommitted={handleChangeCommitted}
    />
  );
};
