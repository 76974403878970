import { feedbackMapper } from 'container/feedbackMapper';
import { questionMapper } from 'container/questionMapper';
import { trendMapper } from 'container/trendMapper';

import { OpinionQuestionDetailsMapper } from '../mapper/OpinionQuestionDetailsMapper';

export const opinionQuestionDetailsMapper = new OpinionQuestionDetailsMapper(
  feedbackMapper,
  questionMapper,
  trendMapper
);
