import { selectFiltersQuery } from 'util/selector/querySelector';

import { ResponseRate } from 'model/ResponseRate';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { STORE_NAME } from 'reduxStore/respondent/initialState';
import { projectApi } from 'container/projectApi';

export const fetchProjectResponseRate = createAsyncThunk<ResponseRate, { projectId: number }>(
  `${STORE_NAME}/fetchRespondents`,
  ({ projectId }, { getState }) => {
    const query = selectFiltersQuery(getState());
    return projectApi.getResponseRate(projectId, query);
  }
);
