import { convertObjectToArray } from 'util/convertObjectToArray';

import { Question } from 'model/Question';
import { QuestionApi } from 'api/QuestionApi';
import { Sentiment, SentimentEnum } from 'model/Sentiment';
import moment from 'moment';

import { CompareGroupMapper } from './CompareGroupMapper';
export class QuestionMapper {
  constructor(private compareGroupMapper: CompareGroupMapper) {}

  deserialize(
    entry: QuestionApi.Entry,
    groups?: { [key: number]: string },
    protections?: { [key: number]: boolean }
  ): Question {
    return {
      id: entry.id,
      content: entry.content,
      kind: entry.kind,
      option: entry.option,
      askedAt: entry.asked_at ? moment(entry.asked_at) : undefined,
      isLatest: entry.isLatest,
      groups: entry.byGroup
        ? convertObjectToArray(entry.byGroup).map((group) =>
            this.compareGroupMapper.deserialize(group, groups, protections)
          )
        : undefined,
      sentiment: this.deserializeSentiment(entry.sentiment),
      trendPlotData: entry.trendPlotData?.map((elem) => elem.value),
    };
  }

  deserializeSentiment(apiSentiment: QuestionApi.Entry['sentiment']): SentimentEnum | null {
    return Sentiment.isSentimentSet(apiSentiment) ? apiSentiment : null;
  }
}
