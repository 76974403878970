import { styled } from '@mui/material';
import { GREY_TEXT } from 'app/theme/colors';

export type ButtonProps = {
  color?: string;
  backgroundColor?: string;
  fontWeight?: any;
};

export const Button = styled('button', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<ButtonProps>(
  {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    height: '38px',
    border: 'none',
    borderRadius: '25px',
    backgroundColor: 'white',
    minWidth: 100,
    padding: '0 25px',
    fontSize: 15,
    ':hover': {
      cursor: 'pointer',
    },
    ':focus': {
      outline: 0,
      transition: 'background-color 0.5s ease-out',
    },
  },
  (props: ButtonProps) => ({
    color: props.color ? props.color : GREY_TEXT,
    backgroundColor: props.backgroundColor ? props.backgroundColor : 'transparent',
    fontWeight: props.fontWeight ? props.fontWeight : 400,
  })
);
