import moment from 'moment';

export type ResponseCountDto = Readonly<{
  date: string;
  value: number;
}>;

export type ResponseCountModel = {
  value: number;
  date: string;
  dateLabel: string;
};

export const mapResponseCountDtoListToModes = (dtoList: ResponseCountDto[]): ResponseCountModel[] =>
  dtoList.map((dto, index) => {
    const date = moment(dto.date);

    return {
      date: date.format('MMM YY'),
      value: dto.value,
      dateLabel: index === 0 ? date.format('MMM YY') : date.format('MMM'),
    };
  });
