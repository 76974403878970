import React from 'react';
import { useSelector } from 'react-redux';

import { ReportSummaryWidgets } from 'view/ReportView/SummaryWidgetReport/ReportSummaryWidgets';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import {
  selectSimpleProjectCombineBarChart,
  selectSimpleProjectCombinePieChart,
  selectSimpleProjectCombineQuestionContent,
  selectSimpleProjectCombineQuestions,
} from 'reduxStore/simpleProject/selectors';
import { PieChart as PieChartModel } from 'model/PieChart';
import { PieChart } from 'component/PieChart/PieChart';
import { BarChartModel } from 'model/BarChart';
import { BarChart } from 'component/BarChart/BarChart';
import { QuestionTable } from 'view/EngagementDriverByCategoryPage/QuestionTable/QuestionTable';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { CasChartsWrapper } from 'view/ReportView/FeedbackReport/Cas/CasReport.s';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';

export type CasReportCombinePureProps = {
  questions: OpinionQuestionDetails.Combine[];
  isAdmin: boolean;
  barChart: BarChartModel[];
  pieChart: PieChartModel[];
  casQuestion: string;
};

export const CasReportCombinePure: React.FC<CasReportCombinePureProps> = (props) => {
  return (
    <div>
      <ReportContent title="Summary" component={<ReportSummaryWidgets isReportView />} />
      <ReportContent
        title={props.casQuestion}
        component={
          <CasChartsWrapper>
            <PieChart data={props.pieChart} />
            <BarChart data={props.barChart} />
          </CasChartsWrapper>
        }
      />
      <ReportContent
        title="Survey Questions"
        component={
          <QuestionTable
            data={props.questions}
            width="max-content"
            isReportView
            shouldRenderCategory={false}
          />
        }
      />
    </div>
  );
};

export const CasReportCombine: React.FC = () => {
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const casQuestion = useSelector(selectSimpleProjectCombineQuestionContent)!;
  const isAdmin = useSelector(selectIsAdmin);
  const pieChart = useSelector(selectSimpleProjectCombinePieChart);
  const barChart = useSelector(selectSimpleProjectCombineBarChart);

  return (
    <CasReportCombinePure
      questions={questions}
      casQuestion={casQuestion}
      isAdmin={isAdmin}
      pieChart={pieChart}
      barChart={barChart}
    />
  );
};
