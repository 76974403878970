import { Feedback } from 'model/Feedback';
import { OpinionQuestionApi } from 'api/OpinionQuestionApi';

export class FeedbackMapper {
  deserialize(entry: OpinionQuestionApi.FeedbackEntry): Feedback {
    return {
      label: entry.label,
      value: entry.value,
      options: entry.options,
    };
  }
}
