import React, { useState } from 'react';

import { TopNavBar } from 'component/TopNavBar/TopNavBar';
import { RibbonNavBar } from 'component/RibbonNavBar/RibbonNavBar';
import { NavigationMenuItems } from 'component/Navigation/NavigationMenuItems';
import { MobileMenuButton } from 'component/MobileMenuButton/MobileMenuButton';
import { useMediaQuery, useTheme } from '@mui/material';
import { TopNavBarDropdown } from 'component/TopNavBar/TopNavBarDropdown';
import { MobileNavigationMenu } from 'component/Navigation/MobileNavigationMenu';
import { useGetVisibleRibbonNavBarItems } from 'component/RibbonNavBar/hooks/useGetVisibleRibbonNavBarItems';

import { TopNavBarDropdownWrapper } from './PulseProjectNavigation.s';
import { PulseNavigationMenuItemWrapper } from './Navigation.s';

export const ProjectNavigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const visibleRibbonNavBarItems = useGetVisibleRibbonNavBarItems();
  const hasVisibleRibbonNavBarItems = visibleRibbonNavBarItems.length > 0;

  return (
    <>
      <TopNavBar>
        {isMobile && (
          <>
            <MobileMenuButton
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              isMenuOpen={isMobileMenuOpen}
            />
            <MobileNavigationMenu isOpen={isMobileMenuOpen} shouldShowKnowledgebaseLink={false} />
          </>
        )}
        {!isMobile && (
          <TopNavBarDropdownWrapper>
            <TopNavBarDropdown />
          </TopNavBarDropdownWrapper>
        )}
      </TopNavBar>

      {!isMobile && (
        <PulseNavigationMenuItemWrapper
          hasVisibleRibbonNavBarItems={hasVisibleRibbonNavBarItems}
          data-testid="desktopNavigationItems"
        >
          <NavigationMenuItems />
        </PulseNavigationMenuItemWrapper>
      )}

      {hasVisibleRibbonNavBarItems && (
        <RibbonNavBar visibleRibbonNavBarItems={visibleRibbonNavBarItems} />
      )}
    </>
  );
};
