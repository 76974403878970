import { styled } from '@mui/material';
import { WHITE } from 'app/theme/colors';

export type TableWrapperProps = {
  width?: number | string;
};

export const TableWrapper = styled('table')((props: TableWrapperProps) => ({
  width: props.width ? props.width : '100%',
  background: WHITE,
  borderRadius: '10px',
}));
