import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { usePrevious, useUnmount } from 'react-use';

import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import {
  selectRespondentFilters,
  selectRespondentsTablePage,
} from 'reduxStore/respondent/selectors';
import { fetchRespondents } from 'reduxStore/respondent/asyncActions';
import { RespondentState } from 'reduxStore/respondent/initialState';
import { setRespondentsFilters, setRespondentsTablePage } from 'reduxStore/respondent/slice';
import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';
import { selectGroupedClassifications } from 'reduxStore/demography/selectors';
import { useDebouncedFilters } from 'shared/hooks/useDebouncedFilters';
import { selectIsClearingAllFilters } from 'reduxStore/visualSettings/selectors';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import { useDebounceValue } from 'shared/hooks/useDebounceValue';

const PAGE_LIMIT = 10;

export const useFetchRespondents = () => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  const filters = useSelector(selectRespondentFilters);
  const page = useSelector(selectRespondentsTablePage);
  const previousPage = usePrevious(page);
  const isClearingAllFilters = useSelector(selectIsClearingAllFilters);

  const departments = useSelector(selectSelectedDepartmentIds);
  const classifications = useSelector(selectGroupedClassifications);

  const selectedDepartmentsDebounced = useDebounceValue(
    departments,
    FILTERS_CHANGED_DEBOUNCE_TIMEOUT
  );
  const selectedClassificationsDebounced = useDebounceValue(
    classifications,
    FILTERS_CHANGED_DEBOUNCE_TIMEOUT
  );

  const onFiltersChange = (filters: RespondentState.RespondentsFilters) => {
    dispatch(setRespondentsFilters(filters));
  };

  const { debouncedFilters, mergeFilters } = useDebouncedFilters<
    RespondentState.RespondentsFilters
  >({ filters, onFiltersChange, debounceMs: FILTERS_CHANGED_DEBOUNCE_TIMEOUT });

  useEffect(() => {
    const shouldResetPage = previousPage === page;
    if (shouldResetPage) {
      dispatch(setRespondentsTablePage(1));
    }
    if (!isClearingAllFilters) {
      dispatch(
        fetchRespondents({
          respondentLikeFilter: debouncedFilters.respondentLikeFilter,
          advocacyFilter: debouncedFilters.advocacyFilter,
          page: shouldResetPage ? 1 : page,
          limit: PAGE_LIMIT,
          projectId,
          departments,
          classifications,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedFilters.respondentLikeFilter,
    debouncedFilters.advocacyFilter,
    selectedDepartmentsDebounced,
    selectedClassificationsDebounced,
    page,
    projectId,
  ]);

  useUnmount(() => {
    dispatch(setRespondentsTablePage(1));
    dispatch(setRespondentsFilters({ advocacyFilter: [], respondentLikeFilter: '' }));
  });

  return { filters, mergeFilters };
};
