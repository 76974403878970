import React from 'react';
import { useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { useAppDispatch } from 'reduxStore/appStore';
import {
  selectCurrentPulseProjectAvailableProjectsOptionId,
  selectPulseProjectAvailableProjectsOptions,
} from 'reduxStore/project/selectors';
import { changeProject as changeProjectAction } from 'reduxStore/router/asyncActions';
import { ProjectKind } from 'model/ProjectKind';
import { resetAggregationPeriod } from 'reduxStore/filtering/slice';
import { Typography } from '@mui/material';
import { ProjectModel } from 'model/Project.model';
import { ExpandMore } from '@mui/icons-material';

import {
  WBPulseSelectPaperStyles,
  WBPulseSelectStyles,
  WBPulseSelectWrapper,
} from './PulseSelect.s';

export interface PulseNavigationOption {
  label: string;
  projectId: ProjectModel['id'];
  kind: ProjectKind;
}

export type PulseSelectPureProps = {
  options: PulseNavigationOption[];
  currentlyChosenOptionId: ProjectModel['id'];
  changeProject(projectId: ProjectModel['id']): void;
};

const PulseSelectPure: React.FC<PulseSelectPureProps> = ({
  options,
  currentlyChosenOptionId,
  changeProject,
}) => {
  const handleChange: SelectInputProps['onChange'] = (event) => {
    changeProject(event.target.value as ProjectModel['id']);
  };

  return (
    <WBPulseSelectWrapper data-testid="projectTypeSelector">
      <Select
        value={currentlyChosenOptionId}
        onChange={handleChange}
        variant="standard"
        sx={WBPulseSelectStyles}
        IconComponent={ExpandMore}
        MenuProps={{
          PaperProps: {
            sx: WBPulseSelectPaperStyles,
          },
        }}
      >
        {options.map(({ projectId, label }, index) => (
          <MenuItem value={projectId} key={index} data-testid="projectTypeList">
            <Typography variant="h5">{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </WBPulseSelectWrapper>
  );
};

export const PulseSelect: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentlyChosenOptionId = useSelector(selectCurrentPulseProjectAvailableProjectsOptionId);
  const options = useSelector(selectPulseProjectAvailableProjectsOptions);

  const changeProject = (projectId: ProjectModel['id']) => {
    dispatch(resetAggregationPeriod());
    dispatch(changeProjectAction({ projectId, navigateToSummary: true }));
  };

  return (
    <PulseSelectPure
      currentlyChosenOptionId={currentlyChosenOptionId}
      options={options}
      changeProject={changeProject}
    />
  );
};
