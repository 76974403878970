import React from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { NotFoundPageContentWrapper } from 'router/components/NotFound/NotFound.s';
import { Box, Typography } from '@mui/material';
import { NavItem } from 'component/NavItem/NavItem';
import { compilePath } from 'router/compilePath';
import { AppRoute } from 'app/route/app';
import { BUTTON_ORANGE, WHITE } from 'app/theme/colors';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { clearDemography } from 'reduxStore/demography/slice';
import { useAppDispatch } from 'reduxStore/appStore';
import { clearDepartments } from 'reduxStore/departments/slice';

export const NotFoundPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentProjectId = useSelector(selectCurrentProjectId);

  useMount(() => {
    dispatch(clearDemography());
    dispatch(clearDepartments());
  });

  return (
    <NotFoundPageContentWrapper data-testid="notFoundPage">
      <Box component="h2" sx={{ pb: 2 }}>
        Page not found
      </Box>
      <Typography variant="body1">
        It seems that page has moved or doesn’t exist anymore.
      </Typography>
      <Typography variant="body1" mb={2}>
        If the error continues please email{' '}
        <a href="mailto:support@workbuzz.com" target="_blank">
          support@workbuzz.com
        </a>
        .
      </Typography>
      <NavItem
        data-testid="returnToProjectSummaryButton"
        label="Return to project summary"
        href={compilePath(AppRoute.Home.SUMMARY, { id: currentProjectId })}
        backgroundColor={BUTTON_ORANGE}
        activeBackgroundColor={BUTTON_ORANGE}
        color={WHITE}
        activeColor={WHITE}
        style={{ margin: '0 10px' }}
      />
    </NotFoundPageContentWrapper>
  );
};
