import { PulseApi } from 'api/PulseApi';
import { Pulse } from 'model/Pulse';
import moment from 'moment';

export class PulseMapper {
  deserialize({ id, closedAt, startedAt, name, lifecycleType }: PulseApi.Entry): Pulse {
    return {
      name,
      id,
      closedAt: moment(closedAt),
      startedAt: moment(startedAt),
      lifecycleType,
    };
  }
}
