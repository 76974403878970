import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AdvocacyDtoEnum } from 'api/dto/Advocacy.dto';
import { SentimentEnum, SentimentStats } from 'model/Sentiment';
import { Pagination } from 'model/Pagination';
import { addAsyncCases } from 'shared/utils/redux';
import {
  fetchAllSentimentTopics,
  fetchAllComments,
  fetchCommentAnswers,
  fetchCommentsQuestions,
  fetchReportAnswers,
} from 'reduxStore/comments/asyncActions';
import { QuestionAndAnswers } from 'model/QuestionAndAnswers';
import { Resource } from 'model/Resource';
import { Answer } from 'model/Answer';
import { Question } from 'model/Question';

import { CommentsState, STORE_NAME } from './initialState';

export const commentsSlice = createSlice({
  name: STORE_NAME,
  initialState: CommentsState.INITIAL_DOMAIN,
  reducers: {
    clearComments: (state) => {
      state.questions = Resource.createPendingResource([]);
      state.answers = CommentsState.INITIAL_DOMAIN.answers;
      state.allAnswers = Resource.createPendingResource([]);
      state.all = Resource.createPendingResource([]);
      state.selected = null;
      state.pagination = null;
      state.allSentimentTopics = Resource.createPendingResource({ data: {} });
      state.sentimentFilter = [];
      state.topicFilter = [];
      state.searchedText = '';
      state.sentimentStats = null;
      state.advocacyFilter = [];
    },
    clearAllTopics: (state) => {
      state.allSentimentTopics = CommentsState.INITIAL_DOMAIN.allSentimentTopics;
    },
    setTopicFilter: (state, action: PayloadAction<number[]>) => {
      state.topicFilter = action.payload;
    },
    setAdvocacyFilter: (state, action: PayloadAction<AdvocacyDtoEnum[]>) => {
      state.advocacyFilter = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchedText = action.payload;
    },
    setSentimentFilter: (state, action: PayloadAction<SentimentEnum[]>) => {
      state.sentimentFilter = action.payload;
    },
    setPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    resetPaginationPage: (state) => {
      state.pagination = state.pagination ? { ...state.pagination, index: 1 } : null;
    },
    setEmptyResolvedCommentsAnswers: (state) => {
      state.answers = Resource.createResolvedResource({ data: [] });
    },
    resetCommentsQuestions: (state) => {
      state.questions = Resource.createPendingResource([]);
    },
    setSelectedQuestion: (state, action: PayloadAction<CommentsState.Domain['selected']>) => {
      state.selected = action.payload;
    },
    resetSelectedQuestion: (state) => {
      state.selected = null;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchAllComments, (state, action) => {
      state.all = Resource.createResolvedResource({
        data: (action.payload.data || []) as Draft<QuestionAndAnswers[]>,
      });
    });
    addAsyncCases(builder, fetchReportAnswers, (state, action) => {
      state.allAnswers = Resource.createResolvedResource({
        data: (action.payload.data || []) as Draft<Answer[]>,
      });
    });
    addAsyncCases(builder, fetchAllSentimentTopics, (state, action) => {
      state.allSentimentTopics = action.payload.data || {};
    });
    addAsyncCases(builder, fetchCommentsQuestions, (state, action) => {
      state.questions = action.payload as Resource<Draft<Question[]>>;
    });
    builder
      .addCase(fetchCommentAnswers.fulfilled, (state, action) => {
        state.answers = Resource.createResolvedResource({ data: action.payload.data });
        state.sentimentStats = (action.payload.sentimentStats ||
          null) as Draft<SentimentStats | null>;
      })
      .addCase(fetchCommentAnswers.rejected, (state, asction) => {
        state.answers = Resource.createRejectedResource({
          data: [],
          error: asction.error,
        }) as Resource<Answer[]>;
        state.sentimentStats = null;
      });
  },
});

export const {
  clearComments,
  clearAllTopics,
  setSearchText,
  setTopicFilter,
  setAdvocacyFilter,
  setSentimentFilter,
  setPagination,
  resetPaginationPage,
  setEmptyResolvedCommentsAnswers,
  resetCommentsQuestions,
  setSelectedQuestion,
  resetSelectedQuestion,
} = commentsSlice.actions;
