import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { NotFoundPage } from 'router/components/NotFound/NotFoundPage';

import { NavigationItem } from '../models';

type RenderRoutesProps = {
  routes: NavigationItem[];
  RelativeRoute: React.FC<RouteProps>;
};

export const renderRoutes = ({ RelativeRoute, routes }: RenderRoutesProps) => {
  const filteredNavigation = routes.filter((nav) => nav.canActivate);

  if (filteredNavigation.length < 1) {
    return <></>;
  }

  const result = filteredNavigation.map(({ component: Component, path }) => {
    return <RelativeRoute key={path.toString()} path={path} component={Component} />;
  });

  result.push(<Route key="not-found" component={NotFoundPage} />);

  return result;
};
