import React from 'react';

import TooltipArrowSvg from 'app/asset/tooltip-arrow.svg';
import {
  AvatarStyled,
  EmployeeInfoWrapper,
  EmployeeName,
} from 'component/EmployeeInfo/EmployeeInfo.s';
import { RespondentModel } from 'api/dto/Respondent.dto';
import { Box, Tooltip, Typography } from '@mui/material';

export type EmployeeInfoProps = {
  respondent: RespondentModel;
};

export const EmployeeInfo = ({ respondent }: EmployeeInfoProps) => {
  const { firstName, lastName, employedSince, departmentName, departments } = respondent;
  const employeeFullName = [firstName, lastName].join(' ');
  const employeeInitials = [firstName, lastName]
    .map((namePart) => namePart?.charAt(0).toUpperCase())
    .join('');

  const teamTooltipContent = departments && (
    <Box sx={{ maxWidth: '450px' }} data-testid="employeeInfoTeamTooltipContent">
      {departments.map((departmentName, index) => {
        const isLastDepartment = index === departments.length - 1;
        const isBold = isLastDepartment && index !== 0;
        return (
          <Typography
            sx={{ fontWeight: isBold ? 'bold' : undefined, color: 'white' }}
            display="inline-block"
            key={index}
            translate="no"
          >
            {departmentName}
            {!isLastDepartment && (
              // @ts-ignore
              <TooltipArrowSvg style={{ margin: '0 5px' }} width="8px" height="8px" />
            )}
          </Typography>
        );
      })}
    </Box>
  );

  return (
    <Box width={360} sx={{ display: 'flex', marginRight: 2 }} data-testid="employeeInfoWrapper">
      <AvatarStyled>{employeeInitials}</AvatarStyled>
      <EmployeeInfoWrapper>
        <Tooltip title={employeeFullName} placement="bottom" enterTouchDelay={0} arrow>
          <EmployeeName>{employeeFullName}</EmployeeName>
        </Tooltip>
        {employedSince && (
          <Box sx={{ display: 'flex', alignItems: 'center' }} data-cy="employeeStartDate">
            <Typography variant="body2">Start Date:</Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 800, paddingLeft: 1 }}>
              {employedSince.format('DD MMM YYYY')}
            </Typography>
          </Box>
        )}
        <Tooltip title={teamTooltipContent || ''} placement="bottom" enterTouchDelay={0} arrow>
          <Box sx={{ display: 'flex', alignItems: 'center' }} data-cy="employeeTeam">
            <Typography variant="body2">Team:</Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 800, paddingLeft: 1 }}>
              {departmentName}
            </Typography>
          </Box>
        </Tooltip>
      </EmployeeInfoWrapper>
    </Box>
  );
};
