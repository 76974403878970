import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { selectModeType } from 'util/selector/querySelector';

import { useAppDispatch } from 'reduxStore/appStore';
import {
  selectCurrentProjectId,
  selectIsEngagementScoreCompatibleProject,
} from 'reduxStore/project/selectors';
import {
  fetchEngagementScore,
  fetchEngagementScoreDebounced,
} from 'reduxStore/engagementDrivers/asyncActions';
import { selectSelectedClassifications } from 'reduxStore/demography/selectors';
import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';
import { clearVisualSettings } from 'reduxStore/visualSettings/slice';

export const useFetchEngagementScore = () => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  const isCompatibleProjectKind = useSelector(selectIsEngagementScoreCompatibleProject);
  const selectedClassificationsString = useSelector(selectSelectedClassifications).toString();
  const selectedDepartmentIdsString = useSelector(selectSelectedDepartmentIds).toString();
  const modeType = useSelector(selectModeType);

  useEffect(() => {
    if (isCompatibleProjectKind) dispatch(fetchEngagementScore());

    return () => {
      if (isCompatibleProjectKind) {
        dispatch(clearVisualSettings());
      }
    };
  }, [dispatch, isCompatibleProjectKind, modeType]);

  useUpdateEffect(() => {
    if (isCompatibleProjectKind) dispatch(fetchEngagementScoreDebounced());
  }, [dispatch, selectedClassificationsString, selectedDepartmentIdsString, projectId]);
};
