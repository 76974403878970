import {
  selectIsSentimentAnalysisEnabled,
  selectSentimentIsLoaded,
} from 'reduxStore/project/selectors';
import {
  selectHasCommentsAnalysisPermission,
  selectHasTopicsAnalysisPermission,
} from 'reduxStore/user/selectors';
import { createSelector } from 'reselect';

/**
 * Both comments and topics require both user to have permissions and project to has analysis enabled
 */
export const selectIsCommentsAnalysisEnabled = createSelector(
  selectIsSentimentAnalysisEnabled,
  selectHasCommentsAnalysisPermission,
  (enabled, hasAccess) => enabled && hasAccess
);

export const selectIsTopicsAnalysisEnabled = createSelector(
  selectIsSentimentAnalysisEnabled,
  selectHasTopicsAnalysisPermission,
  (enabled, hasAccess) => enabled && hasAccess
);

export const selectIsSentimentLoadPending = createSelector(
  selectIsCommentsAnalysisEnabled,
  selectSentimentIsLoaded,
  (enabled, isSentimentLoaded) => enabled && !isSentimentLoaded
);
