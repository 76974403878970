import { Box, styled } from '@mui/material';
import { WHITE } from 'app/theme/colors';

export const TopicPageCard = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: WHITE,
  borderRadius: '10px',
  padding: 24,
});
