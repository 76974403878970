import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';

import { STORE_NAME, TopicsState } from './initialState';

export const selectTopicsResource = (state: AppState) => state[STORE_NAME];

export const selectTopicsData = createSelector(selectTopicsResource, (domain) => domain.data);

export const selectTopicsSummary = createSelector(
  selectTopicsData,
  (data) => data?.summary || TopicsState.INITIAL_DOMAIN_SUMMARY
);

export const selectTopicsSummaryScore = createSelector(
  selectTopicsSummary,
  (summary) => summary.score
);

export const selectTopicsSummaryCommentsCount = createSelector(
  selectTopicsSummary,
  (summary) => summary.commentCount
);

export const selectTopics = createSelector(
  selectTopicsData,
  (data) => data?.topics || TopicsState.INITIAL_DOMAIN_TOPICS
);

export const selectAreTopicsEmpty = createSelector(
  selectTopics,
  (topics = []) => topics.length === 0
);
