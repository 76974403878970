import { styled } from '@mui/material';
import { css } from 'glamor';
import { GREY_BACKGROUND } from 'app/theme/colors';

export type CategoryWrapperProps = {
  isReportView?: boolean;
};

export const CategoryWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isReportView',
})((props: CategoryWrapperProps): any => ({
  margin: props.isReportView ? 0 : '32px 32px',
}));

export const CategoryTitle = styled('p')(({ theme }) => ({
  fontSize: 45,
  fontWeight: 300,
  [theme.breakpoints.down('md')]: {
    fontSize: 30,
  },
}));

export type BarWrapperProps = {
  isReportView?: boolean;
};

export const BarWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isReportView',
})<BarWrapperProps>(({ theme, isReportView }) => ({
  marginBottom: 30,
  alignItems: 'center',
  maxWidth: 990,
  display: 'flex',
  paddingRight: 30,
  '& > *': {
    padding: '0 10px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: isReportView ? 'row' : 'column',
    paddingRight: isReportView ? 30 : 0,
  },
}));

export type StatisticsWrapperProps = {
  isReportView?: boolean;
};

export const StatisticsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isReportView',
})<StatisticsWrapperProps>(({ theme, isReportView }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '1.5rem',
  [theme.breakpoints.down('md')]: {
    justifyContent: isReportView ? 'flex-start' : 'space-around',
    gap: isReportView ? '1.5rem' : 'unset',
  },
}));

export const toolTipStyle = css({
  backgroundColor: `${GREY_BACKGROUND} !important`,
  opacity: '0.8 !important',
  '& p': {
    paddingTop: '5px',
  },
  '&::after': {
    borderRightColor: `${GREY_BACKGROUND} !important`,
  },
});
