import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Header } from 'component/CompareMatrix/Header/Header';
import { Body } from 'component/CompareMatrix/Body';
import {
  MatrixContainer,
  Matrix,
  RestrictionMessage,
} from 'component/CompareMatrix/CompareMatrix.s';
import { ComparisionMatrix } from 'model/ComparisionMatrix';
import { selectProjectKind, selectRespondentProtection } from 'reduxStore/project/selectors';
import { RespondentProtectionEnum, RespondentProtectionMode } from 'model/RespondentProtection';
import { ProjectKind } from 'model/ProjectKind';
import { useAtom } from 'jotai';
import { isVariableConfidentialityCompareAtom } from 'shared/state/atoms';
import { SxProps, Typography } from '@mui/material';

export type CompareMatrixProps = {
  title?: string;
  titleDataTestId?: string;
  titleDataCy?: string;
  sx?: SxProps;
  departments: string[];
  labels: ComparisionMatrix.Label[];
  values: ComparisionMatrix.Value[];
  colorType: ComparisionMatrix.ColorType;
  minResponses: number;
  isReportView?: boolean;
  categoriesId?: (number | null)[];
  onCategoryClick?: (id: number) => void;
  overflow?: string;
  'data-testid'?: string;
};

export const CompareMatrix: React.FC<CompareMatrixProps> = (props) => {
  const [, setIsVariableConfidentialityCompareAtom] = useAtom(isVariableConfidentialityCompareAtom);

  const hasRestrictedAnswers = props.values.some(
    (item) => item.value.indexOf(undefined || null) >= 0
  );

  useEffect(() => {
    const isVariableRespondentProtectionType = props.values.some(
      (item) =>
        item.respondentProtectionTypeArray.indexOf(
          RespondentProtectionEnum.VARIABLE_CONFIDENTIALITY_PROTECTION
        ) >= 0
    );

    setIsVariableConfidentialityCompareAtom(isVariableRespondentProtectionType);

    return () => {
      setIsVariableConfidentialityCompareAtom(false);
    };
  }, [props.values, setIsVariableConfidentialityCompareAtom]);

  const protectionSettings = useSelector(selectRespondentProtection);
  const thresholdType =
    protectionSettings?.mode === RespondentProtectionMode.InvitedEmployees ? 'people' : 'responses';
  const projectKind = useSelector(selectProjectKind);
  const isCAS = ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE === projectKind;

  const respondentProtectionMessage =
    !isCAS && thresholdType
      ? `Less than ${props.minResponses} ${thresholdType}. Answers are restricted.`
      : `Less than ${props.minResponses} responses. Answers are restricted.`;

  return (
    <MatrixContainer
      data-testid={props['data-testid'] || 'compareMatrix'}
      sx={props.sx}
      isReportView={props.isReportView}
    >
      {props.title && (
        <Typography
          variant="h5"
          m={3}
          data-testid={props.titleDataTestId}
          data-cy={props.titleDataCy}
        >
          {props.title}
        </Typography>
      )}
      <Matrix>
        <Header names={props.departments} />
        <Body
          labels={props.labels}
          values={props.values}
          colorType={props.colorType}
          columnNumber={props.departments.length}
          minResponses={props.minResponses}
          categoriesId={props.categoriesId}
          onCategoryClick={props.onCategoryClick}
          isReportView={props.isReportView}
          respondentProtectionMessage={respondentProtectionMessage}
        />
      </Matrix>
      {hasRestrictedAnswers && props.isReportView && (
        <RestrictionMessage data-testid="compareMatrixRestrictionMessage">
          (-) {respondentProtectionMessage}
        </RestrictionMessage>
      )}
    </MatrixContainer>
  );
};
