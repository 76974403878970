import { Department } from 'model/Department';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'departments';

export namespace DepartmentState {
  export type Domain = {
    list: Resource<Department[]>;
  };

  export const INITIAL_DOMAIN: Domain = {
    list: Resource.createPendingResource([]),
  };
}

export type DepartmentState = {
  departments: DepartmentState.Domain;
};
