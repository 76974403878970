import { useSelector } from 'react-redux';

import { selectHasMatch } from 'util/selector/routerSelector';
import { selectReportProjectId } from 'util/selector/reportSelector';

import { ReportRoute } from 'app/route/report';
import {
  selectCurrentProjectId,
  selectIsLifecycleProjectKind,
  selectIsOnDemandProjectKind,
  selectIsPulseProjectKind,
  selectPulseTemplateId,
} from 'reduxStore/project/selectors';
import { getUserPermissions } from 'reduxStore/user/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';

export const useGetUserPermissionsCallback = () => {
  const dispatch = useAppDispatch();

  const isReport = useSelector(selectHasMatch(ReportRoute.REPORT_PATTERN));
  const isDemandPoll = useSelector(selectIsOnDemandProjectKind);
  const isPulse = useSelector(selectIsPulseProjectKind);
  const isLifecycle = useSelector(selectIsLifecycleProjectKind);

  const pulseTemplateId = useSelector(selectPulseTemplateId);
  const reportProjectId = useSelector(selectReportProjectId);
  const currentProjectId = useSelector(selectCurrentProjectId);
  let projectId: number;

  if (isDemandPoll || isPulse || isLifecycle) {
    projectId = pulseTemplateId!;
  } else {
    projectId = isReport ? reportProjectId : currentProjectId;
  }

  return () => dispatch(getUserPermissions({ projectId }));
};
