import React from 'react';
import { useSelector } from 'react-redux';

import { scrollTop } from 'util/scrollTop';

import {
  EngagementContentWrapper,
  EngagementData,
  EngagementTitle,
} from 'view/EngagementDriversView/EngagementDriversView.s';
import { AppRoute } from 'app/route/app';
import { routeWithQuery } from 'reduxStore/router/asyncActions';
import {
  selectCurrentProjectId,
  selectIsPulseProjectFamilyKind,
} from 'reduxStore/project/selectors';
import { Trend } from 'model/Trend';
import { setEngagementDriverByCategory } from 'reduxStore/engagementDrivers/asyncActions';
import { selectHasAnyBenchmark } from 'reduxStore/engagementDrivers/selectors';
import { Benchmark } from 'model/Benchmark';
import { useAppDispatch } from 'reduxStore/appStore';
import { LinearProgress, Typography } from '@mui/material';
import { progressBarStyles } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/EngagementScoreCombineWidget.s';

import { StatisticValues } from './StatisticValues';

export type SingleBarProps = {
  categoryId: number;
  categoryName: string;
  score: number;
  index: number;
  trends?: ReadonlyArray<Trend>;
  benchmark: Benchmark;
};

export type SingleBarPureProps = {
  currentProjectId: string;
  hasAnyBenchmark: boolean;
  isPulseProjectFamilyKind: boolean;
  onCategoryClick: (projectId: string, categoryId: number) => void;
} & SingleBarProps;

export const SingleBarPure: React.FC<SingleBarPureProps> = (props) => {
  return (
    <EngagementContentWrapper data-testid="engagementContentWrapper">
      <EngagementTitle
        data-testid="categoryTitle"
        onClick={() => props.onCategoryClick(props.currentProjectId, props.categoryId)}
      >
        {props.categoryName}
      </EngagementTitle>

      <EngagementData className="engagementData">
        <li>
          <LinearProgress
            sx={progressBarStyles}
            style={{ width: '100%', marginRight: '20px' }}
            value={props.score}
            variant="determinate"
          />
        </li>
        <Typography variant="h5" data-cy="categoryScore">
          {props.score}
        </Typography>

        <StatisticValues
          trends={props.trends}
          benchmark={props.benchmark}
          hasAnyBenchmark={props.hasAnyBenchmark}
          isPulseProjectFamilyKind={props.isPulseProjectFamilyKind}
          index={props.index}
        />
      </EngagementData>
    </EngagementContentWrapper>
  );
};

export const SingleBar: React.FC<SingleBarProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentProjectId = String(useSelector(selectCurrentProjectId));
  const hasAnyBenchmark = useSelector(selectHasAnyBenchmark);
  const isPulseProjectFamilyKind = useSelector(selectIsPulseProjectFamilyKind);

  const onCategoryClick = (projectId: string, categoryId: number) => {
    dispatch(
      routeWithQuery({
        pattern: AppRoute.Home.Summary.REPORT,
        params: { id: projectId, categoryId: categoryId },
      })
    );
    dispatch(setEngagementDriverByCategory());
    scrollTop();
  };

  return (
    <SingleBarPure
      {...props}
      currentProjectId={currentProjectId}
      hasAnyBenchmark={hasAnyBenchmark}
      isPulseProjectFamilyKind={isPulseProjectFamilyKind}
      onCategoryClick={onCategoryClick}
    />
  );
};
