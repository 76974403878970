import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Bar } from 'component/SentimentBar/Bar';
import { toLower } from 'lodash';
import { Sentiment, SentimentEnum, SentimentStats } from 'model/Sentiment';
import { ScaleLinear, scaleLinear } from 'd3-scale';
import debounce from 'lodash/debounce';
import { CARDINAL, GREEN_GARLANDS, GREEN_HAZE, WORKBUZZ_ORANGE } from 'app/theme/colors';
import { BREAKPOINT } from 'shared/utils/test/setViewportWidth';

import { BarWrapper } from './SentimentBar.s';

export namespace SentimentBar {
  export type StateProps = {};
  export type OwnProps = {
    height: number;
    data: SentimentStats;
  };

  export type Props = StateProps & OwnProps;
}

const marginRight = 8;

export const SentimentBar: React.FC<SentimentBar.Props> = (props) => {
  const sortedData = [
    {
      label: Sentiment.toString(SentimentEnum.Positive),
      value: props.data.positive,
      cumulative: 0,
      backgroundColor: Sentiment.getLabelColor(SentimentEnum.Positive),
      borderColor: GREEN_GARLANDS,
    },
    {
      label: Sentiment.toString(SentimentEnum.Neutral),
      value: props.data.neutral,
      cumulative: props.data.positive,
      backgroundColor: Sentiment.getLabelColor(SentimentEnum.Neutral),
      borderColor: WORKBUZZ_ORANGE,
    },
    {
      label: Sentiment.toString(SentimentEnum.Negative),
      value: props.data.negative,
      cumulative: props.data.positive + props.data.neutral,
      backgroundColor: Sentiment.getLabelColor(SentimentEnum.Negative),
      borderColor: CARDINAL,
    },
  ];
  const threeBorderColors = [GREEN_HAZE, WORKBUZZ_ORANGE, CARDINAL];

  const getInitialScale = useCallback(() => scaleLinear().domain([0, 100]), []);

  const xScale = useRef<ScaleLinear<number, number>>(getInitialScale());

  const [width, setWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const offsetWidth = document.body.offsetWidth;
      let containerWidth;
      if (offsetWidth < BREAKPOINT.Md) {
        containerWidth = offsetWidth - 109;
      } else if (offsetWidth < BREAKPOINT.Lg) {
        containerWidth = offsetWidth / 2 - 88;
      } else {
        containerWidth = offsetWidth / 2 - 231;
      }

      xScale.current = scaleLinear()
        .domain([0, 100])
        .range([0, containerWidth - marginRight]);
      setWidth(containerWidth);
    };

    const debouncedHandleResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedHandleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [getInitialScale]);

  return (
    <BarWrapper data-testid="sentimentBar">
      <svg width={width} height={props.height} style={{ borderRadius: '5px' }}>
        {sortedData.map((data, index) => (
          <Bar
            key={index}
            cumulative={data.cumulative}
            value={data.value}
            xScale={xScale.current}
            height={props.height}
            color={data.backgroundColor}
            marginRight={marginRight}
            tooltip={`${data.value}% of comments are ${toLower(data.label)}`}
            index={index}
            borderColors={threeBorderColors}
          />
        ))}
      </svg>
    </BarWrapper>
  );
};
