import React from 'react';

import { selectColorBySubmitType } from 'util/selectColorBySubmitType';

import {
  LegendLabel,
  LegendPoint,
  LegendPointWrapper,
  LegendWrapper,
} from 'component/Legend/Legend.s';
import { PieChart } from 'model/PieChart';

export namespace Legend {
  export type Props = {
    data: PieChart[];
  };
}

export const Legend: React.FC<Legend.Props> = (props) => (
  <LegendWrapper>
    {props.data.map((item, index) => (
      <LegendPointWrapper key={index}>
        <LegendPoint color={selectColorBySubmitType(item.type)} />
        <LegendLabel data-testid="legendLabel">{item.label}</LegendLabel>
      </LegendPointWrapper>
    ))}
  </LegendWrapper>
);
