export enum ModeType {
  COMPARED = 'compared',
  COMBINED = 'combined',
}

/**
 * Low frequency pulses category view type on summary page
 */
export enum SummaryCategoryViewMode {
  Category = '0',
  All = '1',
}

export const FILTER_COUNT_COMPARE_MODE = 2;
export const COMPARE_GROUPS_LIMIT_BOLD = 9;
export const COMPARE_GROUPS_LIMIT_DEFAULT = 16;
