import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PercentageBarType } from 'model/PercentageBarType';
import { ComponentLabel, ComponentWrapper } from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { PercentageBar } from 'component/PercentageBar/PercentageBar';
import { PercentageBarSize } from 'component/PercentageBar/PercentageBarSize';
import { useAppDispatch } from 'reduxStore/appStore';
import {
  selectIsLoadingResponseRate,
  selectResponseRateByProjectId,
} from 'reduxStore/responseRate/selectors';
import { fetchProjectResponseRate } from 'reduxStore/responseRate/asyncActions';
import { ResponseRate } from 'model/ResponseRate';
import { LoaderWrapper } from 'component/ResourceLoader/ResourceLoader.s';
import { LoadingIcon } from 'component/PageHeader/PageHeader.s';
import { DARK_ORANGE } from 'app/theme/colors';
import { clearResponseRate } from 'reduxStore/responseRate/slice';
import { SxProps, Typography } from '@mui/material';

export namespace ResponseRateWidget {
  export type State = {
    visible: boolean;
  };
}

export type ResponseRateWidgetProps = {
  projectId: number;
  isReportView?: boolean;
  icon?: ReactElement;
  percentageBarSize?: PercentageBarSize;
  minWidth?: number | string;
  sx?: SxProps;
  brakeAfter?: string;
};

export type ResponseRateWidgetPureProps = { responseRate: ResponseRate } & ResponseRateWidgetProps;

const ResponseRateWidgetPure: React.FC<ResponseRateWidgetPureProps> = (props) => (
  <ComponentWrapper
    sx={props.sx}
    minWidth={props.minWidth}
    data-testid="responseRateWidget"
    breakAfter={props.brakeAfter || 'auto'}
  >
    <ComponentLabel adjustToLeft>
      <Typography variant={'h5'}>Response Rate</Typography>
      {props.icon}
    </ComponentLabel>
    <PercentageBar
      type={PercentageBarType.ResponseRate}
      value={props.responseRate.score}
      responses={props.responseRate.responses}
      invited={props.responseRate.invited}
      size={props.percentageBarSize}
      isReportView={props.isReportView}
    />
  </ComponentWrapper>
);

export const ResponseRateWidget: React.FC<ResponseRateWidgetProps> = (props) => {
  const dispatch = useAppDispatch();
  const { projectId } = props;
  const responseRate = useSelector(selectResponseRateByProjectId(projectId));
  const isLoading = useSelector(selectIsLoadingResponseRate(projectId));
  const hasResponseRate = !!responseRate;

  useEffect(() => {
    if (!hasResponseRate) {
      dispatch(fetchProjectResponseRate({ projectId }));
    }
  }, [projectId, hasResponseRate, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearResponseRate());
    };
  }, [dispatch]);

  if (isLoading || !hasResponseRate) {
    return (
      <ComponentWrapper>
        <LoaderWrapper data-testid="loader">
          <LoadingIcon type="spin" color={DARK_ORANGE} height={40} width={40} />
        </LoaderWrapper>
      </ComponentWrapper>
    );
  }

  return <ResponseRateWidgetPure {...props} responseRate={responseRate as ResponseRate} />;
};
