import { isArrayEmpty } from 'util/isEmpty';

import { CompareGroup } from 'model/CompareGroup';

type DataType = 'group' | 'label' | 'data';

export const mapDataToCompareMatrix = <R>(
  items: CompareGroup[] | any,
  keys: string[],
  type: DataType,
  index: number = 0
): R[] => {
  const result: R[] = [];
  const lastKey = keys[keys.length - 1];
  // prevent infinite recursive calls
  if (items.length === 0) {
    throw new Error('items must not be empty');
  }

  // create temporary array from nested items or push data to result array if item contains wanted key
  const tmpArray = items.map((item: any) => {
    if (item.hasOwnProperty(lastKey) && item[lastKey] !== undefined) {
      return result.push(item[lastKey]);
    } else if (Array.isArray(item)) {
      return item.map((object) => result.push(object[keys[index]]));
    } else {
      return item[keys[index]];
    }
  });

  // check is result array empty if not contains data relay temporary data to mapInsightsDataToCompareMatrix or return result array
  // first case is for departments data
  // default case if for labels and values

  if (isArrayEmpty(result)) {
    switch (type) {
      case 'group':
        return mapDataToCompareMatrix(tmpArray[0], keys, type, index + 1);
      default:
        return mapDataToCompareMatrix(tmpArray, keys, type, index + 1);
    }
  } else {
    return result;
  }
};
