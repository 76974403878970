import { WordCloudApi } from 'api/WordCloudApi';
import { WordCloud } from 'model/WordCloud';

export class WordCloudMapper {
  deserialize(wordCloud: WordCloudApi.Entry): WordCloud {
    return Object.keys(wordCloud).reduce<WordCloud>(
      (reduced: any, key) => [...reduced, { name: key, value: wordCloud[key] }],
      []
    );
  }
}
