import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';
import { selectIsReportView } from 'util/selector/reportSelector';

import { EngagementDriverByCategoryCombine } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/EngagementDriverByCategoryCombine';
import { CategoryHeader } from 'view/EngagementDriverByCategoryPage/CategoryHeader/CategoryHeader';
import {
  selectEngagementDriversByCategoryCombine,
  selectEngagementDriversByCategoryCompare,
  selectEngagementDriversByCategoryCompareGroups,
  selectEngagementDriversByCategoryCompareGroupsLength,
  selectEngagementDriversByCategoryCompareQuestions,
  selectEngagementDriversCategoryName,
  selectEngagementDriversCombineResource,
  selectEngagementDriversCompareResource,
  selectTrendLabel,
} from 'reduxStore/engagementDrivers/selectors';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { PageContentWrapper } from 'shared/styles/layout';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import {
  selectIsOverallProjectKind,
  selectIsPulseOrTemplateProjectKind,
} from 'reduxStore/project/selectors';
import { EngagementDriver } from 'model/ReportEngagamentDriver';
import { useFetchOverall } from 'shared/hooks/useFetchOverall';
import { useIsCompareModeLock } from 'shared/hooks/useIsCompareModeLock';
import {
  selectOverallSurveyCombineResource,
  selectOverallSurveyCompareResource,
} from 'reduxStore/overallSurvey/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { useFetchDemography } from 'shared/hooks/useFetchDemography';
import { useFetchDepartments } from 'shared/hooks/useFetchDepartments';
import { useFetchEngagementScore } from 'shared/hooks/useFetchEngagementScore';
import { useRedirectToSummary } from 'view/EngagementDriverByCategoryPage/hooks/useRedirectToSummary';

import { EngagementDriverByCategoryCompare } from './EngagementDriverByCategoryCompare/EngagementDriverByCategoryCompare';

export const EngagementDriverByCategoryPage: React.FC = () => {
  const engagementDriverByCategoryCombine = useSelector(selectEngagementDriversByCategoryCombine);
  const engagementDriverByCategoryCompare = useSelector(selectEngagementDriversByCategoryCompare);
  const isCompareMode = useSelector(selectIsCompareMode);

  const isPulseTemplateOrProject = useSelector(selectIsPulseOrTemplateProjectKind);
  const isAdmin = useSelector(selectIsAdmin);
  const categoryName = useSelector(selectEngagementDriversCategoryName);
  const groups = useSelector(selectEngagementDriversByCategoryCompareGroups);
  const questions = useSelector(selectEngagementDriversByCategoryCompareQuestions);
  const groupsLength = useSelector(selectEngagementDriversByCategoryCompareGroupsLength);
  const trendLabel = EngagementDriver.getTrendLabel(
    isPulseTemplateOrProject,
    useSelector(selectTrendLabel)
  );
  const isReportView = useSelector(selectIsReportView);
  const overallSurveyCombineResource = useSelector(selectOverallSurveyCombineResource);
  const overallSurveyCompareResource = useSelector(selectOverallSurveyCompareResource);
  const engagementDriversCombineResource = useSelector(selectEngagementDriversCombineResource);
  const engagementDriversCompareResource = useSelector(selectEngagementDriversCompareResource);

  const engagementDriversResource = isCompareMode
    ? engagementDriversCompareResource
    : engagementDriversCombineResource;

  const overallSurveyResource = isCompareMode
    ? overallSurveyCompareResource
    : overallSurveyCombineResource;

  const isOverallProjectKind = useSelector(selectIsOverallProjectKind);
  const resource = isOverallProjectKind ? overallSurveyResource : engagementDriversResource;

  useIsCompareModeLock();
  useFetchEngagementScore();
  useFetchOverall({ enableDebounce: true });
  useFetchDemography();
  useFetchDepartments();
  useRedirectToSummary();

  return (
    <ResourceLoader
      resource={resource}
      rejected={(error) => (
        <>
          <CategoryHeader categoryName={categoryName ? categoryName : ''} isDownloadDisabled />
          <PageContentWrapper>
            <ErrorHandler error={error} />
          </PageContentWrapper>
        </>
      )}
    >
      <>
        {!isCompareMode ? (
          engagementDriverByCategoryCombine ? (
            <EngagementDriverByCategoryCombine
              categoryTitle={engagementDriverByCategoryCombine.category!.name}
              categoryId={engagementDriverByCategoryCombine.category!.id}
              score={engagementDriverByCategoryCombine.score}
              categoryData={engagementDriverByCategoryCombine.details}
              trends={engagementDriverByCategoryCombine.trends}
              benchmark={engagementDriverByCategoryCombine.benchmark}
              isAdmin={isAdmin}
              trendLabel={trendLabel}
              isReportView={isReportView}
            />
          ) : null
        ) : engagementDriverByCategoryCompare ? (
          <EngagementDriverByCategoryCompare
            engagementDriverByCategory={engagementDriverByCategoryCompare}
            groups={groups}
            groupsLength={groupsLength}
            questions={questions}
          />
        ) : null}
      </>
    </ResourceLoader>
  );
};
