import { Category } from 'model/Category';
import { OpinionQuestionApi } from 'api/OpinionQuestionApi';

export class CategoryMapper {
  deserialize(entry: OpinionQuestionApi.CategoryEntry): Category {
    return {
      id: entry.id,
      connectedId: entry.connected_id,
      name: entry.name,
    };
  }
}
