import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { OpinionQuestion } from 'model/OpinionQuestion';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'engagementDrivers';

export namespace EngagementDriversState {
  export type Domain = {
    categoryName?: string;
    combine: {
      list: Resource<OpinionQuestion.Combine[]>;
      selected: OpinionQuestion.Combine | null;
    };
    compare: {
      list: Resource<OpinionQuestion.Compare[]>;
      selected: OpinionQuestionDetails.Compare | null;
    };
  };

  export const INITIAL_DOMAIN_COMBINE = {
    list: Resource.createPendingResource([]),
    selected: null,
  };

  export const INITIAL_DOMAIN_COMPARE = {
    list: Resource.createPendingResource([]),
    selected: null,
  };

  export const INITIAL_DOMAIN: EngagementDriversState.Domain = {
    combine: INITIAL_DOMAIN_COMBINE,
    compare: INITIAL_DOMAIN_COMPARE,
  };
}
