import React from 'react';
import { useSelector } from 'react-redux';

import { CasReportCombine } from 'view/ReportView/FeedbackReport/Cas/CasReportCombine';
import { CasReportCompare } from 'view/ReportView/FeedbackReport/Cas/CasReportCompare';
import {
  selectSimpleProjectCombineResource,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';

export type CasReportProps = {
  isCompareMode: boolean;
};

export const CasReport: React.FC<CasReportProps> = ({ isCompareMode }) => {
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);

  const simpleProjectResource = isCompareMode
    ? simpleProjectCompareResource
    : simpleProjectCombineResource;

  return (
    <ResourceLoader resource={simpleProjectResource}>
      {isCompareMode ? <CasReportCompare /> : <CasReportCombine />}
    </ResourceLoader>
  );
};
