import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { InsightsHeader } from 'view/InsightsPage/InsightsHeader/InsightsHeader';
import { PageContentWrapper } from 'shared/styles/layout';
import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { useInsightsPageEffects } from 'view/InsightsPage/hooks/useInsightsPageEffects';

import { InsightsCombinedPage } from './combined/InsightsCombinedPage';
import { InsightsComparedPage } from './compared/InsightsComparedPage';
import { InsightsLifecycleCombinedPage } from './combined/InsightsLifecycleCombinedPage';
import { InsightsLifecycleComparedPage } from './compared/InsightsLifecycleComparedPage';

export const InsightsPage: React.FC = () => {
  const isCompareMode = useSelector(selectIsCompareMode);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);

  useInsightsPageEffects();

  return (
    <>
      <InsightsHeader title="Insights" />
      <PageContentWrapper>
        {!isLifecycleProjectKind && !isCompareMode && <InsightsCombinedPage />}
        {!isLifecycleProjectKind && isCompareMode && <InsightsComparedPage />}
        {isLifecycleProjectKind && !isCompareMode && <InsightsLifecycleCombinedPage />}
        {isLifecycleProjectKind && isCompareMode && <InsightsLifecycleComparedPage />}
      </PageContentWrapper>
    </>
  );
};
