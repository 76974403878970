import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsReportView } from 'util/selector/reportSelector';

import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import {
  selectHasSimpleProjectCompareQuestions,
  selectSimpleProjectCompareEmployeesNumber,
  selectSimpleProjectCompareGroups,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import { OnDemandTableCompare } from 'view/OnDemandPage/Compare/OnDemandTableCompare';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';

type LifecycleComparePagePureProps = {
  hasQuestions: boolean;
  groups: string[];
  isReportView: boolean;
  minResponses: number;
  responsesNumber: number;
  simpleProjectCompareResource: Resource<any>;
};

const LifecycleComparePagePure: React.FC<LifecycleComparePagePureProps> = ({
  groups,
  hasQuestions,
  isReportView,
  minResponses,
  responsesNumber,
  simpleProjectCompareResource,
}) => (
  <ResourceLoader resource={simpleProjectCompareResource}>
    <>
      <SummaryCompareWidgets />
      {hasQuestions && (
        <OnDemandTableCompare
          title={`Based on feedback from ${responsesNumber} employees`}
          titleDataTestId="questionContentLabel"
          groups={groups}
          minResponses={minResponses}
          isReportView={isReportView}
        />
      )}
    </>
  </ResourceLoader>
);

export const LifecycleComparePage: React.FC = () => {
  const hasQuestions = useSelector(selectHasSimpleProjectCompareQuestions);
  const groups = useSelector(selectSimpleProjectCompareGroups);
  const isReportView = useSelector(selectIsReportView);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const responsesNumber = useSelector(selectSimpleProjectCompareEmployeesNumber);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);

  return (
    <LifecycleComparePagePure
      hasQuestions={hasQuestions}
      groups={groups}
      isReportView={isReportView}
      minResponses={minResponses}
      responsesNumber={responsesNumber}
      simpleProjectCompareResource={simpleProjectCompareResource}
    />
  );
};
