import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import { fetchProjectTopics } from 'reduxStore/topics/asyncActions';

import { STORE_NAME, TopicsState } from './initialState';

export const topicsSlice = createSlice({
  name: STORE_NAME,
  initialState: TopicsState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    addAsyncCases<TopicsState.Domain, TopicsState.Data>(
      builder,
      fetchProjectTopics,
      (state, action) => {
        const { data, loadingState, error } = action.payload;
        state.data = data;
        state.loadingState = loadingState;
        state.error = error;
      }
    );
  },
});
