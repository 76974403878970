import { CompareGroup } from 'model/CompareGroup';

export class CompareGroupMapper {
  deserialize(
    entry: any,
    groups?: { [key: number]: string },
    protections?: { [key: number]: boolean }
  ): CompareGroup {
    return {
      group: entry.group ? entry.group : groups && groups[entry.id],
      respondentProtection: entry.respondentProtection,
      percentage: entry.percentage,
      score: entry.score,
      count: entry.count,
      respondentProtectionType:
        groups && protections ? protections[entry.id] : entry.respondentProtection,
      confidentialityProtection: entry.confidentialityProtection
        ? entry.confidentialityProtection
        : false,
    };
  }
}
