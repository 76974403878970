import { ImpactOnEngagement } from 'model/ImpactOnEngagement';
import { QuestionDataElementType } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';

export enum ColumnName {
  Benchmark = 'Benchmark',
  Category = 'Category',
  Trends = 'Trends',
  Question = 'Question',
  Impact = 'ImpactOnEngagement',
  Score = 'Score',
}

export enum OrderBy {
  Ascending = 'asc',
  Descending = 'desc',
}

export const AscendingImpactOnEngagement = {
  [ImpactOnEngagement.Low]: 3,
  [ImpactOnEngagement.Medium]: 2,
  [ImpactOnEngagement.High]: 1,
  [ImpactOnEngagement.VeryHigh]: 0,
};

const getTrendDiff = (data: QuestionDataElementType, label?: string): number => {
  return label
    ? data.trends?.find((trend) => trend.label === label)?.diff || 0
    : (data.trends || [{ diff: null }])[0].diff || 0;
};

export const Comparator = {
  [ColumnName.Category]: {
    [OrderBy.Ascending]: (a: QuestionDataElementType, b: QuestionDataElementType): number =>
      (a.categoryTitle || '').localeCompare(b.categoryTitle || ''),
    [OrderBy.Descending]: (a: QuestionDataElementType, b: QuestionDataElementType): number =>
      (a.categoryTitle || '').localeCompare(b.categoryTitle || '') * -1,
  },
  [ColumnName.Question]: {
    [OrderBy.Ascending]: (a: QuestionDataElementType, b: QuestionDataElementType): number =>
      a.question.content.localeCompare(b.question.content),
    [OrderBy.Descending]: (a: QuestionDataElementType, b: QuestionDataElementType): number =>
      a.question.content.localeCompare(b.question.content) * -1,
  },
  [ColumnName.Impact]: {
    [OrderBy.Ascending]: (a: QuestionDataElementType, b: QuestionDataElementType): number => {
      const impactA = a.impactOnEngagement;
      const impactB = b.impactOnEngagement;

      if (typeof impactA !== 'string' || typeof impactB !== 'string') {
        return -1;
      }

      if (AscendingImpactOnEngagement[impactA] > AscendingImpactOnEngagement[impactB]) {
        return 1;
      } else if (AscendingImpactOnEngagement[impactA] < AscendingImpactOnEngagement[impactB]) {
        return -1;
      } else {
        return 0;
      }
    },
    [OrderBy.Descending]: (a: QuestionDataElementType, b: QuestionDataElementType): number => {
      const impactA = a.impactOnEngagement;
      const impactB = b.impactOnEngagement;
      if (typeof impactA !== 'string' || typeof impactB !== 'string') {
        return -1;
      }

      if (AscendingImpactOnEngagement[impactA] < AscendingImpactOnEngagement[impactB]) {
        return 1;
      } else if (AscendingImpactOnEngagement[impactA] > AscendingImpactOnEngagement[impactB]) {
        return -1;
      } else {
        return 0;
      }
    },
  },
  [ColumnName.Score]: {
    [OrderBy.Ascending]: (a: QuestionDataElementType, b: QuestionDataElementType) => {
      if (a.score > b.score) {
        return 1;
      } else if (b.score > a.score) {
        return -1;
      } else {
        return 0;
      }
    },
    [OrderBy.Descending]: (a: QuestionDataElementType, b: QuestionDataElementType) => {
      if (a.score > b.score) {
        return -1;
      } else if (b.score > a.score) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  [ColumnName.Trends]: {
    [OrderBy.Ascending]: (label?: string) => (
      a: QuestionDataElementType,
      b: QuestionDataElementType
    ) => {
      const trendA = getTrendDiff(a, label);
      const trendB = getTrendDiff(b, label);
      if (trendA > trendB) {
        return 1;
      } else if (trendB > trendA) {
        return -1;
      } else {
        return 0;
      }
    },
    [OrderBy.Descending]: (label?: string) => (
      a: QuestionDataElementType,
      b: QuestionDataElementType
    ) => {
      const trendA = getTrendDiff(a, label);
      const trendB = getTrendDiff(b, label);
      if (trendA > trendB) {
        return -1;
      } else if (trendB > trendA) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  [ColumnName.Benchmark]: {
    [OrderBy.Ascending]: (a: QuestionDataElementType, b: QuestionDataElementType) => {
      const benchmarkA = a.benchmark?.value || 0;
      const benchmarkB = b.benchmark?.value || 0;
      if (benchmarkA > benchmarkB) {
        return 1;
      } else if (benchmarkB > benchmarkA) {
        return -1;
      } else {
        return 0;
      }
    },
    [OrderBy.Descending]: (a: QuestionDataElementType, b: QuestionDataElementType) => {
      const benchmarkA = a.benchmark?.value || 0;
      const benchmarkB = b.benchmark?.value || 0;
      if (benchmarkA > benchmarkB) {
        return -1;
      } else if (benchmarkB > benchmarkA) {
        return 1;
      } else {
        return 0;
      }
    },
  },
};

export const getNextOrderBy = ({
  currentSortBy,
  column,
}: {
  currentSortBy: SortBy;
  column: ColumnName | string;
}): OrderBy => {
  // first click on a column
  if (column !== currentSortBy.column) {
    return OrderBy.Ascending;
  } else {
    // following clicks on a column
    return currentSortBy.order === OrderBy.Ascending ? OrderBy.Descending : OrderBy.Ascending;
  }
};

export type SortBy = {
  column?: ColumnName | string;
  order?: OrderBy;
};
