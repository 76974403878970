import { Link } from 'react-router-dom';

import { styled } from '@mui/material';
import { BLACK, WHITE } from 'app/theme/colors';
import { BASE_PADDING } from 'app/theme/constants';

export const SelectProjectPageWrapper = styled('div')({
  padding: '0 32px',
});

export const PaginationWrapper = styled('div')({
  marginTop: 20,
  marginBottom: 80,
});

export const ProjectList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  background: WHITE,
  padding: '16px',
});

export const ProjectWrapper = styled('span')({
  margin: '10px 0',
});

export const ProjectListItem = styled<any>(Link)({
  fontSize: 20,
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
});

export const ProjectName = styled('span')({
  color: BLACK,
});

export const ProjectEndDate = styled('span')({
  fontWeight: 100,
});

export const InfoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'calc(100vh - 217px)',
  marginTop: 'auto',
  padding: 0,
});

export const SelectProjectTooltipWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: WHITE,
});

export const NewExplanationWrapper = styled('div')({
  display: 'flex',
  padding: `${BASE_PADDING}px`,
  maxWidth: '750px',
  alignItems: 'center',
});

export const NewExplanationText = styled('p')({
  paddingLeft: `${BASE_PADDING}px`,
});
