import { AxiosInstance } from 'axios';
import { SummaryApi } from 'api/SummaryApi';
import { OpinionQuestionApi } from 'api/OpinionQuestionApi';
import { CompareGroupApi } from 'api/CompareGroupApi';
import { EngagementScoreSurvey } from 'model/EngagementScoreSurvey';
import { BenchmarkApi } from 'api/BenchmarkApi';
import { Summary } from 'model/Summary';

import { EngagementScoreMapper } from '../mapper/EngagementScoreMapper';
import { SummaryMapper } from '../mapper/SummaryMapper';

export namespace ProjectScoreApi {
  export type EntryCombine = {
    byCategory: OpinionQuestionApi.CombineEntry[];
    showImpactOnEngagement: boolean;
  };

  export type EngagementCombine = ProjectScoreApi.EntryCombine & {
    summary: SummaryApi.EntryCombine;
    chartLabel?: string;
    aggregatedBenchmark?: BenchmarkApi.Entry;
  };

  export type PulseSummaryEntry = {
    byProject: SummaryApi.ProjectSummary[];
    aggregatedBenchmark: BenchmarkApi.Entry;
  };

  export type EntryCompare = {
    summary: SummaryApi.EntryCompare[];
    categories: OpinionQuestionApi.CompareEntry[];
    groups: CompareGroupApi.Entry;
    combineModeLock: boolean;
    protections: { [key: number]: boolean };
  };
}

export class ProjectScoreApi {
  static isEngagementCombine = (score: unknown): score is ProjectScoreApi.EngagementCombine => {
    return !!(score as ProjectScoreApi.EngagementCombine).summary;
  };

  constructor(
    private client: AxiosInstance,
    private engagementScoreMapper: EngagementScoreMapper,
    private summaryMapper: SummaryMapper
  ) {}

  combine(projectId: number, query: string): Promise<EngagementScoreSurvey.Combine> {
    return this.client
      .get<ProjectScoreApi.EntryCombine | ProjectScoreApi.EngagementCombine>(
        `/api/v1/project/${projectId}/report/summary/combined${query}`
      )
      .then((response) => this.engagementScoreMapper.deserializeCombine(response.data))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  compare(projectId: number, query: string): Promise<EngagementScoreSurvey.Compare> {
    return this.client
      .get(`/api/v1/project/${projectId}/report/summary/compared${query}`)
      .then((response) => this.engagementScoreMapper.deserializeCompare(response.data))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  pulsesSummary(projectId: number, query: string): Promise<Summary.Pulses> {
    return this.client
      .get<ProjectScoreApi.PulseSummaryEntry>(
        `/api/v1/project/${projectId}/report/pulse-summary${query}`
      )
      .then(({ data: { byProject, aggregatedBenchmark } }) =>
        this.summaryMapper.deserializePulseSummary(aggregatedBenchmark, byProject)
      )
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }
}
