import moment from 'moment';

export type RecommendChartDataDto = {
  date: string;
  value: number | null;
};

export type RecommendChartDataModel = {
  value: number | null;
  date: string;
  dateLabel: string;
};

export const mapRecommendChartDtoArrayToModelArray = (
  dtoArray: ReadonlyArray<RecommendChartDataDto>
): RecommendChartDataModel[] => {
  return dtoArray.map((dto, index) => {
    return {
      value: dto.value,
      date: moment(dto.date).format('MMM YY'),
      dateLabel: index === 0 ? moment(dto.date).format('MMM YY') : moment(dto.date).format('MMM'),
    };
  });
};
