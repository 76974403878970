import React from 'react';

import { SentimentScale, SentimentScaleWrapper } from 'view/TopicsPage/Sentiment/Sentiment.s';
import { Sentiment } from 'model/Sentiment';
import { Typography } from '@mui/material';

export const SentimentLegend = () => {
  return (
    <SentimentScaleWrapper>
      <Typography mb={1} variant="body2">
        Positive
      </Typography>
      <SentimentScale colors={Sentiment.getScaleColors()} />
      <Typography mt={1} variant="body2">
        Negative
      </Typography>
    </SentimentScaleWrapper>
  );
};
