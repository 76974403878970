import { ResponseRate } from 'model/ResponseRate';
import { ResponseRateApi } from 'api/ResponseRateApi';
import moment from 'moment';

export class ResponseRateMapper {
  deserialize(entry: ResponseRateApi.Entry): ResponseRate {
    return {
      invited: entry.respondentCount,
      responses: entry.submissionCount,
      score: entry.responseRate,
      date: moment(entry.date),
    };
  }
}
