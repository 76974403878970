import { createSlice } from '@reduxjs/toolkit';
import { fetchProjectResponseRate } from 'reduxStore/responseRate/asyncActions';

import { ResponseRateState, STORE_NAME } from './initialState';

export const responseRateSlice = createSlice({
  name: STORE_NAME,
  initialState: ResponseRateState.INITIAL_DOMAIN,
  reducers: {
    clearResponseRate: (state) => {
      state.data = ResponseRateState.INITIAL_DOMAIN.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectResponseRate.pending, (state, action) => {
        state.data[action.meta.arg.projectId] = { isLoading: true };
      })
      .addCase(fetchProjectResponseRate.fulfilled, (state, action) => {
        state.data[action.meta.arg.projectId] = action.payload;
      })
      .addCase(fetchProjectResponseRate.rejected, (state, action) => {
        delete state.data[action.meta.arg.projectId];
      });
  },
});

export const { clearResponseRate } = responseRateSlice.actions;
