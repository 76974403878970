import { styled } from '@mui/material';

export const LegendWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    padding: 10,
  },
});

export const LegendPointWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export type LegendPointProps = {
  color: string;
};

export const LegendPoint = styled('div')(
  {
    height: 15,
    width: 15,
  },
  (props: LegendPointProps) => ({
    backgroundColor: props.color,
  })
);

export const LegendLabel = styled('span')({
  paddingLeft: 10,
});
