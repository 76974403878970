import { useEffect } from 'react';

import { selectErrorStatusCode } from 'util/selector/axiosSelector';

import { datadogLogs } from '@datadog/browser-logs';
import { getAppConfig } from 'app/getConfig';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { AxiosError } from 'axios';
import { latestReduxActions, wrappedReduxStateSnapshot } from 'shared/constants/datadog';

export const useInitializeDataDogLogs = () => {
  const isRUMEnabled = useFeatureIsOn('datadog-logs-fe');
  const { DATADOG_REPORT_UI_CLIENT_TOKEN, NODE_ENV } = getAppConfig();

  const isAllowedToInit = isRUMEnabled && DATADOG_REPORT_UI_CLIENT_TOKEN;

  useEffect(() => {
    if (isAllowedToInit) {
      datadogLogs.init({
        clientToken: DATADOG_REPORT_UI_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'report-ui',
        env: NODE_ENV,
        sessionSampleRate: 100,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
      });
    } else {
      datadogLogs.logger.error('datadogLogs failed to init', {
        env: NODE_ENV,
        service: 'report-ui',
        DATADOG_REPORT_UI_CLIENT_TOKEN: !!DATADOG_REPORT_UI_CLIENT_TOKEN,
        isRUMEnabled,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const logInterceptedError = (error: AxiosError) => {
  const errorCode = selectErrorStatusCode(error);

  datadogLogs.logger.error(`[Axios] ${errorCode} Error Intercepted`, {
    latestReduxActions: [...latestReduxActions].reverse(),
    error,
    state: wrappedReduxStateSnapshot.state,
  });
};
