import React from 'react';

import { BarWrapper } from 'component/PercentageBar/PercentageBar.s';
import { Circle } from 'component/PercentageBar/Circle';
import { PercentageBarSize } from 'component/PercentageBar/PercentageBarSize';

export namespace PercentageBar {
  export type Props = {
    type: string;
    value: number;
    size?: PercentageBarSize;
    responses?: number;
    invited?: number;
    isReportView?: boolean;
  };
}

const PercentageBarPure: React.FC<PercentageBar.Props> = ({
  type,
  value,
  size = PercentageBarSize.DEFAULT,
  responses,
  invited,
  isReportView,
}) => {
  const strokeWidth = 14;
  const radius = (size - strokeWidth) / 2;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * value) / 100;
  const viewBox = `0 0 ${size} ${size}`;

  return (
    <BarWrapper data-testid="percentageBar" isReportView={isReportView}>
      <svg width={size} height={size} viewBox={viewBox}>
        <Circle
          width={size}
          strokeWidth={strokeWidth}
          radius={radius}
          dashArray={dashArray}
          dashOffset={dashOffset}
          type={type}
          value={value}
          responses={responses}
          invited={invited}
        />
      </svg>
    </BarWrapper>
  );
};

export const PercentageBar = PercentageBarPure;
