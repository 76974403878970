import { selectAuthApiUrl } from 'util/selector/apiSelector';

import axios, { AxiosInstance } from 'axios';
import { logInterceptedError } from 'shared/hooks/useInitializeDataDogLogs';

export const authClient: AxiosInstance = axios.create({
  baseURL: selectAuthApiUrl(),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-GB',
    Accept: 'application/json',
  },
});

authClient.interceptors.response.use(
  (response) => response,
  (error) => {
    logInterceptedError(error);
  }
);
