import {
  selectFiltersQuery,
  selectIsCompareMode,
  selectLifecycleFiltersQuery,
  selectModeType,
} from 'util/selector/querySelector';
import { selectIsReportView, selectReportProjectId } from 'util/selector/reportSelector';
import { selectHasMatch } from 'util/selector/routerSelector';

import { Draft, createAsyncThunk } from '@reduxjs/toolkit';
import { ReportRoute } from 'app/route/report';
import { projectScoreApi } from 'container/projectScoreApi';
import { EngagementScoreSurvey } from 'model/EngagementScoreSurvey';
import { selectCurrentProjectId, selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { serializeError } from 'shared/utils/redux';
import { selectDetailsByCategoryId } from 'reduxStore/engagementDrivers/selectors';
import { OpinionQuestion } from 'model/OpinionQuestion';
import { ModeType } from 'register/ModeType';
import { debounce } from 'lodash';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';

import { STORE_NAME } from './initialState';
import {
  setEngagementDriverByCategoryCombine,
  setEngagementDriverByCategoryCompare,
} from './slice';

export const setEngagementDriverByCategory = createAsyncThunk<void>(
  `${STORE_NAME}/setEngagementDriverByCategory`,
  (_, { getState, dispatch }) => {
    const isCompareMode = selectIsCompareMode(getState());

    if (isCompareMode) {
      dispatch(
        setEngagementDriverByCategoryCompare(
          (selectDetailsByCategoryId(getState()) as Draft<OpinionQuestion.Compare>) ?? null
        )
      );
    } else {
      dispatch(
        setEngagementDriverByCategoryCombine(
          (selectDetailsByCategoryId(getState()) as Draft<OpinionQuestion.Combine>) ?? null
        )
      );
    }
  },
  { serializeError }
);

export const fetchEngagementScore = createAsyncThunk<void>(
  `${STORE_NAME}/fetchEngagementScore`,
  async (_, { getState, dispatch }) => {
    const currentMode = selectModeType(getState());

    if (currentMode === ModeType.COMBINED) {
      await dispatch(fetchEngagementScoreCombine());
    } else {
      await dispatch(fetchEngagementScoreCompare());
    }

    dispatch(setEngagementDriverByCategory());
  },
  { serializeError }
);

export const fetchEngagementScoreCombine = createAsyncThunk<EngagementScoreSurvey.Combine>(
  `${STORE_NAME}/fetchEngagementScoreCombine`,
  (_, { getState }) => {
    const isReport = selectIsReportView(getState());

    const projectId = isReport
      ? selectReportProjectId(getState())
      : selectCurrentProjectId(getState());

    const isLifecycleKind = selectIsLifecycleProjectKind(getState());

    const query = isLifecycleKind
      ? selectLifecycleFiltersQuery(getState())
      : selectFiltersQuery(getState());

    return projectScoreApi.combine(projectId, query);
  },
  { serializeError }
);

export const fetchEngagementScoreCompare = createAsyncThunk<EngagementScoreSurvey.Compare>(
  `${STORE_NAME}/fetchEngagementScoreCompare`,
  (_, { getState }) => {
    const isReport = selectHasMatch(ReportRoute.REPORT_PATTERN)(getState());
    const projectId = isReport
      ? selectReportProjectId(getState())
      : selectCurrentProjectId(getState());

    const query = selectFiltersQuery(getState());

    return projectScoreApi.compare(projectId, query);
  },
  { serializeError }
);

const fetchEngagementScoreDebouncedThunk = debounce(
  fetchEngagementScore(),
  FILTERS_CHANGED_DEBOUNCE_TIMEOUT
);

export const fetchEngagementScoreDebounced = createAsyncThunk(
  `${STORE_NAME}/fetchEngagementScoreDebounced`,
  (_, { dispatch }) => {
    dispatch(fetchEngagementScoreDebouncedThunk);
  },
  { serializeError }
);
