import { AxiosInstance } from 'axios';
import { Insight } from 'model/Insight';
import { QuestionApi } from 'api/QuestionApi';
import {
  InsightsComparedDto,
  InsightsComparedModel,
  mapInsightsComparedDtoToModel,
} from 'api/dto/new/InsightsCompared.dto';
import {
  InsightsLifecycleComparedDto,
  InsightsLifecycleComparedModel,
  mapInsightsLifecycleComparedDtoToModel,
} from 'api/dto/new/InsightsLifecycleCompared.dto';

import { InsightMapper } from '../mapper/InsightMapper';

export namespace InsightApi {
  export type EntryCombineQuestion = {
    answers: AnswerCombine[];
    question: QuestionApi.Entry;
    respondentProtection?: boolean;
  };
  export type EntryCombine = {
    numberOfEmployees?: number;
    questions: EntryCombineQuestion[];
  };
  export type AnswerCombine = {
    option: Option;
    count: number;
    score: number;
    trendPlotData?: {
      date: string;
      value: number | null;
    }[];
  };
  export type Option = {
    id: number;
    date: string;
    name: string;
  };
}

export class InsightApi {
  constructor(private client: AxiosInstance, private insightMapper: InsightMapper) {}

  combine(projectId: number, query: string): Promise<Insight.Combine> {
    return this.client
      .get<InsightApi.EntryCombine>(`/api/v1/project/${projectId}/report/insights/combined${query}`)
      .then((response) => this.insightMapper.deserializeCombine(response.data))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  insightsCompared(projectId: number, query: string): Promise<InsightsComparedModel> {
    return this.client
      .get<InsightsComparedDto>(`/api/survey/${projectId}/report/insights-compared${query}`)
      .then((response) => response.data)
      .then(mapInsightsComparedDtoToModel)
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  insightsLifecycleCompared(
    projectId: number,
    query: string
  ): Promise<InsightsLifecycleComparedModel> {
    return this.client
      .get<InsightsLifecycleComparedDto>(
        `/api/survey/${projectId}/report/insights-lifecycle-compared${query}`
      )
      .then((response) => response.data)
      .then(mapInsightsLifecycleComparedDtoToModel)
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }
}
