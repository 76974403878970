export namespace AppRoute {
  export const HOME = '/' as const;
  export const PROJECT_PATTERN = '/:id' as const;
  export const NOT_FOUND_PROJECT = `${PROJECT_PATTERN}/not-found-project` as const;
  export const INSIGHTS = `${PROJECT_PATTERN}/insights` as const;
  export const COMMENTS = `${PROJECT_PATTERN}/comments` as const;
  export const INDIVIDUAL_FEEDBACK = `${PROJECT_PATTERN}/individual-feedback` as const;
  export const TOPICS = `${PROJECT_PATTERN}/topics` as const;
  export const HOME_PATTERN = `${PROJECT_PATTERN}/home` as const;

  export namespace Home {
    export const SUMMARY = `${HOME_PATTERN}/summary` as const;
    export const REPORTS = `${HOME_PATTERN}/reports` as const;

    export namespace Summary {
      export const REPORT = `${Home.SUMMARY}/:categoryId` as const;
    }
  }
}
