import { AppState } from 'reduxStore/appStore';
import { RespondentState, STORE_NAME } from 'reduxStore/respondent/initialState';
import { createSelector } from 'reselect';

export const selectRespondentDomain = (state: AppState): RespondentState.Domain =>
  state[STORE_NAME];

export const selectRespondentResource = createSelector(
  selectRespondentDomain,
  (domain) => domain.resource
);

export const selectRespondentPagination = createSelector(
  selectRespondentDomain,
  (domain) => domain.resource.data?.pagination
);

export const selectRespondentFilters = createSelector(
  selectRespondentDomain,
  (domain) => domain.filters
);

export const selectRespondentIndividualFeedback = createSelector(
  selectRespondentDomain,
  (domain) => domain.individualFeedback
);

export const selectRespondentsTablePage = createSelector(
  selectRespondentDomain,
  (domain) => domain.respondentsTablePage
);
