import { styled } from '@mui/material';
import { DARK_GREY, IRON, LIGHT_GREY, WHITE } from 'app/theme/colors';
import checkmark from 'app/asset/checkmark.png';
import checkmarkDark from 'app/asset/checkmarkDark.png';

export const CheckboxWrapper = styled('label')({
  marginLeft: 10,
});

export type CheckboxInputProps = {
  variant: 'LIGHT' | 'DARK';
};

export const CheckboxInput = styled('input')(
  {
    display: 'none',
    '&:hover + span': {
      cursor: 'pointer',
    },
    '&:disabled + span': {
      '&:hover': {
        cursor: 'not-allowed',
      },
      backgroundColor: DARK_GREY,
    },
  },
  (props: CheckboxInputProps) => ({
    '& + span': {
      display: 'flex',
      width: 18,
      height: 18,
      backgroundColor: props.variant === 'LIGHT' ? WHITE : LIGHT_GREY,
      alignItems: 'center',
      color: WHITE,
      margin: 3,
      border: props.variant === 'LIGHT' ? `2px solid ${IRON}` : `2px solid ${LIGHT_GREY}`,
      borderRadius: 4,
      boxSizing: 'border-box',
      transition: 'all 150ms',
    },
    '&:checked + span': {
      '&::before': {
        content: "'icon'",
        background: `${props.variant === 'LIGHT' ? WHITE : LIGHT_GREY} url(${
          props.variant === 'LIGHT' ? checkmarkDark : checkmark
        }) no-repeat center center`,
        backgroundSize: '12px',
        width: '14px',
        height: '12px',
        fontFamily: 'Material Icons',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '12px',
      },
    },
  })
);
