import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchState, STORE_NAME } from './initialState';

export const searchSlice = createSlice({
  name: STORE_NAME,
  initialState: SearchState.INITIAL_DOMAIN,
  reducers: {
    search: (state, action: PayloadAction<string>) => {
      state.filtersSearchText = action.payload;
    },
    searchReport: (state, action: PayloadAction<string>) => {
      state.reportSearchText = action.payload;
    },
  },
});

export const { search, searchReport } = searchSlice.actions;
