import React from 'react';

import { TEXT_COLOR } from 'app/theme/colors';
import { Text } from 'component/Text/Text';
import { PercentageBarType } from 'model/PercentageBarType';
import { fontFamily, fontSize } from 'app/theme/typography';

export namespace Values {
  export type Props = {
    value: number;
    responses?: number;
    invited?: number;
    type: string;
  };
}

export const Values: React.FC<Values.Props> = (props) => (
  <Text
    x="50%"
    y="50%"
    dy={props.type === PercentageBarType.ResponseRate ? '4%' : '7%'}
    textAnchor="middle"
    fill={TEXT_COLOR}
  >
    <tspan
      x="50%"
      fontSize={fontSize.mdPlus.h1}
      fontWeight={800}
      fontFamily={fontFamily}
      data-testid="percentageBarValue"
    >
      {props.value}
      {props.type === PercentageBarType.ResponseRate && '%'}
    </tspan>
    {props.type === PercentageBarType.ResponseRate && (
      <tspan
        x="50%"
        dy="1.8em"
        fontFamily={fontFamily}
        fontSize={fontSize.mdPlus.body2}
        fontWeight={400}
        data-testid="percentageBarResponseAndInvitedRatio"
      >
        {props.responses} / {props.invited}
      </tspan>
    )}
  </Text>
);
