import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { PageHeader } from 'component/PageHeader/PageHeader';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import { OnDemandCombinePage } from 'view/OnDemandPage/OnDemandCombinePage';
import { OnDemandComparePage } from 'view/OnDemandPage/OnDemandComparePage';
import { fetchSimpleProject } from 'reduxStore/simpleProject/asyncActions';
import { useAppParams } from 'shared/hooks/useAppParams';
import { useAppDispatch } from 'reduxStore/appStore';
import { PageContentWrapper } from 'shared/styles/layout';
import {
  selectSimpleProjectCombineResource,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { fetchLastPulseId } from 'reduxStore/project/asyncActions';

export const OnDemandPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const isCompareMode = useSelector(selectIsCompareMode);
  const { projectId } = useAppParams();

  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);

  const simpleProjectResource = isCompareMode
    ? simpleProjectCompareResource
    : simpleProjectCombineResource;

  useEffect(() => {
    dispatch(fetchSimpleProject());
    dispatch(fetchLastPulseId());
  }, [projectId, dispatch]);

  return (
    <ResourceLoader
      resource={simpleProjectResource}
      rejected={(error) => (
        <>
          <PageHeader isDownloadDisabled />
          <PageContentWrapper>
            <ErrorHandler error={error} />
          </PageContentWrapper>
        </>
      )}
    >
      <PageHeader />
      <PageContentWrapper>
        {isCompareMode ? <OnDemandComparePage /> : <OnDemandCombinePage />}
      </PageContentWrapper>
    </ResourceLoader>
  );
};
