import React from 'react';

import { forScoreCases } from 'util/forScoreCases';
import { isString } from 'util/isString';

import { StatisticNumber } from 'component/Statistics/StatisticNumber';

import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowWrapper,
  InfoWrapper,
  NullValue,
  StatsTitle,
} from './Statistics.s';

export namespace Statistics {
  export type Props = {
    score: number | string | null;
    position?: 'vertical' | 'horizontal';
    arrowSize?: number;
    fontSize?: number;
    arrowBottomMargin?: number;
    title?: React.ReactNode;
    isAdmin?: boolean;
    isLarge?: boolean;
    isReportView?: boolean;
  };
  export type ScoreProps = {
    score: number | string | null;
    fontSize: number;
    position?: 'vertical' | 'horizontal';
    arrowSize?: number;
    arrowBottomMargin: number;
    isAdmin?: boolean;
    isLarge?: boolean;
    isReportView?: boolean;
  };
}

const isLong = (title: Statistics.Props['title']) => {
  if (title && typeof title === 'string') {
    return title.length > 15;
  }

  return false;
};

const StatisticsPure: React.FC<Statistics.Props> = ({
  title,
  score,
  fontSize = 16,
  position = 'horizontal',
  arrowSize = 18,
  arrowBottomMargin = 0,
  isAdmin,
  isLarge,
  isReportView,
}) => (
  <div>
    {title && <StatsTitle long={isLong(title)}>{title}</StatsTitle>}
    <StatisticsScore
      score={score}
      fontSize={fontSize}
      position={position}
      arrowSize={arrowSize}
      arrowBottomMargin={arrowBottomMargin}
      isAdmin={isAdmin}
      isLarge={isLarge}
      isReportView={isReportView}
    />
  </div>
);

const StatisticsScore: React.FC<Statistics.ScoreProps> = (props) => (
  <InfoWrapper
    position={props.position!}
    isReportView={props.isReportView}
    data-testid="statistics"
  >
    {props.score === null ? (
      <NullValue isLarge={props.isLarge} data-testid="emptyStatisticScoreLabel">
        -
      </NullValue>
    ) : (
      <>
        <ArrowWrapper marginBottom={props.arrowBottomMargin}>
          {!isString(props.score)
            ? forScoreCases(Number(props.score), {
                negative: (
                  <ArrowDownIcon sx={{ fontSize: props.arrowSize }} data-testid="arrowUp" />
                ),
                neutral: (
                  <ArrowRightIcon sx={{ fontSize: props.arrowSize }} data-testid="arrowRight" />
                ),
                positive: (
                  <ArrowUpIcon sx={{ fontSize: props.arrowSize }} data-testid="arrowDown" />
                ),
              })
            : null}
        </ArrowWrapper>
        {!isString(props.score) ? (
          <StatisticNumber score={Number(props.score)} fontSize={props.fontSize} />
        ) : (
          ' - '
        )}
      </>
    )}
  </InfoWrapper>
);

export const Statistics = StatisticsPure;
