import { Question } from 'model/Question';
import { InsightAnswer } from 'model/InsightAnswer';

export namespace Insight {
  export type CombineData = {
    question: Question;
    answers: ReadonlyArray<CombineAnswer>;
    respondentProtection: boolean;
  };
  export type Combine = Readonly<{
    data: ReadonlyArray<CombineData>;
    numberOfEmployees?: number;
  }>;
  export type CombineAnswer = {
    count: number;
    score: number;
    option: InsightAnswer.Option;
    trendPlotData?: (number | null)[];
  };
}

export const plotDataToTableData = (value: number | null, index: number) => ({
  name: index.toString(),
  value,
});
