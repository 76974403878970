import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { selectFiltersQuery, selectIsCompareModeLockFactory } from 'util/selector/querySelector';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useAppDispatch } from 'reduxStore/appStore';
import { setMode } from 'reduxStore/filtering/asyncActions';
import { ModeType } from 'register/ModeType';
import debounce from 'lodash/debounce';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import { selectPreferredMode } from 'reduxStore/visualSettings/selectors';
import { setPreferredMode } from 'reduxStore/visualSettings/slice';

export const useIsCompareModeLock = () => {
  const isFeatureEnabled = useFeatureIsOn('cross-filter-heatmaps');
  const dispatch = useAppDispatch();
  const isCompareModeLock = useSelector(selectIsCompareModeLockFactory(isFeatureEnabled));
  const preferredMode = useSelector(selectPreferredMode);
  const query = useSelector(selectFiltersQuery);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDispatch = useCallback(
    debounce((modeToSet: ModeType) => {
      dispatch(setMode(modeToSet));
    }, FILTERS_CHANGED_DEBOUNCE_TIMEOUT),
    []
  );

  let modeToSet = ModeType.COMBINED;

  if (isCompareModeLock && preferredMode !== null) {
    dispatch(setPreferredMode(null));
  } else {
    if (preferredMode === null) {
      modeToSet = isCompareModeLock ? ModeType.COMBINED : ModeType.COMPARED;
    } else {
      modeToSet = preferredMode;
    }
  }

  useEffect(() => {
    debouncedDispatch(modeToSet);
  }, [modeToSet, query, debouncedDispatch]);
};
