import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'reduxStore/appStore';
import { downloadMonthlyBreakdownReport } from 'reduxStore/report/asyncActions';
import { DARK_ORANGE } from 'app/theme/colors';
import { LoadingIcon } from 'component/PageHeader/PageHeader.s';
import { MonthlyBreakdownReportType } from 'model/MonthlyBreakdownReport';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import { getReportActionNameByReportFormat } from 'reduxStore/report/getReportActionName';

import { WBOutlinedButton } from './DownloadMonthlyBreakdownButton.s';

export type DownloadMonthlyBreakdownButtonProps = {
  monthlyBreakdownReport: MonthlyBreakdownReportType;
};

export const DownloadMonthlyBreakdownButton: React.FC<DownloadMonthlyBreakdownButtonProps> = ({
  monthlyBreakdownReport,
}) => {
  const dispatch = useAppDispatch();

  const isDownloadPending: boolean = useSelector(
    selectIsPendingReport(getReportActionNameByReportFormat(downloadMonthlyBreakdownReport))
  );

  const handleClick = () => {
    if (!isDownloadPending) {
      dispatch(downloadMonthlyBreakdownReport({ monthlyBreakdownReport }));
    }
  };

  return (
    <div>
      <WBOutlinedButton
        onClick={handleClick}
        disabled={isDownloadPending}
        data-testid="monthlyBreakdownButton"
        sx={{
          position: 'relative',
          paddingLeft: isDownloadPending ? '48px' : '32px',
          paddingRight: isDownloadPending ? '16px' : '32px',
        }}
      >
        {isDownloadPending && (
          <LoadingIcon
            sx={{ position: 'absolute', left: '16px' }}
            type="spin"
            color={DARK_ORANGE}
            height={20}
            width={20}
          />
        )}
        Download Monthly Breakdown
      </WBOutlinedButton>
    </div>
  );
};
