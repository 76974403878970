import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFeatureValue } from '@growthbook/growthbook-react';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';

export type LocalizeConfig = {
  [projectId: string]: {
    bundleKey?: string;
    defaultLanguage?: string;
  };
};

type InitialisationConfig = {
  key: string;
  retranslateOnNewPhrases: boolean;
  autoApprove: boolean;
  localizeImages: boolean;
};

export type Localize = {
  getLanguage(): string;
  hideWidget(): void;
  initialize(config: InitialisationConfig): void;
  setLanguage(lang: string): void;
};

export type OnInitializeParams = {
  defaultLanguage: string | undefined;
  localize: Localize;
};

export type OnInitialize = (params: OnInitializeParams) => void;

export const useLocalize = (onInitialize: OnInitialize) => {
  const config: LocalizeConfig = useFeatureValue('localize-config', {});
  const projectId = useSelector(selectCurrentProjectId);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const localize = window.Localize;
    const shouldInitialize =
      !isInitialized && config && Object.keys(config).includes(projectId.toString());

    if (shouldInitialize && localize) {
      const { defaultLanguage, bundleKey } = config[projectId];

      if (typeof bundleKey === 'undefined') {
        console.error('Please, provide a bundle key to use Localize.js');
      } else {
        localize.initialize({
          key: bundleKey,
          retranslateOnNewPhrases: true,
          autoApprove: true,
          localizeImages: true,
        });

        onInitialize({
          defaultLanguage,
          localize,
        });
      }
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, isInitialized, projectId]);
};

export const getSelectedLanguage = () => window.Localize?.getLanguage() || 'en';
