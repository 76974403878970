import { SxProps, styled } from '@mui/material';
import { MARINER, PROGRESS_BAR_BLUE, WHITE } from 'app/theme/colors';

export const EngagementScoreCombineWidgetContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: WHITE,
  borderRadius: 10,
  padding: 24,
  marginBottom: 24,

  '@media print': {
    padding: 0,
    marginBottom: 10,
  },

  '.combineWidgetLeft': {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    width: '50%',
  },

  '.combineWidgetRight': {
    display: 'flex',
    justifyContent: 'right',
    gap: '52px',
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',

    '.combineWidgetLeft': {
      width: 'auto',
      height: '60px',
      justifyContent: 'space-between',
    },

    '.combineWidgetRight': {
      justifyContent: 'space-between',
    },
  },
}));

export const progressBarStyles: SxProps = {
  height: '10px',
  background: PROGRESS_BAR_BLUE,
  borderRadius: '24px',

  '.MuiLinearProgress-bar1Determinate': {
    background: MARINER,
    borderRadius: '24px',
  },
};

export const ScoreWraper = styled('div')({
  margin: '20px 0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const StatisticWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
