import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectModeType,
  selectSorting,
  selectSummaryCategoryViewMode,
} from 'util/selector/querySelector';
import { createFiltersQuery } from 'util/createFiltersQuery';

import {
  EngagementBarsWrapper,
  EngagementViewHeadingWrapper,
  EngagementViewWrapper,
} from 'view/EngagementDriversView/EngagementDriversView.s';
import {
  selectEngagementDriversCombineList,
  selectEngagementDriversCombineQuestionsWithCategories,
} from 'reduxStore/engagementDrivers/selectors';
import { SingleBar } from 'view/EngagementDriversView/SingleBar';
import { OpinionQuestion } from 'model/OpinionQuestion';
import { ProjectKind } from 'model/ProjectKind';
import {
  selectCurrentProjectIsHighPulseFrequency,
  selectIsPulseTemplateProjectKind,
  selectProjectClosedAt,
  selectProjectKind,
} from 'reduxStore/project/selectors';
import { Project } from 'model/Project';
import { EngagementDriver } from 'model/ReportEngagamentDriver';
import { QuestionDataType } from 'view/EngagementDriverByCategoryPage/QuestionTable/QuestionTable';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { Moment } from 'moment';
import { useAppDispatch } from 'reduxStore/appStore';
import {
  DesktopViewAllButton,
  MobileViewAllButton,
} from 'view/EngagementDriversView/EngagementDriversCombine.s';
import { pushQuery } from 'reduxStore/router/asyncActions';
import { SummaryCategoryViewMode } from 'register/ModeType';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';
import { selectSelectedClassifications } from 'reduxStore/demography/selectors';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';

export type EngagementDriversCombineProps = {
  isReportView?: boolean;
};

export type EngagementDriversCombinePureProps = {
  engagementDrivers: OpinionQuestion.Combine[];
  questionTableData: QuestionDataType;
  isAdmin: boolean;
  kind: ProjectKind;
  isPulseHighFrequency: boolean;
  summaryCategoryViewMode: SummaryCategoryViewMode;
  closedAt?: Moment;
  onSummaryCategoryViewModeChange: (mode: SummaryCategoryViewMode) => void;
} & EngagementDriversCombineProps;

export const EngagementDriversCombinePure: React.FC<EngagementDriversCombinePureProps> = (
  props
) => {
  const changeView = () => {
    const newMode =
      props.summaryCategoryViewMode === SummaryCategoryViewMode.Category
        ? SummaryCategoryViewMode.All
        : SummaryCategoryViewMode.Category;
    props.onSummaryCategoryViewModeChange(newMode);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <EngagementViewWrapper data-testid="EngagementView">
      {!props.isReportView && (
        <EngagementViewHeadingWrapper>
          <Typography variant="h5">
            {Project.getEngagementDriversTitle({
              kind: props.kind,
              closedAt: props.closedAt,
            })}
          </Typography>
          {!props.isPulseHighFrequency && isDesktop && (
            <DesktopViewAllButton data-testid="desktopViewAllButton" onClick={changeView}>
              {props.summaryCategoryViewMode === SummaryCategoryViewMode.All
                ? 'View by category'
                : 'View all'}
            </DesktopViewAllButton>
          )}
        </EngagementViewHeadingWrapper>
      )}
      <EngagementBarsWrapper>
        {props.isPulseHighFrequency && (
          <ScrollBar overflow="horizontal" float="none" light>
            <SortableQuestionTable
              data={props.questionTableData}
              isReportView={props.isReportView}
              shouldRenderCategory
            />
          </ScrollBar>
        )}
        {!props.isPulseHighFrequency &&
          (props.summaryCategoryViewMode === SummaryCategoryViewMode.All ? (
            <ScrollBar overflow="horizontal" float="none" light>
              <SortableQuestionTable
                data={props.questionTableData}
                isReportView={props.isReportView}
                shouldRenderCategory
              />
            </ScrollBar>
          ) : (
            props.engagementDrivers.map((engagementDriver, index) => (
              <SingleBar
                key={index}
                index={index}
                categoryId={engagementDriver.category!.id}
                categoryName={engagementDriver.category!.name}
                score={engagementDriver.score}
                trends={engagementDriver.trends}
                benchmark={engagementDriver.benchmark}
              />
            ))
          ))}
      </EngagementBarsWrapper>

      {!isDesktop && !props.isPulseHighFrequency && !props.isReportView && (
        <MobileViewAllButton data-testid="mobileViewAllButton" onClick={changeView}>
          {props.summaryCategoryViewMode === SummaryCategoryViewMode.All
            ? 'View by category'
            : 'View all'}
        </MobileViewAllButton>
      )}
    </EngagementViewWrapper>
  );
};

export const EngagementDriversCombine: React.FC<EngagementDriversCombineProps> = (props) => {
  const dispatch = useAppDispatch();
  const engagementDriversCombineList = useSelector(selectEngagementDriversCombineList);
  const isPulseTemplateProjectKind = useSelector(selectIsPulseTemplateProjectKind);
  const questionTableData = useSelector(selectEngagementDriversCombineQuestionsWithCategories);
  const isAdmin = useSelector(selectIsAdmin);
  const kind = useSelector(selectProjectKind);
  const closedAt = useSelector(selectProjectClosedAt);
  const isPulseHighFrequency = useSelector(selectCurrentProjectIsHighPulseFrequency);
  const summaryCategoryViewMode = useSelector(selectSummaryCategoryViewMode);

  const engagementDrivers = engagementDriversCombineList.map(
    EngagementDriver.mapOpinionQuestionsCombineWithPulse(isPulseTemplateProjectKind)
  );

  const selectedDepartments = useSelector(selectSelectedDepartmentIds);
  const summaryMode = useSelector(selectModeType);
  const selectedClassifications = useSelector(selectSelectedClassifications);
  const sortBy = useSelector(selectSorting);

  const onSummaryCategoryViewModeChange = (categoryMode: SummaryCategoryViewMode) => {
    dispatch(
      pushQuery({
        query: createFiltersQuery({
          classifications: selectedClassifications,
          departments: selectedDepartments,
          summaryMode,
          categoryMode,
          sortBy,
        }),
      })
    );
  };

  return (
    <EngagementDriversCombinePure
      {...props}
      engagementDrivers={engagementDrivers}
      questionTableData={questionTableData}
      isAdmin={isAdmin}
      kind={kind}
      closedAt={closedAt}
      isPulseHighFrequency={isPulseHighFrequency}
      summaryCategoryViewMode={summaryCategoryViewMode}
      onSummaryCategoryViewModeChange={onSummaryCategoryViewModeChange}
    />
  );
};
