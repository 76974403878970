import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { selectReportOrCurrentProjectId } from 'util/selector/reportSelector';

import { useAppDispatch } from 'reduxStore/appStore';
import { clearEngagementDrivers } from 'reduxStore/engagementDrivers/slice';

export const useClearEngagementDrivers = () => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectReportOrCurrentProjectId);

  useUpdateEffect(() => {
    if (!projectId) {
      dispatch(clearEngagementDrivers());
    }
  }, [projectId, dispatch]);
};
