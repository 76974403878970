import { LoadingStateEnum, Resource } from 'model/Resource';
import { PaginatedResponse } from 'model/PaginatedResponse';
import { AdvocacyDtoEnum } from 'api/dto/Advocacy.dto';
import { RespondentModel } from 'api/dto/Respondent.dto';
import { RespondentIndividualFeedbackModel } from 'api/dto/RespondentIndividualFeedback.dto';

export const STORE_NAME = 'respondent';

export namespace RespondentState {
  export type RespondentsFilters = {
    advocacyFilter: AdvocacyDtoEnum[];
    respondentLikeFilter: string;
  };

  export type Domain = {
    resource: Resource<PaginatedResponse<RespondentModel[]> | null>;
    filters: RespondentsFilters;
    individualFeedback: RespondentIndividualFeedbackModel[];
    respondentsTablePage: number;
  };

  export const INITIAL_DOMAIN: Domain = {
    resource: {
      data: null,
      loadingState: LoadingStateEnum.PENDING,
      error: null,
    },
    filters: {
      advocacyFilter: [],
      respondentLikeFilter: '',
    },
    individualFeedback: [],
    respondentsTablePage: 1,
  };
}
