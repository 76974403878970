import {
  ActionReducerMapBuilder,
  AsyncThunk,
  AsyncThunkConfig,
  createSlice,
} from '@reduxjs/toolkit';
import {
  downloadAllCommentsExcelReport,
  downloadAllCommentsPdfReport,
  downloadAllFeedbackPdfReport,
  downloadCategoryReport,
  downloadCommentReport,
  downloadFeedbackReport,
  downloadIndividualFeedbackReport,
  downloadInsightsReport,
  downloadMonthlyBreakdownReport,
  downloadRespondentIndividualFeedbackReport,
} from 'reduxStore/report/asyncActions';
import {
  getReportActionNameByDepartmentId,
  getReportActionNameByReportFormat,
} from 'reduxStore/report/getReportActionName';
import { ReportFormatType } from 'model/ReportFormat';

import { ReportState, STORE_NAME } from './initialState';

const addReportAsyncCases = (
  builder: ActionReducerMapBuilder<ReportState.Domain>,
  asyncThunk: AsyncThunk<void, any, AsyncThunkConfig>,
  getReportActionName: (
    asyncThunk: AsyncThunk<void, any, AsyncThunkConfig>,
    arg: { type?: ReportFormatType; departmentId?: number }
  ) => string
) => {
  builder
    .addCase(asyncThunk.pending, (state, action) => {
      const reportActionName = getReportActionName(asyncThunk, action.meta.arg);
      if (!state.pendingReports.includes(reportActionName)) {
        state.pendingReports.push(reportActionName);
      }
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      const reportActionName = getReportActionName(asyncThunk, action.meta.arg);
      const index = state.pendingReports.indexOf(reportActionName);
      if (index !== -1) {
        state.pendingReports.splice(index, 1);
      }
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      const reportActionName = getReportActionName(asyncThunk, action.meta.arg);
      const index = state.pendingReports.indexOf(reportActionName);
      if (index !== -1) {
        state.pendingReports.splice(index, 1);
      }
    });
};

export const reportSlice = createSlice({
  name: STORE_NAME,
  initialState: ReportState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    [
      downloadFeedbackReport,
      downloadCategoryReport,
      downloadCommentReport,
      downloadInsightsReport,
      downloadAllCommentsExcelReport,
      downloadMonthlyBreakdownReport,
      downloadRespondentIndividualFeedbackReport,
      downloadIndividualFeedbackReport,
    ].forEach((asyncThunk) => {
      addReportAsyncCases(builder, asyncThunk, getReportActionNameByReportFormat);
    });

    addReportAsyncCases(builder, downloadAllFeedbackPdfReport, getReportActionNameByDepartmentId);
    addReportAsyncCases(builder, downloadAllCommentsPdfReport, getReportActionNameByDepartmentId);
  },
});
