import React from 'react';
import { useSelector } from 'react-redux';

import { OnDemandReportCombine } from 'view/ReportView/FeedbackReport/OnDemand/OnDemandReportCombine';
import { OnDemandReportCompare } from 'view/ReportView/FeedbackReport/OnDemand/OnDemandReportCompare';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import {
  selectSimpleProjectCombineResource,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';

export type ODPReportProps = {
  isCompareMode: boolean;
};
export const OnDemandReport: React.FC<ODPReportProps> = ({ isCompareMode }) => {
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);

  const simpleProjectResource = isCompareMode
    ? simpleProjectCompareResource
    : simpleProjectCombineResource;

  return (
    <ResourceLoader resource={simpleProjectResource}>
      {isCompareMode ? <OnDemandReportCompare /> : <OnDemandReportCombine />}
    </ResourceLoader>
  );
};
