import { styled } from '@mui/material';
import Slider from '@mui/material/Slider';
import { WHITE, WORKBUZZ_ORANGE, IRON, SONIC_SILVER } from 'app/theme/colors';
import ArrowRight from 'react-icons/lib/md/keyboard-arrow-right';
import ArrowLeft from 'react-icons/lib/md/keyboard-arrow-left';

export const SliderSectionContainer = styled('div')({
  background: WHITE,
  padding: '16px 24px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  marginBottom: 24,
  marginTop: 24,
});

export const SliderSectionWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export type ArrowIconProps = {
  disabled: boolean;
};

export const ArrowIconRight = styled(ArrowRight)(
  {
    fontSize: 40,
    position: 'relative',
    bottom: '4px',
    marginLeft: '8px',
  },
  (props: ArrowIconProps): any => ({
    cursor: props.disabled ? 'auto' : 'pointer',
    color: props.disabled ? SONIC_SILVER : undefined,
  })
);

export const ArrowIconLeft = styled(ArrowLeft)(
  {
    fontSize: 40,
    position: 'relative',
    bottom: '4px',
  },
  (props: ArrowIconProps): any => ({
    cursor: props.disabled ? 'auto' : 'pointer',
    color: props.disabled ? SONIC_SILVER : undefined,
  })
);

export const StyledSlider = styled(Slider)(() => ({
  color: WORKBUZZ_ORANGE,

  '& .MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
    backgroundColor: IRON,
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: WORKBUZZ_ORANGE,
    border: `2px solid ${WORKBUZZ_ORANGE}`,
    '&:focus, &:hover, &:active': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-track': {
    height: 8,
    borderRadius: 4,
    backgroundColor: WORKBUZZ_ORANGE,
    borderColor: WORKBUZZ_ORANGE,
  },
  '& .MuiSlider-mark': {
    display: 'none',
  },

  '& .MuiSlider-thumb:before': {
    boxShadow: 'none',
  },
}));
