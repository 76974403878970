import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { DeepPartial } from 'shared/utils/test/renderWithApp';
import { configureStore, ThunkAction, combineReducers } from '@reduxjs/toolkit';
import { AnyAction, Middleware } from 'redux';
import { History } from 'history';
import appHistory from 'app/history';
import { loggingMiddleware } from 'reduxStore/appMiddlewares';

import { AppState as AppStateFromReducer, appReducerMap } from './appReducer';

export type AppState = AppStateFromReducer & {
  router: RouterState<unknown>;
};

export const prepareStore = ({
  preloadedState,
  history = appHistory,
  middleware = [],
}: {
  preloadedState?: DeepPartial<AppState>;
  history?: History;
  middleware?: Middleware[];
}) => {
  return configureStore({
    reducer: combineReducers<AppState>({
      ...appReducerMap,
      router: connectRouter(history),
    }),
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
        .concat(middleware)
        .concat(routerMiddleware(history));
    },
    preloadedState: preloadedState as AppState,
  });
};

export const store = prepareStore({ middleware: [loggingMiddleware] });

export type StoreType = ReturnType<typeof prepareStore>;

export type AppThunk<R = void> = ThunkAction<R, AppState, undefined, AnyAction>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
