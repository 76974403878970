import { Topic } from 'model/Topics';
import Color from 'color-scales/lib/Color';
import { head, last, sortBy } from 'lodash';
import { Sentiment } from 'model/Sentiment';

export type LabelData = { hide: boolean; value: string; position: 'top' | 'bottom' };
export type ChartItem = Topic & { color: Color } & { label: LabelData };
export type ChartData = {
  /**
   * Domain tell the chart what values to expect
   * It should return minimum and maximum value found in the data
   */
  domain: [number, number];
  /**
   * Range is hardcoded config that define what is the minimal and maximal size of the bubble.
   * Increasing first value make the smaller values from the domain render bigger size bubbles
   * Increasing the second value increase the maximum size of the bubble can get
   * Increasing distance between those values increase the difference between bubbles from the start of the domain and the end.
   */
  range: [number, number];
  list: ChartItem[];
  scoreDomain: [number, number];
};

type LabelPosition = 'top' | 'bottom';
const labelPositions: (LabelPosition | null)[] = [];

export const topicsToChartData = (topics: Topic[]): ChartData => {
  const sortedByCommentsCount = sortBy(topics, ['commentCount']);
  const domain: ChartData['domain'] = [
    head(sortedByCommentsCount)?.commentCount || 0,
    last(sortedByCommentsCount)?.commentCount || 0,
  ];

  const labelDisplayBoundary =
    sortedByCommentsCount[Math.floor(sortedByCommentsCount.length / 2)]?.commentCount;

  const sortedByScore = sortBy(topics, ['score']);

  const scoreDomain: ChartData['scoreDomain'] = [
    Math.max(head(sortedByScore)?.score || -1, -1),
    Math.min(last(sortedByScore)?.score || 1, 1),
  ];

  // TODO if performance is an issue remove spread operator
  return {
    domain,
    scoreDomain,
    range: [750, 7500],
    list: topics.map((topic, index, list) => {
      const hide = topic.commentCount < labelDisplayBoundary;
      const position = getPosition([list[index - 1], topic], labelPositions[index - 1]);

      labelPositions[index] = hide ? null : position;

      return {
        ...topic,
        label: {
          hide: hide,
          value: topic.name,
          position: position,
        },
        color: Sentiment.getValueColor(topic.score),
      };
    }),
  };
};

const getPosition = (
  [prevTopic, currTopic]: [Topic | undefined, Topic],
  prevLabelPosition: LabelPosition | null
): LabelPosition => {
  return hasPreviousTopicSameScore(prevTopic, currTopic) && prevLabelPosition === 'bottom'
    ? 'top'
    : 'bottom';
};

const hasPreviousTopicSameScore = (
  previousTopic: Topic | undefined,
  currentTopic: Topic
): boolean => {
  return previousTopic?.score === currentTopic.score;
};
