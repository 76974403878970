import { Box, styled } from '@mui/material';
import { BORDER_ANSWER, IRON, WHITE } from 'app/theme/colors';

type AnswerItemProps = {
  isReportView?: boolean;
};

export const AnswerItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isReportView',
})<AnswerItemProps>({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  pageBreakInside: 'avoid',
  pageBreakAfter: 'auto',
  flex: '1',
  minHeight: 80,
  '@media print': { minHeight: 20 },
});

export const AnswerItemWrapper = styled(Box)({
  breakInside: 'avoid',
  paddingBottom: '24px',
  '@media print': {
    padding: '24px 0',
    borderBottom: `1px solid ${BORDER_ANSWER}`,
    '&:first-of-type': {
      paddingTop: 0,
    },
    '&:last-of-type': {
      borderBottom: '0',
    },
  },
});

type ContentWrapperProps = {
  isReportView?: boolean;
};

export const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isReportView',
})<ContentWrapperProps>(({ theme, isReportView }) => ({
  width: '100%',
  padding: isReportView ? 0 : '24px',
  [theme.breakpoints.down('md')]: {
    padding: isReportView ? 0 : '20px',
  },
}));

export const CommentAnswersWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const Block = styled('div')({
  minHeight: '80px',
  width: '3px !important',
  backgroundColor: IRON,
});

export const Divider = styled('hr')({
  display: 'flex',
  width: '100%',
  border: 'none',
  borderBottom: `1px solid ${BORDER_ANSWER}`,
  margin: '20px 0',
});

export const PaginationWrapper = styled('div')({
  marginTop: '20px',
});

export type RowsWrapperProps = {
  mobileDirection?: string;
};

export const RowsWrapper = styled('div')<RowsWrapperProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '30px 0',

  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
}));

export type RowCellWrapperProps = {
  noMargins?: true;
};

export const RowCellWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noMargins',
})<RowCellWrapperProps>(({ theme, noMargins }) => ({
  marginRight: '30px',
  [theme.breakpoints.down('md')]: noMargins ? { marginRight: 0 } : {},
}));

export const ComponentWrapper = styled('div')({
  width: '100%',
});

export const CommentsPageCard = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: WHITE,
  borderRadius: '10px',
  padding: 24,
});
