import React, { ReactNode } from 'react';

import { forScoreCases } from 'util/forScoreCases';

import { Benchmark } from 'model/Benchmark';
import { Trend } from 'model/Trend';
import { LinearProgress, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon } from 'component/Statistics/Statistics.s';
import { CARDINAL, ENGAGEMENT_GREY, GREEN_GARLANDS, SONIC_SILVER } from 'app/theme/colors';
import { Score } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/Score/Score';

import {
  EngagementScoreCombineWidgetContainer,
  ScoreWraper,
  StatisticWrapper,
  progressBarStyles,
} from './EngagementScoreCombineWidget.s';

export type EngagementScoreCombineWidgetProps = {
  score: number;
  categoryTitle: string;
  benchmark?: Benchmark;
  trends?: ReadonlyArray<Trend>;
  trendLabel?: ReactNode;
};

export const EngagementScoreCombineWidget: React.FC<EngagementScoreCombineWidgetProps> = (
  props
) => {
  const { categoryTitle } = props;

  return (
    <EngagementScoreCombineWidgetContainer>
      <div className="combineWidgetLeft">
        <Typography variant="h3">{categoryTitle}</Typography>
        <LinearProgress
          data-testid="engagementBar"
          sx={progressBarStyles}
          value={props.score}
          variant="determinate"
        />
      </div>
      <div className="combineWidgetRight">
        <Score score={props.score} />

        {props.trends && (
          <ScoreWraper>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
              {props.trendLabel}
            </Typography>
            <SimplifiedStatistic score={props.trends[0].diff} />
          </ScoreWraper>
        )}

        {Benchmark.isNotNone(props.benchmark) && props.benchmark && (
          <ScoreWraper>
            <Typography variant="body2">{props.benchmark.label}</Typography>
            <SimplifiedStatistic score={props.benchmark.value} />
          </ScoreWraper>
        )}
      </div>
    </EngagementScoreCombineWidgetContainer>
  );
};

export const SimplifiedStatistic = (props: {
  score: number | string | null;
  isSingleBar?: boolean;
}) => {
  return (
    <StatisticWrapper data-testid="statistics">
      {!isNumber(props.score) && <Typography variant="h3">-</Typography>}
      {isNumber(props.score) && (
        <>
          {forScoreCases(Number(props.score), {
            negative: <ArrowDownIcon isSingleBar={!!props.isSingleBar} data-testid="arrowUp" />,
            neutral: <ArrowRightIcon data-testid="arrowRight" />,
            positive: <ArrowUpIcon isSingleBar={!!props.isSingleBar} data-testid="arrowDown" />,
          })}

          <Typography
            color={
              props.isSingleBar
                ? SONIC_SILVER
                : forScoreCases(props.score, {
                    negative: CARDINAL,
                    neutral: ENGAGEMENT_GREY,
                    positive: GREEN_GARLANDS,
                  })
            }
            variant={props.isSingleBar ? 'h5' : 'h3'}
            data-testid="statisticScore"
          >
            {Math.abs(props.score)}
          </Typography>
        </>
      )}
    </StatisticWrapper>
  );
};
