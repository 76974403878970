import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode, selectTitlePageOnly } from 'util/selector/querySelector';

import { ProjectKind } from 'model/ProjectKind';
import { TitlePage } from 'view/ReportView/ReportTemplate/TitlePage/TitlePage';
import { ReportType } from 'model/ReportType';
import { selectReportDepartment } from 'reduxStore/departments/selectors';
import { selectProjectKind, selectReportTitle } from 'reduxStore/project/selectors';
import { EngagementScoreReport } from 'view/ReportView/FeedbackReport/EngagementScore/EngagementScoreReport';
import { CasReport } from 'view/ReportView/FeedbackReport/Cas/CasReport';
import { OverallSurveyReport } from 'view/ReportView/FeedbackReport/Overall/OverallSurveyReport';
import { OnDemandReport } from 'view/ReportView/FeedbackReport/OnDemand/OnDemandReport';
import { ErrorLabelWrapper } from 'component/ErrorHandler/ErrorHandler.s';
import { LifecycleReport } from 'view/ReportView/FeedbackReport/Lifecycle/LifecycleReport';
import { useFetchOverall } from 'shared/hooks/useFetchOverall';
import { useFetchEngagementScore } from 'shared/hooks/useFetchEngagementScore';
import { useFetchSimpleProject } from 'shared/hooks/useFetchSimpleProject';

export const FeedbackReportView: React.FC = () => {
  const projectKind = useSelector(selectProjectKind);
  const selectedDepartment = useSelector(selectReportDepartment);
  const isCompareMode = useSelector(selectIsCompareMode);
  const isTitlePageOnly = useSelector(selectTitlePageOnly);
  const reportTitle = useSelector(selectReportTitle);

  useFetchEngagementScore();
  useFetchOverall();
  useFetchSimpleProject();

  const selectReportByProjectKind = () => {
    switch (projectKind) {
      case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      case ProjectKind.PULSE_PROJECT:
        return <EngagementScoreReport isCompareMode={isCompareMode} />;
      case ProjectKind.PULSE_LIFECYCLE_PROJECT:
        return <LifecycleReport isCompareMode={isCompareMode} />;
      case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
        return <CasReport isCompareMode={isCompareMode} />;
      case ProjectKind.PROJECT_OVERALL_SCORE:
        return <OverallSurveyReport isCompareMode={isCompareMode} />;
      case ProjectKind.PULSE_ON_DEMAND_PROJECT:
        return <OnDemandReport isCompareMode={isCompareMode} />;
      default:
        return <ErrorLabelWrapper>Project kind is not supported</ErrorLabelWrapper>;
    }
  };

  if (isTitlePageOnly) {
    return (
      <TitlePage
        title={reportTitle}
        reportType={ReportType.Feedback}
        departmentName={selectedDepartment?.name}
      />
    );
  }

  return <div>{selectReportByProjectKind()}</div>;
};
