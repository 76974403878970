import { selectIsReportsTab } from 'util/selector/reportSelector';

import { AppState } from 'reduxStore/appStore';
import { VisualSettingsState } from 'reduxStore/visualSettings/initialState';
import { createSelector } from 'reselect';
import { selectBenchmark } from 'reduxStore/summary/selectors';
import { selectDemography } from 'reduxStore/demography/selectors';
import { selectDepartments } from 'reduxStore/departments/selectors';
import { Project } from 'model/Project';

export function selectDomain(state: AppState): VisualSettingsState.Domain {
  return state.visualSettings || VisualSettingsState.INITIAL_DOMAIN;
}

export const selectIsCombineModeLockFromVisualSettings = createSelector(
  selectDomain,
  (domain) => domain && domain.isCombineModeLock
);

export const selectShowImpactOnEngagement = createSelector(
  selectDomain,
  (domain) => domain && domain.showImpactOnEngagement
);

export const selectChartLabel = createSelector(
  selectDomain,
  (domain) => domain && domain.chartLabel
);

export const selectBenchmarkFromVisualSettings = createSelector(
  selectDomain,
  selectBenchmark,
  (domain, benchmark) => {
    // TODO get rid of double benchmark storing once api updated
    return (domain && domain.benchmark) || benchmark;
  }
);
export const selectIsSidebarHidden = createSelector(
  selectIsReportsTab,
  selectDemography,
  selectDepartments,
  (isReportsTab, demography, departments) => {
    return Project.shouldHideSidebar(isReportsTab, demography, departments);
  }
);

export const selectIsClearingAllFilters = createSelector(
  selectDomain,
  (domain) => domain.isClearingAllFilters
);

export const selectPreferredMode = createSelector(selectDomain, (domain) => domain.preferredMode);
