import { styled } from '@mui/material';

export const HeadRow = styled('tr')({
  fontWeight: 600,
  fontSize: 15,
  '@media print': {
    pageBreakAfter: 'auto',
    pageBreakBefore: 'auto',
    pageBreakInside: 'auto',
  },
});

export type HeaderCellProps = {
  textLength?: number;
  isReportView?: boolean;
};

export const HeaderCell = styled('th', {
  shouldForwardProp: (prop) => prop !== 'isReportView',
})(
  {
    padding: '15px 8px',
    verticalAlign: 'middle',
    textAlign: 'center',
    maxWidth: 130,
  },
  (props: HeaderCellProps) => ({
    fontSize: props.textLength && props.textLength > 18 ? 12 : 'inherit',
  })
);

export const HeaderCellContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
