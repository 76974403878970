import React, { ErrorInfo, PropsWithChildren } from 'react';

import { ErrorPage } from './ErrorPage';

type ErrorBoundaryPageState =
  | { hasError: true; error: Error; info: ErrorInfo }
  | {
      hasError: false;
      error?: unknown;
      info?: unknown;
    };

export class ErrorBoundary extends React.Component<PropsWithChildren<{}>, ErrorBoundaryPageState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // TODO Add Datadog Log reporting here when will be enabled in project
    console.error(error, info);

    this.setState({
      hasError: true,
      error,
      info,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
