import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';

import { OverallSurveyState } from './initialState';

export const selectDomain = (state: AppState): OverallSurveyState.Domain => state.overallSurvey;

export const selectOverallSurveyCombineResource = createSelector(
  selectDomain,
  (domain) => domain.combine
);

export const selectOverallSurveyCompareResource = createSelector(
  selectDomain,
  (domain) => domain && domain.compare
);
