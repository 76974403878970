import React from 'react';

import { Typography } from '@mui/material';

import { ScoreWrapper } from './Score.s';

export type ScoreProps = {
  score: number;
};

export const Score: React.FC<ScoreProps> = ({ score }) => (
  <ScoreWrapper data-testid="engagementDriverByCategoryScore">
    <Typography variant="body2" data-testid="scoreTitle">
      Score
    </Typography>
    <Typography variant="h3" data-testid="scoreValue">
      {score}
    </Typography>
  </ScoreWrapper>
);
