import { styled } from '@mui/material';
import { BOULDER, IRON, PLACEHOLDER_FOCUS_GREY, TEXT_COLOR, WHITE } from 'app/theme/colors';
import Icon from 'react-icons/lib/md/search';

export const SearchInputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0 16px',
});

export const SearchButtonInput = styled('input')({
  height: '48px',
  border: `2px solid ${IRON}`,
  borderRadius: '6px',
  padding: '12px 16px',
  color: BOULDER,
  backgroundColor: WHITE,
  cursor: 'pointer',
  width: '100%',
  '&:focus': {
    outline: 0,
  },
  '::placeholder': {
    color: BOULDER,
  },
  '&:focus::placeholder': {
    color: PLACEHOLDER_FOCUS_GREY,
  },
});

export const SearchIconWrapper = styled('span')({
  position: 'relative',
});

export const SearchIcon = styled(Icon)({
  fontSize: '16px',
  position: 'absolute',
  right: 16,
  top: '50%',
  color: TEXT_COLOR,
  transform: 'translate(0, -50%)',
});
