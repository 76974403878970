import { Draft, createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import { User } from 'model/User';

import { fetchUser, getUserPermissions } from './asyncActions';
import { STORE_NAME, UserState } from './initialState';

export const userSlice = createSlice({
  name: STORE_NAME,
  initialState: UserState.INITIAL_DOMAIN,
  reducers: {
    clearUserPermissions: (state) => {
      if (state.data) {
        state.data.permissions = [];
      }
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchUser, (state, action) => {
      state.data = action.payload.data;
    });

    builder.addCase(getUserPermissions.fulfilled, (state, action) => {
      state.data = action.payload as Draft<User>;
    });
  },
});

export const { clearUserPermissions } = userSlice.actions;
