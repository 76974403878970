import React from 'react';
import { useSelector } from 'react-redux';

import { selectFiltersQuery } from 'util/selector/querySelector';

import { ProjectKind } from 'model/ProjectKind';
import { selectCurrentProjectId, selectProjectKind } from 'reduxStore/project/selectors';
import { EngagementProjectInfo } from 'view/SummaryPage/SummaryInfo/EngagementProjectInfo';
import { CasProjectInfo } from 'view/SummaryPage/SummaryInfo/CasProjectInfo';
import { selectShouldShowEngagementScore } from 'reduxStore/engagementDrivers/selectors';

export type SummaryInfoProps = {
  overrideProjectKind?: ProjectKind;
};

export type SummaryInfoPureProps = {
  projectKind: ProjectKind;
  query: string;
  projectId: number;
  showEngagementScore: boolean;
  overrideProjectKind?: ProjectKind;
};

export const SummaryInfoPure: React.FC<SummaryInfoPureProps> = (props) => {
  const type = props.overrideProjectKind ? props.overrideProjectKind : props.projectKind;

  switch (type) {
    case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      return <EngagementProjectInfo projectId={props.projectId} />;
    case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
      return <CasProjectInfo />;
    case ProjectKind.PULSE_PROJECT:
      if (props.showEngagementScore) {
        return <EngagementProjectInfo projectId={props.projectId} />;
      }
      return null;
    default:
      return null;
  }
};

export const SummaryInfo: React.FC<SummaryInfoProps> = (props) => {
  const projectKind = useSelector(selectProjectKind);
  const query = useSelector(selectFiltersQuery);
  const projectId = useSelector(selectCurrentProjectId);
  const showEngagementScore = useSelector(selectShouldShowEngagementScore);

  return (
    <SummaryInfoPure
      overrideProjectKind={props.overrideProjectKind}
      projectKind={projectKind}
      query={query}
      projectId={projectId}
      showEngagementScore={showEngagementScore}
    />
  );
};
