import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';

import { ReportState, STORE_NAME } from './initialState';

export const selectReportDomain = (state: AppState): ReportState.Domain => state[STORE_NAME];

export const selectPendingReports = createSelector(
  selectReportDomain,
  (domain) => domain.pendingReports
);

export const selectIsPendingReport = (name: string) =>
  createSelector(selectPendingReports, (pendingReports: string[]) => pendingReports.includes(name));
