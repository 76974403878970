import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectIsReportView } from 'util/selector/reportSelector';
import { selectModeType } from 'util/selector/querySelector';

import { useAppDispatch } from 'reduxStore/appStore';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { route } from 'reduxStore/router/asyncActions';
import { AppRoute } from 'app/route/app';
import {
  selectDetailsByCategoryId,
  selectEngagementDriversCombineResource,
  selectEngagementDriversCompareResource,
} from 'reduxStore/engagementDrivers/selectors';
import { LoadingStateEnum } from 'model/Resource';
import { ModeType } from 'register/ModeType';

export const useRedirectToSummary = () => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  const engagementDriversCombineResource = useSelector(selectEngagementDriversCombineResource);
  const engagementDriversCompareResource = useSelector(selectEngagementDriversCompareResource);
  const detailsByCategoryId = useSelector(selectDetailsByCategoryId);
  const isReportView = useSelector(selectIsReportView);
  const mode = useSelector(selectModeType);
  const isCombineResourceResolved =
    mode === ModeType.COMBINED &&
    engagementDriversCombineResource?.loadingState === LoadingStateEnum.RESOLVED;
  const isCompareResourceResolved =
    mode === ModeType.COMPARED &&
    engagementDriversCompareResource?.loadingState === LoadingStateEnum.RESOLVED;

  useEffect(() => {
    if (
      !isReportView &&
      !detailsByCategoryId &&
      (isCombineResourceResolved || isCompareResourceResolved)
    ) {
      dispatch(route({ pattern: AppRoute.Home.SUMMARY, params: { id: projectId } }));
    }
  }, [
    projectId,
    detailsByCategoryId,
    isCombineResourceResolved,
    isCompareResourceResolved,
    isReportView,
    dispatch,
  ]);
};
