import { CommentApi } from 'api/CommentApi';
import { Answer } from 'model/Answer';
import { mapRespondentDtoToModel } from 'api/dto/Respondent.dto';

export namespace CommentAnswerMapper {
  export const deserialize = (entry: CommentApi.AnswerEntry): Answer => {
    return {
      id: entry.id,
      answer: entry.answer,
      sentiment: entry.sentiment || null,
      respondent: entry.respondent ? mapRespondentDtoToModel(entry.respondent) : null,
    };
  };
}
