import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { EmployeeInfo } from 'component/EmployeeInfo/EmployeeInfo';
import { AdvocacyTag } from 'component/Tags/AdvocacyTag';
import { YesNoTag } from 'component/Tags/YesNoTag';
import { Pagination as PaginationModel } from 'model/Pagination';
import { Pagination } from 'component/Pagination/Pagination';
import {
  selectCurrentCompanyName,
  selectCurrentProjectId,
  selectShowConsentQuestion,
} from 'reduxStore/project/selectors';
import { RespondentModel } from 'api/dto/Respondent.dto';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { DocumentIcon } from 'component/DocumentIcon/DocumentIcon';
import { DocumentType } from 'model/DocumentType';
import { Dropdown } from 'component/Dropdown/Dropdown';
import { downloadRespondentIndividualFeedbackReport } from 'reduxStore/report/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import { getReportActionNameByReportFormat } from 'reduxStore/report/getReportActionName';
import { typography } from 'app/theme/typography';
import { Block } from 'view/CommentsPage/CommentsPage.s';

import { PaginationWrapper } from './IndividualFeedbackPage.s';

export type RespondentsTableProps = {
  respondents: RespondentModel[];
  pagination: PaginationModel;
  onPageChange: (page: number) => void;
};

export const RespondentsTable: React.FC<RespondentsTableProps> = ({
  respondents,
  pagination,
  onPageChange,
}) => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  const companyName = useSelector(selectCurrentCompanyName);
  const showConsentQuestion = useSelector(selectShowConsentQuestion);
  const tableWidth = showConsentQuestion ? 1050 : 860;
  const isExcelDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadRespondentIndividualFeedbackReport, {
        type: ReportFormat.Excel,
      })
    )
  );
  const isPDFDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadRespondentIndividualFeedbackReport, {
        type: ReportFormat.PDF,
      })
    )
  );

  const onReportDownload = (respondentId: string, type: ReportFormatType) => {
    dispatch(
      downloadRespondentIndividualFeedbackReport({ type, projectId, respondentIds: [respondentId] })
    );
  };
  const [paddingBottom, setPaddingBottom] = useState('0');

  return (
    <Box sx={{ paddingBottom }}>
      <Table style={{ maxWidth: tableWidth }} data-testid="respondentsTable">
        <TableHead>
          <TableRow style={{ verticalAlign: 'middle' }}>
            <TableCell />
            <TableCell align="center">
              <Typography sx={{ ...typography.subtitle2, fontWeight: 800 }}>Advocacy</Typography>
            </TableCell>
            {showConsentQuestion && (
              <TableCell data-testid="consentQuestionColumn" align="center">
                <Typography sx={{ ...typography.subtitle2, fontWeight: 800 }}>
                  Opt-in to share
                  <br />
                  with line manager
                </Typography>
              </TableCell>
            )}
            <TableCell align="center">
              <Typography sx={{ ...typography.subtitle2, fontWeight: 800 }}>
                Download individual
                <br />
                feedback report
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {respondents.map((respondent, index) => {
            const { id, advocacy, shareWithManager } = respondent;
            const isLast = index === respondents.length - 1;
            return (
              <TableRow key={id} data-testid="employeeAnswer">
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EmployeeInfo respondent={respondent} />
                    <Block sx={{ minHeight: '70px' }} />
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box style={{ display: 'inline-block', maxWidth: '180px' }}>
                    <AdvocacyTag
                      advocacy={advocacy}
                      companyName={companyName}
                      data-testid="advocacy"
                    />
                  </Box>
                </TableCell>
                {showConsentQuestion && (
                  <TableCell align="center" style={{ minWidth: 150 }}>
                    <YesNoTag value={!!shareWithManager} />
                  </TableCell>
                )}
                <TableCell align="center">
                  <Dropdown
                    label="Download Report"
                    data-testid={`exportRespondentIndividualFeedback[${id}]`}
                    onToggle={
                      isLast
                        ? (isVisible) => {
                            setPaddingBottom(isVisible ? '40px' : '0');
                          }
                        : undefined
                    }
                  >
                    <DropdownItem onClick={() => onReportDownload(id, ReportFormat.Excel)}>
                      <DocumentIcon
                        type={DocumentType.Excel}
                        isDownloadPending={isExcelDownloadPending}
                      />
                      <span data-testid={`exportRespondentIndividualFeedbackExcelOption[${id}]`}>
                        Excel
                      </span>
                    </DropdownItem>
                    <DropdownItem onClick={() => onReportDownload(id, ReportFormat.PDF)}>
                      <DocumentIcon
                        type={DocumentType.PDF}
                        isDownloadPending={isPDFDownloadPending}
                      />
                      <span data-testid={`exportRespondentIndividualFeedbackPDFOption[${id}]`}>
                        PDF
                      </span>
                    </DropdownItem>
                  </Dropdown>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {pagination.pageCount > 1 && (
        <PaginationWrapper>
          <Pagination
            pageCount={pagination.pageCount}
            page={pagination.index}
            onPageChange={onPageChange}
          />
        </PaginationWrapper>
      )}
    </Box>
  );
};
