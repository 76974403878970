import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { LogoWrapper, Menu, TopNavBarWrapper } from 'component/TopNavBar/TopNavBar.s';
import WorkbuzzLogo from 'app/asset/workbuzz_logo.svg';
import { AppRoute } from 'app/route/app';
import { compilePath } from 'router/compilePath';

export namespace TopNavBar {
  export type StateProps = {};
  export type DispatchProps = {};
  export type OwnProps = {};
  export type Props = StateProps & DispatchProps & OwnProps;
}

export const TopNavBar: React.FC<PropsWithChildren<TopNavBar.Props>> = ({ children }) => {
  return (
    <TopNavBarWrapper data-testid="topNavBarWrapper">
      <Menu>
        <LogoWrapper>
          <Link to={compilePath(AppRoute.HOME)}>
            <WorkbuzzLogo data-testid="topNavBarLogo" />
          </Link>
        </LogoWrapper>
        {children}
      </Menu>
    </TopNavBarWrapper>
  );
};
