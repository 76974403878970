import React from 'react';

import { ReportHeader } from 'view/ReportView/ReportTemplate/ReportHeader/ReportHeader';
import { ReportBody } from 'view/ReportView/ReportTemplate/ReportBody/ReportBody';

import { ReportWrapper } from './ReportContent.s';

export type ReportContentProps = {
  title?: string;
  component: React.ReactElement;
  reportType?: string;
  departmentName?: string;
  'data-testid'?: string;
};

export type ReportContentPureProps = {} & ReportContentProps;

export const ReportContentPure: React.FC<ReportContentPureProps> = (props) => (
  <ReportWrapper data-testid={props['data-testid']}>
    <ReportHeader title={props.title} reportType={props.reportType} />
    <ReportBody component={props.component} />
  </ReportWrapper>
);

export const ReportContent: React.FC<ReportContentProps> = (props) => {
  return <ReportContentPure {...props} />;
};
