import React from 'react';

import { SummaryCombineWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCombineWidgets/SummaryCombineWidgets';
import { EngagementDriversCombine } from 'view/EngagementDriversView/EngagementDriversCombine';

export namespace OverallCombinePage {
  export type Props = {};
}

export const OverallCombinePage: React.FC<OverallCombinePage.Props> = () => {
  return (
    <>
      <SummaryCombineWidgets />

      <EngagementDriversCombine />
    </>
  );
};
