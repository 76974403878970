import { useUpdateEffect } from 'react-use';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectQueryString } from 'util/selector/routerSelector';

import { useAppDispatch } from 'reduxStore/appStore';
import { fetchCommentsQuestions } from 'reduxStore/comments/asyncActions';
import { usePushLegalQuery } from 'shared/hooks/usePushLegalQuery';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { resetSelectedQuestion } from 'reduxStore/comments/slice';
import { useFetchAllSentimentTopics } from 'view/CommentsPage/hooks/useFetchAllSentimentTopics';
import { useDebounceValue } from 'shared/hooks/useDebounceValue';
import { useFetchCommentsQuestions } from 'shared/hooks/useFetchCommentsQuestions';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import { useFetchDepartments } from 'shared/hooks/useFetchDepartments';
import { useFetchDemography } from 'shared/hooks/useFetchDemography';
import { ModeType } from 'register/ModeType';

export const useCommentsPageEffects = () => {
  const dispatch = useAppDispatch();
  const currentProjectId = useSelector(selectCurrentProjectId);
  const queryString = useSelector(selectQueryString);

  const debouncedQueryStringDefault = `mode=${ModeType.COMBINED}`;
  const debouncedQueryString =
    useDebounceValue(queryString, FILTERS_CHANGED_DEBOUNCE_TIMEOUT) || debouncedQueryStringDefault;

  usePushLegalQuery();
  useFetchAllSentimentTopics();
  useFetchCommentsQuestions();
  useFetchDemography();
  useFetchDepartments();

  useEffect(() => {
    dispatch(fetchCommentsQuestions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQueryString, currentProjectId]);

  useUpdateEffect(() => {
    dispatch(resetSelectedQuestion());
    dispatch(fetchCommentsQuestions());
  }, [currentProjectId]);
};
