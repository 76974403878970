export type Department = {
  id: number;
  name: string;
  children: Department[];
  blocked: boolean;
  parentId: number | undefined;
  checked: boolean;
  collapsed: boolean;
  matched: boolean;
  level?: number;
};

export namespace Department {
  export const showFilters = (departments: Department[] = []): boolean => {
    return flattenDepartments(departments).length > 1;
  };

  export const flattenDepartments = (departments: Department[] = []): Department[] => {
    return (
      departments.reduce(
        (flat, department) => [...flat, department, ...flattenDepartments(department.children)],
        [] as Department[]
      ) || []
    );
  };

  export const getSortedDepartmentsIds = (departments: Department[] = []): number[] => {
    return departments.map((department) => department.id).sort();
  };

  export const mergeDepartments = ({
    stateDepartmentsFlatten,
    payloadDepartments,
  }: {
    stateDepartmentsFlatten: Department[];
    payloadDepartments: Department[];
  }): Department[] => {
    return payloadDepartments.map((department) => {
      const matchedStateDepartment = stateDepartmentsFlatten.find(
        (stateDepartment) => stateDepartment.id === department.id
      );
      return matchedStateDepartment
        ? ({
            ...department,
            checked: matchedStateDepartment.checked,
            collapsed: matchedStateDepartment.collapsed,
            matched: matchedStateDepartment.matched,
            children: mergeDepartments({
              stateDepartmentsFlatten: stateDepartmentsFlatten,
              payloadDepartments: department.children,
            }),
          } as Department)
        : department;
    });
  };
}
