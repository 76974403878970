import React from 'react';

import { Tag, TagVariantEnum } from 'component/Tags/Tag';

export type YesNoTagProps = {
  value: boolean;
};

export const YesNoTag: React.FC<YesNoTagProps> = ({ value }) => (
  <Tag variant={value ? TagVariantEnum.Positive : TagVariantEnum.Negative}>
    <span data-testid="yesNoTagSpan">{value ? 'Yes' : 'No'}</span>
  </Tag>
);
