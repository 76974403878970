import React from 'react';
import { useSelector } from 'react-redux';

import { EngagementScoreReportCompare } from 'view/ReportView/FeedbackReport/EngagementScore/EngagementScoreReportCompare';
import { EngagementScoreReportCombine } from 'view/ReportView/FeedbackReport/EngagementScore/EngagementScoreReportCombine';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import {
  selectEngagementDriversCombineResource,
  selectEngagementDriversCompareResource,
} from 'reduxStore/engagementDrivers/selectors';

export type EngagementScoreReportProps = {
  isCompareMode: boolean;
};

export const EngagementScoreReport: React.FC<EngagementScoreReportProps> = ({ isCompareMode }) => {
  const combineResource = useSelector(selectEngagementDriversCombineResource);
  const compareResource = useSelector(selectEngagementDriversCompareResource);

  if (isCompareMode) {
    return (
      <ResourceLoader resource={compareResource}>
        <EngagementScoreReportCompare />
      </ResourceLoader>
    );
  }

  return (
    <ResourceLoader resource={combineResource}>
      <EngagementScoreReportCombine />
    </ResourceLoader>
  );
};
