import { styled } from '@mui/material';
import { IRON } from 'app/theme/colors';

export const TableTrendPlotColumn = styled('td')({
  verticalAlign: 'middle',
});

export const ColumnWrapper = styled('td')({
  verticalAlign: 'middle',
});

export const TableRow = styled('tr')({
  textAlign: 'left',
  borderTop: `1px solid ${IRON}`,
  pageBreakInside: 'avoid',
});

export const TableTrendPlotWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'left',
});
