import React from 'react';

import { NotFoundPageContentWrapper } from 'router/components/NotFound/NotFound.s';
import { Box, Typography } from '@mui/material';
import { BUTTON_ORANGE, WHITE } from 'app/theme/colors';
import { NavItem } from 'component/NavItem/NavItem';
import { compilePath } from 'router/compilePath';
import { AppRoute } from 'app/route/app';

export const NotFoundProjectPage: React.FC = () => {
  return (
    <NotFoundPageContentWrapper>
      <Box component="h2" sx={{ pb: 2 }}>
        Project not found
      </Box>
      <Typography variant="body1">It seems that project has moved or doesn’t exist.</Typography>
      <Typography variant="body1" mb={2}>
        If the error continues please email{' '}
        <a href="mailto:support@workbuzz.com" target="_blank">
          support@workbuzz.com
        </a>
        .
      </Typography>
      <NavItem
        label="Return to dashboard"
        href={compilePath(AppRoute.HOME)}
        activeBackgroundColor={BUTTON_ORANGE}
        activeColor={WHITE}
        style={{ margin: '0 10px' }}
      />
    </NotFoundPageContentWrapper>
  );
};
