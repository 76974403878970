import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchSentimentState } from 'reduxStore/project/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import {
  selectCurrentProjectId,
  selectIsSentimentAnalysisEnabled,
} from 'reduxStore/project/selectors';

export const useFetchSentimentState = () => {
  const dispatch = useAppDispatch();
  const currentProjectId = useSelector(selectCurrentProjectId);
  const isSentimentAnalysisEnabled = useSelector(selectIsSentimentAnalysisEnabled);

  useEffect(() => {
    if (currentProjectId && isSentimentAnalysisEnabled) {
      dispatch(fetchSentimentState());
    }
  }, [currentProjectId, isSentimentAnalysisEnabled, dispatch]);
};
