import { AppState } from 'reduxStore/appStore';
import { PermissionType } from 'register/PermissionType';
import { Permission } from 'model/Permission';
import { UserRole } from 'register/UserRole';
import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { createSelector } from '@reduxjs/toolkit';

import { STORE_NAME } from './initialState';

export const selectDomain = (state: AppState) => state[STORE_NAME];

export const selectCurrentUser = createSelector(selectDomain, (domain) => domain?.data);

export const selectRole = createSelector(selectCurrentUser, (user) => user?.role);

export const selectIsAdmin = createSelector(
  selectCurrentUser,
  (user) => user?.role === UserRole.Admin
);

export const selectIsClientAdmin = createSelector(
  selectCurrentUser,
  (user) => user?.role === UserRole.ClientAdmin
);

export const selectIsCircleAdmin = createSelector(
  selectCurrentUser,
  (user) => user?.role === UserRole.CircleAdmin
);

export const selectHasMatrixAccess = createSelector(selectCurrentUser, (user) =>
  Boolean(user?.permissions?.find((it) => it === PermissionType.MatrixAccess))
);

export const selectUserId = createSelector(selectCurrentUser, (user) => user?.id);

export const selectUserFirstName = createSelector(selectCurrentUser, (user) => user?.firstName);

export const selectUserLastName = createSelector(selectCurrentUser, (user) => user?.lastName);

export const selectUserEmail = createSelector(selectCurrentUser, (user) => user?.email);

export const selectShortenUserFullName = createSelector(
  selectUserFirstName,
  selectUserLastName,
  (firstName, lastName) => firstName && `${firstName} ${lastName ? lastName[0] + '.' : ''}`
);

export const selectUserNameToDisplay = createSelector(
  selectShortenUserFullName,
  selectUserEmail,
  (userFullName, userEmail) => (userFullName ? userFullName : userEmail)
);

export const selectPermissions = createSelector(selectCurrentUser, (user) => user?.permissions);

export const selectHasResponseRatePermission = createSelector(selectCurrentUser, (user) =>
  Boolean(user && Permission.isGranted(user, PermissionType.ResponseRate))
);

const selectHasRestrictedCrossFiltering = createSelector(selectPermissions, (permissions = []) => {
  // Do not include admin logic hare. This "permission" works like restriction,
  // Only add this to users that have this field.
  return permissions?.includes(PermissionType.RestrictedCrossFiltering);
});

export const selectHasUnrestrictedCrossFilteringPermission = createSelector(
  selectHasRestrictedCrossFiltering,
  (restricted) => !restricted
);

export const selectHasReportPagePermission = createSelector(
  selectIsAdmin,
  selectIsLifecycleProjectKind,
  (isAdmin, isLifecycleKind) => Boolean(isAdmin && !isLifecycleKind)
);

export const selectHasCommentPagePermission = createSelector(selectCurrentUser, (user) =>
  Boolean(user && Permission.isGranted(user, PermissionType.Comment))
);

export const selectHasImpactOnEngagementPermission = createSelector(selectCurrentUser, (user) =>
  Boolean(user && Permission.isGranted(user, PermissionType.ImpactOnEngagement))
);

export const selectHasCommentsAnalysisPermission = createSelector(selectCurrentUser, (user) =>
  Boolean(user && Permission.isGranted(user, PermissionType.SentimentAnalysisComments))
);

export const selectHasTopicsAnalysisPermission = createSelector(selectCurrentUser, (user) =>
  Boolean(user && Permission.isGranted(user, PermissionType.SentimentAnalysisTopics))
);

export const selectHasSummaryPermission = createSelector(
  selectHasReportPagePermission,
  selectHasResponseRatePermission,
  (hasReportPermission, hasResponseRatePermission) =>
    hasReportPermission || hasResponseRatePermission
);
