import React, { useState } from 'react';

import { Demography } from 'model/Demography';
import {
  ArrowIconDown,
  ArrowIconRight,
  DemographyChildrenWrapper,
  DemographyItem,
  DemographyItemTitle,
} from 'component/DemographyFilter/DemographyFilter.s';
import { DemographyFilterChild } from 'component/DemographyFilter/DemographyFilterChild';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { IRON } from 'app/theme/colors';
import { OverflowTooltip } from 'component/OverflowTooltip/OverflowTooltip';
import { TooltipProps } from '@mui/material/Tooltip';

export type DemographyFilterLabelProps = {
  item: Demography;
  disabled?: boolean;
};

export const DemographyFilterLabel: React.FC<DemographyFilterLabelProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const titleNode = <>{props.item.title}</>;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const desktopTooltipProps: {
    placement: TooltipProps['placement'];
    slotProps: TooltipProps['slotProps'];
  } = {
    placement: 'right',
    slotProps: {
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      },
    },
  };
  const mobileTooltipProps: { placement: TooltipProps['placement'] } = {
    placement: 'top',
  };

  return (
    <Box
      sx={{ borderBottom: `1px solid ${IRON}`, marginBottom: 2, paddingBottom: 2 }}
      data-testid={`demographicFilterExpanded[${expanded}]`}
    >
      <DemographyItem onClick={() => setExpanded(!expanded)}>
        <DemographyItemTitle>
          <OverflowTooltip
            id={`demography${props.item.id}`}
            title={titleNode}
            arrow
            {...(isMobile ? mobileTooltipProps : desktopTooltipProps)}
            enterDelay={0}
            leaveDelay={0}
          >
            {titleNode}
          </OverflowTooltip>
        </DemographyItemTitle>
        {expanded ? <ArrowIconDown /> : <ArrowIconRight />}
      </DemographyItem>
      <DemographyChildrenWrapper expanded={expanded}>
        {props.item.classifications &&
          props.item.classifications.map((classification) => (
            <DemographyFilterChild
              key={classification.id}
              classification={classification}
              disabled={props.disabled}
            />
          ))}
      </DemographyChildrenWrapper>
    </Box>
  );
};
