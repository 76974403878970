import { AppRoute } from 'app/route/app';
import { SelectProjectPage } from 'view/SelectProjectPage/SelectProjectPage';
import { ReportRoute } from 'app/route/report';
import { ProjectRoutes } from 'router/ProjectRoutes/ProjectRoutes';
import { ReportRoutes } from 'router/ReportRoutes/ReportRoutes';

import { NavigationItem } from './models';

export const useAppRoutes = (): NavigationItem[] => {
  return [
    {
      path: ReportRoute.REPORT_PATTERN,
      component: ReportRoutes,
      canActivate: true,
    },
    {
      path: AppRoute.PROJECT_PATTERN,
      component: ProjectRoutes,
      canActivate: true,
    },
    {
      path: AppRoute.HOME,
      component: SelectProjectPage,
      canActivate: true,
    },
  ];
};
