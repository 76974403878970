import { useUnmount } from 'react-use';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchCommentsQuestions } from 'reduxStore/comments/asyncActions';
import { clearComments } from 'reduxStore/comments/slice';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectCurrentProject } from 'reduxStore/project/selectors';

export const useFetchCommentsQuestions = () => {
  const dispatch = useAppDispatch();
  const { id: projectId } = useSelector(selectCurrentProject);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchCommentsQuestions());
    }
  }, [projectId, dispatch]);

  useUnmount(() => {
    dispatch(clearComments());
  });
};
