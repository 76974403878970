export enum ProjectKind {
  PROJECT_CLIENT_ADVOCACY_SCORE = 'project_client_advocacy_score',
  PROJECT_NET_PROMOTER_SCORE = 'project_net_promoter_score',
  PROJECT_ENGAGEMENT_SCORE = 'project_engagement_score',
  PROJECT_OVERALL_SCORE = 'project_overall_score',

  PULSE_TEMPLATE = 'pulse_template',
  PULSE_PROJECT = 'pulse_project',
  PULSE_ON_DEMAND_PROJECT = 'pulse_on_demand_project',
  PULSE_LIFECYCLE_PROJECT = 'pulse_lifecycle_project',
}

export type RegularProjectKind =
  | ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE
  | ProjectKind.PROJECT_NET_PROMOTER_SCORE
  | ProjectKind.PROJECT_ENGAGEMENT_SCORE
  | ProjectKind.PROJECT_OVERALL_SCORE;

export type PulseTemplateChildProjectKind =
  | ProjectKind.PULSE_PROJECT
  | ProjectKind.PULSE_ON_DEMAND_PROJECT
  | ProjectKind.PULSE_LIFECYCLE_PROJECT;

export const REGULAR_PROJECT_KIND_LIST = Object.freeze([
  ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE,
  ProjectKind.PROJECT_NET_PROMOTER_SCORE,
  ProjectKind.PROJECT_ENGAGEMENT_SCORE,
  ProjectKind.PROJECT_OVERALL_SCORE,
] as const);

export const PULSE_TEMPLATE_CHILD_PROJECT_KIND_LIST = Object.freeze([
  ProjectKind.PULSE_PROJECT,
  ProjectKind.PULSE_ON_DEMAND_PROJECT,
  ProjectKind.PULSE_LIFECYCLE_PROJECT,
] as const);
