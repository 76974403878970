import React from 'react';
import { useSelector } from 'react-redux';

import {
  Classification,
  DemographyChild,
  DemographyFilterChildWrapper,
} from 'component/DemographyFilter/DemographyFilterChild.s';
import { Checkbox } from 'component/Checkbox/Checkbox';
import { DemographyClassification } from 'model/DemographyClassification';
import { selectIsClassificationSelected } from 'reduxStore/demography/selectors';
import { changeFilter } from 'reduxStore/filtering/asyncActions';
import { selectCanApplyFilter } from 'reduxStore/filtering/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { OverflowTooltip } from 'component/OverflowTooltip/OverflowTooltip';
import { useMediaQuery, useTheme } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';

export type DemographyFilterChildPureProps = {
  checked: boolean;
  classification: DemographyClassification;
  disabled: boolean;
  onSelectClassification: (classification: DemographyClassification) => void;
};

export const DemographyFilterChildPure: React.FC<DemographyFilterChildPureProps> = ({
  checked,
  classification,
  disabled,
  onSelectClassification,
}) => {
  const titleNode = <>{classification.title}</>;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const desktopTooltipProps: {
    placement: TooltipProps['placement'];
    slotProps: TooltipProps['slotProps'];
  } = {
    placement: 'right',
    slotProps: {
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 25],
            },
          },
        ],
      },
    },
  };
  const mobileTooltipProps: { placement: TooltipProps['placement'] } = {
    placement: 'top',
  };

  return (
    <DemographyFilterChildWrapper>
      <DemographyChild data-cy={`isChecked[${checked}]`} data-testid={classification.title}>
        <Classification
          onClick={() => {
            !disabled && onSelectClassification(classification);
          }}
        >
          <OverflowTooltip
            id={`classification${classification.id}`}
            title={titleNode}
            {...(isMobile ? mobileTooltipProps : desktopTooltipProps)}
            arrow
            enterDelay={0}
            leaveDelay={0}
          >
            {titleNode}
          </OverflowTooltip>
        </Classification>
        <Checkbox
          variant="LIGHT"
          checked={checked}
          disabled={checked ? false : disabled}
          onToggle={() => {
            onSelectClassification(classification);
          }}
        />
      </DemographyChild>
    </DemographyFilterChildWrapper>
  );
};

export type DemographyFilterChildProps = {
  classification: DemographyClassification;
  disabled?: boolean;
};

export const DemographyFilterChild: React.FC<DemographyFilterChildProps> = ({
  classification,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const checked = useSelector(selectIsClassificationSelected(classification));
  const canApplyFilter = useSelector(
    selectCanApplyFilter({
      type: 'DEMOGRAPHY',
      classification: classification,
    })
  );

  const onSelectClassification = (classification: DemographyClassification) => {
    dispatch(
      changeFilter({
        type: 'DEMOGRAPHY',
        classification,
      })
    );
  };

  return (
    <DemographyFilterChildPure
      checked={checked}
      disabled={disabled || !canApplyFilter}
      onSelectClassification={onSelectClassification}
      classification={classification}
    />
  );
};
