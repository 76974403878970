import React from 'react';

import { TooltipProps as ChartTooltipProps } from 'recharts';
import { ChartTooltipWrapper } from 'component/LifecycleCharts/LifecycleCharts.s';

export const RecommendChartTooltip: React.FC<ChartTooltipProps<number, string>> = ({
  active,
  payload,
}) => {
  if (active && payload?.length) {
    const { value, date } = payload[0].payload;

    return (
      <ChartTooltipWrapper date-testid="recommendChartTooltip">
        <span>{`${date}, ${value}%`}</span>
      </ChartTooltipWrapper>
    );
  }

  return null;
};
