import React from 'react';
import { useSelector } from 'react-redux';

import { ReportSummaryWidgets } from 'view/ReportView/SummaryWidgetReport/ReportSummaryWidgets';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { selectSimpleProjectCombineQuestions } from 'reduxStore/simpleProject/selectors';
import { QuestionTable } from 'view/EngagementDriverByCategoryPage/QuestionTable/QuestionTable';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';

export type OnDemandReportCombinePureProps = {
  questions: OpinionQuestionDetails.Combine[];
  isAdmin: boolean;
};

export const OnDemandReportCombinePure: React.FC<OnDemandReportCombinePureProps> = (props) => {
  return (
    <div>
      <ReportContent title="Summary" component={<ReportSummaryWidgets isReportView hideScore />} />
      <ReportContent
        title="Survey Questions"
        component={
          <QuestionTable
            data={props.questions}
            width={'max-content'}
            isReportView
            shouldRenderCategory={false}
          />
        }
      />
    </div>
  );
};

export const OnDemandReportCombine: React.FC = () => {
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const isAdmin = useSelector(selectIsAdmin);

  return <OnDemandReportCombinePure questions={questions} isAdmin={isAdmin} />;
};
