import React from 'react';

import { Typography } from '@mui/material';

export type PageTitleProps = {
  title: string;
  'data-testid'?: string;
};

export const PageTitle: React.FC<PageTitleProps> = ({ title, 'data-testid': dataTestId }) => {
  return (
    <Typography variant="h3" data-testid={dataTestId}>
      {title}
    </Typography>
  );
};
