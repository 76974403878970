import Loading from 'react-loading';

import { styled } from '@mui/material';

type PageWrapperHeaderProps = {
  isTitleVisible?: boolean;
};

export const PageWrapperHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isTitleVisible',
})(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '32px',
    minHeight: '107px',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '50px',
      flexDirection: 'column',
      gap: '16px',
      alignItems: 'flex-start',
    },
  }),
  ({ isTitleVisible = true }: PageWrapperHeaderProps) => ({
    justifyContent: isTitleVisible ? 'space-between' : 'flex-end',
  })
);

export const LoadingIcon = styled(Loading)({
  marginRight: 5,
});
