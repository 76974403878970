import { useEffect } from 'react';

import { clearSummary } from 'reduxStore/summary/slice';
import { useAppDispatch } from 'reduxStore/appStore';

export const useClearSummaryOnUnmount = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearSummary());
    };
  }, [dispatch]);
};
