import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectInsightsLifecycleCompared,
  selectInsightsLifecycleComparedNumberOfRespondents,
  selectInsightsLifecycleComparedResource,
} from 'reduxStore/insights/selectors';
import { InsightsHeatmapLegend } from 'component/HeatmapLegend/HeatMapLegend';
import { PeriodSelectionSlider } from 'component/PeriodSelectionSlider/PeriodSelectionSlider';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import { fetchInsightsLifecycleCompared } from 'reduxStore/insights/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { InsightsComparedQuestionMatrix } from 'view/InsightsPage/compared/InsightsComparedQuestionMatrix';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import { NoResultsMessage } from 'component/NoResultsMessage/NoResultsMessage';

export const InsightsLifecycleComparedPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const insightsLifecycleCompareResource = useSelector(selectInsightsLifecycleComparedResource);
  const insightsLifecycleCompare = useSelector(selectInsightsLifecycleCompared);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const insightsLifecycleCompareEmployeesNumber = useSelector(
    selectInsightsLifecycleComparedNumberOfRespondents
  );

  useFetchInsights(fetchInsightsLifecycleCompared);

  const handleChangeCommitted = () => {
    dispatch(fetchInsightsLifecycleCompared());
  };

  return (
    <ResourceLoader
      resource={insightsLifecycleCompareResource}
      rejected={(error) => (
        <>
          <PeriodSelectionSlider onChangeCommitted={handleChangeCommitted} />
          <ErrorHandler error={error} />
        </>
      )}
    >
      <>
        <PeriodSelectionSlider onChangeCommitted={handleChangeCommitted} />

        {insightsLifecycleCompare?.insights.length === 0 && (
          <NoResultsMessage
            title="For this pulse survey, we asked no insights questions for the group you are viewing feedback for."
            hideIcon
          />
        )}

        {insightsLifecycleCompare && insightsLifecycleCompare.insights.length > 0 && (
          <>
            <Typography variant="h4" sx={{ mb: '32px' }}>
              Based on feedback from {insightsLifecycleCompareEmployeesNumber} employees:
            </Typography>
            <Box mb={3}>
              <InsightsHeatmapLegend />
            </Box>
            {insightsLifecycleCompare.insights.map((item, index) => (
              <InsightsComparedQuestionMatrix
                key={index}
                item={item}
                groups={insightsLifecycleCompare.groups}
                minResponses={minResponses}
              />
            ))}
          </>
        )}
      </>
    </ResourceLoader>
  );
};
