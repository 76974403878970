import { styled } from '@mui/material';
import { typography } from 'app/theme/typography';

export const HeadRow = styled('tr')({
  ...typography.h6,

  '@media print': {
    pageBreakAfter: 'auto',
    pageBreakBefore: 'auto',
    pageBreakInside: 'auto',
  },
});

export type HeaderCellProps = {
  textLength?: number;
  isReportView?: boolean;
};

export const HeaderCell = styled('th', {
  shouldForwardProp: (prop) => prop !== 'isReportView',
})(
  {
    position: 'relative',
    verticalAlign: 'middle',
    textAlign: 'center',
    maxWidth: 130,
    fontWeight: 800,
  },
  (props: HeaderCellProps) => ({
    fontSize: props.textLength && props.textLength > 18 ? 12 : 'inherit',
  })
);

export const HeaderCellContent = styled('div')({
  display: 'flex',
  alignItems: 'center',

  '@media print': {
    fontSize: '.9rem',
  },
});
