import React from 'react';

import { Box, Chip } from '@mui/material';
import { AnswerSentiment, Sentiment } from 'model/Sentiment';
import { SENTIMENT_BACKGROUND_DEFAULT } from 'app/theme/colors';

const marginRight = '15px';

export namespace SentimentLabels {
  export type OwnProps = {
    data: AnswerSentiment;
  };
  export type Props = OwnProps;
}

export const SentimentLabels: React.FC<SentimentLabels.Props> = (props) => {
  return (
    <Box data-testid="sentimentLabels">
      {Sentiment.isSentimentSet(props.data.value) && (
        <Chip
          data-testid="sentimentLabelType"
          key="sentiment"
          label={Sentiment.toString(props.data.value)}
          style={{ backgroundColor: Sentiment.getLabelColor(props.data.value), marginRight }}
        />
      )}
      {props.data.topics.map((item, index) => (
        <Chip
          data-testid="sentimentLabelTopic"
          key={`topic-${index}`}
          label={item}
          style={{ backgroundColor: SENTIMENT_BACKGROUND_DEFAULT, marginRight }}
        />
      ))}
    </Box>
  );
};
