import { DemographyApi } from 'api/DemographyApi';
import { DemographyClassification } from 'model/DemographyClassification';

export class DemographyClassificationMapper {
  deserialize(
    entry: DemographyApi.ClassificationEntry,
    demographyId: number
  ): DemographyClassification {
    return {
      id: entry.id,
      ref: entry.ref,
      title: entry.title,
      demographyId: demographyId,
      checked: false,
    };
  }
}
