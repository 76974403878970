import { Benchmark } from 'model/Benchmark';
import { BenchmarkApi } from 'api/BenchmarkApi';

export namespace BenchmarkMapper {
  export const deserialize = (
    benchmarkEntry?: BenchmarkApi.Entry | null
  ): Benchmark | undefined => {
    return (
      benchmarkEntry && {
        label: benchmarkEntry.label,
        type: benchmarkEntry.type,
        value: benchmarkEntry.value,
      }
    );
  };
}
