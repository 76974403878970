import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Table, TableBody, Typography } from '@mui/material';
import { useFetchRespondentIndividualFeedback } from 'shared/hooks/useFetchRespondentIndividualFeedback';
import { selectRespondentIndividualFeedback } from 'reduxStore/respondent/selectors';
import { selectCurrentProjectName } from 'reduxStore/project/selectors';

import {
  FullHeightTable,
  StyledList,
  StyledListItem,
  StyledTableContainer,
  StyledTableRow,
  TableCellWithBorder,
  WithPageBreak,
} from './IndividualFeedbackReportView.s';

export const IndividualFeedbackReportView: React.FC = () => {
  const [individualFeedback] = useSelector(selectRespondentIndividualFeedback);
  const currentProjectName = useSelector(selectCurrentProjectName);

  useFetchRespondentIndividualFeedback();

  if (!currentProjectName || !individualFeedback) {
    return null;
  }

  return (
    <Box>
      <WithPageBreak>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h3">{currentProjectName}</Typography>
          <Typography variant="h4" mb={4}>
            Individual Feedback Report
          </Typography>
        </Box>
        <StyledTableContainer>
          <Table data-testid="individualFeedbackUserTable">
            <TableBody>
              <StyledTableRow>
                <TableCellWithBorder>First name</TableCellWithBorder>
                <TableCellWithBorder>{individualFeedback.firstName}</TableCellWithBorder>
              </StyledTableRow>
              <StyledTableRow>
                <TableCellWithBorder>Last name</TableCellWithBorder>
                <TableCellWithBorder>{individualFeedback.lastName}</TableCellWithBorder>
              </StyledTableRow>
              <StyledTableRow>
                <TableCellWithBorder>Email</TableCellWithBorder>
                <TableCellWithBorder>{individualFeedback.email}</TableCellWithBorder>
              </StyledTableRow>

              {/* Demographics */}
              {individualFeedback.demographics.map((demographic) => (
                <StyledTableRow key={demographic.name}>
                  <TableCellWithBorder>{demographic.name}</TableCellWithBorder>
                  <TableCellWithBorder>{demographic.value}</TableCellWithBorder>
                </StyledTableRow>
              ))}

              <StyledTableRow>
                <TableCellWithBorder>Date survey completed</TableCellWithBorder>
                <TableCellWithBorder>
                  {individualFeedback.dateSurveyCompleted.format('DD/MM/YYYY')}
                </TableCellWithBorder>
              </StyledTableRow>
              <StyledTableRow>
                <TableCellWithBorder>Line manager</TableCellWithBorder>
                <TableCellWithBorder>{individualFeedback.lineManager}</TableCellWithBorder>
              </StyledTableRow>

              {/* Departments by level */}
              {individualFeedback.departments.map((department, index) => (
                <StyledTableRow key={index}>
                  <TableCellWithBorder>Department level {index + 1}</TableCellWithBorder>
                  <TableCellWithBorder>{department}</TableCellWithBorder>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </WithPageBreak>

      <WithPageBreak>
        <StyledTableContainer>
          <Table data-testid="individualFeedbackAnswersTable">
            <TableBody>
              <StyledTableRow>
                <TableCellWithBorder>Advocacy</TableCellWithBorder>
                <TableCellWithBorder>{individualFeedback.advocacy}</TableCellWithBorder>
              </StyledTableRow>
              {individualFeedback.report.map(({ question, answers }) => {
                // TODO: remove answers filtering once the https://buzzing.atlassian.net/browse/WB3-1407 is done
                const filteredAnswers = answers.filter((answers) => answers);
                return (
                  <StyledTableRow key={question}>
                    <TableCellWithBorder>{question}</TableCellWithBorder>
                    <TableCellWithBorder>
                      {filteredAnswers.length === 1 && filteredAnswers[0]}
                      {filteredAnswers.length > 1 && (
                        <StyledList>
                          {filteredAnswers.map((answer, index) => (
                            <StyledListItem key={index}>{answer}</StyledListItem>
                          ))}
                        </StyledList>
                      )}
                    </TableCellWithBorder>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </WithPageBreak>

      <WithPageBreak>
        <StyledTableContainer>
          <FullHeightTable>
            <TableBody data-testid="individualFeedbackNotesTable">
              <StyledTableRow sx={{ height: 0 }}>
                <TableCellWithBorder sx={{ textAlign: 'center' }}>
                  Internal Notes:
                </TableCellWithBorder>
              </StyledTableRow>
              <StyledTableRow>
                <TableCellWithBorder></TableCellWithBorder>
              </StyledTableRow>
            </TableBody>
          </FullHeightTable>
        </StyledTableContainer>
      </WithPageBreak>
    </Box>
  );
};
