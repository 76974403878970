import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { BarChartModel } from 'model/BarChart';
import { PieChart as PieChartModel } from 'model/PieChart';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { ReportSummaryWidgets } from 'view/ReportView/SummaryWidgetReport/ReportSummaryWidgets';
import { CasChartsWrapper } from 'view/ReportView/FeedbackReport/Cas/CasReport.s';
import { PieChart } from 'component/PieChart/PieChart';
import { BarChart } from 'component/BarChart/BarChart';
import {
  selectSimpleProjectCombineBarChart,
  selectSimpleProjectCombinePieChart,
  selectSimpleProjectCombineQuestionContent,
  selectSimpleProjectCombineQuestions,
  selectSimpleProjectCombineResource,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { ReportType } from 'model/ReportType';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';
import { selectInsightsCombinedResource } from 'reduxStore/insights/selectors';
import { selectHasInsightQuestion } from 'reduxStore/project/selectors';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { Insight } from 'model/Insight';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';

import { InsightsWrapper } from '../AllFeedbackReport.s';

export type AllCasReportPureProps = {
  questions: OpinionQuestionDetails.Combine[];
  isAdmin: boolean;
  barChart: BarChartModel[];
  pieChart: PieChartModel[];
  casQuestion: string;
  insightsCombineResource: Resource<Insight.Combine>;
  hasInsightsQuestion: boolean;
  simpleProjectCombineResource: Resource<any>;
  simpleProjectCompareResource: Resource<any>;
  isCompareMode: Boolean;
};

export const AllCasReportPure: React.FC<AllCasReportPureProps> = (props) => {
  const { isCompareMode, simpleProjectCompareResource, simpleProjectCombineResource } = props;
  const simpleProjectResource = isCompareMode
    ? simpleProjectCompareResource
    : simpleProjectCombineResource;

  return (
    <ResourceLoader resource={simpleProjectResource}>
      <div>
        <ReportContent title="Summary" component={<ReportSummaryWidgets isReportView />} />
        <ReportContent
          title={props.casQuestion}
          component={
            <CasChartsWrapper>
              <PieChart data={props.pieChart} />
              <BarChart data={props.barChart} />
            </CasChartsWrapper>
          }
        />
        <ReportContent
          title="Survey Questions"
          component={
            <SortableQuestionTable
              data={props.questions}
              width="max-content"
              isReportView
              shouldRenderCategory={false}
            />
          }
        />
      </div>

      {props.hasInsightsQuestion && (
        <ResourceLoader resource={props.insightsCombineResource}>
          <InsightsWrapper>
            <ReportContent
              title="Insights"
              reportType={ReportType.Insights}
              component={
                <>
                  {props.insightsCombineResource.data?.data.map((item, index) => (
                    <InsightsCombinedQuestion key={index} item={item} isReportView />
                  ))}
                </>
              }
            />
          </InsightsWrapper>
        </ResourceLoader>
      )}
    </ResourceLoader>
  );
};

export const AllCasReport: React.FC = () => {
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const casQuestion = useSelector(selectSimpleProjectCombineQuestionContent);
  const isAdmin = useSelector(selectIsAdmin);
  const pieChart = useSelector(selectSimpleProjectCombinePieChart);
  const barChart = useSelector(selectSimpleProjectCombineBarChart);
  const insightsCombineResource = useSelector(selectInsightsCombinedResource);
  const hasInsightsQuestion = useSelector(selectHasInsightQuestion);
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);
  const isCompareMode = useSelector(selectIsCompareMode);

  return (
    <AllCasReportPure
      questions={questions}
      casQuestion={casQuestion!}
      isAdmin={isAdmin}
      pieChart={pieChart}
      barChart={barChart}
      insightsCombineResource={insightsCombineResource}
      hasInsightsQuestion={hasInsightsQuestion}
      simpleProjectCombineResource={simpleProjectCombineResource}
      simpleProjectCompareResource={simpleProjectCompareResource}
      isCompareMode={isCompareMode}
    />
  );
};
