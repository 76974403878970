import React from 'react';
import { useSelector } from 'react-redux';

import { isArrayEmpty } from 'util/isEmpty';

import {
  PulseSurveyBars,
  PulseSurveyBarsProps,
} from 'component/PulseSurveyBarChart/PulseSurveyBar';
import { scaleBand, ScaleBand, scaleLinear, ScaleLinear, scalePoint, ScalePoint } from 'd3-scale';
import { BarChartWrapper } from 'component/PulseSurveyBarChart/PulseSurveyBarChart.s';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { Axes } from 'component/BarChart/Axes';
import { BenchmarkLine } from 'component/PulseSurveyBarChart/BenchmarkLine';
import { ProjectSummary } from 'model/ProjectSummary';
import { Benchmark } from 'model/Benchmark';
import {
  selectAggregatedBenchmark,
  selectPulseSummaryResource,
} from 'reduxStore/summary/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { BenchmarkLabel } from 'component/PulseSurveyBarChart/BenchmarkLabel';
import { ProjectStatus } from 'model/ProjectStatus';

export namespace PulseSurveyBarChart {
  export type Margin = {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  export type Props = {
    data: PulseSurveyBarsProps['data'];
    benchmark: Benchmark;
    projectSummariesForBenchmark: ProjectSummary[] | undefined;
    height?: number;
    width?: number;
  };
}

const PulseSurveyBarChartPure: React.FC<PulseSurveyBarChart.Props> = ({
  benchmark,
  data,
  height = 350,
  projectSummariesForBenchmark,
  width: propsWidth,
}) => {
  const nonEmptyScoreData = data.filter(({ score }) => score !== 0);
  const projectsInDevelopment = nonEmptyScoreData.filter(
    ({ status }) => status === ProjectStatus.IN_DEVELOPMENT
  );
  const modifiedData = [
    ...nonEmptyScoreData.filter(({ status }) =>
      [ProjectStatus.LIVE, ProjectStatus.CLOSE].includes(status)
    ),
    ...projectsInDevelopment.slice(0, 3),
  ];

  const BAR_WIDTH = 75;
  const BAR_MARGIN = 15;

  const margins = { top: 10, right: 20, bottom: 80, left: 45 };
  const BARS_X_PADDING = 40;
  const width =
    modifiedData.length < 2
      ? BAR_MARGIN + BAR_WIDTH + 100
      : propsWidth ?? modifiedData.length * (BAR_WIDTH + BAR_MARGIN);

  const xScale: ScaleBand<string> = scaleBand()
    .domain(modifiedData.map((d) => d.date.toString()))
    .range([margins.left + BARS_X_PADDING / 2, width - BARS_X_PADDING / 2])
    .paddingInner(0.3)
    .paddingOuter(0);

  const benchmarkXScale: ScalePoint<string> = scalePoint()
    .domain(modifiedData.map((d) => d.date.toString()))
    .range([margins.left, width]);

  const yScale: ScaleLinear<number, number> = scaleLinear()
    .domain([0, 100])
    .range([height - margins.bottom, margins.top]);

  const showBenchmarkLine =
    !isArrayEmpty(projectSummariesForBenchmark) && Benchmark.isNotNone(benchmark);

  let firstEmptyBenchmarkIndex = null;

  if (
    Array.isArray(projectsInDevelopment) &&
    Object.prototype.toString.call(projectsInDevelopment[0]) === '[object Object]'
  ) {
    firstEmptyBenchmarkIndex = projectsInDevelopment[0].date.toString();
  }

  return (
    <ScrollBar maxHeight={height} maxWidth={'max-content'} overflow="horizontal">
      <BarChartWrapper data-testid="pulseSurveyBarChart">
        <svg width={width} height={height}>
          <Axes
            xScale={xScale}
            yScale={yScale}
            margin={margins}
            height={height}
            width={width}
            isPulseSurveyChart={true}
          />
          {projectSummariesForBenchmark && showBenchmarkLine && (
            <BenchmarkLine
              scales={{ xScale, yScale, benchmarkXScale }}
              margin={margins}
              data={projectSummariesForBenchmark.filter(({ score }) => score !== 0)}
            />
          )}
          <PulseSurveyBars
            data={modifiedData}
            margin={margins}
            scales={{ xScale, yScale }}
            height={height}
          />
          {projectSummariesForBenchmark && showBenchmarkLine && (
            <BenchmarkLabel
              scales={{ xScale, yScale, benchmarkXScale }}
              width={width}
              benchmark={benchmark}
              firstEmptyBenchmarkIndex={firstEmptyBenchmarkIndex}
            />
          )}
        </svg>
      </BarChartWrapper>
    </ScrollBar>
  );
};

export type PulseSurveyBarChartProps = {
  data: PulseSurveyBarsProps['data'];
  height?: number;
  width?: number;
};

export const PulseSurveyBarChart: React.FC<PulseSurveyBarChartProps> = ({
  data,
  height,
  width,
}) => {
  const benchmark = useSelector(selectAggregatedBenchmark);
  const projectSummariesForBenchmark = ProjectSummary.combineSummariesAndBenchmark(data, benchmark);
  const resource = useSelector(selectPulseSummaryResource);

  return (
    <ResourceLoader resource={resource}>
      <PulseSurveyBarChartPure
        data={data}
        benchmark={benchmark}
        projectSummariesForBenchmark={projectSummariesForBenchmark}
        height={height}
        width={width}
      />
    </ResourceLoader>
  );
};
