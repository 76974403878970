import React from 'react';
import { useSelector } from 'react-redux';

import { MultiSelectBoxBase } from 'component/SelectBox/MultiSelectBoxBase';
import { CheckboxOption, LeftAlignedOptionItem } from 'component/SelectBox/SelectBox.s';
import { Sentiment, SentimentEnum } from 'model/Sentiment';
import { selectSentimentFilter } from 'reduxStore/comments/selectors';
import { useMediaQuery, useTheme } from '@mui/material';

export type SentimentFilterValue = SentimentEnum;
export type SentimentFilterOption = Readonly<{
  id: SentimentFilterValue;
  label: string;
}>;

export type SentimentFilterProps = {
  onChange: (values: SentimentFilterValue[]) => void;
  disabled?: boolean;
};

export type SentimentFilterPureProps = {
  selectedFilter: SentimentFilterValue[];
} & SentimentFilterProps;

const mapSentimentToOption = (sentiment: SentimentEnum): SentimentFilterOption => ({
  id: sentiment,
  label: Sentiment.toString(sentiment),
});

const optionList = [SentimentEnum.Negative, SentimentEnum.Neutral, SentimentEnum.Positive].map(
  mapSentimentToOption
);

const renderSelected = (selectedOptions: SentimentFilterOption[]) => (
  <span>
    {selectedOptions.length
      ? selectedOptions.length === 1
        ? selectedOptions[0].label
        : `${selectedOptions.length} selected`
      : 'Any Sentiment'}
  </span>
);

const compareValue = (option1: SentimentFilterOption, option2: SentimentFilterOption) => {
  return option1.id === option2.id;
};

const SentimentFilterPure: React.FC<SentimentFilterPureProps> = (props) => {
  const selectedFilters = optionList.filter((option) => props.selectedFilter.includes(option.id));

  const handleChange = (options: SentimentFilterOption[]) => {
    props.onChange(options.map((option) => option.id));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const width = isMobile ? '100%' : 180;

  return (
    <MultiSelectBoxBase<SentimentFilterOption>
      data-testid="sentimentTypeMultiSelectBox"
      onChange={handleChange}
      selected={selectedFilters}
      renderSelected={renderSelected}
      compareValues={compareValue}
      width={width}
      disabled={!!props.disabled}
    >
      {optionList.map((item) => {
        const isSelected = props.selectedFilter.includes(item.id);

        return (
          <LeftAlignedOptionItem
            key={item.id}
            value={item.id}
            label={item.label}
            data-testid={item.id}
            selected={isSelected}
            data-item={item}
          >
            <CheckboxOption checked={isSelected} variant="LIGHT" />
            {item.label}
          </LeftAlignedOptionItem>
        );
      })}
    </MultiSelectBoxBase>
  );
};

export const SentimentFilter: React.FC<SentimentFilterProps> = (props) => {
  const selectedFilter = useSelector(selectSentimentFilter);

  return <SentimentFilterPure {...props} selectedFilter={selectedFilter} />;
};
