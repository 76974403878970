import { Topic, TopicSummary } from 'model/Topics';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'topics';

export namespace TopicsState {
  export type Domain = Resource<Data | null>;

  export type Data = {
    topics: Topic[];
    summary: TopicSummary;
  };

  export const INITIAL_DOMAIN_TOPICS: Topic[] = [];
  export const INITIAL_DOMAIN_SUMMARY: TopicSummary = {
    score: 0,
    commentCount: 0,
  };
  export const INITIAL_DOMAIN: TopicsState.Domain = Resource.createPendingResource<Data>({
    topics: INITIAL_DOMAIN_TOPICS,
    summary: INITIAL_DOMAIN_SUMMARY,
  });
}

export interface TopicsState {
  topics: TopicsState.Domain;
}
