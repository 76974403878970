import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectEngagementDriversCompareListGroups,
  selectEngagementDriversCompareListGroupsLength,
  selectEngagementDriversQuestions,
} from 'reduxStore/engagementDrivers/selectors';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import { CompareMatrix } from 'component/CompareMatrix/CompareMatrix';
import { CompareGroup } from 'model/CompareGroup';
import { OpinionQuestion } from 'model/OpinionQuestion';
import { mapEngagementDriverByCategoryCompareData } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCompare/mapEngagementDriverByCategoryCompareData';

export type EngagementDriverCompareProps = {
  title?: string;
  minResponses: number;
  groupsLength: number;
  groups: ReadonlyArray<CompareGroup>;
  questions: OpinionQuestion.Compare['questions'];
  isReportView?: boolean;
};

export const EngagementDriverHighFrequencyPulsesComparePure: React.FC<EngagementDriverCompareProps> = ({
  title,
  minResponses,
  groupsLength,
  groups,
  questions,
  isReportView,
}) => {
  const questionsData = mapEngagementDriverByCategoryCompareData({
    groups,
    questions,
    groupsLength,
  });

  return (
    <CompareMatrix
      title={title}
      data-testid="engagementDriverHighFrequencyPulsesCompareMatrix"
      departments={questionsData.groups}
      labels={questionsData.labels}
      values={questionsData.values}
      colorType="color"
      minResponses={minResponses}
      isReportView={isReportView}
    />
  );
};

export const EngagementDriverHighFrequencyPulsesCompare = ({
  isReportView,
}: {
  isReportView?: boolean;
}) => {
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const groupsLength = useSelector(selectEngagementDriversCompareListGroupsLength);
  const groups = useSelector(selectEngagementDriversCompareListGroups);
  const questions = useSelector(selectEngagementDriversQuestions);

  return (
    <EngagementDriverHighFrequencyPulsesComparePure
      minResponses={minResponses}
      groupsLength={groupsLength}
      groups={groups}
      questions={questions}
      isReportView={isReportView}
    />
  );
};
