import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectReportOrCurrentProjectId } from 'util/selector/reportSelector';

import { useAppDispatch } from 'reduxStore/appStore';
import { fetchDepartments } from 'reduxStore/departments/asyncActions';

export const useFetchDepartments = () => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectReportOrCurrentProjectId);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchDepartments());
    }
  }, [projectId, dispatch]);
};
