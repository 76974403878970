import ColorScale from 'color-scales';
import Color from 'color-scales/lib/Color';
import { isNil, isObject } from 'lodash';
import {
  PETITE_ORCHID,
  CHESTNUT_ROSE,
  FLUSH_MAHOGANY,
  SIDECAR,
  GOLD_SAND,
  DI_SERRIA,
  SENTIMENT_BACKGROUND_DEFAULT,
  SENTIMENT_BACKGROUND_NEGATIVE,
  SENTIMENT_BACKGROUND_NEUTRAL,
  SENTIMENT_BACKGROUND_POSITIVE,
  EDGEWATER,
  SHADOW_GREEN,
  VIRIDIAN,
} from 'app/theme/colors';

export enum SentimentEnum {
  Positive = 1,
  Neutral = 0,
  Negative = -1,
}

type UnknownSentiment = null;

export type AnswerSentiment = {
  value: SentimentEnum | UnknownSentiment;
  topics: string[];
};

export enum SentimentState {
  Loaded = 'LOADED',
  Loading = 'LOADING',
  Off = 'OFF',
}

export type SentimentStats = Readonly<{
  negative: number; // number between 0 and 100;
  neutral: number; // number between 0 and 100;
  positive: number; // number between 0 and 100;
}>;

type SentimentValue = number; // number between -1 and 1;
type Config = {
  minValue: SentimentValue;
  maxValue: SentimentValue;
  colors: [string, string, string, string, string, string, string, string, string];
};

const SentimentConfig: Config = {
  minValue: -1,
  maxValue: 1,
  colors: [
    FLUSH_MAHOGANY,
    CHESTNUT_ROSE,
    PETITE_ORCHID,
    DI_SERRIA,
    GOLD_SAND,
    SIDECAR,
    EDGEWATER,
    SHADOW_GREEN,
    VIRIDIAN,
  ],
};

const colorScale = new ColorScale(
  SentimentConfig.minValue,
  SentimentConfig.maxValue,
  SentimentConfig.colors
);

const getValueColor = (sentiment: SentimentValue): Color => {
  return colorScale.getColor(sentiment);
};

const getScaleColors = () => {
  return Sentiment.config.colors;
};

const isSentimentSet = (value?: SentimentEnum | UnknownSentiment): value is SentimentEnum => {
  return isNil(value)
    ? false
    : [SentimentEnum.Positive, SentimentEnum.Negative, SentimentEnum.Neutral].includes(value);
};

const isSentimentStats = (value: unknown): value is SentimentStats => {
  return isObject(value) && 'negative' in value && 'neutral' in value && 'positive' in value;
};

const toString = (value: SentimentEnum | UnknownSentiment): string => {
  switch (value) {
    case SentimentEnum.Positive:
      return 'Positive';
    case SentimentEnum.Neutral:
      return 'Neutral';
    case SentimentEnum.Negative:
      return 'Negative';
    default:
      return 'Undefined';
  }
};

const getLabelColor = (value: SentimentEnum | UnknownSentiment): string => {
  switch (value) {
    case SentimentEnum.Positive:
      return SENTIMENT_BACKGROUND_POSITIVE;
    case SentimentEnum.Neutral:
      return SENTIMENT_BACKGROUND_NEUTRAL;
    case SentimentEnum.Negative:
      return SENTIMENT_BACKGROUND_NEGATIVE;
    default:
      return SENTIMENT_BACKGROUND_DEFAULT;
  }
};

type Sentiment = {
  config: Config;
  getValueColor: (value: number) => Color;
  getScaleColors: () => Config['colors'];
  getLabelColor: (value: SentimentEnum | UnknownSentiment) => string;
  isSentimentSet: (value?: SentimentEnum | UnknownSentiment) => value is SentimentEnum;
  isSentimentStats: (value: any) => boolean;
  toString: (value: SentimentEnum | UnknownSentiment) => string;
};

export const Sentiment: Sentiment = {
  config: SentimentConfig,
  getValueColor,
  getScaleColors,
  getLabelColor,
  isSentimentSet,
  isSentimentStats,
  toString,
};
