import { styled, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import { DARK_GREY, SONIC_SILVER } from 'app/theme/colors';

export const WithPageBreak = styled('div')({
  '@media print': {
    pageBreakAfter: 'always',
  },
  '&:last-of-type': {
    pageBreakAfter: 'auto',
  },
});

export const StyledTableContainer = styled(TableContainer)({
  padding: 16,
  boxSizing: 'border-box',
});

export const FullHeightTable = styled(Table)({
  minHeight: 'calc(100vh - 32px)',
});

export const StyledTableRow = styled(TableRow)({
  '@media print': {
    pageBreakInside: 'avoid',
  },

  '> td': {
    width: '50%',
    padding: 4,
    border: `1px solid ${DARK_GREY}`,
    verticalAlign: 'top',
  },

  '> td:first-of-type': {
    fontWeight: 'bold',
  },
});

export const StyledList = styled('ul')({
  paddingLeft: '16px',
  fontFamily: 'inherit',
});

export const StyledListItem = styled('li')({
  listStyleType: 'disc',
  fontFamily: 'inherit',
});

export const TableCellWithBorder = styled(TableCell)({
  border: `1px solid ${SONIC_SILVER}`,
});
