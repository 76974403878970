export type RespondentProtection = {
  comment: number;
  closed: number;
  mode: number;
  variableConfidentialityThreshold: number;
};

export enum RespondentProtectionMode {
  CompletedSurveys = 0,
  InvitedEmployees = 1,
}

export enum RespondentProtectionEnum {
  RESPONDENT_PROTECTION = 'RESPONDENT_PROTECTION',
  VARIABLE_CONFIDENTIALITY_PROTECTION = 'VARIABLE_CONFIDENTIALITY_PROTECTION',
}

export type RespondentProtectionEnumNullable = RespondentProtectionEnum | null;
