import React from 'react';

import { ComponentWrapper } from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { Statistics } from 'component/Statistics/Statistics';
import { Benchmark } from 'model/Benchmark';
import { Typography } from '@mui/material';

export namespace BenchmarkWidget {
  export type Props = {
    isUserAdmin: boolean;
    benchmark?: Benchmark;
    noPaddings?: boolean;
    isReportView?: boolean;
  };
}

export const BenchmarkWidget: React.FC<BenchmarkWidget.Props> = (props) => (
  <>
    {props.benchmark && (
      <ComponentWrapper data-testid="benchmarkWidget" noPaddings={props.noPaddings}>
        <Typography variant="h5" data-testid="benchmarkWidgetLabel" mb="40px">
          {props.benchmark.label}
        </Typography>
        <Statistics
          score={props.benchmark.value}
          position="vertical"
          arrowSize={45}
          fontSize={35}
          arrowBottomMargin={10}
          isReportView={props.isReportView}
        />
      </ComponentWrapper>
    )}
  </>
);
