import { styled } from '@mui/material';
import { IRON, WHITE } from 'app/theme/colors';

export const FeedbackWrapper = styled('div')({
  background: WHITE,
  borderRadius: '10px',
  padding: '24px',
});

export const TableWrapper = styled('div')({
  border: `1px solid ${IRON}`,
  borderRadius: '8px',
  marginTop: '24px',
  overflow: 'hidden',
});

export const ChartWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
});
