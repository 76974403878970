import React, { ChangeEventHandler, FormEventHandler } from 'react';
import { useUnmount } from 'react-use';

import { findMatchDepartment } from 'util/tree';

import {
  SearchButtonInput,
  SearchIcon,
  SearchIconWrapper,
  SearchInputWrapper,
} from 'component/SearchDepartmentsInput/SearchDepartmentsInput.s';
import { search } from 'reduxStore/search/slice';
import { changeFilter } from 'reduxStore/filtering/asyncActions';
import { Department } from 'model/Department';
import { useAppDispatch } from 'reduxStore/appStore';
import { Box } from '@mui/material';

export type SearchDepartmentsInputPureProps = SearchDepartmentsInputProps & {
  onSearch: (query: string) => void;
  onCheck: (id: number) => void;
};

const SearchDepartmentsInputPure: React.FC<SearchDepartmentsInputPureProps> = ({
  departments,
  onSearch,
  onCheck,
}) => {
  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    if (departments.length === 1) {
      const department = findMatchDepartment(departments[0]);
      onCheck(department.id);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onSearch(event.target.value);
  };

  return (
    <SearchInputWrapper data-testid="searchInputWrapper">
      <Box component="form" id="departmentSearchForm" onSubmit={onSubmit} width="100%">
        <SearchButtonInput type="text" placeholder="Search Hierarchy..." onChange={handleChange} />
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </Box>
    </SearchInputWrapper>
  );
};

export type SearchDepartmentsInputProps = {
  departments: Department[];
};

export const SearchDepartmentsInput: React.FC<SearchDepartmentsInputProps> = ({ departments }) => {
  const dispatch = useAppDispatch();

  const onSearch = (query: string) => dispatch(search(query));

  const onCheck = (id: number) => dispatch(changeFilter({ type: 'DEPARTMENT', departmentId: id }));

  useUnmount(() => onSearch(''));

  return (
    <SearchDepartmentsInputPure onSearch={onSearch} onCheck={onCheck} departments={departments} />
  );
};
