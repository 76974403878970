import { toFileExtension } from 'util/toFileExtension';

import { ReportFormatType } from 'model/ReportFormat';
import { AsyncThunk, AsyncThunkConfig } from '@reduxjs/toolkit';

export const getReportActionNameByReportFormat = (
  asyncThunk: AsyncThunk<void, any, AsyncThunkConfig>,
  arg: { type?: ReportFormatType } = {}
) => `${asyncThunk.typePrefix}${arg.type ? `/${toFileExtension(arg.type)}` : ''}`;

export const getReportActionNameByDepartmentId = (
  asyncThunk: AsyncThunk<void, any, AsyncThunkConfig>,
  arg: { departmentId?: number } = {}
) => `${asyncThunk.typePrefix}${arg.departmentId ? `/departmentId[${arg.departmentId}]` : ''}`;
