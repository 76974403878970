import { Box, styled } from '@mui/material';

export const FeedbackBarWrapper = styled(Box)<{
  width: number;
  height: number;
}>(({ width, height }) => ({
  display: 'flex',
  width: `${width}px`,
  height: `${height}px`,
  borderRadius: '5px',
}));

export const FeedbackBarPart = styled(Box)<{
  value: number;
  backgroundColor: string;
  borderColor: string;
}>(({ value, backgroundColor, borderColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: value,
  flexShrink: 0,
  backgroundColor,
  borderBottom: `3px solid ${borderColor}`,
  '&:first-of-type': {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
}));
