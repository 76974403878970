import React from 'react';
import { useSelector } from 'react-redux';

import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import { downloadIndividualFeedbackReport } from 'reduxStore/report/asyncActions';
import { DocumentType } from 'model/DocumentType';
import { PageWrapperHeader } from 'component/PageHeader/PageHeader.s';
import { PageTitle } from 'component/PageHeaderTitle/PageTitle';
import { DocumentIcon } from 'component/DocumentIcon/DocumentIcon';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';
import { selectGroupedClassifications } from 'reduxStore/demography/selectors';
import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import { getReportActionNameByReportFormat } from 'reduxStore/report/getReportActionName';
import { AdvocacyDtoEnum } from 'api/dto/Advocacy.dto';

export type IndividualFeedbackPageHeaderPureProps = {
  isExcelDownloadPending: boolean;
  onReportDownload: (type: ReportFormatType) => void;
  isDownloadDisabled?: boolean;
};

export const IndividualFeedbackPageHeaderPure: React.FC<IndividualFeedbackPageHeaderPureProps> = ({
  isExcelDownloadPending,
  onReportDownload,
  isDownloadDisabled,
}) => (
  <PageWrapperHeader data-testid="individualFeedbackPageHeader">
    <PageTitle title="Individual Feedback" />
    {!isDownloadDisabled && (
      <Dropdown label="Export Data" data-testid="exportIndividualFeedbackDropdown">
        <DropdownItem onClick={() => onReportDownload(ReportFormat.Excel)}>
          <DocumentIcon type={DocumentType.Excel} isDownloadPending={isExcelDownloadPending} />
          <span data-testid="exportIndividualFeedbackDropdownExcelOption">Excel</span>
        </DropdownItem>
      </Dropdown>
    )}
  </PageWrapperHeader>
);

export type IndividualFeedbackPageHeaderProps = {
  advocacyFilter: AdvocacyDtoEnum[];
  respondentLikeFilter: string;
  isDownloadDisabled?: boolean;
};

export const IndividualFeedbackPageHeader: React.FC<IndividualFeedbackPageHeaderProps> = ({
  advocacyFilter,
  respondentLikeFilter,
  isDownloadDisabled,
}) => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  const departments = useSelector(selectSelectedDepartmentIds);
  const classifications = useSelector(selectGroupedClassifications);
  const isExcelDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadIndividualFeedbackReport, {
        type: ReportFormat.Excel,
      })
    )
  );

  const onReportDownload = (type: ReportFormatType) =>
    dispatch(
      downloadIndividualFeedbackReport({
        type,
        projectId,
        classifications,
        departments,
        advocacyFilter,
        respondentLikeFilter,
      })
    );

  return (
    <IndividualFeedbackPageHeaderPure
      isExcelDownloadPending={isExcelDownloadPending}
      onReportDownload={onReportDownload}
      isDownloadDisabled={isDownloadDisabled}
    />
  );
};
