import React from 'react';
import ToolTip from 'react-tooltip';

import { withDefaultProps } from 'util/withDefaultProps';

import { Pie } from 'component/PieChart/Pie';
import { Legend } from 'component/Legend/Legend';
import { PieChart as PieChartModel } from 'model/PieChart';
import { toolTip } from 'component/BarChart/BarChart.s';
import { Box } from '@mui/material';

export namespace PieChart {
  export type Props = {
    data?: PieChartModel[];
    width: number;
    height: number;
  };
}

export const PieChartPure: React.FC<PieChart.Props> = (props) => {
  const minViewportSize = Math.min(props.width, props.height);
  const radius = (minViewportSize * 0.9) / 2;
  const x = props.width / 2;
  const y = props.height / 2;

  return (
    <Box width={props.width} data-testid="pieChart">
      <ToolTip place="right" type="info" className={`${toolTip}`} html />
      <svg width={props.width} height={props.height}>
        <Pie x={x} y={y} radius={radius} data={props.data ?? []} />
      </svg>
      <Legend data={props.data ?? []} />
    </Box>
  );
};

const defaultProps = {
  width: 500,
  height: 300,
};

export const PieChart = withDefaultProps(defaultProps as PieChart.Props, PieChartPure);
