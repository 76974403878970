import { Department } from 'model/Department';

type DepartmentLevels = Department[][];

export function groupDepartmentsByLevel(
  departments: Department[],
  level: number = 0,
  levels: DepartmentLevels = []
) {
  if (!levels[level]) {
    levels[level] = [];
  }
  levels[level] = levels[level].concat(departments);
  departments.forEach((department) => {
    if (department.children && department.children.length) {
      groupDepartmentsByLevel(department.children, level + 1, levels);
    }
  });

  return levels;
}
