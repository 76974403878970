import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { InsightsComparedReportView } from 'view/ReportView/InsightsReport/compared/InsightsComparedReportView';
import { InsightsLifecycleComparedReportView } from 'view/ReportView/InsightsReport/compared/InsightsLifecycleComparedReportView';
import { InsightsCombinedReportView } from 'view/ReportView/InsightsReport/combined/InsightsCombinedReportView';
import { InsightsLifecycleCombinedReportView } from 'view/ReportView/InsightsReport/combined/InsightsLifecycleCombinedReportView';

export const InsightsReportView: React.FC = () => {
  const isCompareMode = useSelector(selectIsCompareMode);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);

  return (
    <>
      {!isLifecycleProjectKind && !isCompareMode && <InsightsCombinedReportView />}
      {!isLifecycleProjectKind && isCompareMode && <InsightsComparedReportView />}
      {isLifecycleProjectKind && !isCompareMode && <InsightsLifecycleCombinedReportView />}
      {isLifecycleProjectKind && isCompareMode && <InsightsLifecycleComparedReportView />}
    </>
  );
};
