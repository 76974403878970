export type BasicDemographyClassification = Readonly<{
  id: number;
  demographyId: number;
}>;

export namespace DemographyClassification {
  export const hasEqualIds = (
    classificationA: BasicDemographyClassification,
    classificationB: BasicDemographyClassification
  ) =>
    classificationA.demographyId === classificationB.demographyId &&
    classificationA.id === classificationB.id;
}

export type DemographyClassification = BasicDemographyClassification &
  Readonly<{
    ref: string;
    title: string;
    checked: boolean;
  }>;
