import React from 'react';

import { ScaleLinear } from 'd3-scale';
import { Text } from 'component/Text/Text';
import Tooltip from '@mui/material/Tooltip';

export namespace Bar {
  export type Props = {
    cumulative: number;
    value: number;
    xScale: ScaleLinear<number, number>;
    height: number;
    color: string;
    marginRight: number;
    tooltip: string;
    borderColors: string[];
    index: number;
  };
}

const textMargin = 4;

export const Bar: React.FC<Bar.Props> = (props) => {
  const middleOfBar =
    props.xScale(props.cumulative) + (props.xScale(props.value) + props.marginRight) / 2;

  const leftBorderBottomSide = `
      H ${props.xScale(props.cumulative)} 
      V ${props.height - 2} 
  `;

  const rightBorderBottomSide = `
    H ${props.xScale(props.value) + props.marginRight + props.xScale(props.cumulative)} 
    V ${props.height} 
`;

  return (
    <Tooltip
      title={props.tooltip}
      placement="top"
      enterTouchDelay={0}
      arrow
      id={'sentiment-bar-tooltip'}
    >
      <g>
        {props.value > 0 && (
          <rect
            x={props.xScale(props.cumulative)}
            width={props.xScale(props.value) + props.marginRight}
            height={props.height}
            fill={props.color}
            rx="5"
            ry="5"
          />
        )}
        <path
          d={`
          M ${props.xScale(props.cumulative)} ${props.height - 3} 
          ${rightBorderBottomSide}
          ${leftBorderBottomSide}
          Z
        `}
          fill={props.borderColors[props.index]}
        />
        {props.value >= 5 && (
          <Text
            x={middleOfBar}
            y={props.height / 2 + textMargin}
            textAnchor="middle"
            fontSize={12}
            fontWeight={600}
          >
            {`${props.value}%`}
          </Text>
        )}
      </g>
    </Tooltip>
  );
};
