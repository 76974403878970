export enum LoadingStateEnum {
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

export type Resource<TResult = any, TError = any> = {
  data: TResult;
  loadingState: LoadingStateEnum;
  error: TError | null;
};

export namespace Resource {
  export function createPendingResource<TResult, TError = any>(): Resource<TResult | null, TError>;
  // TODO: align createPendingResource and createResolvedResource signature
  export function createPendingResource<TResult, TError = any>(
    data: TResult
  ): Resource<TResult, TError>;

  export function createPendingResource<TResult, TError = any>(
    data?: TResult
  ): Resource<TResult | null, TError> {
    return {
      data: data ?? null,
      loadingState: LoadingStateEnum.PENDING,
      error: null,
    };
  }

  export function createResolvedResource<TResult, TError = any>({
    data,
  }: {
    data: TResult;
  }): Resource<TResult, TError> {
    return { data, loadingState: LoadingStateEnum.RESOLVED, error: null };
  }

  export function createRejectedResource<TResult, TError = any>({
    error,
    data,
  }: {
    error: TError;
    data?: TResult;
  }): Resource<TResult | null, TError> {
    return { data: data ?? null, loadingState: LoadingStateEnum.REJECTED, error };
  }
}
