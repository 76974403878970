import React from 'react';

import { SummaryCombineWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCombineWidgets/SummaryCombineWidgets';
import { EngagementDriversCombine } from 'view/EngagementDriversView/EngagementDriversCombine';
import { ProjectKind } from 'model/ProjectKind';
import { PulseSummaryCombineWidgets } from 'view/SummaryPage/SummaryWidgets/PulseSummaryCombineWidgets/PulseSummaryCombineWidgets';

export namespace EngagementScoreCombinePage {
  export type Props = {
    projectKind: ProjectKind;
  };
}

export const EngagementScoreCombinePage: React.FC<EngagementScoreCombinePage.Props> = (
  props: EngagementScoreCombinePage.Props
) => {
  const selectSummaryView = (kind: ProjectKind) => {
    switch (kind) {
      case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
        return <SummaryCombineWidgets />;
      case ProjectKind.PULSE_PROJECT:
        return <PulseSummaryCombineWidgets />;
      default:
        throw new Error(`Project kind ${kind} is not supported by this component`);
    }
  };

  return (
    <>
      {selectSummaryView(props.projectKind)}

      <EngagementDriversCombine />
    </>
  );
};
