import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode, selectSummaryCategoryViewMode } from 'util/selector/querySelector';

import { ReportType } from 'model/ReportType';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { EngagementDriversCombine } from 'view/EngagementDriversView/EngagementDriversCombine';
import { EngagementDriverByCategoryCombine } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/EngagementDriverByCategoryCombine';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';
import {
  selectEngagementDriversCombineList,
  selectEngagementDriversCombineResource,
  selectEngagementDriversCompareResource,
  selectTrendLabel,
} from 'reduxStore/engagementDrivers/selectors';
import { selectCurrentUser, selectIsAdmin } from 'reduxStore/user/selectors';
import {
  selectCurrentProjectIsHighPulseFrequency,
  selectHasInsightQuestion,
  selectProjectClosedAt,
  selectProjectKind,
} from 'reduxStore/project/selectors';
import { ReportEngagementDriver } from 'model/ReportEngagamentDriver';
import { selectInsightsCombinedResource } from 'reduxStore/insights/selectors';
import { Insight } from 'model/Insight';
import { SummaryReportStrategy } from 'view/ReportView/SummaryWidgetReport/SummaryReportStrategy';
import { ProjectKind } from 'model/ProjectKind';
import { User } from 'model/User';
import { Project } from 'model/Project';
import { Moment } from 'moment';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { Resource } from 'model/Resource';
import { SummaryCategoryViewMode } from 'register/ModeType';

import { InsightsWrapper } from '../AllFeedbackReport.s';

export type AllEngagementScoreReportPureProps = {
  engagementDrivers: ReportEngagementDriver[];
  engagementScoreResource: Resource<any>;
  insightsCombineResource: Resource<Insight.Combine>;
  hasInsightsQuestion: boolean;
  isAdmin: boolean;
  trendLabel: string;
  projectKind: ProjectKind;
  closedAt?: Moment;
  user: User | null;
  isPulseHighFrequency: boolean;
  categoryMode: SummaryCategoryViewMode;
};

export const AllEngagementScoreReportPure: React.FC<AllEngagementScoreReportPureProps> = (
  props
) => (
  <ResourceLoader resource={props.engagementScoreResource}>
    <>
      <div>
        <SummaryReportStrategy kind={props.projectKind} />
        <ReportContent
          title={Project.getEngagementDriversTitle({
            kind: props.projectKind,
            closedAt: props.closedAt,
          })}
          component={<EngagementDriversCombine isReportView />}
        />
        {props.categoryMode === SummaryCategoryViewMode.Category &&
          props.engagementDrivers.length &&
          !props.isPulseHighFrequency && (
            <div>
              {props.engagementDrivers.map((engagementDriver, index) => (
                <ReportContent
                  key={index}
                  title={engagementDriver.category!.name + ' : ' + engagementDriver.score}
                  component={
                    <EngagementDriverByCategoryCombine
                      key={index}
                      categoryTitle={engagementDriver.category!.name}
                      categoryId={engagementDriver.category!.id}
                      score={engagementDriver.score}
                      categoryData={engagementDriver.details}
                      trends={engagementDriver.trends}
                      benchmark={engagementDriver.benchmark}
                      isAdmin={props.isAdmin}
                      trendLabel={props.trendLabel}
                      isReportView
                    />
                  }
                />
              ))}
            </div>
          )}
      </div>

      {props.hasInsightsQuestion && (
        <ResourceLoader resource={props.insightsCombineResource}>
          {props.insightsCombineResource.data?.data.length ? (
            <InsightsWrapper>
              <ReportContent
                title="Insights"
                reportType={ReportType.Insights}
                component={
                  <>
                    {props.insightsCombineResource.data?.data.map((item, index) => (
                      <InsightsCombinedQuestion key={index} item={item} isReportView />
                    ))}
                  </>
                }
              />
            </InsightsWrapper>
          ) : null}
        </ResourceLoader>
      )}
    </>
  </ResourceLoader>
);

export const AllEngagementScoreReport: React.FC = () => {
  const engagementDrivers = useSelector(selectEngagementDriversCombineList);
  const engagementCompareResource = useSelector(selectEngagementDriversCompareResource);
  const engagementCombineResource = useSelector(selectEngagementDriversCombineResource);
  const isCompareMode = useSelector(selectIsCompareMode);
  const engagementScoreResource = isCompareMode
    ? engagementCompareResource
    : engagementCombineResource;
  const insightsCombineResource = useSelector(selectInsightsCombinedResource);
  const hasInsightsQuestion = useSelector(selectHasInsightQuestion);
  const isAdmin = useSelector(selectIsAdmin);
  const trendLabel = useSelector(selectTrendLabel);
  const projectKind = useSelector(selectProjectKind);
  const user = useSelector(selectCurrentUser);
  const closedAt = useSelector(selectProjectClosedAt);
  const isPulseHighFrequency = useSelector(selectCurrentProjectIsHighPulseFrequency);
  const categoryMode = useSelector(selectSummaryCategoryViewMode);

  return (
    <AllEngagementScoreReportPure
      engagementDrivers={engagementDrivers}
      engagementScoreResource={engagementScoreResource}
      insightsCombineResource={insightsCombineResource}
      hasInsightsQuestion={hasInsightsQuestion}
      isAdmin={isAdmin}
      trendLabel={trendLabel}
      projectKind={projectKind}
      user={user}
      closedAt={closedAt}
      isPulseHighFrequency={isPulseHighFrequency}
      categoryMode={categoryMode}
    />
  );
};
