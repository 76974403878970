import React from 'react';
import { useSelector } from 'react-redux';

import { selectReportCategoryId, selectReportProjectName } from 'util/selector/reportSelector';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { selectCompanyLogoUrl } from 'reduxStore/project/selectors';
import {
  selectEngagementDriversByCategoryCombine,
  selectTrendLabel,
} from 'reduxStore/engagementDrivers/selectors';
import { EngagementDriverByCategoryCombine } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/EngagementDriverByCategoryCombine';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { OpinionQuestion } from 'model/OpinionQuestion';

export type CategoryReportCombinePureProps = {
  projectName: string | undefined;
  logoUrl: string;
  engagementDriverByCategoryCombine: OpinionQuestion.Combine | null;
  isAdmin: boolean;
  categoryId: number;
  trendLabel: string;
};

export const CategoryReportCombinePure: React.FC<CategoryReportCombinePureProps> = (props) =>
  props.engagementDriverByCategoryCombine ? (
    <ReportContent
      title={
        props.engagementDriverByCategoryCombine.category!.name +
        ' : ' +
        props.engagementDriverByCategoryCombine.score
      }
      component={
        <EngagementDriverByCategoryCombine
          categoryTitle={props.engagementDriverByCategoryCombine.category!.name}
          categoryId={props.engagementDriverByCategoryCombine.category!.id}
          score={props.engagementDriverByCategoryCombine.score}
          categoryData={props.engagementDriverByCategoryCombine.details}
          trends={props.engagementDriverByCategoryCombine.trends}
          benchmark={props.engagementDriverByCategoryCombine.benchmark}
          isAdmin={props.isAdmin}
          trendLabel={props.trendLabel}
          isReportView
        />
      }
    />
  ) : null;

export const CategoryReportCombine: React.FC = () => {
  const projectName = useSelector(selectReportProjectName);
  const engagementDriverByCategoryCombine = useSelector(selectEngagementDriversByCategoryCombine);
  const logoUrl = useSelector(selectCompanyLogoUrl);
  const isAdmin = useSelector(selectIsAdmin);
  const categoryId = useSelector(selectReportCategoryId);
  const trendLabel = useSelector(selectTrendLabel);

  return (
    <CategoryReportCombinePure
      projectName={projectName}
      engagementDriverByCategoryCombine={engagementDriverByCategoryCombine}
      logoUrl={logoUrl}
      isAdmin={isAdmin}
      categoryId={categoryId}
      trendLabel={trendLabel}
    />
  );
};
