import React from 'react';
import { useSelector } from 'react-redux';

import { PageWrapperHeader } from 'component/PageHeader/PageHeader.s';
import { PageTitle } from 'component/PageHeaderTitle/PageTitle';
import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import {
  downloadAllCommentsExcelReport,
  downloadAllCommentsPdfReport,
  downloadCommentReport,
} from 'reduxStore/report/asyncActions';
import { DocumentType } from 'model/DocumentType';
import { DocumentIcon } from 'component/DocumentIcon/DocumentIcon';
import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { selectSelectedQuestion } from 'reduxStore/comments/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectReportDepartment } from 'reduxStore/departments/selectors';
import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { selectIsVisibleDownloadAllOption } from 'reduxStore/filtering/selectors';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import {
  getReportActionNameByDepartmentId,
  getReportActionNameByReportFormat,
} from 'reduxStore/report/getReportActionName';

export type CommentsHeaderProps = {
  title?: string;
  isDownloadDisabled?: boolean;
};

export const CommentsHeader: React.FC<CommentsHeaderProps> = ({ title, isDownloadDisabled }) => {
  const dispatch = useAppDispatch();

  const reportDepartment = useSelector(selectReportDepartment);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);
  const isVisibleDownloadAllOption = useSelector(selectIsVisibleDownloadAllOption);

  const isExcelDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadCommentReport, { type: ReportFormat.Excel })
    )
  );
  const isPdfDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadCommentReport, { type: ReportFormat.PDF })
    )
  );
  const isAllCommentsExcelReportDownloadPending = useSelector(
    selectIsPendingReport(getReportActionNameByReportFormat(downloadAllCommentsExcelReport))
  );
  const isAllCommentsPdfReportDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByDepartmentId(downloadAllCommentsPdfReport, {
        departmentId: reportDepartment?.id,
      })
    )
  );
  const selectedQuestion = useSelector(selectSelectedQuestion);

  const onReportDownload = (type: ReportFormatType, questionName?: string) =>
    dispatch(downloadCommentReport({ type, questionName }));

  const onAllCommentsExcelReportDownload = () => dispatch(downloadAllCommentsExcelReport());

  const onAllCommentsPdfReportDownload = () =>
    dispatch(
      downloadAllCommentsPdfReport({
        departmentId: reportDepartment?.id,
        departmentName: reportDepartment?.name,
      })
    );

  return (
    <PageWrapperHeader data-testid="commentsHeader">
      <PageTitle title={title || 'Comments'} />
      {!isDownloadDisabled && (
        <Dropdown label="Download Report" data-testid="downloadReportDropdown">
          <DropdownItem
            data-testid="downloadReportExcel"
            onClick={() => onReportDownload(ReportFormat.Excel)}
          >
            <DocumentIcon type={DocumentType.Excel} isDownloadPending={isExcelDownloadPending} />
            {isLifecycleProjectKind ? 'Excel' : 'This Question Only - Excel'}
          </DropdownItem>
          <DropdownItem
            data-testid="downloadReportPDF"
            onClick={() => onReportDownload(ReportFormat.PDF, selectedQuestion?.content)}
          >
            <DocumentIcon type={DocumentType.PDF} isDownloadPending={isPdfDownloadPending} />
            {isLifecycleProjectKind ? 'PDF' : 'This Question Only - PDF'}
          </DropdownItem>
          <DropdownItem
            data-testid="downloadAllCommentsExcelReport"
            onClick={onAllCommentsExcelReportDownload}
          >
            <DocumentIcon
              type={DocumentType.Excel}
              isDownloadPending={isAllCommentsExcelReportDownloadPending}
            />
            All Questions - Excel
          </DropdownItem>
          {isVisibleDownloadAllOption && (
            <DropdownItem
              data-testid="downloadAllCommentsPdfReport"
              onClick={onAllCommentsPdfReportDownload}
            >
              <DocumentIcon
                type={DocumentType.PDF}
                isDownloadPending={isAllCommentsPdfReportDownloadPending}
              />
              All Questions - PDF
            </DropdownItem>
          )}
        </Dropdown>
      )}
    </PageWrapperHeader>
  );
};
