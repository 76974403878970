import { useSelector } from 'react-redux';

import { selectHasMatch } from 'util/selector/routerSelector';
import {
  hasSelectedAnyClassification,
  hasSelectedAnyDepartment,
} from 'util/selector/querySelector';

import { AppRoute } from 'app/route/app';
import {
  resetPaginationPage,
  setAdvocacyFilter,
  setSearchText,
  setSentimentFilter,
  setTopicFilter,
} from 'reduxStore/comments/slice';
import { fetchCommentAnswers } from 'reduxStore/comments/asyncActions';
import { setRespondentsFilters, setRespondentsTablePage } from 'reduxStore/respondent/slice';
import { useAppDispatch } from 'reduxStore/appStore';

export const useGetClearPageFiltersHandler = () => {
  const dispatch = useAppDispatch();
  const isCommentsPage = useSelector(selectHasMatch(AppRoute.COMMENTS));
  const isIndividualFeedbackPage = useSelector(selectHasMatch(AppRoute.INDIVIDUAL_FEEDBACK));

  const hasSelectedDepartment = useSelector(hasSelectedAnyDepartment);
  const hasSelectedClassification = useSelector(hasSelectedAnyClassification);

  return async () => {
    const shouldFetchData = !hasSelectedDepartment && !hasSelectedClassification;
    if (isCommentsPage) {
      dispatch(setSearchText(''));
      dispatch(setAdvocacyFilter([]));
      dispatch(setSentimentFilter([]));
      dispatch(setTopicFilter([]));
      dispatch(resetPaginationPage());
      return shouldFetchData && dispatch(fetchCommentAnswers());
    }
    if (isIndividualFeedbackPage) {
      dispatch(setRespondentsTablePage(1));
      dispatch(setRespondentsFilters({ advocacyFilter: [], respondentLikeFilter: '' }));
    }
  };
};
