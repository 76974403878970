import { groupClassificationsByDemography, GroupedClassifications } from 'util/createFiltersQuery';
import { selectQueryParamObject } from 'util/selector/routerSelector';

import { createSelector } from 'reselect';
import { AppState } from 'reduxStore/appStore';
import {
  BasicDemographyClassification,
  DemographyClassification,
} from 'model/DemographyClassification';

import { STORE_NAME } from './initialState';

const selectDomain = (state: AppState) => state[STORE_NAME];

export const selectDemographyResource = createSelector(selectDomain, (domain) => domain.list);

export const selectDemography = createSelector(
  selectDemographyResource,
  (demographyResource) => demographyResource.data
);

export const selectSelectedClassifications = createSelector(
  selectQueryParamObject('f'),
  (queryParam: any) => {
    if (!queryParam || !queryParam['c']) {
      return [] as BasicDemographyClassification[];
    }

    return Object.keys(queryParam['c']).reduce(
      (classifications: BasicDemographyClassification[], demographyId: string) => [
        ...classifications,
        ...Object.keys(queryParam['c'][demographyId]).map((index) => ({
          id: Number(queryParam['c'][demographyId][index]),
          demographyId: Number(demographyId),
        })),
      ],
      []
    ) as BasicDemographyClassification[];
  }
);

export const selectGroupedClassifications = createSelector(
  selectSelectedClassifications,
  (classifications: any) =>
    (classifications
      ? groupClassificationsByDemography(classifications)
      : []) as GroupedClassifications
);

export const selectIsClassificationSelected = (classification: DemographyClassification) =>
  createSelector(
    selectSelectedClassifications,
    (selectedClassifications) =>
      !!selectedClassifications.find((selectedClassification) =>
        DemographyClassification.hasEqualIds(classification, selectedClassification)
      )
  );
