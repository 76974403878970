import React from 'react';

import { mapDataToCompareMatrix } from 'util/mapDataToCompareMatrix';
import { chunk } from 'util/chunkArray';

import { InsightQuestionWrapper } from 'view/InsightsPage/InsightsPage.s';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { InsightsItemModel } from 'api/dto/new/InsightsCompared/InsightsItem.dto';
import { Box } from '@mui/material';
import { CompareMatrix } from 'component/CompareMatrix/CompareMatrix';
import { ComparisionMatrix } from 'model/ComparisionMatrix';
import { RespondentProtectionEnum } from 'model/RespondentProtection';

export type InsightsComparedQuestionMatrixProps = {
  groups: Readonly<Record<string | number, string>>;
  isLifecycleProjectKind?: boolean;
  isReportView?: boolean;
  item: InsightsItemModel;
  minResponses: number;
};

export const InsightsComparedQuestionMatrix: React.FC<InsightsComparedQuestionMatrixProps> = ({
  groups,
  isLifecycleProjectKind,
  isReportView,
  item,
  minResponses,
}) => {
  const groupKeys = Object.keys(groups);
  const labelsValues = getLabelsValues(item);
  const labels = getLabels(labelsValues);
  const scores = getScores(item, groupKeys);
  const protectedScore = getProtectedScore(item, groupKeys);
  const confidentialityProtection = getConfidentialityProtection(item, groupKeys);
  const values = getValues(scores, protectedScore, confidentialityProtection);
  const renderContent = () => (
    <Box data-testid={`insightCompareContent[${item.question.id}]`}>
      <CompareMatrix
        title={item.question.content}
        titleDataCy="insightQuestionHeader"
        departments={Object.values(groups)}
        labels={labels}
        values={values}
        colorType="mixedBlue"
        minResponses={minResponses}
        isReportView={isReportView}
        overflow={'visible'}
      />
    </Box>
  );

  return (
    <InsightQuestionWrapper
      isReportView={isReportView}
      isLifecycleProjectKind={isLifecycleProjectKind}
      data-testid="insightQuestion"
      data-cy={`insightQuestion[${item?.question?.id ?? ''}]`}
      p={0}
    >
      {isReportView ? (
        renderContent()
      ) : (
        <ScrollBar overflow={'horizontal'} float={'none'} light paddingBottom={0}>
          {renderContent()}
        </ScrollBar>
      )}
    </InsightQuestionWrapper>
  );
};

export const getLabelsValues = (item: InsightsItemModel) => {
  return mapDataToCompareMatrix<string>(item.answers, ['option', 'label'], 'label');
};

export const getLabels = (labelsValues: string[]) => {
  return labelsValues.map((value) => ({ content: value, contentType: 'percentage' }));
};

export const getScores = (item: InsightsItemModel, groupKeys: string[]) => {
  const scores = item.answers.map((answer) => {
    return groupKeys.map(
      (groupKey) =>
        mapDataToCompareMatrix<number>([answer], ['byGroup', groupKey, 'score'], 'data')[0] || 0
    );
  });
  return chunk(scores.flat(), groupKeys.length);
};

export const getProtectedScore = (item: InsightsItemModel, groupKeys: string[]) => {
  const respondentProtections = item.answers.flatMap((answer) =>
    groupKeys.map((groupKey) => {
      return (
        mapDataToCompareMatrix<RespondentProtectionEnum | null>(
          [answer],
          ['byGroup', groupKey, 'respondentProtection'],
          'data'
        )[0] ||
        mapDataToCompareMatrix<RespondentProtectionEnum | null>(
          [answer],
          ['byGroup', groupKey, 'variableConfidentialityProtection'],
          'data'
        )[0] ||
        null
      );
    })
  );
  return chunk(respondentProtections, groupKeys.length);
};

export const getConfidentialityProtection = (item: InsightsItemModel, groupKeys: string[]) => {
  const variableConfidentialityProtections = item.answers.map((answer) => {
    return groupKeys.map(
      (groupKey) =>
        !!mapDataToCompareMatrix<boolean>(
          [answer],
          ['byGroup', groupKey, 'variableConfidentialityProtection'],
          'data'
        )[0] || false
    );
  });
  return chunk(variableConfidentialityProtections.flat(), groupKeys.length);
};

export const getValues = (
  scores: (number | null)[][],
  protectedScore: (RespondentProtectionEnum | null)[][],
  confidentialityProtection: boolean[][]
): ComparisionMatrix.Value[] => {
  return scores.map((item, index) => ({
    value: item,
    respondentProtectionTypeArray: protectedScore[index],
    isConfidentialityProtection: confidentialityProtection[index],
  }));
};
