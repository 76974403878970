import { UserApi } from 'api/UserApi';
import { User } from 'model/User';
import { Permission } from 'model/Permission';

export class UserMapper {
  deserialize(entry: UserApi.Entry, projectId?: number): User {
    return {
      id: entry.id,
      firstName: entry.firstName,
      lastName: entry.lastName,
      email: entry.email,
      expirationDate: entry.exp,
      permissions:
        entry.groups && projectId
          ? Permission.getPermissionsByProjectId(entry.groups, projectId)
          : [],
      role: entry.roles[0],
    };
  }
}
