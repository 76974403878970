import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  ComponentLabel,
  ComponentWrapper,
  SummaryWidgetsContent,
  SummaryWidgetsWrapper,
} from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { selectCurrentProjectId, selectProjectIdParam } from 'reduxStore/project/selectors';
import { PulseSurveyBarChart } from 'component/PulseSurveyBarChart/PulseSurveyBarChart';
import { ProjectSummary } from 'model/ProjectSummary';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import chunk from 'lodash/chunk';
import { ProjectKindLabel } from 'component/ProjectKindLabel/ProjectKindLabel';
import { selectEngagementDriverProjectScore } from 'reduxStore/engagementDrivers/selectors';
import { selectPulsesList, selectPulseSummaryResource } from 'reduxStore/summary/selectors';
import { PercentageBarSize } from 'component/PercentageBar/PercentageBarSize';
import { ResponseRateWidget } from 'view/ReportView/SummaryWidgetReport/ResponseRateWidget';
import { useAppDispatch } from 'reduxStore/appStore';
import { ProjectStatus } from 'model/ProjectStatus';
import { fetchPulsesSummary } from 'reduxStore/summary/asyncActions';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';

export type ReportPulseSummaryCombineWidgetsProps = {
  projectId: number;
  maxScore: number | undefined;
  isReportView?: boolean;
  pulses: ProjectSummary[];
};

export const ReportPulseSummaryCombineWidgetsPure: React.FC<ReportPulseSummaryCombineWidgetsProps> = ({
  projectId,
  maxScore,
  isReportView,
  pulses,
}) => {
  const MAX_ITEMS_PER_PAGE = 9;

  const wrapComponent = (component: ReactElement) => (
    <ReportContent title="Summary" component={component} />
  );

  // Only show response rate on first page if chart is split for multiple sites
  const baseView = (component: ReactElement | null, showResponseRate: boolean) => {
    return (
      <SummaryWidgetsWrapper isReportView={isReportView} paddingTop={50}>
        <SummaryWidgetsContent isPulseTemplateView>
          {showResponseRate && (
            <ResponseRateWidget
              projectId={projectId}
              isReportView={isReportView}
              percentageBarSize={PercentageBarSize.BIG}
              brakeAfter="page"
            />
          )}
          {ProjectSummary.showPulseSummaryGraph(pulses) && (
            <ComponentWrapper>
              {component && maxScore && (
                <ComponentLabel adjustToLeft>
                  <ProjectKindLabel name={`Engagement Score: ${maxScore}`} />
                </ComponentLabel>
              )}
              {component}
            </ComponentWrapper>
          )}
        </SummaryWidgetsContent>
      </SummaryWidgetsWrapper>
    );
  };

  const sliceData = (pulseSummary: ProjectSummary[]): ProjectSummary[][] => {
    const nonEmptyScoreData = pulseSummary.filter(({ score }) => score !== 0);

    const chunked = chunk(nonEmptyScoreData, MAX_ITEMS_PER_PAGE);
    // If there are more than one page of bars and the last one consists only of grey bars, we should remove it.
    const removeIfAllInDevelopmentStatus = chunked.map((item, i) => {
      const total = item.filter((x) => x.status === ProjectStatus.IN_DEVELOPMENT).length;

      if (chunked[i].length === total) {
        return [];
      }

      return item;
    });

    const removeEmptyArray = removeIfAllInDevelopmentStatus.filter((e) => e.length);

    return removeEmptyArray;
  };

  const barChartComponent = (data: ProjectSummary[]) => {
    return <PulseSurveyBarChart data={ProjectSummary.markSelected(projectId, data)} />;
  };

  return (
    <>
      {sliceData(pulses).map((summaries, index) => (
        <React.Fragment key={index}>
          {wrapComponent(baseView(barChartComponent(summaries), index === 0))}
        </React.Fragment>
      ))}
    </>
  );
};

export const ReportPulseSummaryCombineWidgets = () => {
  const dispatch = useAppDispatch();
  const projectIdFromState = useSelector(selectCurrentProjectId);
  const projectIdFromUrl = useSelector(selectProjectIdParam);
  const projectId = projectIdFromState || projectIdFromUrl;
  const maxScore = useSelector(selectEngagementDriverProjectScore);
  const pulses = useSelector(selectPulsesList);
  const resource = useSelector(selectPulseSummaryResource);

  useEffect(() => {
    if (!pulses) {
      dispatch(fetchPulsesSummary());
    }
  }, [dispatch, pulses]);

  return (
    <ResourceLoader resource={resource}>
      <ReportPulseSummaryCombineWidgetsPure
        pulses={pulses!}
        projectId={projectId}
        maxScore={maxScore}
        isReportView
      />
    </ResourceLoader>
  );
};
