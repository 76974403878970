import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { selectHasMatch } from 'util/selector/routerSelector';
import { selectReportProjectId } from 'util/selector/reportSelector';
import {
  selectCommentReportPageFilterQueryString,
  selectCommentsPageFilterQueryString,
} from 'util/selector/querySelector';

import { ReportRoute } from 'app/route/report';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { fetchAllComments } from 'reduxStore/comments/asyncActions';
import { queryDto } from 'api/dto/Query.dto';

export const useFetchAllComments = () => {
  const dispatch = useAppDispatch();
  const isReport = useSelector(selectHasMatch(ReportRoute.REPORT_PATTERN));
  const reportProjectId = useSelector(selectReportProjectId);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const projectId = isReport ? reportProjectId : currentProjectId;

  const commentReportPageFilterQueryString = useSelector(selectCommentReportPageFilterQueryString);
  const commentsPageFilterQueryString = useSelector(selectCommentsPageFilterQueryString);

  const query = queryDto.build(
    isReport ? commentReportPageFilterQueryString : commentsPageFilterQueryString
  );

  useMount(() => {
    dispatch(fetchAllComments({ projectId, query }));
  });
};
