import React from 'react';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { selectHasMatch } from 'util/selector/routerSelector';

import { NavButton, NavButtonProps } from 'component/NavButton/NavButton';
import { openInNewTab } from 'reduxStore/router/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';

export namespace NavItem {
  export type StateProps = {
    style: any;
  };
  export type DispatchProps = {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
  export type OwnProps = NavButtonProps & {
    href: string;
    matchHref?: string | string[];
    activeBackgroundColor: string;
    activeColor: string;
    externalLink?: boolean;
    activeFontWeight?: any;
    'data-testid'?: string;
  };
  export type Props = DispatchProps & OwnProps;
}

export const NavItem: React.FC<NavItem.OwnProps> = (props) => {
  const dispatch = useAppDispatch();

  const matchHrefArray = props.matchHref
    ? typeof props.matchHref === 'string'
      ? [props.matchHref]
      : props.matchHref
    : [];

  const hasMatchParamsArray = !props.matchHref ? [props.href] : matchHrefArray;

  const isActive = useSelector(selectHasMatch(...hasMatchParamsArray));

  let style = props.style || {};

  if (props.href) {
    style = {
      ...style,
      backgroundColor: isActive ? props.activeBackgroundColor : props.backgroundColor,
      color: isActive ? props.activeColor : props.color,
      fontWeight: isActive ? props.activeFontWeight : 400,
      whiteSpace: 'nowrap',
    };
  }

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.href) {
      const isExternalHref = props.href.startsWith('http://') || props.href.startsWith('https://');
      if (isExternalHref) {
        dispatch(openInNewTab(props.href));
      } else {
        dispatch(push(props.href));
      }
    }
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return <NavButton {...props} style={style} onClick={onClick} />;
};
