import React from 'react';

import { Option } from 'model/Option';
import { SelectBoxBase, SelectBoxBaseProps } from 'component/SelectBox/SelectBoxBase';

export namespace SelectBox {
  export type Props = SelectBoxBaseProps<Option['id']> & {
    onChange: (value: Option['id']) => void;
  };
}

export function OptionSelectBox(props: SelectBox.Props) {
  return <SelectBoxBase {...props} />;
}
