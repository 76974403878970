import { CORNFLOWER_BLUE, MARINER, WHITE } from 'app/theme/colors';
import { Box, styled, SxProps } from '@mui/material';

type InsightQuestionWrapperProps = {
  isReportView?: boolean;
  isLifecycleProjectKind?: boolean;
};

export const InsightQuestionWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !['isReportView', 'isLifecycleProjectKind'].includes(prop.toString()),
})(
  {
    background: WHITE,
    borderRadius: '10px',
    marginBottom: '24px',
    pageBreakAfter: 'avoid',
    pageBreakBefore: 'avoid',
    pageBreakInside: 'avoid',
    '&:last-child > hr': {
      display: 'none',
    },
    '@media print': {
      '&:last-child': {
        pageBreakAfter: 'avoid',
      },
      pageBreakAfter: 'always',
    },
  },
  ({ isReportView, isLifecycleProjectKind }: InsightQuestionWrapperProps) => ({
    width: isReportView ? (isLifecycleProjectKind ? 960 : 850) : 'auto',
  })
);

export const SingleInsightBar = styled('div')({
  display: 'flex',
  marginBottom: '24px',
});

export const InsightCombineContentWrapper = styled('div')({
  width: '100%',
});

export const insightBarChartStyles: SxProps = {
  height: '10px',
  width: '100%',
  background: CORNFLOWER_BLUE,
  borderRadius: '24px',
  mr: '24px',

  '.MuiLinearProgress-bar1Determinate': {
    background: MARINER,
    borderRadius: '24px',
  },
};
