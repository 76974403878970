import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectFiltersQuery } from 'util/selector/querySelector';

import { PageWrapperHeader } from 'component/PageHeader/PageHeader.s';
import { PageTitle } from 'component/PageHeaderTitle/PageTitle';
import { PageContentWrapper } from 'shared/styles/layout';
import { NetSentimentHeader } from 'view/TopicsPage/Sentiment/NetSentiment';
import { SentimentGraph } from 'view/TopicsPage/Sentiment/SentimentGraph';
import { TopicsPageDescription } from 'view/TopicsPage/TopicsPageDescription';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectAreTopicsEmpty, selectTopicsResource } from 'reduxStore/topics/selectors';
import { NoCommentsMessage } from 'view/CommentsPage/CommentsPage';
import { fetchProjectTopics } from 'reduxStore/topics/asyncActions';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import debounce from 'lodash/debounce';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import { useFetchDemography } from 'shared/hooks/useFetchDemography';
import { useFetchDepartments } from 'shared/hooks/useFetchDepartments';
import { TopicPageCard } from 'view/TopicsPage/TopicsPage.s';

export namespace TopicsPage {
  export type Props = {
    areTopicsEmpty: boolean;
  };
}

export const TopicsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const topicsResource = useSelector(selectTopicsResource);
  const areTopicsEmpty = useSelector(selectAreTopicsEmpty);
  const projectId = useSelector(selectCurrentProjectId);
  const query = useSelector(selectFiltersQuery);

  useFetchDemography();
  useFetchDepartments();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDispatch = useCallback(
    debounce(({ projectId, query }: { projectId: number; query: string }) => {
      dispatch(fetchProjectTopics({ projectId, query }));
    }, FILTERS_CHANGED_DEBOUNCE_TIMEOUT),
    []
  );
  useEffect(() => {
    debouncedDispatch({ projectId, query });
  }, [query, projectId, dispatch, debouncedDispatch]);

  return (
    <div>
      <PageWrapperHeader>
        <PageTitle title="Topics" />
      </PageWrapperHeader>
      <PageContentWrapper>
        <ResourceLoader resource={topicsResource}>
          <TopicPageCard>
            {areTopicsEmpty ? (
              <NoCommentsMessage />
            ) : (
              <>
                <NetSentimentHeader />
                <TopicsPageDescription />
                <SentimentGraph />
              </>
            )}
          </TopicPageCard>
        </ResourceLoader>
      </PageContentWrapper>
    </div>
  );
};
