import { Draft, createSlice } from '@reduxjs/toolkit';
import {
  fetchSimpleProjectCombine,
  fetchSimpleProjectCompare,
} from 'reduxStore/simpleProject/asyncActions';
import { Resource } from 'model/Resource';
import { SimpleProjectCombine } from 'model/SimpleProjectCombine';
import { SimpleProjectCompare } from 'model/SimpleProjectCompare';

import { STORE_NAME, SimpleProjectState } from './initialState';

export const simpleProjectSlice = createSlice({
  name: STORE_NAME,
  initialState: SimpleProjectState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSimpleProjectCombine.fulfilled, (state, action) => {
      state.combine = Resource.createResolvedResource({
        data: action.payload.simpleProject,
      }) as Draft<Resource<SimpleProjectCombine>>;
    });

    builder.addCase(fetchSimpleProjectCombine.pending, (state) => {
      state.combine = Resource.createPendingResource() as Draft<Resource<SimpleProjectCombine>>;
    });

    builder.addCase(fetchSimpleProjectCombine.rejected, (state, action) => {
      state.combine = Resource.createRejectedResource({
        error: action.payload ?? action.error,
      }) as Draft<Resource<SimpleProjectCombine>>;
    });

    builder.addCase(fetchSimpleProjectCompare.fulfilled, (state, action) => {
      state.compare = Resource.createResolvedResource({
        data: action.payload.simpleProject,
      }) as Draft<Resource<SimpleProjectCompare>>;
    });

    builder.addCase(fetchSimpleProjectCompare.pending, (state) => {
      state.compare = Resource.createPendingResource() as Draft<Resource<SimpleProjectCompare>>;
    });

    builder.addCase(fetchSimpleProjectCompare.rejected, (state, action) => {
      state.compare = Resource.createRejectedResource({
        error: action.payload ?? action.error,
      }) as Draft<Resource<SimpleProjectCompare>>;
    });
  },
});
