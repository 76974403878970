import React, { forwardRef, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectSentimentIsLoaded } from 'reduxStore/project/selectors';
import { CommentAnswerItem } from 'view/CommentsPage/CommentAnswerItem';
import { Answer } from 'model/Answer';
import { Pagination } from 'component/Pagination/Pagination';
import { Pagination as PaginationModel } from 'model/Pagination';
import { fetchCommentAnswers } from 'reduxStore/comments/asyncActions';
import { CommentAnswersWrapper, PaginationWrapper } from 'view/CommentsPage/CommentsPage.s';
import { selectHasAnswers } from 'reduxStore/comments/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { Typography } from '@mui/material';

export type CommentAnswersProps = {
  answers: Answer[];
  pagination: PaginationModel | null;
};

export type CommentAnswersPureProps = CommentAnswersProps & {
  hasAnswers: boolean;
  isSentimentLoaded: boolean;
  fetchAnswers: (page?: number) => void;
};

export const CommentAnswersPure = forwardRef<HTMLDivElement, CommentAnswersPureProps>(
  ({ answers, fetchAnswers, hasAnswers, isSentimentLoaded, pagination }, ref) => {
    return (
      <CommentAnswersWrapper data-testid="commentAnswers" ref={ref}>
        {hasAnswers ? (
          <>
            {answers.map((item, index) => (
              <CommentAnswerItem isSentimentLoaded={isSentimentLoaded} key={index} item={item} />
            ))}
            {pagination && pagination.pageCount > 1 && (
              <PaginationWrapper>
                <Pagination
                  pageCount={pagination.pageCount}
                  page={pagination.index}
                  onPageChange={(page: number): void => {
                    fetchAnswers(page);
                  }}
                  data-testid="commentAnswersPagination"
                />
              </PaginationWrapper>
            )}
          </>
        ) : (
          <Typography data-testid="noCommentsMessage">
            No comments received for the above question
          </Typography>
        )}
      </CommentAnswersWrapper>
    );
  }
);

export const CommentAnswers: React.FC<CommentAnswersProps> = ({ answers, pagination }) => {
  const dispatch = useAppDispatch();
  const hasAnswers = useSelector(selectHasAnswers);
  const isSentimentLoaded = useSelector(selectSentimentIsLoaded);
  const isPaginationFetch = useRef(false);
  const wrapper: React.ForwardedRef<HTMLDivElement> = useRef(null);
  const fetchAnswers = (page?: number) => {
    isPaginationFetch.current = true;
    dispatch(fetchCommentAnswers({ page, refetchWordCloud: false }));
  };

  useEffect(() => {
    if (isPaginationFetch.current) {
      isPaginationFetch.current = false;
      window.scrollTo({ top: wrapper?.current?.offsetTop || 0, left: 0, behavior: 'smooth' });
    }
  }, [answers]);

  return (
    <CommentAnswersPure
      ref={wrapper}
      answers={answers}
      pagination={pagination}
      hasAnswers={hasAnswers}
      isSentimentLoaded={isSentimentLoaded}
      fetchAnswers={fetchAnswers}
    />
  );
};
