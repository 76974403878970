import { expandDepartmentTree, toggleDepartmentTree } from 'util/tree';

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { fetchDepartments } from 'reduxStore/departments/asyncActions';
import { Department } from 'model/Department';
import { isEqual } from 'lodash';
import { Resource } from 'model/Resource';

import { DepartmentState, STORE_NAME } from './initialState';

export const departmentSlice = createSlice({
  name: STORE_NAME,
  initialState: DepartmentState.INITIAL_DOMAIN,
  reducers: {
    toggleDepartmentCollapsedState: (state, action: PayloadAction<number>) => {
      state.list.data = state.list.data.map((department) =>
        toggleDepartmentTree(department, action.payload)
      );
    },
    expandAllDepartments: (state) => {
      state.list.data = state.list.data.map(expandDepartmentTree);
    },
    clearDepartments: (state) => {
      state.list = DepartmentState.INITIAL_DOMAIN['list'];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDepartments.pending, (state) => {
      state.list = Resource.createPendingResource(state.list.data);
    });
    builder.addCase(fetchDepartments.fulfilled, (state, action) => {
      const payloadDepartments = (action.payload || []) as Department[];
      const payloadDepartmentsIds = Department.getSortedDepartmentsIds(payloadDepartments);
      const stateDepartmentsIds = Department.getSortedDepartmentsIds(state.list.data);
      const stateDepartmentsFlatten = Department.flattenDepartments(state.list.data);
      const data = isEqual(stateDepartmentsIds, payloadDepartmentsIds)
        ? Department.mergeDepartments({ stateDepartmentsFlatten, payloadDepartments })
        : payloadDepartments;

      state.list = Resource.createResolvedResource({ data });
    });
    builder.addCase(fetchDepartments.rejected, (state, action) => {
      state.list = Resource.createRejectedResource({
        data: [],
        error: action.payload ?? action.error,
      }) as Draft<Resource<Department[]>>;
    });
  },
});

export const {
  clearDepartments,
  expandAllDepartments,
  toggleDepartmentCollapsedState,
} = departmentSlice.actions;
