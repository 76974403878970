import { AxiosInstance } from 'axios';
import { ReportCategoryQuery } from 'api/ReportCategory.query';

export namespace DepartmentApi {
  export type Entry = {
    id: number;
    name: string;
    children: Entry[];
    blocked: boolean;
  };
}

export class DepartmentApi {
  constructor(private client: AxiosInstance) {}

  list(
    projectId: number,
    query: { reportCategory?: ReportCategoryQuery }
  ): Promise<DepartmentApi.Entry[]> {
    return (
      this.client
        // TODO rename prefix to reportCategory
        .get(`/api/v1/project/${projectId}/department`, {
          params: { prefix: query.reportCategory },
        })
        .then((response) => response.data.data)
    );
  }
}
