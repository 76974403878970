import { styled, Typography } from '@mui/material';
import { WHITE } from 'app/theme/colors';
import { css } from 'glamor';
import { Checkbox } from 'component/Checkbox/Checkbox';
import { typography } from 'app/theme/typography';

export type ItemProps = {
  level: number;
};

export const ItemWrapper = styled('div')({
  position: 'relative',
});

export const Item = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  (props: ItemProps): any => ({
    marginLeft: props.level * 10,
  })
);

export const ItemTitleWrapper = styled('div')({
  width: 300,
  display: 'inline',
  alignItems: 'center',
  color: WHITE,
  marginTop: 5,
});

export type ItemTitleProps = {
  hasChildren: boolean;
  checked: boolean;
  blocked: boolean;
  level?: number;
};

export const ItemTitle = styled(Typography, {
  shouldForwardProp: (prop) => !['hasChildren', 'checked', 'blocked'].includes(prop.toString()),
})((props: ItemTitleProps) => {
  const width = 216 - (props.hasChildren ? 16 : 0) - (props.level ?? 0) * 10;

  return {
    ...typography.body1,
    display: 'block',
    cursor: props.blocked ? 'default' : 'pointer',
    width,
  };
});

export const ListWrapper = styled('div')({
  height: 300,
});

export const StyledCheckbox = styled(Checkbox)({
  position: 'absolute',
  right: 0,
});

export type ArrowIconWrapperProps = {
  level?: number;
};

export const ArrowIconWrapper = styled('div')((props: ArrowIconWrapperProps): any => ({
  marginLeft: props.level ? props.level * -5 : -6,
}));

export const styledAutoList = css({
  willChange: 'unset !important',
});
