import React, { PropsWithChildren } from 'react';

import { Box, Grid2 as Grid } from '@mui/material';
import { ChartWrapper } from 'view/LifecyclePage/LifecyclePage.s';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';

import { ResponsesChart } from './ResponsesChart/ResponsesChart';
import { RecommendChart } from './RecommendChart/RecommendChart';
import { ResponsesChartReport } from './ResponsesChart/ResponsesChartReport';
import { RecommendChartReport } from './RecommendChart/RecommendChartReport';
import { ChartsContainer } from './LifecycleCharts.s';

type LifecycleChartsProps = {
  isReportView?: boolean;
};

export const LifecycleCharts: React.FC<LifecycleChartsProps> = ({ isReportView }) => {
  const ChartGridItem: React.FC<PropsWithChildren<{ children: React.ReactNode }>> = ({
    children,
  }) => (
    <Grid size={{ lg: 6, sm: isReportView ? 6 : 12 }} sx={{ minWidth: '500px' }}>
      <ChartWrapper>{children}</ChartWrapper>
    </Grid>
  );

  return isReportView ? (
    <Box>
      <ScrollBar
        maxWidth={'max-content'}
        minWidth={500}
        maxHeight={'max-content'}
        overflow={'horizontal'}
        light
        float={'none'}
      >
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          flexWrap={isReportView ? 'nowrap' : 'wrap'}
        >
          <ChartGridItem>
            <ResponsesChartReport />
          </ChartGridItem>
          <ChartGridItem>
            <RecommendChartReport />
          </ChartGridItem>
        </Grid>
      </ScrollBar>
    </Box>
  ) : (
    <ChartsContainer>
      <ResponsesChart />
      <RecommendChart />
    </ChartsContainer>
  );
};
