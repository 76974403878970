/**
 * This is separated type from TopicApi.Topic because api structure might change
 */
export type Topic = {
  name: string;
  commentCount: number;
  score: number;
};

export const TopicKeys: { [key in keyof Topic]: keyof Topic } = {
  name: 'name',
  commentCount: 'commentCount',
  score: 'score',
};

export type TopicSummary = {
  score: number;
  commentCount: number;
};
