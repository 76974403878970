import React, { PropsWithChildren } from 'react';

export namespace Text {
  export type Props = {
    x: number | string | undefined;
    y: number | string | undefined;
    fontWeight?: number;
    fontSize?: number;
    dy?: number | string;
    textAnchor?: string;
    fill?: string;
  };
}

export const Text: React.FC<PropsWithChildren<Text.Props>> = (props) => (
  <text
    textAnchor={props.textAnchor}
    x={props.x}
    y={props.y}
    fill={props.fill}
    fontWeight={props.fontWeight}
    fontSize={props.fontSize}
    dy={props.dy}
  >
    {props.children}
  </text>
);
