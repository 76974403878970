import { ResponseRate } from 'model/ResponseRate';

export const STORE_NAME = 'responseRate';

export namespace ResponseRateState {
  export interface LoadingState {
    isLoading: true;
  }

  export type Domain = { data: Record<number, ResponseRate | LoadingState> };

  export const INITIAL_DOMAIN: Domain = { data: {} };
}
