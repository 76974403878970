import { usePushLegalQuery } from 'shared/hooks/usePushLegalQuery';
import { useIsCompareModeLock } from 'shared/hooks/useIsCompareModeLock';
import { useFetchDemography } from 'shared/hooks/useFetchDemography';
import { useFetchDepartments } from 'shared/hooks/useFetchDepartments';

export const useInsightsPageEffects = () => {
  usePushLegalQuery();
  useIsCompareModeLock();
  useFetchDemography();
  useFetchDepartments();
};
