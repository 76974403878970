import React from 'react';

import { createRoot } from 'react-dom/client';
import { getAppConfig } from 'app/getConfig';

import history from '../app/history';

import { App } from './App';

if (getAppConfig().NODE_ENV === 'production') {
  let shouldUpdate = false;

  import('offline-plugin/runtime').then((OfflinePluginRuntime) => {
    OfflinePluginRuntime.install({
      onUpdateReady: () => OfflinePluginRuntime.applyUpdate(),
      onUpdated: () => (shouldUpdate = true),
    });
  });

  history.listen((location) => {
    if (shouldUpdate) {
      // reload application
      window.location.assign(location.toString());
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<App />);
});
