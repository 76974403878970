import React, { useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import { Box } from '@mui/material';

export namespace SentimentBarPlaceholder {
  export type Props = {
    width: number;
    height: number;
  };
}

const barCount = 10;

export const SentimentBarPlaceholder: React.FC<SentimentBarPlaceholder.Props> = (props) => {
  const [width, setWidth] = useState(props.width);
  const [patternWidth, setPatternWidth] = useState(props.width / barCount);
  useEffect(() => {
    const handleResize = () => {
      if (document.body.offsetWidth < 600) {
        const barWidth = document.body.offsetWidth - 30;
        setWidth(barWidth);
        setPatternWidth(barWidth / barCount);
      } else {
        setWidth(props.width);
        setPatternWidth(props.width / barCount);
      }
    };

    const debouncedHandleResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedHandleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [props.width]);

  return (
    <Box sx={{ minHeight: '150px', display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '100%' }} data-testid="sentimentBarPlaceholder">
        <p style={{ textAlign: 'center' }}>
          Sentiment analysis is currently being run – as your survey has just closed this may take a
          few minutes.
        </p>
        <svg width="100%" height={props.height}>
          <defs>
            <pattern
              id="sentiment-bar-placeholder-background"
              width={patternWidth / width}
              height="100%"
              patternTransform="skewX(50)"
            >
              <rect width="100%" height="100%" fill="#C3C3C3" />
              <rect width="15" height="100%" fill="#585858">
                <animate
                  attributeName="x"
                  values={`0;${patternWidth}`}
                  dur="2s"
                  repeatCount="indefinite"
                />
              </rect>
              <rect width="15" height="100%" fill="#585858">
                <animate
                  attributeName="x"
                  values={`-${patternWidth};0`}
                  dur="2s"
                  repeatCount="indefinite"
                />
              </rect>
            </pattern>
          </defs>

          <rect
            width="100%"
            height="100%"
            rx="5"
            ry="5"
            fill="url(#sentiment-bar-placeholder-background)"
          />
        </svg>
      </div>
    </Box>
  );
};
