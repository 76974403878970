import React from 'react';
import { useSelector } from 'react-redux';

import { TopicsApi } from 'api/TopicsApi';
import { MultiSelectBoxBase } from 'component/SelectBox/MultiSelectBoxBase';
import { CheckboxOption, LeftAlignedOptionItem } from 'component/SelectBox/SelectBox.s';
import { selectAllSentimentTopics, selectTopicFilter } from 'reduxStore/comments/selectors';
import { useMediaQuery, useTheme } from '@mui/material';

export type TopicFilterValue = keyof TopicsApi.AllTopicsEntry;
export type TopicFilterOption = Readonly<{
  id: TopicFilterValue;
  label: TopicsApi.AllTopicsEntry[keyof TopicsApi.AllTopicsEntry];
}>;

export type TopicFilterProps = {
  onChange: (values: TopicFilterValue[]) => void;
  disabled?: boolean;
};

export type TopicFilterPureProps = {
  selectedFilter: TopicFilterValue[];
  optionList: TopicsApi.AllTopicsEntry;
} & TopicFilterProps;

const renderSelected = (selectedOptions: TopicFilterOption[]) => (
  <span>
    {selectedOptions.length
      ? selectedOptions.length === 1
        ? selectedOptions[0].label
        : `${selectedOptions.length} selected`
      : 'Any Topic'}
  </span>
);

const compareValue = (option1: TopicFilterOption, option2: TopicFilterOption) => {
  return option1.id === option2.id;
};

const TopicFilterPure: React.FC<TopicFilterPureProps> = (props) => {
  const optionList = Object.entries(props.optionList).map(([key, label]) => ({
    id: Number(key),
    label: label,
  }));
  const selectedFilters = optionList.filter((option) => props.selectedFilter.includes(option.id));
  const handleChange = (options: TopicFilterOption[]) => {
    props.onChange(options.map((option) => option.id));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const width = isMobile ? '100%' : 280;

  return (
    <MultiSelectBoxBase<TopicFilterOption>
      data-testid="sentimentTopicMultiSelectBox"
      onChange={handleChange}
      selected={selectedFilters}
      renderSelected={renderSelected}
      compareValues={compareValue}
      width={width}
      disabled={!!props.disabled}
    >
      {optionList.map((item) => {
        const isSelected = props.selectedFilter.includes(item.id);

        return (
          <LeftAlignedOptionItem
            key={item.id}
            value={item.id}
            label={item.label}
            data-testid={item.id}
            selected={isSelected}
            data-item={item}
          >
            <CheckboxOption checked={isSelected} variant="LIGHT" />
            {item.label}
          </LeftAlignedOptionItem>
        );
      })}
    </MultiSelectBoxBase>
  );
};

export const TopicFilter: React.FC<TopicFilterProps> = (props) => {
  const selectedFilter = useSelector(selectTopicFilter);
  const optionList = useSelector(selectAllSentimentTopics);

  return <TopicFilterPure {...props} selectedFilter={selectedFilter} optionList={optionList} />;
};
