import React from 'react';

import { DownloadButton } from 'view/ReportsPage/DepartmentLevel/DepartmentLevel.s';
import { LoadingIcon } from 'component/PageHeader/PageHeader.s';
import { WHITE } from 'app/theme/colors';

type DownloadReportButtonProps = {
  isPending: boolean;
  label: string;
  onClick: () => void;
  isFeedback?: boolean;
};

export const DownloadReportButton: React.FC<DownloadReportButtonProps> = ({
  isPending,
  label,
  onClick,
  isFeedback,
}) => {
  return (
    <DownloadButton isFeedback={isFeedback} onClick={onClick}>
      {isPending ? <LoadingIcon type="spin" color={WHITE} height={20} width={20} /> : label}
    </DownloadButton>
  );
};
