import React from 'react';
import { useSelector } from 'react-redux';

import { selectFiltersQuery } from 'util/selector/querySelector';

import { ProjectKind } from 'model/ProjectKind';
import { PercentageBar } from 'component/PercentageBar/PercentageBar';
import {
  ComponentLabel,
  ComponentWrapper,
  SummaryWidgetsContent,
  SummaryWidgetsWrapper,
} from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { Trend } from 'model/Trend';
import { selectBenchmark, selectScore, selectTrends } from 'reduxStore/summary/selectors';
import { PercentageBarType } from 'model/PercentageBarType';
import {
  selectCurrentProjectId,
  selectProjectIdParam,
  selectIsOverallScoreShow,
  selectProjectKind,
} from 'reduxStore/project/selectors';
import { ProjectKindLabel } from 'component/ProjectKindLabel/ProjectKindLabel';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { ResponseRateWidget } from 'view/ReportView/SummaryWidgetReport/ResponseRateWidget';
import { TrendWidget } from 'view/SummaryPage/SummaryWidgets/TrendWidget/TrendWidget';
import { selectShouldShowEngagementScore } from 'reduxStore/engagementDrivers/selectors';
import { selectChartLabel } from 'reduxStore/visualSettings/selectors';
import { Benchmark } from 'model/Benchmark';
import { BenchmarkWidget } from 'view/SummaryPage/SummaryWidgets/BenchmarkWidget/BenchmarkWidget';
import { Score } from 'model/Score';

export type ReportSummaryWidgetsProps = {
  hideScore?: boolean;
  isReportView?: boolean;
  noPaddings?: boolean;
};

export type ReportSummaryWidgetsPureProps = ReportSummaryWidgetsProps & {
  projectKind: ProjectKind;
  query: string;
  projectId: number;
  isUserAdmin: boolean;
  score: Score | undefined;
  trends: ReadonlyArray<Trend> | undefined;
  benchmark: Benchmark;
  showEngagementScore: boolean;
  isOverallShow: boolean;
  chartLabel: string | null;
  noPaddings?: boolean;
};

export const ReportSummaryWidgetsPure: React.FC<ReportSummaryWidgetsPureProps> = (props) => {
  return (
    <SummaryWidgetsWrapper
      isReportView={props.isReportView}
      marginLeft={props.trends && props.trends.length > 1 ? 0 : undefined}
      paddingTop={props.trends && props.trends.length > 1 ? 0 : undefined}
    >
      <SummaryWidgetsContent>
        <ResponseRateWidget projectId={props.projectId} isReportView={props.isReportView} />

        <ComponentWrapper noPaddings={props.noPaddings}>
          <ComponentLabel>
            {props.projectKind === ProjectKind.PROJECT_ENGAGEMENT_SCORE ||
            props.showEngagementScore ? (
              <ProjectKindLabel
                kind={
                  props.showEngagementScore
                    ? ProjectKind.PROJECT_ENGAGEMENT_SCORE
                    : props.projectKind
                }
                name={props.chartLabel}
              />
            ) : props.projectKind === ProjectKind.PROJECT_OVERALL_SCORE ? (
              props.isOverallShow ? (
                <ProjectKindLabel kind={props.projectKind} />
              ) : null
            ) : (
              <ProjectKindLabel kind={props.projectKind} />
            )}
          </ComponentLabel>
          {!props.hideScore && props.score && (
            <PercentageBar
              type={PercentageBarType.Question}
              value={props.score.value}
              isReportView
            />
          )}
        </ComponentWrapper>
        {props.trends && <TrendWidget trends={props.trends} isReportView={props.isReportView} />}
        {Benchmark.isNotNone(props.benchmark) && (
          <BenchmarkWidget
            isUserAdmin={props.isUserAdmin}
            benchmark={props.benchmark}
            noPaddings={props.trends && props.trends.length > 1}
            isReportView={props.isReportView}
          />
        )}
      </SummaryWidgetsContent>
    </SummaryWidgetsWrapper>
  );
};

export const ReportSummaryWidgets: React.FC<ReportSummaryWidgetsProps> = (props) => {
  const trends = useSelector(selectTrends);
  const score = useSelector(selectScore);
  const benchmark = useSelector(selectBenchmark);
  const projectKind = useSelector(selectProjectKind);
  const query = useSelector(selectFiltersQuery);
  const isUserAdmin = useSelector(selectIsAdmin);
  const showEngagementScore = useSelector(selectShouldShowEngagementScore);
  const isOverallShow = useSelector(selectIsOverallScoreShow);
  const chartLabel = useSelector(selectChartLabel);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const projectIdParam = useSelector(selectProjectIdParam);

  const projectId = currentProjectId ? currentProjectId : projectIdParam;

  return (
    <ReportSummaryWidgetsPure
      {...props}
      projectId={projectId}
      trends={trends}
      score={score}
      benchmark={benchmark}
      projectKind={projectKind}
      query={query}
      isUserAdmin={isUserAdmin}
      showEngagementScore={showEngagementScore}
      isOverallShow={isOverallShow}
      chartLabel={chartLabel}
    />
  );
};
