import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { TopNavBar } from 'component/TopNavBar/TopNavBar';
import { RibbonNavBar } from 'component/RibbonNavBar/RibbonNavBar';
import { NavigationMenuItems } from 'component/Navigation/NavigationMenuItems';
import {
  AdminContainer,
  Link,
  PulseNavigationMenuItemWrapper,
} from 'component/Navigation/Navigation.s';
import { PulseSelect } from 'component/Navigation/PulseSelect';
import { getAppConfig } from 'app/getConfig';
import { selectIsAdmin, selectIsCircleAdmin, selectIsClientAdmin } from 'reduxStore/user/selectors';
import { MobileNavigationMenu } from 'component/Navigation/MobileNavigationMenu';
import { MobileMenuButton } from 'component/MobileMenuButton/MobileMenuButton';
import { TopNavBarDropdown } from 'component/TopNavBar/TopNavBarDropdown';
import { TopNavBarDropdownWrapper } from 'component/Navigation/PulseProjectNavigation.s';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useGetVisibleRibbonNavBarItems } from 'component/RibbonNavBar/hooks/useGetVisibleRibbonNavBarItems';

export const PulseProjectNavigation = () => {
  const adminLink = getAppConfig().ADMIN_UI_URL;
  const isAdmin = useSelector(selectIsAdmin);
  const isClientAdmin = useSelector(selectIsClientAdmin);
  const isCircleAdmin = useSelector(selectIsCircleAdmin);
  const shouldShowAdminLink = Boolean(adminLink) && (isAdmin || isClientAdmin || isCircleAdmin);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const visibleRibbonNavBarItems = useGetVisibleRibbonNavBarItems();
  const hasVisibleRibbonNavBarItems = visibleRibbonNavBarItems.length > 0;

  return (
    <>
      <TopNavBar>
        {isMobile && (
          <MobileMenuButton
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            isMenuOpen={isMobileMenuOpen}
          />
        )}
        <PulseSelect />
        {isMobile && (
          <MobileNavigationMenu
            isOpen={isMobileMenuOpen}
            shouldShowAdminLink={shouldShowAdminLink}
            shouldShowKnowledgebaseLink
            adminLink={adminLink}
          />
        )}
        <AdminContainer>
          <Link
            data-testid="knowledgebaseLink"
            sx={{ marginRight: shouldShowAdminLink ? '2rem' : 0 }}
            href="https://workbuzzsupport.zendesk.com/hc/en-gb/categories/4417866782610-WorkBuzz-Dashboard-Results"
            target="_blank"
          >
            <Typography sx={{ display: 'inline-block', fontSize: '16px' }} variant="body1">
              Knowledgebase
            </Typography>
          </Link>
          {shouldShowAdminLink && (
            <Link href={adminLink} target="_blank">
              <Typography sx={{ display: 'inline-block', fontSize: '16px' }} variant="body1">
                Admin
              </Typography>
            </Link>
          )}
        </AdminContainer>
        <TopNavBarDropdownWrapper>
          <TopNavBarDropdown />
        </TopNavBarDropdownWrapper>
      </TopNavBar>

      {!isMobile && (
        <PulseNavigationMenuItemWrapper
          hasVisibleRibbonNavBarItems={hasVisibleRibbonNavBarItems}
          data-testid="desktopNavigationItems"
        >
          <NavigationMenuItems />
        </PulseNavigationMenuItemWrapper>
      )}

      {hasVisibleRibbonNavBarItems && (
        <RibbonNavBar visibleRibbonNavBarItems={visibleRibbonNavBarItems} />
      )}
    </>
  );
};
