import { styled } from '@mui/material';

export const BarWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'isReportView' })(
  {
    maxWidth: 400,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  ({ isReportView }: { isReportView?: boolean }) => ({
    padding: isReportView ? 0 : '0 30px',
  })
);
