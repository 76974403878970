export enum LifecycleType {
  ONBOARD = 'onboard',
  EXIT = 'exit',
}

export namespace Lifecycle {
  export const LIFECYCLE_LABEL = {
    [LifecycleType.ONBOARD]: 'Onboard',
    [LifecycleType.EXIT]: 'Exit',
  } as const;
}
