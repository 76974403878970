import React from 'react';
import { useSelector } from 'react-redux';

import { chunk } from 'util/chunkArray';
import { mapDataToCompareMatrix } from 'util/mapDataToCompareMatrix';

import { CompareMatrix } from 'component/CompareMatrix/CompareMatrix';
import { Question } from 'model/Question';
import { selectSimpleProjectCompareQuestion } from 'reduxStore/simpleProject/selectors';

export type CasQuestionCompareProps = {
  title?: string;
  groups: string[];
  minResponses: number;
  isReportView: boolean;
};

export type CasQuestionComparePureProps = {
  question?: Question;
} & CasQuestionCompareProps;

export const CasQuestionComparePure: React.FC<CasQuestionComparePureProps> = (props) => {
  if (!props.question) {
    return null;
  }

  const labels = [
    {
      content: 'Score',
      contentType: 'number',
    },
  ];

  const scores = chunk<number>(
    mapDataToCompareMatrix<number>(props.question.groups, ['score'], 'data'),
    props.groups.length
  );

  const protectedScore = chunk<number>(
    mapDataToCompareMatrix<number>(
      props.question.groups,
      ['groups', 'respondentProtectionType'],
      'data'
    ),
    props.groups.length
  );

  const confidentialityProtection = chunk<number>(
    mapDataToCompareMatrix<number>(
      props.question.groups,
      ['groups', 'confidentialityProtection'],
      'data'
    ),
    props.groups.length
  );

  const values = scores.map((item, index) => ({
    value: item,
    respondentProtectionTypeArray: protectedScore[index],
    isConfidentialityProtection: confidentialityProtection[index],
  })) as any[];

  return (
    <CompareMatrix
      title={props.title}
      sx={{ mb: 3 }}
      departments={props.groups}
      labels={labels}
      values={values}
      colorType="grey"
      minResponses={props.minResponses}
      isReportView={props.isReportView}
    />
  );
};

export const CasQuestionCompare: React.FC<CasQuestionCompareProps> = (props) => {
  const question = useSelector(selectSimpleProjectCompareQuestion);

  return <CasQuestionComparePure {...props} question={question} />;
};
