import { compareGroupMapper } from 'container/compareGroupMapper';
import { questionMapper } from 'container/questionMapper';
import { pieChartMapper } from 'container/pieChartMapper';
import { barCharMapper } from 'container/barChartMapper';
import { opinionQuestionDetailsMapper } from 'container/opinionQuestionDetailsMapper';
import { summaryMapper } from 'container/summaryMapper';

import { SimpleProjectMapper } from '../mapper/SimpleProjectMapper';

export const simpleProjectMapper = new SimpleProjectMapper(
  summaryMapper,
  opinionQuestionDetailsMapper,
  compareGroupMapper,
  questionMapper,
  pieChartMapper,
  barCharMapper
);
