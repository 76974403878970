import { Box, styled } from '@mui/material';
import { WHITE } from 'app/theme/colors';

export const Tooltip = styled(Box)({
  backgroundColor: WHITE,
  borderRadius: '10px',
  boxShadow: '0px 0px 44px 0px rgba(0, 0, 0, 0.25)',
  padding: '24px',
});

export const SentimentScaleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'calc(100% - 25px)',
});

type SentimentScaleProps = {
  colors: [string, string, string, string, string, string, string, string, string];
};

export const SentimentScale = styled('div')(
  {
    flex: '1 0 auto',
    width: '20px',
    borderRadius: 6,
  },
  ({ colors }: SentimentScaleProps) => ({
    background: `linear-gradient(0deg, ${colors[0]} 0%, ${colors[0]} 11%, ${colors[1]} 11%, ${colors[1]} 22%, ${colors[2]} 22%, ${colors[2]} 33%, ${colors[3]} 33%, ${colors[3]} 44%, ${colors[4]} 44%, ${colors[4]} 55%, ${colors[5]} 55%, ${colors[5]} 66%, ${colors[6]} 66%, ${colors[6]} 77%, ${colors[7]} 77%, ${colors[7]} 88%, ${colors[8]} 88%)`,
  })
);

export const SentimentGraphWrapper = styled('div')({
  display: 'flex',
  paddingTop: '35px',
});

export const SentimentGraphVerticalLegend = styled('div')({
  flex: '0 0 40px',
  position: 'relative',
});

export const SentimentChartWrapper = styled('div')({
  flex: '1 1 900px',
  '& svg': {
    overflow: 'visible',
  },
});

export const NetSentimentHeaderWrapper = styled('div')({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  marginBottom: 30,
});

export const TooltipContent = styled('div')({
  maxWidth: 700,
  color: WHITE,
});
