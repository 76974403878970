import { ReactNode } from 'react';

import { withTooltip } from 'util/withTooltip';

import { Category } from 'model/Category';
import { Trend } from 'model/Trend';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { Benchmark } from 'model/Benchmark';
import { OpinionQuestion } from 'model/OpinionQuestion';

export type ReportEngagementDriver = Readonly<{
  score: number;
  details: ReadonlyArray<OpinionQuestionDetails.Combine>;
  category?: Category;
  trends?: ReadonlyArray<Trend>;
  benchmark?: Benchmark;
}>;

export namespace EngagementDriver {
  const CHANGE_LABEL = 'Change';
  const CHANGE_TOOLTIP =
    'This shows how your feedback for this category has changed, based on questions which have been repeated';

  type IsChangeLabel = (label: Trend['label']) => boolean;
  const isChangeLabel: IsChangeLabel = (label) => label === CHANGE_LABEL;

  type GetTrendLabel = (isPulse: boolean, label: Trend['label']) => ReactNode;
  export const getTrendLabel: GetTrendLabel = (isPulse, label) => {
    return isPulse && isChangeLabel(label) ? withTooltip(label, CHANGE_TOOLTIP) : label;
  };

  type MapOpinionQuestionsCombineWithPulse = (
    isPulse: boolean
  ) => (val: OpinionQuestion.Combine) => OpinionQuestion.Combine;

  export const mapOpinionQuestionsCombineWithPulse: MapOpinionQuestionsCombineWithPulse = (
    isPulse
  ) => ({ trends, ...rest }) => ({
    ...rest,
    trends: isPulse ? undefined : trends,
  });

  type NeedLegend = (drivers: ReportEngagementDriver[]) => boolean;
  export const needLegend: NeedLegend = (drivers) => {
    return drivers.some(({ details }) => OpinionQuestionDetails.needExplanation(details));
  };
}
