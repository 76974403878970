import { AxiosInstance } from 'axios';
import { WordCloud } from 'model/WordCloud';

import { WordCloudMapper } from '../mapper/WordCloudMapper';

export namespace WordCloudApi {
  export type Entry = {
    [word: string]: number;
  };
}

export class WordCloudApi {
  constructor(private client: AxiosInstance, private wordCloudMapper: WordCloudMapper) {}

  fetchWordCloud(projectId: number, questionId: number, query: string): Promise<WordCloud> {
    return this.client
      .get(`api/v1/project/${projectId}/report/word-cloud/${questionId}${query}`)
      .then((response) => this.wordCloudMapper.deserialize(response.data));
  }
}
