import { AggregationRange, FilteringState, STORE_NAME } from 'reduxStore/filtering/initialState';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export const filteringSlice = createSlice({
  name: STORE_NAME,
  initialState: FilteringState.INITIAL_DOMAIN,
  reducers: {
    changeAggregationPeriod: (state, action: PayloadAction<Draft<AggregationRange>>) => {
      state.aggregationPeriod = action.payload;
    },
    resetAggregationPeriod: (state) => {
      state.aggregationPeriod = FilteringState.INITIAL_AGGREGATION_PERIOD_DOMAIN;
    },
  },
});

export const { changeAggregationPeriod, resetAggregationPeriod } = filteringSlice.actions;
