import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { ReportSummaryWidgets } from 'view/ReportView/SummaryWidgetReport/ReportSummaryWidgets';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import {
  selectSimpleProjectCombineQuestions,
  selectSimpleProjectCombineResource,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { ReportType } from 'model/ReportType';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';
import { selectHasInsightQuestion } from 'reduxStore/project/selectors';
import { selectInsightsCombinedResource } from 'reduxStore/insights/selectors';
import { Insight } from 'model/Insight';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';

import { InsightsWrapper } from '../AllFeedbackReport.s';

export type AllOnDemandReportPureProps = {
  insightsCombineResource: Resource<Insight.Combine>;
  hasInsightsQuestion: boolean;
  questions: OpinionQuestionDetails.Combine[];
  isAdmin: boolean;
  simpleProjectCombineResource: Resource<any>;
  simpleProjectCompareResource: Resource<any>;
  isCompareMode: Boolean;
};

export const AllOnDemandReportPure: React.FC<AllOnDemandReportPureProps> = (props) => {
  const { isCompareMode, simpleProjectCompareResource, simpleProjectCombineResource } = props;
  const simpleProjectResource = isCompareMode
    ? simpleProjectCompareResource
    : simpleProjectCombineResource;

  return (
    <ResourceLoader resource={simpleProjectResource}>
      <ReportContent
        data-testid="report-content-summary"
        title="Summary"
        component={<ReportSummaryWidgets isReportView hideScore />}
      />
      <ReportContent
        data-testid="report-content-questions"
        title="Survey Questions"
        component={
          <SortableQuestionTable
            data={props.questions}
            isReportView
            shouldRenderCategory={false}
            width={'max-content'}
          />
        }
      />
      {props.hasInsightsQuestion && (
        <ResourceLoader resource={props.insightsCombineResource}>
          <InsightsWrapper>
            <ReportContent
              title="Insights"
              reportType={ReportType.Insights}
              component={
                <>
                  {props.insightsCombineResource.data?.data.map((item, index) => (
                    <InsightsCombinedQuestion key={index} item={item} isReportView />
                  ))}
                </>
              }
            />
          </InsightsWrapper>
        </ResourceLoader>
      )}
    </ResourceLoader>
  );
};

export const AllOnDemandReport: React.FC = () => {
  const hasInsightsQuestion = useSelector(selectHasInsightQuestion);
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const insightsCombineResource = useSelector(selectInsightsCombinedResource);
  const isAdmin = useSelector(selectIsAdmin);
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);
  const isCompareMode = useSelector(selectIsCompareMode);

  return (
    <AllOnDemandReportPure
      hasInsightsQuestion={hasInsightsQuestion}
      questions={questions}
      insightsCombineResource={insightsCombineResource}
      isAdmin={isAdmin}
      simpleProjectCombineResource={simpleProjectCombineResource}
      simpleProjectCompareResource={simpleProjectCompareResource}
      isCompareMode={isCompareMode}
    />
  );
};
