import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectHasMatch } from 'util/selector/routerSelector';

import { fetchDemography } from 'reduxStore/demography/asyncActions';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { ReportRoute } from 'app/route/report';
import { clearDemography } from 'reduxStore/demography/slice';

export const useFetchDemography = () => {
  const dispatch = useAppDispatch();
  const projectIdParam = useSelector(selectCurrentProjectId);
  useEffect(() => {
    if (projectIdParam) {
      dispatch(fetchDemography());
    }
    return () => {
      clearDemography();
    };
  }, [projectIdParam, dispatch]);
};

export const useFetchDemographyReport = () => {
  const dispatch = useAppDispatch();
  const projectIdParam = useSelector(selectCurrentProjectId);

  const isReport = useSelector(selectHasMatch(ReportRoute.REPORT_PATTERN));

  useEffect(() => {
    if (isReport) {
      dispatch(fetchDemography());
    }

    return () => {
      clearDemography();
    };
  }, [projectIdParam, dispatch, isReport]);
};
