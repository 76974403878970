import { Box, styled } from '@mui/material';
import { WHITE } from 'app/theme/colors';

export const LevelWrapper = styled(Box)({
  marginTop: 32,
  maxWidth: 850,
});

export const ReportsWrapper = styled(Box)({
  padding: '28px 25px',
  borderRadius: '8px',
  background: WHITE,
});
