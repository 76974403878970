import { selectReportApiUrl } from 'util/selector/apiSelector';
import {
  selectHasExactMatch,
  selectHasMatch,
  selectQueryParams,
} from 'util/selector/routerSelector';

import { createSelector } from 'reselect';
import { selectCurrentProject, selectCurrentProjectId } from 'reduxStore/project/selectors';
import { AppRoute } from 'app/route/app';
import { ReportRoute } from 'app/route/report';
import { QueryType } from 'model/QueryType';

export const selectIsReportView = selectHasMatch(ReportRoute.REPORT_PATTERN);

export const selectReportProjectId = createSelector(selectQueryParams, (params) =>
  Number(params.projectId)
);

export const selectReportQuestionId = createSelector(selectQueryParams, (params) =>
  Number(params.questionId)
);

export const selectReportOptionId = createSelector(selectQueryParams, (params) =>
  params && params.f && params.f['option'] ? Number(params.f['option']) : null
);

export const selectReportAnswerLike = createSelector(selectQueryParams, (params) =>
  params && params.f && params.f[QueryType.AnswerLike] ? params.f[QueryType.AnswerLike] : null
);

export const selectReportAdvocacy = createSelector(
  selectQueryParams,
  (params) => params?.f?.[QueryType.Advocacy]
);

export const selectReportCategoryId = createSelector(selectQueryParams, (params) =>
  Number(params.categoryId)
);

export const selectReportProjectName = createSelector(
  selectCurrentProject,
  (project) => project && project.name
);

export const selectIsReportsTab = createSelector(
  selectHasExactMatch(AppRoute.Home.REPORTS),
  (match) => match && match
);

export const selectAllReportDownloadUrl = createSelector(
  selectReportApiUrl,
  selectCurrentProjectId,
  (reportApiUrl, projectId) => `${reportApiUrl}/api/v1/project/${projectId}/report`
);

export const selectReportOrCurrentProjectId = createSelector(
  selectIsReportView,
  selectReportProjectId,
  selectCurrentProjectId,
  (isReport, reportProjectId, currentProjectId) => (isReport ? reportProjectId : currentProjectId)
);
