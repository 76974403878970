import React from 'react';
import { useSelector } from 'react-redux';

import {
  downloadAllCommentsPdfReport,
  downloadAllFeedbackPdfReport,
} from 'reduxStore/report/asyncActions';
import { selectHasCommentPagePermission } from 'reduxStore/user/selectors';
import {
  selectHasCommentQuestion,
  selectReportAvailability,
  selectRespondentProtection,
} from 'reduxStore/project/selectors';
import { RespondentProtection } from 'model/RespondentProtection';
import { ReportType } from 'model/ReportType';
import { ReportAvailability } from 'model/ReportAvailability';
import { useAppDispatch } from 'reduxStore/appStore';
import { DownloadReportDisabledButton } from 'view/ReportsPage/DownloadReportDisabledButton/DownloadReportDisabledButton';
import { DownloadReportButton } from 'view/ReportsPage/DownloadReportButton/DownloadReportButton';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import { getReportActionNameByDepartmentId } from 'reduxStore/report/getReportActionName';
import { Typography } from '@mui/material';

import { ButtonsWrapper, DepartmentWrapper } from './DepartmentLevel.s';

export type DepartmentLevelPureProps = DepartmentLevelProps & {
  isAllFeedbackDownloadPending: boolean;
  isAllCommentDownloadPending: boolean;
  reportAvailability?: ReportAvailability;
  hasCommentPagePermission: boolean;
  hasCommentQuestions: boolean;
  respondentProtection: RespondentProtection;
  onAllFeedbackReportDownload: (departmentId: number, departmentName: string) => void;
  onAllCommentReportDownload: (departmentId: number, departmentName: string) => void;
};

export const DepartmentLevelPure: React.FC<DepartmentLevelPureProps> = ({
  departmentName,
  departmentId,
  isAllFeedbackDownloadPending,
  isAllCommentDownloadPending,
  reportAvailability,
  hasCommentPagePermission,
  hasCommentQuestions,
  respondentProtection,
  onAllFeedbackReportDownload,
  onAllCommentReportDownload,
}) => {
  return (
    <DepartmentWrapper data-testid="reportDownloadDepartmentLevel">
      <Typography translate="no" variant="body1">
        {departmentName}
      </Typography>
      <ButtonsWrapper>
        {reportAvailability && reportAvailability.feedback ? (
          <DownloadReportButton
            label="Feedback Report"
            isFeedback
            isPending={isAllFeedbackDownloadPending}
            onClick={() => onAllFeedbackReportDownload(departmentId, departmentName)}
          />
        ) : (
          <DownloadReportDisabledButton
            departmentId={departmentId}
            respondentProtection={respondentProtection}
            reportType={ReportType.Feedback}
          />
        )}
        {hasCommentPagePermission &&
          hasCommentQuestions &&
          (reportAvailability && reportAvailability.comments ? (
            <DownloadReportButton
              label="Comment Report"
              isPending={isAllCommentDownloadPending}
              onClick={() => onAllCommentReportDownload(departmentId, departmentName)}
            />
          ) : (
            <DownloadReportDisabledButton
              departmentId={departmentId}
              respondentProtection={respondentProtection}
              reportType={ReportType.Comments}
            />
          ))}
      </ButtonsWrapper>
    </DepartmentWrapper>
  );
};

type DepartmentLevelProps = {
  departmentName: string;
  departmentId: number;
};

export const DepartmentLevel: React.FC<DepartmentLevelProps> = ({
  departmentId,
  departmentName,
}) => {
  const dispatch = useAppDispatch();
  const reportAvailability = useSelector(selectReportAvailability(departmentId));
  const respondentProtection = useSelector(selectRespondentProtection);
  const hasCommentPagePermission = useSelector(selectHasCommentPagePermission);
  const hasCommentQuestions = useSelector(selectHasCommentQuestion);
  const isAllFeedbackDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByDepartmentId(downloadAllFeedbackPdfReport, { departmentId })
    )
  );
  const isAllCommentDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByDepartmentId(downloadAllCommentsPdfReport, { departmentId })
    )
  );

  const onAllFeedbackReportDownload = (departmentId: number, departmentName: string) =>
    dispatch(downloadAllFeedbackPdfReport({ departmentId, departmentName }));

  const onAllCommentReportDownload = (departmentId: number, departmentName: string) =>
    dispatch(downloadAllCommentsPdfReport({ departmentId, departmentName }));

  return (
    <DepartmentLevelPure
      departmentName={departmentName}
      departmentId={departmentId}
      isAllFeedbackDownloadPending={isAllFeedbackDownloadPending}
      isAllCommentDownloadPending={isAllCommentDownloadPending}
      reportAvailability={reportAvailability}
      hasCommentPagePermission={hasCommentPagePermission}
      hasCommentQuestions={hasCommentQuestions}
      respondentProtection={respondentProtection}
      onAllFeedbackReportDownload={onAllFeedbackReportDownload}
      onAllCommentReportDownload={onAllCommentReportDownload}
    />
  );
};
