import {
  selectModeType,
  selectSorting,
  selectSummaryCategoryViewMode,
} from 'util/selector/querySelector';
import { createFiltersQuery } from 'util/createFiltersQuery';

import { toggleDepartment } from 'reduxStore/departments/asyncActions';
import { selectCanApplyFilter, selectIsFilterSelected } from 'reduxStore/filtering/selectors';
import { Filtering } from 'model/Filtering';
import { DemographyClassification } from 'model/DemographyClassification';
import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';
import {
  selectIsClassificationSelected,
  selectSelectedClassifications,
} from 'reduxStore/demography/selectors';
import { pushQuery } from 'reduxStore/router/asyncActions';
import { ModeType } from 'register/ModeType';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

const toggleClassification = createAsyncThunk<void, DemographyClassification>(
  `${STORE_NAME}/toggleClassification`,
  (classification, { dispatch, getState }) => {
    const selectedDepartments = selectSelectedDepartmentIds(getState());
    const summaryMode = selectModeType(getState());
    let selectedClassifications = selectSelectedClassifications(getState());
    const isSelectedClassification = selectIsClassificationSelected(classification)(getState());
    const categoryMode = selectSummaryCategoryViewMode(getState());
    const sortBy = selectSorting(getState());

    if (isSelectedClassification) {
      selectedClassifications = selectedClassifications.filter(
        (selectedClassification) =>
          !DemographyClassification.hasEqualIds(classification, selectedClassification)
      );
    } else {
      selectedClassifications = [
        ...selectedClassifications,
        { id: classification.id, demographyId: classification.demographyId },
      ];
    }

    dispatch(
      pushQuery({
        query: createFiltersQuery({
          classifications: selectedClassifications,
          departments: selectedDepartments,
          summaryMode,
          categoryMode,
          sortBy,
        }),
      })
    );
  }
);

export const changeFilter = createAsyncThunk<void, Filtering.ChangeFilteringCommand>(
  `${STORE_NAME}/changeFilter`,
  (command, { dispatch, getState }) => {
    const isSelected = selectIsFilterSelected(command)(getState());
    /**
     * Unselecting a filter is always allowed
     */
    if (isSelected) {
      switch (command.type) {
        case 'DEPARTMENT':
          dispatch(toggleDepartment(command.departmentId));
          return;
        case 'DEMOGRAPHY':
          dispatch(toggleClassification(command.classification));
          return;
      }
    }

    const canSelectFilter = selectCanApplyFilter(command)(getState());

    if (canSelectFilter) {
      switch (command.type) {
        case 'DEPARTMENT':
          dispatch(toggleDepartment(command.departmentId));
          break;
        case 'DEMOGRAPHY':
          dispatch(toggleClassification(command.classification));
          break;
      }
    }
  }
);

export const setMode = createAsyncThunk<void, ModeType>(
  `${STORE_NAME}/setMode`,
  (summaryMode, { getState, dispatch }) => {
    const selectedClassifications = selectSelectedClassifications(getState());
    const selectedDepartmentsIds = selectSelectedDepartmentIds(getState());
    const categoryMode = selectSummaryCategoryViewMode(getState());
    const sortBy = selectSorting(getState());

    dispatch(
      pushQuery({
        query: createFiltersQuery({
          classifications: selectedClassifications,
          departments: selectedDepartmentsIds,
          summaryMode,
          categoryMode,
          sortBy,
        }),
      })
    );
  },
  { serializeError }
);
