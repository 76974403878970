import React from 'react';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Box, Typography } from '@mui/material';
import { WHITE } from 'app/theme/colors';

export type NoResultsMessageProps = {
  title: string;
  description?: string;
  hideIcon?: boolean;
  'data-testid'?: string;
};

export const NoResultsMessage: React.FC<NoResultsMessageProps> = ({
  title,
  description,
  hideIcon,
  'data-testid': dataTestId,
}) => {
  return (
    <Box
      data-testid={dataTestId}
      sx={{
        background: WHITE,
        borderRadius: '10px',
        padding: '24px',
        display: 'flex',
      }}
    >
      {!hideIcon && <VisibilityOffOutlinedIcon sx={{ fontSize: 24 }} />}
      <Box ml={!hideIcon ? 2 : 0}>
        <Typography variant="h5" mb={1}>
          {title}
        </Typography>
        {description && <Typography variant="body1">{description}</Typography>}
      </Box>
    </Box>
  );
};
