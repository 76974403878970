import React from 'react';

import {
  ImpactOnEngagementLink,
  ImpactOnEngagementWrapper,
} from 'component/ImpactOnEngagementInfo/ImpactOnEngagementInfo.s';
import { openInNewTab } from 'reduxStore/router/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { IMPACT_ON_ENGAGEMENT_URL } from 'shared/constants/externalLinks';

export type ImpactOnEngagementInfoPureProps = {
  onClick: (url: string) => void;
};

export const ImpactOnEngagementInfoPure: React.FC<ImpactOnEngagementInfoPureProps> = (props) => (
  <ImpactOnEngagementWrapper data-testid="impactOnEngagementInfo">
    <div style={{ color: 'white', paddingBottom: '8px' }}>
      When acting on your feedback, a common mistake is to focus on the lowest scoring questions,
      which normally include pay. These are often "hygiene" factors which rarely drive employee
      engagement the most. Using a statistical algorithm, we pinpoint which questions have the
      biggest impact on motivating and engaging your team members.
    </div>
    <ImpactOnEngagementLink onClick={() => props.onClick(IMPACT_ON_ENGAGEMENT_URL)}>
      Find out more
    </ImpactOnEngagementLink>
  </ImpactOnEngagementWrapper>
);

export const ImpactOnEngagementInfo: React.FC = () => {
  const dispatch = useAppDispatch();

  const onClick = (url: string) => dispatch(openInNewTab(url));

  return <ImpactOnEngagementInfoPure onClick={onClick} />;
};
