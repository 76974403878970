import React, { PropsWithChildren } from 'react';

import { Overflow, ScrollWrapper } from 'component/ScrollBar/ScrollBar.s';
import {
  LIGHT_SCROLL_BACKGROUND,
  LIGHT_SCROLL_TRACK,
  SCROLL_BAR_BACKGROUND,
  SCROLL_BAR_TRACK,
} from 'app/theme/colors';

export namespace Scroll {
  export type Props = {
    overflow: 'vertical' | 'horizontal';
    maxHeight?: number | string;
    maxWidth?: number | string;
    minWidth?: number;
    backgroundColor?: string;
    trackColor?: string;
    width?: number;
    height?: number;
    radius?: number;
    light?: boolean;
    float?: string;
    paddingBottom?: number;
  };
}

export const ScrollBarPure: React.FC<PropsWithChildren<Scroll.Props>> = ({
  maxHeight = 'max-content',
  maxWidth,
  minWidth,
  width = 10,
  height = 10,
  trackColor = SCROLL_BAR_TRACK,
  backgroundColor = SCROLL_BAR_BACKGROUND,
  radius = 10,
  children,
  overflow,
  light,
  float,
  paddingBottom,
}) => (
  <ScrollWrapper
    width={width}
    height={height}
    radius={radius}
    backgroundColor={light ? LIGHT_SCROLL_BACKGROUND : backgroundColor}
    trackColor={light ? LIGHT_SCROLL_TRACK : trackColor}
    overflow={overflow}
    float={float}
  >
    <Overflow
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minWidth={minWidth}
      paddingBottom={paddingBottom}
    >
      {children}
    </Overflow>
  </ScrollWrapper>
);

export const ScrollBar = ScrollBarPure;
