type MinValue = number;
type MaxValue = number;
export type AggregationRange = [MinValue, MaxValue];

export const STORE_NAME = 'filtering';

export namespace FilteringState {
  export type Domain = {
    aggregationPeriod: AggregationRange;
  };

  export const INITIAL_AGGREGATION_PERIOD_DOMAIN: AggregationRange = [-6, 0];
  export const INITIAL_DOMAIN = {
    aggregationPeriod: INITIAL_AGGREGATION_PERIOD_DOMAIN,
  };
}

export type FilteringState = {
  filtering: FilteringState.Domain;
};
