import { Resource } from 'model/Resource';
import { SimpleProjectCombine } from 'model/SimpleProjectCombine';
import { SimpleProjectCompare } from 'model/SimpleProjectCompare';

export const STORE_NAME = 'simpleProject';

export namespace SimpleProjectState {
  export type Domain = {
    combine: Resource<SimpleProjectCombine | null>;
    compare: Resource<SimpleProjectCompare | null>;
  };

  export const INITIAL_DOMAIN_COMBINE = Resource.createPendingResource(null);
  export const INITIAL_DOMAIN_COMPARE = Resource.createPendingResource(null);

  export const INITIAL_DOMAIN: SimpleProjectState.Domain = {
    combine: INITIAL_DOMAIN_COMBINE,
    compare: INITIAL_DOMAIN_COMPARE,
  };
}

export type SimpleProjectState = {
  simpleProject: SimpleProjectState.Domain;
};
