import React from 'react';

import {
  CARDINAL,
  CARDINAL_DARKER,
  CINDERELLA,
  CINDERELLA_DARKER,
  GREEN_HAZE,
  GREEN_HAZE_DARKER,
  PEACH_UMBRELLA,
  WARP_DRIVE,
  WARP_DRIVE_DARKER,
  WORKBUZZ_ORANGE,
} from 'app/theme/colors';
import { Feedback } from 'model/Feedback';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

import { FeedbackBarPart, FeedbackBarWrapper } from './FeedbackBar.s';

export type FeedbackBarProps = {
  data: ReadonlyArray<Feedback>;
  width: number;
  height: number;
};

export const FeedbackBar: React.FC<FeedbackBarProps> = ({ data, width, height }) => {
  const threeColors = [WARP_DRIVE, PEACH_UMBRELLA, CINDERELLA];
  const threeBorderColors = [GREEN_HAZE, WORKBUZZ_ORANGE, CARDINAL];
  const fiveColors = [WARP_DRIVE_DARKER, WARP_DRIVE, PEACH_UMBRELLA, CINDERELLA, CINDERELLA_DARKER];
  const fiveBorderColors = [
    GREEN_HAZE_DARKER,
    GREEN_HAZE,
    WORKBUZZ_ORANGE,
    CARDINAL,
    CARDINAL_DARKER,
  ];

  if (data.length !== 3 && data.length !== 5) {
    return null;
  }

  const colors = data.length === 3 ? threeColors : fiveColors;
  const borderColors = data.length === 3 ? threeBorderColors : fiveBorderColors;

  return (
    <FeedbackBarWrapper data-testid="feedbackBar" width={width} height={height}>
      {data.map((item, index) => {
        if (item.value === 0) {
          return null;
        }

        return (
          <Tooltip
            title={Feedback.getTooltip(item) as string}
            placement="top"
            enterTouchDelay={0}
            arrow
            key={index}
          >
            <FeedbackBarPart
              key={index}
              value={item.value}
              backgroundColor={colors[index]}
              borderColor={borderColors[index]}
            >
              <Typography variant="body2">{item.value}%</Typography>
            </FeedbackBarPart>
          </Tooltip>
        );
      })}
    </FeedbackBarWrapper>
  );
};
