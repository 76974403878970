import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import {
  selectSimpleProjectCombineEmployeesNumber,
  selectSimpleProjectCombineQuestions,
  selectSimpleProjectCombineResource,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { ReportType } from 'model/ReportType';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';
import { selectHasInsightQuestion } from 'reduxStore/project/selectors';
import { selectInsightsCombinedResource } from 'reduxStore/insights/selectors';
import { Insight } from 'model/Insight';
import { LifecycleCharts } from 'component/LifecycleCharts/LifecycleCharts';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';
import { Typography } from '@mui/material';

import { InsightsWrapper } from '../AllFeedbackReport.s';

export type AllLifecycleReportPureProps = {
  insightsCombineResource: Resource<Insight.Combine>;
  hasInsightsQuestion: boolean;
  questions: OpinionQuestionDetails.Combine[];
  responsesNumber: number;
  simpleProjectCombineResource: Resource<any>;
  simpleProjectCompareResource: Resource<any>;
  isCompareMode: Boolean;
};

export const AllLifecycleReportPure: React.FC<AllLifecycleReportPureProps> = ({
  insightsCombineResource,
  hasInsightsQuestion,
  questions,
  responsesNumber,
  simpleProjectCombineResource,
  simpleProjectCompareResource,
  isCompareMode,
}) => {
  const simpleProjectResource = isCompareMode
    ? simpleProjectCompareResource
    : simpleProjectCombineResource;

  const renderInsightsReport = () => (
    <InsightsWrapper>
      <ReportContent
        title="Insights"
        reportType={ReportType.Insights}
        component={
          <>
            {insightsCombineResource.data?.data.map((item, index) => (
              <InsightsCombinedQuestion key={index} item={item} isReportView />
            ))}
          </>
        }
      />
    </InsightsWrapper>
  );

  return (
    <ResourceLoader resource={simpleProjectResource}>
      <div>
        <ReportContent title="Summary" component={<LifecycleCharts />} />
        <ReportContent
          title="Survey Questions"
          component={
            <div>
              <Typography variant="h5">
                Based on feedback from {responsesNumber} employees
              </Typography>

              <SortableQuestionTable
                data={questions}
                width={'max-contentgit'}
                shouldRenderCategory={false}
                isReportView
              />
            </div>
          }
        />
      </div>
      {hasInsightsQuestion && (
        <ResourceLoader resource={insightsCombineResource}>{renderInsightsReport()}</ResourceLoader>
      )}
    </ResourceLoader>
  );
};

export const AllLifecycleReport: React.FC = () => {
  const hasInsightsQuestion = useSelector(selectHasInsightQuestion);
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const insightsCombineResource = useSelector(selectInsightsCombinedResource);
  const responsesNumber = useSelector(selectSimpleProjectCombineEmployeesNumber);
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);
  const isCompareMode = useSelector(selectIsCompareMode);

  return (
    <AllLifecycleReportPure
      hasInsightsQuestion={hasInsightsQuestion}
      questions={questions}
      insightsCombineResource={insightsCombineResource}
      responsesNumber={responsesNumber}
      simpleProjectCombineResource={simpleProjectCombineResource}
      simpleProjectCompareResource={simpleProjectCompareResource}
      isCompareMode={isCompareMode}
    />
  );
};
