import React from 'react';

import { MultiSelectBoxBase } from 'component/SelectBox/MultiSelectBoxBase';
import { CheckboxOption, LeftAlignedOptionItem } from 'component/SelectBox/SelectBox.s';
import { AdvocacyDtoEnum, mapAdvocacyDtoToString } from 'api/dto/Advocacy.dto';
import { useMediaQuery, useTheme } from '@mui/material';

export namespace AdvocacyFilter {
  export type Value = AdvocacyDtoEnum;
  export type Option = Readonly<{
    id: Value;
    label: string;
  }>;

  export type StateProps = {
    selectedValues: Value[];
  };
  export type OwnProps = {
    onChange: (selectedValues: Value[]) => void;
  };
  export type Props = StateProps & OwnProps;
}

export const AdvocacyFilter: React.FC<AdvocacyFilter.Props> = (props) => {
  const optionList: AdvocacyFilter.Option[] = [
    AdvocacyDtoEnum.WouldRecommend,
    AdvocacyDtoEnum.Unsure,
    AdvocacyDtoEnum.WouldNotRecommend,
  ].map((advocacyDto) => ({ id: advocacyDto, label: mapAdvocacyDtoToString(advocacyDto) }));

  const selectedOptions: AdvocacyFilter.Option[] = optionList.filter((option) =>
    props.selectedValues.includes(option.id)
  );

  const handleChange = (options: AdvocacyFilter.Option[]) => {
    props.onChange(options.map((option) => option.id));
  };

  const renderSelected = (selectedOptions: AdvocacyFilter.Option[]) => (
    <span>
      {selectedOptions.length
        ? selectedOptions.length === 1
          ? selectedOptions[0].label
          : `${selectedOptions.length} selected`
        : 'Any Advocacy'}
    </span>
  );

  const compareValue = (option1: AdvocacyFilter.Option, option2: AdvocacyFilter.Option) => {
    return option1.id === option2.id;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const width = isMobile ? '100%' : 230;

  return (
    <MultiSelectBoxBase<AdvocacyFilter.Option>
      onChange={handleChange}
      selected={selectedOptions}
      renderSelected={renderSelected}
      compareValues={compareValue}
      width={width}
      data-testid="advocacyFilter"
    >
      {optionList.map((item) => {
        const isSelected = props.selectedValues.includes(item.id);

        return (
          <LeftAlignedOptionItem
            key={item.id}
            value={item.id}
            label={item.label}
            data-testid={`advocacyFilterOption[${item.id}]`}
            selected={isSelected}
            data-item={item}
          >
            <CheckboxOption
              checked={isSelected}
              variant="LIGHT"
              data-testid="advocacyFilterOptionCheckbox"
            />
            <span data-testid="advocacyFilterOptionLabel">{item.label}</span>
          </LeftAlignedOptionItem>
        );
      })}
    </MultiSelectBoxBase>
  );
};
