import { selectModeType } from 'util/selector/querySelector';

import { createSelector } from 'reselect';
import { selectHasUnrestrictedCrossFilteringPermission } from 'reduxStore/user/selectors';
import { AppState } from 'reduxStore/appStore';
import {
  selectIsDepartmentSelected,
  selectSelectedDepartmentIds,
} from 'reduxStore/departments/selectors';
import {
  selectGroupedClassifications,
  selectIsClassificationSelected,
  selectSelectedClassifications,
} from 'reduxStore/demography/selectors';
import { Filtering } from 'model/Filtering';
import { FilteringState } from 'reduxStore/filtering/initialState';
import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { ModeType } from 'register/ModeType';

export const selectCanApplyFilter = (command: Filtering.ChangeFilteringCommand) =>
  createSelector(
    selectHasUnrestrictedCrossFilteringPermission,
    selectSelectedClassifications,
    selectSelectedDepartmentIds,
    (unrestrictedFiltering, demographics, departmentIds) => {
      if (unrestrictedFiltering) {
        return true;
      }

      return Filtering.canApplyFilter({
        command,
        selectedDemographics: demographics,
        selectedDepartments: departmentIds,
      });
    }
  );

export const selectCanFilterByDepartment = createSelector(
  selectHasUnrestrictedCrossFilteringPermission,
  selectSelectedClassifications,
  (unrestrictedFiltering, demographics) => {
    if (unrestrictedFiltering) {
      return true;
    }

    return demographics.length === 0;
  }
);

export const selectIsFilterSelected = (command: Filtering.ChangeFilteringCommand) => (
  state: AppState
): boolean => {
  switch (command.type) {
    case 'DEPARTMENT':
      return selectIsDepartmentSelected(command.departmentId)(state);
    case 'DEMOGRAPHY':
      return selectIsClassificationSelected(command.classification)(state);
    default:
      throw new Error('UNKNOWN COMMAND TYPE');
  }
};

export function selectDomain(state: AppState): FilteringState.Domain {
  return state.filtering || FilteringState.INITIAL_DOMAIN;
}

export const selectAggregationPeriod = createSelector(
  selectDomain,
  (domain) => domain.aggregationPeriod
);

// Returns true if non-lifecycle project, no demography selected, 0 or 1 department selected
export const selectIsVisibleDownloadAllOption = createSelector(
  selectIsLifecycleProjectKind,
  selectGroupedClassifications,
  selectSelectedDepartmentIds,
  (isLifecycleProjectKind, demographics, departmentIds) =>
    !isLifecycleProjectKind && Object.keys(demographics).length < 1 && departmentIds.length <= 1
);

export const selectSelectedFiltersLength = createSelector(
  selectSelectedDepartmentIds,
  selectSelectedClassifications,
  (departments, demographics) => {
    return departments.length + demographics.length;
  }
);

export const MAXIMUM_SELECTED_FILTERS = 13;
// Should show download PDFs and PPTs;
export const selectShouldShowDownloadButton = createSelector(
  (state) => selectModeType(state),
  selectSelectedFiltersLength,
  (modeType, selectedFiltersLength) =>
    modeType === ModeType.COMBINED || selectedFiltersLength <= MAXIMUM_SELECTED_FILTERS
);
