import { styled } from '@mui/material';
import { WORKBUZZ_ORANGE } from 'app/theme/colors';

export const DividerLine = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: 3,
  paddingLeft: '40px',
  background: WORKBUZZ_ORANGE,
  zIndex: 100,
});

export const Menu = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 25px',
  height: '96px',
}));

export const LogoWrapper = styled('div')(() => ({
  flex: '0 0 auto',
  width: '200px',
  marginTop: '5px',
}));

export const NavigationMenuItemWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
}));
