import { ProjectKind } from 'model/ProjectKind';
import { Moment } from 'moment';
import { ProjectStatus } from 'model/ProjectStatus';
import { Benchmark } from 'model/Benchmark';

/**
 * ProjectSummary is only send for projects with EmployeeEngagement questions inside
 * This can mean less summaries than pulses in project are send from backend
 */
export type ProjectSummary = Readonly<{
  id: number;
  score: number;
  date: Moment;
  benchmark: number;
  status: ProjectStatus;
}>;

export type ProjectSummarySelected = ProjectSummary & {
  selected: boolean;
};

export namespace ProjectSummary {
  export const combineSummariesAndBenchmark = (
    projectSummaries: ProjectSummary[] | undefined,
    benchmark: Benchmark
  ) => {
    if (
      !ProjectSummary.hasProjectSummariesForBenchmark(projectSummaries) ||
      !Benchmark.isNotNone(benchmark)
    ) {
      return undefined;
    }
    return Benchmark.isVsExternalBenchmark(benchmark)
      ? ProjectSummary.getProjectSummariesForVsExternalBenchmark(projectSummaries, benchmark.value)
      : ProjectSummary.getProjectSummariesForVsCompanyAverage(projectSummaries, benchmark.value);
  };

  type MarkSelected = (projectId: number, summaries: ProjectSummary[]) => ProjectSummarySelected[];
  export const markSelected: MarkSelected = (selectedProjectId, summaries) => {
    return summaries.map((summary) => ({
      ...summary,
      selected: summary.id === selectedProjectId,
    }));
  };

  export const hasProjectSummariesForBenchmark = (
    projectSummaries: ProjectSummary[] | undefined
  ): projectSummaries is ProjectSummary[] => {
    return (
      !!projectSummaries &&
      projectSummaries.some(
        (projectSummary) =>
          projectSummary.benchmark !== null &&
          projectSummary.status !== ProjectStatus.IN_DEVELOPMENT
      )
    );
  };

  export const getProjectSummariesForVsExternalBenchmark = (
    projectSummaries: ProjectSummary[],
    externalBenchmarkValue: number
  ): ProjectSummary[] => {
    return projectSummaries.map((item) => ({
      ...item,
      benchmark: externalBenchmarkValue,
    }));
  };

  export const getProjectSummariesForVsCompanyAverage = (
    projectSummaries: ProjectSummary[],
    companyBenchmarkValue: number
  ): ProjectSummary[] => {
    return projectSummaries.map((item) => ({
      ...item,
      benchmark:
        item.status === ProjectStatus.IN_DEVELOPMENT ? companyBenchmarkValue : item.benchmark,
    }));
  };

  export const showPulseSummaryGraph = (pulseSummary: ProjectSummary[]): boolean => {
    // When project status isn't ProjectStatus.IN_DEVELOPMENT we display the graph
    // because questions cannot be changed so we can analyze the engagement scores.
    return !!pulseSummary.length && pulseSummary[0].status !== ProjectStatus.IN_DEVELOPMENT;
  };

  type GetOverrideProjectKind = (showEngagementScore: boolean) => ProjectKind | undefined;
  export const getOverrideProjectKind: GetOverrideProjectKind = (showEngagementScore) =>
    showEngagementScore ? ProjectKind.PROJECT_ENGAGEMENT_SCORE : undefined;
}
