import React from 'react';

import { Props as ChartLabelProps } from 'recharts/types/component/Label';
import { LabelData } from 'view/TopicsPage/Sentiment/sentiment.utils';
import { isNumber } from 'lodash';
import { SONIC_SILVER } from 'app/theme/colors';

export type SentimentLabelProps = ChartLabelProps;

export const SentimentLabel: React.FC<SentimentLabelProps> = (props) => {
  const {
    x,
    y,
    value: { hide, value: name, position },
    width,
    height = 0,
    offset = 1,
  } = props as ChartLabelProps & { value: LabelData };

  if (hide || !isNumber(x) || !isNumber(y) || !isNumber(width) || !isNumber(height)) {
    return null;
  }

  // Define vertical offsets and position inverts based on the value being positive or negative
  const bottomMargin = 10;
  const verticalSign = height >= 0 ? 1 : -1;
  const verticalOffset = verticalSign * offset + bottomMargin;

  const yAttr = position === 'top' ? y - verticalSign * offset : y + height + verticalOffset;

  const positionAttrs = {
    x: x + width / 2,
    y: yAttr,
    textAnchor: 'middle',
    height,
    width,
  };

  return (
    <text {...positionAttrs} fill={SONIC_SILVER} fontSize="13.33px" fontWeight="400">
      {name}
    </text>
  );
};
