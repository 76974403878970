import { styled } from '@mui/material';
import { DARK_ORANGE, FLAG_BLUE, WHITE } from 'app/theme/colors';

export const flagWrapperWidth = 56;

export const FlagWrapper = styled('div')({
  height: 30,
  width: flagWrapperWidth,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 10px',
  borderRadius: 3,
  backgroundColor: FLAG_BLUE,
});

export const TextContent = styled('span')({
  fontSize: 16,
  fontWeight: 'bold',
  color: WHITE,
});

export const TextFlagContent = styled('span')({
  paddingLeft: 10,
  color: DARK_ORANGE,
});
