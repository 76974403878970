import { Option } from 'model/Option';

export class OptionMapper {
  deserialize(entry: any): Option {
    return {
      id: entry.id,
      date: entry.date,
      label: entry.label,
    };
  }
}
