import React from 'react';
import { useSelector } from 'react-redux';

import { ReportType } from 'model/ReportType';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import {
  selectInsightsLifecycleCompared,
  selectInsightsLifecycleComparedNumberOfRespondents,
  selectInsightsLifecycleComparedResource,
} from 'reduxStore/insights/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { InsightsComparedQuestionMatrix } from 'view/InsightsPage/compared/InsightsComparedQuestionMatrix';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { fetchInsightsLifecycleCompared } from 'reduxStore/insights/asyncActions';
import { ReportParamsSelectionMessage } from 'view/ReportView/components/ReportParamsSelectionMessage';

export const InsightsLifecycleComparedReportView: React.FC = () => {
  const insightsLifecycleCompareResource = useSelector(selectInsightsLifecycleComparedResource);
  const insightsLifecycleCompare = useSelector(selectInsightsLifecycleCompared);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const insightsLifecycleCompareEmployeesNumber = useSelector(
    selectInsightsLifecycleComparedNumberOfRespondents
  );

  useFetchInsights(fetchInsightsLifecycleCompared);

  return (
    <ResourceLoader resource={insightsLifecycleCompareResource}>
      <ReportContent
        title="Insights"
        reportType={ReportType.Insights}
        component={
          <>
            <ReportParamsSelectionMessage
              responsesNumber={insightsLifecycleCompareEmployeesNumber}
            />
            {insightsLifecycleCompare?.insights.map((item, index) => (
              <InsightsComparedQuestionMatrix
                isReportView
                isLifecycleProjectKind
                key={index}
                item={item}
                groups={insightsLifecycleCompare.groups}
                minResponses={minResponses}
              />
            ))}
          </>
        }
      />
    </ResourceLoader>
  );
};
