/**
 * Gets values of provided type
 * @example
 * interface A {
 *   a: number;
 *   b: 10;
 * }
 *
 * type ValuesA = Values<A>; // number | 10
 * @example
 * const config = {
 *   name: 'Bob',
 *   age: 22
 * } as const
 *
 * type ConfigValues = Values<typeof config>; // 'Bob' | 22
 * // without this util it's too verbose
 * type WithoutUtil = typeof config[keyof typeof config]
 */
export type Values<T> = Prettify<T[keyof T]>;

/**
 * Helps to read actual type instead of hierarchy or wrappers
 */
export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

/**
 * Typed version of object.hasOwnProperty
 * @param obj - object
 * @param prop - object key
 */
export const hasOwnProperty = <T extends {}, K extends PropertyKey>(
  obj: T,
  prop: K
): obj is T & Record<K, unknown> => obj.hasOwnProperty(prop);
