import React from 'react';

import { Demography } from 'model/Demography';
import { DemographyFilterLabel } from 'component/DemographyFilter/DemographyFilterLabel';
import { DemographyFilterWrapper } from 'component/DemographyFilter/DemographyFilter.s';

export type DemographyFilterProps = {
  data: Demography[];
};

export const DemographyFilter: React.FC<DemographyFilterProps> = ({ data }) => (
  <DemographyFilterWrapper>
    {data.map((item) => (
      <DemographyFilterLabel key={item.id} item={item} />
    ))}
  </DemographyFilterWrapper>
);
