import { User } from 'model/User';

export const STORE_NAME = 'user';

export namespace UserState {
  export type Domain = {
    data: User | null;
  };

  export const INITIAL_DOMAIN: Domain = {
    data: null,
  };
}
