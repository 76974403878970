import React from 'react';
import { useSelector } from 'react-redux';

import { mapDataToCompareMatrix } from 'util/mapDataToCompareMatrix';
import { chunk } from 'util/chunkArray';
import { isArrayEmpty } from 'util/isEmpty';
import { scrollTop } from 'util/scrollTop';
import { selectQueryString } from 'util/selector/routerSelector';

import {
  selectEngagementDriversCompareList,
  selectEngagementDriversCompareListGroups,
  selectEngagementDriversCompareListGroupsLength,
} from 'reduxStore/engagementDrivers/selectors';
import {
  selectCurrentProjectId,
  selectMinResponsesCloseQuestion,
} from 'reduxStore/project/selectors';
import { CompareMatrix } from 'component/CompareMatrix/CompareMatrix';
import { AppRoute } from 'app/route/app';
import { routeWithQuery } from 'reduxStore/router/asyncActions';
import { CompareGroup } from 'model/CompareGroup';
import { setEngagementDriverByCategory } from 'reduxStore/engagementDrivers/asyncActions';
import { OpinionQuestion } from 'model/OpinionQuestion';
import { useAppDispatch } from 'reduxStore/appStore';
import { RespondentProtectionEnumNullable } from 'model/RespondentProtection';

export type EngagementDriverCompareProps = {
  isReportView?: boolean;
};

export type EngagementDriverComparePureProps = {
  title?: string;
  list: OpinionQuestion.Compare[];
  minResponses: number;
  projectId: number;
  query: string;
  groupsLength: number;
  groups: ReadonlyArray<CompareGroup>;
  onCategoryClick: (projectId: number, categoryId: number) => void;
} & EngagementDriverCompareProps;

export const EngagementDriverComparePure: React.FC<EngagementDriverComparePureProps> = (props) => {
  const groups =
    props.groups !== undefined
      ? mapDataToCompareMatrix<string>(props.groups, ['group'], 'group')
      : [];

  const labelsValues = !isArrayEmpty(props.list)
    ? mapDataToCompareMatrix<string>(props.list, ['category', 'name'], 'label')
    : [];

  const labels = labelsValues.map((value) => ({
    content: value,
    contentType: 'percentage',
  }));

  const categoriesId = !isArrayEmpty(props.list)
    ? mapDataToCompareMatrix<number>(props.list, ['category', 'id'], 'label')
    : [];

  const scores = !isArrayEmpty(props.list)
    ? chunk<number>(
        mapDataToCompareMatrix<number>(props.list, ['groups', 'score'], 'data'),
        props.groupsLength
      )
    : [];

  const protectedScore = !isArrayEmpty(props.list)
    ? chunk<RespondentProtectionEnumNullable>(
        mapDataToCompareMatrix<RespondentProtectionEnumNullable>(
          props.list,
          ['groups', 'respondentProtectionType'],
          'data'
        ),
        props.groupsLength
      )
    : [];

  const confidentialityProtection = !isArrayEmpty(props.list)
    ? chunk<boolean>(
        mapDataToCompareMatrix<boolean>(
          props.list,
          ['groups', 'confidentialityProtection'],
          'data'
        ),
        props.groupsLength
      )
    : [];

  const values = scores.map((item, index) => ({
    value: item,
    respondentProtectionTypeArray: protectedScore[index],
    isConfidentialityProtection: confidentialityProtection[index],
  }));

  return (
    <CompareMatrix
      title={props.title}
      data-testid="engagementDriverCompareMatrix"
      departments={groups}
      labels={labels}
      values={values}
      colorType="color"
      minResponses={props.minResponses}
      categoriesId={categoriesId}
      onCategoryClick={(categoryId) => props.onCategoryClick(props.projectId, categoryId)}
      isReportView={props.isReportView}
    />
  );
};

export const EngagementDriverCompare: React.FC<EngagementDriverCompareProps> = (props) => {
  const dispatch = useAppDispatch();
  const list = useSelector(selectEngagementDriversCompareList);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const projectId = useSelector(selectCurrentProjectId);
  const query = useSelector(selectQueryString);
  const groupsLength = useSelector(selectEngagementDriversCompareListGroupsLength);
  const groups = useSelector(selectEngagementDriversCompareListGroups);

  const onCategoryClick = (projectId: number, categoryId: number) => {
    dispatch(
      routeWithQuery({
        pattern: AppRoute.Home.Summary.REPORT,
        params: { id: projectId, categoryId: categoryId },
      })
    );
    dispatch(setEngagementDriverByCategory());
    scrollTop();
  };

  return (
    <EngagementDriverComparePure
      {...props}
      list={list}
      minResponses={minResponses}
      projectId={projectId}
      query={query}
      groupsLength={groupsLength}
      groups={groups}
      onCategoryClick={onCategoryClick}
    />
  );
};
