import { isArrayEmpty } from 'util/isEmpty';
import { selectMatch } from 'util/selector/routerSelector';
import {
  selectHasSelectedMoreThanOneDepartment,
  selectIsCompareMode,
} from 'util/selector/querySelector';
import { selectIsReportView, selectReportCategoryId } from 'util/selector/reportSelector';

import { createSelector } from 'reselect';
import { AppState } from 'reduxStore/appStore';
import { STORE_NAME } from 'reduxStore/engagementDrivers/initialState';
import { AppRoute } from 'app/route/app';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { selectIsOverallScoreShow } from 'reduxStore/project/selectors';
import { selectHasImpactOnEngagementPermission } from 'reduxStore/user/selectors';
import { selectShowImpactOnEngagement } from 'reduxStore/visualSettings/selectors';
import { Benchmark } from 'model/Benchmark';
import { OpinionQuestionCategory } from 'register/OpinionQuestionCategory';
import { OpinionQuestion } from 'model/OpinionQuestion';

export const selectDomain = (state: AppState) => state[STORE_NAME];

export const selectConnectedCategoryId = createSelector(
  selectDomain,
  (domain) => domain.combine.selected?.category?.connectedId
);

export const selectEngagementDriversCombineList = createSelector(
  selectDomain,
  (domain) => domain.combine.list.data || []
);

export const selectEngagementDriversCombineResource = createSelector(
  selectDomain,
  (domain) => domain.combine.list
);

export const selectEngagementDriversCompareList = createSelector(
  selectDomain,
  (domain) => domain.compare.list.data || []
);

export const selectEngagementDriversCompareResource = createSelector(
  selectDomain,
  (domain) => domain.compare.list
);

export const selectHasEngagementDrivers = createSelector(
  selectEngagementDriversCombineList,
  (combineList) =>
    Boolean(
      !isArrayEmpty(combineList)
        ? combineList.find((item) => (item.category ? item.category.id === 10 : false))
        : false
    )
);

export const selectEngagementDriversCompareListFirstCategory = createSelector(
  selectEngagementDriversCompareList,
  (compare) => compare && compare[0]
);

export const selectEngagementDriversCompareListGroups = createSelector(
  selectEngagementDriversCompareListFirstCategory,
  (compare) => compare && compare.groups
);

export const selectEngagementDriversCompareListGroupsLength = createSelector(
  selectEngagementDriversCompareListGroups,
  (compare) => compare && compare.length
);

export const selectEngagementDriversByCategoryCompare = createSelector(
  selectDomain,
  (domain) => domain && (domain.compare.selected as OpinionQuestionDetails.Compare)
);

export const selectEngagementDriversByCategoryCompareFirstQuestion = createSelector(
  selectEngagementDriversByCategoryCompare,
  (compare) => compare && compare.questions[0]
);

export const selectEngagementDriversByCategoryCompareQuestions = createSelector(
  selectEngagementDriversByCategoryCompare,
  (compare) => compare && compare.questions
);

export const selectEngagementDriversQuestions = createSelector(
  selectEngagementDriversCompareList,
  (list) =>
    list.reduce<OpinionQuestion.Compare['questions']>(
      (questions, el) => questions.concat(el.questions),
      []
    )
);

export const selectEngagementDriversByCategoryCompareGroups = createSelector(
  selectEngagementDriversByCategoryCompareFirstQuestion,
  (questions) => questions && questions.groups
);

export const selectEngagementDriversByCategoryCompareGroupsLength = createSelector(
  selectEngagementDriversByCategoryCompareGroups,
  (groups) => groups && groups.length
);

export const selectEngagementDriversCombineQuestionsWithCategories = createSelector(
  selectEngagementDriversCombineList,
  (combineList) =>
    combineList.flatMap((combine) =>
      combine.details.map((details) => ({
        ...details,
        categoryTitle: combine.category?.name,
      }))
    )
);

export const selectEngagementDriverProjectScore = createSelector(
  selectEngagementDriversCombineList,
  (list) =>
    (list.find((el) => el.category?.id === OpinionQuestionCategory.EMPLOYEE_ENGAGEMENT) || {}).score
);

export const selectEngagementDriversByCategoryCombine = createSelector(
  selectDomain,
  (domain) => domain && domain.combine.selected
);

export const selectEngagementDriverTrends = createSelector(
  selectEngagementDriversCombineList,
  (selected) => selected && selected[0] && selected[0].trends
);

export const selectFirstEngagementDriverTrend = createSelector(
  selectEngagementDriverTrends,
  (trends) => trends && trends[0]
);

export const selectTrendLabel = createSelector(selectFirstEngagementDriverTrend, (trend) =>
  trend ? trend.label : ''
);

export const selectCurrentCategoryId = createSelector(
  selectMatch(AppRoute.Home.Summary.REPORT),
  (match) => match && match.params && parseInt(match.params.categoryId, 10)
);

export const selectDetailsByCategoryId = createSelector(
  selectIsReportView,
  selectCurrentCategoryId,
  selectConnectedCategoryId,
  selectReportCategoryId,
  selectIsCompareMode,
  selectEngagementDriversCombineList,
  selectEngagementDriversCompareList,
  (
    isReportView,
    projectCategoryId,
    connectedCategoryId,
    reportCategoryId,
    isCompareMode,
    engagementDriversListCombine,
    engagementDriversListCompare
  ) => {
    const categoryId = isReportView ? reportCategoryId : projectCategoryId;
    const listToSearch: (OpinionQuestion.Combine | OpinionQuestion.Compare)[] = isCompareMode
      ? engagementDriversListCompare
      : engagementDriversListCombine;

    const selectedCategory = listToSearch.find(
      (engagementDriver) => categoryId && categoryId === engagementDriver.category?.id
    );
    const selectedConnectedCategory = listToSearch.find(
      (engagementDriver) =>
        connectedCategoryId && connectedCategoryId === engagementDriver.category?.connectedId
    );
    return selectedCategory || selectedConnectedCategory || null;
  }
);

export const selectEngagementDriversCategoryName = createSelector(
  selectDomain,
  (domain) => domain?.categoryName
);

export const selectShouldShowEngagementScore = createSelector(
  selectHasEngagementDrivers,
  selectIsOverallScoreShow,
  (hasEngagementDriver, isOverallScoreShow) => (isOverallScoreShow ? false : hasEngagementDriver)
);

export const selectShouldShowImpactOnEngagement = createSelector(
  selectHasSelectedMoreThanOneDepartment,
  selectHasImpactOnEngagementPermission,
  selectShowImpactOnEngagement,
  (hasSelectedModeThanOneDepartment, hasPermission, showImpactOnEngagement) =>
    !hasSelectedModeThanOneDepartment && hasPermission && showImpactOnEngagement
);

export const selectHasAnyBenchmark = createSelector(
  selectEngagementDriversCombineList,
  (list) => list && list.some((item) => Benchmark.isNotNone(item.benchmark))
);
