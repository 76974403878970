import React from 'react';

import { scrollTop } from 'util/scrollTop';

import { AppRoute } from 'app/route/app';
import {
  SummaryInfoContent,
  SummaryInfoLink,
  SummaryInfoWrapper,
} from 'view/SummaryPage/SummaryInfo/SummaryInfo.s';
import { routeWithQuery } from 'reduxStore/router/asyncActions';
import { setEngagementDriverByCategory } from 'reduxStore/engagementDrivers/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';

export namespace EngagementProjectInfo {
  export type StateProps = {};
  export type DispatchProps = {
    onViewDetailsClick: (projectId: number, categoryId: number) => void;
  };
  export type OwnProps = {
    projectId: number;
  };
  export type Props = StateProps & DispatchProps & OwnProps;
}

export type EngagementProjectInfoProps = {
  projectId: number;
};

export type EngagementProjectInfoPureProps = {
  projectId: number;
  onViewDetailsClick: (projectId: number, categoryId: number) => void;
};

export const EngagementProjectInfoPure: React.FC<EngagementProjectInfoPureProps> = ({
  projectId,
  onViewDetailsClick,
}) => (
  <SummaryInfoWrapper>
    <SummaryInfoContent>
      This measures employees' emotional connection for working for your organisation,
      <br />
      their plans to stay and motivation to go the extra mile.
    </SummaryInfoContent>
    <br />
    <SummaryInfoLink onClick={() => onViewDetailsClick(projectId, 10)}>
      View Details
    </SummaryInfoLink>
  </SummaryInfoWrapper>
);

export const EngagementProjectInfo: React.FC<EngagementProjectInfoProps> = (props) => {
  const dispatch = useAppDispatch();

  const onViewDetailsClick = (projectId: number, categoryId: number) => {
    dispatch(
      routeWithQuery({
        pattern: AppRoute.Home.Summary.REPORT,
        params: { id: projectId, categoryId: categoryId },
      })
    );
    dispatch(setEngagementDriverByCategory());
    scrollTop();
  };

  return (
    <EngagementProjectInfoPure
      projectId={props.projectId}
      onViewDetailsClick={onViewDetailsClick}
    />
  );
};
