import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsReportView } from 'util/selector/reportSelector';

import { Option } from 'model/Option';
import { useAppDispatch } from 'reduxStore/appStore';
import { OptionItem } from 'component/SelectBox/SelectBox.s';
import { OptionSelectBox } from 'component/SelectBox/OptionSelectBox';
import {
  selectCurrentProjectId,
  selectIsOnDemandProjectKind,
  selectProjectOnDemandPulses,
  selectProjectPulses,
} from 'reduxStore/project/selectors';
import { PulseFiltering } from 'model/PulseFilter';
import { PulseFilterWrapper } from 'component/PulseFilter/PulseFilter.s';
import { changeProject } from 'reduxStore/router/asyncActions';
import { ProjectModel } from 'model/Project.model';
import { SxProps } from '@mui/material';

export type PulseFilterPureProps = {
  options: Option[];
  selected: ProjectModel['id'];
  isReport: boolean;
  isOnDemandProject: boolean;
  onSelect: (id: ProjectModel['id']) => void;
  wrapperSx?: SxProps;
};

const renderSelected = (options: Option[], isOnDemandProject: boolean) => (
  selectedId: Option['id']
) => {
  const selectedOption = options.find(({ id }) => selectedId === id);

  if (!selectedOption) {
    return (
      <span>
        No {isOnDemandProject && 'on demand'} pulse selected - <u>click here</u> to change
      </span>
    );
  }

  if (isOnDemandProject) {
    return <span>{`${selectedOption.label} - ${selectedOption.date}`}</span>;
  }

  return (
    <span>
      Feedback from {selectedOption.date} - <u>Change</u>
    </span>
  );
};

export const PulseFilterPure: React.FC<PulseFilterPureProps> = (props) => {
  return PulseFiltering.showFilter(props.options, props.isReport) ? (
    <PulseFilterWrapper data-testid="pulseFilter" sx={{ ...props.wrapperSx }}>
      <OptionSelectBox
        onChange={props.onSelect}
        initialSelected={props.selected}
        renderSelected={renderSelected(props.options, props.isOnDemandProject)}
        data-testid="projectsList"
      >
        {props.options.map(({ id, date, label }) => (
          <OptionItem key={id} data-testid={id} disabled={id === props.selected} value={id}>
            {label} - {date}
          </OptionItem>
        ))}
      </OptionSelectBox>
    </PulseFilterWrapper>
  ) : null;
};

export type PulseFilterProps = {
  wrapperSx?: SxProps;
};

export const PulseFilter: React.FC<PulseFilterProps> = ({ wrapperSx }) => {
  const dispatch = useAppDispatch();
  const isOnDemandProject = useSelector(selectIsOnDemandProjectKind);
  const projectOnDemandPulses = useSelector(selectProjectOnDemandPulses);
  const projectPulses = useSelector(selectProjectPulses);
  const selected = Number(useSelector(selectCurrentProjectId));
  const isReport = useSelector(selectIsReportView);

  const options = (isOnDemandProject ? projectOnDemandPulses : projectPulses)
    .map((item) => item)
    .sort(PulseFiltering.sortByDate)
    .map(PulseFiltering.pulseToOption);

  const onSelect = (projectId: ProjectModel['id']) => {
    dispatch(changeProject({ projectId }));
  };

  return (
    <PulseFilterPure
      selected={selected}
      options={options}
      isOnDemandProject={isOnDemandProject}
      onSelect={onSelect}
      isReport={isReport}
      wrapperSx={wrapperSx}
    />
  );
};
