import { createSlice, Draft } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import { WordCloud } from 'model/WordCloud';

import { fetchWordCloud } from './asyncActions';
import { STORE_NAME, WordCloudState } from './initialState';

export const wordCloudSlice = createSlice({
  name: STORE_NAME,
  initialState: WordCloudState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    addAsyncCases<WordCloudState.Domain, WordCloud>(builder, fetchWordCloud, (state, action) => {
      const { data, loadingState, error } = action.payload;
      state.data = data as Draft<WordCloud>;
      state.loadingState = loadingState;
      state.error = error;
    });
  },
});
