import { ProjectApi } from 'api/ProjectApi';
import { Settings } from 'model/Settings';

export class ProjectSettingsMapper {
  deserialize(entry: ProjectApi.SettingsEntry): Settings {
    return {
      aggregationMonths: entry.aggregationMonths,
      isOverallScoreShow: !!entry.isOverallScoreShow,
      isThreePointScale: entry.isThreePointScale,
      logo: entry.logo,
      respondentProtection: entry.respondentProtection,
      sentimentAnalysis: entry.sentimentAnalysis,
      shareFeedbackWithLineManager: entry.shareFeedbackWithLineManager,
      showConsentQuestion: entry.showConsentQuestion,
    };
  }
}
