import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { PageHeader } from 'component/PageHeader/PageHeader';
import { PageContentWrapper } from 'shared/styles/layout';
import { EngagementScoreCombinePage } from 'view/EngagementScorePage/EngagementScoreCombinePage';
import { EngagementScoreComparePage } from 'view/EngagementScorePage/EngagementScoreComparePage';
import {
  selectCurrentProjectIsHighPulseFrequency,
  selectCurrentProjectName,
  selectIsPulseOrTemplateProjectKind,
  selectProjectKind,
} from 'reduxStore/project/selectors';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import {
  selectEngagementDriversCombineResource,
  selectEngagementDriversCompareResource,
} from 'reduxStore/engagementDrivers/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import { useFetchEngagementScore } from 'shared/hooks/useFetchEngagementScore';

export const EngagementScorePage: React.FC = () => {
  const projectName = useSelector(selectCurrentProjectName);
  const showHeader = !useSelector(selectIsPulseOrTemplateProjectKind);
  const isCompareMode = useSelector(selectIsCompareMode);
  const projectKind = useSelector(selectProjectKind);
  const engagementDriversCombineResource = useSelector(selectEngagementDriversCombineResource);
  const engagementDriversCompareResource = useSelector(selectEngagementDriversCompareResource);
  const engagementDriversResource = isCompareMode
    ? engagementDriversCompareResource
    : engagementDriversCombineResource;
  const isHighFrequencyPulse = useSelector(selectCurrentProjectIsHighPulseFrequency);

  useFetchEngagementScore();

  const getTitle = () => {
    return showHeader ? projectName : null;
  };

  return (
    <>
      <ResourceLoader
        resource={engagementDriversResource}
        rejected={(error) => (
          <>
            <PageHeader title={getTitle()} isDownloadDisabled />
            <PageContentWrapper>
              <ErrorHandler error={error} />
            </PageContentWrapper>
          </>
        )}
      >
        <PageHeader title={getTitle()} />
        <PageContentWrapper>
          <PulseFilter />
          {isCompareMode && (
            <EngagementScoreComparePage
              projectKind={projectKind}
              isHighFrequencyPulse={isHighFrequencyPulse}
            />
          )}
          {!isCompareMode && <EngagementScoreCombinePage projectKind={projectKind} />}
        </PageContentWrapper>
      </ResourceLoader>
    </>
  );
};
