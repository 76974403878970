import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { ReportFileExtension, ReportFileExtensionType } from 'model/ReportFileExtension';

export const toFileExtension = (type: ReportFormatType): ReportFileExtensionType => {
  const reportFormatToFileExtensionMap = {
    [ReportFormat.Excel]: ReportFileExtension.Excel,
    [ReportFormat.PowerPoint]: ReportFileExtension.PowerPoint,
    [ReportFormat.PDF]: ReportFileExtension.PDF,
    [ReportFormat.ExcelSummary]: ReportFileExtension.Excel,
  };
  return reportFormatToFileExtensionMap[type] ?? ReportFileExtension.Excel;
};
