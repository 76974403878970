import { ProjectKind, RegularProjectKind } from 'model/ProjectKind';
import { PulseApi } from 'api/PulseApi';
import { PulseFrequency } from 'model/PulseFrequency';
import { LifecycleType } from 'model/Lifecycle';
import { ProjectApi } from 'api/ProjectApi';
import { ProjectModel } from 'model/Project.model';
import moment from 'moment';
import { pulseMapper } from 'container/pulseMapper';
import { projectSettingsMapper } from 'container/projectSettingsMapper';

type BaseProject = Readonly<{
  companyName: string;
  id: number;
  name: string;
  settings: ProjectApi.SettingsEntry;
}>;

export type RegularProjectDto = BaseProject &
  Readonly<{
    closedAt: string;
    kind: RegularProjectKind;
    pulseFrequencyId: PulseFrequency | null;
    pulseTemplateId: null;
  }>;

export type OnDemandProjectDto = BaseProject &
  Readonly<{
    closedAt: string;
    kind: ProjectKind.PULSE_ON_DEMAND_PROJECT;
    pulseFrequencyId: PulseFrequency | null;
    pulseTemplateId: number;
    startedAt: string;
  }>;

export type PulseProjectDto = BaseProject &
  Readonly<{
    closedAt: string;
    kind: ProjectKind.PULSE_PROJECT;
    pulseFrequencyId: PulseFrequency;
    pulseTemplateId: number;
    startedAt: string;
  }>;

export type LifecycleProjectDto = BaseProject &
  Readonly<{
    kind: ProjectKind.PULSE_LIFECYCLE_PROJECT;
    lifecycleType: LifecycleType;
    pulseTemplateId: number;
  }>;

export type PulseTemplateProjectDto = BaseProject &
  Readonly<{
    closedAt: string;
    kind: ProjectKind.PULSE_TEMPLATE;
    pulseFrequencyId: PulseFrequency | null;
    pulseLifecycleProjects: PulseApi.Entry[];
    // Latest pulse on demand is last element
    pulseOnDemandProjects: PulseApi.Entry[];
    // Latest pulse project is last element
    pulseProjects: PulseApi.Entry[];
  }>;

export type ProjectDto =
  | RegularProjectDto
  | OnDemandProjectDto
  | PulseProjectDto
  | LifecycleProjectDto
  | PulseTemplateProjectDto;

export const mapProjectDtoToModel = (dto: Readonly<ProjectDto>): ProjectModel => {
  const baseData = {
    companyName: dto.companyName,
    id: dto.id,
    name: dto.name,
    settings: projectSettingsMapper.deserialize(dto.settings),
  };
  if (dto.kind === ProjectKind.PULSE_TEMPLATE) {
    return {
      ...baseData,
      closedAt: moment(dto.closedAt),
      kind: dto.kind,
      pulseFrequencyId: dto.pulseFrequencyId,
      pulseLifecycleProjects: dto.pulseLifecycleProjects.map(pulseMapper.deserialize),
      pulseOnDemandProjects: dto.pulseOnDemandProjects.map(pulseMapper.deserialize),
      pulseProjects: dto.pulseProjects.map(pulseMapper.deserialize),
    };
  }
  if (dto.kind === ProjectKind.PULSE_LIFECYCLE_PROJECT) {
    return {
      ...baseData,
      kind: dto.kind,
      lifecycleType: dto.lifecycleType,
      pulseTemplateId: dto.pulseTemplateId,
      pulseLifecycleProjects: [],
      pulseOnDemandProjects: [],
      pulseProjects: [],
    };
  }
  if (dto.kind === ProjectKind.PULSE_PROJECT) {
    return {
      ...baseData,
      closedAt: moment(dto.closedAt),
      kind: dto.kind,
      pulseFrequencyId: dto.pulseFrequencyId,
      pulseTemplateId: dto.pulseTemplateId,
      startedAt: moment(dto.startedAt),
      pulseLifecycleProjects: [],
      pulseOnDemandProjects: [],
      pulseProjects: [],
    };
  }
  if (dto.kind === ProjectKind.PULSE_ON_DEMAND_PROJECT) {
    return {
      ...baseData,
      closedAt: moment(dto.closedAt),
      kind: dto.kind,
      pulseFrequencyId: dto.pulseFrequencyId,
      pulseTemplateId: dto.pulseTemplateId,
      startedAt: moment(dto.startedAt),
      pulseLifecycleProjects: [],
      pulseOnDemandProjects: [],
      pulseProjects: [],
    };
  }
  // Regular Project
  return {
    ...baseData,
    closedAt: moment(dto.closedAt),
    kind: dto.kind,
    pulseFrequencyId: dto.pulseFrequencyId,
    pulseTemplateId: null,
  };
};
