import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount, useUpdateEffect } from 'react-use';

import { selectCurrentProjectIdFromState } from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectSelectedClassifications } from 'reduxStore/demography/selectors';
import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';
import { clearInsights } from 'reduxStore/insights/slice';
import { AsyncThunk, AsyncThunkConfig } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';

export const useFetchInsights = (fetchInsightsThunk: AsyncThunk<any, void, AsyncThunkConfig>) => {
  const dispatch = useAppDispatch();
  const currentProjectIdFromState = useSelector(selectCurrentProjectIdFromState);
  const selectedClassificationsString = useSelector(selectSelectedClassifications).toString();
  const selectedDepartmentIdsString = useSelector(selectSelectedDepartmentIds).toString();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchInsightsDebounceThunk = useCallback(
    debounce(() => {
      dispatch(fetchInsightsThunk());
    }, FILTERS_CHANGED_DEBOUNCE_TIMEOUT),
    []
  );

  useEffect(() => {
    if (currentProjectIdFromState) {
      dispatch(fetchInsightsThunk());
    }
  }, [dispatch, fetchInsightsThunk, currentProjectIdFromState]);

  useEffect(() => {
    if (!currentProjectIdFromState) {
      dispatch(clearInsights());
    }
  }, [dispatch, currentProjectIdFromState]);

  useUpdateEffect(() => {
    fetchInsightsDebounceThunk();
  }, [dispatch, selectedClassificationsString, selectedDepartmentIdsString]);

  useUnmount(() => {
    dispatch(clearInsights());
  });
};
