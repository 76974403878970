import {
  InsightsQuestionDto,
  InsightsQuestionModel,
} from 'api/dto/new/InsightsCompared/InsightsQuestion.dto';
import {
  InsightsAnswerDto,
  InsightsAnswerModel,
  mapInsightsAnswerDtoToModel,
} from 'api/dto/new/InsightsCompared/InsightsAnswer.dto';
import { RespondentProtectionEnumNullable } from 'model/RespondentProtection';

export type InsightsItemDto = Readonly<{
  question: InsightsQuestionDto;
  answers: ReadonlyArray<InsightsAnswerDto>;
}>;

export type InsightsItemModel = Readonly<{
  question: InsightsQuestionModel;
  answers: ReadonlyArray<InsightsAnswerModel>;
}>;

export const mapInsightsItemDtoToModel = (
  dto: InsightsItemDto,
  protections: Record<string | number, RespondentProtectionEnumNullable> = {}
): InsightsItemModel => ({
  question: dto.question,
  answers: dto.answers.map((answer) => mapInsightsAnswerDtoToModel(answer, protections)),
});
