import { Trend } from 'model/Trend';
import { TrendApi } from 'api/TrendApi';
import moment from 'moment';
export class TrendMapper {
  deserialize(entry: TrendApi.DataEntry, trendQuestion?: TrendApi.QuestionEntry): Trend {
    return {
      label: entry.label,
      diff: entry.diff,
      askedAt: trendQuestion && moment(trendQuestion.trend_asked_at),
    };
  }
}
