import React from 'react';
import { useSelector } from 'react-redux';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import { EngagementDriverCompare } from 'view/EngagementDriversView/EngagementDriverCompare';
import { selectEngagementDriversCompareList } from 'reduxStore/engagementDrivers/selectors';
import { EngagementDriverByCategoryCompare } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCompare/EngagementDriverByCategoryCompare';
import { OpinionQuestion } from 'model/OpinionQuestion';
import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import {
  selectCurrentProjectIsHighPulseFrequency,
  selectProjectClosedAt,
  selectProjectKind,
} from 'reduxStore/project/selectors';
import { Moment } from 'moment';
import { EngagementDriverHighFrequencyPulsesCompare } from 'view/EngagementDriversView/EngagementDriverHighFrequencyPulsesCompare';

export type EngagementScoreReportCompareProps = {
  engagementDrivers: OpinionQuestion.Compare[];
  kind: ProjectKind;
  closedAt?: Moment;
  isHighFrequencyPulse: boolean;
};

export const EngagementScoreReportComparePure: React.FC<EngagementScoreReportCompareProps> = ({
  engagementDrivers,
  kind,
  closedAt,
  isHighFrequencyPulse,
}) => {
  return (
    <div>
      <ReportContent title="Summary" component={<SummaryCompareWidgets isReportView />} />

      {isHighFrequencyPulse && (
        <ReportContent component={<EngagementDriverHighFrequencyPulsesCompare isReportView />} />
      )}

      {!isHighFrequencyPulse && (
        <>
          <ReportContent
            title={Project.getEngagementDriversTitle({
              kind: kind,
              closedAt: closedAt,
            })}
            component={<EngagementDriverCompare isReportView />}
          />
          {engagementDrivers.map((engagementDriver, index) => (
            <ReportContent
              key={index}
              title={engagementDriver.category!.name}
              component={
                <EngagementDriverByCategoryCompare
                  engagementDriverByCategory={engagementDriver}
                  groups={engagementDriver.groups}
                  groupsLength={engagementDriver.groups.length}
                  questions={engagementDriver.questions}
                  isReportView
                />
              }
            />
          ))}
        </>
      )}
    </div>
  );
};

export const EngagementScoreReportCompare = () => {
  const engagementDrivers = useSelector(selectEngagementDriversCompareList);
  const kind = useSelector(selectProjectKind);
  const closedAt = useSelector(selectProjectClosedAt);
  const isHighFrequencyPulse = useSelector(selectCurrentProjectIsHighPulseFrequency);

  return (
    <EngagementScoreReportComparePure
      engagementDrivers={engagementDrivers}
      kind={kind}
      closedAt={closedAt}
      isHighFrequencyPulse={isHighFrequencyPulse}
    />
  );
};
