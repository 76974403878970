import React from 'react';
import { useSelector } from 'react-redux';

import { OverallSurveyReportCompare } from 'view/ReportView/FeedbackReport/Overall/OverallSurveyReportCompare';
import { OverallSurveyReportCombine } from 'view/ReportView/FeedbackReport/Overall/OverallSurveyReportCombine';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import {
  selectOverallSurveyCombineResource,
  selectOverallSurveyCompareResource,
} from 'reduxStore/overallSurvey/selectors';

export type OverallSurveyReportProps = {
  isCompareMode: boolean;
};

export const OverallSurveyReport: React.FC<OverallSurveyReportProps> = ({ isCompareMode }) => {
  const combineResource = useSelector(selectOverallSurveyCombineResource);
  const compareResource = useSelector(selectOverallSurveyCompareResource);
  const resource = isCompareMode ? compareResource : combineResource;

  return (
    <ResourceLoader resource={resource}>
      {isCompareMode ? <OverallSurveyReportCompare /> : <OverallSurveyReportCombine />}
    </ResourceLoader>
  );
};
