import React from 'react';

import { Question } from 'model/Question';
import { TextFlagNew } from 'component/FlagNew/TextFlagNew';
import { SelectBoxBase, SelectBoxBaseProps } from 'component/SelectBox/SelectBoxBase';

export type QuestionSelectBoxProps = Omit<
  SelectBoxBaseProps<Question | undefined>,
  'renderSelected'
>;

export const QuestionSelectBox: React.FC<QuestionSelectBoxProps> = (props) => {
  const renderSelected = (selected?: Question) => {
    if (!selected) return <></>;

    return (
      <span>
        {selected.content} <TextFlagNew isLatest={selected.isLatest} />
      </span>
    );
  };

  return (
    <SelectBoxBase
      {...props}
      renderSelected={renderSelected}
      isBold
      wrapper={Wrapper}
      data-testid="selectedQuestion"
    />
  );
};

const Wrapper: SelectBoxBaseProps<Question | undefined>['wrapper'] = ({ children }) => {
  return <>{children}</>;
};
