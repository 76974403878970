import mediaQuery from 'css-mediaquery';
import type { Values } from 'shared/utils/types';

/**
 * Breakpoint values which are taken from default Material UI Theme
 * If we change them to custom one, these map should be updated
 */
export const BREAKPOINT = {
  Xs: 0,
  Sm: 600,
  Md: 900,
  Lg: 1200,
  Xl: 1536,
} as const;

export const createMatchMedia = (width: Values<typeof BREAKPOINT>) => {
  return (query: string) => {
    return {
      matches: mediaQuery.match(query, { width }),
      addEventListener: () => {},
      removeEventListener: () => {},
    };
  };
};

/**
 * Sets window width
 * @param {BREAKPOINT} width - width in pixels
 * @example
 * describe('MyTests', () => {
 *   beforeAll(() => {
 *     setViewportWidth(BREAKPOINT.Lg);
 *   });
 *   it('should show mobile menu on tabled view', () => {
 *     setViewportWidth(BREAKPOINT.Sm);
 *     // add assert expression
 *   })
 * });
 *
 */
export const setViewportWidth = (width: Values<typeof BREAKPOINT>) => {
  // @ts-ignore
  window.matchMedia = createMatchMedia(width);
};
