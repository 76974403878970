import React from 'react';

import { ErrorHandlerWrapper, ErrorLabelWrapper } from 'component/ErrorHandler/ErrorHandler.s';
import { ErrorRenderer, HttpError } from 'component/ErrorHandler/ErrorRenderer';

export type ErrorHandlerProps = {
  error: HttpError | null;
};

export const ErrorHandler: React.FC<ErrorHandlerProps> = ({ error }) => {
  const isHttpError = Boolean(error?.httpCode);

  return (
    <ErrorHandlerWrapper data-testid="errorHandler">
      {error ? (
        <ErrorRenderer error={error} isHttpError={isHttpError} />
      ) : (
        <ErrorLabelWrapper>Something went wrong. Please refresh the page.</ErrorLabelWrapper>
      )}
    </ErrorHandlerWrapper>
  );
};
