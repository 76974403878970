import { convertObjectToArray } from 'util/convertObjectToArray';
import { isArrayEmpty } from 'util/isEmpty';

import { SimpleProjectApi } from 'api/SimpleProjectApi';
import { BarChartModel } from 'model/BarChart';
import { PieChart } from 'model/PieChart';
import { SimpleProject } from 'model/SimpleProject';

import { CompareGroupMapper } from './CompareGroupMapper';
import { QuestionMapper } from './QuestionMapper';
import { PieChartMapper } from './PieChartMapper';
import { BarChartMapper } from './BarChartMapper';
import { OpinionQuestionDetailsMapper } from './OpinionQuestionDetailsMapper';
import { SummaryMapper } from './SummaryMapper';

export class SimpleProjectMapper {
  constructor(
    private summaryMapper: SummaryMapper,
    private opinionQuestionDetailsMapper: OpinionQuestionDetailsMapper,
    private compareGroupMapper: CompareGroupMapper,
    private questionMapper: QuestionMapper,
    private pieChartMapper: PieChartMapper,
    private barChartMapper: BarChartMapper
  ) {}

  deserializeCombine(entry: SimpleProjectApi.EntryCombine): SimpleProject.Combine {
    return {
      summary: this.summaryMapper.deserializeCombine(entry.summary),
      simpleProject: {
        numberOfEmployees: entry.numberOfEmployees,
        questions:
          entry.questions?.map((item) => this.opinionQuestionDetailsMapper.deserialize(item)) ?? [],
        question: !entry.question ? undefined : this.questionMapper.deserialize(entry.question),
        barChart: entry.barChart
          ?.map((item: SimpleProjectApi.BarChartCombineEntry) =>
            this.barChartMapper.deserializeCombine(item)
          )
          .sort((a: BarChartModel, b: BarChartModel) => b.option - a.option),
        pieChart: entry.pieChart
          ?.map((item: SimpleProjectApi.PieChartCombineEntry) =>
            this.pieChartMapper.deserializeCombine(item)
          )
          .sort((a: PieChart, b: PieChart) => a.type - b.type),
        recommendPlotData: entry.recommendPlotData,
      },
    };
  }

  deserializeCompare(entry: SimpleProjectApi.EntryCompare): SimpleProject.Compare {
    return {
      isCombineModeLock: entry.combineModeLock,
      summary: entry.summary?.map((item) =>
        this.summaryMapper.deserializeCompare(item, entry.groups, entry.protections)
      ),
      simpleProject: {
        numberOfEmployees: entry.numberOfEmployees,
        groups: convertObjectToArray(entry.groups),
        question: !entry.question
          ? undefined
          : this.questionMapper.deserialize(entry.question, entry.groups, entry.protections),
        questions: !isArrayEmpty(entry.questions)
          ? convertObjectToArray(entry.questions).map((item) => ({
              groups: convertObjectToArray(item.byGroup).map((group) =>
                this.compareGroupMapper.deserialize(group, entry.groups, entry.protections)
              ),
              question: this.questionMapper.deserialize(item.question),
            }))
          : [],
        pieChart: !entry.pieChart
          ? undefined
          : entry.pieChart.map((item) =>
              this.pieChartMapper.deserializeCompare(item, entry.groups, entry.protections)
            ),
        barChart: !entry.barChart
          ? undefined
          : entry.barChart.map((item) =>
              this.barChartMapper.deserializeCompare(item, entry.groups, entry.protections)
            ),
      },
    };
  }
}
