import { styled, Typography } from '@mui/material';
import { GRAY_NURSE } from 'app/theme/colors';

export const HeaderRow = styled('tr')({
  borderBottom: `1px ${GRAY_NURSE} solid`,
});

export const HeaderCell = styled('th')({
  padding: '16px 8px',
  textAlign: 'center',
  width: 114,
});

// CellWrapper is required for IE 11
// IE 11 don't process td as correct element po position using relative.
export const CellTextWrapper = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const CellText = styled(Typography)({});
