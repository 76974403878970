import { isArrayEmpty } from 'util/isEmpty';

import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';

import { STORE_NAME } from './initialState';

export const selectDomain = (state: AppState) => state[STORE_NAME];

export const selectSimpleProjectCombineResource = createSelector(
  selectDomain,
  (domain) => domain.combine
);
export const selectSimpleProjectCompareResource = createSelector(
  selectDomain,
  (domain) => domain.compare
);

export const selectSimpleProjectCombine = createSelector(
  selectSimpleProjectCombineResource,
  (resource) => resource?.data
);
export const selectSimpleProjectCompare = createSelector(
  selectSimpleProjectCompareResource,
  (resource) => resource?.data
);

export const selectSimpleProjectCombineQuestions = createSelector(
  selectSimpleProjectCombine,
  (combine) => combine?.questions || []
);

export const selectSimpleProjectCombineEmployeesNumber = createSelector(
  selectSimpleProjectCombine,
  (combine) => combine?.numberOfEmployees || 0
);

export const selectSimpleProjectCompareEmployeesNumber = createSelector(
  selectSimpleProjectCompare,
  (compare) => compare?.numberOfEmployees || 0
);

export const selectHasSimpleProjectCombineQuestions = createSelector(
  selectSimpleProjectCombineQuestions,
  (questions) => questions && !isArrayEmpty(questions)
);

export const selectSimpleProjectCombineQuestion = createSelector(
  selectSimpleProjectCombine,
  (combine) => combine?.question
);

export const selectSimpleProjectCombineQuestionContent = createSelector(
  selectSimpleProjectCombineQuestion,
  (question) => question?.content
);

export const selectSimpleProjectCombineBarChart = createSelector(
  selectSimpleProjectCombine,
  (combine) => combine?.barChart || []
);

export const selectSimpleProjectCombinePieChart = createSelector(
  selectSimpleProjectCombine,
  (combine) => combine?.pieChart || []
);

export const selectSimpleProjectCompareGroups = createSelector(
  selectSimpleProjectCompare,
  (compare) => compare?.groups || []
);

export const selectSimpleProjectCompareQuestions = createSelector(
  selectSimpleProjectCompare,
  (compare) => compare?.questions || []
);

export const selectHasSimpleProjectCompareQuestions = createSelector(
  selectSimpleProjectCompareQuestions,
  (questions) => questions && !isArrayEmpty(questions)
);

export const selectSimpleProjectCompareBarChart = createSelector(
  selectSimpleProjectCompare,
  (compare) => compare?.barChart
);

export const selectSimpleProjectComparePieChart = createSelector(
  selectSimpleProjectCompare,
  (compare) => compare?.pieChart
);

export const selectSimpleProjectCompareQuestion = createSelector(
  selectSimpleProjectCompare,
  (compare) => compare?.question
);

export const selectSimpleProjectCompareQuestionGroups = createSelector(
  selectSimpleProjectCompareQuestion,
  (question) => question?.groups
);

export const selectSimpleProjectCompareQuestionContent = createSelector(
  selectSimpleProjectCompareQuestion,
  (question) => question?.content
);

export const selectSimpleProjectRecommendPlotData = createSelector(
  selectSimpleProjectCombine,
  (simpleProjectCombine) => simpleProjectCombine?.recommendPlotData ?? []
);
