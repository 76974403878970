import { mapDataToCompareMatrix } from 'util/mapDataToCompareMatrix';
import { isArrayEmpty } from 'util/isEmpty';

import { Summary } from 'model/Summary';
import { SummaryCompareWidgetsPureProps } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import {
  mapConfidentialityProtection,
  mapGroups,
  mapProtectedScores,
  mapScores,
  mapValues,
} from 'shared/utils/compareMatrix';

export const mapSummaryCompareWidgetsData = ({
  groups,
  list,
  groupsLength,
  isLifecycleProjectKind,
}: Pick<
  SummaryCompareWidgetsPureProps,
  'groups' | 'list' | 'groupsLength' | 'isLifecycleProjectKind'
>) => {
  const { labels, responseRateIndex } = mapLabels(list);
  const scores = mapScores(list, groupsLength);
  const protectedScores = mapProtectedScores(list, groupsLength);
  const confidentialityProtection = mapConfidentialityProtection(list, groupsLength);
  const values = mapValues(scores, protectedScores, confidentialityProtection);

  if (isLifecycleProjectKind && responseRateIndex >= 0) {
    labels.splice(responseRateIndex, 1);
    values.splice(responseRateIndex, 1);
  }

  return { groups: mapGroups(groups), labels, values };
};

const mapLabels = (list: Summary.Compare[]) => {
  const labelsValues =
    list && !isArrayEmpty(list) ? mapDataToCompareMatrix<string>(list, ['name'], 'label') : [];

  let responseRateIndex = -1;
  const labels = labelsValues.map((value, index) => {
    if (value === 'Response Rate') {
      responseRateIndex = index;
    }
    return { content: value, contentType: index === 0 ? 'number' : 'percentage' };
  });
  return { labels, responseRateIndex };
};
