import React from 'react';
import { useSelector } from 'react-redux';

import { CompareMatrix } from 'component/CompareMatrix/CompareMatrix';
import {
  selectCurrentProjectName,
  selectIsPulseOrTemplateProjectKind,
  selectMinResponsesCloseQuestion,
} from 'reduxStore/project/selectors';
import { CategoryHeader } from 'view/EngagementDriverByCategoryPage/CategoryHeader/CategoryHeader';
import { CategoryWrapper } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/EngagementDriverByCategoryCombine.s';
import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import { CompareGroup } from 'model/CompareGroup';
import { Question } from 'model/Question';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import { mapEngagementDriverByCategoryCompareData } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCompare/mapEngagementDriverByCategoryCompareData';

export type QuestionWithGroup = { question: Question; groups: ReadonlyArray<CompareGroup> };

export type EngagementDriverByCategoryCompareProps = {
  title?: string;
  engagementDriverByCategory: OpinionQuestionDetails.Compare;
  groups: ReadonlyArray<CompareGroup>;
  questions: QuestionWithGroup[];
  groupsLength: number;
  isReportView?: boolean;
};

export type EngagementDriverByCategoryComparePureProps = {
  minResponses: number;
  projectName: string;
  showHeader: boolean;
} & EngagementDriverByCategoryCompareProps;

export const EngagementDriverByCategoryComparePure: React.FC<EngagementDriverByCategoryComparePureProps> = (
  props
) => {
  const { groups, labels, values } = mapEngagementDriverByCategoryCompareData({
    groups: props.groups,
    questions: props.questions,
    groupsLength: props.groupsLength,
  });

  const getMainHeader = () => {
    return props.showHeader ? props.projectName : null;
  };

  return (
    <>
      {!props.isReportView && <CategoryHeader header={getMainHeader()} />}
      <CategoryWrapper isReportView={props.isReportView}>
        {!props.isReportView && (
          <>
            <PulseFilter />
            <SummaryCompareWidgets />
            <CategoryHeader
              categoryName={props.engagementDriverByCategory.category!.name}
              style={{
                height: 40,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderBottom: 'none',
                padding: 0,
              }}
              isDownloadDisabled
            />
          </>
        )}
        <CompareMatrix
          title={props.title}
          departments={groups}
          labels={labels}
          values={values}
          colorType="color"
          minResponses={props.minResponses}
          isReportView={props.isReportView}
        />
      </CategoryWrapper>
    </>
  );
};

export const EngagementDriverByCategoryCompare: React.FC<EngagementDriverByCategoryCompareProps> = (
  props
) => {
  const projectName = useSelector(selectCurrentProjectName);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const showHeader = !useSelector(selectIsPulseOrTemplateProjectKind);

  return (
    <EngagementDriverByCategoryComparePure
      {...props}
      projectName={projectName}
      minResponses={minResponses}
      showHeader={showHeader}
    />
  );
};
