import React from 'react';

import { Trend } from 'model/Trend';
import { StatisticLabel } from 'view/EngagementDriversView/EngagementDriversView.s';
import { Statistics } from 'component/Statistics/Statistics';
import { Benchmark } from 'model/Benchmark';
import { useMediaQuery, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

type StatisticValuesProps = {
  hasAnyBenchmark: boolean;
  benchmark?: Benchmark;
  trends?: ReadonlyArray<Trend>;
  isPulseProjectFamilyKind: boolean;
  index: number;
};

export const StatisticValues: React.FC<StatisticValuesProps> = ({
  hasAnyBenchmark,
  benchmark,
  trends,
  isPulseProjectFamilyKind,
  index,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const shouldShowLabel = isMobile ? false : index < 2;

  const renderBenchmarkValue = () => {
    return (
      benchmark && (
        <li>
          {shouldShowLabel && <StatisticLabel>{benchmark.label}</StatisticLabel>}
          <Tooltip
            title={isMobile ? benchmark.label : ''}
            placement="top"
            enterTouchDelay={0}
            leaveTouchDelay={10000}
            arrow
          >
            <div>
              <Statistics score={benchmark.value} />
            </div>
          </Tooltip>
        </li>
      )
    );
  };

  const renderTrendsDiff = () => {
    return (
      trends &&
      trends.map((trend, index) => {
        if (trend.label !== 'Change' && !isPulseProjectFamilyKind) {
          return (
            <li key={index}>
              {shouldShowLabel && <StatisticLabel>{trend.label}</StatisticLabel>}
              <Tooltip
                title={isMobile ? trend.label : ''}
                placement="top"
                enterTouchDelay={0}
                leaveTouchDelay={10000}
                arrow
              >
                <div>
                  <Statistics score={trend.diff} />
                </div>
              </Tooltip>
            </li>
          );
        }
      })
    );
  };

  const renderBenchmarkValueWithTrendsDiff = () => {
    return (
      <>
        {renderTrendsDiff()}
        {renderBenchmarkValue()}
      </>
    );
  };

  const renderStatistic = () => {
    if (hasAnyBenchmark) {
      return trends ? renderBenchmarkValueWithTrendsDiff() : renderBenchmarkValue();
    }

    return renderTrendsDiff();
  };

  return <>{renderStatistic()}</>;
};
