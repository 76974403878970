import { css } from 'glamor';
import { GREY_BACKGROUND } from 'app/theme/colors';
import { styled } from '@mui/material';

export const pulseSurveyToolTip = css({
  backgroundColor: `${GREY_BACKGROUND} !important`,
  opacity: '0.8 !important',
  '& p': {
    paddingTop: '5px',
  },
  '&::after': {
    borderRightColor: `${GREY_BACKGROUND} !important`,
  },
});

export const BarChartWrapper = styled('div')({
  marginBottom: 10,
});
