import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchCurrentProject, fetchQuestionsStats } from 'reduxStore/project/asyncActions';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { route } from 'reduxStore/router/asyncActions';
import { AppRoute } from 'app/route/app';

import { hasOwnProperty } from '../utils/types';

type WithAxiosError<T> = {
  [P in keyof T]: T[P];
} & { error: AxiosError };

const hasAxiosError = <T extends {}>(data: T): data is WithAxiosError<T> => {
  if (hasOwnProperty(data, 'error')) {
    return axios.isAxiosError(data.error);
  }
  return false;
};

export const useFetchCurrentProject = () => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  useEffect(() => {
    dispatch(fetchCurrentProject()).then((data) => {
      if (hasAxiosError(data)) {
        const status = (data.error.toJSON() as AxiosResponse).status;

        if (status === 404) {
          dispatch(route({ pattern: AppRoute.NOT_FOUND_PROJECT, params: { id: projectId } }));
        }
      }

      return dispatch(fetchQuestionsStats());
    });
  }, [dispatch, projectId]);
};
