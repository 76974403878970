import React from 'react';

import { Typography } from '@mui/material';

import { HeaderLine, HeaderWrapper } from './ReportHeader.s';

export namespace ReportHeader {
  export type Props = {
    title?: string;
    reportType?: string;
  };
}

export const ReportHeader: React.FC<ReportHeader.Props> = (props) => (
  <HeaderWrapper>
    <tr>
      <th style={{ width: '100%' }}>
        <Typography variant="h2">{props.title}</Typography>
        <HeaderLine />
      </th>
    </tr>
  </HeaderWrapper>
);
