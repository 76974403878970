import { AxiosInstance } from 'axios';
import { SummaryApi } from 'api/SummaryApi';
import { QuestionApi } from 'api/QuestionApi';
import { OpinionQuestionApi } from 'api/OpinionQuestionApi';
import { CompareGroupApi } from 'api/CompareGroupApi';
import { SimpleProject } from 'model/SimpleProject';

import { SimpleProjectMapper } from '../mapper/SimpleProjectMapper';

export namespace SimpleProjectApi {
  export type EntryCombine = Readonly<{
    numberOfEmployees?: number;
    summary: SummaryApi.EntryCombine;
    questions: OpinionQuestionApi.DetailsEntry[];
    question?: QuestionApi.Entry;
    barChart?: BarChartCombineEntry[];
    pieChart?: PieChartCombineEntry[];
    recommendPlotData?: AggregatedResponseRateEntry[];
  }>;
  export type EntryCompare = {
    numberOfEmployees?: number;
    summary?: SummaryApi.EntryCompare[];
    questions: OpinionQuestionApi.CompareEntry[];
    question?: QuestionApi.Entry;
    groups: CompareGroupApi.Entry;
    pieChart?: PieChartCompareEntry[];
    barChart?: BarChartCompareEntry[];
    combineModeLock: boolean;
    protections: { [key: number]: boolean };
  };
  export type BarChartCombineEntry = {
    count: number;
    option: number;
    percentage: number;
    type: number;
    label: string;
  };
  export type BarChartCompareEntry = {
    label: string;
    type: number;
    byGroup: ReadonlyArray<{ id: number; percentage: number; confidentialityProtection?: boolean }>;
  };
  export type PieChartCombineEntry = {
    count: number;
    percentage: number;
    type: number;
    label: string;
  };
  export type PieChartCompareEntry = {
    label: string;
    type: number;
    byGroup: ReadonlyArray<{ id: number; percentage: number }>;
  };
  export type AggregatedResponseRateEntry = {
    date: string;
    value: number | null;
  };
}

export class SimpleProjectApi {
  constructor(private client: AxiosInstance, private simpleProjectMapper: SimpleProjectMapper) {}

  combine(projectId: number, query: string): Promise<SimpleProject.Combine> {
    return this.client
      .get<SimpleProjectApi.EntryCombine>(
        `/api/v1/project/${projectId}/report/summary/combined${query}`
      )
      .then((response) => this.simpleProjectMapper.deserializeCombine(response.data))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  compare(projectId: number, query: string): Promise<SimpleProject.Compare> {
    return this.client
      .get(`/api/v1/project/${projectId}/report/summary/compared${query}`)
      .then((response) => this.simpleProjectMapper.deserializeCompare(response.data))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }
}
