import React from 'react';

import { ErrorCode } from 'register/ErrorCode';
import { RespondentProtectionLabel } from 'component/RespondentProtectionLabel/RespondentProtectionLabel';
import { ErrorLabelWrapper } from 'component/ErrorHandler/ErrorHandler.s';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import { NoResultsMessage } from 'component/NoResultsMessage/NoResultsMessage';

export type Error = {
  code?: string;
  message?: string;
};

export type HttpError = Error & {
  httpCode?: number;
};

export type ErrorRendererProps = {
  error: HttpError;
  isHttpError: boolean;
};

export const ErrorRenderer: React.FC<ErrorRendererProps> = ({ error, isHttpError }) => {
  if (isHttpError) {
    return <ErrorLabelWrapper>{error.message}</ErrorLabelWrapper>;
  }

  switch (error.code) {
    case ErrorCode.RESPONDENT_PROTECTION:
      return (
        <>
          <PulseFilter />
          <RespondentProtectionLabel />
        </>
      );
    case ErrorCode.VARIABLE_CONFIDENTIALITY_PROTECTION:
      return (
        <>
          <PulseFilter />
          <RespondentProtectionLabel isVariableRespondentProtection />
        </>
      );
    case ErrorCode.DEPARTMENT_ACCESS_FORBIDDEN:
    case ErrorCode.DEMOGRAPHY_ACCESS_FORBIDDEN:
      // Solution to task WB2-2591
      // Refactor of error handling and pulseFiltering should be made
      // Pulse filter will not show in normal project view
      return (
        <>
          <PulseFilter />
          <NoResultsMessage title="There is no data to display for this survey" hideIcon />
        </>
      );
    case ErrorCode.MATRIX_ACCESS:
      return (
        <>
          <PulseFilter />
          <NoResultsMessage
            data-testid="matrixAccessError"
            title="Please select any department filter on the left-hand side menu to view your
                feedback."
          />
        </>
      );
    default:
      return <ErrorLabelWrapper>Something went wrong. Please refresh the page.</ErrorLabelWrapper>;
  }
};
