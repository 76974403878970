import React from 'react';

import { selectColorBySubmitType } from 'util/selectColorBySubmitType';

import { BarChartModel } from 'model/BarChart';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { BarChart } from 'component/BarChart/BarChart';

export namespace Bars {
  export type Scale = {
    xScale: ScaleBand<string>;
    yScale: ScaleLinear<number, number>;
  };
  export type Props = {
    scales: Scale;
    margin: BarChart.Margin;
    maxValue: number;
    height: number;
    data: BarChartModel[];
  };
}

export const Bars: React.FC<Bars.Props> = (props) => (
  <g>
    {props.data.map((d, index: number) => (
      <rect
        key={index}
        style={{ zIndex: 1000 }}
        x={props.scales.xScale(d.label)}
        y={props.scales.yScale(d.value)}
        height={props.height - props.margin.bottom - props.scales.yScale(d.value)}
        width={props.scales.xScale.bandwidth()}
        fill={selectColorBySubmitType(d.type)}
        data-testid="barRect"
        data-percentage-value={d.value}
        data-tip={`
          <div>
            <p style="color: white; font-size: 14px">${d.value.toFixed(2)}%</p>
          </div>
        `}
      />
    ))}
  </g>
);
