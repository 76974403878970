import { User } from 'model/User';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { userApi } from 'container/userApi';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchUser = createAsyncThunk<User>(
  `${STORE_NAME}/fetchUser`,
  () => userApi.fetchUser(),
  { serializeError }
);

export const getUserPermissions = createAsyncThunk<User, { projectId: number }>(
  `${STORE_NAME}/getUserPermissions`,
  ({ projectId }) => userApi.getUserPermissions(projectId),
  { serializeError }
);
