import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectIsReportView, selectReportProjectId } from 'util/selector/reportSelector';
import { selectFiltersQuery, selectIsCompareMode } from 'util/selector/querySelector';
import { selectQueryStringWithoutUISettings } from 'util/selector/routerSelector';

import { selectCurrentProjectId, selectIsOverallProjectKind } from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { fetchOverallCombine, fetchOverallCompare } from 'reduxStore/overallSurvey/asyncActions';
import { setEngagementDriverByCategory } from 'reduxStore/engagementDrivers/asyncActions';
import { useDebounceValue } from 'shared/hooks/useDebounceValue';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import { ModeType } from 'register/ModeType';

// TODO: remove async usages below and change appropriate DefaultLoader to ResourceLoader when engagementDriver state is migrated to redux-toolkit
export const useFetchOverall = ({ enableDebounce }: { enableDebounce?: boolean } = {}) => {
  const dispatch = useAppDispatch();

  const isReport = useSelector(selectIsReportView);
  const reportProjectId = useSelector(selectReportProjectId);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const projectId = isReport ? reportProjectId : currentProjectId;
  const filtersQuery = useSelector(selectFiltersQuery);
  let queryString = useSelector(selectQueryStringWithoutUISettings);

  const isOverallProjectKind = useSelector(selectIsOverallProjectKind);
  const isCompareMode = useSelector(selectIsCompareMode);

  const _debouncedQueryString = useDebounceValue(queryString, FILTERS_CHANGED_DEBOUNCE_TIMEOUT);
  // TODO
  // Empty query string means 'mode=combined',
  // we should redirect summary page to combined by default without mounting all children components to avoid this situation
  const debouncedQueryString = enableDebounce
    ? _debouncedQueryString || `mode=${ModeType.COMBINED}`
    : '';

  useEffect(() => {
    if (isOverallProjectKind) {
      const fetchOverall = (isCompareMode ? fetchOverallCompare : fetchOverallCombine) as any;

      dispatch(fetchOverall({ projectId, filtersQuery })).then(() =>
        dispatch(setEngagementDriverByCategory())
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverallProjectKind, debouncedQueryString, projectId]);
};
