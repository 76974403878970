import { nodeIncludeString, searchPruneTree } from 'util/tree';
import { groupDepartmentsByLevel } from 'util/groupDepartmentsByLevel';
import { selectQueryParam } from 'util/selector/routerSelector';

import { createSelector } from 'reselect';
import { AppState } from 'reduxStore/appStore';
import memoize from 'fast-memoize';
import { selectFiltersSearchText, selectReportSearchText } from 'reduxStore/search/selectors';
import { ComparisionMatrix } from 'model/ComparisionMatrix';
import { COMPARE_GROUPS_LIMIT_BOLD, COMPARE_GROUPS_LIMIT_DEFAULT } from 'register/ModeType';
import { Department } from 'model/Department';
import { selectSelectedClassifications } from 'reduxStore/demography/selectors';

import { STORE_NAME } from './initialState';

export const selectDomain = (state: AppState) => state[STORE_NAME];

export const selectDepartments = createSelector(
  selectDomain,
  (domain) => domain && domain.list.data
);

export const selectDepartmentsLoadingState = createSelector(
  selectDomain,
  (domain) => domain && domain.list.loadingState
);

export const selectSearchedDepartments = memoize(
  createSelector(selectDepartments, selectFiltersSearchText, (departments, query) =>
    departments
      .map((department) => searchPruneTree(department, nodeIncludeString(query)))
      .filter((department) => department !== null)
  )
);

export const selectSelectedDepartmentIds = createSelector(
  selectQueryParam('f'),
  (queryParam: any): number[] => {
    const departmentIds: string[] = Array.isArray(queryParam?.d)
      ? queryParam.d
      : Object.keys(queryParam?.d || {}).map((key) => queryParam.d[key]);

    return departmentIds.map((departmentId: string) => Number(departmentId));
  }
);

export const selectIsDepartmentSelected = (departmentId: number) =>
  createSelector(
    selectSelectedDepartmentIds,
    (selectedDepartmentsIds) => selectedDepartmentsIds.indexOf(departmentId) !== -1
  );

export const selectReportSelectedDepartmentId = createSelector(
  selectQueryParam('f'),
  (queryParam: any) => queryParam && Number(queryParam.d)
);

export const selectDepartmentsFlatList = createSelector(
  selectDepartments,
  selectReportSelectedDepartmentId,
  (departments) => departments && Department.flattenDepartments(departments)
);

export const selectReportDepartment = createSelector(
  selectReportSelectedDepartmentId,
  selectDepartmentsFlatList,
  (departmentId, departments) =>
    departments && departments.find((department) => department.id === departmentId)
);

export const selectGroupedDepartmentsByLevel = createSelector(
  selectDepartments,
  (departments) => departments && groupDepartmentsByLevel(departments)
);

export const selectReportSearchedDepartments = memoize(
  createSelector(selectGroupedDepartmentsByLevel, selectReportSearchText, (departments, query) =>
    departments.map((level) =>
      level.filter((department) =>
        department.name.toLocaleLowerCase().trim().includes(query.toLocaleLowerCase().trim())
      )
    )
  )
);

export const selectTableBreadth = createSelector(
  selectSelectedDepartmentIds,
  selectSelectedClassifications,
  (selectedDepartmentIds, selectedClassifications): ComparisionMatrix.Breadth => {
    const itemsLength = Math.max(selectedDepartmentIds.length, selectedClassifications.length);

    if (!itemsLength) {
      return 'bold';
    }

    if (itemsLength <= COMPARE_GROUPS_LIMIT_BOLD) {
      return 'bold';
    }

    if (itemsLength <= COMPARE_GROUPS_LIMIT_DEFAULT) {
      return 'default';
    }

    return 'narrow';
  }
);
