import { mapDataToCompareMatrix } from 'util/mapDataToCompareMatrix';

import {
  mapConfidentialityProtection,
  mapGroups,
  mapProtectedScores,
  mapScores,
  mapValues,
} from 'shared/utils/compareMatrix';
import {
  EngagementDriverByCategoryCompareProps,
  QuestionWithGroup,
} from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCompare/EngagementDriverByCategoryCompare';

export const mapEngagementDriverByCategoryCompareData = ({
  groups,
  questions,
  groupsLength,
}: Pick<EngagementDriverByCategoryCompareProps, 'groups' | 'questions' | 'groupsLength'>) => {
  const labels = mapLabels(questions);
  const scores = mapScores(questions, groupsLength);
  const protectedScores = mapProtectedScores(questions, groupsLength);
  const confidentialityProtection = mapConfidentialityProtection(questions, groupsLength);
  const values = mapValues(scores, protectedScores, confidentialityProtection);

  return { groups: mapGroups(groups), labels, values };
};

const mapLabels = (questions: QuestionWithGroup[]) => {
  const labelsValues = mapDataToCompareMatrix<string>(questions, ['question', 'content'], 'label');

  const isLatestQuestions = mapDataToCompareMatrix<boolean>(
    questions,
    ['question', 'isLatest'],
    'label'
  );

  return labelsValues.map((value, index) => ({
    content: value,
    isLatest: isLatestQuestions[index],
    contentType: 'percentage',
  }));
};
