import { selectErrorData } from 'util/selector/axiosSelector';

import { AxiosError } from 'axios';
import { getAppConfig } from 'app/getConfig';

export const selectReportApiUrl = () => getAppConfig().REPORT_API_CLIENT_URL;
export const selectAuthApiUrl = () => getAppConfig().AUTH_API_CLIENT_URL;
export const selectAuthUiUrl = () => getAppConfig().AUTH_UI_URL;

type ApiError = {
  code: string;
  message: string;
  [key: string]: any;
};

export const selectApiError = (error: AxiosError): ApiError => selectErrorData(error, 'errors[0]');
