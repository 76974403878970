export const STORE_NAME = 'search';

export namespace SearchState {
  export type Domain = {
    filtersSearchText: string;
    reportSearchText: string;
  };

  export const INITIAL_DOMAIN = {
    filtersSearchText: '',
    reportSearchText: '',
  };
}
