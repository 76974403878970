import React from 'react';

import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import { EngagementDriverCompare } from 'view/EngagementDriversView/EngagementDriverCompare';
import { ProjectKind } from 'model/ProjectKind';
import { EngagementDriverHighFrequencyPulsesCompare } from 'view/EngagementDriversView/EngagementDriverHighFrequencyPulsesCompare';

export namespace EngagementScoreComparePage {
  export type Props = {
    projectKind: ProjectKind;
    isHighFrequencyPulse: boolean;
  };
}

export const EngagementScoreComparePage: React.FC<EngagementScoreComparePage.Props> = (
  props: EngagementScoreComparePage.Props
) => {
  const selectSummaryView = (kind: ProjectKind) => {
    switch (kind) {
      case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
      case ProjectKind.PROJECT_NET_PROMOTER_SCORE:
      case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      case ProjectKind.PROJECT_OVERALL_SCORE:
      case ProjectKind.PULSE_PROJECT:
      case ProjectKind.PULSE_ON_DEMAND_PROJECT:
        return <SummaryCompareWidgets />;
      default:
        return undefined;
    }
  };

  return (
    <>
      {selectSummaryView(props.projectKind)}
      {props.isHighFrequencyPulse ? (
        <EngagementDriverHighFrequencyPulsesCompare />
      ) : (
        <EngagementDriverCompare />
      )}
    </>
  );
};
