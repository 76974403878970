import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { PageContentWrapper } from 'shared/styles/layout';
import { fetchSimpleProject } from 'reduxStore/simpleProject/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { LifecycleCombinePage } from 'view/LifecyclePage/LifecycleCombinePage';
import { useAppParams } from 'shared/hooks/useAppParams';
import { LifecycleComparePage } from 'view/LifecyclePage/LifecycleComparePage';
import { LifecycleHeader } from 'view/LifecyclePage/LifecycleHeader/LifecycleHeader';
import {
  selectSimpleProjectCombine,
  selectSimpleProjectCompare,
} from 'reduxStore/simpleProject/selectors';

export const LifecyclePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const isCompareMode = useSelector(selectIsCompareMode);
  const { projectId: lifecycleProjectId } = useAppParams();
  const simpleProjectCombine = useSelector(selectSimpleProjectCombine);
  const simpleProjectCompare = useSelector(selectSimpleProjectCompare);
  const isDownloadDisabled =
    (isCompareMode && !simpleProjectCompare) || (!isCompareMode && !simpleProjectCombine);

  useEffect(() => {
    if (lifecycleProjectId) {
      dispatch(fetchSimpleProject());
    }
    // TODO Aggregation should be in deps, react on changes from CHANGE_AGGREGATION_PERIOD
  }, [dispatch, lifecycleProjectId]);

  return (
    <>
      <LifecycleHeader isDownloadDisabled={isDownloadDisabled} />
      <PageContentWrapper>
        {isCompareMode ? <LifecycleComparePage /> : <LifecycleCombinePage />}
      </PageContentWrapper>
    </>
  );
};
