export enum AdvocacyDtoEnum {
  WouldRecommend = 1,
  Unsure = 0,
  WouldNotRecommend = -1,
}

export enum AdvocacyModelEnum {
  WouldRecommend = 'Would recommend',
  Unsure = 'Unsure',
  WouldNotRecommend = 'Would not recommend',
}

export type AdvocacyDto = AdvocacyDtoEnum | null;

export type AdvocacyModel = AdvocacyModelEnum | null;

export const mapAdvocacyDtoToModel = (dto: AdvocacyDto): AdvocacyModel => {
  switch (dto) {
    case AdvocacyDtoEnum.WouldRecommend:
      return AdvocacyModelEnum.WouldRecommend;
    case AdvocacyDtoEnum.Unsure:
      return AdvocacyModelEnum.Unsure;
    case AdvocacyDtoEnum.WouldNotRecommend:
      return AdvocacyModelEnum.WouldNotRecommend;
    default:
      return null;
  }
};

export const mapAdvocacyDtoToString = (dto: AdvocacyDto): string =>
  mapAdvocacyDtoToModel(dto) || 'Any advocacy';
