export const ReportFormat = {
  Excel: 'excel',
  PDF: 'pdf',
  PowerPoint: 'power_point',
  ExcelSummary: 'excel&only_summary=1',
} as const;

export type ReportFormatType = typeof ReportFormat[keyof typeof ReportFormat];

export const mapReportFormatToAcceptHeader = (reportFormat: ReportFormatType): string => {
  const reportFormatToAcceptHeaderMap: Record<ReportFormatType, string> = {
    [ReportFormat.Excel]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    [ReportFormat.ExcelSummary]:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    [ReportFormat.PDF]: 'application/pdf',
    [ReportFormat.PowerPoint]: 'application/vnd.ms-powerpoint',
  };
  return reportFormatToAcceptHeaderMap[reportFormat];
};
