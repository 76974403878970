import { TopicsApi } from 'api/TopicsApi';
import { topicsApi } from 'container/topicsApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { STORE_NAME } from 'reduxStore/topics/initialState';
import { serializeError } from 'shared/utils/redux';

export const fetchProjectTopics = createAsyncThunk<
  TopicsApi.ProjectEntry,
  { projectId: number; query: string }
>(
  `${STORE_NAME}/fetchProjectTopics`,
  async ({ projectId, query }) => {
    return topicsApi.getProjectTopics(projectId, query);
  },
  { serializeError }
);
