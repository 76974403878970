import { createSlice, Draft } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import { Resource } from 'model/Resource';
import { Summary } from 'model/Summary';
import {
  fetchSimpleProjectCombine,
  fetchSimpleProjectCompare,
} from 'reduxStore/simpleProject/asyncActions';
import {
  fetchEngagementScoreCombine,
  fetchEngagementScoreCompare,
} from 'reduxStore/engagementDrivers/asyncActions';
import { fetchOverallCombine, fetchOverallCompare } from 'reduxStore/overallSurvey/asyncActions';

import { STORE_NAME, SummaryState } from './initialState';
import { fetchPulsesSummary } from './asyncActions';

export const summarySlice = createSlice({
  name: STORE_NAME,
  initialState: SummaryState.INITIAL_DOMAIN,

  reducers: {
    clearSummary: (state) => {
      state.combine = SummaryState.INITIAL_DOMAIN_COMBINE as Draft<Resource<Summary.Combine>>;
      state.compare = SummaryState.INITIAL_DOMAIN_COMPARE as Draft<Resource<Summary.Compare[]>>;
      state.pulses = SummaryState.INITIAL_DOMAIN_PULSES as Draft<Resource<Summary.Pulses>>;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(
      builder,
      fetchPulsesSummary,
      (state, action) => {
        state.pulses = action.payload as Draft<Resource<Summary.Pulses>>;
      },
      SummaryState.INITIAL_DOMAIN_PULSES.data
    );

    builder.addMatcher(
      (action) =>
        [
          fetchSimpleProjectCombine.fulfilled.type,
          fetchEngagementScoreCombine.fulfilled.type,
          fetchOverallCombine.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.combine = Resource.createResolvedResource({ data: action.payload.summary });
      }
    );

    builder.addMatcher(
      (action) =>
        [
          fetchSimpleProjectCompare.fulfilled.type,
          fetchEngagementScoreCompare.fulfilled.type,
          fetchOverallCompare.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.compare = Resource.createResolvedResource({ data: action.payload.summary });
      }
    );
  },
});

export const { clearSummary } = summarySlice.actions;
