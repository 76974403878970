import {
  InsightsItemDto,
  InsightsItemModel,
  mapInsightsItemDtoToModel,
} from 'api/dto/new/InsightsCompared/InsightsItem.dto';

export type InsightsLifecycleComparedDto = Readonly<{
  groups: Readonly<Record<string | number, string>>;
  insights: ReadonlyArray<InsightsItemDto>;
  numberOfRespondents: number;
}>;

export type InsightsLifecycleComparedModel = Readonly<{
  groups: Readonly<Record<string | number, string>>;
  insights: ReadonlyArray<InsightsItemModel>;
  numberOfRespondents: number;
}>;

export const mapInsightsLifecycleComparedDtoToModel = (
  dto: InsightsLifecycleComparedDto
): InsightsLifecycleComparedModel => ({
  groups: dto.groups,
  insights: dto.insights.map((insight) => mapInsightsItemDtoToModel(insight)),
  numberOfRespondents: dto.numberOfRespondents,
});
