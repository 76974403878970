import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectInsightsLifecycleCombined,
  selectInsightsLifecycleCombinedNumberOfRespondents,
  selectInsightsLifecycleCombinedResource,
} from 'reduxStore/insights/selectors';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';
import { PeriodSelectionSlider } from 'component/PeriodSelectionSlider/PeriodSelectionSlider';
import { DownloadMonthlyBreakdownButton } from 'component/DownloadMonthlyBreakdownButton/DownloadMonthlyBreakdownButton';
import { MonthlyBreakdownReport } from 'model/MonthlyBreakdownReport';
import Typography from '@mui/material/Typography';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { fetchInsightsLifecycleCombined } from 'reduxStore/insights/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import { NoResultsMessage } from 'component/NoResultsMessage/NoResultsMessage';

export const InsightsLifecycleCombinedPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const insightsLifecycleCombineResource = useSelector(selectInsightsLifecycleCombinedResource);
  const insightsLifecycleCombine = useSelector(selectInsightsLifecycleCombined);
  const insightsLifecycleCombineEmployeesNumber = useSelector(
    selectInsightsLifecycleCombinedNumberOfRespondents
  );

  useFetchInsights(fetchInsightsLifecycleCombined);

  const handleChangeCommitted = () => {
    dispatch(fetchInsightsLifecycleCombined());
  };

  return (
    <ResourceLoader
      resource={insightsLifecycleCombineResource}
      rejected={(error) => (
        <>
          <PeriodSelectionSlider onChangeCommitted={handleChangeCommitted} />
          <ErrorHandler error={error} />
        </>
      )}
    >
      <>
        <PeriodSelectionSlider onChangeCommitted={handleChangeCommitted} />

        {insightsLifecycleCombine?.data?.length === 0 && (
          <NoResultsMessage
            title="For this pulse survey, we asked no insights questions for the group you are viewing feedback for."
            hideIcon
          />
        )}

        {Array.isArray(insightsLifecycleCombine?.data) && insightsLifecycleCombine.data.length > 0 && (
          <>
            <Typography variant="h4" sx={{ mb: '32px' }}>
              Based on feedback from {insightsLifecycleCombineEmployeesNumber} employees:
            </Typography>
            {insightsLifecycleCombine.data.map((item, index) => (
              <InsightsCombinedQuestion key={index} item={item} isLifecycleProjectKind />
            ))}
            <DownloadMonthlyBreakdownButton
              monthlyBreakdownReport={MonthlyBreakdownReport.Insights}
            />
          </>
        )}
      </>
    </ResourceLoader>
  );
};
