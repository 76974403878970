import { createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'shared/utils/redux';
import { overallSurveyApi } from 'container/overallSurveyApi';
import { OverallSurvey } from 'model/OverallSurvey';

import { STORE_NAME } from './initialState';

export const fetchOverallCombine = createAsyncThunk<
  OverallSurvey.Combine,
  { projectId: number; filtersQuery: string }
>(
  `${STORE_NAME}/fetchOverallCombine`,
  ({ projectId, filtersQuery }) => {
    return overallSurveyApi.combine(projectId, filtersQuery);
  },
  { serializeError }
);

export const fetchOverallCompare = createAsyncThunk<
  OverallSurvey.Compare,
  { projectId: number; filtersQuery: string }
>(
  `${STORE_NAME}/fetchOverallCompare`,
  ({ projectId, filtersQuery }) => {
    return overallSurveyApi.compare(projectId, filtersQuery);
  },
  { serializeError }
);
