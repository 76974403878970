import { AxiosInstance } from 'axios';
import { PaginatedResponse } from 'model/PaginatedResponse';
import { FetchRespondentsProps } from 'reduxStore/respondent/asyncActions';
import qs from 'qs';
import { QueryType } from 'model/QueryType';
import { mapRespondentDtoToModel, RespondentDto, RespondentModel } from 'api/dto/Respondent.dto';
import {
  mapRespondentIndividualFeedbackDtoToModel,
  RespondentIndividualFeedbackDto,
  RespondentIndividualFeedbackModel,
} from 'api/dto/RespondentIndividualFeedback.dto';

import { PaginationMapper } from '../mapper/PaginationMapper';

export class IndividualFeedbackApi {
  constructor(private client: AxiosInstance) {}

  fetchRespondents({
    advocacyFilter,
    classifications,
    departments,
    limit,
    page,
    projectId,
    respondentLikeFilter,
  }: FetchRespondentsProps): Promise<PaginatedResponse<RespondentModel[]>> {
    const query = qs.stringify(
      {
        [QueryType.Filter]: {
          [QueryType.Advocacy]: advocacyFilter,
          [QueryType.Classification]: classifications,
          [QueryType.Department]: departments,
          [QueryType.RespondentLike]: respondentLikeFilter,
        },
        [QueryType.Pagination]: {
          index: page,
          length: limit,
        },
      },
      { encode: false, addQueryPrefix: true }
    );

    return this.client
      .get<PaginatedResponse<RespondentDto[]>>(
        `/api/v1/project/${projectId}/report/individual-feedback${query}`
      )
      .then(({ data: { data: responseData, pagination } }) => ({
        data: responseData.map(mapRespondentDtoToModel),
        pagination: PaginationMapper.deserialize(pagination),
      }))
      .catch((error) => {
        throw error.response?.data.error || error;
      });
  }

  fetchRespondentIndividualFeedback({
    projectId,
    respondentIds,
  }: {
    projectId: string;
    respondentIds: string[];
  }): Promise<RespondentIndividualFeedbackModel[]> {
    const query = qs.stringify(
      { [QueryType.Filter]: { respondentIds } },
      { encode: false, addQueryPrefix: true }
    );

    return this.client
      .get<{ data: RespondentIndividualFeedbackDto[] }>(
        `/api/v1/project/${projectId}/report/individual-feedback-personal${query}`
      )
      .then(({ data: { data } }) =>
        data.map<RespondentIndividualFeedbackModel>(mapRespondentIndividualFeedbackDtoToModel)
      );
  }
}
