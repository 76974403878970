import React from 'react';

import { selectColorBySubmitType } from 'util/selectColorBySubmitType';

import * as d3 from 'd3';
import { Slice } from 'component/PieChart/Slice';
import { PieChart } from 'model/PieChart';

export namespace Pie {
  export type Props = {
    data: PieChart[];
    x: number;
    y: number;
    radius: number;
  };
}

export const Pie: React.FC<Pie.Props> = (props) => {
  const pie = d3.pie<PieChart>().value((item: PieChart) => item.value);

  return (
    <g transform={`translate(${props.x}, ${props.y})`}>
      {pie(props.data).map((item, index) => (
        <Slice
          key={index}
          outerRadius={props.radius}
          item={item}
          fill={selectColorBySubmitType(item.data.type)}
        />
      ))}
    </g>
  );
};
