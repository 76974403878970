import { isArrayEmpty } from 'util/isEmpty';
import { treeToFlatListForSelector } from 'util/flattenTreeWithExpansion';
import {
  selectHasMatch,
  selectQueryParam,
  selectQueryParamObject,
} from 'util/selector/routerSelector';

import { createSelector } from 'reselect';
import {
  selectDemography,
  selectGroupedClassifications,
  selectSelectedClassifications,
} from 'reduxStore/demography/selectors';
import {
  selectSearchedDepartments,
  selectSelectedDepartmentIds,
} from 'reduxStore/departments/selectors';
import qs from 'qs';
import { FILTER_COUNT_COMPARE_MODE, ModeType, SummaryCategoryViewMode } from 'register/ModeType';
import {
  selectAdvocacyFilter,
  selectPaginationItemCount,
  selectPaginationPage,
  selectSearchedText,
  selectSelectedOptionId,
  selectSentimentFilter,
  selectTopicFilter,
} from 'reduxStore/comments/selectors';
import { AppRoute } from 'app/route/app';
import { ReportRoute } from 'app/route/report';
import { QueryType } from 'model/QueryType';
import { ReportCategoryQuery } from 'api/ReportCategory.query';
import { queryDto } from 'api/dto/Query.dto';
import { CompilePathQuery } from 'router/compilePath';
import { Department } from 'model/Department';
import { selectAggregationPeriod } from 'reduxStore/filtering/selectors';
import moment from 'moment';
import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { AppState } from 'reduxStore/appStore';
import {
  ColumnName,
  OrderBy,
  SortBy,
} from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/utils';

import {
  selectIsReportView,
  selectReportAdvocacy,
  selectReportAnswerLike,
  selectReportOptionId,
} from './reportSelector';

export const selectModeType = createSelector(selectQueryParamObject(QueryType.Mode), (query) =>
  query === ModeType.COMPARED ? ModeType.COMPARED : ModeType.COMBINED
);

export const selectFiltersQuery = createSelector(
  selectGroupedClassifications,
  selectSelectedDepartmentIds,
  (classifications, departments) =>
    '?' +
    qs.stringify(
      {
        [QueryType.Filter]: {
          [QueryType.Classification]: classifications,
          [QueryType.Department]: departments,
        },
      },
      { encode: false }
    )
);

export const aggregationRelativeDateToQueryFormat = (value: number) =>
  moment().add(value, 'M').format('YYYY-MM');

export const selectSliderDatesFromQueryParams = createSelector(
  selectQueryParamObject(QueryType.Filter),
  (filter) => {
    if (!filter) return {};

    const { aggr_from, aggr_to } = filter;

    return {
      aggr_from,
      aggr_to,
    };
  }
);

export const selectFormattedSliderDatesFromQueryParams = createSelector(
  selectSliderDatesFromQueryParams,
  (dates) => {
    const formattedFromDate = moment(dates.aggr_from).format('MMM YYYY');
    const formattedToDate = moment(dates.aggr_to).format('MMM YYYY');

    return {
      formattedFromDate,
      formattedToDate,
    };
  }
);

export const selectLifecycleFiltersQuery = createSelector(
  selectGroupedClassifications,
  selectSelectedDepartmentIds,
  selectAggregationPeriod,
  selectIsReportView,
  selectSliderDatesFromQueryParams,
  (
    classifications,
    departments,
    [aggregationMin, aggregationMax],
    isReport,
    sliderDatesFromQueryParams
  ) =>
    '?' +
    qs.stringify(
      {
        [QueryType.Filter]: {
          [QueryType.Classification]: classifications,
          [QueryType.Department]: departments,
          [QueryType.DateAggregatedFrom]: isReport
            ? sliderDatesFromQueryParams.aggr_from
            : aggregationRelativeDateToQueryFormat(aggregationMin),
          [QueryType.DateAggregatedTo]: isReport
            ? sliderDatesFromQueryParams.aggr_to
            : aggregationRelativeDateToQueryFormat(aggregationMax),
        },
      },
      { encode: false }
    )
);

export const selectLifecycleFiltersReportQuery = createSelector(
  selectGroupedClassifications,
  selectSelectedDepartmentIds,
  selectSliderDatesFromQueryParams,
  (classifications, departments, chartDatesFromQueryParams) =>
    '?' +
    qs.stringify(
      {
        [QueryType.Filter]: {
          [QueryType.Classification]: classifications,
          [QueryType.Department]: departments,
          [QueryType.DateAggregatedFrom]: chartDatesFromQueryParams.aggr_from,
          [QueryType.DateAggregatedTo]: chartDatesFromQueryParams.aggr_to,
        },
      },
      { encode: false }
    )
);

export const selectSummaryCategoryViewMode = createSelector(
  selectQueryParam('categoryMode'),
  (mode) => mode || SummaryCategoryViewMode.Category
);

/**
 * Select opinion question table sorting
 */
export const selectSorting = createSelector(
  selectQueryParam('sortColumn'),
  selectQueryParam('sortOrder'),
  (column: ColumnName | string, order: OrderBy): SortBy => ({ column, order })
);

export const selectLanguage = createSelector(selectQueryParam('language'), (language) => language);
export const selectTitlePageOnly = createSelector(
  selectQueryParam('titlePageOnly'),
  (titlePageOnly) => titlePageOnly
);

export const selectLegalFiltersWithModeQueryAsObject = createSelector(
  selectGroupedClassifications,
  selectSelectedDepartmentIds,
  selectModeType,
  selectSearchedDepartments,
  selectDemography,
  selectSummaryCategoryViewMode,
  selectSorting,
  (
    classifications,
    departments: number[],
    summaryMode,
    legalDepartments: Department[],
    legalDemography,
    categoryMode,
    sortBy
  ): CompilePathQuery => {
    // Legal query is the query that only has elements that user's role specifies
    const demographyIdsList = legalDemography.map((elem) => elem.id);
    const filteredClassifications = Object.fromEntries(
      Object.entries(classifications).filter((value) =>
        demographyIdsList.includes(Number(value[0]))
      )
    );

    const flatDepartmentIdsList = treeToFlatListForSelector(legalDepartments).map(
      (elem) => elem.id
    );
    const filteredDepartments = departments.filter((department) =>
      flatDepartmentIdsList.includes(department)
    );

    return {
      [QueryType.Filter]: {
        [QueryType.Classification]: filteredClassifications,
        [QueryType.Department]: filteredDepartments,
      },
      mode: summaryMode,
      categoryMode,
      sortColumn: sortBy.column,
      sortOrder: sortBy.order,
    };
  }
);

export const selectLegalFiltersWithModeQuery = createSelector(
  selectLegalFiltersWithModeQueryAsObject,
  (queryObject): string => qs.stringify(queryObject, { encode: false })
);

export const selectProjectsQuery = (forcePage?: number) =>
  createSelector(selectQueryParam('p'), (paginationParams) => {
    const currentPage = forcePage ? forcePage : !!paginationParams ? paginationParams.index : 1;

    return queryDto.build(queryDto.stringifyPagination(currentPage, 50));
  });
const selectCommentsFilterQueryString = ({
  answerLike,
  optionId,
  advocacyIds,
  isReport,
}: {
  answerLike?: string;
  optionId?: number | null;
  advocacyIds?: number[];
  isReport?: boolean;
}) =>
  createSelector(
    selectGroupedClassifications,
    selectSelectedDepartmentIds,
    selectSentimentFilter,
    selectTopicFilter,
    selectIsLifecycleProjectKind,
    selectAggregationPeriod,
    selectSliderDatesFromQueryParams,
    (
      classifications,
      departments,
      sentiments,
      topics,
      isLifecycleKind,
      [aggregationPeriodMinFromState, aggregationPeriodMaxFromState],
      aggregationPeriodFromQueryParams
    ) => {
      return queryDto.stringifyFilter({
        [QueryType.Classification]: classifications,
        [QueryType.Department]: departments,
        [QueryType.Sentiment]: sentiments,
        [QueryType.Topics]: topics,
        [QueryType.AnswerLike]: answerLike,
        [QueryType.Option]: optionId,
        [QueryType.Advocacy]: advocacyIds,
        [QueryType.DateAggregatedFrom]: isLifecycleKind
          ? isReport
            ? aggregationPeriodFromQueryParams.aggr_from
            : aggregationRelativeDateToQueryFormat(aggregationPeriodMinFromState)
          : undefined,
        [QueryType.DateAggregatedTo]: isLifecycleKind
          ? isReport
            ? aggregationPeriodFromQueryParams.aggr_to
            : aggregationRelativeDateToQueryFormat(aggregationPeriodMaxFromState)
          : undefined,
      });
    }
  );

export const selectCommentReportPageFilterQueryString = createSelector(
  selectReportAnswerLike,
  selectReportOptionId,
  selectReportAdvocacy,
  (appState) => appState,
  (answerLike, optionId, advocacyIds, appState) => {
    return selectCommentsFilterQueryString({ answerLike, optionId, advocacyIds, isReport: true })(
      appState
    );
  }
);

export const selectCommentsPageFilterQueryString = createSelector(
  selectSearchedText,
  selectSelectedOptionId,
  selectAdvocacyFilter,
  (appState) => appState as AppState,
  (answerLike, optionId, advocacyIds, appState) => {
    return selectCommentsFilterQueryString({ answerLike, optionId, advocacyIds })(appState);
  }
);

export const selectCommentsPaginationQueryString = (isReport?: boolean, page?: number) =>
  createSelector(selectPaginationPage, selectPaginationItemCount, (paginationPage, itemCount) =>
    queryDto.stringifyPagination(
      isReport ? 1 : page ? page : paginationPage,
      isReport ? itemCount : 10
    )
  );

export const selectCommentsQuery = ({
  isReport = false,
  optionId,
  advocacyIds,
  page,
  answerLike,
}: {
  isReport?: boolean;
  optionId?: number;
  advocacyIds?: number[];
  page?: number;
  answerLike?: string;
}) =>
  createSelector(
    selectCommentsPaginationQueryString(isReport, page),
    selectCommentsFilterQueryString({ answerLike, optionId, advocacyIds }),
    (commentsPaginationQueryString, commentsFilterQueryString) =>
      queryDto.build(commentsPaginationQueryString, commentsFilterQueryString)
  );

export const hasSelectedAnyDepartment = createSelector(
  selectSelectedDepartmentIds,
  (departments) => !isArrayEmpty(departments)
);

export const selectHasSelectedOneDepartment = createSelector(
  selectSelectedDepartmentIds,
  (ids) => ids.length === 1
);

export const hasSelectedAnyClassification = createSelector(
  selectSelectedClassifications,
  (classifications) => !isArrayEmpty(classifications)
);

export const selectHasSelectedOneClassification = createSelector(
  selectSelectedClassifications,
  (classifications) => classifications?.length === 1
);

export const selectHasSelectedMoreThanOneDepartment = createSelector(
  selectSelectedDepartmentIds,
  (departments) => departments.length >= FILTER_COUNT_COMPARE_MODE
);

export const selectHasSelectedMoreThanOneDemographyFromOneGroup = createSelector(
  selectGroupedClassifications,
  (classifications) => {
    const classificationKeys = Object.keys(classifications);

    if (!isArrayEmpty(classificationKeys)) {
      return (
        classificationKeys.length === 1 &&
        classifications[classificationKeys[0]].length >= FILTER_COUNT_COMPARE_MODE
      );
    }
  }
);

export const selectIsCompareMode = createSelector(
  selectModeType,
  (mode) => mode && mode === ModeType.COMPARED
);

export const selectHasCrossFilterClassifications = createSelector(
  selectGroupedClassifications,
  (classifications) => {
    const values = Object.values(classifications);
    const countOfSingleElementArrays = values.filter((it) => it.length === 1).length;
    return Object.keys(classifications).length === 2 && countOfSingleElementArrays === 1;
  }
);

export const selectIsCompareModeLockFactory = (isCrossFilteringEnabled: boolean) =>
  createSelector(
    selectHasCrossFilterClassifications,
    selectHasSelectedMoreThanOneDemographyFromOneGroup,
    selectHasSelectedMoreThanOneDepartment,
    selectSelectedDepartmentIds,
    selectSelectedClassifications,
    selectHasMatch(AppRoute.Home.SUMMARY),
    selectHasMatch(AppRoute.Home.Summary.REPORT),
    selectHasMatch(AppRoute.INSIGHTS),
    (
      hasCrossFilterClassifications,
      hasSelectedMoreThanOneClassification,
      hasSelectedMoreThanOneDepartment,
      selectedDepartmentIds,
      selectedClassifications,
      isSummaryPage,
      isCategoryPage,
      isInsightsPage
    ) => {
      const isRelevantPage = isSummaryPage || isCategoryPage || isInsightsPage;
      const noDepartmentSelected = selectedDepartmentIds.length === 0;
      const oneDepartmentSelected = selectedDepartmentIds.length === 1;
      const noClassificationSelected = selectedClassifications.length === 0;
      const oneClassificationSelected = selectedClassifications.length === 1;

      if (isCrossFilteringEnabled && isRelevantPage) {
        if (
          // TODO: remove isInsightsPage from condition below when switched to new compared endpoints
          (hasCrossFilterClassifications && noDepartmentSelected && isInsightsPage) ||
          (hasSelectedMoreThanOneClassification && oneDepartmentSelected) ||
          (hasSelectedMoreThanOneDepartment && oneClassificationSelected)
        ) {
          return false;
        }
      }
      if (isInsightsPage || isSummaryPage) {
        if (
          (hasSelectedMoreThanOneClassification && noDepartmentSelected) ||
          (hasSelectedMoreThanOneDepartment && noClassificationSelected)
        ) {
          return false;
        }
        if (hasSelectedMoreThanOneClassification && selectedDepartmentIds.length > 0) {
          return true;
        }
      }
      return true;
    }
  );

/**
 * Base on this prefix backend restrict departments and demographics returned to specific page.
 * Backend resolve if user have all permission for filtering on specific page
 */
export const selectReportCategoryQuery = createSelector(
  selectHasMatch(AppRoute.Home.SUMMARY),
  selectHasMatch(AppRoute.Home.REPORTS),
  selectHasMatch(AppRoute.INSIGHTS),
  selectHasMatch(AppRoute.COMMENTS),
  selectHasMatch(AppRoute.TOPICS),
  selectHasMatch(ReportRoute.REPORT_PATTERN),
  (
    hasMatchSummary,
    hasMatchReports,
    hasMatchInsights,
    hasMatchComments,
    hasMatchTopics,
    hasMatchReportView
  ) => {
    switch (true) {
      case hasMatchSummary:
      case hasMatchInsights:
        return ReportCategoryQuery.SUMMARY;
      case hasMatchComments:
      case hasMatchTopics:
        return ReportCategoryQuery.COMMENT;
      case hasMatchReports:
      case hasMatchReportView:
      default:
        return undefined;
    }
  }
);
