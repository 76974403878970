import React from 'react';

import { SummaryInfoContent, SummaryInfoWrapper } from 'view/SummaryPage/SummaryInfo/SummaryInfo.s';

export namespace CasProjectInfo {
  export type Props = {};
}

export const CasProjectInfo: React.FC<CasProjectInfo.Props> = () => (
  <SummaryInfoWrapper>
    <SummaryInfoContent>
      This is your key of client satisfaction and a reliable predictor of whether clients will
      continue to use your services in the future and provide referrals.
      <br />
      Your Client Advocacy Score = Promoters (Very Likely) - Detractors (Very Unlikely + Unlikely +
      Unsure)
    </SummaryInfoContent>
  </SummaryInfoWrapper>
);
