import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OpinionQuestion } from 'model/OpinionQuestion';
import { fetchOverallCombine, fetchOverallCompare } from 'reduxStore/overallSurvey/asyncActions';
import { Resource } from 'model/Resource';

import { STORE_NAME, EngagementDriversState } from './initialState';
import { fetchEngagementScoreCombine, fetchEngagementScoreCompare } from './asyncActions';

export const engagementDriversSlice = createSlice({
  name: STORE_NAME,
  initialState: EngagementDriversState.INITIAL_DOMAIN,
  reducers: {
    setEngagementDriverByCategoryCompare: (
      state,
      action: PayloadAction<Draft<OpinionQuestion.Compare> | null>
    ) => {
      state.compare.selected = action.payload;
      state.categoryName = action.payload?.category?.name;
    },
    setEngagementDriverByCategoryCombine: (
      state,
      action: PayloadAction<Draft<OpinionQuestion.Combine> | null>
    ) => {
      state.combine.selected = action.payload;
      state.categoryName = action.payload?.category?.name;
    },
    clearEngagementDrivers: (state) => {
      state.categoryName = undefined;
      state.combine = EngagementDriversState.INITIAL_DOMAIN_COMBINE;
      state.compare = EngagementDriversState.INITIAL_DOMAIN_COMPARE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOverallCombine.fulfilled, (state, action) => {
      state.combine.list = Resource.createResolvedResource({
        data: action.payload.byCategory as OpinionQuestion.Combine[],
      }) as Draft<Resource<OpinionQuestion.Combine[]>>;
    });

    builder.addCase(fetchOverallCompare.fulfilled, (state, action) => {
      state.compare.list = Resource.createResolvedResource({
        data: action.payload.byCategory as OpinionQuestion.Compare[],
      }) as Draft<Resource<OpinionQuestion.Compare[]>>;
    });

    builder.addCase(fetchEngagementScoreCombine.fulfilled, (state, action) => {
      state.combine.list = Resource.createResolvedResource({
        data: action.payload.byCategory,
      }) as Draft<Resource<OpinionQuestion.Combine[]>>;
    });

    builder.addCase(fetchEngagementScoreCombine.pending, (state) => {
      state.combine.list = Resource.createPendingResource([]) as Draft<
        Resource<OpinionQuestion.Combine[]>
      >;
    });

    builder.addCase(fetchEngagementScoreCombine.rejected, (state, action) => {
      state.combine.list = Resource.createRejectedResource({
        error: action.payload ?? action.error,
      }) as Draft<Resource<OpinionQuestion.Combine[]>>;
    });

    builder.addCase(fetchEngagementScoreCompare.fulfilled, (state, action) => {
      state.compare.list = Resource.createResolvedResource({
        data: action.payload.byCategory,
      }) as Draft<Resource<OpinionQuestion.Compare[]>>;
    });

    builder.addCase(fetchEngagementScoreCompare.pending, (state) => {
      state.compare.list = Resource.createPendingResource([]) as Draft<
        Resource<OpinionQuestion.Compare[]>
      >;
    });

    builder.addCase(fetchEngagementScoreCompare.rejected, (state, action) => {
      state.compare.list = Resource.createRejectedResource({
        error: action.payload ?? action.error,
      }) as Draft<Resource<OpinionQuestion.Compare[]>>;
    });
  },
});

export const {
  setEngagementDriverByCategoryCombine,
  setEngagementDriverByCategoryCompare,
  clearEngagementDrivers,
} = engagementDriversSlice.actions;
