import { ProjectModel } from 'model/Project.model';

type GetPathWithReplacedProjectId = (projectId: ProjectModel['id']) => (location: string) => string;

export const getPathWithReplacedProjectId: GetPathWithReplacedProjectId = (projectId) => (
  location
) => {
  // It looks for
  // first "/" sign
  // then a chain of numbers 0-9 any length
  // finally second "/" sign
  const projectIdRegex = new RegExp('/([0-9]+)/');
  return location.replace(projectIdRegex, `/${projectId}/`);
};
