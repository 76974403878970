import { AxiosInstance } from 'axios';

export namespace TopicsApi {
  type TopicEntry = {
    name: string;
    commentCount: number;
    score: number;
  };

  export type ProjectEntry = {
    summary: {
      score: number;
      commentCount: number;
    };
    topics: TopicEntry[];
  };

  export type AllTopicsEntry = {
    [key: number]: string;
  };
}

export class TopicsApi {
  constructor(private client: AxiosInstance) {}

  getProjectTopics(projectId: number, query: string): Promise<TopicsApi.ProjectEntry> {
    return this.client
      .get<TopicsApi.ProjectEntry>(`/api/v1/project/${projectId}/report/topics${query}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  getAllTopics(): Promise<TopicsApi.AllTopicsEntry> {
    return this.client
      .get<{ data: TopicsApi.AllTopicsEntry }>('/api/v1/sentiment-topics')
      .then((response) => response.data.data)
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }
}
