import { convertObjectToArray } from 'util/convertObjectToArray';

import { ProjectScoreApi } from 'api/ProjectScoreApi';
import { OpinionQuestionApi } from 'api/OpinionQuestionApi';
import { SummaryApi } from 'api/SummaryApi';
import { EngagementScoreSurvey } from 'model/EngagementScoreSurvey';

import { OpinionQuestionMapper } from './OpinionQuestionMapper';
import { SummaryMapper } from './SummaryMapper';

export class EngagementScoreMapper {
  constructor(
    private summaryMapper: SummaryMapper,
    private opinionQuestionMapper: OpinionQuestionMapper
  ) {}

  deserializeCombine({
    showImpactOnEngagement,
    ...entry
  }:
    | ProjectScoreApi.EntryCombine
    | ProjectScoreApi.EngagementCombine): EngagementScoreSurvey.Combine {
    const byCategory: EngagementScoreSurvey.Combine['byCategory'] =
      entry.byCategory &&
      entry.byCategory.map((item: OpinionQuestionApi.CombineEntry) =>
        this.opinionQuestionMapper.deserializeCombine(item)
      );

    return {
      byCategory,
      showImpactOnEngagement,
      ...(ProjectScoreApi.isEngagementCombine(entry)
        ? {
            summary: this.summaryMapper.deserializeCombine(entry.summary),
            chartLabel: entry.chartLabel,
          }
        : {}),
    };
  }

  deserializeCompare(entry: ProjectScoreApi.EntryCompare): EngagementScoreSurvey.Compare {
    return {
      summary: entry.summary.map((item: SummaryApi.EntryCompare) =>
        this.summaryMapper.deserializeCompare(item, entry.groups, entry.protections)
      ),
      byCategory: convertObjectToArray(entry.categories).map((item) =>
        this.opinionQuestionMapper.deserializeCompare(item, entry.groups, entry.protections)
      ),
      isCombineModeLock: entry.combineModeLock,
    };
  }
}
