import { useMount, useUnmount } from 'react-use';

import { useAppDispatch } from 'reduxStore/appStore';
import { fetchUser } from 'reduxStore/user/asyncActions';
import { setRefreshTokenInterval } from 'reduxStore/user/utils';

export const useFetchUser = () => {
  const dispatch = useAppDispatch();
  let clearRefreshTokenInterval: (() => void) | undefined;

  useMount(() => {
    dispatch(fetchUser())
      .unwrap()
      .then((user) => {
        const expirationDate = new Date(user.expirationDate * 1000);
        clearRefreshTokenInterval = setRefreshTokenInterval({ expirationDate });
      });
  });

  useUnmount(() => {
    clearRefreshTokenInterval?.();
  });
};
