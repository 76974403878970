import { selectAuthUiUrl } from 'util/selector/apiSelector';

import { fetchUser } from 'reduxStore/user/asyncActions';
import { authServiceApi } from 'container/authServiceApi';
import posthog from 'posthog-js';

export const redirectToLogin = (ignoreReferrer?: boolean) => {
  if (posthog) {
    posthog.reset();
  }
  const referrer = ignoreReferrer ? '' : `?referrer=${window.location.href}`;
  window.location.replace(`${selectAuthUiUrl()}${referrer}`);
};

export const logout = () => authServiceApi.logout().then(() => redirectToLogin(true));

export const refreshToken = () => authServiceApi.refreshToken();

export const setRefreshTokenInterval = ({ expirationDate }: { expirationDate: Date }) => {
  const intervalId = setInterval(
    () => {
      const secondsLeft = (expirationDate.getTime() - Date.now()) / 1000;
      const refreshSeconds = 600; // 10 minutes
      if (secondsLeft <= refreshSeconds) {
        refreshToken().then(fetchUser);
      }
    },
    60000 // 1 minute
  );
  return () => clearInterval(intervalId);
};
