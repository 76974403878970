import React from 'react';

import { Typography } from '@mui/material';
import { TOPICS_MORE_INFO_URL } from 'shared/constants/externalLinks';

export const TopicsPageDescription = () => (
  <Typography data-testid="topicsDescriptionContainer" mb={4}>
    To help you better understand how people are feeling, WorkBuzz uses machine learning (Artificial
    Intelligence) algorithms to analyse employees' comments and group these in to Topics. The
    largest bubbles below received the most comments across all open questions in your survey.
    Topics in green received mainly positive comments, whereas those in red received mainly negative
    feedback -{' '}
    <a target="_blank" href={TOPICS_MORE_INFO_URL}>
      Find out more
    </a>
    .
  </Typography>
);
