import { Score } from 'model/Score';
import { ScoreApi } from 'api/ScoreApi';

export namespace ScoreMapper {
  export const deserialize = (scoreEntry: ScoreApi.Entry | undefined): Score | undefined => {
    return (
      scoreEntry && {
        label: scoreEntry.label,
        value: scoreEntry.value,
      }
    );
  };
}
