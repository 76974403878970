import React from 'react';

import { forScoreCases } from 'util/forScoreCases';

import { StatsNumber } from 'component/Statistics/Statistics.s';
import { CARDINAL, ENGAGEMENT_GREY, GREEN_GARLANDS } from 'app/theme/colors';

export namespace StatisticNumber {
  export type Props = {
    score: number;
    fontSize: number;
  };
}

export const StatisticNumber: React.FC<StatisticNumber.Props> = (props) => (
  <StatsNumber
    data-testid="statisticScore"
    fontSize={props.fontSize}
    color={forScoreCases(props.score, {
      negative: CARDINAL,
      neutral: ENGAGEMENT_GREY,
      positive: GREEN_GARLANDS,
    })}
  >
    {Math.abs(props.score)}
  </StatsNumber>
);
