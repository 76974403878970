import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'reduxStore/appStore';
import {
  ArrowIconLeft,
  ArrowIconRight,
  SliderSectionContainer,
  SliderSectionWrapper,
  StyledSlider,
} from 'component/PeriodSelectionSlider/PeriodSelectionSlider.s';
import { selectAggregationMonths } from 'reduxStore/project/selectors';
import { range } from 'lodash';
import moment from 'moment';
import { ValueLabelComponent } from 'component/PeriodSelectionSlider/ValueLabelComponent';
import { changeAggregationPeriod } from 'reduxStore/filtering/slice';
import { selectAggregationPeriod } from 'reduxStore/filtering/selectors';
import { AggregationRange } from 'reduxStore/filtering/initialState';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { Typography } from '@mui/material';

export namespace PeriodSelectionSlider {
  export type Props = {
    onChange?: (period: AggregationRange) => void;
    onChangeCommitted?: (period: AggregationRange) => void;
  };
}

const getValueLabel = (value: number, offset: number = 0) =>
  moment()
    .add(value + offset, 'M')
    .format('MMM YYYY');

const MIN_MONTH_STEP = -11;
const MAX_MONTH_STEP = 0;
const VISIBLE_RANGE = MIN_MONTH_STEP - 1;

export const PeriodSelectionSlider = ({
  onChange,
  onChangeCommitted,
}: PeriodSelectionSlider.Props): React.JSX.Element => {
  const aggregationMonths = useSelector(selectAggregationMonths);
  const aggregationPeriod = useSelector(selectAggregationPeriod);
  const dispatch = useAppDispatch();

  // Each pickedValue will always be in <-11, 0> because it describes currently visible picked range.
  const [pickedValue, setPickedValue] = React.useState<AggregationRange>(aggregationPeriod);
  // Each chosenValue can be more than only 12 visible months.
  const [chosenValue, setChosenValue] = React.useState<AggregationRange>(aggregationPeriod);
  // offset says how much to the left the visible range should move. It's always a multiple of -3.
  const [offset, setOffset] = React.useState<number>(0);
  const [leftArrowIconDisabled, setLeftArrowIconDisabled] = React.useState<boolean>(false);
  const [rightArrowIconDisabled, setRightArrowIconDisabled] = React.useState<boolean>(false);

  useEffect(() => {
    if (offset === -(aggregationMonths + VISIBLE_RANGE)) {
      setLeftArrowIconDisabled(true);
    }
    if (offset === MAX_MONTH_STEP) {
      setRightArrowIconDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Slider will always have a visible range of <MIN_MONTH_STEP, MAX_MONTH_STEP> but month names must change
  // when clicked on the range changing arrows.
  const marks = useMemo(
    () =>
      range(MAX_MONTH_STEP, VISIBLE_RANGE, -1).map((mark) => ({
        value: mark,
        label: moment()
          .add(mark + offset, 'M')
          .format('MMM'),
      })),
    [offset]
  );

  const handleChange = (event: any, newValue: number | number[]) => {
    const newValueArray = newValue as AggregationRange;
    const [minPickedValue, maxPickedValue] = pickedValue;
    const [newMinPickedValue, newMaxPickedValue] = newValueArray;

    if (minPickedValue === newMinPickedValue && maxPickedValue === newMaxPickedValue) {
      return;
    }

    // When setting chosenValue make sure to only set elem that changed.
    setChosenValue(
      (oldChosenValue) =>
        oldChosenValue
          .map((oldValue, index) =>
            (newValueArray[index] === MIN_MONTH_STEP && pickedValue[index] === MIN_MONTH_STEP) ||
            (newValueArray[index] === MAX_MONTH_STEP && pickedValue[index] === MAX_MONTH_STEP)
              ? oldValue
              : newValueArray[index] + offset
          )
          .sort((a, b) => a - b) as AggregationRange
    );

    setPickedValue(newValueArray);
    onChange?.(chosenValue);
  };

  const moveRange = (direction: string) => {
    const moveBy = 3;
    if (direction === 'left' && !leftArrowIconDisabled) {
      setRightArrowIconDisabled(false);

      setOffset((oldOffset) => {
        const newOffset = oldOffset - moveBy;

        if (newOffset === -(aggregationMonths + VISIBLE_RANGE)) {
          setLeftArrowIconDisabled(true);
        }

        // When moving range using arrows, we must recreate a chosen value range.
        setPickedValue(
          (oldPickedValue) =>
            oldPickedValue
              .map((oldValue, index) =>
                chosenValue[index] - newOffset <= MIN_MONTH_STEP
                  ? MIN_MONTH_STEP
                  : chosenValue[index] - newOffset
              )
              .map((elem) => Math.min(MAX_MONTH_STEP, elem))
              .sort((a, b) => a - b) as AggregationRange
        );

        return newOffset;
      });
    } else if (direction === 'right' && !rightArrowIconDisabled) {
      setLeftArrowIconDisabled(false);

      setOffset((oldOffset) => {
        const newOffset = oldOffset + moveBy;

        if (newOffset === MAX_MONTH_STEP) {
          setRightArrowIconDisabled(true);
        }

        setPickedValue(
          (oldPickedValue) =>
            oldPickedValue
              .map((oldValue, index) =>
                chosenValue[index] - newOffset >= MAX_MONTH_STEP
                  ? MAX_MONTH_STEP
                  : chosenValue[index] - newOffset
              )
              .map((elem) => Math.max(MIN_MONTH_STEP, elem))
              .sort((a, b) => a - b) as AggregationRange
        );

        return newOffset;
      });
    }
  };

  return (
    <SliderSectionContainer>
      <ScrollBar
        maxWidth={'unset'}
        minWidth={500}
        maxHeight={'max-content'}
        overflow={'horizontal'}
        light
        float={'none'}
      >
        <Typography variant="h5" sx={{ mb: '16px' }}>
          Viewing feedback from {getValueLabel(chosenValue[0])} to {getValueLabel(chosenValue[1])}
        </Typography>

        <SliderSectionWrapper>
          <ArrowIconLeft onClick={() => moveRange('left')} disabled={leftArrowIconDisabled} />
          <StyledSlider
            valueLabelDisplay="auto"
            aria-labelledby="periodSelectionSlider"
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            valueLabelFormat={(value: number) => getValueLabel(value, offset)}
            value={pickedValue}
            onChange={handleChange}
            onChangeCommitted={() => {
              dispatch(changeAggregationPeriod(chosenValue));
              onChangeCommitted?.(chosenValue);
            }}
            marks={marks}
            step={1}
            min={MIN_MONTH_STEP}
            max={MAX_MONTH_STEP}
            data-testid="periodSelectionSlider"
          />
          <ArrowIconRight onClick={() => moveRange('right')} disabled={rightArrowIconDisabled} />
        </SliderSectionWrapper>
      </ScrollBar>
    </SliderSectionContainer>
  );
};
