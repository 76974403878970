import { useSelector } from 'react-redux';
import React from 'react';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { CategoryReportCompare } from 'view/ReportView/CategoryReport/CategoryReportCompare';
import { CategoryReportCombine } from 'view/ReportView/CategoryReport/CategoryReportCombine';
import { useFetchOverall } from 'shared/hooks/useFetchOverall';
import {
  selectOverallSurveyCombineResource,
  selectOverallSurveyCompareResource,
} from 'reduxStore/overallSurvey/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import {
  selectEngagementDriversCombineResource,
  selectEngagementDriversCompareResource,
} from 'reduxStore/engagementDrivers/selectors';
import { useFetchEngagementScore } from 'shared/hooks/useFetchEngagementScore';
import { selectIsOverallProjectKind } from 'reduxStore/project/selectors';

export const CategoryReportView: React.FC = () => {
  const isCompareMode = useSelector(selectIsCompareMode);
  const overallSurveyCombineResource = useSelector(selectOverallSurveyCombineResource);
  const overallSurveyCompareResource = useSelector(selectOverallSurveyCompareResource);

  const combineResource = useSelector(selectEngagementDriversCombineResource);
  const compareResource = useSelector(selectEngagementDriversCompareResource);

  const engagementScoreResource = isCompareMode ? compareResource : combineResource;
  const overallSurveyResource = isCompareMode
    ? overallSurveyCompareResource
    : overallSurveyCombineResource;

  const isOverallProjectKind = useSelector(selectIsOverallProjectKind);
  const resource = isOverallProjectKind ? overallSurveyResource : engagementScoreResource;

  useFetchEngagementScore();
  useFetchOverall();

  return (
    <ResourceLoader resource={resource}>
      {isCompareMode ? <CategoryReportCompare /> : <CategoryReportCombine />}
    </ResourceLoader>
  );
};
