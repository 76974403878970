import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useAppDispatch } from 'reduxStore/appStore';
import { selectHasMatrixAccess } from 'reduxStore/user/selectors';
import { selectCurrentProjectId, selectPulseTemplateId } from 'reduxStore/project/selectors';
import { expandAllDepartments } from 'reduxStore/departments/slice';
import { selectDepartmentsLoadingState } from 'reduxStore/departments/selectors';
import { LoadingStateEnum } from 'model/Resource';

export const useExpandAllDepartments = () => {
  const dispatch = useAppDispatch();
  const hasMatrixAccess = useSelector(selectHasMatrixAccess);

  const departmentsLoadingState = useSelector(selectDepartmentsLoadingState);
  const pulseTemplateId = useSelector(selectPulseTemplateId);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const projectId = pulseTemplateId || currentProjectId;

  useEffect(() => {
    if (hasMatrixAccess && projectId && departmentsLoadingState === LoadingStateEnum.RESOLVED) {
      dispatch(expandAllDepartments());
    }
  }, [hasMatrixAccess, projectId, departmentsLoadingState, dispatch]);
};
