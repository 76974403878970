import React from 'react';
import { useSelector } from 'react-redux';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import {
  selectSimpleProjectCombineEmployeesNumber,
  selectSimpleProjectCombineQuestions,
} from 'reduxStore/simpleProject/selectors';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { LifecycleCharts } from 'component/LifecycleCharts/LifecycleCharts';
import { ReportParamsSelectionMessage } from 'view/ReportView/components/ReportParamsSelectionMessage';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';

export type LifecycleReportCombinePureProps = {
  questions: OpinionQuestionDetails.Combine[];
  responsesNumber: number;
};

export const LifecycleReportCombinePure: React.FC<LifecycleReportCombinePureProps> = ({
  questions,
  responsesNumber,
}) => {
  return (
    <div>
      <ReportContent
        title="Summary"
        component={
          <>
            <ReportParamsSelectionMessage sx={{ mb: 3 }} responsesNumber={responsesNumber} />
            <LifecycleCharts isReportView />
          </>
        }
      />
      <ReportContent
        title="Survey Questions"
        component={
          <SortableQuestionTable
            data={questions}
            width={'max-content'}
            shouldRenderCategory={false}
            isReportView
          />
        }
      />
    </div>
  );
};

export const LifecycleReportCombine: React.FC = () => {
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const responsesNumber = useSelector(selectSimpleProjectCombineEmployeesNumber);

  return <LifecycleReportCombinePure questions={questions} responsesNumber={responsesNumber} />;
};
