import React, { PropsWithChildren } from 'react';

import { TagStyled } from 'component/Tags/Tag.s';

export enum TagVariantEnum {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export type TagProps = {
  variant: TagVariantEnum;
};

export const Tag: React.FC<PropsWithChildren<TagProps>> = ({ variant, children }) => {
  return <TagStyled variant={variant}>{children}</TagStyled>;
};
