import { createFiltersQuery, GroupedClassifications } from 'util/createFiltersQuery';
import { toFileExtension } from 'util/toFileExtension';
import {
  selectCommentsPageFilterQueryString,
  selectFiltersQuery,
  selectIsCompareMode,
  selectLifecycleFiltersQuery,
  selectSorting,
  selectSummaryCategoryViewMode,
} from 'util/selector/querySelector';

import { selectCurrentProjectId, selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import FileSaver from 'file-saver';
import { selectDetailsByCategoryId } from 'reduxStore/engagementDrivers/selectors';
import { selectSelectedQuestionId } from 'reduxStore/comments/selectors';
import { queryDto } from 'api/dto/Query.dto';
import { AdvocacyDto } from 'api/dto/Advocacy.dto';
import { reportApi } from 'container/reportApi';
import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { MonthlyBreakdownReportType } from 'model/MonthlyBreakdownReport';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { STORE_NAME } from 'reduxStore/overallSurvey/initialState';
import { serializeError } from 'shared/utils/redux';
import { ReportFileExtension } from 'model/ReportFileExtension';
import { ModeType } from 'register/ModeType';
import { getSelectedLanguage } from 'shared/hooks/useLocalize';

export const downloadFeedbackReport = createAsyncThunk<
  void,
  { type: ReportFormatType; isLifecycleProjectKind?: boolean }
>(
  `${STORE_NAME}/downloadFeedbackReport`,
  ({ type, isLifecycleProjectKind }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    const format = toFileExtension(type);
    const mode = selectIsCompareMode(getState()) ? ModeType.COMPARED : ModeType.COMBINED;

    const filtersQuery = isLifecycleProjectKind
      ? selectLifecycleFiltersQuery(getState())
      : selectFiltersQuery(getState());

    const categoryMode = selectSummaryCategoryViewMode(getState());
    const language = getSelectedLanguage();
    const sortBy = selectSorting(getState());

    const query = queryDto.build(
      filtersQuery,
      queryDto.stringifyCategoryMode(categoryMode),
      queryDto.stringifyLanguage(language),
      queryDto.stringifySortBy(sortBy)
    );

    return reportApi
      .getFeedbackReport(projectId, query, mode, type)
      .then((data) => FileSaver.saveAs(new Blob([data]), `Feedback_Report.${format}`));
  },
  { serializeError }
);

export const downloadCategoryReport = createAsyncThunk<void, { type: ReportFormatType }>(
  `${STORE_NAME}/downloadCategoryReport`,
  ({ type }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    const detailsByCategoryId = selectDetailsByCategoryId(getState());
    const categoryId = detailsByCategoryId?.category?.id;
    const filtersQuery = selectFiltersQuery(getState());

    const format = toFileExtension(type);
    const mode = selectIsCompareMode(getState()) ? ModeType.COMPARED : ModeType.COMBINED;
    const language = getSelectedLanguage();
    const sortBy = selectSorting(getState());

    const query = queryDto.build(
      filtersQuery,
      queryDto.stringifyLanguage(language),
      queryDto.stringifySortBy(sortBy)
    );

    if (categoryId) {
      return reportApi
        .getCategoryReport(projectId, categoryId, query, mode, type)
        .then((data) => FileSaver.saveAs(new Blob([data]), `Feedback_Report.${format}`));
    }
  },
  { serializeError }
);

export const downloadCommentReport = createAsyncThunk<
  void,
  { type: ReportFormatType; questionName?: string }
>(
  `${STORE_NAME}/downloadCommentReport`,
  ({ type, questionName }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    const questionId = selectSelectedQuestionId(getState());

    if (!questionId) return;

    const filtersQueryString = selectCommentsPageFilterQueryString(getState());
    const language = getSelectedLanguage();
    const sortBy = selectSorting(getState());

    const query = queryDto.build(
      filtersQueryString,
      queryDto.stringifyFormat(type),
      queryDto.stringifyLanguage(language),
      queryDto.stringifySortBy(sortBy)
    );

    const format = toFileExtension(type);
    const fileName = questionName
      ? `Comment_Report_${questionName}.${format}`
      : `Comment_Report.${format}`;

    return reportApi
      .getCommentReport(projectId, questionId, query)
      .then((data) => FileSaver.saveAs(new Blob([data]), fileName));
  },
  { serializeError }
);

export const downloadInsightsReport = createAsyncThunk<void, { type: ReportFormatType }>(
  `${STORE_NAME}/downloadInsightsReport`,
  async ({ type }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    const isLifecycleKind = selectIsLifecycleProjectKind(getState());
    const format = toFileExtension(type);
    const isCompareMode = selectIsCompareMode(getState());

    const filtersQuery = isLifecycleKind
      ? selectLifecycleFiltersQuery(getState())
      : selectFiltersQuery(getState());

    const language = getSelectedLanguage();
    const sortBy = selectSorting(getState());

    const query = queryDto.build(
      filtersQuery,
      queryDto.stringifyLanguage(language),
      queryDto.stringifySortBy(sortBy)
    );

    const reportApiMethod = !isCompareMode
      ? reportApi.getInsightsCombinedReport.bind(reportApi)
      : isLifecycleKind
      ? reportApi.getInsightsLifecycleComparedReport.bind(reportApi)
      : reportApi.getInsightsComparedReport.bind(reportApi);

    const data = await reportApiMethod(projectId, query, type);
    FileSaver.saveAs(new Blob([data]), `Insights_Report.${format}`);
  },
  { serializeError }
);

export const downloadAllFeedbackPdfReport = createAsyncThunk<
  void,
  { departmentId?: number; departmentName?: string }
>(
  `${STORE_NAME}/downloadAllFeedbackPdfReport`,
  ({ departmentId, departmentName }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    const language = getSelectedLanguage();
    const categoryMode = selectSummaryCategoryViewMode(getState());
    const filtersQuery =
      '?' +
      createFiltersQuery({
        classifications: [],
        departments: departmentId ? [departmentId] : [],
        categoryMode,
      });
    const sortBy = selectSorting(getState());
    const query = queryDto.build(
      filtersQuery,
      queryDto.stringifyLanguage(language),
      queryDto.stringifySortBy(sortBy)
    );

    return reportApi
      .getAllFeedbackPDFReport(projectId, query)
      .then((data) =>
        FileSaver.saveAs(
          new Blob([data]),
          `${departmentName ? `${departmentName}_` : ''}All_Feedback_Report.pdf`
        )
      );
  },
  { serializeError }
);

export const downloadAllCommentsExcelReport = createAsyncThunk(
  `${STORE_NAME}/downloadAllCommentsExcelReport`,
  (_, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    const commentsPageFilterQueryString = selectCommentsPageFilterQueryString(getState());

    const query = queryDto.build(commentsPageFilterQueryString);

    return reportApi
      .getAllCommentsReport(projectId, query, ReportFormat.Excel)
      .then((data) =>
        FileSaver.saveAs(new Blob([data]), `All_Comments_Report.${ReportFileExtension.Excel}`)
      );
  },
  { serializeError }
);

export const downloadAllCommentsPdfReport = createAsyncThunk<
  void,
  { departmentId?: number; departmentName?: string }
>(
  `${STORE_NAME}/downloadAllCommentsPdfReport`,
  ({ departmentName }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    const commentsPageFilterQueryString = selectCommentsPageFilterQueryString(getState());
    const language = getSelectedLanguage();
    const sortBy = selectSorting(getState());

    const query = queryDto.build(
      commentsPageFilterQueryString,
      queryDto.stringifyLanguage(language),
      queryDto.stringifySortBy(sortBy)
    );

    return reportApi
      .getAllCommentsReport(projectId, query, ReportFormat.PDF)
      .then((data) =>
        FileSaver.saveAs(
          new Blob([data]),
          `${departmentName ? `${departmentName}_` : ''}All_Comments_Report.${
            ReportFileExtension.PDF
          }`
        )
      );
  },
  { serializeError }
);

export const downloadMonthlyBreakdownReport = createAsyncThunk<
  void,
  { monthlyBreakdownReport: MonthlyBreakdownReportType }
>(
  `${STORE_NAME}/downloadMonthlyBreakdownReport`,
  ({ monthlyBreakdownReport }, { getState }) => {
    const projectId = selectCurrentProjectId(getState());
    const query = selectFiltersQuery(getState());

    return reportApi
      .getMonthlyBreakdownReport(projectId, query, monthlyBreakdownReport)
      .then((data) =>
        FileSaver.saveAs(new Blob([data]), `Monthly_Breakdown.${ReportFileExtension.Excel}`)
      );
  },
  { serializeError }
);

export const downloadRespondentIndividualFeedbackReport = createAsyncThunk<
  void,
  { type: ReportFormatType; projectId: number; respondentIds: string[] }
>(
  `${STORE_NAME}/downloadRespondentIndividualFeedbackReport`,
  ({ type, projectId, respondentIds }, { getState }) => {
    const format = toFileExtension(type);
    const fileName = `Respondent_Individual_Feedback_Report_${respondentIds?.[0]}.${format}`;
    const language = getSelectedLanguage();
    const sortBy = selectSorting(getState());

    return reportApi
      .getRespondentIndividualFeedbackReport({ type, projectId, respondentIds, language, sortBy })
      .then((data) => FileSaver.saveAs(new Blob([data]), fileName));
  },
  { serializeError }
);

export const downloadIndividualFeedbackReport = createAsyncThunk<
  void,
  {
    type: ReportFormatType;
    projectId: number;
    advocacyFilter: AdvocacyDto[];
    classifications: GroupedClassifications;
    departments: number[];
    respondentLikeFilter: string;
  }
>(
  `${STORE_NAME}/downloadIndividualFeedbackReport`,
  (props, { getState }) => {
    const format = toFileExtension(props.type);
    const fileName = `Individual_Feedbacks_Report.${format}`;
    const language = getSelectedLanguage();
    const sortBy = selectSorting(getState());

    return reportApi
      .getIndividualFeedbackReport({ ...props, language, sortBy })
      .then((data) => FileSaver.saveAs(new Blob([data]), fileName));
  },
  { serializeError }
);
