import { styled } from '@mui/material';
import { DARK_ORANGE, GREY_TEXT, IRON, SONIC_SILVER, WHITE } from 'app/theme/colors';

type PulseNavigationMenuItemWrapperProps = {
  hasVisibleRibbonNavBarItems: boolean;
};

export const PulseNavigationMenuItemWrapper = styled('nav')<PulseNavigationMenuItemWrapperProps>(
  ({ theme, hasVisibleRibbonNavBarItems }) => ({
    backgroundColor: WHITE,
    display: 'flex',
    position: 'relative',
    borderBottom: `3px solid ${hasVisibleRibbonNavBarItems ? IRON : DARK_ORANGE}`,
    padding: '10px 25px 0',
    height: 51,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  })
);

export const AdminContainer = styled('div')(({ theme }) => ({
  flex: '0 0 auto',
  marginRight: '50px',
  marginLeft: 'auto',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const Link = styled('a')({
  textDecoration: 'none',
  color: GREY_TEXT,

  '&:hover': {
    borderBottom: `2px solid ${SONIC_SILVER}`,
  },
});

export const MobileLink = styled('a')({
  textDecoration: 'none',
  alignSelf: 'start',
  color: GREY_TEXT,
  margin: '8px 0',
  padding: '6px 0',
  borderBottom: `3px solid transparent`,

  '&:hover': {
    borderBottom: `3px solid ${IRON}`,
  },
});
