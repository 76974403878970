export interface AppConfig {
  NODE_ENV: string;
  REPORT_API_CLIENT_URL: string;
  AUTH_API_CLIENT_URL: string;
  ACT_UI_URL: string;
  AUTH_UI_URL: string;
  ADMIN_UI_URL: string;
  REPORT_UI_GTM_KEY: string;
  RECAPTCHA_SITE_KEY: string;
  GROWTHBOOK_API_KEY: string;
  REACT_APP_PUBLIC_POSTHOG_HOST: string;
  REACT_APP_PUBLIC_POSTHOG_KEY: string;
  DATADOG_REPORT_UI_CLIENT_TOKEN: string;
  DATADOG_REPORT_UI_RUM_APPLICATION_ID: string;
  DATADOG_REPORT_UI_RUM_DEFAULT_PRIVACY_LEVEL: string;
  DATADOG_REPORT_UI_RUM_SESSION_REPLAY_SAMPLE_RATE: string;
  DATADOG_REPORT_UI_RUM_SESSION_SAMPLE_RATE: string;
}

/**
 * Get app config. During development is uses values from env variables.
 * On prod it return global config object
 *
 * This enables single Docker image build to be run with different configs.
 */
export const getAppConfig = (): AppConfig => {
  /**
   * In development use env variables
   */
  if (process.env.NODE_ENV === 'development') {
    return {
      NODE_ENV: process.env.NODE_ENV,
      REPORT_API_CLIENT_URL: process.env.REPORT_API_CLIENT_URL,
      AUTH_API_CLIENT_URL: process.env.AUTH_API_CLIENT_URL,
      ACT_UI_URL: process.env.ACT_UI_URL,
      AUTH_UI_URL: process.env.AUTH_UI_URL,
      ADMIN_UI_URL: process.env.ADMIN_UI_URL,
      REPORT_UI_GTM_KEY: process.env.REPORT_UI_GTM_KEY,
      RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
      GROWTHBOOK_API_KEY: process.env.GROWTHBOOK_API_KEY,
      REACT_APP_PUBLIC_POSTHOG_HOST: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
      REACT_APP_PUBLIC_POSTHOG_KEY: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
      DATADOG_REPORT_UI_CLIENT_TOKEN: process.env.DATADOG_REPORT_UI_CLIENT_TOKEN,
      DATADOG_REPORT_UI_RUM_APPLICATION_ID: process.env.DATADOG_REPORT_UI_RUM_APPLICATION_ID,
      DATADOG_REPORT_UI_RUM_DEFAULT_PRIVACY_LEVEL:
        process.env.DATADOG_REPORT_UI_RUM_DEFAULT_PRIVACY_LEVEL,
      DATADOG_REPORT_UI_RUM_SESSION_REPLAY_SAMPLE_RATE:
        process.env.DATADOG_REPORT_UI_RUM_SESSION_REPLAY_SAMPLE_RATE,
      DATADOG_REPORT_UI_RUM_SESSION_SAMPLE_RATE:
        process.env.DATADOG_REPORT_UI_RUM_SESSION_SAMPLE_RATE,
    } as AppConfig;
  }

  if (!window || !window.AppConfig) {
    throw new Error('APP CONFIG MISSING!');
  }

  const appConfigShallowCopy = {
    ...window.AppConfig,
  };

  return appConfigShallowCopy;
};
