import moment, { Moment } from 'moment';
import { AdvocacyDto, AdvocacyModel, mapAdvocacyDtoToModel } from 'api/dto/Advocacy.dto';

export type RespondentDto = Readonly<{
  advocacy: AdvocacyDto;
  department_name: string;
  employed_since: string | null;
  first_name: string;
  id: string;
  last_name: string;
  share_with_manager: boolean | null;
  departments?: string[];
}>;

export type RespondentModel = Readonly<{
  advocacy: AdvocacyModel;
  departmentName: string;
  employedSince: Moment | null;
  firstName: string;
  id: string;
  lastName: string;
  shareWithManager: boolean | null;
  departments?: string[];
}>;

export const mapRespondentDtoToModel = (dto: RespondentDto): RespondentModel => {
  return {
    advocacy: mapAdvocacyDtoToModel(dto.advocacy),
    departmentName: dto.department_name,
    employedSince: dto.employed_since ? moment(dto.employed_since) : null,
    firstName: dto.first_name,
    id: dto.id,
    lastName: dto.last_name,
    shareWithManager: dto.share_with_manager,
    departments: dto.departments,
  };
};
