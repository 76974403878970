import React from 'react';
import { useSelector } from 'react-redux';

import { TopNavBarWrapper } from 'component/TopNavBar/TopNavBar.s';
import WorkbuzzLogo from 'app/asset/workbuzz_logo.svg';
import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import LogoutIcon from 'react-icons/lib/md/exit-to-app';
import { logout } from 'reduxStore/user/utils';
import {
  DividerLine,
  Menu,
  LogoWrapper,
  NavigationMenuItemWrapper,
} from 'component/SimplifyTopNavBar/SimplifiedTopNavBar.s';
import { selectRole, selectUserNameToDisplay } from 'reduxStore/user/selectors';
import { UserRole } from 'register/UserRole';
import ArrowRightIcon from 'react-icons/lib/fa/arrow-right';
import { goToPage } from 'reduxStore/router/asyncActions';
import { AdminRoute } from 'app/route/admin';
import { useAppDispatch } from 'reduxStore/appStore';

export type SimplifiedTopNavBarPureProps = {
  userName: string;
  userRole: UserRole;
  onLogout: () => void;
  redirectToAdminService: () => void;
};

// TODO combine with TopNavBarDropDown use shared components
export const SimplifiedTopNavBarPure: React.FC<SimplifiedTopNavBarPureProps> = (props) => (
  <TopNavBarWrapper>
    <Menu>
      <LogoWrapper>
        <WorkbuzzLogo />
      </LogoWrapper>
      <NavigationMenuItemWrapper>
        <Dropdown data-testid="topNavbarDropDownButton" label={props.userName}>
          <>
            {props.userRole === UserRole.ClientAdmin && (
              <DropdownItem alignedRight onClick={() => props.redirectToAdminService()}>
                <ArrowRightIcon style={{ fontSize: '16px', marginRight: '5px' }} />
                Admin Dashboard
              </DropdownItem>
            )}
            <DropdownItem alignedRight data-testid="logoutItem" onClick={() => props.onLogout()}>
              <LogoutIcon style={{ fontSize: '18px', marginRight: '5px' }} />
              Log Out
            </DropdownItem>
          </>
        </Dropdown>
      </NavigationMenuItemWrapper>
    </Menu>
    <DividerLine />
  </TopNavBarWrapper>
);

export const SimplifiedTopNavBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const userName = useSelector(selectUserNameToDisplay) || '';
  const userRole = useSelector(selectRole);

  const redirectToAdminService = () => dispatch(goToPage(AdminRoute.PROJECT_PATTERN));

  return (
    <SimplifiedTopNavBarPure
      userName={userName}
      userRole={userRole as UserRole}
      redirectToAdminService={redirectToAdminService}
      onLogout={logout}
    />
  );
};
