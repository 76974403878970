import React from 'react';
import { useSelector } from 'react-redux';

import { ReportType } from 'model/ReportType';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import {
  selectInsightsCompared,
  selectInsightsComparedResource,
} from 'reduxStore/insights/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { InsightsComparedQuestionMatrix } from 'view/InsightsPage/compared/InsightsComparedQuestionMatrix';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { fetchInsightsCompared } from 'reduxStore/insights/asyncActions';

export const InsightsComparedReportView: React.FC = () => {
  const insightsComparedResource = useSelector(selectInsightsComparedResource);
  const insightsCompared = useSelector(selectInsightsCompared);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);

  useFetchInsights(fetchInsightsCompared);

  return (
    <ResourceLoader resource={insightsComparedResource}>
      <ReportContent
        title="Insights"
        reportType={ReportType.Insights}
        component={
          <>
            {insightsCompared?.insights?.map((item, index) => (
              <InsightsComparedQuestionMatrix
                isReportView
                key={index}
                item={item}
                groups={insightsCompared.groups}
                minResponses={minResponses}
              />
            ))}
          </>
        }
      />
    </ResourceLoader>
  );
};
