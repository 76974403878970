import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectFormattedSliderDatesFromQueryParams,
  selectTitlePageOnly,
} from 'util/selector/querySelector';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { TitlePage } from 'view/ReportView/ReportTemplate/TitlePage/TitlePage';
import {
  selectAllAnswersResource,
  selectReportSelectedQuestion,
} from 'reduxStore/comments/selectors';
import { CommentAnswerItem } from 'view/CommentsPage/CommentAnswerItem';
import { Question } from 'model/Question';
import { Answer } from 'model/Answer';
import { ReportType } from 'model/ReportType';
import { selectIsLifecycleProjectKind, selectReportTitle } from 'reduxStore/project/selectors';
import { Department } from 'model/Department';
import { selectReportDepartment } from 'reduxStore/departments/selectors';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { useFetchCommentsQuestions } from 'shared/hooks/useFetchCommentsQuestions';

export type CommentReportViewPure = {
  answersResource: Resource<Answer[] | null>;
  reportTitle: string;
  reportTypeExtraLine?: string;
  selectedDepartment?: Department;
  selectedQuestion: Question;
};

export const CommentReportViewPure: React.FC<CommentReportViewPure> = ({
  answersResource,
  selectedDepartment,
  selectedQuestion,
  reportTitle,
}) => {
  const newFlag = selectedQuestion?.isLatest ? '(New)' : '';
  const isTitlePageOnly = useSelector(selectTitlePageOnly);

  if (isTitlePageOnly) {
    return (
      <TitlePage
        title={reportTitle}
        reportType={ReportType.Comments}
        departmentName={selectedDepartment && selectedDepartment.name}
      />
    );
  }

  return (
    <div>
      <ResourceLoader resource={answersResource}>
        <ReportContent
          title={selectedQuestion?.content + ' ' + newFlag}
          reportType={ReportType.Comments}
          component={
            <>
              {answersResource.data?.map((item, index) => (
                <CommentAnswerItem key={index} item={item} isReportView />
              ))}
            </>
          }
        />
      </ResourceLoader>
    </div>
  );
};

export const CommentReportView: React.FC = () => {
  const answersResource = useSelector(selectAllAnswersResource);
  const selectedQuestion = useSelector(selectReportSelectedQuestion);
  const selectedDepartment = useSelector(selectReportDepartment);
  const reportTitle = useSelector(selectReportTitle);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);
  const { formattedFromDate, formattedToDate } = useSelector(
    selectFormattedSliderDatesFromQueryParams
  );
  const reportTypeExtraLine = isLifecycleProjectKind
    ? `${formattedFromDate} - ${formattedToDate}`
    : undefined;

  useFetchCommentsQuestions();

  return (
    <CommentReportViewPure
      answersResource={answersResource}
      selectedQuestion={selectedQuestion}
      selectedDepartment={isLifecycleProjectKind ? undefined : selectedDepartment}
      reportTitle={reportTitle}
      reportTypeExtraLine={reportTypeExtraLine}
    />
  );
};
