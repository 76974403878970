import React from 'react';

import { NavigationMenuItems } from 'component/Navigation/NavigationMenuItems';
import { TopNavBarDropdown } from 'component/TopNavBar/TopNavBarDropdown';
import { Collapse, Divider, Stack, Typography } from '@mui/material';
import { MobileLink } from 'component/Navigation/Navigation.s';
import { MobileNavigationMenuWrapper } from 'component/Navigation/MobileNavigationMenu.s';

export type MobileNavigationMenuProps = {
  isOpen: boolean;
  shouldShowAdminLink?: boolean;
  shouldShowKnowledgebaseLink?: boolean;
  adminLink?: string;
};

export const MobileNavigationMenu: React.FC<MobileNavigationMenuProps> = ({
  isOpen,
  shouldShowAdminLink,
  shouldShowKnowledgebaseLink,
  adminLink,
}) => {
  return (
    <MobileNavigationMenuWrapper data-testid="mobileNavigationMenu">
      <Collapse in={isOpen}>
        <Stack>
          <NavigationMenuItems />
          <Divider sx={{ marginTop: '16px' }} />
          {shouldShowKnowledgebaseLink && (
            <MobileLink
              data-testid="mobileKnowledgebaseLink"
              href="https://workbuzzsupport.zendesk.com/hc/en-gb/categories/4417866782610-WorkBuzz-Dashboard-Results"
              target="_blank"
            >
              <Typography variant="h6">Knowledgebase</Typography>
            </MobileLink>
          )}
          {shouldShowAdminLink && (
            <MobileLink
              data-testid="mobileAdminLink"
              href={adminLink}
              target="_blank"
              sx={{ marginTop: shouldShowKnowledgebaseLink ? 0 : '16px' }}
            >
              <Typography variant="h6">Admin</Typography>
            </MobileLink>
          )}
          <TopNavBarDropdown
            sx={shouldShowAdminLink ? undefined : { marginTop: '16px' }}
            data-testid={'mobileTopNavBarDropdownWrapper'}
          />
        </Stack>
      </Collapse>
    </MobileNavigationMenuWrapper>
  );
};
