import React from 'react';

import { ScaleLinear, ScalePoint } from 'd3-scale';
import { PulseSurveyBarChart } from 'component/PulseSurveyBarChart/PulseSurveyBarChart';
import { ProjectStatus } from 'model/ProjectStatus';
import { MARINER, MIDNIGHT_EXPRESS, PLATINUM } from 'app/theme/colors';
import { ProjectSummarySelected } from 'model/ProjectSummary';
import Tooltip from '@mui/material/Tooltip';

export type PulseSurveyBarsProps = {
  scales: {
    xScale: ScalePoint<string>;
    yScale: ScaleLinear<number, number>;
  };
  margin: PulseSurveyBarChart.Margin;
  height: number;
  data: ProjectSummarySelected[];
};

export const PulseSurveyBars: React.FC<PulseSurveyBarsProps> = (props) => {
  const selectColor = (status: ProjectStatus, selected: boolean) => {
    if (selected) {
      return MIDNIGHT_EXPRESS;
    }

    switch (status) {
      case ProjectStatus.LIVE:
      case ProjectStatus.CLOSE:
        return MARINER;
      case ProjectStatus.IN_DEVELOPMENT:
        return PLATINUM;
    }
  };

  return (
    <g>
      {props.data.map((d, index: number) => {
        const x = props.scales.xScale(d.date.toString());
        const y = props.scales.yScale(d.score);
        const height = props.height - props.margin.bottom - props.scales.yScale(d.score);
        const width = 60;
        const radius = 6;

        return (
          <Tooltip
            title={d.status === ProjectStatus.IN_DEVELOPMENT ? '' : d.score}
            placement="top"
            key={index}
          >
            <path
              style={{ zIndex: 1000 }}
              fill={selectColor(d.status, d.selected)}
              d={`
               M${x},${y + height}
               h${width}
               v-${height - radius}
               a${radius},${radius} 0 0 0 -${radius},-${radius}
               h-${width - 2 * radius}
               a${radius},${radius} 0 0 0 -${radius},${radius}  
               v${height - radius}  
               Z                  
              `}
            />
          </Tooltip>
        );
      })}
    </g>
  );
};
