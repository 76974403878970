import React, { ReactNode } from 'react';
import ToolTip from 'react-tooltip';

import { Trend } from 'model/Trend';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { Benchmark } from 'model/Benchmark';
import { FlagNew } from 'component/FlagNew/FlagNew';
import {
  NewExplanationText,
  NewExplanationWrapper,
} from 'view/SelectProjectPage/SelectProjectPage.s';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import { CategoryHeader } from 'view/EngagementDriverByCategoryPage/CategoryHeader/CategoryHeader';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { useRedirectToSummary } from 'view/EngagementDriverByCategoryPage/hooks/useRedirectToSummary';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';

import { CategoryWrapper, toolTipStyle } from './EngagementDriverByCategoryCombine.s';
import { EngagementScoreCombineWidget } from './EngagementScoreCombineWidget';

export namespace EngagementDriverByCategoryCombine {
  export type OwnProps = {
    score: number;
    isAdmin: boolean;
    categoryId: number;
    categoryTitle: string;
    categoryData: ReadonlyArray<OpinionQuestionDetails.Combine>;
    isReportView?: boolean;
    benchmark?: Benchmark;
    trends?: ReadonlyArray<Trend>;
    trendLabel?: ReactNode;
  };

  export type Props = OwnProps;
}

export const EngagementDriverByCategoryCombine: React.FC<EngagementDriverByCategoryCombine.Props> = (
  props
) => {
  useRedirectToSummary();

  return (
    <>
      {!props.isReportView && <CategoryHeader categoryName={props.categoryTitle} />}
      <CategoryWrapper isReportView={props.isReportView}>
        <PulseFilter />
        <ToolTip place="right" type="info" className={`${toolTipStyle}`} html />

        <EngagementScoreCombineWidget
          score={props.score}
          categoryTitle={props.categoryTitle}
          trends={props.trends}
          trendLabel={props.trendLabel}
          benchmark={props.benchmark}
        />

        <ScrollBar overflow="horizontal" float="none" light>
          <SortableQuestionTable
            data={props.categoryData}
            isReportView={props.isReportView}
            categoryId={props.categoryId}
            shouldRenderCategory={false}
          />
        </ScrollBar>

        {OpinionQuestionDetails.needExplanation(props.categoryData, !!props.isReportView) ? (
          <NewExplanationWrapper data-testid="newQuestionExplanation">
            <FlagNew isLatest />
            <NewExplanationText>
              This question was asked in your most recent survey. It may be the first time you've
              asked this question, or a question you've asked before with new feedback showing for
              it.
            </NewExplanationText>
          </NewExplanationWrapper>
        ) : null}
      </CategoryWrapper>
    </>
  );
};
