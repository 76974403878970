import React from 'react';
import { useSelector } from 'react-redux';

import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { CasQuestionTableCompare } from 'view/CasPage/Compare/CasQuestionTableCompare';
import {
  selectSimpleProjectCompareGroups,
  selectSimpleProjectCompareQuestionContent,
} from 'reduxStore/simpleProject/selectors';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';

export type OnDemandReportComparePureProps = {
  question: string | undefined;
  groups: string[];
  minResponses: number;
};

export const OnDemandReportComparePure: React.FC<OnDemandReportComparePureProps> = (props) => {
  return (
    <div>
      <ReportContent title="Summary" component={<SummaryCompareWidgets isReportView />} />
      <ReportContent
        title="Survey Questions"
        component={
          <CasQuestionTableCompare
            groups={props.groups}
            minResponses={props.minResponses}
            isReportView
          />
        }
      />
    </div>
  );
};

export const OnDemandReportCompare: React.FC = () => {
  const question = useSelector(selectSimpleProjectCompareQuestionContent);
  const groups = useSelector(selectSimpleProjectCompareGroups);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);

  return (
    <OnDemandReportComparePure question={question} groups={groups} minResponses={minResponses} />
  );
};
