import React from 'react';

import { ProjectKind } from 'model/ProjectKind';
import { Typography } from '@mui/material';

export namespace ProjectKindLabel {
  export type Props = {
    kind?: ProjectKind;
    onClick?: () => void;
    name?: string | null;
    isClickable?: boolean;
    color?: string;
  };
}

const getProjectKindLabel = (kind?: ProjectKind): string => {
  switch (kind) {
    case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
      return 'Client Advocacy Score';
    case ProjectKind.PROJECT_NET_PROMOTER_SCORE:
      return 'Net Promoter Score';
    case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      return 'Engagement Score';
    case ProjectKind.PROJECT_OVERALL_SCORE:
      return 'Overall Score';
    default:
      return '';
  }
};

export const ProjectKindLabel: React.FC<ProjectKindLabel.Props> = (props) => (
  <Typography
    variant="h5"
    data-testid="projectKindLabel"
    onClick={() => props.onClick && props.onClick()}
    style={{ cursor: props.isClickable ? 'pointer' : 'text', color: props.color || undefined }}
  >
    {props.name ? props.name : getProjectKindLabel(props.kind)}
  </Typography>
);
