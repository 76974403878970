import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectIsCompareModeLockFactory,
  selectModeType,
  selectSummaryCategoryViewMode,
} from 'util/selector/querySelector';
import { createFiltersQuery } from 'util/createFiltersQuery';

import {
  ClearAllFiltersButton,
  Menu,
  MenuTitle,
  SwitchModeWrapper,
} from 'component/FiltersMenu/FiltersMenu.s';
import { selectDepartments, selectSearchedDepartments } from 'reduxStore/departments/selectors';
import { Department } from 'model/Department';
import { DepartmentList } from 'component/DepartmentList/DepartmentList';
import { Demography } from 'model/Demography';
import { selectDemography } from 'reduxStore/demography/selectors';
import { DemographyFilter } from 'component/DemographyFilter/DemographyFilter';
import { SearchDepartmentsInput } from 'component/SearchDepartmentsInput/SearchDepartmentsInput';
import { SwitchButton } from 'component/SwitchButton/SwitchButton';
import { ModeType } from 'register/ModeType';
import { setMode } from 'reduxStore/filtering/asyncActions';
import { selectIsCombineModeLockFromVisualSettings } from 'reduxStore/visualSettings/selectors';
import { selectRespondentProtectionNumber } from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectCanFilterByDepartment } from 'reduxStore/filtering/selectors';
import { pushQuery } from 'reduxStore/router/asyncActions';
import { setIsClearingAllFilters, setPreferredMode } from 'reduxStore/visualSettings/slice';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { selectIsCombineModeLockFromInsightsCompared } from 'reduxStore/insights/selectors';

export type FiltersMenuPureProps = {
  clearAllFilters: () => void;
  demography: Demography[];
  departments: Department[];
  disabledDepartments: boolean;
  isCombineModeLock: boolean;
  isCompareModeLock: boolean;
  respondentProtectionNumber: number;
  selectedMode: ModeType;
  setMode: (mode: ModeType) => void;
  showFilters: boolean;
};

export const FiltersMenuPure: React.FC<FiltersMenuPureProps> = ({
  clearAllFilters,
  demography,
  departments,
  disabledDepartments,
  isCombineModeLock,
  isCompareModeLock,
  respondentProtectionNumber,
  selectedMode,
  setMode,
  showFilters,
}) => {
  return (
    <Menu>
      <ClearAllFiltersButton data-testid="clearAllFiltersButton" onClick={clearAllFilters}>
        Clear all filters
      </ClearAllFiltersButton>
      <MenuTitle variant="h5">Filters</MenuTitle>
      <SwitchModeWrapper>
        <SwitchButton
          leftButtonLabel="Heatmap"
          rightButtonLabel="Combine"
          leftButtonValue={ModeType.COMPARED}
          rightButtonValue={ModeType.COMBINED}
          onLeftButtonClick={() => setMode(ModeType.COMPARED)}
          onRightButtonClick={() => setMode(ModeType.COMBINED)}
          activeButton={selectedMode}
          isCombineModeLock={isCombineModeLock}
          isCompareModeLock={isCompareModeLock}
          respondentProtection={respondentProtectionNumber}
        />
      </SwitchModeWrapper>
      <DemographyFilter data={demography} />
      {showFilters && (
        <>
          <SearchDepartmentsInput departments={departments} />
          <DepartmentList data={departments} disabled={disabledDepartments} />
        </>
      )}
    </Menu>
  );
};

export type FiltersMenuProps = {
  onClearAllFilters?: () => Promise<any>;
};

export const FiltersMenu: React.FC<FiltersMenuProps> = ({ onClearAllFilters }) => {
  const dispatch = useAppDispatch();

  const allDepartments: Department[] = useSelector(selectDepartments);
  const demography = useSelector(selectDemography);
  const searchedDepartments: Department[] = useSelector(selectSearchedDepartments);

  const canFilterByDepartment = useSelector(selectCanFilterByDepartment);

  const isCombineModeLockFromVisualSettings = useSelector(
    selectIsCombineModeLockFromVisualSettings
  );
  const isCombineModeLockFromInsightsCompared = useSelector(
    selectIsCombineModeLockFromInsightsCompared
  );
  const respondentProtectionNumber = useSelector(selectRespondentProtectionNumber);
  const selectedMode = useSelector(selectModeType);

  const isFeatureEnabled = useFeatureIsOn('cross-filter-heatmaps');
  const isCompareModeLock = useSelector(selectIsCompareModeLockFactory(isFeatureEnabled));
  const categoryMode = useSelector(selectSummaryCategoryViewMode);

  const clearAllFilters = () => {
    dispatch(setIsClearingAllFilters(true));
    onClearAllFilters?.().finally(() => {
      dispatch(
        pushQuery({
          query: createFiltersQuery({
            classifications: [],
            departments: [],
            summaryMode: ModeType.COMBINED,
            categoryMode,
          }),
        })
      );
      dispatch(setIsClearingAllFilters(false));
    });
  };

  const setModeHandler = (mode: ModeType) => {
    if (selectedMode !== mode) {
      dispatch(setMode(mode));
      dispatch(setPreferredMode(mode));
    }
  };

  return (
    <FiltersMenuPure
      clearAllFilters={clearAllFilters}
      demography={demography}
      departments={searchedDepartments}
      disabledDepartments={!canFilterByDepartment}
      // TODO: stop using the isCombineModeLockFromVisualSettings once we switch no all new endpoints
      isCombineModeLock={
        isCombineModeLockFromVisualSettings || isCombineModeLockFromInsightsCompared
      }
      isCompareModeLock={isCompareModeLock}
      respondentProtectionNumber={respondentProtectionNumber}
      selectedMode={selectedMode}
      setMode={setModeHandler}
      showFilters={Department.showFilters(allDepartments)}
    />
  );
};
