export enum QuestionKind {
  QuestionOpinion = 101,
  QuestionRadioButton = 102,
  QuestionCheckbox = 103,
  QuestionNPS = 104, // Net Promoter Score
  QuestionCAS = 105, // Client Advocacy Score
  QuestionCommentsBox = 106,
  QuestionForm = 107,
  QuestionDemographic = 108,
}
