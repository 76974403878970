import React from 'react';
import { useSelector } from 'react-redux';

import { selectFormattedSliderDatesFromQueryParams } from 'util/selector/querySelector';

import { Typography } from '@mui/material';
import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { TypographyProps } from '@mui/material/Typography/Typography';

export type ReportParamsSelectionMessageProps = TypographyProps & {
  responsesNumber: number;
};

export const ReportParamsSelectionMessage: React.FC<ReportParamsSelectionMessageProps> = (
  props
) => {
  const { formattedToDate, formattedFromDate } = useSelector(
    selectFormattedSliderDatesFromQueryParams
  );
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);
  const { responsesNumber } = props;

  if (!isLifecycleProjectKind) {
    return null;
  }

  return (
    <Typography data-testid="reportParamsSelectionMessage" sx={{ fontWeight: 600 }} {...props}>
      Based on feedback from {responsesNumber} employees - {formattedFromDate} to {formattedToDate}
    </Typography>
  );
};
