import moment, { Moment } from 'moment';

type RespondentIndividualFeedbackAnswer = {
  question: string;
  answers: (string | null)[];
};

export type RespondentIndividualFeedbackDto = {
  advocacy: string;
  firstName: string;
  lastName: string;
  email: string;
  demographics: { name: string; value: string }[];
  dateSurveyCompleted: string;
  lineManager: string | null;
  departments: string[];
  report: RespondentIndividualFeedbackAnswer[];
};

export type RespondentIndividualFeedbackModel = {
  advocacy: string;
  firstName: string;
  lastName: string;
  email: string;
  demographics: { name: string; value: string }[];
  dateSurveyCompleted: Moment;
  lineManager: string | null;
  departments: string[];
  report: RespondentIndividualFeedbackAnswer[];
};

export const mapRespondentIndividualFeedbackDtoToModel = (
  dto: RespondentIndividualFeedbackDto
): RespondentIndividualFeedbackModel => {
  return {
    advocacy: dto.advocacy,
    firstName: dto.firstName,
    lastName: dto.lastName,
    email: dto.email,
    demographics: dto.demographics,
    dateSurveyCompleted: moment(dto.dateSurveyCompleted),
    lineManager: dto.lineManager,
    departments: dto.departments,
    report: dto.report,
  };
};
