import { InsightApi } from 'api/InsightApi';
import { Insight } from 'model/Insight';

import { QuestionMapper } from './QuestionMapper';
import { CompareGroupMapper } from './CompareGroupMapper';
import { OptionMapper } from './OptionMapper';

export class InsightMapper {
  constructor(
    private questionMapper: QuestionMapper,
    private groupMapper: CompareGroupMapper,
    private optionMapper: OptionMapper
  ) {}

  deserializeCombine(entry: InsightApi.EntryCombine): Insight.Combine {
    return {
      numberOfEmployees: entry.numberOfEmployees,
      data: entry.questions.map((item) => ({
        answers: item.answers.map((answer) => ({
          count: answer.count,
          score: answer.score,
          option: this.optionMapper.deserialize(answer.option),
          trendPlotData: answer.trendPlotData?.map((elem) => elem.value),
        })),
        question: this.questionMapper.deserialize(item.question),
        respondentProtection: !!item.respondentProtection,
      })),
    };
  }
}
