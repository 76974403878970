import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchRespondentIndividualFeedback,
  fetchRespondents,
} from 'reduxStore/respondent/asyncActions';
import { addAsyncCases } from 'shared/utils/redux';

import { RespondentState, STORE_NAME } from './initialState';

export const respondentSlice = createSlice({
  name: STORE_NAME,
  initialState: RespondentState.INITIAL_DOMAIN,
  reducers: {
    clearRespondents: (state) => {
      state.resource = RespondentState.INITIAL_DOMAIN.resource;
    },
    setRespondentsFilters: (state, action: PayloadAction<RespondentState.RespondentsFilters>) => {
      state.filters = action.payload;
    },
    setRespondentsTablePage: (state, action: PayloadAction<number>) => {
      state.respondentsTablePage = action.payload;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchRespondents, (state, action) => {
      state.resource = action.payload;
    });
    builder.addCase(fetchRespondentIndividualFeedback.fulfilled, (state, action) => {
      state.individualFeedback = action.payload;
    });
  },
});

export const {
  clearRespondents,
  setRespondentsFilters,
  setRespondentsTablePage,
} = respondentSlice.actions;
