import { css } from 'glamor';
import { GREY_BACKGROUND } from 'app/theme/colors';

export const toolTip = css({
  backgroundColor: `${GREY_BACKGROUND} !important`,
  opacity: '0.8 !important',
  '& p': {
    paddingTop: '5px',
  },
  '&.place-left::after': {
    borderLeftColor: `${GREY_BACKGROUND} !important`,
  },
  '&.place-right::after': {
    borderRightColor: `${GREY_BACKGROUND} !important`,
  },
  '&.place-top::after': {
    borderTopColor: `${GREY_BACKGROUND} !important`,
  },
  '&.place-bottom::after': {
    borderBottomColor: `${GREY_BACKGROUND} !important`,
  },
});
