import React from 'react';
import { useSelector } from 'react-redux';

import { selectHasExactMatch } from 'util/selector/routerSelector';

import { selectProjectKind, selectRespondentProtection } from 'reduxStore/project/selectors';
import { AppRoute } from 'app/route/app';
import { ErrorLabelWrapper } from 'component/ErrorHandler/ErrorHandler.s';
import { RespondentProtectionMode } from 'model/RespondentProtection';
import { NoResultsMessage } from 'component/NoResultsMessage/NoResultsMessage';

export type RespondentProtectionLabelProps = {
  isVariableRespondentProtection?: boolean;
};

export const RespondentProtectionLabel: React.FC<RespondentProtectionLabelProps> = (props) => {
  const { isVariableRespondentProtection } = props;
  const protectionSettings = useSelector(selectRespondentProtection);
  const isComment = useSelector(selectHasExactMatch(AppRoute.COMMENTS));
  const projectKind = useSelector(selectProjectKind);
  const threshold = isComment ? protectionSettings?.comment : protectionSettings?.closed;
  const variableThreshold = protectionSettings?.variableConfidentialityThreshold;

  const thresholdType =
    protectionSettings?.mode === RespondentProtectionMode.InvitedEmployees ? 'people' : 'responses';

  if (!projectKind) {
    return (
      <ErrorLabelWrapper data-testid="errorLabelWrapper">
        Something went wrong. Please refresh the page.
      </ErrorLabelWrapper>
    );
  }

  if (isVariableRespondentProtection) {
    return (
      <NoResultsMessage
        data-testid="variableRespondentProtectionMessage"
        title={`When this survey was live, one of the groups in this demographic you are viewing feedback for had fewer than ${variableThreshold} ${thresholdType}.`}
        description="To protect their confidentiality, their answers are restricted."
      />
    );
  }

  return (
    <NoResultsMessage
      data-testid="confidentialityProtectionMessage"
      title={`When this survey was live, the group you are viewing feedback for had fewer than ${threshold} ${thresholdType}.`}
      description="To protect their confidentiality, their answers are restricted."
    />
  );
};
