import { selectFiltersQuery, selectLifecycleFiltersQuery } from 'util/selector/querySelector';
import { selectReportOrCurrentProjectId } from 'util/selector/reportSelector';

import { insightApi } from 'container/insightApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { STORE_NAME } from 'reduxStore/comments/initialState';
import { serializeError } from 'shared/utils/redux';
import { Insight } from 'model/Insight';
import { InsightsComparedModel } from 'api/dto/new/InsightsCompared.dto';
import { InsightsLifecycleComparedModel } from 'api/dto/new/InsightsLifecycleCompared.dto';

// TODO: replace Insight.Combine and insightApi.combine to the newly created endpoint data model (https://buzzing.atlassian.net/browse/WB3-2298)
export const fetchInsightsCombined = createAsyncThunk<Insight.Combine>(
  `${STORE_NAME}/fetchInsightsCombined`,
  (_, { getState }) => {
    const projectId = selectReportOrCurrentProjectId(getState());
    const query = selectFiltersQuery(getState());

    return insightApi.combine(projectId, query);
  },
  { serializeError }
);

// TODO: replace Insight.Combine and insightApi.combine to the newly created endpoint data model (https://buzzing.atlassian.net/browse/WB3-2298)
export const fetchInsightsLifecycleCombined = createAsyncThunk<Insight.Combine>(
  `${STORE_NAME}/fetchInsightsLifecycleCombined`,
  (_, { getState }) => {
    const projectId = selectReportOrCurrentProjectId(getState());
    const query = selectLifecycleFiltersQuery(getState());

    return insightApi.combine(projectId, query);
  },
  { serializeError }
);

export const fetchInsightsCompared = createAsyncThunk<InsightsComparedModel>(
  `${STORE_NAME}/fetchInsightsCompared`,
  (_, { getState }) => {
    const projectId = selectReportOrCurrentProjectId(getState());
    const query = selectFiltersQuery(getState());

    return insightApi.insightsCompared(projectId, query);
  },
  { serializeError }
);

export const fetchInsightsLifecycleCompared = createAsyncThunk<InsightsLifecycleComparedModel>(
  `${STORE_NAME}/fetchInsightsLifecycleCompared`,
  (_, { getState }) => {
    const projectId = selectReportOrCurrentProjectId(getState());
    const query = selectLifecycleFiltersQuery(getState());

    return insightApi.insightsLifecycleCompared(projectId, query);
  },
  { serializeError }
);
