import React from 'react';

export namespace BackgroundCircle {
  export type Props = {
    width: number;
    radius: number;
    strokeWidth: number;
  };
}

export const BackgroundCircle: React.FC<BackgroundCircle.Props> = (props) => (
  <circle
    cx={props.width / 2}
    cy={props.width / 2}
    r={props.radius}
    strokeWidth={`${props.strokeWidth}px`}
    fill="none"
    stroke="#d2def5"
  />
);
