import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  parseQueryString,
  selectQueryParamObject,
  selectQueryString,
} from 'util/selector/routerSelector';

import { useAppDispatch } from 'reduxStore/appStore';
import { fetchRespondentIndividualFeedback } from 'reduxStore/respondent/asyncActions';

export const useFetchRespondentIndividualFeedback = () => {
  const dispatch = useAppDispatch();
  const queryString = useSelector(selectQueryString);
  const { projectId } = parseQueryString(queryString);
  const { respondentIds: respondentIdsObject } = useSelector(selectQueryParamObject('f'));

  useEffect(() => {
    const respondentIds = respondentIdsObject
      ? Object.values<string>(respondentIdsObject)
      : undefined;

    if (projectId && respondentIds) {
      dispatch(fetchRespondentIndividualFeedback({ projectId, respondentIds }));
    }
  }, [dispatch, projectId, respondentIdsObject]);
};
