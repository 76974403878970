import { TopicsApi } from 'api/TopicsApi';
import { Question } from 'model/Question';
import { Answer } from 'model/Answer';
import { Pagination } from 'model/Pagination';
import { QuestionAndAnswers } from 'model/QuestionAndAnswers';
import { SentimentEnum, SentimentStats } from 'model/Sentiment';
import { AdvocacyDtoEnum } from 'api/dto/Advocacy.dto';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'comments';

export namespace CommentsState {
  export type Domain = {
    questions: Resource<Question[]>;
    answers: Resource<Answer[]>;
    allAnswers: Resource<Answer[]>;
    all: Resource<QuestionAndAnswers[]>;
    selected: { id: number; optionId?: number } | null;
    pagination: Pagination | null;
    allSentimentTopics: TopicsApi.AllTopicsEntry;
    sentimentFilter: SentimentEnum[];
    topicFilter: (keyof TopicsApi.AllTopicsEntry)[];
    searchedText: string;
    sentimentStats: SentimentStats | null;
    advocacyFilter: AdvocacyDtoEnum[];
  };

  export const INITIAL_DOMAIN: CommentsState.Domain = {
    questions: Resource.createPendingResource([]),
    answers: Resource.createPendingResource([]),
    allAnswers: Resource.createResolvedResource({ data: [] }),
    all: Resource.createResolvedResource({ data: [] }),
    selected: null,
    pagination: null,
    allSentimentTopics: {},
    sentimentFilter: [],
    topicFilter: [],
    searchedText: '',
    sentimentStats: null,
    advocacyFilter: [],
  };
}
