import React, { useRef } from 'react';

import { Tooltip } from '@mui/material';
import { FlagNew } from 'component/FlagNew/FlagNew';
import {
  QuestionColumnWrapper,
  QuestionContent,
  QuestionText,
} from 'view/EngagementDriverByCategoryPage/QuestionTable/QuestionColumn/QuestionColumn.s';
import { Question } from 'model/Question';
import { useTooltip } from 'shared/hooks/useTooltip';

export type QuestionColumnProps = {
  question: Question;
};

export const QuestionColumn = ({ question }: QuestionColumnProps): React.JSX.Element => {
  const labelRef = useRef<HTMLParagraphElement>(null);
  const { isTooltipOpen, handleOpen, handleClose } = useTooltip(labelRef);
  return (
    <QuestionColumnWrapper>
      <QuestionContent>
        <Tooltip
          title={question.content}
          placement="top"
          enterTouchDelay={0}
          arrow
          open={isTooltipOpen}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <QuestionText data-testid="questionColumnContent" ref={labelRef}>
            {question.content}
          </QuestionText>
        </Tooltip>
        <FlagNew isLatest={question.isLatest} />
      </QuestionContent>
    </QuestionColumnWrapper>
  );
};
