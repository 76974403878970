import React from 'react';
import { useSelector } from 'react-redux';

import { ProjectKind } from 'model/ProjectKind';
import { ProjectNavigation } from 'component/Navigation/ProjectNavigation';
import { PulseProjectNavigation } from 'component/Navigation/PulseProjectNavigation';
import { selectProjectKind } from 'reduxStore/project/selectors';

export type NavigationPureProps = {
  projectKind: ProjectKind;
};

const NavigationPure = ({ projectKind }: NavigationPureProps) => {
  switch (projectKind) {
    case ProjectKind.PULSE_ON_DEMAND_PROJECT:
    case ProjectKind.PULSE_LIFECYCLE_PROJECT:
    case ProjectKind.PULSE_PROJECT:
    case ProjectKind.PULSE_TEMPLATE:
      return <PulseProjectNavigation />;

    case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
    case ProjectKind.PROJECT_NET_PROMOTER_SCORE:
    case ProjectKind.PROJECT_OVERALL_SCORE:
    case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      return <ProjectNavigation />;

    default:
      console.error('NO PROJECT KIND MATCHED NAVIGATION');
      return null;
  }
};

export const Navigation = () => {
  const projectKind = useSelector(selectProjectKind);

  return <NavigationPure projectKind={projectKind} />;
};
