import React from 'react';
import { useSelector } from 'react-redux';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import {
  selectEngagementDriversByCategoryCompare,
  selectEngagementDriversByCategoryCompareGroups,
  selectEngagementDriversByCategoryCompareGroupsLength,
  selectEngagementDriversByCategoryCompareQuestions,
} from 'reduxStore/engagementDrivers/selectors';
import { EngagementDriverByCategoryCompare } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCompare/EngagementDriverByCategoryCompare';
import { Question } from 'model/Question';
import { CompareGroup } from 'model/CompareGroup';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';

export type CategoryReportComparePureProps = {
  engagementDriverByCategoryCompare: OpinionQuestionDetails.Compare;
  groups: ReadonlyArray<CompareGroup>;
  questions: { question: Question; groups: ReadonlyArray<CompareGroup> }[];
  groupsLength: number;
};

export const CategoryReportComparePure: React.FC<CategoryReportComparePureProps> = (props) =>
  props.engagementDriverByCategoryCompare ? (
    <ReportContent
      title={props.engagementDriverByCategoryCompare.category!.name}
      component={
        <EngagementDriverByCategoryCompare
          engagementDriverByCategory={props.engagementDriverByCategoryCompare}
          groups={props.groups}
          groupsLength={props.groupsLength}
          questions={props.questions}
          isReportView
        />
      }
    />
  ) : null;

export const CategoryReportCompare: React.FC = () => {
  const groups = useSelector(selectEngagementDriversByCategoryCompareGroups);
  const questions = useSelector(selectEngagementDriversByCategoryCompareQuestions);
  const groupsLength = useSelector(selectEngagementDriversByCategoryCompareGroupsLength);
  const engagementDriverByCategoryCompare = useSelector(selectEngagementDriversByCategoryCompare);

  return (
    <CategoryReportComparePure
      groups={groups}
      questions={questions}
      groupsLength={groupsLength}
      engagementDriverByCategoryCompare={engagementDriverByCategoryCompare}
    />
  );
};
