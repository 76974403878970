import React, {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  DisplayOption,
  OptionList,
  OptionListItem,
  SelectBoxWrapper,
  SelectOptionLabel,
} from 'component/SelectBox/SelectBox.s';
import AngleDown from 'react-icons/lib/fa/angle-down';

export type MultiSelectBoxBaseProps<T> = {
  onChange: (values: T[]) => any;
  selected: T[];
  renderSelected: (selected: T[]) => React.JSX.Element;
  wrapper?: ComponentType<PropsWithChildren<{ visible: boolean }>>;
  children: ReactNode;
  compareValues?: (a: T, b: T) => boolean;
  disabled?: boolean;
  width?: number | string;
  'data-testid'?: string;
};

const DefaultWrapper: React.FC<PropsWithChildren<{ visible: boolean }>> = ({ children }) => (
  <>{children}</>
);

export const MultiSelectBoxBase = <T,>({
  onChange,
  selected,
  renderSelected,
  wrapper: Wrapper = DefaultWrapper,
  children,
  compareValues,
  disabled = false,
  width = '100%',
  'data-testid': dataTestId,
}: MultiSelectBoxBaseProps<T>) => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [disabled, handleClickOutside]);

  const toggleSelectBoxVisible = () => {
    if (!disabled) {
      setVisible(!visible);
    }
  };

  const defaultCompareValues = (a: T, b: T) => a === b;

  const toggleSelectedItem = (item: T) => {
    const comparator = compareValues || defaultCompareValues;
    const newSelected = selected.find((stateSelected) => comparator(stateSelected, item))
      ? selected.filter((stateSelected) => !comparator(stateSelected, item))
      : [...selected, item];

    onChange(newSelected);
  };

  return (
    <SelectBoxWrapper ref={wrapperRef} style={{ width }}>
      <DisplayOption disabled={disabled} data-testid={dataTestId} onClick={toggleSelectBoxVisible}>
        <Wrapper visible={visible}>
          <SelectOptionLabel>{renderSelected(selected)}</SelectOptionLabel>
          <AngleDown style={{ fontSize: 25, marginLeft: 10 }} />
        </Wrapper>
      </DisplayOption>
      <OptionList visible={visible}>
        {visible && (
          <OptionListItem>
            {React.Children.map(children, (child: ReactElement | any) =>
              React.cloneElement(child, {
                onClick: () => {
                  if (!child.props.disabled) {
                    toggleSelectedItem(child.props['data-item']);
                  }
                },
              })
            )}
          </OptionListItem>
        )}
      </OptionList>
    </SelectBoxWrapper>
  );
};
