import { styled } from '@mui/material';
import { VIEW_DETAILS_GREY, WHITE } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

export const EngagementViewWrapper = styled('div', {
  shouldForwardProp: (prop) => 'isReportView' !== prop.toString(),
})(() => ({
  background: WHITE,
  borderRadius: '10px',
  padding: '24px',
  '@media print': {
    padding: '0 24px',
  },
}));

export const EngagementViewHeadingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',

  h5: {
    alignSelf: 'center',
  },
});

export const EngagementBarsWrapper = styled('div')({
  '@media screen': {
    marginTop: '25px',
  },
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0 10px',
});

export const EngagementContentWrapper = styled('div')(
  {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'column',

    ':nth-child(12n)': {
      pageBreakAfter: 'always',
    },
    ':nth-child(2n) .engagementData': {
      padding: 0,
    },
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  ({ theme }) => ({
    width: '49%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
);

export const EngagementTitle = styled('h6')({
  ...typography.body1,
  display: 'block',
  ':hover': {
    cursor: 'pointer',
  },
});

export const EngagementData = styled('ul')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > li:first-child': {
    flex: '1 1 250px',
  },
  '& > li:first-child + h5': {
    flex: '0 1 50px',
  },
  '& > li:first-child + h5 ~ li': {
    flex: '0 0 65px',
  },
  gap: '8px',
  [theme.breakpoints.down('md')]: {
    '& > li:first-child + h5 ~ li': {
      flex: '0 0 45px',
    },
  },
}));

export const EngagementStatisticWrapper = styled('div')({
  minWidth: '40px',
  display: 'flex',
  justifyContent: 'center',
});

export const EngagementScore = styled('span')({
  fontWeight: 300,
  fontSize: 17,
});

export const EngagementDetails = styled('span')({
  fontWeight: 400,
  fontSize: 13,
  color: VIEW_DETAILS_GREY,
  ':hover': {
    cursor: 'pointer',
  },
  '@media print': {
    visibility: 'hidden',
  },
});

export const EngagementBarWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StatisticLabel = styled('div')({
  height: 20,
  width: 60,
  lineHeight: 1,
  bottom: 5,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: 13,
  textAlign: 'center',
});
