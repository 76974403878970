import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';
import { SearchState } from 'reduxStore/search/initialState';

export function selectDomain(state: AppState): SearchState.Domain {
  return state.search || SearchState.INITIAL_DOMAIN;
}

export const selectFiltersSearchText = createSelector(
  selectDomain,
  (domain) => domain && domain.filtersSearchText
);

export const selectReportSearchText = createSelector(
  selectDomain,
  (domain) => domain && domain.reportSearchText
);
