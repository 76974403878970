import React, { useState } from 'react';

export type UseTooltipReturnType = {
  /**
   * MUI Tooltip state
   */
  isTooltipOpen: boolean;
  /**ś
   * MUI Tooltip onOpen handler
   */
  handleOpen: () => void;
  /**
   * MUI Tooltip onClose handler
   */
  handleClose: () => void;
};

/**
 * Used for Material UI Tooltip to show when part of a label is hidden by overflow
 * @param {React.RefObject} ref - ref to an element which Material UI Tooltip is
 * wrapped
 * @returns {UseTooltipReturnType}
 *
 * @example
 * export const WithTooltip = ({labelText}) => {
 *   const labelRef = useRef(null);
 *   const { isTooltipOpen, handleOpen, handleClose } = useTooltip(labelRef);
 *   return <>
 *     <Tooltip
 *       title={labelText}
 *       placement="top"
 *       enterTouchDelay={0}
 *       arrow
 *       open={isTooltipOpen}
 *       onOpen={handleOpen}
 *       onClose={handleClose}
 *     >
 *       <span ref={labelRef}>{labelText}</span>
 *     </Tooltip>
 *   </>
 * }
 */
export const useTooltip = <T extends HTMLElement>(
  ref: React.RefObject<T>
): UseTooltipReturnType => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleOpen = () => {
    const { offsetWidth = 0, scrollWidth = 0 } = ref?.current || {};
    if (scrollWidth > offsetWidth) {
      setIsTooltipOpen(true);
    }
  };
  const handleClose = () => {
    setIsTooltipOpen(false);
  };

  return {
    handleOpen,
    handleClose,
    isTooltipOpen,
  };
};
