import React from 'react';
import { useSelector } from 'react-redux';

import { mapDataToCompareMatrix } from 'util/mapDataToCompareMatrix';
import { chunk } from 'util/chunkArray';

import { CompareMatrix } from 'component/CompareMatrix/CompareMatrix';
import { CompareGroup } from 'model/CompareGroup';
import { Question } from 'model/Question';
import { selectSimpleProjectCompareQuestions } from 'reduxStore/simpleProject/selectors';
import { SxProps } from '@mui/material';

export type OnDemandTableCompareProps = {
  title?: string;
  titleDataTestId?: string;
  sx?: SxProps;
  groups: string[];
  minResponses: number;
  isReportView: boolean;
};

export type OnDemandTableComparePropsPure = {
  questions: ReadonlyArray<{ question: Question; groups: ReadonlyArray<CompareGroup> }>;
} & OnDemandTableCompareProps;

export const OnDemandTableComparePure: React.FC<OnDemandTableComparePropsPure> = (props) => {
  const labelsValues = mapDataToCompareMatrix<string>(
    props.questions,
    ['question', 'content'],
    'label'
  );

  const labels = labelsValues.map((value) => ({
    content: value,
    contentType: 'percentage',
  }));

  const scores = chunk<number>(
    mapDataToCompareMatrix<number>(props.questions, ['groups', 'score'], 'data'),
    props.groups.length
  );

  const protectedScore = chunk<number>(
    mapDataToCompareMatrix<number>(props.questions, ['groups', 'respondentProtectionType'], 'data'),
    props.groups.length
  );

  const confidentialityProtection = chunk<number>(
    mapDataToCompareMatrix<number>(
      props.questions,
      ['groups', 'confidentialityProtection'],
      'data'
    ),
    props.groups.length
  );

  const values = scores.map((item, index) => ({
    value: item,
    respondentProtectionTypeArray: protectedScore[index],
    isConfidentialityProtection: confidentialityProtection[index],
  })) as any[];

  return (
    <CompareMatrix
      title={props.title}
      titleDataTestId={props.titleDataTestId}
      sx={props.sx}
      departments={props.groups}
      labels={labels}
      values={values}
      colorType="color"
      minResponses={props.minResponses}
      isReportView={props.isReportView}
    />
  );
};

export const OnDemandTableCompare: React.FC<OnDemandTableCompareProps> = (props) => {
  const questions = useSelector(selectSimpleProjectCompareQuestions);

  return <OnDemandTableComparePure {...props} questions={questions} />;
};
