import { useSelector } from 'react-redux';

import { selectIsTopicsAnalysisEnabled } from 'util/selector/sentimentSelector';

import {
  selectHasCommentPagePermission,
  selectHasReportPagePermission,
} from 'reduxStore/user/selectors';
import { AppRoute } from 'app/route/app';
import { ReportsPage } from 'view/ReportsPage/ReportsPage';
import { SummaryPage } from 'view/SummaryPage/SummaryPage';
import { InsightsPage } from 'view/InsightsPage/InsightsPage';
import { EngagementDriverByCategoryPage } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryPage';
import { selectIsLifecycleProjectKind } from 'reduxStore/project/selectors';
import { CommentsPage } from 'view/CommentsPage/CommentsPage';
import { IndividualFeedbackPage } from 'view/IndividualFeedbackPage/IndividualFeedbackPage';
import { TopicsPage } from 'view/TopicsPage/TopicsPage';
import { NotFoundProjectPage } from 'router/components/NotFound/NotFoundProjectPage';

import { NavigationItem } from '../models';

export const useProjectRoutes = (): NavigationItem[] => {
  const hasReportPagePermission = useSelector(selectHasReportPagePermission);
  const hasCommentPagePermission = useSelector(selectHasCommentPagePermission);
  const hasTopicsPagePermission = useSelector(selectIsTopicsAnalysisEnabled);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);

  return [
    {
      path: AppRoute.Home.Summary.REPORT,
      component: EngagementDriverByCategoryPage,
      canActivate: true,
    },
    {
      path: AppRoute.Home.REPORTS,
      component: ReportsPage,
      canActivate: hasReportPagePermission,
    },
    {
      path: AppRoute.Home.SUMMARY,
      component: SummaryPage,
      canActivate: true,
    },
    {
      path: AppRoute.INSIGHTS,
      component: InsightsPage,
      canActivate: true,
    },
    {
      path: AppRoute.COMMENTS,
      component: CommentsPage,
      canActivate: hasCommentPagePermission || isLifecycleProjectKind,
    },
    {
      path: AppRoute.INDIVIDUAL_FEEDBACK,
      component: IndividualFeedbackPage,
      canActivate: isLifecycleProjectKind,
    },
    {
      path: AppRoute.TOPICS,
      component: TopicsPage,
      canActivate: hasTopicsPagePermission,
    },
    {
      path: AppRoute.NOT_FOUND_PROJECT,
      component: NotFoundProjectPage,
      canActivate: true,
    },
  ];
};
