import { WordCloud } from 'model/WordCloud';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'wordCloud';

export namespace WordCloudState {
  export type Domain = Resource<WordCloud>;

  export const INITIAL_DOMAIN = Resource.createPendingResource<WordCloud>([]);
}

export type WordCloudState = {
  wordCloud: WordCloudState.Domain;
};
