import {
  RespondentProtectionEnum,
  RespondentProtectionEnumNullable,
} from 'model/RespondentProtection';

export type InsightsByGroupDto = Readonly<{
  score: number;
  count: number;
}>;

export type InsightsByGroupModel = Readonly<{
  score: number;
  count: number;
  respondentProtection: RespondentProtectionEnum.RESPONDENT_PROTECTION | null;
  variableConfidentialityProtection: RespondentProtectionEnum.VARIABLE_CONFIDENTIALITY_PROTECTION | null;
}>;

export const mapInsightsByGroupDtoToModel = (
  dto: InsightsByGroupDto,
  protection: RespondentProtectionEnumNullable
): InsightsByGroupModel => ({
  score: dto.score,
  count: dto.count,
  respondentProtection:
    protection === RespondentProtectionEnum.RESPONDENT_PROTECTION ? protection : null,
  variableConfidentialityProtection:
    protection === RespondentProtectionEnum.VARIABLE_CONFIDENTIALITY_PROTECTION ? protection : null,
});
