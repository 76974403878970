import { convertObjectToArray } from 'util/convertObjectToArray';

import { OpinionQuestionApi } from 'api/OpinionQuestionApi';
import { OpinionQuestion } from 'model/OpinionQuestion';

import { CategoryMapper } from './CategoryMapper';
import { OpinionQuestionDetailsMapper } from './OpinionQuestionDetailsMapper';
import { TrendMapper } from './TrendMapper';
import { QuestionMapper } from './QuestionMapper';
import { CompareGroupMapper } from './CompareGroupMapper';
import { BenchmarkMapper } from './BenchmarkMapper';

export class OpinionQuestionMapper {
  constructor(
    private categoryMapper: CategoryMapper,
    private questionMapper: QuestionMapper,
    private opinionQuestionDetailsMapper: OpinionQuestionDetailsMapper,
    private groupMapper: CompareGroupMapper,
    private trendMapper: TrendMapper
  ) {}

  deserializeCombine(entry: OpinionQuestionApi.CombineEntry): OpinionQuestion.Combine {
    return {
      category: entry.category && this.categoryMapper.deserialize(entry.category),
      score: entry.score,
      details: entry.details
        ? entry.details.map((detail) => this.opinionQuestionDetailsMapper.deserialize(detail))
        : [],
      benchmark: BenchmarkMapper.deserialize(entry.benchmark) || null,
      trends: entry.trends
        ? entry.trends.map((trend) => this.trendMapper.deserialize(trend))
        : undefined,
    };
  }

  deserializeCompare(
    entry: OpinionQuestionApi.CompareEntry,
    groups: { [key: number]: string },
    protections: { [key: number]: boolean }
  ): OpinionQuestion.Compare {
    return {
      category: entry.category && this.categoryMapper.deserialize(entry.category),
      groups: convertObjectToArray(entry.byGroup).map((group) =>
        this.groupMapper.deserialize(group, groups, protections)
      ),
      questions: convertObjectToArray(entry.questions).map((item) => ({
        groups: convertObjectToArray(item.byGroup).map((group) =>
          this.groupMapper.deserialize(group, groups, protections)
        ),
        question: this.questionMapper.deserialize(item.question),
      })),
    };
  }
}
