import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Line, LineChart, Tooltip as ChartTooltip, XAxis, YAxis } from 'recharts';
import { selectSimpleProjectRecommendPlotData } from 'reduxStore/simpleProject/selectors';
import { selectCurrentCompanyName } from 'reduxStore/project/selectors';
import { RecommendChartTooltip } from 'component/LifecycleCharts/RecommendChart/RecommendChartTooltip';
import {
  mapRecommendChartDtoArrayToModelArray,
  RecommendChartDataModel,
} from 'api/dto/RecommendChartData.dto';
import { MARINER } from 'app/theme/colors';
import { Typography } from '@mui/material';

export const RecommendChartReport: React.FC = () => {
  const recommendPlotData = useSelector(selectSimpleProjectRecommendPlotData);
  const companyName = useSelector(selectCurrentCompanyName);
  const [recommendChartData, setRecommendChartData] = useState<RecommendChartDataModel[]>([]);

  useEffect(() => {
    setRecommendChartData(mapRecommendChartDtoArrayToModelArray(recommendPlotData));
  }, [recommendPlotData]);

  return (
    <>
      <Typography variant="h5" data-testid="recommendChartTitle">
        Percentage who would recommend {companyName}
      </Typography>
      <LineChart width={450} height={300} data={recommendChartData} id="recommendChart">
        <XAxis dataKey="dateLabel" />
        <YAxis dataKey="value" domain={[0, 100]} unit="%" />
        <ChartTooltip content={<RecommendChartTooltip />} />
        <Line
          type="linear"
          dataKey="value"
          connectNulls
          unit="%"
          stroke={MARINER}
          strokeWidth={4}
          animationDuration={50}
        />
      </LineChart>
    </>
  );
};
