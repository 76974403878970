import { styled } from '@mui/material';
import { BLACK, IRON, WHITE, WORKBUZZ_ORANGE } from 'app/theme/colors';

export type WBNavButtonProps = {
  isActive: boolean;
  isDesktop: boolean;
  isSubNav: boolean;
  hasVisibleRibbonNavBarItems: boolean;
};

export const WBNavButton = styled('button', {
  shouldForwardProp: (prop) =>
    !['isDesktop', 'isActive', 'isSubNav', 'matchHref', 'hasVisibleRibbonNavBarItems'].includes(
      prop.toString()
    ),
})<WBNavButtonProps>(
  {
    height: '38px',
    background: WHITE,
    border: 'none',
    margin: '0 12px',
    position: 'relative',
    top: 3,

    '&:first-child': {
      marginLeft: 0,
    },

    ':hover': {
      cursor: 'pointer',
    },
  },
  (props: WBNavButtonProps) => ({
    ...(props.isDesktop || props.isSubNav
      ? getDesktopStyles(props)
      : {
          textAlign: 'left',
          alignSelf: 'start',
          margin: 0,
          padding: 0,
          borderBottom: `3px solid ${props.isActive ? WORKBUZZ_ORANGE : 'transparent'}`,

          '&:hover': {
            borderBottom: `3px solid ${props.isActive ? WORKBUZZ_ORANGE : IRON}`,
          },
        }),

    // need to fix subnav mobile,
  })
);

function getDesktopStyles(props: WBNavButtonProps) {
  if (props.isActive) {
    return {
      borderBottom: `3px solid ${
        props.isSubNav || !props.hasVisibleRibbonNavBarItems ? BLACK : WORKBUZZ_ORANGE
      }`,

      '&:before': {
        content: `''`,
        height: 35,
        width: 5,
        background: WHITE,
        position: 'absolute',
        left: -5,
        bottom: -5,
      },
      '&:after': {
        content: `''`,
        height: 10,
        width: 5,
        background: WHITE,
        position: 'absolute',
        right: -5,
        bottom: -5,
      },
    };
  }

  return {
    borderBottom: `3px solid ${
      props.isSubNav || !props.hasVisibleRibbonNavBarItems ? WORKBUZZ_ORANGE : IRON
    }`,
  };
}
