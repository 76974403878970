export enum QueryType {
  Advocacy = 'advocacy',
  AnswerLike = 'answer_like',
  /**
   * Low frequency pulses category view type on summary page
   */
  CategoryMode = 'categoryMode',
  Classification = 'c',
  DateAggregatedFrom = 'aggr_from',
  DateAggregatedTo = 'aggr_to',
  Department = 'd',
  Filter = 'f',
  Format = 'format',
  Language = 'language',
  Mode = 'mode',
  Option = 'option',
  Pagination = 'p',
  ProjectId = 'projectId',
  RespondentLike = 'respondent_like',
  Sentiment = 's',
  SortColumn = 'sortColumn',
  SortOrder = 'sortOrder',
  Topics = 't',
}
