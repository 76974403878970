import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLifecycleFiltersReportQuery } from 'util/selector/querySelector';

import { Line, LineChart, Tooltip as ChartTooltip, XAxis, YAxis } from 'recharts';
import { ResponseCountModel } from 'api/dto/ResponseCount.dto';
import { aggregatedResponseCountApi } from 'container/aggregatedResponseCountApi';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { MARINER } from 'app/theme/colors';
import { Typography } from '@mui/material';

import { ResponsesChartTooltip } from './ResponsesChartTooltip';

export const ResponsesChartReport: React.FC = () => {
  const projectId = useSelector(selectCurrentProjectId);
  const query = useSelector(selectLifecycleFiltersReportQuery);
  const [responsesCounts, setResponsesCounts] = useState<ResponseCountModel[]>([]);

  useEffect(() => {
    aggregatedResponseCountApi.list({ projectId: projectId, query }).then(setResponsesCounts);
  }, [projectId, query]);

  const defaultMaxValue = 1;
  const maxValue = Math.max(...responsesCounts.map(({ value }) => value)) || defaultMaxValue;

  return (
    <>
      <Typography pb="5px" variant="h5" data-testid="responsesChartTitle">
        Number of responses
      </Typography>
      <LineChart width={450} height={300} data={responsesCounts} id="responsesChart">
        <XAxis dataKey="dateLabel" />
        <YAxis dataKey="value" allowDecimals={false} domain={[0, maxValue]} />
        <ChartTooltip content={<ResponsesChartTooltip />} />
        <Line
          type="linear"
          dataKey="value"
          stroke={MARINER}
          strokeWidth={4}
          animationDuration={50}
        />
      </LineChart>
    </>
  );
};
