import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { useGetUserPermissionsCallback } from 'shared/hooks/useGetUserPermissionsCallback';
import { useAppDispatch } from 'reduxStore/appStore';
import { clearUserPermissions } from 'reduxStore/user/slice';
import { selectIsCurrentProjectResolved } from 'reduxStore/project/selectors';

export const useGetUserPermissionsOnCurrentProjectChange = () => {
  const dispatch = useAppDispatch();
  const isFetchCurrentProjectResolved = useSelector(selectIsCurrentProjectResolved);

  const getUserPermissions = useGetUserPermissionsCallback();

  useEffect(() => {
    if (isFetchCurrentProjectResolved) {
      getUserPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchCurrentProjectResolved]);

  useUnmount(() => {
    dispatch(clearUserPermissions());
  });
};
