import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsReportView } from 'util/selector/reportSelector';

import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import {
  selectHasSimpleProjectCompareQuestions,
  selectSimpleProjectCompareGroups,
  selectSimpleProjectCompareQuestionContent,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import { CasQuestionTableCompare } from 'view/CasPage/Compare/CasQuestionTableCompare';
import { CasBarChartCompare } from 'view/CasPage/Compare/CasBarChartCompare';
import { CasPieChartCompare } from 'view/CasPage/Compare/CasPieChartCompare';
import { CasQuestionCompare } from 'view/CasPage/Compare/CasQuestionCompare';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';

export type CasComparePagePureProps = {
  question?: string;
  hasQuestions: boolean;
  groups: string[];
  isReportView: boolean;
  minResponses: number;
  simpleProjectCompareResource: Resource<any>;
};

export const CasComparePagePure: React.FC<CasComparePagePureProps> = (props) => (
  <ResourceLoader resource={props.simpleProjectCompareResource}>
    <div data-testid="casComparePage">
      <SummaryCompareWidgets />

      <CasQuestionCompare
        title="Client Advocacy Score"
        groups={props.groups}
        minResponses={props.minResponses}
        isReportView={props.isReportView}
      />

      <CasBarChartCompare
        title={props.question}
        groups={props.groups}
        minResponses={props.minResponses}
        isReportView={props.isReportView}
      />

      <CasPieChartCompare
        title="Percentage of:"
        sx={props.hasQuestions ? { mb: 3 } : {}}
        groups={props.groups}
        minResponses={props.minResponses}
        isReportView={props.isReportView}
      />

      {props.hasQuestions && (
        <CasQuestionTableCompare
          title="Survey Questions"
          groups={props.groups}
          minResponses={props.minResponses}
          isReportView={props.isReportView}
        />
      )}
    </div>
  </ResourceLoader>
);

export const CasComparePage: React.FC = () => {
  const hasQuestions = useSelector(selectHasSimpleProjectCompareQuestions);
  const question = useSelector(selectSimpleProjectCompareQuestionContent);
  const groups = useSelector(selectSimpleProjectCompareGroups);
  const isReportView = useSelector(selectIsReportView);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);

  return (
    <CasComparePagePure
      hasQuestions={hasQuestions}
      question={question}
      groups={groups}
      isReportView={isReportView}
      minResponses={minResponses}
      simpleProjectCompareResource={simpleProjectCompareResource}
    />
  );
};
