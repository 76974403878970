import React from 'react';

import { Line, LineChart } from 'recharts';

export namespace TableTrendPlot {
  type DataPoint = {
    name: string;
    value: number | null;
  };

  export type Props = {
    data: DataPoint[];
    width: number;
    height: number;
  };
}

export const TableTrendPlot = ({
  data,
  width,
  height,
}: TableTrendPlot.Props): React.JSX.Element => (
  <div data-testid="tableTrendPlot">
    <LineChart width={width} height={height} data={data}>
      <Line type="linear" dataKey="value" dot={false} connectNulls stroke="gray" />
    </LineChart>
  </div>
);
