import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectLegalFiltersWithModeQuery } from 'util/selector/querySelector';

import { useAppDispatch } from 'reduxStore/appStore';
import { pushLegalQuery } from 'reduxStore/router/asyncActions';

export const usePushLegalQuery = () => {
  const dispatch = useAppDispatch();
  const legalFiltersWithModeQuery = useSelector(selectLegalFiltersWithModeQuery);

  useEffect(() => {
    dispatch(pushLegalQuery());
  }, [dispatch, legalFiltersWithModeQuery]);
};
