import React from 'react';

import { END_ORANGE, MIDDLE, START_ORANGE } from 'app/theme/colors';
import { BackgroundCircle } from 'component/PercentageBar/BackgroundCIrcle';
import { PercentageArc } from 'component/PercentageBar/PercentageArc';
import { Values } from 'component/PercentageBar/Values';

export namespace Circle {
  export type Props = {
    width: number;
    radius: number;
    strokeWidth: number;
    dashArray: number;
    dashOffset: number;
    value: number;
    type: string;
    responses?: number;
    invited?: number;
  };
}

export const Circle: React.FC<Circle.Props> = (props) => (
  <g>
    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor={END_ORANGE} />
      <stop offset="50%" stopColor={MIDDLE} />
      <stop offset="100%" stopColor={START_ORANGE} />
    </linearGradient>
    <BackgroundCircle width={props.width} radius={props.radius} strokeWidth={props.strokeWidth} />
    <PercentageArc
      width={props.width}
      radius={props.radius}
      strokeWidth={props.strokeWidth}
      dashOffset={props.dashOffset}
      dashArray={props.dashArray}
    />
    <Values
      value={props.value}
      responses={props.responses}
      invited={props.invited}
      type={props.type}
    />
  </g>
);
