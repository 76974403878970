import { formatDateToShortDateString } from 'util/formatDate';

import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';
import { selectIsPulseTemplateProjectKind } from 'reduxStore/project/selectors';
import { selectResponseRateByProjectId } from 'reduxStore/responseRate/selectors';
import { ResponseRate } from 'model/ResponseRate';

import { STORE_NAME } from './initialState';

const selectDomain = (state: AppState) => state[STORE_NAME];

export const selectSummaryCombineResource = createSelector(
  selectDomain,
  (domain) => domain.combine
);

export const selectSummaryCombine = createSelector(
  selectSummaryCombineResource,
  ({ data }) => data
);

export const selectSummaryCompareResource = createSelector(
  selectDomain,
  (domain) => domain.compare
);

export const selectSummaryCompare = createSelector(
  selectSummaryCompareResource,
  ({ data }) => data
);

export const selectSummaryCompareGroups = createSelector(
  selectSummaryCompare,
  (compare) => compare[0]?.groups
);

export const selectSummaryCompareGroupsLength = createSelector(
  selectSummaryCompareGroups,
  (groups) => groups?.length
);

export const selectTrends = createSelector(selectSummaryCombine, (combine) => combine?.trends);

export const selectBenchmark = createSelector(
  selectSummaryCombine,
  (combine) => combine?.benchmark
);

export const selectScore = createSelector(selectSummaryCombine, (combine) => combine?.score);

export const selectPulseSummaryResource = createSelector(selectDomain, (domain) => domain.pulses);

export const selectPulseSummary = createSelector(selectPulseSummaryResource, ({ data }) => data);

export const selectPulsesList = createSelector(selectPulseSummary, (pulses) => pulses.list);

export const selectAggregatedBenchmark = createSelector(
  selectPulseSummary,
  (summary) => summary.aggregatedBenchmark || null
);

export const selectProjectLabelByProjectId = (projectId: number) =>
  createSelector(
    selectIsPulseTemplateProjectKind,
    selectResponseRateByProjectId(projectId),
    (isPulseTemplate, responseRate) => {
      return isPulseTemplate
        ? 'your last WorkBuzz pulse'
        : formatDateToShortDateString((responseRate as ResponseRate)?.date);
    }
  );
