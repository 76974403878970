import React, { ChangeEvent } from 'react';

import { searchReport } from 'reduxStore/search/slice';
import { useAppDispatch } from 'reduxStore/appStore';

import { SearchIcon, SearchInputContent, SearchInputWrapper } from './ReportsSearchInput.s';

export const ReportSearchInput: React.FC = () => {
  const dispatch = useAppDispatch();
  const onSearch = (query: string) => dispatch(searchReport(query));

  return (
    <SearchInputWrapper>
      <SearchIcon />
      <SearchInputContent
        data-testid="reportSearchInput"
        type="text"
        placeholder="Search hierarchy"
        onChange={(event: ChangeEvent<HTMLInputElement>) => onSearch(event.target.value)}
      />
    </SearchInputWrapper>
  );
};
