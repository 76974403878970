import { ProjectKind, RegularProjectKind } from 'model/ProjectKind';
import { ProjectListItemModel } from 'model/ProjectListItem.model';
import moment from 'moment';

type BaseProjectListItem = Readonly<{
  closedAt: string;
  companyName: string;
  id: number;
  // No usage
  importStatus: boolean;
  name: string;
  publishedAt: string;
}>;

export type RegularProjectListItemDto = BaseProjectListItem &
  Readonly<{
    kind: RegularProjectKind;
  }>;

export type OnDemandProjectListItemDto = BaseProjectListItem &
  Readonly<{
    kind: ProjectKind.PULSE_ON_DEMAND_PROJECT;
    pulseTemplateId: number;
  }>;

export type PulseTemplateProjectListItemDto = BaseProjectListItem &
  Readonly<{
    kind: ProjectKind.PULSE_TEMPLATE;
    // Latest pulse lifecycle project IDs [onboardId, exitId]
    pulseLifecycleProjectIds: Readonly<[onboardId: number, exitId: number]>;
    // Latest pulse project is last element
    pulseProjectIds: Readonly<number[]>;
    // Latest pulse on demand is last element
    pulseOnDemandProjectIds: Readonly<number[]>;
  }>;

/**
 * DTO only for projects list on GET /api/v1/project
 */
export type ProjectListItemDto =
  | RegularProjectListItemDto
  | OnDemandProjectListItemDto
  | PulseTemplateProjectListItemDto;

export const mapDtoToProjectListItemModel = (dto: ProjectListItemDto): ProjectListItemModel => {
  const baseData = {
    closedAt: moment(dto.closedAt),
    companyName: dto.companyName,
    id: dto.id,
    name: dto.name,
    publishedAt: moment(dto.publishedAt),
  };
  if (dto.kind === ProjectKind.PULSE_TEMPLATE) {
    return {
      ...baseData,
      kind: dto.kind,
      pulseLifecycleProjects: dto.pulseLifecycleProjectIds.map((id) => ({ id })),
      pulseProjects: dto.pulseProjectIds.map((id) => ({ id })),
      pulseOnDemandProjects: dto.pulseOnDemandProjectIds.map((id) => ({ id })),
    };
  }
  if (dto.kind === ProjectKind.PULSE_ON_DEMAND_PROJECT) {
    return {
      ...baseData,
      kind: dto.kind,
      pulseTemplateId: dto.pulseTemplateId,
    };
  }

  // Regular Project
  return {
    ...baseData,
    kind: dto.kind,
  };
};
