import { SubmitType } from 'model/SubmitType';
import { CARDINAL_70, MANGO_TANGO_50, TEAL_80 } from 'app/theme/colors';

export const selectColorBySubmitType = (type: SubmitType) => {
  switch (type) {
    case SubmitType.NEGATIVE:
      return CARDINAL_70;
    case SubmitType.NEUTRAL:
      return MANGO_TANGO_50;
    case SubmitType.POSITIVE:
      return TEAL_80;
    default:
      return MANGO_TANGO_50;
  }
};
