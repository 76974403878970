import { AxiosInstance } from 'axios';
import { User } from 'model/User';
import { UserRole } from 'register/UserRole';

import { UserMapper } from '../mapper/UserMapper';

export namespace UserApi {
  export type Entry = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: UserRole[];
    exp: number;
    groups?: PermissionGroupEntry[];
    permissions?: PermissionEntry[];
  };
  export type PermissionGroupEntry = {
    id: string;
    name: string;
    objectIdentifier: string;
    permissions: string[];
  };
  export type PermissionEntry = {
    objectId: string;
    objectType: string;
  };
}

export class UserApi {
  constructor(private client: AxiosInstance, private userMapper: UserMapper) {}

  fetchUser(): Promise<User> {
    return this.client
      .get('api/auth/user')
      .then((response) => this.userMapper.deserialize(response.data.data));
  }

  getUserPermissions(projectId: number): Promise<User> {
    return this.client
      .get('api/auth/extended-user')
      .then((response) => this.userMapper.deserialize(response.data.data, projectId));
  }
}
