import { departmentSlice } from 'reduxStore/departments/slice';
import { StateFromReducersMapObject } from 'redux';
import { respondentSlice } from 'reduxStore/respondent/slice';
import { searchSlice } from 'reduxStore/search/slice';
import { responseRateSlice } from 'reduxStore/responseRate/slice';
import { userSlice } from 'reduxStore/user/slice';
import { overallSurveySlice } from 'reduxStore/overallSurvey/slice';
import { commentsSlice } from 'reduxStore/comments/slice';
import { topicsSlice } from 'reduxStore/topics/slice';
import { wordCloudSlice } from 'reduxStore/wordCloud/slice';
import { insightsSlice } from 'reduxStore/insights/slice';
import { filteringSlice } from 'reduxStore/filtering/slice';
import { reportSlice } from 'reduxStore/report/slice';
import { summarySlice } from 'reduxStore/summary/slice';

import { demographySlice } from './demography/slice';
import { engagementDriversSlice } from './engagementDrivers/slice';
import { visualSettingsSlice } from './visualSettings/slice';
import { simpleProjectSlice } from './simpleProject/slice';
import { projectSlice } from './project/slice';

export const appReducerMap = {
  [commentsSlice.name]: commentsSlice.reducer,
  [demographySlice.name]: demographySlice.reducer,
  [departmentSlice.name]: departmentSlice.reducer,
  [engagementDriversSlice.name]: engagementDriversSlice.reducer,
  [filteringSlice.name]: filteringSlice.reducer,
  [insightsSlice.name]: insightsSlice.reducer,
  [overallSurveySlice.name]: overallSurveySlice.reducer,
  [reportSlice.name]: reportSlice.reducer,
  [respondentSlice.name]: respondentSlice.reducer,
  [responseRateSlice.name]: responseRateSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [simpleProjectSlice.name]: simpleProjectSlice.reducer,
  [summarySlice.name]: summarySlice.reducer,
  [topicsSlice.name]: topicsSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [visualSettingsSlice.name]: visualSettingsSlice.reducer,
  [wordCloudSlice.name]: wordCloudSlice.reducer,
  [projectSlice.name]: projectSlice.reducer,
};

export type AppState = StateFromReducersMapObject<typeof appReducerMap>;
