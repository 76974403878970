import React from 'react';

import { TextFlagContent } from 'component/FlagNew/FlagNew.s';

export namespace TextFlagNew {
  export type Props = {
    isLatest?: boolean;
  };
}

export const TextFlagNew: React.FC<TextFlagNew.Props> = ({ isLatest }) =>
  isLatest ? <TextFlagContent>(New)</TextFlagContent> : null;
