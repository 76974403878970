export const STORE_NAME = 'report';

export namespace ReportState {
  export type Domain = {
    pendingReports: string[];
  };

  export const INITIAL_DOMAIN: Domain = {
    pendingReports: [],
  };
}
