import React from 'react';
import { useSelector } from 'react-redux';

import { selectLegalFiltersWithModeQueryAsObject } from 'util/selector/querySelector';

import { compilePath, CompilePathQuery } from 'router/compilePath';
import { AppRoute } from 'app/route/app';
import { Project } from 'model/Project';
import {
  selectCurrentProjectId,
  selectIsLifecycleProjectKind,
  selectIsPulseOrTemplateProjectKind,
  selectIsPulseProjectKind,
  selectQuestionsStats,
} from 'reduxStore/project/selectors';
import { selectHasCommentPagePermission } from 'reduxStore/user/selectors';
import { getAppConfig } from 'app/getConfig';
import { WBNavItem } from 'component/NavItem/WBNavItem';
import { useGetVisibleRibbonNavBarItems } from 'component/RibbonNavBar/hooks/useGetVisibleRibbonNavBarItems';

export type NavigationMenuItemsProps = {
  currentProjectId: string;
  queryObject: CompilePathQuery;
  showActLink: boolean;
  showComments: boolean;
  showIndividualFeedback: boolean;
  showInsights: boolean;
  hasVisibleRibbonNavBarItems: boolean;
};

export const NavigationMenuItemsPure: React.FC<NavigationMenuItemsProps> = ({
  currentProjectId,
  queryObject,
  showActLink,
  showComments,
  showIndividualFeedback,
  showInsights,
  hasVisibleRibbonNavBarItems,
}) => {
  const items = [
    {
      label: 'Home',
      matchHref: AppRoute.HOME_PATTERN,
      url: compilePath(AppRoute.Home.SUMMARY, { id: currentProjectId }, queryObject),
      visible: true,
    },
    {
      label: 'Insights',
      matchHref: AppRoute.INSIGHTS,
      url: compilePath(AppRoute.INSIGHTS, { id: currentProjectId }, queryObject),
      visible: showInsights,
    },
    {
      label: 'Comments',
      matchHref: [AppRoute.COMMENTS, AppRoute.TOPICS],
      url: compilePath(AppRoute.COMMENTS, { id: currentProjectId }, queryObject),
      visible: showComments,
    },
    {
      label: 'Individual Feedback',
      matchHref: AppRoute.INDIVIDUAL_FEEDBACK,
      url: compilePath(AppRoute.INDIVIDUAL_FEEDBACK, { id: currentProjectId }, queryObject),
      visible: showIndividualFeedback,
    },
    {
      label: 'ACT',
      url: `${getAppConfig().ACT_UI_URL}/landing`,
      visible: showActLink,
    },
  ];

  const visibleItems = items.filter(({ visible }) => visible);

  return (
    <>
      {visibleItems.map((item, index) => {
        const itemDataTestId = `item${item.label.replace(/\s/g, '')}`;
        return (
          <WBNavItem
            key={index}
            label={item.label}
            href={item.url}
            matchHref={item.matchHref}
            data-testid={itemDataTestId}
            isSubNav={false}
            hasVisibleRibbonNavBarItems={hasVisibleRibbonNavBarItems}
          />
        );
      })}
    </>
  );
};

export const NavigationMenuItems: React.FC = () => {
  const questionsStats = useSelector(selectQuestionsStats);
  const isPulseOrTemplateProjectKind = useSelector(selectIsPulseOrTemplateProjectKind);
  const isLifecycleProjectKind = useSelector(selectIsLifecycleProjectKind);
  const hasCommentPagePermission = useSelector(selectHasCommentPagePermission);
  const visibleRibbonNavBarItems = useGetVisibleRibbonNavBarItems();
  const hasVisibleRibbonNavBarItems = visibleRibbonNavBarItems.length > 0;

  const stateProps: NavigationMenuItemsProps = {
    currentProjectId: useSelector(selectCurrentProjectId).toString(),
    queryObject: useSelector(selectLegalFiltersWithModeQueryAsObject),
    showActLink: useSelector(selectIsPulseProjectKind),
    showComments:
      (hasCommentPagePermission || isLifecycleProjectKind) &&
      Project.showCommentsTab(questionsStats, !isPulseOrTemplateProjectKind),
    showIndividualFeedback: isLifecycleProjectKind,
    showInsights: Project.showInsightsTab(questionsStats, !isPulseOrTemplateProjectKind),
    hasVisibleRibbonNavBarItems,
  };

  return <NavigationMenuItemsPure {...stateProps} />;
};
