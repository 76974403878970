import { ProjectKind, RegularProjectKind, PulseTemplateChildProjectKind } from 'model/ProjectKind';
import { compilePath } from 'router/compilePath';
import { AppRoute } from 'app/route/app';

type ProjectArg = { id: number } & (
  | {
      kind: ProjectKind.PULSE_TEMPLATE;
      pulseProjects: Readonly<{ id: number }[]>;
      pulseOnDemandProjects: Readonly<{ id: number }[]>;
      pulseLifecycleProjects: Readonly<{ id: number }[]>;
    }
  | {
      kind: RegularProjectKind | PulseTemplateChildProjectKind;
    }
);

export const projectUrlBuilder = {
  getDefault: (project: ProjectArg) => {
    let idParam: number = project.id;

    if (project.kind === ProjectKind.PULSE_TEMPLATE) {
      const { pulseProjects, pulseOnDemandProjects, pulseLifecycleProjects } = project;
      if (pulseProjects.length > 0) {
        idParam = pulseProjects[pulseProjects.length - 1].id;
      } else if (pulseOnDemandProjects?.length > 0) {
        idParam = pulseOnDemandProjects[pulseOnDemandProjects.length - 1].id;
      } else if (pulseLifecycleProjects.length > 0) {
        idParam = pulseLifecycleProjects[0].id;
      } else {
        console.warn(
          `Project id=${project.id},  kind=${ProjectKind.PULSE_TEMPLATE} should contain at least 1 child`
        );
      }
    }

    return compilePath(AppRoute.Home.SUMMARY, {
      id: idParam,
    });
  },
} as const;
