import React from 'react';

import { ComponentWrapper } from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { Statistics } from 'component/Statistics/Statistics';
import { Trend } from 'model/Trend';
import { Typography } from '@mui/material';

export namespace TrendWidget {
  export type Props = {
    isReportView?: boolean;
    trends?: ReadonlyArray<Trend>;
  };
}

export const TrendWidget: React.FC<TrendWidget.Props> = (props) => {
  const { trends, isReportView } = props;
  if (!trends || trends.length === 0) {
    return null;
  }

  return (
    <>
      {trends.map((trend, index) => (
        <ComponentWrapper
          key={index}
          index={index}
          data-testid={`trendWidget-${index}`}
          noPaddings={trends.length > 1}
        >
          <Typography variant="h5" data-testid="trendWidgetLabel" mb="40px">
            {trend.label}
          </Typography>
          <Statistics
            score={trend.diff}
            position="vertical"
            arrowSize={45}
            fontSize={35}
            arrowBottomMargin={10}
            isLarge
            isReportView={isReportView}
          />
        </ComponentWrapper>
      ))}
    </>
  );
};
