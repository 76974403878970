import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  DropdownList,
  DropdownListItem,
  DropdownMenu,
  DropdownMenuLabel,
  DropdownWrapper,
} from 'component/Dropdown/Dropdown.s';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { SONIC_SILVER } from 'app/theme/colors';

export type DropdownProps = {
  children: React.ReactNode;
  label: string;
  noArrow?: boolean;
  'data-testid'?: string;
  onToggle?: (isVisible: boolean) => void;
};

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  noArrow = false,
  'data-testid': dataTestId,
  onToggle,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const toggleDropdown = () => {
    const newState = !visible;
    setVisible(newState);
    onToggle?.(newState);
  };

  return (
    <DropdownWrapper ref={wrapperRef}>
      <DropdownMenu onClick={toggleDropdown} data-testid={dataTestId}>
        <DropdownMenuLabel>
          <Typography variant="body1">{label}</Typography>
        </DropdownMenuLabel>
        {!noArrow && (
          <>
            <ExpandLess style={{ display: visible ? 'block' : 'none', fill: SONIC_SILVER }} />
            <ExpandMore style={{ display: !visible ? 'block' : 'none', fill: SONIC_SILVER }} />
          </>
        )}
      </DropdownMenu>
      <DropdownList visible={visible}>
        {visible && <DropdownListItem>{children}</DropdownListItem>}
      </DropdownList>
    </DropdownWrapper>
  );
};
