type ScoreCases<T> = {
  negative: T;
  neutral: T;
  positive: T;
};

export const forScoreCases = (score: number, cases: ScoreCases<any>) => {
  if (score < 0) {
    return cases.negative;
  } else if (score === 0) {
    return cases.neutral;
  } else {
    return cases.positive;
  }
};
