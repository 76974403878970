import qs from 'qs';
import { QueryType } from 'model/QueryType';
import { SummaryCategoryViewMode } from 'register/ModeType';
import { SortBy } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/utils';

export type QueryDto = {
  [key in QueryType]?: any;
};

const stringifyQuery = (dto: QueryDto) => {
  return qs.stringify(dto, { encode: false, skipNulls: true });
};

const build = (...queryStrings: string[]): string => {
  return `${queryStrings[0].startsWith('?') ? '' : '?'}${queryStrings
    .filter((part) => part !== '')
    .join('&')}`;
};

const stringifyPagination = (page: number, listLength?: number): string => {
  return stringifyQuery({
    [QueryType.Pagination]: {
      index: page,
      length: listLength,
    },
  });
};

export const stringifyFilter = (dto: QueryDto): string => {
  return stringifyQuery({
    [QueryType.Filter]: {
      ...dto,
    },
  });
};

export const stringifyFormat = (formatType: string): string => {
  return `${QueryType.Format}=${formatType}`;
};

export const stringifyLanguage = (lang: string): string => `${QueryType.Language}=${lang}`;

export const stringifyCategoryMode = (mode: SummaryCategoryViewMode): string =>
  `${QueryType.CategoryMode}=${mode}`;

export const stringifySortBy = ({ column, order }: SortBy): string =>
  column && order ? `${QueryType.SortColumn}=${column}&${QueryType.SortOrder}=${order}` : '';

export const queryDto = {
  build,
  stringifyCategoryMode,
  stringifyFilter,
  stringifyFormat,
  stringifyLanguage,
  stringifyPagination,
  stringifySortBy,
};
