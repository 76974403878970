import React from 'react';

import { Tooltip } from '@mui/material';

export namespace ValueLabelComponent {
  export type OwnProps = {
    children: React.ReactElement;
    open: boolean;
    value: number;
  };
  export type Props = OwnProps;
}

export const ValueLabelComponent = ({
  children,
  open,
  value,
}: ValueLabelComponent.Props): React.JSX.Element => (
  <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
    {children}
  </Tooltip>
);
