import React, { useEffect, useRef } from 'react';

import { ScaleBand, ScaleLinear, ScalePoint } from 'd3-scale';
import { axisBottom, AxisContainerElement, axisLeft } from 'd3-axis';
import { select as d3Select } from 'd3-selection';
import { colorLine, textStyle } from 'component/BarChart/Axes.s';
import moment from 'moment';
import { BarChart } from 'component/BarChart/BarChart';

export type AxesProps = {
  xScale: ScaleBand<string> | ScalePoint<string>;
  yScale: ScaleLinear<number, number>;
  margin: BarChart.Margin;
  height: number;
  width: number;
  isPulseSurveyChart?: boolean;
  unit?: string;
};

export const Axes: React.FC<AxesProps> = ({
  xScale,
  yScale,
  margin,
  height,
  width,
  isPulseSurveyChart = false,
  unit,
}) => {
  const xAxisRef = useRef<AxisContainerElement | any>(null);
  const yAxisRef = useRef<AxisContainerElement | any>(null);

  useEffect(() => {
    renderXAxis();
    renderYAxis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xScale, yScale, width]);

  const xAxisPadding = isPulseSurveyChart ? 7 : 3;
  const generateXAxis = () => axisBottom(xScale).tickPadding(xAxisPadding).tickSize(0);

  const renderXAxis = () => {
    if (isPulseSurveyChart) {
      const axis = generateXAxis().tickFormat((value) =>
        moment(value).format("MMM 'YY").toString()
      );
      d3Select(xAxisRef.current).call(axis);
    } else {
      const axis = generateXAxis();

      d3Select(xAxisRef.current).call(axis);
    }
  };

  const renderYAxis = () => {
    const axis = axisLeft(yScale)
      .tickPadding(22)
      .tickSize(-width)
      .tickFormat((value) => `${value}${unit ? unit : ''}`);

    if (isPulseSurveyChart) {
      axis.tickValues([0, 25, 50, 75, 100]);
    }

    d3Select(yAxisRef.current).call(axis);
  };

  return (
    <g data-testid="axes">
      <g
        data-testid="xAxis"
        ref={(el) => (xAxisRef.current = el)}
        className={`${colorLine} ${textStyle}`}
        transform={`translate(0, ${height - margin.bottom + 5})`}
      />
      <g
        data-testid="yAxis"
        ref={(el) => (yAxisRef.current = el)}
        className={`${colorLine} ${textStyle}`}
        transform={`translate(${margin.left}, 0)`}
      />
    </g>
  );
};
