import { createAsyncThunk } from '@reduxjs/toolkit';
import { wordCloudApi } from 'container/wordCloudApi';
import { STORE_NAME } from 'reduxStore/wordCloud/initialState';

export const fetchWordCloud = createAsyncThunk(
  `${STORE_NAME}/fetchWordCloud`,
  ({
    projectId,
    questionId,
    query,
  }: {
    projectId?: number;
    questionId?: number;
    query: string;
  }) => {
    if (!projectId || !questionId) {
      return Promise.reject(
        new Error('Cannot fetch word cloud if there is no project or selected question id')
      );
    }

    return wordCloudApi.fetchWordCloud(projectId, questionId, query);
  }
);
