import { createTheme } from '@mui/material/styles';
import { BLACK, PAGE_BACKGROUND, WHITE } from 'app/theme/colors';
import { breakpoints } from 'app/theme/breakpoints';
import { typography } from 'app/theme/typography';

export const theme = createTheme({
  breakpoints,
  spacing: 8,
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: () => ({
        body: {
          fontFamily: typography.fontFamily,
          backgroundColor: PAGE_BACKGROUND,
          '@media print': {
            backgroundColor: WHITE,
          },
        },
      }),
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...typography.subtitle2,
          backgroundColor: BLACK,
          borderRadius: '4px',
          padding: '10px 16px',
          maxWidth: 500,
          color: `${WHITE} !important`,
          textAlign: 'center',
        },
        popper: {
          ...typography.subtitle2,
          color: WHITE,
        },
        arrow: {
          color: BLACK,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
  },
});
