import { AxiosInstance } from 'axios';
import { ReportCategoryQuery } from 'api/ReportCategory.query';

export namespace DemographyApi {
  export type Entry = {
    id: number;
    ref: string;
    title: string;
    classifications: ClassificationEntry[];
  };

  export type ClassificationEntry = {
    id: number;
    ref: string;
    title: string;
  };
}

export class DemographyApi {
  constructor(private client: AxiosInstance) {}

  list(
    projectId: number,
    query: { reportCategory?: ReportCategoryQuery }
  ): Promise<DemographyApi.Entry[]> {
    return (
      this.client
        // TODO rename prefix to reportCategory
        .get(`/api/v1/project/${projectId}/demography`, {
          params: { prefix: query.reportCategory },
        })
        .then((response) => response.data.data)
    );
  }
}
