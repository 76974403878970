import { GroupedClassifications } from 'util/createFiltersQuery';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { individualFeedbackApi } from 'container/individualFeedbackApi';
import { AdvocacyDto } from 'api/dto/Advocacy.dto';
import { PaginatedResponse } from 'model/PaginatedResponse';
import { RespondentModel } from 'api/dto/Respondent.dto';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export type FetchRespondentsProps = {
  advocacyFilter: AdvocacyDto[];
  classifications: GroupedClassifications;
  departments: number[];
  limit: number;
  page: number;
  projectId: number;
  respondentLikeFilter: string;
};

export const fetchRespondents = createAsyncThunk<
  PaginatedResponse<RespondentModel[]>,
  FetchRespondentsProps
>(
  `${STORE_NAME}/fetchRespondents`,
  (props) => {
    return individualFeedbackApi.fetchRespondents(props);
  },
  { serializeError }
);

export const fetchRespondentIndividualFeedback = createAsyncThunk(
  `${STORE_NAME}/fetchRespondentIndividualFeedback`,
  ({ projectId, respondentIds }: { projectId: string; respondentIds: string[] }) => {
    return individualFeedbackApi.fetchRespondentIndividualFeedback({ projectId, respondentIds });
  },
  { serializeError }
);
