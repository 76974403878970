import React from 'react';
import { useSelector } from 'react-redux';

import { SummaryCombineWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCombineWidgets/SummaryCombineWidgets';
import { CasChartsWrapper, CasContentWrapper } from 'view/CasPage/CasPage.s';
import { PieChart } from 'component/PieChart/PieChart';
import { BarChart } from 'component/BarChart/BarChart';
import {
  selectHasSimpleProjectCombineQuestions,
  selectSimpleProjectCombineBarChart,
  selectSimpleProjectCombinePieChart,
  selectSimpleProjectCombineQuestionContent,
  selectSimpleProjectCombineQuestions,
  selectSimpleProjectCombineResource,
} from 'reduxStore/simpleProject/selectors';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { BarChartModel } from 'model/BarChart';
import { PieChart as PieChartModel } from 'model/PieChart';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { SortableQuestionTable } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionTable';
import { FeedbackWrapper, TableWrapper } from 'view/LifecyclePage/LifecyclePage.s';
import { Typography } from '@mui/material';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';

export type CasCombinePagePureProps = {
  questions: OpinionQuestionDetails.Combine[];
  hasQuestions: boolean;
  isAdmin: boolean;
  barChart?: BarChartModel[];
  pieChart?: PieChartModel[];
  casQuestion?: string;
  simpleProjectCombineResource: Resource<any>;
};

export const CasCombinePagePure: React.FC<CasCombinePagePureProps> = (props) => (
  <ResourceLoader resource={props.simpleProjectCombineResource}>
    <div data-testid="casCombinePage">
      <SummaryCombineWidgets />
      <CasContentWrapper>
        <FeedbackWrapper style={{ marginBottom: '24px' }}>
          <Typography data-testid="casQuestionText" variant="h5">
            {props.casQuestion}
          </Typography>
          <ScrollBar overflow="horizontal" float="none" light>
            <CasChartsWrapper>
              <PieChart data={props.pieChart} />
              <BarChart data={props.barChart} />
            </CasChartsWrapper>
          </ScrollBar>
        </FeedbackWrapper>
        {props.hasQuestions && (
          <FeedbackWrapper>
            <Typography variant="h5">Survey Questions</Typography>

            <TableWrapper>
              <ScrollBar overflow="horizontal" float="none" light>
                <SortableQuestionTable data={props.questions} shouldRenderCategory={false} />
              </ScrollBar>
            </TableWrapper>
          </FeedbackWrapper>
        )}
      </CasContentWrapper>
    </div>
  </ResourceLoader>
);

export const CasCombinePage: React.FC = () => {
  const hasQuestions = useSelector(selectHasSimpleProjectCombineQuestions);
  const questions = useSelector(selectSimpleProjectCombineQuestions);
  const casQuestion = useSelector(selectSimpleProjectCombineQuestionContent);
  const isAdmin = useSelector(selectIsAdmin);
  const barChart = useSelector(selectSimpleProjectCombineBarChart);
  const pieChart = useSelector(selectSimpleProjectCombinePieChart);
  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);

  return (
    <CasCombinePagePure
      hasQuestions={hasQuestions}
      questions={questions}
      casQuestion={casQuestion}
      isAdmin={isAdmin}
      barChart={barChart}
      pieChart={pieChart}
      simpleProjectCombineResource={simpleProjectCombineResource}
    />
  );
};
