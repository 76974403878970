import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { PageHeader } from 'component/PageHeader/PageHeader';
import { PageContentWrapper } from 'shared/styles/layout';
import { OverallCombinePage } from 'view/OverallPage/OverallCombinePage';
import { OverallComparePage } from 'view/OverallPage/OverallComparePage';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import { selectCurrentProjectName } from 'reduxStore/project/selectors';
import { OverallSurveyState } from 'reduxStore/overallSurvey/initialState';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import {
  selectOverallSurveyCombineResource,
  selectOverallSurveyCompareResource,
} from 'reduxStore/overallSurvey/selectors';

export type OverallPagePropsPure = {
  combineResource: OverallSurveyState.Domain['combine'];
  compareResource: OverallSurveyState.Domain['compare'];
  isCompareMode: boolean;
  projectName: string;
};

export const OverallPagePure: React.FC<OverallPagePropsPure> = ({
  combineResource,
  compareResource,
  isCompareMode,
  projectName,
}) => {
  return (
    <>
      <ResourceLoader
        resource={isCompareMode ? compareResource : combineResource}
        rejected={(error) => (
          <>
            <PageHeader title={projectName} isDownloadDisabled />
            <PageContentWrapper>
              <ErrorHandler error={error} />
            </PageContentWrapper>
          </>
        )}
      >
        <PageHeader title={projectName} />
        <PageContentWrapper>
          {isCompareMode ? <OverallComparePage /> : <OverallCombinePage />}
        </PageContentWrapper>
      </ResourceLoader>
    </>
  );
};

export const OverallPage: React.FC = () => {
  const projectName = useSelector(selectCurrentProjectName);
  const isCompareMode = useSelector(selectIsCompareMode);
  const combineResource = useSelector(selectOverallSurveyCombineResource);
  const compareResource = useSelector(selectOverallSurveyCompareResource);

  return (
    <OverallPagePure
      combineResource={combineResource}
      compareResource={compareResource}
      projectName={projectName}
      isCompareMode={isCompareMode}
    />
  );
};
