import React from 'react';
import { useSelector } from 'react-redux';

import { getAppConfig } from 'app/getConfig';
import { selectIsAdmin, selectIsCircleAdmin, selectIsClientAdmin } from 'reduxStore/user/selectors';
import { TopNavBar } from 'component/TopNavBar/TopNavBar';
import { Link } from 'component/Navigation/Navigation.s';
import { Menu as RibbonNavBarMenu } from 'component/RibbonNavBar/RibbonNavBar.s';

export const NavigationWithoutProject: React.FC = () => {
  const adminLink = getAppConfig()?.ADMIN_UI_URL;
  const isAdmin = useSelector(selectIsAdmin);
  const isClientAdmin = useSelector(selectIsClientAdmin);
  const isCircleAdmin = useSelector(selectIsCircleAdmin);

  const shouldShowAdminLink = adminLink && (isAdmin || isClientAdmin || isCircleAdmin);

  return (
    <>
      <TopNavBar>
        {shouldShowAdminLink && (
          <Link href={adminLink} target="_blank">
            Admin
          </Link>
        )}
      </TopNavBar>
      <RibbonNavBarMenu />
    </>
  );
};
