import { createFiltersQuery } from 'util/createFiltersQuery';
import {
  selectModeType,
  selectReportCategoryQuery,
  selectSorting,
  selectSummaryCategoryViewMode,
} from 'util/selector/querySelector';
import { selectReportOrCurrentProjectId } from 'util/selector/reportSelector';

import { selectSelectedClassifications } from 'reduxStore/demography/selectors';
import { pushQuery } from 'reduxStore/router/asyncActions';
import {
  selectIsDepartmentSelected,
  selectSelectedDepartmentIds,
} from 'reduxStore/departments/selectors';
import { departmentApi } from 'container/departmentApi';
import { departmentMapper } from 'container/departmentMapper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'shared/utils/redux';
import { Department } from 'model/Department';

import { STORE_NAME } from './initialState';

export const fetchDepartments = createAsyncThunk<Department[]>(
  `${STORE_NAME}/fetchDepartments`,
  (_, { getState }) => {
    const reportCategory = selectReportCategoryQuery(getState());
    const projectId = selectReportOrCurrentProjectId(getState());

    return departmentApi
      .list(projectId, { reportCategory })
      .then((list) => list.map((department) => departmentMapper.deserialize(department)));
  },
  { serializeError }
);

export const toggleDepartment = createAsyncThunk<void, number>(
  `${STORE_NAME}/toggleDepartment`,
  (departmentId, { dispatch, getState }) => {
    const selectedDepartmentsIds = selectSelectedDepartmentIds(getState());
    const selectedClassifications = selectSelectedClassifications(getState());
    const summaryMode = selectModeType(getState());
    const isSelected = selectIsDepartmentSelected(departmentId)(getState());
    const categoryMode = selectSummaryCategoryViewMode(getState());
    const sortBy = selectSorting(getState());

    const updatedSelectedDepartmentsIds = isSelected
      ? selectedDepartmentsIds.filter(
          (selectedDepartmentId: number) => selectedDepartmentId !== departmentId
        )
      : [...selectedDepartmentsIds, departmentId];

    dispatch(
      pushQuery({
        query: createFiltersQuery({
          classifications: selectedClassifications,
          departments: updatedSelectedDepartmentsIds,
          summaryMode,
          categoryMode,
          sortBy,
        }),
      })
    );
  },
  { serializeError }
);
