import { AxiosInstance } from 'axios';
import { AnswerSentiment, SentimentStats } from 'model/Sentiment';
import { Answer } from 'model/Answer';
import { QuestionApi } from 'api/QuestionApi';
import { PaginatedResponse } from 'model/PaginatedResponse';
import { Question } from 'model/Question';
import { RespondentDto } from 'api/dto/Respondent.dto';
import { QuestionAndAnswers } from 'model/QuestionAndAnswers';

import { PaginationMapper } from '../mapper/PaginationMapper';
import { QuestionMapper } from '../mapper/QuestionMapper';
import { CommentAnswerMapper } from '../mapper/CommentAnswerMapper';

export namespace CommentApi {
  export type AnswerEntry = {
    id: number;
    answer: string;
    sentiment: SentimentEntry | null;
    respondent: RespondentDto | null;
  };

  // Currently, API entry match sentiment model
  export type SentimentEntry = AnswerSentiment;

  export type FetchAnswersData = {
    question: QuestionApi.Entry;
    answers: AnswerEntry[];
  };
}

export class CommentApi {
  constructor(private client: AxiosInstance, private questionMapper: QuestionMapper) {}

  fetchQuestions(id: number, query: string): Promise<Question[]> {
    return this.client
      .get<QuestionApi.Entry[]>(`/api/v1/project/${id}/survey/question${query}&f[kind]=106`)
      .then((response) => response.data.map((item) => this.questionMapper.deserialize(item)))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  fetchAnswers(
    projectId: number,
    questionId: number,
    query: string
  ): Promise<PaginatedResponse<Answer[]> & { sentimentStats?: SentimentStats | null }> {
    return this.client
      .get<PaginatedResponse<CommentApi.FetchAnswersData>>(
        `/api/v1/project/${projectId}/survey/question/${questionId}/answer${query}`
      )
      .then(({ data: { data: responseData, pagination } }) => ({
        data: responseData.answers.map((item) => CommentAnswerMapper.deserialize(item)),
        sentimentStats: responseData.question['sentiment-stats'],
        pagination: PaginationMapper.deserialize(pagination),
      }))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  fetchAllComments(projectId: number, query: string): Promise<QuestionAndAnswers[]> {
    return this.client
      .get(`/api/v1/project/${projectId}/survey/all-comments${query}`)
      .then((response) => response.data.data);
  }
}
