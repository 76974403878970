import { trendMapper } from 'container/trendMapper';
import { responseRateMapper } from 'container/responseRateMapper';
import { compareGroupMapper } from 'container/compareGroupMapper';
import { projectSummaryMapper } from 'container/projectSummaryMapper';

import { SummaryMapper } from '../mapper/SummaryMapper';

export const summaryMapper = new SummaryMapper(
  trendMapper,
  responseRateMapper,
  compareGroupMapper,
  projectSummaryMapper
);
