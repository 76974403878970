import React from 'react';

import { FlagWrapper, TextContent } from 'component/FlagNew/FlagNew.s';

export namespace FlagNew {
  export type Props = {
    isLatest?: boolean;
  };
}

export const FlagNew: React.FC<FlagNew.Props> = ({ isLatest }) =>
  isLatest ? (
    <FlagWrapper>
      <TextContent>New</TextContent>
    </FlagWrapper>
  ) : null;
