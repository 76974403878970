import React from 'react';

import { BodyWrapper } from './ReportBody.s';

export namespace ReportBody {
  export type Props = {
    component: React.ReactElement;
  };
}

export const ReportBody: React.FC<ReportBody.Props> = (props) => (
  <BodyWrapper>
    <tr>
      <td>{props.component}</td>
    </tr>
  </BodyWrapper>
);
