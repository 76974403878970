import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { createRelativeRoute } from 'component/RelativeRoute/RelativeRoute';
import { AppRoute } from 'app/route/app';
import { renderRoutes } from 'router/components/renderRoutes';
import { useFetchUser } from 'router/hooks/useFetchUser';
import { useClearDepartments } from 'shared/hooks/useClearDepartments';
import { useClearEngagementDrivers } from 'shared/hooks/useClearEngagementDrivers';
import { useInitializePostHog } from 'shared/hooks/useInitializePostHog';
import { useInitializeRUM } from 'shared/hooks/useInitializeRUM';
import { useInitializeDataDogLogs } from 'shared/hooks/useInitializeDataDogLogs';
import { selectUserId } from 'reduxStore/user/selectors';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useWBUserGuiding } from '@workbuzz/shared-ui';

import { useAppRoutes } from './useAppRoutes';

const RelativeRoute = createRelativeRoute(AppRoute.HOME);

export const AppRoutes: React.FC = () => {
  const routes = useAppRoutes();
  const isFeatureEnabled = useFeatureIsOn('core.user-guiding');
  const userId = useSelector(selectUserId);

  useInitializeRUM();
  useInitializeDataDogLogs();
  useFetchUser();
  useInitializePostHog();
  useWBUserGuiding(isFeatureEnabled, userId);
  useClearDepartments();
  useClearEngagementDrivers();

  return <Switch>{renderRoutes({ RelativeRoute, routes })}</Switch>;
};
