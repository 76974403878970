import { ProjectApi } from 'api/ProjectApi';
import { ReportAvailability } from 'model/ReportAvailability';
import { ProjectDto } from 'api/dto/Project.dto';
import { ProjectKind } from 'model/ProjectKind';

export class ProjectMapper {
  deserializeLastPulseId(entry: ProjectDto): number | null {
    if (entry.kind === ProjectKind.PULSE_TEMPLATE && entry.pulseProjects.length > 0) {
      return entry.pulseProjects[entry.pulseProjects.length - 1].id;
    }

    return null;
  }

  deserializeProjectAvailability(entry: ProjectApi.ReportAvailabilityEntry): ReportAvailability {
    return {
      departmentId: entry.departmentId,
      feedback: entry.feedback,
      comments: entry.comments,
    };
  }

  deserializeProjectAvailabilities(
    entries: ProjectApi.ReportAvailabilityEntry[]
  ): ReportAvailability[] {
    return entries ? entries.map((entry) => this.deserializeProjectAvailability(entry)) : [];
  }
}
