import { OverallSurvey } from 'model/OverallSurvey';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'overallSurvey';

export namespace OverallSurveyState {
  export type Domain = {
    combine: Resource<OverallSurvey.Combine | null>;
    compare: Resource<OverallSurvey.Compare | null>;
  };

  export const INITIAL_DOMAIN: Domain = {
    combine: Resource.createPendingResource(null),
    compare: Resource.createPendingResource(null),
  };
}
