import { match, matchPath } from 'react-router';
import { RouterLocation } from 'connected-react-router';

import memoize from 'fast-memoize';
import { createSelector, Selector } from 'reselect';
import qs from 'qs';
import { AppState } from 'reduxStore/appStore';
import { History } from 'history';
import { QueryType } from 'model/QueryType';

export function selectLocation(state: AppState): RouterLocation<History.LocationState> | undefined {
  return state?.router?.location;
}

export const selectLocationPath = createSelector(
  selectLocation,
  (location) => (location && location.pathname) || undefined
);

export const selectQueryString = createSelector(selectLocation, (location) => {
  let queryString = (location && location.search) || '';
  if (queryString.startsWith('?')) {
    queryString = queryString.substring(1);
  }

  return queryString;
});

/**
 * Selects query string without category mode and sorting values
 */
export const selectQueryStringWithoutUISettings = createSelector(
  selectQueryString,
  (queryString) => {
    const categoryModeRegexp = new RegExp(`(:?\\?|&)${QueryType.CategoryMode}=\\d`);
    const sortOrderRegexp = new RegExp(`(:?\\?|&)${QueryType.SortOrder}=[^&]+`);
    const sortColumnRegexp = new RegExp(`(:?\\?|&)${QueryType.SortColumn}=[^&]+`);
    return queryString
      .replace(categoryModeRegexp, '')
      .replace(sortOrderRegexp, '')
      .replace(sortColumnRegexp, '');
  }
);

export const parseQueryString = (queryString: string) =>
  qs.parse(queryString, { parseArrays: false });

export const selectQueryParamsObject = createSelector(selectQueryString, parseQueryString);

export const selectQueryParams = createSelector(selectQueryString, (queryString) => {
  return qs.parse(queryString);
});

export const selectQueryParamObject: <T = any>(
  param: string
) => Selector<any, T> = memoize((param: string) =>
  createSelector(selectQueryParamsObject, (params) => params[param])
);

export const selectQueryParam: <T = any>(
  param: string
) => Selector<any, T> = memoize((param: string) =>
  createSelector(selectQueryParams, (params) => params[param])
);

export const selectMatch: <P = any>(
  ...routes: string[]
) => Selector<any, match<P> | null> = memoize(
  (...routes: string[]) =>
    createSelector(selectLocationPath, (currentPath) => {
      if (!currentPath) {
        return null;
      }

      return routes.reduce(
        (aMatch, route) => aMatch || matchPath<any>(currentPath, { path: route, exact: false }),
        null as match<any> | null
      );
    }),
  (...routes: string[]) => routes.join(' ')
);

export const selectExactMatch = <P = any>(...routes: string[]): Selector<any, match<P> | null> =>
  createSelector(selectLocationPath, (currentPath) => {
    if (!currentPath) {
      return null;
    }

    return routes.reduce(
      (aMatch, route) => aMatch || matchPath<any>(currentPath, { path: route, exact: true }),
      null as match<any> | null
    );
  });

export const selectHasMatch: (...routes: string[]) => Selector<any, boolean> = memoize(
  (...routes: string[]) => createSelector(selectMatch(...routes), (aMatch) => !!aMatch),
  (...routes: string[]) => routes.join(' ')
);

export const selectHasExactMatch: (...routes: string[]) => Selector<any, boolean> = memoize(
  (...routes: string[]) => createSelector(selectExactMatch(...routes), (aMatch) => !!aMatch),
  (...routes: string[]) => routes.join(' ')
);
