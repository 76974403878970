import { push } from 'connected-react-router';

import { getPathWithReplacedProjectId } from 'util/getPathWithReplacedProjectId';
import { removeQueryParams } from 'util/removeQueryParams';
import { selectLocationPath, selectQueryString } from 'util/selector/routerSelector';
import { selectLegalFiltersWithModeQuery } from 'util/selector/querySelector';

import { AppRoute } from 'app/route/app';
import { compilePath, CompilePathParams, CompilePathQuery } from 'router/compilePath';
import flow from 'lodash/flow';
import { ProjectModel } from 'model/Project.model';
import { ThunkAction } from '@reduxjs/toolkit';
import { AppState } from 'reduxStore/appStore';
import { Action } from 'redux';

export type RouteParams = {
  pattern: string;
  params?: CompilePathParams;
  query?: CompilePathQuery;
};

/**
 * Push new route to the history
 */
export const route = ({
  pattern,
  params,
  query,
}: RouteParams): ThunkAction<void, AppState, void, Action<string>> => (dispatch) =>
  dispatch(push(compilePath(pattern, params, query)));

export type RouteWithQueryParams = {
  pattern: string;
  params?: CompilePathParams;
};

export const routeWithQuery = ({
  pattern,
  params,
}: RouteWithQueryParams): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch,
  getState
) => dispatch(push(compilePath(pattern, params) + '?' + selectQueryString(getState())));

export const pushQuery = ({
  query,
}: {
  query: string;
}): ThunkAction<void, AppState, void, Action<string>> => (dispatch, getState) =>
  dispatch(push(selectLocationPath(getState()) + '?' + query));

export const goToPage = (url: string) => () => {
  window.location.href = url;
};

export const openInNewTab = (url: string) => () => {
  window.open(url, '_blank');
};

export type ChangeProjectParams = {
  projectId: ProjectModel['id'];
  navigateToSummary?: boolean;
};

export const changeProject = ({
  projectId,
  navigateToSummary = false,
}: ChangeProjectParams): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch,
  getState
) => {
  let newLocation: string;

  if (navigateToSummary) {
    newLocation = compilePath(AppRoute.Home.SUMMARY, { id: projectId });
  } else {
    const location = selectLocationPath(getState()) || '';
    newLocation = flow(getPathWithReplacedProjectId(projectId), removeQueryParams)(location);
  }

  if (!newLocation) {
    console.error('changeProjectId called from invalid location');
    return;
  }

  dispatch(push(newLocation));
};

// Legal query is the query that only has elements that user's role specifies
export const pushLegalQuery = (): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch,
  getState
) => {
  const query = selectLegalFiltersWithModeQuery(getState());

  dispatch(pushQuery({ query }));
};
