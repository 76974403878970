import React from 'react';

import {
  insightBarChartStyles,
  InsightCombineContentWrapper,
  SingleInsightBar,
} from 'view/InsightsPage/InsightsPage.s';
import { RespondentProtectionLabel } from 'component/RespondentProtectionLabel/RespondentProtectionLabel';
import { Insight, plotDataToTableData } from 'model/Insight';
import { TableTrendPlot } from 'component/TableTrendPlot/TableTrendPlot';
import { Box, LinearProgress, Typography } from '@mui/material';

export type InsightCombineModeProps = {
  item: Insight.CombineData;
  shouldDisplayTrendPlotDataColumn?: boolean;
};

export const InsightsCombinedMode: React.FC<InsightCombineModeProps> = ({
  item,
  shouldDisplayTrendPlotDataColumn,
}) => (
  <Box data-testid="insightsCombinedMode">
    {item.respondentProtection && <RespondentProtectionLabel />}
    {!item.respondentProtection && (
      <InsightCombineContentWrapper data-testid={`insightCombineContent[${item.question.id}]`}>
        {item.answers.map((answer, index) => (
          <SingleInsightBar key={index} data-testid="singleInsightBar">
            <Typography
              sx={{ flex: '1 0 50%', paddingRight: '16px' }}
              variant="body1"
              data-testid="insightAnswer"
            >
              {answer.option.label}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', flex: '1 0 50%' }}>
              <LinearProgress
                data-testid="insightBarChart"
                sx={insightBarChartStyles}
                value={answer.score}
                variant="determinate"
              />

              <Typography variant="h6" minWidth="39px" data-testid="insightBarValue">
                {answer.score}%
              </Typography>

              {shouldDisplayTrendPlotDataColumn && !!answer.trendPlotData && (
                <TableTrendPlot
                  data={answer.trendPlotData.map(plotDataToTableData)}
                  width={80}
                  height={26}
                />
              )}
            </Box>
          </SingleInsightBar>
        ))}
      </InsightCombineContentWrapper>
    )}
  </Box>
);
