import { styled } from '@mui/material';
import { CSSProperties } from 'glamor';

export type ScrollWrapperProps = {
  overflow: 'vertical' | 'horizontal';
  backgroundColor: string | undefined;
  trackColor: string | undefined;
  width: number | undefined;
  height: number | undefined;
  radius: number | undefined;
  float?: string;
};

export type OverflowProps = {
  maxHeight?: number | string;
  maxWidth?: number | string;
  minWidth?: number;
  paddingBottom?: number;
};

export const ScrollWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['overflow', 'backgroundColor', 'trackColor', 'width', 'height', 'radius', 'float'].includes(
      prop.toString()
    ),
})(
  () => ({
    float: 'left',
    width: '100%',
  }),
  (props: ScrollWrapperProps): CSSProperties => ({
    marginRight: props.overflow === 'vertical' ? 15 : 0,
    overflowX: props.overflow === 'vertical' ? 'hidden' : 'auto',
    overflowY: props.overflow === 'vertical' ? 'scroll' : 'hidden',
    float: props.float ? props.float : 'left',
    '&::-webkit-scrollbar': {
      height: props.overflow === 'horizontal' ? props.height : 'auto',
      width: props.width,
      backgroundColor: props.backgroundColor,
      borderRadius: props.radius,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: props.trackColor,
      borderRadius: props.radius,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: props.backgroundColor,
      borderRadius: props.radius,
    },
  })
);

export const Overflow = styled('div', {
  shouldForwardProp: (prop) =>
    !['maxHeight', 'maxWidth', 'minWidth', 'paddingBottom'].includes(prop.toString()),
})((props: OverflowProps) => ({
  maxHeight: props.maxHeight,
  maxWidth: props.maxWidth,
  minWidth: props.minWidth || 'auto',
  paddingBottom: props.paddingBottom ?? 16,
}));
