import React, { ChangeEventHandler } from 'react';

import { SearchIcon, SearchInputElement, SearchInputWrapper } from './SearchInput.s';

export type SearchInputProps = {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  'data-testid'?: string;
};
export const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  value,
  placeholder = 'Search',
  'data-testid': dataTestId,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    onChange(event.target.value);

  return (
    <SearchInputWrapper>
      <SearchIcon />
      <SearchInputElement
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        data-testid={dataTestId}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    </SearchInputWrapper>
  );
};
