import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { InfoIcon } from 'util/withTooltip';

import {
  Line,
  LineChart,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { selectSimpleProjectRecommendPlotData } from 'reduxStore/simpleProject/selectors';
import {
  selectCurrentCompanyName,
  selectCurrentProjectLifecycleType,
} from 'reduxStore/project/selectors';
import { LifecycleType } from 'model/Lifecycle';
import { RecommendChartTooltip } from 'component/LifecycleCharts/RecommendChart/RecommendChartTooltip';
import {
  mapRecommendChartDtoArrayToModelArray,
  RecommendChartDataModel,
} from 'api/dto/RecommendChartData.dto';
import { Box, Tooltip, Typography } from '@mui/material';
import { typography } from 'app/theme/typography';
import { IRON, MARINER, SONIC_SILVER, WHITE } from 'app/theme/colors';

import { ChartWrapper } from '../LifecycleCharts.s';

export const RecommendChart: React.FC = () => {
  const recommendPlotData = useSelector(selectSimpleProjectRecommendPlotData);
  const companyName = useSelector(selectCurrentCompanyName);
  const lifecycleType = useSelector(selectCurrentProjectLifecycleType);
  const [recommendChartData, setRecommendChartData] = useState<RecommendChartDataModel[]>([]);

  useEffect(() => {
    setRecommendChartData(mapRecommendChartDtoArrayToModelArray(recommendPlotData));
  }, [recommendPlotData]);

  const tooltipContent = (
    <>
      {lifecycleType === LifecycleType.ONBOARD && (
        <>
          <Typography sx={{ ...typography.subtitle2, color: WHITE }}>
            You can track employees' emotional connection for working for your organisation with the
            "I would recommend" Engagement question in Lifecycle surveys.
          </Typography>
          <Typography sx={{ ...typography.subtitle2, color: WHITE }}>
            This chart tracks the score for the "I would recommend" question from New Starters in
            the Onboard survey on a monthly basis.
          </Typography>
        </>
      )}
      {lifecycleType === LifecycleType.EXIT && (
        <>
          <Typography sx={{ ...typography.subtitle2, color: WHITE }}>
            You can track employees' emotional connection for working for your organisation with the
            "I would recommend" Engagement question in Lifecycle surveys.
          </Typography>
          <Typography sx={{ ...typography.subtitle2, color: WHITE }}>
            This chart tracks the score for the "I would recommend" question from Leavers in the
            Exit survey on a monthly basis.
          </Typography>
        </>
      )}
    </>
  );

  return (
    <ChartWrapper>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="h5" data-testid="recommendChartTitle">
          <span style={{ marginRight: '6px' }}>Percentage who would recommend {companyName}</span>
          {lifecycleType && (
            <Tooltip
              id="ResponseRateTooltip"
              arrow
              title={tooltipContent}
              enterTouchDelay={0}
              leaveTouchDelay={10000}
            >
              <InfoIcon className="recommendChartInfoIcon" data-testid="recommendChartInfoIcon" />
            </Tooltip>
          )}
        </Typography>
      </Box>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={recommendChartData} id="recommendChart">
          <XAxis
            dataKey="dateLabel"
            stroke={IRON}
            strokeWidth={3}
            style={{
              ...typography.body2,
              fill: SONIC_SILVER,
            }}
            dy={5}
          />
          <YAxis
            dataKey="value"
            domain={[0, 100]}
            unit="%"
            stroke={IRON}
            strokeWidth={3}
            style={{
              ...typography.body2,
              fill: SONIC_SILVER,
            }}
            dx={-5}
            width={40}
          />
          <ChartTooltip content={<RecommendChartTooltip />} />
          <Line
            type="linear"
            dataKey="value"
            connectNulls
            unit="%"
            stroke={MARINER}
            strokeWidth={4}
            animationDuration={50}
          />
        </LineChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};
