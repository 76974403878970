import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectIsCommentsAnalysisEnabled } from 'util/selector/sentimentSelector';

import { fetchAllSentimentTopics } from 'reduxStore/comments/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { clearAllTopics } from 'reduxStore/comments/slice';

export const useFetchAllSentimentTopics = () => {
  const dispatch = useAppDispatch();
  const isCommentsAnalysisEnabled = useSelector(selectIsCommentsAnalysisEnabled);

  useEffect(() => {
    dispatch(isCommentsAnalysisEnabled ? fetchAllSentimentTopics() : clearAllTopics());
  }, [isCommentsAnalysisEnabled, dispatch]);
};
