import { DemographyApi } from 'api/DemographyApi';
import { Demography } from 'model/Demography';
import { demographyClassificationMapper } from 'container/demographyClassificationMapper';

export class DemographyMapper {
  deserialize(entry: DemographyApi.Entry): Demography {
    return {
      id: entry.id,
      ref: entry.ref,
      title: entry.title,
      classifications: entry.classifications
        ? entry.classifications.map((classification) =>
            demographyClassificationMapper.deserialize(classification, entry.id)
          )
        : [],
    };
  }
}
