import React from 'react';

import { Legend, LegendWrapper } from 'component/HeatmapLegend/HeatMapLegend.s';
import { INSIGHTS_HEATMAP_COLORS, SUMMARY_HEATMAP_COLORS } from 'component/HeatmapLegend/constants';
import { Box, SxProps } from '@mui/material';

type HeatmapLegendProps = {
  colors: string[];
  fromLabel: string;
  toLabel: string;
  sx?: SxProps;
};

export const HeatmapLegend: React.FC<HeatmapLegendProps> = ({ colors, fromLabel, toLabel, sx }) => {
  const height = 20;
  const width = '100%';

  return (
    <Box sx={sx}>
      <LegendWrapper width={width} data-testid="heatmapLegendHeader">
        <li>
          <Legend>{fromLabel}</Legend>
        </li>
        <li>
          <Legend>{toLabel}</Legend>
        </li>
      </LegendWrapper>
      <svg data-testid="heatmapLegend" width={width} height={height} style={{ borderRadius: 6 }}>
        {colors.map((color, i) => {
          const width = 100 / colors.length;
          return (
            <rect
              key={i}
              x={`${width * i}%`}
              y="0"
              width={`${width}%`}
              height={height}
              fill={color}
            />
          );
        })}
      </svg>
    </Box>
  );
};

export const SummaryHeatmapLegend: React.FC<{ sx?: SxProps }> = ({ sx }) => (
  <HeatmapLegend sx={sx} colors={SUMMARY_HEATMAP_COLORS} fromLabel="0%" toLabel="100%" />
);

export const InsightsHeatmapLegend: React.FC<{ sx?: SxProps }> = ({ sx }) => (
  <HeatmapLegend sx={sx} colors={INSIGHTS_HEATMAP_COLORS} fromLabel="0%" toLabel="100%" />
);
