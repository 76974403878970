import React, { ReactElement, ReactNode } from 'react';

import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import { WORKBUZZ_ORANGE } from 'app/theme/colors';
import { LoadingIcon } from 'component/PageHeader/PageHeader.s';
import { LoadingStateEnum, Resource } from 'model/Resource';

import { LoaderWrapper } from './ResourceLoader.s';

export type ResourceLoaderProps = {
  resource: Resource | Resource[];
  children: ReactNode;
  rejected?: (error: Error | null) => ReactElement;
  hideLoader?: boolean;
};

export const ResourceLoader: React.FC<ResourceLoaderProps> = ({
  resource,
  rejected,
  children,
  hideLoader = false,
}) => {
  const resources = Array.isArray(resource) ? resource : [resource];
  const pendingResource = resources.find((res) => res.loadingState === LoadingStateEnum.PENDING);
  const rejectedResource = resources.find((res) => res.loadingState === LoadingStateEnum.REJECTED);
  const hasAllResourcesResolved =
    resources.length ===
    resources.filter((res) => res.loadingState === LoadingStateEnum.RESOLVED).length;

  const shouldShowLoader = !hideLoader && pendingResource;

  return (
    <>
      {!rejectedResource && shouldShowLoader && (
        <LoaderWrapper data-testid="loader">
          <LoadingIcon type="spin" color={WORKBUZZ_ORANGE} height={40} width={40} />
        </LoaderWrapper>
      )}
      {!rejectedResource && hasAllResourcesResolved && children}
      {rejectedResource &&
        (rejected?.(rejectedResource.error) ?? <ErrorHandler error={rejectedResource.error} />)}
    </>
  );
};
