import { AxiosInstance } from 'axios';
import {
  mapResponseCountDtoListToModes,
  ResponseCountDto,
  ResponseCountModel,
} from 'api/dto/ResponseCount.dto';

export namespace AggregatedResponseCountApi {
  export type Props = Readonly<{
    projectId: number;
    query: string;
  }>;
}

export class AggregatedResponseCountApi {
  constructor(private client: AxiosInstance) {}

  list({ projectId, query }: AggregatedResponseCountApi.Props): Promise<ResponseCountModel[]> {
    return this.client
      .get<ResponseCountDto[]>(
        `/api/v1/project/${projectId}/report/aggregated-response-count${query}`
      )
      .then((response) => mapResponseCountDtoListToModes(response.data));
  }
}
