import { styled, Typography } from '@mui/material';

export const LegendWrapper = styled('ul')(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2px',
  }),
  ({ width }: { width: number | string }) => ({
    width,
  })
);

export const Legend = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& > span': {
      display: 'none',
    },
  },
}));
