import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { selectReportOrCurrentProjectId } from 'util/selector/reportSelector';

import { SummaryPageLine } from 'view/SummaryPage/SummaryPage.s';
import { PageContentWrapper } from 'shared/styles/layout';
import { selectReportSearchedDepartments } from 'reduxStore/departments/selectors';
import { Department } from 'model/Department';
import { searchReport } from 'reduxStore/search/slice';
import { fetchReportAvailabilities } from 'reduxStore/project/asyncActions';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import { useAppDispatch } from 'reduxStore/appStore';
import { useFetchDepartments } from 'shared/hooks/useFetchDepartments';
import { useFetchDemography } from 'shared/hooks/useFetchDemography';
import { selectCurrentProjectResource } from 'reduxStore/project/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { ProjectState } from 'reduxStore/project/initialState';
import { useFetchCurrentProject } from 'shared/hooks/useFetchCurrentProject';
import { Typography } from '@mui/material';

import { LevelWrapper, ReportsWrapper } from './ReportsPage.s';
import { DepartmentLevel } from './DepartmentLevel/DepartmentLevel';
import { ReportSearchInput } from './ReportsSearchInput/ReportsSearchInput';
import { ReportsHeader } from './ReportsHeader/ReportsHeader';

export type ReportsPageProps = {
  searchedDepartments: Department[][];
  currentProjectResource: ProjectState.Domain['current'];
};

export const ReportsPagePure: React.FC<ReportsPageProps> = ({
  searchedDepartments,
  currentProjectResource,
}) => {
  return (
    <div>
      <ReportsHeader />
      <PageContentWrapper>
        <ResourceLoader resource={currentProjectResource}>
          <>
            <PulseFilter wrapperSx={{ marginBottom: '43px' }} />

            <ReportsWrapper>
              <Typography variant="body1">
                Click on the relevant group(s) below to download a ready-made report.
              </Typography>

              <ReportSearchInput />

              {searchedDepartments.map((departments: Department[], index: number) => (
                <LevelWrapper key={index}>
                  <Typography variant="h5">Level {index + 1}</Typography>
                  <SummaryPageLine />
                  {departments.map((department: Department) =>
                    department.blocked ? null : (
                      <DepartmentLevel
                        key={department.id}
                        departmentName={department.name}
                        departmentId={department.id}
                      />
                    )
                  )}
                </LevelWrapper>
              ))}
            </ReportsWrapper>
          </>
        </ResourceLoader>
      </PageContentWrapper>
    </div>
  );
};

export const ReportsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const searchedDepartments = useSelector(selectReportSearchedDepartments) as Department[][];
  const projectId = useSelector(selectReportOrCurrentProjectId);
  const currentProjectResource = useSelector(selectCurrentProjectResource);

  useFetchCurrentProject();
  useFetchDepartments();
  useFetchDemography();

  useEffect(() => {
    dispatch(searchReport(''));
    dispatch(fetchReportAvailabilities());
  }, [projectId, dispatch]);

  useUnmount(() => {
    dispatch(searchReport(''));
  });

  return (
    <ReportsPagePure
      searchedDepartments={searchedDepartments}
      currentProjectResource={currentProjectResource}
    />
  );
};
