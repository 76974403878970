import React from 'react';
import { useSelector } from 'react-redux';

import { TableWrapper } from 'view/EngagementDriverByCategoryPage/QuestionTable/QuestionTable.s';
import { Header } from 'view/EngagementDriverByCategoryPage/QuestionTable/Header/Header';
import { Body } from 'view/EngagementDriverByCategoryPage/QuestionTable/Body/Body';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { selectShouldShowImpactOnEngagement } from 'reduxStore/engagementDrivers/selectors';
import { OpinionQuestionCategory } from 'register/OpinionQuestionCategory';
import { selectIsPulseTemplateProjectKind } from 'reduxStore/project/selectors';
import { Benchmark } from 'model/Benchmark';
import { selectBenchmarkFromVisualSettings } from 'reduxStore/visualSettings/selectors';

import { hasAnyTrendPlotData } from './utils';

export type QuestionDataElementType = OpinionQuestionDetails.Combine & { categoryTitle?: string };
export type QuestionDataType = ReadonlyArray<QuestionDataElementType>;
export type QuestionTableProps = {
  data: QuestionDataType;
  categoryId?: number;
  isReportView?: boolean;
  width?: number | string;
  shouldRenderCategory: boolean;
};
export type QuestionTablePureProps = {
  showImpactOnEngagement: boolean;
  isPulseTemplate: boolean;
  benchmark: Benchmark;
} & QuestionTableProps;

export const QuestionTablePure: React.FC<QuestionTablePureProps> = (props) => {
  const shouldDisplayTrendPlotDataColumn = hasAnyTrendPlotData(props.data);

  return (
    <TableWrapper width={props.width} data-testid="questionTable">
      <Header
        trendsColumnName={
          props.data.length && props.data[0].trends?.length
            ? props.data[0].trends[0].label
            : undefined
        }
        isReportView={props.isReportView}
        isPulseTemplate={props.isPulseTemplate}
        showImpactOnEngagement={props.showImpactOnEngagement}
        benchmark={OpinionQuestionDetails.getHeaderBenchmark(props.data)}
        shouldRenderCategory={props.shouldRenderCategory}
        shouldDisplayTrendPlotDataColumn={shouldDisplayTrendPlotDataColumn}
      />
      <Body
        data={props.data}
        showImpactOnEngagement={props.showImpactOnEngagement}
        shouldRenderCategory={props.shouldRenderCategory}
        shouldDisplayTrendPlotDataColumn={shouldDisplayTrendPlotDataColumn}
        isReportView={props.isReportView}
      />
    </TableWrapper>
  );
};

export const QuestionTable: React.FC<QuestionTableProps> = (props) => {
  const shouldShowImpactOnEngagement = useSelector(selectShouldShowImpactOnEngagement);
  const isPulseTemplate = useSelector(selectIsPulseTemplateProjectKind);
  const benchmark = useSelector(selectBenchmarkFromVisualSettings);

  const showImpactOnEngagement =
    shouldShowImpactOnEngagement &&
    props.categoryId !== OpinionQuestionCategory.EMPLOYEE_ENGAGEMENT;

  return (
    <QuestionTablePure
      {...props}
      showImpactOnEngagement={showImpactOnEngagement}
      isPulseTemplate={isPulseTemplate}
      benchmark={benchmark}
    />
  );
};
