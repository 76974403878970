import React from 'react';

import { RespondentProtection } from 'model/RespondentProtection';
import { ReportType } from 'model/ReportType';
import { DisabledButton } from 'view/ReportsPage/DepartmentLevel/DepartmentLevel.s';
import { Tooltip } from '@mui/material';

type DownloadReportDisabledButtonProps = {
  reportType: string;
  respondentProtection: RespondentProtection;
  departmentId: number;
};

export const DownloadReportDisabledButton: React.FC<DownloadReportDisabledButtonProps> = ({
  reportType,
  respondentProtection,
  departmentId,
}) => {
  return (
    <Tooltip
      id={
        reportType === ReportType.Feedback ? `feedback-${departmentId}` : `comment-${departmentId}`
      }
      arrow
      title={
        <>
          Report not available in order to protect confidentiality - less than{' '}
          {reportType === ReportType.Feedback
            ? respondentProtection.closed
            : respondentProtection.comment}{' '}
          responses received
        </>
      }
    >
      <DisabledButton>
        {reportType === ReportType.Feedback ? 'Feedback' : 'Comment'} Report
      </DisabledButton>
    </Tooltip>
  );
};
