export namespace ReportRoute {
  export const REPORT_PATTERN = `/report` as const;
  export const FEEDBACK_REPORT = `${REPORT_PATTERN}/feedback` as const;
  export const INDIVIDUAL_FEEDBACK_REPORT = `${REPORT_PATTERN}/individual-feedback` as const;
  export const ALL_FEEDBACK_REPORT = `${REPORT_PATTERN}/all-feedback` as const;
  export const CATEGORY_REPORT = `${REPORT_PATTERN}/category` as const;
  export const COMMENT_REPORT = `${REPORT_PATTERN}/comment` as const;
  export const ALL_COMMENTS_REPORT = `${REPORT_PATTERN}/all-comments` as const;
  export const INSIGHTS_REPORT = `${REPORT_PATTERN}/insights` as const;
}
