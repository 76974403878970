import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode, selectSummaryCategoryViewMode } from 'util/selector/querySelector';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { ReportSummaryWidgets } from 'view/ReportView/SummaryWidgetReport/ReportSummaryWidgets';
import { EngagementDriversCombine } from 'view/EngagementDriversView/EngagementDriversCombine';
import { EngagementDriverByCategoryCombine } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCombine/EngagementDriverByCategoryCombine';
import { ReportType } from 'model/ReportType';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';
import { ReportEngagementDriver } from 'model/ReportEngagamentDriver';
import { Insight } from 'model/Insight';
import {
  selectEngagementDriversCombineList,
  selectTrendLabel,
} from 'reduxStore/engagementDrivers/selectors';
import { selectInsightsCombinedResource } from 'reduxStore/insights/selectors';
import {
  selectHasInsightQuestion,
  selectProjectClosedAt,
  selectProjectKind,
} from 'reduxStore/project/selectors';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import { Moment } from 'moment';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { Resource } from 'model/Resource';
import { OverallSurvey } from 'model/OverallSurvey';
import {
  selectOverallSurveyCombineResource,
  selectOverallSurveyCompareResource,
} from 'reduxStore/overallSurvey/selectors';
import { SummaryCategoryViewMode } from 'register/ModeType';

import { InsightsWrapper } from '../AllFeedbackReport.s';

export namespace AllOverallReport {
  export type StateProps = {
    engagementDrivers: ReportEngagementDriver[];
    insightsCombineResource: Resource<Insight.Combine>;
    hasInsightsQuestion: boolean;
    isAdmin: boolean;
    trendLabel: string;
    projectKind: ProjectKind;
    closedAt?: Moment;
    overallSurveyResource:
      | Resource<OverallSurvey.Combine | null>
      | Resource<OverallSurvey.Compare | null>;
    categoryMode: SummaryCategoryViewMode;
  };
  export type OwnProps = {};
  export type Props = OwnProps & StateProps;
}

export const AllOverallReportPure: React.FC<AllOverallReport.Props> = (props) => (
  <ResourceLoader resource={props.overallSurveyResource}>
    <>
      <div>
        <ReportContent title="Summary" component={<ReportSummaryWidgets isReportView />} />
        <ReportContent
          title={Project.getEngagementDriversTitle({
            kind: props.projectKind,
            closedAt: props.closedAt,
          })}
          component={<EngagementDriversCombine isReportView />}
        />
        {props.categoryMode === SummaryCategoryViewMode.Category && props.engagementDrivers ? (
          <div>
            {props.engagementDrivers.map((engagementDriver, index) => (
              <ReportContent
                key={index}
                title={engagementDriver.category!.name + ' : ' + engagementDriver.score}
                component={
                  <EngagementDriverByCategoryCombine
                    key={index}
                    categoryTitle={engagementDriver.category!.name}
                    categoryId={engagementDriver.category!.id}
                    score={engagementDriver.score}
                    categoryData={engagementDriver.details}
                    trends={engagementDriver.trends}
                    benchmark={engagementDriver.benchmark}
                    isAdmin={props.isAdmin}
                    trendLabel={props.trendLabel}
                    isReportView
                  />
                }
              />
            ))}
          </div>
        ) : null}
      </div>

      {props.hasInsightsQuestion && (
        <ResourceLoader resource={props.insightsCombineResource}>
          <InsightsWrapper>
            <ReportContent
              title="Insights"
              reportType={ReportType.Insights}
              component={
                <>
                  {props.insightsCombineResource.data?.data.map((item, index) => (
                    <InsightsCombinedQuestion key={index} item={item} isReportView />
                  ))}
                </>
              }
            />
          </InsightsWrapper>
        </ResourceLoader>
      )}
    </>
  </ResourceLoader>
);

export const AllOverallReport = () => {
  const engagementDrivers = useSelector(selectEngagementDriversCombineList);
  const insightsCombineResource = useSelector(selectInsightsCombinedResource);
  const hasInsightsQuestion = useSelector(selectHasInsightQuestion);
  const isAdmin = useSelector(selectIsAdmin);
  const trendLabel = useSelector(selectTrendLabel);
  const projectKind = useSelector(selectProjectKind);
  const closedAt = useSelector(selectProjectClosedAt);
  const overallSurveyCombineResource = useSelector(selectOverallSurveyCombineResource);
  const overallSurveyCompareResource = useSelector(selectOverallSurveyCompareResource);
  const isCompareMode = useSelector(selectIsCompareMode);
  const overallSurveyResource = isCompareMode
    ? overallSurveyCompareResource
    : overallSurveyCombineResource;
  const categoryMode = useSelector(selectSummaryCategoryViewMode);

  return (
    <AllOverallReportPure
      engagementDrivers={engagementDrivers}
      insightsCombineResource={insightsCombineResource}
      hasInsightsQuestion={hasInsightsQuestion}
      isAdmin={isAdmin}
      trendLabel={trendLabel}
      projectKind={projectKind}
      closedAt={closedAt}
      overallSurveyResource={overallSurveyResource}
      categoryMode={categoryMode}
    />
  );
};
