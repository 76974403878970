import { ReportRoute } from 'app/route/report';
import { FeedbackReportView } from 'view/ReportView/FeedbackReport/FeedbackReportView';
import { CommentReportView } from 'view/ReportView/CommentReport/CommentReportView';
import { InsightsReportView } from 'view/ReportView/InsightsReport/InsightsReportView';
import { CategoryReportView } from 'view/ReportView/CategoryReport/CategoryReportView';
import { AllCommentsReportView } from 'view/ReportView/AllCommentsReport/AllCommentsReportView';
import { AllFeedbackReportView } from 'view/ReportView/AllFeedbackReport/AllFeedbackReportView';
import { IndividualFeedbackReportView } from 'view/ReportView/IndividualFeedbackReport/IndividualFeedbackReportView';

import { NavigationItem } from '../models';

export const useReportRoutes = (): NavigationItem[] => {
  return [
    {
      path: ReportRoute.FEEDBACK_REPORT,
      component: FeedbackReportView,
      canActivate: true,
    },
    {
      path: ReportRoute.INDIVIDUAL_FEEDBACK_REPORT,
      component: IndividualFeedbackReportView,
      canActivate: true,
    },
    {
      path: ReportRoute.COMMENT_REPORT,
      component: CommentReportView,
      canActivate: true,
    },
    {
      path: ReportRoute.INSIGHTS_REPORT,
      component: InsightsReportView,
      canActivate: true,
    },
    {
      path: ReportRoute.CATEGORY_REPORT,
      component: CategoryReportView,
      canActivate: true,
    },
    {
      path: ReportRoute.ALL_COMMENTS_REPORT,
      component: AllCommentsReportView,
      canActivate: true,
    },
    {
      path: ReportRoute.ALL_FEEDBACK_REPORT,
      component: AllFeedbackReportView,
      canActivate: true,
    },
  ];
};
