import React from 'react';
import { useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { InfoIcon } from 'util/withTooltip';

import { RespondentsTable } from 'view/IndividualFeedbackPage/RespondentsTable';
import { useAppDispatch } from 'reduxStore/appStore';
import {
  IndividualFeedbackPageCard,
  RowsWrapper,
  SubTitle,
  TooltipLink,
} from 'view/IndividualFeedbackPage/IndividualFeedbackPage.s';
import { AdvocacyDtoEnum } from 'api/dto/Advocacy.dto';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { RespondentState } from 'reduxStore/respondent/initialState';
import { selectRespondentResource } from 'reduxStore/respondent/selectors';
import { AdvocacyFilter } from 'component/AdvocacyFilter/AdvocacyFilter';
import Tooltip from '@mui/material/Tooltip';
import { SearchInput } from 'component/SearchInput/SearchInput';
import { IndividualFeedbackPageHeader } from 'view/IndividualFeedbackPage/IndividualFeedbackPageHeader';
import { useFetchRespondents } from 'view/IndividualFeedbackPage/useFetchRespondents';
import { clearRespondents, setRespondentsTablePage } from 'reduxStore/respondent/slice';
import { Typography } from '@mui/material';
import { selectCurrentProjectLifecycleType } from 'reduxStore/project/selectors';
import { LifecycleType } from 'model/Lifecycle';
import { LIFECYCLE_CONFIDENTIALITY_PROMISE_URL } from 'shared/constants/externalLinks';
import { PageContentWrapper } from 'shared/styles/layout';
import { Resource } from 'model/Resource';
import { PaginatedResponse } from 'model/PaginatedResponse';
import { RespondentModel } from 'api/dto/Respondent.dto';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { useFetchDemography } from 'shared/hooks/useFetchDemography';
import { useFetchDepartments } from 'shared/hooks/useFetchDepartments';

export type IndividualFeedbackPagePureProps = {
  filters: RespondentState.RespondentsFilters;
  resource: Resource<PaginatedResponse<RespondentModel[]> | null>;
  lifecycleType: LifecycleType;
  onAdvocacyFilterChange: (advocacyFilter: AdvocacyDtoEnum[]) => void;
  onPageChange: (page: number) => void;
  onRespondentLikeFilterChange: (respondentLikeFilter: string) => void;
};

export const IndividualFeedbackPagePure: React.FC<IndividualFeedbackPagePureProps> = ({
  filters,
  lifecycleType,
  onAdvocacyFilterChange,
  onPageChange,
  onRespondentLikeFilterChange,
  resource,
}) => {
  const tooltipContent = (
    <div data-testid="individualFeedbackTooltipContent">
      <Typography color="white">
        WorkBuzz Lifecycle surveys do not have the same confidentiality protection we have in our
        Engage Pulses or On Demand Polls. Lifecycle surveys have no requirement for a minimum number
        of responses or headcount. You can view each employees' individual responses, enabling you
        to act on their feedback, for example if they mention gaps in training or that they do not
        have the relevant equipment to do their job.
      </Typography>
      <Typography color="white">
        For more information, view our Lifecycle Survey Confidentiality Promise{' '}
        <TooltipLink target="_blank" href={LIFECYCLE_CONFIDENTIALITY_PROMISE_URL}>
          here
        </TooltipLink>
        .
      </Typography>
    </div>
  );

  const subTitlePart = lifecycleType === LifecycleType.ONBOARD ? 'onboarding' : 'exit';

  return (
    <div>
      <IndividualFeedbackPageHeader {...filters} isDownloadDisabled={!resource.data} />
      <PageContentWrapper>
        <IndividualFeedbackPageCard>
          {resource.data && (
            <>
              <Typography variant="h5" data-testid="respondentsTableTitle">
                Feedback received in the last 12 months
              </Typography>

              <SubTitle>
                <Typography variant="body1" data-testid="individualFeedbackPageSubTitle">
                  Unlike WorkBuzz Engage Pulses and On Demand Polls, {subTitlePart} surveys are
                  attributed to individuals so you receive actionable feedback.
                </Typography>
                <Tooltip
                  title={tooltipContent}
                  placement="top"
                  leaveDelay={100}
                  enterTouchDelay={0}
                  leaveTouchDelay={15000}
                  arrow
                  className="width-500"
                >
                  <InfoIcon data-testid="individualFeedbackInfoIcon" />
                </Tooltip>
              </SubTitle>

              <RowsWrapper mobileDirection="column">
                <SearchInput
                  onChange={onRespondentLikeFilterChange}
                  value={filters.respondentLikeFilter}
                  data-testid="individualFeedbackSearchInput"
                />
                <AdvocacyFilter
                  selectedValues={filters.advocacyFilter}
                  onChange={onAdvocacyFilterChange}
                />
              </RowsWrapper>
            </>
          )}

          <ResourceLoader resource={resource}>
            <>
              {resource.data && resource.data.data?.length > 0 && (
                <ScrollBar maxWidth={1000} overflow="horizontal" float="none" light>
                  <RespondentsTable
                    respondents={resource.data.data}
                    pagination={resource.data.pagination}
                    onPageChange={onPageChange}
                  />
                </ScrollBar>
              )}
              {resource.data?.data?.length === 0 && (
                <SubTitle data-testid="noRespondentsFound">There is no data to display</SubTitle>
              )}
            </>
          </ResourceLoader>
        </IndividualFeedbackPageCard>
      </PageContentWrapper>
    </div>
  );
};

export const IndividualFeedbackPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const respondentResource = useSelector(selectRespondentResource);
  const lifecycleType = useSelector(selectCurrentProjectLifecycleType);

  const { filters, mergeFilters } = useFetchRespondents();

  useFetchDemography();
  useFetchDepartments();

  useUnmount(() => {
    dispatch(clearRespondents());
  });

  const onRespondentLikeFilterChange = (respondentLikeFilter: string) => {
    mergeFilters({ respondentLikeFilter });
  };

  const onAdvocacyFilterChange = (advocacyFilter: AdvocacyDtoEnum[]) => {
    mergeFilters({ advocacyFilter });
  };

  const onPageChange = (page: number) => {
    dispatch(setRespondentsTablePage(page));
  };

  return (
    <IndividualFeedbackPagePure
      filters={filters}
      lifecycleType={lifecycleType}
      onAdvocacyFilterChange={onAdvocacyFilterChange}
      onPageChange={onPageChange}
      onRespondentLikeFilterChange={onRespondentLikeFilterChange}
      resource={respondentResource}
    />
  );
};
