import { styled } from '@mui/material';
import { GREY_BACKGROUND, WHITE } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

export const AISummaryVideoWidgetWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: '1 0 40%',
});

export const AISummaryVideoContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: 10,

  '& > video::cue': {
    backgroundColor: GREY_BACKGROUND,
    color: WHITE,
    fontSize: typography.subtitle1?.fontSize,
    fontFamily: typography.fontFamily,
  },
});

export const AISummaryVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 10,
});
