import { matchPath } from 'react-router';

import { createError } from 'util/createError';
import { selectReportApiUrl } from 'util/selector/apiSelector';
import { selectErrorStatusCode, selectHasResponseErrorCode } from 'util/selector/axiosSelector';

import axios, { AxiosError, AxiosInstance } from 'axios';
import { FORBIDDEN, INTERNAL_SERVER_ERROR, UNAUTHORIZED } from 'http-status-codes';
import { ReportRoute } from 'app/route/report';
import { redirectToLogin } from 'reduxStore/user/utils';
import { logInterceptedError } from 'shared/hooks/useInitializeDataDogLogs';

export const apiClient: AxiosInstance = axios.create({
  baseURL: selectReportApiUrl(),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-GB',
    Accept: 'application/json',
  },
});

// handle errors by status code
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    logInterceptedError(error);

    return selectHasResponseErrorCode(error)
      ? handleErrorByResponseCode(error)
      : handleErrorByStatusCode(error);
  }
);

const isInternalRequest = (): boolean => {
  return !!matchPath(window.location.pathname, { path: ReportRoute.REPORT_PATTERN, exact: false });
};

// This interceptor add internalRequest to request that are being made from /report/ pages
// Those pages are only used to generate PDF/PowerPoints reports internally ans should not be visited by user ever
apiClient.interceptors.request.use((config) => {
  if (isInternalRequest()) {
    config.params = {
      ...config.params,
      internalRequest: 1,
    };
  }

  return config;
});

export const handleErrorByResponseCode = (error: AxiosError) => {
  throw error;
};

export const handleErrorByStatusCode = (error: AxiosError) => {
  if (selectErrorStatusCode(error) === UNAUTHORIZED) {
    redirectToLogin();
  } else if (selectErrorStatusCode(error) === FORBIDDEN) {
    throw createError('Access denied', selectErrorStatusCode(error));
  } else if (selectErrorStatusCode(error) === INTERNAL_SERVER_ERROR) {
    throw createError('Internal server error!', selectErrorStatusCode(error));
  }
};
