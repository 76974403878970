import { BasicDemographyClassification } from 'model/DemographyClassification';
import qs from 'qs';
import { QueryType } from 'model/QueryType';
import { SummaryCategoryViewMode } from 'register/ModeType';
import { SortBy } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/utils';

type ClassificationId = string;
export type GroupedClassifications = Record<string, ClassificationId[]>;

export type CreateFiltersQueryProps = {
  classifications: BasicDemographyClassification[];
  departments: number[];
  summaryMode?: string;
  categoryMode?: SummaryCategoryViewMode;
  sortBy?: SortBy;
};
export const createFiltersQuery = ({
  classifications,
  departments,
  summaryMode,
  categoryMode,
  sortBy,
}: CreateFiltersQueryProps) => {
  const groupedClassifications = groupClassificationsByDemography(classifications);
  return qs.stringify(
    {
      [QueryType.Filter]: {
        [QueryType.Classification]: groupedClassifications,
        [QueryType.Department]: departments,
      },
      mode: summaryMode,
      categoryMode,
      sortColumn: sortBy?.column,
      sortOrder: sortBy?.order,
    },
    { encode: false }
  );
};

export const groupClassificationsByDemography = (
  classifications: BasicDemographyClassification[]
): GroupedClassifications =>
  classifications.reduce((groupedClassifications: GroupedClassifications, classification) => {
    const demographyId = classification.demographyId;

    if (!groupedClassifications[demographyId]) {
      groupedClassifications[demographyId] = [];
    }

    groupedClassifications[demographyId] = [
      ...groupedClassifications[demographyId],
      String(classification.id),
    ];

    return groupedClassifications;
  }, {});
