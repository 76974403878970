import React from 'react';
import { Switch } from 'react-router';
import { useSelector } from 'react-redux';

import { selectLanguage } from 'util/selector/querySelector';

import { AppRoute } from 'app/route/app';
import { createRelativeRoute } from 'component/RelativeRoute/RelativeRoute';
import { useReportRoutes } from 'router/ReportRoutes/useReportRoutes';
import { renderRoutes } from 'router/components/renderRoutes';
import { useGetUserPermissionsOnCurrentProjectChange } from 'router/hooks/useGetUserPermissionsOnCurrentProjectChange';
import { useFetchDemographyReport } from 'shared/hooks/useFetchDemography';
import { useFetchDepartments } from 'shared/hooks/useFetchDepartments';
import { useFetchCurrentProject } from 'shared/hooks/useFetchCurrentProject';
import { useLocalize } from 'shared/hooks/useLocalize';

const RelativeRoute = createRelativeRoute(AppRoute.HOME);

export const ReportRoutes: React.FC = () => {
  const routes = useReportRoutes();

  useFetchCurrentProject();
  useGetUserPermissionsOnCurrentProjectChange();
  useFetchDemographyReport();
  useFetchDepartments();
  const language = useSelector(selectLanguage);

  useLocalize(({ localize }) => {
    localize.setLanguage(language);
    localize.hideWidget();
  });

  return <Switch>{renderRoutes({ RelativeRoute, routes })}</Switch>;
};
