import React from 'react';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { selectHasMatch } from 'util/selector/routerSelector';

import { openInNewTab } from 'reduxStore/router/asyncActions';
import { useAppDispatch } from 'reduxStore/appStore';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import { WBNavButton } from './WBNavItem.s';

type WBNavItemProps = {
  href: string;
  label: string;
  matchHref?: string | string[];
  externalLink?: boolean;
  isSubNav: boolean;
  hasVisibleRibbonNavBarItems: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  'data-testid'?: string;
};

export const WBNavItem: React.FC<WBNavItemProps> = (props) => {
  const { isSubNav } = props;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const matchHrefArray = props.matchHref
    ? typeof props.matchHref === 'string'
      ? [props.matchHref]
      : props.matchHref
    : [];

  const hasMatchParamsArray = !props.matchHref ? [props.href] : matchHrefArray;

  const isActive = useSelector(selectHasMatch(...hasMatchParamsArray));

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.href) {
      const isExternalHref = props.href.startsWith('http://') || props.href.startsWith('https://');
      if (isExternalHref) {
        dispatch(openInNewTab(props.href));
      } else {
        dispatch(push(props.href));
      }
    }

    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <WBNavButton
      {...props}
      isDesktop={isDesktop}
      isActive={isActive}
      isSubNav={isSubNav}
      hasVisibleRibbonNavBarItems={props.hasVisibleRibbonNavBarItems}
      onClick={onClick}
    >
      <Typography variant={isSubNav ? 'subtitle2' : 'h6'}>{props.label}</Typography>
    </WBNavButton>
  );
};
