import { styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { BLACK, CORNFLOWER_BLUE } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

const AvatarWidth = 80;

export const EmployeeName = styled('span')({
  ...typography.h5,
  fontWeight: 800,
  overflow: 'hidden',
  paddingBottom: '0.5rem',
  '@media screen': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const EmployeeInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  alignSelf: 'center',
});

export const AdditionalInfoLabel = styled('span')({
  fontSize: '13px',
});

export const AdditionalInfoValue = styled('span')({
  fontSize: '13px',
  fontWeight: 600,
});

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  ...typography.h5,
  width: `${AvatarWidth}px`,
  height: `${AvatarWidth}px`,
  marginRight: '25px',
  flex: `0 0 ${AvatarWidth}px`,
  alignSelf: 'center',
  backgroundColor: CORNFLOWER_BLUE,
  color: BLACK,
  fontWeight: 800,
  [theme.breakpoints.down('md')]: {
    width: '60px',
    height: '60px',
    marginRight: '16px',
    flex: '0 0 60px',
  },
}));
