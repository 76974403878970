import React from 'react';

import { ButtonsWrapper, ButtonWrapper } from 'component/SwitchButton/SwitchButton.s';
import Tooltip from '@mui/material/Tooltip';
import { ModeType } from 'register/ModeType';

export type SwitchButtonProps = {
  leftButtonLabel: string;
  rightButtonLabel: string;
  leftButtonValue: ModeType;
  rightButtonValue: ModeType;
  onLeftButtonClick: () => void;
  onRightButtonClick: () => void;
  activeButton: ModeType;
  isCombineModeLock: boolean;
  isCompareModeLock: boolean;
  respondentProtection: number;
};

export const SwitchButton: React.FC<SwitchButtonProps> = (props) => {
  return (
    <ButtonsWrapper>
      <ButtonWrapper
        as="div"
        isActive={props.activeButton === props.leftButtonValue}
        isDisabled={props.isCompareModeLock}
        onClick={() => !props.isCompareModeLock && props.onLeftButtonClick()}
        isLeftButton
        variant="subtitle2"
        data-cy="heatmapButton"
      >
        {props.leftButtonLabel}
      </ButtonWrapper>
      <Tooltip
        id="combineModeButton"
        title={
          props.isCombineModeLock
            ? `You have selected a group with fewer than ${props.respondentProtection} responses.
               Unable to combine results for confidentiality reasons.`
            : ''
        }
      >
        <ButtonWrapper
          as="div"
          isActive={props.activeButton === props.rightButtonValue}
          isDisabled={props.isCombineModeLock}
          onClick={() => !props.isCombineModeLock && props.onRightButtonClick()}
          variant="subtitle2"
          data-cy="combineButton"
        >
          {props.rightButtonLabel}
        </ButtonWrapper>
      </Tooltip>
    </ButtonsWrapper>
  );
};
