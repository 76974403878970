import { Box, styled } from '@mui/material';
import { WHITE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

type MatrixContainerProps = {
  isReportView?: boolean;
};

export const MatrixContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isReportView',
})((props: MatrixContainerProps) => ({
  background: WHITE,
  borderRadius: '10px',
  overflowX: props.isReportView ? 'initial' : 'auto',
  '@media print': {
    margin: 0,
    borderRadius: 0,
  },
}));

export const Matrix = styled('table')({
  backgroundColor: WHITE,
  borderRadius: 10,
  borderCollapse: 'collapse',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    'th:nth-of-type(-n + 2), td:nth-of-type(-n + 2)': {
      position: 'sticky',
      left: 0,
      zIndex: 2,
    },
  },
  'th:nth-of-type(-n + 2), td:nth-of-type(1)': {
    backgroundColor: WHITE,
  },
  'th:nth-of-type(2), td:nth-of-type(2)': {
    left: '236px',
  },
});

export const RestrictionMessage = styled(Box)({
  fontWeight: 600,
  float: 'right',
  margin: '10px 70px 0 0',
});
