import { convertObjectToArray } from 'util/convertObjectToArray';

import { SimpleProjectApi } from 'api/SimpleProjectApi';
import { BarChartModel } from 'model/BarChart';
import { CompareGroup } from 'model/CompareGroup';
import { SubmitType } from 'model/SubmitType';

import { CompareGroupMapper } from './CompareGroupMapper';

export class BarChartMapper {
  constructor(private compareGroupMapper: CompareGroupMapper) {}

  deserializeCombine(entry: SimpleProjectApi.BarChartCombineEntry): BarChartModel {
    return {
      value: entry.percentage,
      type: entry.type,
      label: entry.label,
      option: entry.option,
    };
  }

  deserializeCompare(
    entry: SimpleProjectApi.BarChartCompareEntry,
    groups: { [key: number]: string },
    protections: { [key: number]: boolean }
  ): { label: string; type: SubmitType; groups: ReadonlyArray<CompareGroup> } {
    return {
      label: entry.label,
      type: entry.type,
      groups: convertObjectToArray(entry.byGroup).map((group) =>
        this.compareGroupMapper.deserialize(group, groups, protections)
      ),
    };
  }
}
