import { selectHasMatch, selectMatch, selectQueryParam } from 'util/selector/routerSelector';

import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';
import { AppRoute } from 'app/route/app';
import { ProjectKind, REGULAR_PROJECT_KIND_LIST } from 'model/ProjectKind';
import { Project } from 'model/Project';
import { Question } from 'model/Question';
import { PulseFrequency } from 'model/PulseFrequency';
import { PulseNavigationOption } from 'component/Navigation/PulseSelect';
import moment from 'moment';
import { QueryType } from 'model/QueryType';
import { SentimentState } from 'model/Sentiment';
import { LoadingStateEnum } from 'model/Resource';

import { ProjectState, STORE_NAME } from './initialState';

export function selectDomain(state: AppState | undefined): ProjectState.Domain {
  return state?.[STORE_NAME] || ProjectState.INITIAL_DOMAIN;
}

export const selectProjectList = createSelector(selectDomain, (domain) => domain?.list);

export const selectCurrentProjectResource = createSelector(
  selectDomain,
  (domain) => domain.current
);

export const selectCurrentProject = createSelector(selectCurrentProjectResource, (resource) => {
  return resource.data || ProjectState.INITIAL_DOMAIN.current.data;
});
export const selectCurrentProjectIdFromState = createSelector(
  selectCurrentProject,
  (currentProject) => currentProject?.id
);

export const selectIsCurrentProjectResolved = createSelector(
  selectCurrentProjectResource,
  (resource) => resource.loadingState === LoadingStateEnum.RESOLVED
);

export const selectPagination = createSelector(
  selectDomain,
  (domain) => domain && domain.pagination
);

export const selectHasProjects = createSelector(
  selectProjectList,
  (projects) => projects.data?.length !== 0
);

// TODO: Separate below selector for 2 different selectors, one from the url, another from the state. https://buzzing.atlassian.net/browse/WB3-1736
export const selectCurrentProjectId = createSelector(
  selectMatch(AppRoute.PROJECT_PATTERN),
  selectQueryParam<string>(QueryType.ProjectId),
  selectCurrentProject,
  (match, projectIdFromQueryParam, { id: stateId }) => {
    const urlId: number | undefined | typeof NaN = match?.params && Number(match.params.id);
    const projectIdFromQueryParamCasted = Number(projectIdFromQueryParam);

    if (urlId && !isNaN(urlId)) {
      return urlId;
    }

    if (projectIdFromQueryParamCasted && !isNaN(projectIdFromQueryParamCasted)) {
      return projectIdFromQueryParamCasted;
    }

    return stateId;
  }
);

export const selectProjectIdParam = createSelector(selectQueryParam('projectId'), (projectId) => {
  return Number(projectId);
});

export const selectCurrentProjectName = createSelector(
  selectCurrentProject,
  (project) => project && project.name
);

export const selectCurrentCompanyName = createSelector(
  selectCurrentProject,
  (project) => project && project.companyName
);

export const selectRespondentProtection = createSelector(
  selectCurrentProject,
  (project) => project && project.settings && project.settings.respondentProtection
);

export const selectAggregationMonths = createSelector(
  selectCurrentProject,
  (project) => project?.settings?.aggregationMonths || ProjectState.DEFAULT_AGGREGATION_MONTHS
);

export const selectCompanyLogoUrl = createSelector(
  selectCurrentProject,
  (project) => project && project.settings && project.settings.logo
);

export const selectMinResponsesCloseQuestion = createSelector(
  selectRespondentProtection,
  (responses) => responses && responses.closed
);

export const selectProjectClosedAt = createSelector(selectCurrentProject, (project) => {
  if ('closedAt' in project) {
    return project.closedAt;
  }
  console.error('Something is wrong, you are trying to access field which does not exist');
  return moment();
});

export const selectQuestionsStats = createSelector(
  selectDomain,
  (domain) => domain.questionsStats?.data
);

export const selectHasCommentQuestion = createSelector(
  selectQuestionsStats,
  (stats): boolean => !!(stats && Question.hasCommentsQuestion(stats))
);

export const selectHasInsightQuestion = createSelector(
  selectQuestionsStats,
  (stats) => !!(stats && Question.hasInsightsQuestion(stats))
);

export const selectProjectKind = createSelector(selectCurrentProject, (project) => project?.kind);

export const selectIsRegularProjectKind = createSelector(selectProjectKind, (kind) => {
  return REGULAR_PROJECT_KIND_LIST.some((expectedKind) => expectedKind === kind);
});

export const selectIsPulseProjectKind = createSelector(
  selectProjectKind,
  (kind) => kind && kind === ProjectKind.PULSE_PROJECT
);

export const selectIsPulseProjectFamilyKind = createSelector(selectProjectKind, (kind) =>
  [
    ProjectKind.PULSE_PROJECT,
    ProjectKind.PULSE_ON_DEMAND_PROJECT,
    ProjectKind.PULSE_TEMPLATE,
  ].includes(kind)
);

export const selectIsPulseTemplateProjectKind = createSelector(
  selectProjectKind,
  (kind) => kind && kind === ProjectKind.PULSE_TEMPLATE
);

export const selectIsPulseOrTemplateProjectKind = createSelector(selectProjectKind, (kind) => {
  return kind && [ProjectKind.PULSE_TEMPLATE, ProjectKind.PULSE_PROJECT].includes(kind);
});

export const selectIsOnDemandProjectKind = createSelector(
  selectProjectKind,
  (kind) => kind === ProjectKind.PULSE_ON_DEMAND_PROJECT
);

export const selectIsLifecycleProjectKind = createSelector(
  selectProjectKind,
  (kind) => kind === ProjectKind.PULSE_LIFECYCLE_PROJECT
);

export const selectIsEngagementScoreCompatibleProject = createSelector(
  selectProjectKind,
  (kind) =>
    kind === ProjectKind.PROJECT_ENGAGEMENT_SCORE ||
    kind === ProjectKind.PULSE_PROJECT ||
    kind === ProjectKind.PULSE_TEMPLATE
);

export const selectIsCasProjectKind = createSelector(
  selectProjectKind,
  (kind) => kind === ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE
);

export const selectIsOverallProjectKind = createSelector(
  selectProjectKind,
  (kind) => kind === ProjectKind.PROJECT_OVERALL_SCORE
);

export const selectProjectSettings = createSelector(
  selectCurrentProject,
  (project) => project && project.settings
);

export const selectIsOverallScoreShow = createSelector(
  selectProjectSettings,
  (settings) => !!settings?.isOverallScoreShow
);

export const selectShowConsentQuestion = createSelector(
  selectProjectSettings,
  (settings) => settings && settings.showConsentQuestion
);

export const selectIsOnPageWithCloseQuestion = createSelector(
  selectHasMatch(AppRoute.Home.SUMMARY),
  selectHasMatch(AppRoute.INSIGHTS),
  (isOnSummaryPage, isOnInsightPage) => isOnSummaryPage || isOnInsightPage
);

export const selectRespondentProtectionNumber = createSelector(
  selectRespondentProtection,
  selectIsOnPageWithCloseQuestion,
  (respondent, isOnPageWithCloseQuestions) =>
    respondent && (isOnPageWithCloseQuestions ? respondent.closed : respondent.comment)
);

export const selectPulseTemplateId = createSelector(selectCurrentProject, (project) => {
  if (project.kind === ProjectKind.PULSE_TEMPLATE) {
    return project.id;
  }
  return project.pulseTemplateId;
});

export const selectHasMoreThanOneProject = createSelector(
  selectProjectList,
  (projects) => projects.data.length > 1
);

export const selectReportTitle = createSelector(
  selectCurrentProject,
  (project) => project && Project.getReportTitle(project)
);

export const selectReportAvailabilities = createSelector(
  selectDomain,
  (project) => project && project.reportAvailabilities
);

export const selectIsSentimentAnalysisEnabled = createSelector(
  selectProjectSettings,
  (settings) => settings && settings.sentimentAnalysis
);

export const selectReportAvailability = (departmentId: number) =>
  createSelector(selectReportAvailabilities, (reportAvailabilities) =>
    reportAvailabilities.data?.find(
      (reportAvailability) => reportAvailability.departmentId === departmentId
    )
  );

export const selectProjectPulses = createSelector(selectCurrentProject, (project) => {
  return Project.isPulseTemplateOrOneOfChild(project) ? project.pulseProjects : [];
});

export const selectProjectOnDemandPulses = createSelector(selectCurrentProject, (project) => {
  return Project.isPulseTemplateOrOneOfChild(project) ? project.pulseOnDemandProjects : [];
});

export const selectSentimentIsLoaded = createSelector(
  selectDomain,
  (domain) => domain.sentiment?.data === SentimentState.Loaded
);

export const selectCurrentProjectIsHighPulseFrequency = createSelector(
  selectCurrentProject,
  (project) => {
    if ('pulseFrequencyId' in project && project.pulseFrequencyId !== null) {
      return [PulseFrequency.weekly, PulseFrequency.biWeekly, PulseFrequency.monthly].includes(
        project.pulseFrequencyId
      );
    }
    return false;
  }
);

/**
 * Used to select all project user can navigate to while visiting any  pulse project child (ODP, lifecycle pulse)
 */
export const selectPulseProjectAvailableProjectsOptions = createSelector(
  selectCurrentProject,
  (project): PulseNavigationOption[] => {
    let options: PulseNavigationOption[] = [];

    if (!Project.isPulseTemplateOrOneOfChild(project)) {
      return options;
    }

    const { latestPulseId, latestOnDemandId } = Project.getAvailableProjectOptions(project);

    if (latestPulseId) {
      options.push({
        label: 'Engage (Pulse Surveys)',
        projectId: latestPulseId,
        kind: ProjectKind.PULSE_PROJECT,
      });
    }

    if (latestOnDemandId) {
      options.push({
        label: 'On Demand Polls',
        projectId: latestOnDemandId,
        kind: ProjectKind.PULSE_ON_DEMAND_PROJECT,
      });
    }

    if (project.pulseLifecycleProjects.length > 0) {
      options = options.concat(
        project.pulseLifecycleProjects.map((pulse) => ({
          label: pulse.name,
          projectId: pulse.id,
          kind: ProjectKind.PULSE_LIFECYCLE_PROJECT,
        }))
      );
    }

    return options;
  }
);

// When viewing not the latest ODP/pulse, it's ID is not in any of the options.
// Setting (options.find || currentProjectId) so that Select component doesn't get created uncontrolled
export const selectCurrentPulseProjectAvailableProjectsOptionId = createSelector(
  selectProjectKind,
  selectCurrentProjectId,
  selectPulseProjectAvailableProjectsOptions,
  (projectKind, currentProjectId, options) =>
    projectKind === ProjectKind.PULSE_LIFECYCLE_PROJECT
      ? currentProjectId
      : options.find((option) => option.kind === projectKind)?.projectId || currentProjectId
);

export const selectIsPulseLifecycleProjectKind = createSelector(
  selectProjectKind,
  (kind) => kind === ProjectKind.PULSE_LIFECYCLE_PROJECT
);

export const selectCurrentProjectLifecycleType = createSelector(selectCurrentProject, (project) => {
  if (project.kind !== ProjectKind.PULSE_LIFECYCLE_PROJECT) {
    throw new Error(`Project which you use is not ${ProjectKind.PULSE_LIFECYCLE_PROJECT}`);
  }
  return project.lifecycleType;
});
