import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectInsightsCompared,
  selectInsightsComparedResource,
} from 'reduxStore/insights/selectors';
import { InsightsHeatmapLegend } from 'component/HeatmapLegend/HeatMapLegend';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { Box } from '@mui/material';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { fetchInsightsCompared } from 'reduxStore/insights/asyncActions';
import { InsightsComparedQuestionMatrix } from 'view/InsightsPage/compared/InsightsComparedQuestionMatrix';
import { NoResultsMessage } from 'component/NoResultsMessage/NoResultsMessage';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';

export const InsightsComparedPage: React.FC = () => {
  const insightsComparedResource = useSelector(selectInsightsComparedResource);
  const insightsCompared = useSelector(selectInsightsCompared);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);

  useFetchInsights(fetchInsightsCompared);

  return (
    <ResourceLoader resource={insightsComparedResource}>
      <PulseFilter />
      {insightsCompared?.insights.length === 0 && (
        <NoResultsMessage
          title="For this pulse survey, we asked no insights questions for the group you are viewing feedback for."
          hideIcon
        />
      )}

      {insightsCompared && insightsCompared.insights.length > 0 && (
        <>
          <Box mb={3}>
            <InsightsHeatmapLegend />
          </Box>
          {insightsCompared?.insights?.map((item, index) => (
            <InsightsComparedQuestionMatrix
              key={index}
              item={item}
              groups={insightsCompared.groups}
              minResponses={minResponses}
            />
          ))}
        </>
      )}
    </ResourceLoader>
  );
};
