import { selectHasMatch } from 'util/selector/routerSelector';
import { selectReportProjectId } from 'util/selector/reportSelector';
import { selectReportCategoryQuery } from 'util/selector/querySelector';

import { demographyMapper } from 'container/demographyMapper';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { ReportRoute } from 'app/route/report';
import { demographyApi } from 'container/demographyApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Demography } from 'model/Demography';
import { serializeError } from 'shared/utils/redux';

import { STORE_NAME } from './initialState';

export const fetchDemography = createAsyncThunk<Demography[]>(
  `${STORE_NAME}/fetchDemography`,
  (_, { getState }) => {
    const reportCategory = selectReportCategoryQuery(getState());

    const isReport = selectHasMatch(ReportRoute.REPORT_PATTERN)(getState());
    const projectId = isReport
      ? selectReportProjectId(getState())
      : selectCurrentProjectId(getState());

    return demographyApi
      .list(projectId, { reportCategory })
      .then((list) => list.map((demography) => demographyMapper.deserialize(demography)));
  },
  { serializeError }
);
