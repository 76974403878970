import React from 'react';
import { useSelector } from 'react-redux';

import { scrollTop } from 'util/scrollTop';
import { selectFiltersQuery } from 'util/selector/querySelector';

import {
  SummaryWidgetsContent,
  SummaryWidgetsWrapper,
} from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { Trend } from 'model/Trend';
import { selectBenchmark, selectTrends } from 'reduxStore/summary/selectors';
import { selectCurrentProjectId, selectIsOverallScoreShow } from 'reduxStore/project/selectors';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { AppRoute } from 'app/route/app';
import { routeWithQuery } from 'reduxStore/router/asyncActions';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { ResponseRateWidget } from 'view/ReportView/SummaryWidgetReport/ResponseRateWidget';
import { setEngagementDriverByCategory } from 'reduxStore/engagementDrivers/asyncActions';
import { selectShouldShowEngagementScore } from 'reduxStore/engagementDrivers/selectors';
import { selectChartLabel } from 'reduxStore/visualSettings/selectors';
import { PercentageBarSize } from 'component/PercentageBar/PercentageBarSize';
import { Benchmark } from 'model/Benchmark';
import { useAppDispatch } from 'reduxStore/appStore';

export type DemandSummaryCombineWidgetsProps = {
  isReportView?: boolean;
};

export type SummaryCombineWidgetsPureProps = DemandSummaryCombineWidgetsProps & {
  onProjectLabelClick: (projectId: number, categoryId: number) => void;
  query: string;
  projectId: number;
  isUserAdmin: boolean;
  benchmark?: Benchmark;
  trends?: ReadonlyArray<Trend>;
  showEngagementScore: boolean;
  isOverallShow: boolean;
  chartLabel: string | null;
};

export const SummaryCombineWidgetsPure: React.FC<SummaryCombineWidgetsPureProps> = (props) => {
  return (
    <SummaryWidgetsWrapper isReportView={props.isReportView}>
      <ScrollBar maxWidth={960} maxHeight={530} overflow="horizontal" light>
        <SummaryWidgetsContent>
          <ResponseRateWidget
            projectId={props.projectId}
            isReportView={props.isReportView}
            percentageBarSize={PercentageBarSize.BIG}
          />
        </SummaryWidgetsContent>
      </ScrollBar>
    </SummaryWidgetsWrapper>
  );
};

export const DemandSummaryCombineWidgets: React.FC<DemandSummaryCombineWidgetsProps> = (props) => {
  const dispatch = useAppDispatch();

  const trends = useSelector(selectTrends);
  const benchmark = useSelector(selectBenchmark);
  const query = useSelector(selectFiltersQuery);
  const projectId = useSelector(selectCurrentProjectId);
  const isUserAdmin = useSelector(selectIsAdmin);
  const showEngagementScore = useSelector(selectShouldShowEngagementScore);
  const isOverallShow = useSelector(selectIsOverallScoreShow);
  const chartLabel = useSelector(selectChartLabel);

  const onProjectLabelClick = (projectId: number, categoryId: number) => {
    dispatch(
      routeWithQuery({
        pattern: AppRoute.Home.Summary.REPORT,
        params: { id: projectId, categoryId: categoryId },
      })
    );
    dispatch(setEngagementDriverByCategory());
    scrollTop();
  };

  return (
    <SummaryCombineWidgetsPure
      {...props}
      onProjectLabelClick={onProjectLabelClick}
      trends={trends}
      benchmark={benchmark}
      query={query}
      projectId={projectId}
      isUserAdmin={isUserAdmin}
      showEngagementScore={showEngagementScore}
      isOverallShow={isOverallShow}
      chartLabel={chartLabel}
    />
  );
};
