import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';
import { LoadingStateEnum, Resource } from 'model/Resource';
import { Insight } from 'model/Insight';
import { InsightsComparedModel } from 'api/dto/new/InsightsCompared.dto';
import { InsightsLifecycleComparedModel } from 'api/dto/new/InsightsLifecycleCompared.dto';

import { STORE_NAME } from './initialState';

const selectDomain = (state: AppState) => state[STORE_NAME];

/* Combined insights */
export const selectInsightsCombinedResource = createSelector(
  selectDomain,
  (domain) => domain.combined
);

export const selectInsightsCombined = createSelector(
  selectInsightsCombinedResource,
  ({ data }) => data
);

/* Lifecycle combined insights */
export const selectInsightsLifecycleCombinedResource = createSelector(
  selectDomain,
  (domain) => domain.lifecycleCombined
);

export const selectInsightsLifecycleCombined = createSelector(
  selectInsightsLifecycleCombinedResource,
  ({ data }) => data
);

export const selectInsightsLifecycleCombinedNumberOfRespondents = createSelector(
  selectInsightsLifecycleCombined,
  (lifecycleCombined) => lifecycleCombined?.numberOfEmployees || 0
);

/* Compared insights */
export const selectInsightsComparedResource = createSelector(
  selectDomain,
  (domain) => domain.compared
);

export const selectInsightsCompared = createSelector(
  selectInsightsComparedResource,
  ({ data }) => data
);

export const selectIsCombineModeLockFromInsightsCompared = createSelector(
  selectInsightsCompared,
  (data) => !!data?.combineModeLock
);

/* Lifecycle compared insights */
export const selectInsightsLifecycleComparedResource = createSelector(
  selectDomain,
  (domain) => domain.lifecycleCompared
);

export const selectInsightsLifecycleCompared = createSelector(
  selectInsightsLifecycleComparedResource,
  ({ data }) => data
);

export const selectInsightsLifecycleComparedNumberOfRespondents = createSelector(
  selectInsightsLifecycleCompared,
  (lifecycleCompared) => lifecycleCompared?.numberOfRespondents || 0
);

function isCombinedResource(
  resource:
    | Resource<Insight.Combine>
    | Resource<InsightsComparedModel | null>
    | Resource<InsightsLifecycleComparedModel | null>
): resource is Resource<Insight.Combine> {
  return (
    resource &&
    resource.data !== null &&
    typeof resource.data === 'object' &&
    resource.data.hasOwnProperty('data')
  );
}

export const selectHasInsightsResolved = createSelector(
  selectInsightsCombinedResource,
  selectInsightsLifecycleCombinedResource,
  selectInsightsComparedResource,
  selectInsightsLifecycleComparedResource,
  (
    insightsCombinedResource,
    insightsLifecycleCombinedResource,
    insightsComparedResource,
    insightsLifecycleComparedResource
  ) =>
    [
      insightsCombinedResource,
      insightsLifecycleCombinedResource,
      insightsComparedResource,
      insightsLifecycleComparedResource,
    ].some((resource) => {
      if (resource.loadingState === LoadingStateEnum.RESOLVED && resource.data) {
        // Combined cases
        if (isCombinedResource(resource)) {
          return resource.data.data.length > 0;
        }
        // Compared cases
        return true;
      }
      // Loading, or no data
      return false;
    })
);
