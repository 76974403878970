import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { selectModeType } from 'util/selector/querySelector';

import { useAppDispatch } from 'reduxStore/appStore';
import {
  selectCurrentProjectIdFromState,
  selectIsCasProjectKind,
  selectIsLifecycleProjectKind,
  selectIsOnDemandProjectKind,
} from 'reduxStore/project/selectors';
import {
  fetchSimpleProject,
  fetchSimpleProjectDebounced,
} from 'reduxStore/simpleProject/asyncActions';
import { selectSelectedClassifications } from 'reduxStore/demography/selectors';
import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';

export const useFetchSimpleProject = () => {
  const dispatch = useAppDispatch();
  const currentProjectIdFromState = useSelector(selectCurrentProjectIdFromState);
  const isLifecycleKind = useSelector(selectIsLifecycleProjectKind);
  const isOnDemandKind = useSelector(selectIsOnDemandProjectKind);
  const isCASKind = useSelector(selectIsCasProjectKind);
  const isCompatibleProjectKind = isLifecycleKind || isOnDemandKind || isCASKind;
  const selectedClassificationsString = useSelector(selectSelectedClassifications).toString();
  const selectedDepartmentIdsString = useSelector(selectSelectedDepartmentIds).toString();
  const modeType = useSelector(selectModeType);

  useEffect(() => {
    if (isCompatibleProjectKind) dispatch(fetchSimpleProject());
  }, [dispatch, isCompatibleProjectKind, modeType, currentProjectIdFromState]);

  useUpdateEffect(() => {
    if (isCompatibleProjectKind) dispatch(fetchSimpleProjectDebounced());
  }, [dispatch, selectedClassificationsString, selectedDepartmentIdsString]);
};
