import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import isEqual from 'lodash/isEqual';

import { useDebounceValue } from './useDebounceValue';

type UseDebouncedFiltersProps<T extends object> = {
  filters: T;
  debounceMs: number;
  onFiltersChange: (filters: T) => void;
};

export const useDebouncedFilters = <T extends object>({
  filters,
  debounceMs,
  onFiltersChange,
}: UseDebouncedFiltersProps<T>) => {
  const debouncedFilters = useDebounceValue(filters, debounceMs);
  const previousFilters = usePrevious(debouncedFilters);

  useEffect(() => {
    if (onFiltersChange && !isEqual(previousFilters, debouncedFilters)) {
      onFiltersChange(debouncedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters]);

  const mergeFilters = (value: Partial<T>) => {
    onFiltersChange({ ...filters, ...value });
  };

  return { debouncedFilters, mergeFilters };
};
