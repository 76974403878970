import { AxiosInstance } from 'axios';

export class AuthServiceApi {
  constructor(private client: AxiosInstance) {}

  logout(): Promise<any> {
    return this.client.post('/api/auth/logout');
  }

  refreshToken(): Promise<any> {
    return this.client.get('/api/auth/refresh-token');
  }
}
