import { Checkbox } from 'component/Checkbox/Checkbox';
import { styled } from '@mui/material';
import { ASH_IN_THE_AIR, BUTTON_BORDER, BUTTON_SHADOW, IRON, WHITE } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

export type OptionListProps = {
  visible: boolean;
};

export type DisplayOptionProps = {
  disabled?: boolean;
};

export const SelectBoxWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const DisplayOption = styled('div')(
  {
    ...typography.body1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    float: 'left',
    backgroundColor: WHITE,
    padding: '12px 16px',
    border: `2px solid ${IRON}`,
    borderRadius: '6px',
    justifyContent: 'space-between',
  },
  (props: DisplayOptionProps) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
  })
);

export const SelectOptionLabel = styled('span')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const OptionList = styled('div', {
  shouldForwardProp: (prop) => prop !== 'visible',
})(
  {
    float: 'left',
    position: 'relative',
    width: '100%',
    marginTop: '5px',
    zIndex: 200,
  },
  (props: OptionListProps) => ({
    transform: props.visible ? 'scale(1, 1)' : 'scale(1, 0)',
  })
);

export const OptionListItem = styled('div')({
  position: 'absolute',
  width: '100%',
  zIndex: 100,
  cursor: 'pointer',
  backgroundColor: WHITE,
  boxSizing: 'border-box',
  borderRadius: '5px',
  border: `1px solid ${BUTTON_BORDER}`,
  boxShadow: `0px 0px 11px 0px ${BUTTON_SHADOW}`,
  overflowY: 'auto',
  maxHeight: '211px',
});

export const OptionItem = styled('div')(
  {
    ...typography.body1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    width: '100%',
    padding: '12px 16px',
    boxSizing: 'border-box',
    '&:not(:first-child)': {
      borderTop: `1px solid ${BUTTON_BORDER}`,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ASH_IN_THE_AIR,
    },

    '@media(max-width: 900px)': {
      justifyContent: 'normal',
    },
  },
  (props: { disabled?: boolean; selected?: boolean; value?: any; label?: string }) => {
    return {
      ...(props.selected
        ? {
            backgroundColor: ASH_IN_THE_AIR,
          }
        : {}),
      ...(props.disabled
        ? {
            backgroundColor: ASH_IN_THE_AIR,
            // TODO refactor dropdown and remove !important
            cursor: 'not-allowed !important',
          }
        : {}),
    };
  }
);

export const LeftAlignedOptionItem = styled(OptionItem)({
  justifyContent: 'flex-start',
  backgroundColor: 'inherit',
  '&:hover': {
    backgroundColor: 'inherit',
  },
});

export const CheckboxOption = styled(Checkbox)({
  margin: '0 10px',
  pointerEvents: 'none',
});
