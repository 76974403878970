import { GroupedClassifications } from 'util/createFiltersQuery';

import { AxiosInstance } from 'axios';
import { buildUrl } from 'shared/utils/builders/buildUrl';
import { QueryType } from 'model/QueryType';
import { AdvocacyDto } from 'api/dto/Advocacy.dto';
import { mapReportFormatToAcceptHeader, ReportFormatType } from 'model/ReportFormat';
import { MonthlyBreakdownReportType } from 'model/MonthlyBreakdownReport';
import { SortBy } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/utils';

export namespace ReportApi {
  export type GetRespondentsIndividualFeedbackReportParams = {
    type: ReportFormatType;
    projectId: number;
    respondentIds: string[];
    language: string;
    sortBy: SortBy;
  };

  export type GetIndividualFeedbackReportParams = {
    type: ReportFormatType;
    projectId: number;
    advocacyFilter: AdvocacyDto[];
    classifications: GroupedClassifications;
    departments: number[];
    respondentLikeFilter: string;
    language: string;
    sortBy: SortBy;
  };
}

export class ReportApi {
  constructor(private client: AxiosInstance) {}

  getFeedbackReport(
    projectId: number,
    query: string,
    mode: string,
    type: ReportFormatType
  ): Promise<File> {
    return this.client
      .get(`/api/v1/project/${projectId}/report/summary/${mode}${query}&format=${type}`, {
        responseType: 'blob',
      })
      .then((response) => response.data);
  }

  getCategoryReport(
    projectId: number,
    categoryId: number,
    query: string,
    mode: string,
    type: ReportFormatType
  ): Promise<File> {
    return this.client
      .get(
        `/api/v1/project/${projectId}/report/summary/${categoryId}/${mode}${query}&format=${type}`,
        { responseType: 'blob' }
      )
      .then((response) => response.data);
  }

  getCommentReport(projectId: number, questionId: number, query: string): Promise<File> {
    return this.client
      .get(`/api/v1/project/${projectId}/survey/question/${questionId}/answer${query}`, {
        responseType: 'blob',
      })
      .then((response) => response.data);
  }

  getInsightsCombinedReport(
    projectId: number,
    query: string,
    type: ReportFormatType
  ): Promise<File> {
    return this.client
      .get(`/api/v1/project/${projectId}/report/insights/combined${query}&format=${type}`, {
        responseType: 'blob',
      })
      .then((response) => response.data);
  }

  getInsightsComparedReport(
    projectId: number,
    query: string,
    type: ReportFormatType
  ): Promise<File> {
    return this.client
      .get(`/api/survey/${projectId}/report/insights-compared${query}`, {
        responseType: 'blob',
        headers: { Accept: mapReportFormatToAcceptHeader(type) },
      })
      .then((response) => response.data);
  }

  getInsightsLifecycleComparedReport(
    projectId: number,
    query: string,
    type: ReportFormatType
  ): Promise<File> {
    return this.client
      .get(`/api/survey/${projectId}/report/insights-lifecycle-compared${query}`, {
        responseType: 'blob',
        headers: { Accept: mapReportFormatToAcceptHeader(type) },
      })
      .then((response) => response.data);
  }

  getAllFeedbackPDFReport(projectId: number, query: string): Promise<File> {
    return this.client
      .get(`/api/v1/project/${projectId}/report/all-feedback${query}&format=pdf`, {
        responseType: 'blob',
      })
      .then((response) => response.data);
  }

  getAllCommentsReport(projectId: number, query: string, type: ReportFormatType): Promise<File> {
    return this.client
      .get(`/api/v1/project/${projectId}/survey/all-comments${query}&format=${type}`, {
        responseType: 'blob',
      })
      .then((response) => response.data);
  }

  getMonthlyBreakdownReport(
    projectId: number,
    query: string,
    monthlyBreakdownReport: MonthlyBreakdownReportType
  ): Promise<File> {
    return this.client
      .get(
        `/api/v1/project/${projectId}/report/${monthlyBreakdownReport}-monthly-breakdown${query}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => response.data);
  }

  getRespondentIndividualFeedbackReport({
    type,
    projectId,
    respondentIds,
    language,
    sortBy,
  }: ReportApi.GetRespondentsIndividualFeedbackReportParams): Promise<File> {
    return this.client
      .get(
        buildUrl(`/api/v1/project/${projectId}/report/individual-feedback`, {
          [QueryType.Filter]: { respondentIds },
          format: type,
          language,
          [QueryType.SortColumn]: sortBy.column,
          [QueryType.SortOrder]: sortBy.order,
        }),
        { responseType: 'blob' }
      )
      .then((response) => response?.data);
  }

  getIndividualFeedbackReport({
    type,
    projectId,
    advocacyFilter,
    classifications,
    departments,
    respondentLikeFilter,
    language,
    sortBy,
  }: ReportApi.GetIndividualFeedbackReportParams): Promise<File> {
    return this.client
      .get(
        buildUrl(`/api/v1/project/${projectId}/report/individual-feedback`, {
          [QueryType.Filter]: {
            [QueryType.Advocacy]: advocacyFilter,
            [QueryType.Classification]: classifications,
            [QueryType.Department]: departments,
            [QueryType.RespondentLike]: respondentLikeFilter,
          },
          format: type,
          language,
          [QueryType.SortColumn]: sortBy.column,
          [QueryType.SortOrder]: sortBy.order,
        }),
        { responseType: 'blob' }
      )
      .then((response) => response?.data);
  }
}
