import { OpinionQuestionApi } from 'api/OpinionQuestionApi';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';

import { FeedbackMapper } from './FeedbackMapper';
import { QuestionMapper } from './QuestionMapper';
import { TrendMapper } from './TrendMapper';
import { BenchmarkMapper } from './BenchmarkMapper';

export class OpinionQuestionDetailsMapper {
  constructor(
    private feedbackMapper: FeedbackMapper,
    private questionMapper: QuestionMapper,
    private trendMapper: TrendMapper
  ) {}

  deserialize(entry: OpinionQuestionApi.DetailsEntry): OpinionQuestionDetails.Combine {
    return {
      score: entry.score,
      feedback: entry.feedback
        ? entry.feedback.map((data) => this.feedbackMapper.deserialize(data))
        : [],
      question: entry.question && this.questionMapper.deserialize(entry.question),
      benchmark: BenchmarkMapper.deserialize(entry.benchmark),
      impactOnEngagement: entry.impactOnEngagement,
      trends: entry.trends
        ? entry.trends.map((trend) => this.trendMapper.deserialize(trend, entry.trend_question))
        : undefined,
    };
  }
}
