import React from 'react';
import { Route, RouteProps } from 'react-router';

import { relativePath } from 'router/relativePath';

export function createRelativeRoute(baseRoute: string): React.FC<RouteProps> {
  return ({ path, ...props }: RouteProps) =>
    typeof path === 'string' ? (
      <Route path={relativePath(baseRoute, path)} {...props} />
    ) : (
      <Route {...props} />
    );
}
