import { convertObjectToArray } from 'util/convertObjectToArray';

import { OverallSurveyApi } from 'api/OverallSurveyApi';
import { OverallSurvey } from 'model/OverallSurvey';

import { SummaryMapper } from './SummaryMapper';
import { OpinionQuestionMapper } from './OpinionQuestionMapper';

export class OverallSurveyMapper {
  constructor(
    private summaryMapper: SummaryMapper,
    private opinionQuestionMapper: OpinionQuestionMapper
  ) {}

  deserializeCombine(entry: OverallSurveyApi.EntryCombine): OverallSurvey.Combine {
    return {
      summary: this.summaryMapper.deserializeCombine(entry.summary),
      byCategory: convertObjectToArray(entry.byCategory).map((item) =>
        this.opinionQuestionMapper.deserializeCombine(item)
      ),
      showImpactOnEngagement: entry.showImpactOnEngagement,
    };
  }

  deserializeCompare(entry: OverallSurveyApi.EntryCompare): OverallSurvey.Compare {
    return {
      summary: convertObjectToArray(entry.summary).map((item) =>
        this.summaryMapper.deserializeCompare(item, entry.groups, entry.protections)
      ),
      byCategory: convertObjectToArray(entry.categories).map((item) =>
        this.opinionQuestionMapper.deserializeCompare(item, entry.groups, entry.protections)
      ),
      isCombineModeLock: entry.combineModeLock,
    };
  }
}
