import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsReportView } from 'util/selector/reportSelector';

import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import {
  selectHasSimpleProjectCompareQuestions,
  selectSimpleProjectCompareGroups,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { selectMinResponsesCloseQuestion } from 'reduxStore/project/selectors';
import { OnDemandTableCompare } from 'view/OnDemandPage/Compare/OnDemandTableCompare';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import { Resource } from 'model/Resource';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';

export type OnDemandPagePureProps = {
  hasQuestions: boolean;
  groups: string[];
  isReportView: boolean;
  minResponses: number;
  simpleProjectCompareResource: Resource<any>;
};

export const OnDemandComparePagePure: React.FC<OnDemandPagePureProps> = (props) => (
  <ResourceLoader resource={props.simpleProjectCompareResource}>
    <PulseFilter />
    <SummaryCompareWidgets />
    {props.hasQuestions && (
      <OnDemandTableCompare
        title="Survey Questions"
        groups={props.groups}
        minResponses={props.minResponses}
        isReportView={props.isReportView}
      />
    )}
  </ResourceLoader>
);

export const OnDemandComparePage: React.FC = () => {
  const hasQuestions = useSelector(selectHasSimpleProjectCompareQuestions);
  const groups = useSelector(selectSimpleProjectCompareGroups);
  const isReportView = useSelector(selectIsReportView);
  const minResponses = useSelector(selectMinResponsesCloseQuestion);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);

  return (
    <OnDemandComparePagePure
      hasQuestions={hasQuestions}
      groups={groups}
      isReportView={isReportView}
      minResponses={minResponses}
      simpleProjectCompareResource={simpleProjectCompareResource}
    />
  );
};
