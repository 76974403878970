import { useSelector } from 'react-redux';
import React from 'react';

import { PageWrapperHeader } from 'component/PageHeader/PageHeader.s';
import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import { downloadFeedbackReport } from 'reduxStore/report/asyncActions';
import { DocumentType } from 'model/DocumentType';
import { DocumentIcon } from 'component/DocumentIcon/DocumentIcon';
import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import { getReportActionNameByReportFormat } from 'reduxStore/report/getReportActionName';
import { selectShouldShowDownloadButton } from 'reduxStore/filtering/selectors';

export type LifecycleHeaderProps = {
  isDownloadDisabled?: boolean;
};

export const LifecycleHeader: React.FC<LifecycleHeaderProps> = ({ isDownloadDisabled }) => {
  const dispatch = useAppDispatch();

  const isExcelDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadFeedbackReport, { type: ReportFormat.Excel })
    )
  );
  const isPdfDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadFeedbackReport, { type: ReportFormat.PDF })
    )
  );
  const isPowerPointDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadFeedbackReport, { type: ReportFormat.PowerPoint })
    )
  );

  const onReportDownload = (type: ReportFormatType) =>
    dispatch(downloadFeedbackReport({ type, isLifecycleProjectKind: true }));

  const shouldShowPDFandPPT = useSelector(selectShouldShowDownloadButton);

  return (
    <PageWrapperHeader isTitleVisible={false}>
      {!isDownloadDisabled && (
        <Dropdown label="Download Report" data-testid="downloadReportDropdown">
          <DropdownItem
            data-testid="downloadReportExcel"
            onClick={() => onReportDownload(ReportFormat.ExcelSummary)}
          >
            <DocumentIcon type={DocumentType.Excel} isDownloadPending={isExcelDownloadPending} />
            Excel
          </DropdownItem>
          {shouldShowPDFandPPT && (
            <>
              <DropdownItem
                data-testid="downloadReportPDF"
                onClick={() => onReportDownload(ReportFormat.PDF)}
              >
                <DocumentIcon type={DocumentType.PDF} isDownloadPending={isPdfDownloadPending} />
                PDF
              </DropdownItem>
              <DropdownItem
                data-testid="downloadReportPowerpoint"
                onClick={() => onReportDownload(ReportFormat.PowerPoint)}
              >
                <DocumentIcon
                  type={DocumentType.PowerPoint}
                  isDownloadPending={isPowerPointDownloadPending}
                />
                Power Point
              </DropdownItem>
            </>
          )}
        </Dropdown>
      )}
    </PageWrapperHeader>
  );
};
