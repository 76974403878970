import React from 'react';

import { isQuestionKind } from 'util/isQuestionKind';

import { InsightQuestionWrapper } from 'view/InsightsPage/InsightsPage.s';
import { InsightsCombinedMode } from 'view/InsightsPage/combined/InsightsCombinedMode';
import { Insight } from 'model/Insight';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { Box, Typography } from '@mui/material';
import { QuestionKind } from 'model/QuestionKind';
import { HARD_COAL, SONIC_SILVER } from 'app/theme/colors';

export type InsightsCombinedQuestionProps = {
  isReportView?: boolean;
  isLifecycleProjectKind?: boolean;
  item: Insight.CombineData;
};

export const InsightsCombinedQuestion: React.FC<InsightsCombinedQuestionProps> = ({
  item,
  isLifecycleProjectKind,
  isReportView,
}) => {
  const shouldDisplayTrendPlotDataColumn =
    isLifecycleProjectKind &&
    item.answers.some((answer) => {
      return answer.trendPlotData?.some((trendItem) => trendItem !== null);
    });

  return (
    <InsightQuestionWrapper
      isReportView={isReportView}
      isLifecycleProjectKind={isLifecycleProjectKind}
      data-testid="insightQuestion"
      data-cy={`insightQuestion[${item?.question?.id ?? ''}]`}
      p={'0 24px'}
    >
      <ScrollBar overflow={'horizontal'} float={'none'} light paddingBottom={16}>
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, mt: 3, mb: 2 }}>
            <Typography variant="h5" m="24px 0" data-cy="insightQuestionHeader">
              {item.question.content}
            </Typography>
            {isLifecycleProjectKind && (
              <Typography variant="h6" sx={{ maxWidth: 80, textAlign: 'center', color: HARD_COAL }}>
                6 Month Trend
              </Typography>
            )}
          </Box>

          <InsightsCombinedMode
            shouldDisplayTrendPlotDataColumn={shouldDisplayTrendPlotDataColumn}
            item={item}
          />
        </>

        {isQuestionKind(item.question, QuestionKind.QuestionCheckbox) && (
          <Box
            sx={{ display: 'flex', justifyContent: 'right', mt: '16px' }}
            style={{ pageBreakBefore: 'avoid' }}
          >
            <Typography
              variant="body2"
              sx={{ color: SONIC_SILVER, textAlign: 'center', maxWidth: 400 }}
            >
              Employees could provide more than one answer, so the sum of the percentages may exceed
              100%.
            </Typography>
          </Box>
        )}
      </ScrollBar>
    </InsightQuestionWrapper>
  );
};
