import React from 'react';
import { AutoSizer, List } from 'react-virtualized';

import { treeToFlatList } from 'util/flattenTreeWithExpansion';

import { DepartmentListItem } from 'component/DepartmentList/DepartmentListItem';
import { Department } from 'model/Department';
import { ListWrapper, styledAutoList } from 'component/DepartmentList/DepartmentList.s';
import { Typography } from '@mui/material';

export type DepartmentListProps = {
  data: Department[];
  disabled: boolean;
};

export const DepartmentList: React.FC<DepartmentListProps> = ({ data, disabled }) => {
  const flatDepartmentList = data && treeToFlatList(data);
  const rowCount = flatDepartmentList.length;
  return (
    <>
      <ListWrapper data-cy="departmentList">
        <AutoSizer>
          {({ width, height }): any => (
            <List
              width={width}
              height={height}
              className={`${styledAutoList}`}
              rowHeight={30}
              rowCount={rowCount}
              overscanRowCount={10}
              noRowsRenderer={() => (
                <Typography ml={2.5} variant="body1">
                  No results
                </Typography>
              )}
              rowRenderer={({ style, key, index }): any => (
                <div key={key} style={style}>
                  <DepartmentListItem item={flatDepartmentList[index]} disabled={disabled} />
                </div>
              )}
            />
          )}
        </AutoSizer>
      </ListWrapper>
    </>
  );
};
