import { styled } from '@mui/material';

export const QuestionColumnWrapper = styled('td')(() => ({
  padding: '16px 4px 16px 16px',
  minWidth: '190px',
  verticalAlign: 'middle',

  '@media print': {
    width: 360,
  },
}));

export const QuestionContent = styled('div')({
  width: 'inherit',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const QuestionText = styled('span')({
  paddingRight: 5,
  '@media print': {
    overflow: 'auto',
    textOverflow: 'unset',
    whiteSpace: 'normal',
  },
});
