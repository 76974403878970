import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentProject } from 'reduxStore/project/selectors';
import { EngagementScorePage } from 'view/EngagementScorePage/EngagementScorePage';
import { CasPage } from 'view/CasPage/CasPage';
import { NotAvailableProjectKind } from 'view/NotAvailableProjectKind/NotAvailableProjectKind';
import { OverallPage } from 'view/OverallPage/OverallPage';
import { ProjectKind } from 'model/ProjectKind';
import { OnDemandPage } from 'view/OnDemandPage/OnDemandPage';
import { LifecyclePage } from 'view/LifecyclePage/LifecyclePage';
import { LoaderWrapper } from 'component/ResourceLoader/ResourceLoader.s';
import { LoadingIcon } from 'component/PageHeader/PageHeader.s';
import { DARK_ORANGE } from 'app/theme/colors';
import { useAppParams } from 'shared/hooks/useAppParams';
import { useFetchOverall } from 'shared/hooks/useFetchOverall';
import { usePushLegalQuery } from 'shared/hooks/usePushLegalQuery';
import { useIsCompareModeLock } from 'shared/hooks/useIsCompareModeLock';
import { useFetchDemography } from 'shared/hooks/useFetchDemography';
import { useFetchDepartments } from 'shared/hooks/useFetchDepartments';
import { useFetchSimpleProject } from 'shared/hooks/useFetchSimpleProject';

export const SummaryPage: React.FC = () => {
  const { projectId } = useAppParams();
  const { kind, id } = useSelector(selectCurrentProject);
  usePushLegalQuery();
  useFetchOverall({ enableDebounce: true });
  useIsCompareModeLock();
  useFetchDemography();
  useFetchDepartments();
  useFetchSimpleProject();

  if (projectId !== id) {
    return (
      <LoaderWrapper data-testid="loader">
        <LoadingIcon type="spin" color={DARK_ORANGE} height={40} width={40} />
      </LoaderWrapper>
    );
  }

  switch (kind) {
    case ProjectKind.PULSE_ON_DEMAND_PROJECT:
      return <OnDemandPage />;
    // in past pulse kind "pulse_project" had type 2 -> which was engagement score
    // Pulse project need new page that might lack engagement score
    case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
    case ProjectKind.PULSE_PROJECT:
      return <EngagementScorePage />;
    case ProjectKind.PULSE_LIFECYCLE_PROJECT:
      return <LifecyclePage />;
    case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
      return <CasPage />;
    case ProjectKind.PROJECT_OVERALL_SCORE:
      return <OverallPage />;

    default:
      return <NotAvailableProjectKind />;
  }
};
