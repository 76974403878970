import { useParams } from 'react-router';

import { parseValueToInteger } from 'util/parseValueToInteger';

export type AppParams = {
  /**
   * First in route Project ID
   */
  projectId: number | null;
};

type RouteParams = {
  id?: string;
};

export const useAppParams = (): AppParams => {
  const { id } = useParams<RouteParams>();

  return {
    projectId: parseValueToInteger(id),
  };
};
