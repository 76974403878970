import React from 'react';
import { useSelector } from 'react-redux';

import { ReportContent } from 'view/ReportView/ReportTemplate/ReportContent';
import { SummaryCompareWidgets } from 'view/SummaryPage/SummaryWidgets/SummaryCompareWidgets/SummaryCompareWidgets';
import { EngagementDriverCompare } from 'view/EngagementDriversView/EngagementDriverCompare';
import { EngagementDriverByCategoryCompare } from 'view/EngagementDriverByCategoryPage/EngagementDriverByCategoryCompare/EngagementDriverByCategoryCompare';
import { OpinionQuestion } from 'model/OpinionQuestion';
import { selectEngagementDriversCompareList } from 'reduxStore/engagementDrivers/selectors';
import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import { selectProjectClosedAt, selectProjectKind } from 'reduxStore/project/selectors';
import { Moment } from 'moment';

export type OverallSurveyReportComparePureProps = {
  engagementDrivers: OpinionQuestion.Compare[];
  projectKind: ProjectKind;
  closedAt?: Moment;
};

export const OverallSurveyReportComparePure: React.FC<OverallSurveyReportComparePureProps> = (
  props
) => {
  return (
    <div>
      <ReportContent title="Summary" component={<SummaryCompareWidgets isReportView />} />
      <ReportContent
        title={Project.getEngagementDriversTitle({
          kind: props.projectKind,
          closedAt: props.closedAt,
        })}
        component={<EngagementDriverCompare isReportView />}
      />
      {props.engagementDrivers ? (
        <div>
          {props.engagementDrivers.map((engagementDriver, index) => (
            <ReportContent
              key={index}
              title={engagementDriver.category!.name}
              component={
                <EngagementDriverByCategoryCompare
                  engagementDriverByCategory={engagementDriver}
                  groups={engagementDriver.groups}
                  groupsLength={engagementDriver.groups.length}
                  questions={engagementDriver.questions}
                  isReportView
                />
              }
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export const OverallSurveyReportCompare: React.FC = () => {
  const engagementDrivers = useSelector(selectEngagementDriversCompareList);
  const projectKind = useSelector(selectProjectKind);
  const closedAt = useSelector(selectProjectClosedAt);

  return (
    <OverallSurveyReportComparePure
      engagementDrivers={engagementDrivers}
      projectKind={projectKind}
      closedAt={closedAt}
    />
  );
};
