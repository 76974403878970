import { SxProps, styled } from '@mui/material';
import { ASH_IN_THE_AIR, IRON, LINK_WATER, PLATINUM, TEXT_COLOR, WHITE } from 'app/theme/colors';
import { pxToRem } from 'app/theme/utils/pxToRem';

export const WBPulseSelectWrapper = styled('div')(({ theme }) => ({
  padding: '0 50px',
  [theme.breakpoints.down('lg')]: {
    padding: 0,
  },
}));

export const WBPulseSelectStyles: SxProps = {
  // Bottom border styles
  '&:before': {
    borderColor: PLATINUM,
    borderWidth: 2,
  },
  '&:after': {
    borderColor: PLATINUM,
  },
  '&:not(.Mui-disabled):hover::before': {
    borderColor: PLATINUM,
  },

  // Icon Color
  '& .MuiSvgIcon-root': {
    color: TEXT_COLOR,
  },

  '& .MuiInputBase-input': {
    backgroundColor: WHITE + '!important',
  },
};

export const WBPulseSelectPaperStyles: SxProps = {
  borderRadius: '6px',
  border: `2px solid ${IRON}`,
  marginTop: '5px',

  '& h5': {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22.4),
  },

  '& li:hover': {
    background: ASH_IN_THE_AIR,
  },

  '& .Mui-selected': {
    background: LINK_WATER + '!important',
  },
};
