import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsCompareMode } from 'util/selector/querySelector';

import { PageHeader } from 'component/PageHeader/PageHeader';
import { CasCombinePage } from 'view/CasPage/CasCombinePage';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import { CasComparePage } from 'view/CasPage/CasComparePage';
import { selectCurrentProjectName } from 'reduxStore/project/selectors';
import { PageContentWrapper } from 'shared/styles/layout';
import {
  selectSimpleProjectCombineResource,
  selectSimpleProjectCompareResource,
} from 'reduxStore/simpleProject/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';

export const CasPage: React.FC = () => {
  const projectName = useSelector(selectCurrentProjectName);
  const isCompareMode = useSelector(selectIsCompareMode);

  const simpleProjectCombineResource = useSelector(selectSimpleProjectCombineResource);
  const simpleProjectCompareResource = useSelector(selectSimpleProjectCompareResource);

  const simpleProjectResource = isCompareMode
    ? simpleProjectCompareResource
    : simpleProjectCombineResource;

  return (
    <ResourceLoader
      resource={simpleProjectResource}
      rejected={(error) => (
        <>
          <PageHeader title={projectName} isDownloadDisabled />
          <PageContentWrapper>
            <ErrorHandler error={error} />
          </PageContentWrapper>
        </>
      )}
    >
      <PageHeader title={projectName} />
      <PageContentWrapper>
        {isCompareMode ? <CasComparePage /> : <CasCombinePage />}
      </PageContentWrapper>
    </ResourceLoader>
  );
};
