import { Department } from 'model/Department';

export type Node = {
  id: number;
  name: string;
  collapsed: boolean;
  matched: boolean;
  children: Node[];
};

export const nodeIncludeString = (query: string) => ({ name }: Node) =>
  name.toLocaleLowerCase().trim().includes(query.toLocaleLowerCase().trim());

const pruneTree = (
  node: Node,
  condition: (node: Node) => boolean,
  parentMatch: boolean,
  currentMatch: boolean,
  matchCondition: boolean
): Node | null => {
  const children = node.children
    .map((child) => searchPruneTree(child, condition))
    .filter((child) => child !== null);

  return children.length > 0 || matchCondition
    ? ({
        ...node,
        children: children,
        collapsed: (!currentMatch && hasNestedMatch(node, condition)) || node.collapsed,
        matched: currentMatch,
      } as Node)
    : null;
};

export const searchPruneTree = (node: Node, condition: (node: Node) => boolean): Node | null => {
  const currentMatch = hasNestedMatch(node, condition);

  return pruneTree(node, condition, false, currentMatch, currentMatch);
};

export const hasMatch = (tree: Node, condition: (node: Node) => boolean): boolean =>
  condition(tree);
export const hasNestedMatch = (tree: Node, condition: (node: Node) => boolean): boolean =>
  hasMatch(tree, condition) ||
  (tree.children && tree.children.some((child) => hasNestedMatch(child, condition)));

export const findMatchDepartment = (department: Department): Department => {
  let merged: Department[] = [];

  if (department.matched) {
    return department;
  } else if (department.children !== null) {
    merged = department.children.map((child) => findMatchDepartment(child));
  }
  return merged[0];
};

export const checkDepartmentTree = (department: Department, id: number): Department => ({
  ...department,
  children: department.children.map((child) => checkDepartmentTree(child, id)),
  checked: department.id === id ? !department.checked : department.checked,
});

export const toggleDepartmentTree = (department: Department, id: number): Department => ({
  ...department,
  children: department.children.map((child) => toggleDepartmentTree(child, id)),
  collapsed: department.id === id ? !department.collapsed : department.collapsed,
});

export const expandDepartmentTree = (department: Department): Department => ({
  ...department,
  children: department.children.map((child) => expandDepartmentTree(child)),
  collapsed: false,
});
