import React from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { QuestionSelectBox, QuestionSelectBoxProps } from 'component/SelectBox/QuestionSelectBox';
import { OptionItem } from 'component/SelectBox/SelectBox.s';
import { TextFlagNew } from 'component/FlagNew/TextFlagNew';
import { CommentsPageCard, RowsWrapper } from 'view/CommentsPage/CommentsPage.s';
import { CommentAnswers } from 'view/CommentsPage/CommentAnswers';
import { CommentWordCloud } from 'component/CommentWordCloud/CommentWordCloud';
import { AdvocacyFilter } from 'component/AdvocacyFilter/AdvocacyFilter';
import { Question } from 'model/Question';
import { Answer } from 'model/Answer';
import { Pagination } from 'model/Pagination';
import { SearchInput } from 'component/SearchInput/SearchInput';
import { PeriodSelectionSlider } from 'component/PeriodSelectionSlider/PeriodSelectionSlider';
import { selectAggregationPeriod } from 'reduxStore/filtering/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { fetchCommentsQuestions } from 'reduxStore/comments/asyncActions';
import { selectAnswersResource, selectQuestionsResource } from 'reduxStore/comments/selectors';
import { selectWordCloudResource } from 'reduxStore/wordCloud/selectors';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { LoadingStateEnum } from 'model/Resource';
import { Typography } from '@mui/material';

export type LifecycleCommentsPageProps = {
  answers: Answer[];
  initiallySelectedQuestion?: Question;
  onChangeAdvocacy: AdvocacyFilter.Props['onChange'];
  onQuestionSelect: QuestionSelectBoxProps['onChange'];
  onSearch: (value: string) => void;
  pagination: Pagination | null;
  questions: Question[];
  searchedText: string;
  selectedAdvocacyFilterValues: AdvocacyFilter.StateProps['selectedValues'];
};

export const LifecycleCommentsPage: React.FC<LifecycleCommentsPageProps> = ({
  answers,
  initiallySelectedQuestion,
  onChangeAdvocacy,
  onQuestionSelect,
  onSearch,
  pagination,
  questions,
  searchedText,
  selectedAdvocacyFilterValues,
}) => {
  const [aggregationPeriodStart, aggregationPeriodEnd] = useSelector(selectAggregationPeriod);
  const dispatch = useAppDispatch();

  const questionsResource = useSelector(selectQuestionsResource);
  const wordCloudResource = useSelector(selectWordCloudResource);
  const answersResource = useSelector(selectAnswersResource);

  const hasQuestions = questions.length > 0;
  const hasPagination = !!pagination;
  const hasAnswers = answers.length > 0;

  const shouldShowAnswers = hasQuestions && hasPagination;

  useUpdateEffect(() => {
    dispatch(fetchCommentsQuestions());
  }, [aggregationPeriodStart, aggregationPeriodEnd]);

  return (
    <>
      {answersResource.loadingState === LoadingStateEnum.RESOLVED && <PeriodSelectionSlider />}

      <ResourceLoader resource={[questionsResource, wordCloudResource, answersResource]}>
        <>
          {hasQuestions && (
            <QuestionSelectBox
              onChange={onQuestionSelect}
              initialSelected={initiallySelectedQuestion}
            >
              {questions.map((question, index) => (
                <OptionItem
                  data-testid={`question=[${question.id}]`}
                  key={index}
                  value={question}
                  data-option={question.option}
                >
                  {question.content}
                  <TextFlagNew isLatest={question.isLatest} />
                </OptionItem>
              ))}
            </QuestionSelectBox>
          )}

          {hasQuestions && hasAnswers && (
            <CommentsPageCard mt={3.75}>
              <CommentWordCloud />
            </CommentsPageCard>
          )}

          <CommentsPageCard mt={3.75}>
            {hasQuestions && (
              <RowsWrapper>
                <SearchInput
                  onChange={onSearch}
                  value={searchedText}
                  data-testid="lifecycleCommentsSearchInput"
                />
                <AdvocacyFilter
                  selectedValues={selectedAdvocacyFilterValues}
                  onChange={onChangeAdvocacy}
                />
              </RowsWrapper>
            )}

            {shouldShowAnswers && <CommentAnswers answers={answers} pagination={pagination} />}
            {!hasQuestions && <Typography>No comments to display for this date range</Typography>}
          </CommentsPageCard>
        </>
      </ResourceLoader>
    </>
  );
};
