import {
  InsightsItemDto,
  InsightsItemModel,
  mapInsightsItemDtoToModel,
} from 'api/dto/new/InsightsCompared/InsightsItem.dto';
import { RespondentProtectionEnumNullable } from 'model/RespondentProtection';

export type InsightsComparedDto = Readonly<{
  combineModeLock: boolean;
  groups: Readonly<Record<string | number, string>>;
  insights: ReadonlyArray<InsightsItemDto>;
  protections: Readonly<Record<string | number, RespondentProtectionEnumNullable>>;
}>;

export type InsightsComparedModel = Readonly<{
  combineModeLock: boolean;
  groups: Readonly<Record<string | number, string>>;
  insights: ReadonlyArray<InsightsItemModel>;
  protections: Readonly<Record<string | number, RespondentProtectionEnumNullable>>;
}>;

export const mapInsightsComparedDtoToModel = (dto: InsightsComparedDto): InsightsComparedModel => ({
  combineModeLock: dto.combineModeLock,
  groups: dto.groups,
  insights: dto.insights.map((insight) => mapInsightsItemDtoToModel(insight, dto.protections)),
  protections: dto.protections,
});
