import { CompareGroup } from 'model/CompareGroup';
import { Sentiment, SentimentEnum } from 'model/Sentiment';
import { Moment } from 'moment';
import { QuestionKind } from 'model/QuestionKind';

export type Question = Readonly<{
  id: number;
  content: string;
  kind?: QuestionKind;
  option?: number;
  askedAt?: Moment;
  groups?: ReadonlyArray<CompareGroup>;
  isLatest?: boolean;
  sentiment?: SentimentEnum | null;
  trendPlotData?: (number | null)[];
}>;

export type QuestionStat = Readonly<{
  questionCount: number;
  answerCount: number;
}>;

export type QuestionsStats = {
  [key in QuestionKind]: QuestionStat;
};

export namespace Question {
  const commentsQuestionKeys = [QuestionKind.QuestionCommentsBox, QuestionKind.QuestionForm];

  export const hasCommentsQuestion = (questionStats: QuestionsStats): boolean => {
    return commentsQuestionKeys.some((key) => questionStats[key].questionCount > 0);
  };

  export const hasCommentsAnswers = (questionStats: QuestionsStats): boolean => {
    return commentsQuestionKeys.some((key) => questionStats[key].answerCount > 0);
  };

  const insightsQuestionsKeys = [QuestionKind.QuestionCheckbox, QuestionKind.QuestionRadioButton];

  export const hasInsightsQuestion = (questionStats: QuestionsStats): boolean => {
    return insightsQuestionsKeys.some((key) => questionStats[key].questionCount > 0);
  };

  export const hasInsightsAnswers = (questionStats: QuestionsStats): boolean => {
    return insightsQuestionsKeys.some((key) => questionStats[key].answerCount > 0);
  };

  export const hasSentiment = (question: Question): boolean =>
    Sentiment.isSentimentSet(question.sentiment);
}
