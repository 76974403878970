import React, { useEffect, useRef, useState } from 'react';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Box } from '@mui/material';

export const OverflowTooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setIsOverflow(containerRef.current.scrollWidth > containerRef.current.clientWidth);
    }
  }, [children]);

  return (
    <Tooltip {...props} disableHoverListener={!isOverflowed}>
      <Box
        ref={containerRef}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        <>{children}</>
      </Box>
    </Tooltip>
  );
};
