import React from 'react';

import { InfoIcon } from 'util/withTooltip';

import Tooltip from '@mui/material/Tooltip';
import { ImpactOnEngagementInfo } from 'component/ImpactOnEngagementInfo/ImpactOnEngagementInfo';
import { Benchmark } from 'model/Benchmark';

import { HeaderCell, HeaderCellContent, HeadRow } from './Header.s';

export namespace Header {
  export type Props = {
    showImpactOnEngagement: boolean;
    isPulseTemplate: boolean;
    isReportView?: boolean;
    trendsColumnName?: string;
    benchmark?: Benchmark;
    shouldRenderCategory?: boolean;
    shouldDisplayTrendPlotDataColumn?: boolean;
  };
}

export const Header = (props: Header.Props): React.JSX.Element => {
  const renderTrendHeaderCell = () => {
    return (
      props.trendsColumnName && (
        <HeaderCell>
          <Tooltip id="change-text" title={props.isPulseTemplate && 'Vs your last pulse survey'}>
            <span style={{ fontWeight: 'bold' }} data-testid="headerLabel">
              {props.trendsColumnName}
            </span>
          </Tooltip>
        </HeaderCell>
      )
    );
  };

  const renderBenchmarkHeaderCell = () => {
    return Benchmark.isNotNone(props.benchmark) ? (
      <HeaderCell textLength={props.benchmark.label.length} isReportView={props.isReportView}>
        <div data-testid="headerLabel">{props.benchmark.label}</div>
      </HeaderCell>
    ) : null;
  };

  const renderBenchmarkTrendHeaderCells = () => {
    return (
      <>
        {renderTrendHeaderCell()}
        {renderBenchmarkHeaderCell()}
      </>
    );
  };

  return (
    <thead data-testid="questionTableHeader">
      <HeadRow>
        {props.shouldRenderCategory && <HeaderCell data-testid="headerLabel">Category</HeaderCell>}
        <HeaderCell data-testid="headerLabel">Question</HeaderCell>
        {props.showImpactOnEngagement && (
          <HeaderCell>
            <HeaderCellContent>
              <span data-testid="headerLabel">Impact</span>
              {!props.isReportView ? (
                <Tooltip
                  title={<ImpactOnEngagementInfo />}
                  placement="top"
                  enterTouchDelay={0}
                  leaveTouchDelay={15000}
                  arrow
                >
                  <InfoIcon data-testid="impactOnEngagementInfoIcon" />
                </Tooltip>
              ) : null}
            </HeaderCellContent>
          </HeaderCell>
        )}
        <HeaderCell sx={{ width: '200px' }} data-testid="headerLabel">
          Feedback
        </HeaderCell>
        <HeaderCell data-testid="headerLabel">Score</HeaderCell>
        {renderBenchmarkTrendHeaderCells()}
        {props.shouldDisplayTrendPlotDataColumn && (
          <HeaderCell data-testid="headerLabel">6 Month Trend</HeaderCell>
        )}
      </HeadRow>
    </thead>
  );
};
