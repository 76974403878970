import React from 'react';

import { ScaleLinear, ScalePoint } from 'd3-scale';
import { PulseSurveyBarChart } from 'component/PulseSurveyBarChart/PulseSurveyBarChart';
import { curveMonotoneX, line } from 'd3';
import { BLACK } from 'app/theme/colors';
import { ProjectSummary } from 'model/ProjectSummary';

export namespace Line {
  export type Scale = {
    xScale: ScalePoint<string>;
    yScale: ScaleLinear<number, number>;
    benchmarkXScale: ScalePoint<string>;
  };

  export type Props = {
    scales: Scale;
    margin: PulseSurveyBarChart.Margin;
    data: ProjectSummary[];
  };
}

export const BenchmarkLine: React.FC<Line.Props> = (props) => {
  const DASH_LENGTH = 6;
  const STROKE_WIDTH = 2;

  const benchmarkLine = line<ProjectSummary>()
    .x((d) => props.scales.benchmarkXScale(d ? d.date.toString() : '')!)
    .y((d) => props.scales.yScale(d ? d.benchmark : 0))
    .curve(curveMonotoneX);

  return (
    <g>
      <path
        d={benchmarkLine(props.data)!}
        stroke={BLACK}
        strokeDasharray={DASH_LENGTH}
        strokeDashoffset="-1"
        fill="none"
        strokeWidth={STROKE_WIDTH}
      />
    </g>
  );
};
