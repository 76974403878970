import React from 'react';
import { useSelector } from 'react-redux';

import { selectTitlePageOnly } from 'util/selector/querySelector';

import { ProjectKind } from 'model/ProjectKind';
import { TitlePage } from 'view/ReportView/ReportTemplate/TitlePage/TitlePage';
import { ReportType } from 'model/ReportType';
import { selectReportDepartment } from 'reduxStore/departments/selectors';
import { selectProjectKind, selectReportTitle } from 'reduxStore/project/selectors';
import { AllOverallReport } from 'view/ReportView/AllFeedbackReport/Overall/AllOverallReport';
import { AllEngagementScoreReport } from 'view/ReportView/AllFeedbackReport/EngagementScore/AllEngagementScoreReport';
import { AllCasReport } from 'view/ReportView/AllFeedbackReport/Cas/AllCasReport';
import { AllOnDemandReport } from 'view/ReportView/AllFeedbackReport/OnDemand/AllOnDemandReport';
import { AllLifecycleReport } from 'view/ReportView/AllFeedbackReport/Lifecycle/AllLifecycleReport';
import { useFetchOverall } from 'shared/hooks/useFetchOverall';
import { useFetchEngagementScore } from 'shared/hooks/useFetchEngagementScore';
import { useFetchSimpleProject } from 'shared/hooks/useFetchSimpleProject';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { fetchInsightsCombined } from 'reduxStore/insights/asyncActions';

export const AllFeedbackReportView: React.FC = () => {
  const projectKind = useSelector(selectProjectKind);
  const selectedDepartment = useSelector(selectReportDepartment);
  const isTitlePageOnly = useSelector(selectTitlePageOnly);
  const reportTitle = useSelector(selectReportTitle);

  useFetchEngagementScore();
  useFetchOverall();
  useFetchInsights(fetchInsightsCombined);
  useFetchSimpleProject();

  const selectReportByProjectKind = () => {
    switch (projectKind) {
      case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      case ProjectKind.PULSE_PROJECT:
        return <AllEngagementScoreReport />;
      case ProjectKind.PULSE_LIFECYCLE_PROJECT:
        return <AllLifecycleReport />;
      case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
        return <AllCasReport />;
      case ProjectKind.PROJECT_OVERALL_SCORE:
        return <AllOverallReport />;
      case ProjectKind.PULSE_ON_DEMAND_PROJECT:
        return <AllOnDemandReport />;
    }
  };

  if (isTitlePageOnly) {
    return (
      <TitlePage
        title={reportTitle}
        reportType={ReportType.Feedback}
        departmentName={selectedDepartment && selectedDepartment.name}
      />
    );
  }

  return <div>{selectReportByProjectKind()}</div>;
};
