import React from 'react';
import { useSelector } from 'react-redux';

import { InfoIcon } from 'util/withTooltip';

import {
  selectTopicsSummaryCommentsCount,
  selectTopicsSummaryScore,
} from 'reduxStore/topics/selectors';
import { NetSentimentHeaderWrapper, TooltipContent } from 'view/TopicsPage/Sentiment/Sentiment.s';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

const netSentimentTooltipContent = (
  <TooltipContent data-testid="netSentimentTooltipContent">
    Using machine learning (Artificial Intelligence) WorkBuzz scores the most positive comments 1
    and the most negative comments -1. The average for each Topic is then calculated – those with
    the highest Net Sentiment Scores are where you received most positive feedback and those Topics
    with the lowest or negative scores, are where you received most constructive comments.
  </TooltipContent>
);

export type NetSentimentHeaderPureProps = {
  score: number;
  commentsCount: number;
};

const NetSentimentHeaderPure: React.FC<NetSentimentHeaderPureProps> = (props) => {
  const { score, commentsCount } = props;

  return (
    <NetSentimentHeaderWrapper data-testid="netSentimentComponent">
      <Typography variant="h5">
        Net Sentiment Score: {score} ({commentsCount} comments)
      </Typography>
      <Tooltip
        title={netSentimentTooltipContent}
        placement="top"
        enterTouchDelay={0}
        leaveTouchDelay={15000}
        arrow
      >
        <InfoIcon data-testid="netSentimentInfoIcon" />
      </Tooltip>
    </NetSentimentHeaderWrapper>
  );
};

export const NetSentimentHeader: React.FC = () => {
  const score = useSelector(selectTopicsSummaryScore);
  const commentsCount = useSelector(selectTopicsSummaryCommentsCount);

  return <NetSentimentHeaderPure score={score} commentsCount={commentsCount} />;
};
