import { formatDateToFullDateString } from 'util/formatDate';

import { Option } from 'model/Option';
import { Pulse } from 'model/Pulse';
import { ProjectModel } from 'model/Project.model';

export namespace PulseFiltering {
  const removeSelected = (selected: ProjectModel['id']) => (option: Option): boolean =>
    Number(option.id) !== selected;

  type GetSelectableOptions = (selectedId: ProjectModel['id'], options: Option[]) => Option[];
  export const getSelectableOptions: GetSelectableOptions = (selectedId, options) => {
    return options.filter(removeSelected(selectedId));
  };

  export const showFilter = (options: Option[], isReportView: boolean = false): boolean => {
    const MINIMAL_NUMBER_OF_OPTIONS = 1;
    return options.length > MINIMAL_NUMBER_OF_OPTIONS && !isReportView;
  };

  export const pulseToOption = (pulse: Pulse): Option => ({
    id: pulse.id,
    date: `${formatDateToFullDateString(pulse.closedAt!)}`,
    label: pulse.name,
  });

  type SortByDate = (pulseA: Pulse, pulseB: Pulse) => number;
  export const sortByDate: SortByDate = (pulseA, pulseB) => {
    return pulseB.closedAt!.unix() - pulseA.closedAt!.unix();
  };
}
