import {
  CARDINAL_50,
  CARDINAL_60,
  CARDINAL_70,
  MANGO_TANGO_25,
  MANGO_TANGO_50,
  MANGO_TANGO_75,
  MARINER,
  TEAL_25,
  TEAL_60,
  TEAL_80,
} from 'app/theme/colors';
import { alpha } from '@mui/material';

export const SUMMARY_HEATMAP_COLORS = [
  CARDINAL_70,
  CARDINAL_60,
  CARDINAL_50,
  MANGO_TANGO_75,
  MANGO_TANGO_50,
  MANGO_TANGO_25,
  TEAL_25,
  TEAL_60,
  TEAL_80,
];

export const INSIGHTS_HEATMAP_OPACITY = [0.07, 0.14, 0.21, 0.28, 0.35, 0.42, 0.49, 0.56, 0.63, 0.7];
export const INSIGHTS_HEATMAP_COLORS = INSIGHTS_HEATMAP_OPACITY.map((opacity) =>
  alpha(MARINER, opacity)
);
