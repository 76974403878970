import { Demography } from 'model/Demography';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'demography';

export namespace DemographyState {
  export type Domain = {
    list: Resource<Demography[]>;
  };

  export const INITIAL_DOMAIN: Domain = {
    list: Resource.createResolvedResource({ data: [] }),
  };
}

export type DemographyState = {
  demography: DemographyState.Domain;
};
