import React from 'react';
import ToolTip from 'react-tooltip';

import { withDefaultProps } from 'util/withDefaultProps';

import { scaleBand, ScaleBand, scaleLinear, ScaleLinear } from 'd3-scale';
import { Axes } from 'component/BarChart/Axes';
import { Bars } from 'component/BarChart/Bars';
import { BarChartModel } from 'model/BarChart';
import { toolTip } from 'component/BarChart/BarChart.s';

export namespace BarChart {
  export type Margin = {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  export type Props = {
    data: BarChartModel[];
    height: number;
    width: number;
  };
}

const BarChartPure: React.FC<BarChart.Props> = (props) => {
  const margins = { top: 10, right: 20, bottom: 20, left: 60 };

  const data = props.data ?? [];
  const maxValue = Math.max(...data.map((d) => d.value));

  const xScale: ScaleBand<string> = scaleBand()
    .padding(props.data.length > 5 ? 0.15 : 0.5)
    .domain(props.data.map((d) => d.label))
    .range([margins.left, props.width - margins.right]);

  const yScale: ScaleLinear<number, number> = scaleLinear()
    .domain([0, 100])
    .range([props.height - margins.bottom, margins.top]);

  return (
    <div data-testid="barChart">
      <ToolTip place="right" type="info" className={`${toolTip}`} html />
      <svg width={props.width} height={props.height}>
        <Axes
          xScale={xScale}
          yScale={yScale}
          margin={margins}
          height={props.height}
          width={props.width}
          unit="%"
        />
        <Bars
          data={props.data}
          margin={margins}
          maxValue={maxValue}
          scales={{ xScale, yScale }}
          height={props.height}
        />
      </svg>
    </div>
  );
};

const defaultProps = {
  width: 500,
  height: 350,
};

export const BarChart = withDefaultProps(defaultProps as BarChart.Props, BarChartPure);
