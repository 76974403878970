import { selectColorBySubmitType } from 'util/selectColorBySubmitType';

import { styled, Typography } from '@mui/material';
import { scaleLinear } from 'd3-scale';
import * as d3 from 'd3';
import {
  BLACK,
  GRAY_NURSE,
  GREY_CELL,
  GREY_CELL_TEXT,
  OVERALL_COLUMN,
  RESTRICTED_COLUMN,
  RESTRICTED_COLUMN_TEXT,
  WHITE,
} from 'app/theme/colors';
import { SubmitType } from 'model/SubmitType';
import { typography } from 'app/theme/typography';
import { INSIGHTS_HEATMAP_COLORS, SUMMARY_HEATMAP_COLORS } from 'component/HeatmapLegend/constants';
import { ComparisionMatrix } from 'model/ComparisionMatrix';

export const labelCellPaddingRight = 10;

export const LabelCell = styled('td')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 35,
  padding: '12px 16px',
  paddingRight: labelCellPaddingRight,
  verticalAlign: 'middle',
  textWrap: 'balance',
});

export const LabelCellContent = styled(Typography, {
  shouldForwardProp: (prop) => !['isReportView', 'isClickable'].includes(prop.toString()),
})(
  {
    marginBottom: 0,
    marginRight: 10,
    width: '100%',
    minWidth: '200px',
  },
  ({ isClickable }: { isClickable: boolean }) => ({
    cursor: isClickable ? 'pointer' : 'auto',
  })
);

export const TableRow = styled('tr')({
  borderBottom: `1px ${GRAY_NURSE} solid`,
  '&:last-of-type': {
    borderBottom: 'none',
  },
});

const colors = scaleLinear<string>()
  .domain([0, 22.22, 33.33, 44.44, 55.55, 66.66, 77.77, 88.88, 100])
  .range(SUMMARY_HEATMAP_COLORS)
  .interpolate(d3.interpolateRgb);

const colorsBlue = scaleLinear<string>()
  .domain([0, 11.11, 22.22, 33.33, 44.44, 55.55, 66.66, 77.77, 88.88, 100])
  .range(INSIGHTS_HEATMAP_COLORS)
  .interpolate(d3.interpolateRgb);

const ValueCell = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  ...typography.body2,
});

type ColorCellProps = {
  value: number;
  colorType: Exclude<ComparisionMatrix.ColorType, 'grey'>;
  type?: SubmitType;
};

export const ColorCell = styled(ValueCell, {
  shouldForwardProp: (prop) => prop !== 'colorType',
})(
  {
    width: '1%',
    color: BLACK,
    padding: '0 10px',
  },
  (props: ColorCellProps) => () => {
    const colorsToUse = props.colorType === 'mixedBlue' ? colorsBlue : colors;
    return {
      backgroundColor:
        props.colorType !== 'byScale'
          ? colorsToUse(props.value)
          : selectColorBySubmitType(props.type as SubmitType),
      ':nth-child(2)': {
        backgroundColor:
          props.colorType !== 'byScale'
            ? OVERALL_COLUMN
            : selectColorBySubmitType(props.type as SubmitType),
      },
    };
  }
);

export const GreyCell = styled(ValueCell)(
  {
    width: '1%',
    color: GREY_CELL_TEXT,
  },
  ({ index }: { index: number }) => ({
    backgroundColor: index % 2 === 0 ? GREY_CELL : WHITE,
  })
);

export const LessThanColumn = styled('td')(
  {
    width: 'inherit',
    position: 'relative',
    top: 0,
    left: 0,
    padding: '10px 0',
    verticalAlign: 'middle',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    lineHeight: 0,
  },
  ({ index }: { index: number }) => ({
    backgroundColor: index % 2 === 0 ? RESTRICTED_COLUMN : RESTRICTED_COLUMN,
  })
);

export const HiddenValue = styled(ValueCell)(
  {
    color: RESTRICTED_COLUMN_TEXT,
  },
  ({ index }: { index: number }) => ({
    backgroundColor: index % 2 === 0 ? RESTRICTED_COLUMN : WHITE,
  })
);
