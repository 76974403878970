import React, { ComponentType, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import LogoutIcon from 'react-icons/lib/md/exit-to-app';
import { Dropdown } from 'component/Dropdown/Dropdown';
import ChangeIcon from 'react-icons/lib/fa/exchange';
import {
  selectHasMoreThanOneProject,
  selectIsRegularProjectKind,
} from 'reduxStore/project/selectors';
import { selectUserNameToDisplay } from 'reduxStore/user/selectors';
import { logout } from 'reduxStore/user/utils';
import { route } from 'reduxStore/router/asyncActions';
import { AppRoute } from 'app/route/app';
import { TopDropdownWrapper } from 'component/TopNavBar/TopNavBar.s';
import { useAppDispatch } from 'reduxStore/appStore';
import { SxProps, Theme } from '@mui/material';

export type TopNavBarDropdownPureProps = TopNavBarDropdownProps & {
  hasMoreThanOneProject: boolean;
  isRegularProjectKind: boolean;
  onLogout: () => void;
  onSelectProject: () => void;
  userName?: string;
};

const TopNavBarDropdownPure: ComponentType<TopNavBarDropdownPureProps> = ({
  hasMoreThanOneProject,
  isRegularProjectKind,
  onLogout,
  onSelectProject,
  'data-testid': dataTestId,
  userName = '',
  sx,
}) => {
  const iconStyle = { fontSize: '16px', marginRight: '5px' };

  return (
    <TopDropdownWrapper sx={sx} data-testid={dataTestId}>
      <Dropdown label={userName}>
        <>
          {hasMoreThanOneProject && isRegularProjectKind && (
            <DropdownItem alignedRight onClick={onSelectProject} data-testid="changeSurveyItem">
              <ChangeIcon style={iconStyle} />
              <span>Change Survey</span>
            </DropdownItem>
          )}

          <DropdownItem alignedRight onClick={onLogout} data-testid="logoutItem">
            <LogoutIcon style={iconStyle} />
            <span>Log Out</span>
          </DropdownItem>
        </>
      </Dropdown>
    </TopDropdownWrapper>
  );
};

export type TopNavBarDropdownProps = {
  'data-testid'?: string;
  sx?: SxProps<Theme>;
};

export const TopNavBarDropdown: React.FC<TopNavBarDropdownProps> = ({
  ['data-testid']: dataTestId = 'topDropdownWrapper',
  sx,
}) => {
  const dispatch = useAppDispatch();
  const hasMoreThanOneProject = useSelector(selectHasMoreThanOneProject);
  const isRegularProjectKind = useSelector(selectIsRegularProjectKind);
  const userName = useSelector(selectUserNameToDisplay);

  const onSelectProject = useCallback(() => dispatch(route({ pattern: AppRoute.HOME })), [
    dispatch,
  ]);

  return (
    <TopNavBarDropdownPure
      hasMoreThanOneProject={hasMoreThanOneProject}
      isRegularProjectKind={isRegularProjectKind}
      onLogout={logout}
      onSelectProject={onSelectProject}
      userName={userName}
      data-testid={dataTestId}
      sx={sx}
    />
  );
};
