import { PermissionType } from 'register/PermissionType';
import { PermissionGroup } from 'model/PermissionGroup';
import { User } from 'model/User';
import { UserRole } from 'register/UserRole';

export namespace Permission {
  export const isGranted = (user: User, type: PermissionType) =>
    user.permissions
      ? Boolean(user.permissions.find((permission) => permission === type)) ||
        user.role === UserRole.Admin ||
        user.role === UserRole.CircleAdmin
      : false;

  export const getPermissionsByProjectId = (
    permissionGroups: PermissionGroup[],
    projectId: number
  ) => {
    const permissionGroup = permissionGroups.find((aPermissionGroup) =>
      aPermissionGroup.objectIdentifier.split('-').slice(-1)[0].includes(projectId.toString())
    );

    return permissionGroup ? permissionGroup.permissions : [];
  };
}
