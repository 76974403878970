import { BenchmarkType } from 'model/BenchmarkType';

export type BenchmarkData = {
  label: string;
  type: BenchmarkType;
  value: number;
};

export type Benchmark = BenchmarkData | null;

export namespace Benchmark {
  export const isNotNone = (benchmark: Benchmark | undefined): benchmark is BenchmarkData => {
    return !!benchmark && benchmark.type !== BenchmarkType.NONE;
  };

  export const isVsExternalBenchmark = (benchmark: Benchmark): boolean => {
    return !!benchmark && benchmark.type === BenchmarkType.VS_EXTERNAL_BENCHMARK;
  };

  export const getShortBenchmarkLabel = (benchmark: Benchmark) => {
    return (benchmark && benchmark.label && benchmark.label.replace('Vs', '')) || '';
  };
}
