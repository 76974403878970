import React from 'react';
import { useSelector } from 'react-redux';

import { mapDataToCompareMatrix } from 'util/mapDataToCompareMatrix';
import { chunk } from 'util/chunkArray';

import { CompareMatrix } from 'component/CompareMatrix/CompareMatrix';
import { selectSimpleProjectCompareBarChart } from 'reduxStore/simpleProject/selectors';
import { CompareGroup } from 'model/CompareGroup';
import { SubmitType } from 'model/SubmitType';

export type CasBarChartCompareProps = {
  title?: string;
  groups: string[];
  minResponses: number;
  isReportView: boolean;
};

export type CasBarChartComparePureProps = {
  barChart?: ReadonlyArray<{ label: string; groups: ReadonlyArray<CompareGroup> }>;
} & CasBarChartCompareProps;

export const CasBarChartComparePure: React.FC<CasBarChartComparePureProps> = (props) => {
  const labelsValues = mapDataToCompareMatrix<string>(props.barChart, ['label'], 'label');
  const submitsType = mapDataToCompareMatrix<number>(props.barChart, ['type'], 'label');

  const labels = labelsValues.map((value, index) => ({
    content: value,
    contentType: 'percentage',
    submitType: submitsType[index] as SubmitType,
  }));

  const scores = chunk<number>(
    mapDataToCompareMatrix<number>(props.barChart, ['groups', 'percentage'], 'data'),
    props.groups.length
  );

  const protectedScore = chunk<number>(
    mapDataToCompareMatrix<number>(props.barChart, ['groups', 'respondentProtectionType'], 'data'),
    props.groups.length
  );

  const confidentialityProtection = chunk<number>(
    mapDataToCompareMatrix<number>(props.barChart, ['groups', 'confidentialityProtection'], 'data'),
    props.groups.length
  );

  const values = scores.map((item, index) => ({
    value: item,
    respondentProtectionTypeArray: protectedScore[index],
    isConfidentialityProtection: confidentialityProtection[index],
  })) as any[];

  return (
    <CompareMatrix
      title={props.title}
      sx={{ mb: 3 }}
      departments={props.groups}
      labels={labels}
      values={values}
      colorType="byScale"
      minResponses={props.minResponses}
      isReportView={props.isReportView}
    />
  );
};

export const CasBarChartCompare: React.FC<CasBarChartCompareProps> = (props) => {
  const barChart = useSelector(selectSimpleProjectCompareBarChart);

  return <CasBarChartComparePure {...props} barChart={barChart} />;
};
