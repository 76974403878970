import React from 'react';

import { CheckboxInput, CheckboxWrapper } from 'component/Checkbox/Checkbox.s';

export namespace Checkbox {
  export type Props = {
    onToggle?: () => void;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    variant?: 'DARK' | 'LIGHT';
    'data-testid'?: string;
  };
}

export const Checkbox: React.FC<Checkbox.Props> = (props) => (
  <CheckboxWrapper className={props.className}>
    <CheckboxInput
      type="checkbox"
      checked={props.checked}
      disabled={props.disabled}
      onChange={() => props.onToggle && props.onToggle()}
      variant={props.variant || 'DARK'}
      data-testid={props['data-testid']}
    />
    <span />
  </CheckboxWrapper>
);
