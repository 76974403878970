import { AppState } from 'reduxStore/appStore';
import { createSelector } from 'reselect';

import { STORE_NAME } from './initialState';

export const selectWordCloudResource = (state: AppState) => state[STORE_NAME];

export const selectWordCloud = createSelector(selectWordCloudResource, (domain) => {
  return domain.data || [];
});
export const selectWordCloudStatus = createSelector(
  selectWordCloudResource,
  (domain) => domain.loadingState
);
