import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { createFiltersQuery } from 'util/createFiltersQuery';
import {
  selectModeType,
  selectSorting,
  selectSummaryCategoryViewMode,
} from 'util/selector/querySelector';

import { SortableQuestionHeader } from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/SortableQuestionHeader/SortableQuestionHeader';
import { Body } from 'view/EngagementDriverByCategoryPage/QuestionTable/Body/Body';
import { OpinionQuestionDetails } from 'model/OpinionQuestionDetails';
import { selectShouldShowImpactOnEngagement } from 'reduxStore/engagementDrivers/selectors';
import { OpinionQuestionCategory } from 'register/OpinionQuestionCategory';
import { selectIsPulseTemplateProjectKind } from 'reduxStore/project/selectors';
import { Benchmark } from 'model/Benchmark';
import { selectBenchmarkFromVisualSettings } from 'reduxStore/visualSettings/selectors';
import {
  ColumnName,
  Comparator,
  getNextOrderBy,
  SortBy,
} from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/utils';
import { pushQuery } from 'reduxStore/router/asyncActions';
import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';
import { selectSelectedClassifications } from 'reduxStore/demography/selectors';
import { useAppDispatch } from 'reduxStore/appStore';

import { hasAnyTrendPlotData } from '../QuestionTable/./utils';
import { TableWrapper } from '../QuestionTable/QuestionTable.s';

export type QuestionDataElementType = OpinionQuestionDetails.Combine & { categoryTitle?: string };
export type QuestionDataType = ReadonlyArray<QuestionDataElementType>;
export type SortableQuestionTableProps = {
  data: QuestionDataType;
  categoryId?: number;
  isReportView?: boolean;
  width?: number | string;
  shouldRenderCategory: boolean;
};

export type SortableQuestionTablePureProps = {
  showImpactOnEngagement: boolean;
  isPulseTemplate: boolean;
  benchmark: Benchmark;
  sortBy: SortBy;
  onSort: (column: ColumnName | string) => void;
} & SortableQuestionTableProps;

export const SortableQuestionTablePure: React.FC<SortableQuestionTablePureProps> = (props) => {
  const shouldDisplayTrendPlotDataColumn = hasAnyTrendPlotData(props.data);

  return (
    <TableWrapper width={props.width} data-testid="questionTable">
      <SortableQuestionHeader
        trendLabels={
          props.data.length && props.data[0].trends?.length
            ? props.data[0].trends.map?.((trend) => trend.label)
            : undefined
        }
        isReportView={props.isReportView}
        isPulseTemplate={props.isPulseTemplate}
        showImpactOnEngagement={props.showImpactOnEngagement}
        benchmark={OpinionQuestionDetails.getHeaderBenchmark(props.data)}
        shouldRenderCategory={props.shouldRenderCategory}
        shouldDisplayTrendPlotDataColumn={shouldDisplayTrendPlotDataColumn}
        sortBy={props.sortBy}
        onSort={props.onSort}
      />
      <Body
        data={props.data}
        showImpactOnEngagement={props.showImpactOnEngagement}
        shouldRenderCategory={props.shouldRenderCategory}
        shouldDisplayTrendPlotDataColumn={shouldDisplayTrendPlotDataColumn}
        isReportView={props.isReportView}
      />
    </TableWrapper>
  );
};

export const SortableQuestionTable: React.FC<SortableQuestionTableProps> = (props) => {
  const dispatch = useAppDispatch();
  const shouldShowImpactOnEngagement = useSelector(selectShouldShowImpactOnEngagement);
  const isPulseTemplate = useSelector(selectIsPulseTemplateProjectKind);
  const benchmark = useSelector(selectBenchmarkFromVisualSettings);

  const [sortedData, setSortedData] = useState(props.data.slice());
  const selectedDepartments = useSelector(selectSelectedDepartmentIds);
  const summaryMode = useSelector(selectModeType);
  const selectedClassifications = useSelector(selectSelectedClassifications);
  const categoryMode = useSelector(selectSummaryCategoryViewMode);
  const sortBy = useSelector(selectSorting);

  const showImpactOnEngagement =
    shouldShowImpactOnEngagement &&
    props.categoryId !== OpinionQuestionCategory.EMPLOYEE_ENGAGEMENT;

  useEffect(() => {
    const newSortedData = props.data.slice();
    if (sortBy.column && sortBy.order) {
      // Default Trend sorting
      if (sortBy.column === ColumnName.Trends) {
        newSortedData.sort(Comparator[sortBy.column][sortBy.order]());
        // Custom trends
      } else if (!Comparator.hasOwnProperty(sortBy.column)) {
        newSortedData.sort(Comparator[ColumnName.Trends][sortBy.order](sortBy.column));
        // Other columns sorting
      } else {
        newSortedData.sort(
          Comparator[sortBy.column as Exclude<ColumnName, 'Trends'>][sortBy.order]
        );
      }
    }

    setSortedData(newSortedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, props.data]);

  const onSort = (column: ColumnName | string) => {
    dispatch(
      pushQuery({
        query: createFiltersQuery({
          classifications: selectedClassifications,
          departments: selectedDepartments,
          summaryMode,
          categoryMode,
          sortBy: {
            column,
            order: getNextOrderBy({ currentSortBy: sortBy, column }),
          },
        }),
      })
    );
  };

  return (
    <SortableQuestionTablePure
      {...props}
      data={sortedData}
      showImpactOnEngagement={showImpactOnEngagement}
      isPulseTemplate={isPulseTemplate}
      benchmark={benchmark}
      sortBy={sortBy}
      onSort={onSort}
    />
  );
};
