import { Question } from 'model/Question';
import { Feedback } from 'model/Feedback';
import { Trend } from 'model/Trend';
import { CompareGroup } from 'model/CompareGroup';
import { Category } from 'model/Category';
import { Moment } from 'moment';
import { Benchmark } from 'model/Benchmark';
import { ImpactOnEngagement } from 'model/ImpactOnEngagement';

export namespace OpinionQuestionDetails {
  export type Combine = Readonly<{
    score: number;
    question: Question;
    feedback: ReadonlyArray<Feedback>;
    trends?: ReadonlyArray<Trend>;
    impactOnEngagement?: ImpactOnEngagement | null;
    benchmark?: Benchmark;
    askedAt?: Moment;
  }>;
  export type Compare = Readonly<{
    groups: ReadonlyArray<CompareGroup>;
    questions: { question: Question; groups: ReadonlyArray<CompareGroup> }[];
    category?: Category;
  }>;

  export const getHeaderBenchmark = (
    opinionQuestionDetails: ReadonlyArray<OpinionQuestionDetails.Combine>
  ): Benchmark | undefined => {
    return opinionQuestionDetails && opinionQuestionDetails.length
      ? opinionQuestionDetails[0].benchmark
      : undefined;
  };

  export const needExplanation = (data: readonly Combine[], isReport: boolean = false): boolean => {
    return isReport ? false : data.some((details) => details.question.isLatest);
  };
}
