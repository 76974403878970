import React, { ReactNode } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material';
import { BLACK, SONIC_SILVER } from 'app/theme/colors';

export const InfoIcon = styled(InfoOutlinedIcon)({
  marginLeft: '8px',
  color: SONIC_SILVER,
  '&:hover': {
    cursor: 'pointer',
    color: BLACK,
  },
});

type WithTooltip = (text: ReactNode, tooltipContent: string) => ReactNode;

export const withTooltip: WithTooltip = (text, tooltipContent) => {
  return (
    <>
      {text}
      <Tooltip title={tooltipContent} placement="top" enterTouchDelay={0} arrow>
        <InfoIcon />
      </Tooltip>
    </>
  );
};
