import React from 'react';

import { PageWrapperHeader } from 'component/PageHeader/PageHeader.s';
import { PageTitle } from 'component/PageHeaderTitle/PageTitle';
// import { useSelector } from 'react-redux';
// import { selectAllReportDownloadUrl } from 'util/selector/reportSelector';
// import { DownloadAllButton } from 'view/ReportsPage/ReportsPage.s';

// TODO: uncomment all commented rows in the file to have `Download All Reports` button visible (https://buzzing.atlassian.net/browse/WB3-756)
export const ReportsHeader: React.FC = () => {
  // const allReportDownloadUrl = useSelector(selectAllReportDownloadUrl);

  return (
    <PageWrapperHeader>
      <PageTitle title="Download PDF Reports" />
      {/*<a href={allReportDownloadUrl}>*/}
      {/*  <DownloadAllButton>Download All Reports</DownloadAllButton>*/}
      {/*</a>*/}
    </PageWrapperHeader>
  );
};
