import { Summary } from 'model/Summary';
import { Resource } from 'model/Resource';

export const STORE_NAME = 'summary';

export namespace SummaryState {
  export type Domain = {
    combine: Resource<Summary.Combine>;
    compare: Resource<Summary.Compare[]>;
    pulses: Resource<Summary.Pulses>;
  };

  export const INITIAL_DOMAIN_COMBINE = Resource.createPendingResource<Summary.Combine>(
    {} as Summary.Combine
  );

  export const INITIAL_DOMAIN_COMPARE = Resource.createPendingResource<Summary.Compare[]>([]);

  export const INITIAL_DOMAIN_PULSES = Resource.createPendingResource<Summary.Pulses>({
    list: undefined,
  });

  export const INITIAL_DOMAIN: SummaryState.Domain = {
    combine: INITIAL_DOMAIN_COMBINE,
    compare: INITIAL_DOMAIN_COMPARE,
    pulses: INITIAL_DOMAIN_PULSES,
  };
}

export type SummaryState = {
  summary: SummaryState.Domain;
};
