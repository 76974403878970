import { styled } from '@mui/material';
import { IRON, WORKBUZZ_ORANGE } from 'app/theme/colors';

export const HeaderWrapper = styled('thead')({
  width: '100%',
  textAlign: 'left',
  '@media print': {
    marginBottom: 50,
  },
});

export const HeaderLine = styled('div')({
  margin: '24px 0',
  height: '1px',
  background: WORKBUZZ_ORANGE,

  '@media print': {
    background: IRON,
    height: '2px',
  },
});
