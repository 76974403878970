import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectInsightsCombined,
  selectInsightsCombinedResource,
} from 'reduxStore/insights/selectors';
import { PulseFilter } from 'component/PulseFilter/PulseFilter';
import { InsightsCombinedQuestion } from 'view/InsightsPage/combined/InsightsCombinedQuestion';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { useFetchInsights } from 'view/InsightsPage/hooks/useFetchInsights';
import { fetchInsightsCombined } from 'reduxStore/insights/asyncActions';
import { NoResultsMessage } from 'component/NoResultsMessage/NoResultsMessage';

export const InsightsCombinedPage: React.FC = () => {
  const insightsCombineResource = useSelector(selectInsightsCombinedResource);
  const insightsCombine = useSelector(selectInsightsCombined);

  useFetchInsights(fetchInsightsCombined);

  return (
    <ResourceLoader resource={insightsCombineResource}>
      <PulseFilter />
      {insightsCombine?.data?.map((item, index) => (
        <InsightsCombinedQuestion key={index} item={item} />
      ))}

      {insightsCombine?.data?.length === 0 && (
        <NoResultsMessage
          data-testid="insightsCombineNoResultsMessage"
          title="For this pulse survey, we asked no insights questions for the group you are viewing feedback for."
          hideIcon
        />
      )}
    </ResourceLoader>
  );
};
