import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import { fetchOverallCombine, fetchOverallCompare } from 'reduxStore/overallSurvey/asyncActions';
import { Resource } from 'model/Resource';

import { OverallSurveyState, STORE_NAME } from './initialState';

export const overallSurveySlice = createSlice({
  name: STORE_NAME,
  initialState: OverallSurveyState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    addAsyncCases(builder, fetchOverallCombine, (state, action) => {
      state.combine = action.payload as any;
      state.compare = Resource.createPendingResource(null);
    });
    addAsyncCases(builder, fetchOverallCompare, (state, action) => {
      state.compare = action.payload as any;
      state.combine = Resource.createPendingResource(null);
    });
  },
});
