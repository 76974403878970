import { AxiosInstance } from 'axios';
import { SummaryApi } from 'api/SummaryApi';
import { OpinionQuestionApi } from 'api/OpinionQuestionApi';
import { CompareGroupApi } from 'api/CompareGroupApi';
import { OverallSurvey } from 'model/OverallSurvey';

import { OverallSurveyMapper } from '../mapper/OverallSurveyMapper';

export namespace OverallSurveyApi {
  export type EntryCombine = {
    summary: SummaryApi.EntryCombine;
    byCategory: OpinionQuestionApi.CombineEntry[];
    showImpactOnEngagement: boolean;
  };
  export type EntryCompare = {
    summary: SummaryApi.EntryCompare[];
    categories: OpinionQuestionApi.CompareEntry[];
    groups: CompareGroupApi.Entry;
    combineModeLock: boolean;
    protections: { [key: number]: boolean };
  };
}

export class OverallSurveyApi {
  constructor(private client: AxiosInstance, private overallSurveyMapper: OverallSurveyMapper) {}

  combine(projectId: number, query: string): Promise<OverallSurvey.Combine> {
    return this.client
      .get(`/api/v1/project/${projectId}/report/summary/combined${query}`)
      .then((response) => this.overallSurveyMapper.deserializeCombine(response.data))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  compare(projectId: number, query: string): Promise<OverallSurvey.Compare> {
    return this.client
      .get(`/api/v1/project/${projectId}/report/summary/compared${query}`)
      .then((response) => this.overallSurveyMapper.deserializeCompare(response.data))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }
}
