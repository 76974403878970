import { styled } from '@mui/material';
import { WHITE, WORKBUZZ_ORANGE } from 'app/theme/colors';

export const Menu = styled('div')({
  backgroundColor: WHITE,
  display: 'flex',
  height: '51px',
  padding: '10px 25px 0',
  borderBottom: `3px solid ${WORKBUZZ_ORANGE}`,
});
