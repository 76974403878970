import { styled, Typography } from '@mui/material';
import { GRAY_NURSE, MARINER, RESTRICTED_COLUMN_TEXT, WHITE } from 'app/theme/colors';

type ButtonWrapperProps = {
  isLeftButton?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
};

export const ButtonsWrapper = styled('div')({
  background: GRAY_NURSE,
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'space-between',
  margin: '8px 0 16px',
  padding: 2,
  '& > div, & > p': {
    width: '50%',
  },
});

export const ButtonWrapper = styled(Typography, {
  shouldForwardProp: (prop) =>
    !['isDisabled', 'isActive', 'isLeftButton'].includes(prop.toString()),
})(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 8,
    padding: 8,
  },
  (props: ButtonWrapperProps) => ({
    backgroundColor: props.isActive ? WHITE : 'transparent',
    color: props.isDisabled ? RESTRICTED_COLUMN_TEXT : MARINER,
    ':hover': {
      cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    },
  })
);
